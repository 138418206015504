import { Button, type MantineSize } from '@mantine/core';
import { IconDeviceFloppy } from '@tabler/icons-react';
import React from 'react';

import { useI18n } from '../contexts/I18nProvider';

type Props = {
  size?: MantineSize | `compact-${MantineSize}` | (string & {});
  isLoading?: boolean;
};

export default function ButtonSave({ size = 'sm', isLoading = false }: Props) {
  const { t } = useI18n();

  return (
    <Button
      loading={isLoading}
      size={size}
      type={'submit'}
      leftSection={<IconDeviceFloppy />}
    >
      {t('w.save')}
    </Button>
  );
}
