import { FilterOption, Options, Table } from '@ckprivate/ckf-ui';
import { ActionIcon, Button, Group, Image, Text, Title } from '@mantine/core';
import { upperFirst } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconAlertTriangle, IconDownload, IconEye } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { DataTableColumn } from 'mantine-datatable';
import React, { ReactNode, useMemo, useState } from 'react';

import CustomTooltip from '../../../components/CustomTooltip';
import FileViewer from '../../../components/FileViewer/FileViewer';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import GererMesAffairesService from '../../../services/GererMesAffairesService';
import PayslipService from '../../../services/PayslipService';
import { PayslipGma, PayslipsFromGma, PayslipUser } from '../../../types/types';
import { getPersistedObject } from '../../../utils/localStorage';
import {
  getFilter,
  getHiddenColumns,
  saveFilter,
  saveHiddenColumns,
} from '../../../utils/optionsPreferences';

type Props = {
  userName: string;
  payslips: PayslipUser[];
  userId: string | undefined;
  companyId: string;
  gmaPayslips?: PayslipsFromGma | null;
  loading: boolean;
};

const componentName = 'Payslips';
export default function PayslipsUserTable({
  payslips,
  userName,
  userId,
  companyId,
  gmaPayslips,
  loading,
}: Props) {
  const { t, lang } = useI18n();
  const { access_token } = useAuth();
  const [selectedPayslips, setSelectedPayslips] = useState<any>([]);

  const rightCustomActions: ReactNode[] = useMemo(
    () => [
      <Button
        key={'download-selected-payslips'}
        disabled={selectedPayslips.length === 0}
        leftSection={<IconDownload />}
        onClick={() =>
          selectedPayslips.forEach((payslip: PayslipUser | PayslipGma) =>
            handleLoadPayslipClick(
              payslip.year,
              payslip.month,
              payslip.gmaPayslipId
            )
          )
        }
      >
        {t('w.download')}
      </Button>,
    ],
    [selectedPayslips.length]
  );

  function formatMonthLabel(month: number): string {
    return upperFirst(
      dayjs()
        .locale(lang)
        .month(month - 1)
        .format('MMMM')
    );
  }

  const columns: DataTableColumn<any>[] = useMemo(
    () => [
      {
        accessor: 'month',
        title: t('w.month'),
        sortable: true,
        ellipsis: true,
        render: ({ month }) => <Text>{formatMonthLabel(month)}</Text>,
      },
      {
        accessor: 'year',
        title: t('w.year'),
        sortable: true,
        ellipsis: true,
        render: ({ year }) => <Text>{year}</Text>,
      },

      {
        accessor: 'actions',
        title: t('w.actions'),
        textAlign: 'right',
        sortable: false,
        width: 110,
        render: ({ year, month, gmaPayslipId }) => (
          <Group gap={'sm'} justify="end" wrap={'nowrap'}>
            {gmaPayslipId && (
              <CustomTooltip label={t('w.storedAtGma')} position={'left'}>
                <Image
                  src={'/images/features/gerer_mes_affaires.png'}
                  w={20}
                  h={20}
                />
              </CustomTooltip>
            )}
            <CustomTooltip label={t('w.see')}>
              <ActionIcon
                variant={'subtle'}
                size="sm"
                onClick={() =>
                  handleClickPreviewPayslip(month, year, gmaPayslipId)
                }
              >
                <IconEye />
              </ActionIcon>
            </CustomTooltip>
            <CustomTooltip label={t('w.download')}>
              <ActionIcon
                variant={'subtle'}
                size="sm"
                onClick={() =>
                  handleLoadPayslipClick(year, month, gmaPayslipId)
                }
              >
                <IconDownload />
              </ActionIcon>
            </CustomTooltip>
          </Group>
        ),
      },
    ],
    []
  );

  function getChoices(): { months: number[]; years: number[] } {
    const months: number[] = [];
    const years: number[] = [];

    payslips.forEach((payslip) => {
      months.push(payslip.month);
      years.push(payslip.year);
    });

    const uniqueMonths = months.filter(
      (month, index) => months.indexOf(month) === index
    );
    const uniqueYears = years.filter(
      (year, index) => years.indexOf(year) === index
    );

    return { months: uniqueMonths, years: uniqueYears };
  }

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    hiddenColumns: getHiddenColumns({
      componentName,
      hiddenColumns: [],
    }),
    updateHiddenColumns,
    filter: getFilter({
      componentName,
      filter: [
        {
          accessor: 'month',
          label: t('w.month'),
          choices: getChoices().months.map((month) => {
            return {
              value: month.toString(),
              label: formatMonthLabel(month),
            };
          }),
          selectedChoices: [],
        },
        {
          accessor: 'year',
          label: t('w.year'),
          choices: getChoices().years.map((year) => {
            return {
              value: year.toString(),
              label: year.toString(),
            };
          }),
          selectedChoices: [],
        },
      ],
    }),
    updateFilter,
  });

  function updateFilter(newFilter: FilterOption[]) {
    const _options = { ...options };
    _options.filter = newFilter;
    saveFilter({ componentName, filter: newFilter });
    setOptions(_options);
  }

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  function sortPaylipsByYear(list: PayslipUser[] | PayslipGma[]) {
    return list.sort((a, b) => b.year - a.year);
  }

  function showAlertPayslipNotFound() {
    showNotification({
      id: 'preview-payslip-not-found',
      title: t('w.warning'),
      message: t('w.payslipNotFound'),
      color: 'orange',
      icon: <IconAlertTriangle />,
    });
  }

  function handleLoadPayslipClick(
    year: number,
    month: number,
    gmaPayslipId?: string
  ): void {
    let xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = function () {
      if (xhr.status === 200) {
        const newBlob = new Blob([this.response], { type: 'application/pdf' });
        const data = window.URL.createObjectURL(newBlob);
        const fileName = `${month}-${year}-${userName}.pdf`;
        let link = document.createElement('a');
        link.href = data;
        link.download = fileName;
        link.target = '_blank';
        link.click();
        window.URL.revokeObjectURL(data);
      }
      if (xhr.status === 404) {
        showAlertPayslipNotFound();
      }
    };
    const gma = getPersistedObject('gma');
    xhr.open(
      'GET',
      gmaPayslips && gmaPayslipId
        ? GererMesAffairesService.getPrivatePayslip(
            userId,
            gmaPayslips.spaceId,
            gmaPayslipId,
            gmaPayslips.accessToken,
            gmaPayslips.refreshToken,
            gma?.scope ? gma.scope : 'collaborator'
          )
        : PayslipService.getUserPayslip(userId, year, month, companyId),
      true
    );
    xhr.setRequestHeader('Authorization', 'Bearer ' + access_token);
    xhr.send();
  }

  function handleClickPreviewPayslip(
    month: number,
    year: number,
    gmaPayslipId?: string
  ): void {
    let xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = function () {
      if (xhr.status === 200) {
        const newBlob = new Blob([this.response], { type: 'application/pdf' });
        const filePath = window.URL.createObjectURL(newBlob);

        let link = document.createElement('a');
        link.href = filePath;
        link.download = `${userName}_${month}-${year}.pdf`;
        link.target = '_blank';

        const title = dayjs(new Date(year, month - 1)).format('MMMM YYYY');

        modals.openConfirmModal({
          id: `${userName}-${month}-${year}-modal`,
          title: (
            <Title size={'h3'} component="p">
              {title}
            </Title>
          ),
          fullScreen: true,
          children: (
            <FileViewer
              id={`${userName}_${month}-${year}`}
              srcUrl={filePath}
              title={`${userName}_${month}-${year}.pdf`}
            />
          ),
          labels: { cancel: t('w.cancel'), confirm: t('w.download') },
          onConfirm: () => link.click(),
        });
      }
      if (xhr.status === 404) {
        showAlertPayslipNotFound();
      }
    };
    const gma = getPersistedObject('gma');
    xhr.open(
      'GET',
      gmaPayslips && gmaPayslipId
        ? GererMesAffairesService.getPrivatePayslip(
            userId,
            gmaPayslips.spaceId,
            gmaPayslipId,
            gmaPayslips.accessToken,
            gmaPayslips.refreshToken,
            gma?.scope ? gma.scope : 'collaborator'
          )
        : PayslipService.getUserPayslip(userId, year, month, companyId),
      true
    );
    xhr.setRequestHeader('Authorization', 'Bearer ' + access_token);
    xhr.send();
  }

  return (
    <Table
      onSelect={(payslips) => setSelectedPayslips(payslips)}
      rows={sortPaylipsByYear(payslips)}
      options={options}
      columns={columns}
      lang={lang as any}
      fetching={loading}
      withTableBorder
      rightCustomActions={rightCustomActions}
    />
  );
}
