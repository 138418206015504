import type { Accountant, Address, CommonEntity } from '../common';

export type CompanyResponse = CommonEntity & {
  name: string;
  address: Address;
  phone?: string;
  fax?: string;
  mail: string;
  siret?: string;
  divisionLabel?: string;
  accountant: Accountant;
  useN2: boolean;
  useAssistant: boolean;
  billingPlanId?: string;
  countryCode?: CountryEnum;
  countryArea?: CountryAreaEnum;
  hasDefaultPayment: boolean;
  healthService?: string;
  codeApe?: string;
};

export enum CountryEnum {
  FR = 'FR', // FRANCE
  BE = 'BE', // BELGIUM
  MR = 'MR', // MAURITANIA
  NE = 'NE', // NIGER
  TG = 'TG', // TOGO
  BI = 'BI', // BURUNDI
  GA = 'GA', // GABON
  CF = 'CF', // CENTRAL AFRICAN REPUBLIC
  TD = 'TD', // CHAD
  RW = 'RW', // RWANDA
  KM = 'KM', // COMOROS
  DJ = 'DJ', // DJIBOUTI
  MG = 'MG', // MADAGASCAR
  SC = 'SC', // SEYCHELLES
  CI = 'CI', // IVORY COAST
  MU = 'MU', // MAURITIUS
  SN = 'SN', // SENEGAL
  CM = 'CM', // CAMEROON
  CD = 'CD', // DEMOCRATIC REPUBLIC OF THE CONGO
  CG = 'CG', // REPUBLIC OF THE CONGO
  BJ = 'BJ', // BENIN
  ML = 'ML', // MALI
  BF = 'BF', // BURKINA FASO
  TN = 'TN', // TUNISIA
  DZ = 'DZ', // ALGERIA
  GN = 'GN', // GUINEA
  GQ = 'GQ', // EQUATORIAL GUINEA
  MA = 'MA', // MAROCCO
}

export enum CountryAreaEnum {
  ALSACE_MOSELLE = 'alsace-moselle',
  GUADELOUPE = 'guadeloupe',
  GUYANE = 'guyane',
  LA_REUNION = 'la-reunion',
  MARTINIQUE = 'martinique',
  MAYOTTE = 'mayotte',
  METROPOLE = 'metropole',
  NOUVELLE_CALEDONIE = 'nouvelle-caledonie',
  POLYNESIE_FRANCAISE = 'polynesie-francaise',
  SAINT_BARTHELEMY = 'saint-barthelemy',
  SAINT_MARTIN = 'saint-martin',
  SAINT_PIERRE_ET_MIQUELON = 'saint-pierre-et-miquelon',
  WALLIS_ET_FUTUNA = 'wallis-et-futuna',
}

export type ImportDivisionResultResponse = {
  nbCreated: number;
  nbErrors: number;
  fileName: string;
  importReportList: ImportDivisionReport[];
};

export type ImportDivisionReport = {
  divisionName: string;
  lineNb: number;
  managerN1Mail: string;
  managerN2Mail: string;
  message: string;
  status: string;
};

export type ImportEmployeeResultResponse = {
  nbCreated: number;
  nbErrors: number;
  fileName: string;
  nbUpdated: number;
  importReportList: ImportEmployeeReport[];
};

export type ImportEmployeeReport = {
  role: string;
  lineNb: number;
  mail: string;
  message: string;
  status: string;
};
