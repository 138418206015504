import 'react-phone-number-input/style.css';

import { Paper, TextInput } from '@mantine/core';
import React, { forwardRef } from 'react';
import PhoneInput, { Country } from 'react-phone-number-input';
import fr from 'react-phone-number-input/locale/fr.json';

import { useAuth } from '../../contexts/AuthProvider';
import { useI18n } from '../../contexts/I18nProvider';
import LabelText from '../LabelText/LabelText';
import s from './PhoneField.module.sass';

function CustomPhoneInput(props: any, ref: any) {
  const { t } = useI18n();
  return (
    <TextInput
      {...props}
      ref={ref}
      variant="unstyled"
      placeholder={t('optional')}
      className={s.textInput}
    />
  );
}

const StyledPhoneField = forwardRef(CustomPhoneInput);

type Props = {
  value?: string;
  disabled?: boolean;
  onChange: (newValue?: string) => void;
};

export default function PhoneField({
  value,
  onChange,
  disabled = false,
}: Props) {
  const { user } = useAuth();
  const { t } = useI18n();
  return (
    <span className={s.root}>
      <LabelText text={t('w.phone')} mb={2} />
      <Paper pl="md" withBorder mt={0}>
        <PhoneInput
          labels={fr}
          international
          countryCallingCodeEditable={false}
          defaultCountry={user?.company?.countryCode as Country}
          value={value}
          onChange={onChange}
          inputComponent={StyledPhoneField}
          disabled={disabled}
        />
      </Paper>
    </span>
  );
}
