import { Button, Grid } from '@mantine/core';
import { IconEdit } from '@tabler/icons-react';
import React from 'react';

import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import {
  BuiltInPermissions,
  ScopeEnum,
} from '../../../types/api/response/role';
import type { UserResponse } from '../../../types/api/response/user';
import { hasPermission, isAccountant } from '../../../utils/authorization';

type Props = {
  userProfile: UserResponse;
  onClick: () => void;
};

export default function EditUserProfileButtonContainer({
  userProfile,
  onClick,
}: Props) {
  const { user } = useAuth();
  const { t } = useI18n();
  return (
    <Grid.Col span={2}>
      {(hasPermission(
        {
          permission: BuiltInPermissions.ConfigureEmployees,
          scope: ScopeEnum.ALL,
        },
        user
      ) ||
        isAccountant(user, userProfile.company?.id!)) && (
        <Button onClick={onClick} leftSection={<IconEdit />}>
          {t('w.edit')}
        </Button>
      )}
    </Grid.Col>
  );
}
