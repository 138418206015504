import { Box, Button, Group, Space, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { modals } from '@mantine/modals';
import React from 'react';

import { useI18n } from '../../contexts/I18nProvider';
import type { LeaveEvent } from './DayEventsCell';

type Props = {
  event: LeaveEvent;
  onEventDeleted: () => void;
};

export default function DeleteEvent({ event, onEventDeleted }: Props) {
  const { t } = useI18n();

  const eventToDeleteForm = useForm({});

  function deleteEvent() {
    onEventDeleted();
    modals.close('DeleteEvent');
  }

  return (
    <Box>
      <Text fz={'sm'} fw={'bold'} c={'dimmed'}>
        {t('w.confirmDeletion')}
      </Text>
      <Space h={'xl'} />
      <form onSubmit={eventToDeleteForm.onSubmit(() => deleteEvent())}>
        {t('dailyWork.event.confirmDelete.intro')} <br />
        {t(
          'w.eventFromTo',
          event.leaveType.name,
          event.startDate.utc(false).format('DD/MM/YYYY'),
          event.startDate.utc(false).format('HH:mm'),
          event.endDate.utc(false).format('HH:mm')
        )}
        <Space h={'xl'} />
        <Group justify={'flex-end'}>
          <Button type={'submit'}>{t('w.validate')}</Button>
        </Group>
      </form>
    </Box>
  );
}
