import type { WhiteLabel } from '../types/types';
import {
  MS_WHITE_LABEL_ENDPOINT,
  MS_WHITE_LABEL_ID,
} from '../variables/GlobalVariables';
import request from './AxiosClient';

function getWhiteLabel(paymentAccountId: string): Promise<WhiteLabel> {
  return request
    .get(`${MS_WHITE_LABEL_ENDPOINT}/whiteLabel/${paymentAccountId}`)
    .then((res) => res?.data);
}

function checkDNS(dnsToCheck: string) {
  return request
    .get(`${MS_WHITE_LABEL_ENDPOINT}/dnsChecker`, {
      params: { dnsToCheck },
    })
    .then((res) => res?.data);
}

function getMainUri() {
  return request
    .get(`${MS_WHITE_LABEL_ENDPOINT}/dnsChecker/mainUri`)
    .then((res) => res?.data);
}

function updateOrCreate(paymentAccountId: string, whiteLabel: WhiteLabel) {
  return request
    .post(
      `${MS_WHITE_LABEL_ENDPOINT}/whiteLabel/${paymentAccountId}`,
      whiteLabel,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then((res) => res?.data);
}

function getActiveModules() {
  return request
    .get(MS_WHITE_LABEL_ENDPOINT + '/whiteLabel/activeModules', {
      params: { id: MS_WHITE_LABEL_ID },
    })
    .then((res) => res?.data);
}

const WhiteLabelService = {
  getWhiteLabel,
  checkDNS,
  getMainUri,
  updateOrCreate,
  getActiveModules,
};

export default WhiteLabelService;
