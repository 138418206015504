import { Options, Table } from '@ckprivate/ckf-ui';
import { ActionIcon, Group, Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconEye, IconTrash, IconX } from '@tabler/icons-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DataTableColumn } from 'mantine-datatable';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import CustomCompanyAvatar from '../../../../components/CustomAvatar/CustomCompanyAvatar';
import CustomTooltip from '../../../../components/CustomTooltip';
import { useI18n } from '../../../../contexts/I18nProvider';
import usePaymentAccounts from '../../../../hooks/usePaymentAccounts';
import PaymentAccountService from '../../../../services/PaymentAccountService';
import type { PaymentCompany } from '../../../../types/types';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
import s from '../../PaymentAccount.module.sass';

type Props = {
  paymentAccountId: string;
  variant?: 'default' | 'small';
};

export default function Companies({
  paymentAccountId,
  variant = 'default',
}: Props) {
  const { t } = useI18n();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { queryKey, get } = usePaymentAccounts();
  const paymentAccount = get(paymentAccountId);
  const { mutate: deleteCompany } = useMutation({
    mutationFn: (variables: { paymentAccountId: string; companyId: string }) =>
      PaymentAccountService.removeCompany(
        variables.paymentAccountId,
        variables.companyId
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      showNotification({
        id: 'payment-account-remove-company-success',
        message: t('w.success'),
        icon: <IconCheck />,
        color: 'green',
      });
    },
    onError: (error) =>
      showNotification({
        id: 'payment-account-remove-company-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        icon: <IconX />,
        color: 'red',
      }),
  });
  const options: Options = useMemo(
    () => ({
      sort: { columnAccessor: 'name', direction: 'asc' },
      search: {
        accessor: 'name',
        label: t('w.name'),
      },
    }),
    []
  );
  const columns: DataTableColumn<PaymentCompany>[] = useMemo(
    () => [
      {
        accessor: 'name',
        title: t('w.name'),
        draggable: true,
        resizable: true,
        sortable: true,
        render: ({ id, name }: PaymentCompany) => (
          <div className={s.flexItem}>
            <CustomCompanyAvatar companyId={id} /> {name}
          </div>
        ),
      },
      {
        accessor: 'actions',
        title: t('w.actions'),
        draggable: true,
        resizable: true,
        sortable: true,
        textAlign: 'right',
        render: (company: PaymentCompany) => (
          <Group gap={4} justify="end" wrap={'nowrap'}>
            <CustomTooltip label={t('w.see')}>
              <ActionIcon
                variant={'subtle'}
                size="sm"
                color="green"
                onClick={() => onCompanyClick(company)}
              >
                <IconEye size={16} />
              </ActionIcon>
            </CustomTooltip>
            <CustomTooltip label={t('w.delete')}>
              <ActionIcon
                variant={'subtle'}
                size="sm"
                color="red"
                onClick={() => onCompanyDeleteClick(company)}
              >
                <IconTrash size={16} />
              </ActionIcon>
            </CustomTooltip>
          </Group>
        ),
      },
    ],
    []
  );

  function onCompanyClick(company: PaymentCompany) {
    modals.closeAll();
    navigate(`/company/${company.id}/home`);
  }

  function onCompanyDeleteClick(company: PaymentCompany) {
    modals.openConfirmModal({
      modalId: 'DeleteCompany',
      children: (
        <Text size="sm">
          {t('paymentAccount.removeCompanyModalContent', company.name)}
        </Text>
      ),
      m: 'xl',
      labels: { confirm: t('w.yes'), cancel: t('w.cancel') },
      onConfirm: () => confirmDeleteCompany(company),
      withCloseButton: false,
    });
  }

  function confirmDeleteCompany(company: PaymentCompany) {
    deleteCompany({
      paymentAccountId: paymentAccountId,
      companyId: company.id,
    });
  }

  return (
    <Table
      withTableBorder={false}
      height={variant === 'default' ? undefined : 'auto'}
      options={variant === 'default' ? options : undefined}
      withPagination={variant === 'default'}
      columns={columns}
      fetching={!paymentAccount}
      rows={paymentAccount?.companies?.filter((elt) => elt.active)}
    />
  );
}
