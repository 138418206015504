import { Select, SelectProps } from '@mantine/core';
import * as React from 'react';

import LabelText from '../../../../components/LabelText/LabelText';
import { useI18n } from '../../../../contexts/I18nProvider';
import { CountryAreaEnum } from '../../../../types/api/response/company';

type Props = {
  onChange?: (value: CountryAreaEnum) => void;
  value?: CountryAreaEnum;
};

export function FranceAreaSelect({
  onChange,
  value,
  ...restProps
}: Props & SelectProps) {
  const { t } = useI18n();

  return (
    <Select
      searchable
      data={Object.entries(CountryAreaEnum).map(([value, label]) => ({
        value,
        label: t(`franceArea.${label}`),
      }))}
      label={<LabelText text={t('w.area')} />}
      value={value || CountryAreaEnum.METROPOLE}
      onChange={(value) => {
        onChange?.(value as CountryAreaEnum);
      }}
      {...restProps}
    />
  );
}
