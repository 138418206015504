import React from 'react';

import type { UserResponse } from '../../../../types/api/response/user';
import DailyWorkMode from './DailyWorkMode';
import FixedPriceManagerMode from './FixedPriceManagerMode';

type Props = {
  userProfile: UserResponse;
};

export default function UserActivities({ userProfile }: Props) {
  if (userProfile.fixedPriceManager)
    return <FixedPriceManagerMode userProfile={userProfile} />;

  return <DailyWorkMode userProfile={userProfile} />;
}
