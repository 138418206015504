import { Drawer } from '@mantine/core';
import { MantineSize } from '@mantine/core/lib/core';
import { ReactNode } from 'react';

type Props = {
  opened: boolean;
  onClose: () => void;
  children: ReactNode;
  position?: 'bottom' | 'left' | 'right' | 'top';
  title?: ReactNode;
  size?: MantineSize | (string & {}) | number;
};

export default function CustomDrawer({
  opened,
  onClose,
  children,
  position = 'right',
  title,
  size,
}: Props) {
  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      position={position}
      title={title}
      size={size}
      styles={{ body: { height: '92vh' } }}
    >
      {children}
    </Drawer>
  );
}
