export enum UrssafModule {
  SALARIE = 'simulateur-embauche',
  ENTREPRISE_INDIVIDUELLE = 'simulateur-EI',
  EIRL = 'simulateur-EIRL',
  SAS_U = 'simulateur-assimilesalarie',
  EURL = 'simulateur-eurl',
  AUTO_ENTREPRENEUR = 'simulateur-autoentrepreneur',
  INDEPENDANT = 'simulateur-independant',
  ARTISTE_AUTEUR = 'simulateur-artiste-auteur',
  CHOMAGE_PARTIEL = 'simulateur-chomage-partiel',
  COMPARAISON_DES_STATUTS = 'comparaison-statuts',
  PHARMACIEN = 'pharmacien',
  MEDECIN = 'medecin',
  CHIRURGIEN_DENTISTE = 'chirurgien-dentiste',
  SAGE_FEMME = 'sage-femme',
  AUXILIAIRE_MEDICAL = 'auxiliaire-medical',
  AVOCAT = 'avocat',
  EXPERT_COMPTABLE = 'expert-comptable',
  PROFESSION_LIBERALE = 'profession-liberale',
  DIVIDENDES = 'dividendes',
  COUT_DE_CREATION = 'cout-creation-entreprise',
  IMPOT_SUR_LES_SOCIETES = 'impot-societe',
  CIPAV = 'cipav',
  REDUCTION_GENERALE = 'simulateur-reduction-generale',
  CHOIX_DU_STATUT = 'choix-statut-juridique',
  DECLARATION_DES_CHARGES_SOCIALES_DEDUCTIBLES = 'déclaration-charges-sociales-indépendant',
  RECHERCHE_DE_CODE_APE = 'recherche-code-ape',
}
