import { Box, Button, Group, Space, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import React from 'react';

import LabelText from '../../../../components/LabelText/LabelText';
import { useI18n } from '../../../../contexts/I18nProvider';
import ExpenseReportService from '../../../../services/ExpenseReportService';
import handleErrorMessage from '../../../../utils/handleErrorMessage';

type Props = {
  companyId: string;
  expenseReportId: string;
  refresh: () => void;
  userFullname: string;
};

export default function RejectForm({
  companyId,
  expenseReportId,
  refresh,
  userFullname,
}: Props) {
  const { t } = useI18n();

  const form = useForm({
    initialValues: {
      comment: '',
    },
  });

  const { mutate: mutateReject } = useMutation({
    mutationFn: (comment: string) =>
      ExpenseReportService.reject(companyId, expenseReportId, comment),
    onSuccess: () => {
      refresh();
      modals.close(`reject-${expenseReportId}`);
      showNotification({
        id: 'reject-expense-report-successful',
        message: t('w.success'),
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: (error) =>
      showNotification({
        id: 'reject-expense-report-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  return (
    <Box>
      <Text fz={'sm'} fw={'bold'} c={'dimmed'}>
        {t(
          'expenseReport.request.confirmReject.askForComment',
          `${userFullname}`
        )}
      </Text>
      <Space h={'xl'} />
      <form onSubmit={form.onSubmit((values) => mutateReject(values.comment))}>
        <TextInput
          required
          label={<LabelText text={t('w.comment')} />}
          placeholder={t('w.required')}
          {...form.getInputProps('comment')}
        />

        <Space h={'xl'} />
        <Group justify={'flex-end'}>
          <Button type={'submit'}>
            {t('expenseReport.request.confirmReject.rejectRequest')}
          </Button>
        </Group>
      </form>
    </Box>
  );
}
