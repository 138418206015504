import type { CounterTypePayload } from '../types/api/payload/counterType';
import type {
  CounterType,
  CounterTypeResponse,
} from '../types/api/response/counterType';
import { MS_PLANNING_ENDPOINT } from '../variables/GlobalVariables';
import request from './AxiosClient';

function list(companyId: string): Promise<CounterTypeResponse[]> {
  return request
    .get(`${MS_PLANNING_ENDPOINT}/v2/counter/type/${companyId}`)
    .then((res) => res?.data);
}

function remove(companyId: string, counterKeyToDelete: string): Promise<void> {
  return request
    .delete(
      `${MS_PLANNING_ENDPOINT}/v2/counter/type/${companyId}/${counterKeyToDelete}`
    )
    .then((res) => res?.data);
}

function update(
  companyId: string,
  data: CounterType
): Promise<CounterTypeResponse> {
  return request
    .post(`${MS_PLANNING_ENDPOINT}/v2/counter/type/${companyId}`, data)
    .then((res) => res?.data);
}

function create(
  companyId: string,
  data: CounterTypePayload
): Promise<CounterTypeResponse> {
  return request
    .post(`${MS_PLANNING_ENDPOINT}/v2/counter/type/${companyId}`, data)
    .then((res) => res?.data);
}

const CounterTypeService = {
  list,
  remove,
  update,
  create,
};

export default CounterTypeService;
