import { Flex, Select, SimpleGrid, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import React, { useMemo } from 'react';

import ButtonSave from '../../../../../../components/ButtonSave';
import LabelText from '../../../../../../components/LabelText/LabelText';
import { useAuth } from '../../../../../../contexts/AuthProvider';
import { useI18n } from '../../../../../../contexts/I18nProvider';
import useFetchUsers from '../../../../../../hooks/useFetchUsers';
import EndOfMonthService from '../../../../../../services/EndOfMonthService';
import { UpdateMailRequest } from '../../../../../../types/api/payload/endMonth';
import { NO_WORK_UNIT } from '../../../../../../variables/GlobalVariables';

type Props = {
  id: string;
  companyId: string;
};

interface FormValues {
  mail: string;
  unit: string;
}

export default function MailAndUnitForm({ id, companyId }: Props) {
  const { t } = useI18n();
  const { user } = useAuth();
  const { divisions } = useFetchUsers(companyId);
  const form = useForm<FormValues>({
    initialValues: {
      mail: '',
      unit: '',
    },
  });

  const divisionsSelect = useMemo(() => {
    if (!divisions) {
      return [];
    }
    return divisions.map((division) => {
      return {
        value: division.id,
        label:
          division.name === NO_WORK_UNIT ? t('NO_WORK_UNIT') : division.name,
      };
    });
  }, [divisions]);

  function handleUpdateMailAndUnit(
    values: ReturnType<(values: FormValues) => FormValues>
  ) {
    if (module) {
      addMail({
        payload: {
          divisionId: values.unit,
          mail: values.mail,
          userId: id,
        },
      });
    }
  }

  const { mutate: addMail } = useMutation({
    mutationFn: (variables: { payload: UpdateMailRequest }) =>
      EndOfMonthService.addMail(companyId, variables.payload),
    onSuccess: () => {
      showNotification({
        id: `success-add-mail-openpaye-user`,
        message: t('w.success'),
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: () =>
      showNotification({
        id: `error-add-mail-openpaye-user`,
        title: t('w.error'),
        message: t('error.updateModule'),
        color: 'red',
        icon: <IconX />,
      }),
  });

  return (
    <form
      onSubmit={form.onSubmit((values) =>
        handleUpdateMailAndUnit({ mail: values.mail, unit: values.unit })
      )}
    >
      <SimpleGrid cols={1}>
        <TextInput
          required
          label={<LabelText text={t('w.enterEmail')} />}
          {...form.getInputProps('mail')}
        />
        <Select
          required
          data={divisionsSelect}
          label={
            <LabelText
              text={
                user.company?.divisionLabel
                  ? user.company.divisionLabel
                  : t('w.assignToAWorkUnit')
              }
            />
          }
          placeholder={t('w.select')}
          {...form.getInputProps('unit')}
        />
      </SimpleGrid>
      <Flex
        justify={'flex-end'}
        gap={'md'}
        direction="row"
        wrap="wrap"
        mt={'md'}
      >
        <ButtonSave />
      </Flex>
    </form>
  );
}
