import { ActionIcon, Group, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconPencilCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import React from 'react';

import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import useCompany from '../../../../hooks/useCompany';
import CompanyService from '../../../../services/CompanyService';
import type { UpdateCompanyRequest } from '../../../../types/api/payload/company';
import handleErrorMessage from '../../../../utils/handleErrorMessage';

type Props = {
  label: string | undefined;
  toggle: () => void;
  refetchCompanyData: () => void;
};

export default function WorkUnitLabelForm({
  label,
  toggle,
  refetchCompanyData,
}: Props) {
  const { user } = useAuth();
  const { id: companyId, company } = useCompany(user);
  const { t } = useI18n();

  const form = useForm<UpdateCompanyRequest>({
    initialValues: {
      divisionLabel: label || '',
      useN2: undefined,
      useAssistant: undefined,
    },
  });

  const { mutate: updateDivision } = useMutation({
    mutationFn: (variables: { divisionLabel: string | undefined }) =>
      CompanyService.updateCompany(companyId, {
        divisionLabel: variables.divisionLabel,
        useN2: company?.useN2,
        useAssistant: company?.useAssistant,
      }),
    onSuccess: (data) => {
      toggle();
      refetchCompanyData();
      showNotification({
        id: `update-divisionLabel-${data?.id}-success`,
        title: t('w.success'),
        message: t('success.division.updated', data?.name),
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: (error) => {
      toggle();
      showNotification({
        id: `update-divisionLabel-error`,
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      });
    },
  });

  return (
    <form
      onSubmit={form.onSubmit((values) =>
        updateDivision({ divisionLabel: values.divisionLabel })
      )}
    >
      <Group>
        <TextInput {...form.getInputProps('divisionLabel')} />
        <ActionIcon variant={'subtle'} color={'green'} type={'submit'}>
          <IconPencilCheck />
        </ActionIcon>
      </Group>
    </form>
  );
}
