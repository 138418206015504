import { Button, Flex, Group, NumberInput, SimpleGrid } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs, { type Dayjs } from 'dayjs';
import * as React from 'react';

import ConfirmValidateEmployeesList from '../../../../components/ConfirmValidateEmployeesList';
import { useI18n } from '../../../../contexts/I18nProvider';
import CounterUserService from '../../../../services/CounterUserService';
import { UpdateCounterUserPayload } from '../../../../types/api/payload/counterUser';
import { CounterType } from '../../../../types/api/response/counterType';
import { UsersCountersData } from './UsersCounters';

type Props = {
  counterType: CounterType;
  selectedUsers: UsersCountersData[];
  refresh: () => void;
};

type FormValues = {
  value: number;
  date: Dayjs;
};

export function BulkUpdatesCounters({
  counterType,
  selectedUsers,
  refresh,
}: Props) {
  const { t } = useI18n();
  const queryClient = useQueryClient();

  const form = useForm<FormValues>({
    initialValues: {
      value: 0,
      date: dayjs(),
    },
  });

  const { mutateAsync } = useMutation({
    mutationFn: (payload: UpdateCounterUserPayload & { userId: string }) =>
      CounterUserService.update(payload.userId, payload),
  });

  async function onUpdateClick(values: FormValues, isIncrement: boolean) {
    const promises: any[] = [];
    selectedUsers.forEach(({ userId }) => {
      promises.push(
        mutateAsync({
          userId,
          counterTypeId: counterType.id,
          value: values.value,
          date: dayjs(values.date).format('DD-MM-YYYY'),
          isIncrement,
        })
      );
    });
    await Promise.all(promises);

    showNotification({
      id: 'bulk-updates',
      message: t('w.success'),
      color: 'green',
      icon: <IconCheck />,
    });
    selectedUsers.forEach(({ userId }) => {
      queryClient.invalidateQueries({
        queryKey: ['CounterUserService.getByUser', userId],
      });
    });

    modals.close('bulk-update-counters-modal');
    refresh();
  }

  return (
    <>
      <Group mb="md">
        {selectedUsers?.map((user) => (
          <ConfirmValidateEmployeesList
            key={user.userId}
            userId={user.userId}
            userFullname={user.userFullname}
          />
        ))}
      </Group>
      <SimpleGrid cols={2}>
        <NumberInput
          required
          mb="md"
          label={t('w.value')}
          {...form.getInputProps('value')}
        />

        <DatePickerInput
          required
          {...form.getInputProps('date')}
          valueFormat="DD/MM/YYYY"
          label={t('w.at')}
        />
      </SimpleGrid>

      <Flex justify={'flex-end'} mt="md" gap="md">
        <Button onClick={() => onUpdateClick(form.values, true)}>
          {t('w.increment')}
        </Button>
        <Button onClick={() => onUpdateClick(form.values, false)}>
          {t('w.overwrite')}
        </Button>
      </Flex>
    </>
  );
}
