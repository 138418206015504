import {
  Anchor,
  Button,
  Container,
  Flex,
  Grid,
  LoadingOverlay,
  Stack,
  Text,
  TextInput,
  Title,
  Tooltip,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import CustomLink from '../../components/CustomLink';
import CustomTooltip from '../../components/CustomTooltip';
import CustomTransition from '../../components/CustomTransition';
import LabelText from '../../components/LabelText/LabelText';
import Logo from '../../components/Logo';
import PhoneField from '../../components/PhoneField/PhoneField';
import { useI18n } from '../../contexts/I18nProvider';
import OauthServiceMS from '../../services/OauthServiceMS';
import handleErrorMessage from '../../utils/handleErrorMessage';
import { MS_WHITE_LABEL_ID } from '../../variables/GlobalVariables';
import InputEmail from '../login/components/InputEmail';
import coffreFort from './logos/features/coffre-fort.png';
import dailiesWork from './logos/features/dailiesWork.png';
import expenseReports from './logos/features/expenseReports.png';
import leavesAndAbsences from './logos/features/leavesAndAbsences.png';
import payroll from './logos/features/payroll.png';
import personalSpace from './logos/features/personalSpace.png';

type FormValues = {
  email: string | null;
  lastname: string | null;
  firstname: string | null;
  userPhone: string | null;
  linkId: string | null;
};

export default function SignUp() {
  const { t } = useI18n();
  const [searchParams] = useSearchParams();
  const [opened, setOpened] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const navigate = useNavigate();

  const userAccountForm = useForm<FormValues>({
    initialValues: {
      email: searchParams.get('email') ? searchParams.get('email') : '',
      lastname: searchParams.get('lastname')
        ? searchParams.get('lastname')
        : '',
      firstname: searchParams.get('firstname')
        ? searchParams.get('firstname')
        : '',
      userPhone: searchParams.get('phone') ? searchParams.get('phone') : '',
      linkId: searchParams.get('linkId') ? searchParams.get('linkId') : '',
    },
    validate: {
      email: (val) =>
        val && (/^\S+@\S+$/.test(val) ? null : t('invalid.email')),
    },
  });

  useEffect(() => {
    setTimeout(() => setOpened(true), 100);
  }, []);

  const { mutate: createNewOauthUser, isLoading: isCreateNewOAuthUserLoading } =
    useMutation({
      mutationFn: (variables: FormValues) =>
        OauthServiceMS.createNewOauthUser(
          variables.email,
          variables.lastname,
          variables.firstname,
          variables.userPhone,
          variables.linkId
        ),
      onSuccess: () => {
        setIsDisabled(true);
        showNotification({
          id: 'success-subscribe',
          title: t('w.success'),
          message: t('success.subscribeCompanyMessage'),
          color: 'green',
          icon: <IconX />,
        });
        navigate(`/sign-up/info`);
      },
      onError: (error: any) => {
        if (error?.response?.data?.error_key === 'user_already_exists') {
          userAccountForm.setFieldError(
            'email',
            t('w.userAlreadyExistsWithThisEmail')
          );
          showNotification({
            id: 'failed-subscribe',
            title: t('w.error'),
            message: t('w.userAlreadyExistsWithThisEmail'),
            color: 'red',
            icon: <IconX />,
          });
        } else {
          userAccountForm.clearFieldError('email');
          showNotification({
            id: 'failed-subscribe',
            title: t('w.error'),
            message: handleErrorMessage(error, t),
            color: 'red',
            icon: <IconX />,
          });
        }
      },
    });

  const featuresLogos = useMemo(
    () => [
      {
        src: leavesAndAbsences,
        alt: 'leavesAndAbsences',
        width: '120px',
        height: '120px',
        title: t('w.leavesAndAbsences'),
      },
      {
        src: dailiesWork,
        alt: 'dailiesWork',
        width: '120px',
        height: '120px',
        title: t('w.managementWorkingTime'),
      },
      {
        src: payroll,
        alt: 'payrollPreparation',
        width: '120px',
        height: '109.28px',
        title: t('w.payrollPreparation'),
      },
      {
        src: coffreFort,
        alt: 'digitalSafe',
        width: '115.66px',
        height: '119.99px',
        title: t('w.digitalSafe'),
      },
      {
        src: personalSpace,
        alt: 'personalSpace',
        width: '120px',
        height: '113.1px',
        title: t('w.employeeSpace'),
      },
      {
        src: expenseReports,
        alt: 'expense-reports',
        width: '73.99px',
        height: '119.57px',
        title: t('w.expense-reports'),
      },
    ],
    []
  );

  function handleCreateNewOauthUserSubmit(values: FormValues) {
    createNewOauthUser(values);
  }

  return (
    <Grid style={{ height: '100vh' }}>
      <Grid.Col span={{ base: 12, lg: 6 }} style={{ backgroundColor: 'white' }}>
        <Flex justify={'space-between'} pt={20} px={20}>
          <Logo />
          <Flex align={'center'} gap={'xs'}>
            <Text fz={12}>{t('w.alreadyHaveAnAccount')}</Text>
            <CustomLink navigation={'/'} text={t('w.login')} />
          </Flex>
        </Flex>
        <Container
          display={'grid'}
          size={'sm'}
          style={{ height: '100vh', alignContent: 'center' }}
        >
          <form
            onSubmit={userAccountForm.onSubmit((values) =>
              handleCreateNewOauthUserSubmit(values)
            )}
          >
            <LoadingOverlay visible={isCreateNewOAuthUserLoading} />
            <CustomTransition
              opened={opened}
              transition={'slide-right'}
              duration={600}
              timingFunction={'ease'}
            >
              <Stack mx={'xl'}>
                <Title size={'h2'} c={'hifivework.9'}>
                  {t('w.createYourAccount')}
                </Title>
                <Grid>
                  <Grid.Col span={{ base: 12, md: 6 }}>
                    <TextInput
                      label={<LabelText text={t('w.name')} />}
                      placeholder={t('w.yourLastname')}
                      required
                      {...userAccountForm.getInputProps('lastname')}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 6 }}>
                    <TextInput
                      label={<LabelText text={t('w.firstname')} />}
                      placeholder={t('w.yourFirstname')}
                      required
                      {...userAccountForm.getInputProps('firstname')}
                    />
                  </Grid.Col>
                </Grid>
                <Grid>
                  <Grid.Col span={{ base: 12, md: 6 }}>
                    <CustomTooltip
                      label={t('w.signUpPersonalEmailMessage')}
                      position={'bottom'}
                    >
                      <span>
                        <InputEmail
                          value={
                            userAccountForm.values.email
                              ? userAccountForm.values.email
                              : ''
                          }
                          onChange={(event) =>
                            userAccountForm.setFieldValue(
                              'email',
                              event.currentTarget.value
                            )
                          }
                          error={userAccountForm.errors.email}
                        />
                      </span>
                    </CustomTooltip>
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 6 }}>
                    <PhoneField
                      onChange={(value) =>
                        userAccountForm.setFieldValue(
                          'userPhone',
                          value as string
                        )
                      }
                      value={
                        userAccountForm.values.userPhone
                          ? userAccountForm.values.userPhone
                          : ''
                      }
                    />
                  </Grid.Col>
                </Grid>
                <Text fz={'md'}>
                  {t('w.privacyPolicyTextPart1')}
                  <Anchor
                    onClick={() =>
                      window.open(
                        MS_WHITE_LABEL_ID === 'AFRICAPAIERH'
                          ? 'https://africapaierh.com/politique-de-confidentialite'
                          : 'https://www.hifivework.com/politique-de-confidentialite',
                        '_blank'
                      )
                    }
                  >
                    {t('w.privacyPolicy')}
                  </Anchor>
                  {t('w.privacyPolicyTextPart2')}
                </Text>
                <Button disabled={isDisabled} type={'submit'}>
                  {t('w.signUp')}
                </Button>
              </Stack>
            </CustomTransition>
          </form>
        </Container>
      </Grid.Col>
      <Grid.Col span={{ base: 12, lg: 6 }}>
        <Container
          display={'grid'}
          size={'sm'}
          style={{ height: '100vh', alignContent: 'center' }}
        >
          <CustomTransition
            opened={opened}
            transition={'slide-left'}
            duration={600}
            timingFunction={'ease'}
          >
            <Flex direction={'column'} justify={'center'} gap={'30px'} pt={100}>
              <Title order={1} size={'28px'} c={'hifivework.4'}>
                {t('w.yourSolution')}
              </Title>
              <Text fz={'lg'}>{t('w.signUpMessageInfo')}</Text>
              <Grid>
                {featuresLogos.map((logo, index) => (
                  <Grid.Col
                    span={{ base: 12, sm: 4 }}
                    key={index}
                    pl={33}
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <Tooltip
                      label={<Text fw={'bold'}>{logo.title}</Text>}
                      color={'hifivework'}
                      position={'bottom'}
                      withArrow
                      style={{ borderRadius: 8 }}
                    >
                      <img
                        src={logo.src}
                        alt={logo.alt}
                        width={logo.width}
                        height={logo.height}
                      />
                    </Tooltip>
                  </Grid.Col>
                ))}
              </Grid>
            </Flex>
          </CustomTransition>
        </Container>
      </Grid.Col>
    </Grid>
  );
}
