import { useQuery } from '@tanstack/react-query';
import React from 'react';

import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import ScreenWrapper from '../../../core/layouts/components/ScreenWrapper';
import useCompany from '../../../hooks/useCompany';
import { useQueryParams } from '../../../hooks/useQueryParams';
import LeaveService from '../../../services/LeaveService';
import type { CompanyResponse } from '../../../types/api/response/company';
import LeavesPendingValidationsList from './components/LeavesPendingValidationsList';

type Props = {
  divisionId?: string;
  company?: CompanyResponse;
};

export default function LeavesAndAbsencesPendingRequests({
  divisionId,
  company,
}: Props) {
  const { t } = useI18n();
  const { user } = useAuth();
  const { id: companyId } = useCompany(user);
  let query = useQueryParams();
  const userIdQueryParams = query.get('user');

  const {
    data: leavesList,
    isLoading: leaveslistIsLoading,
    refetch: refetchLeavesList,
  } = useQuery({
    queryKey: [
      'LeaveService.getLeavesPendingValidation',
      company ? company.id : companyId,
    ],
    queryFn: () =>
      LeaveService.getLeavesPendingValidation(company ? company.id : companyId),
  });

  function filterLeavesList() {
    let result = leavesList;
    if (userIdQueryParams) {
      result = result?.filter((item) => item.who === userIdQueryParams);
    }

    if (divisionId) {
      return result?.filter((leave) => leave.division?.id === divisionId);
    }
    return result;
  }

  return (
    <ScreenWrapper title={t('w.leavesAndAbsences')} paper>
      <LeavesPendingValidationsList
        leavesList={filterLeavesList()}
        isLoading={leaveslistIsLoading}
        refetchLeavesList={refetchLeavesList}
      />
    </ScreenWrapper>
  );
}
