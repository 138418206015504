import { BuiltInRoles } from '../types/api/response/role';
import type { TranslateFunction } from '../types/front/common';

export function roleName(t: TranslateFunction, name: BuiltInRoles) {
  if (
    name === BuiltInRoles.ADMINISTRATOR ||
    name === BuiltInRoles.MANAGER ||
    name === BuiltInRoles.DIRECTION ||
    name === BuiltInRoles.EMPLOYEE
  ) {
    return t(`role.${name}`);
  } else {
    return name;
  }
}
