import { Tabs } from '@mantine/core';
import React, { useState } from 'react';

import type { ModuleToEditComponentProps } from '../../../../../components/ModuleToEditModal';
import { useI18n } from '../../../../../contexts/I18nProvider';
import ByParams from './ByParams';
import ByToken from './ByToken';

export default function HfwAzureSyncParams({
  module,
  updateModuleParams,
}: ModuleToEditComponentProps) {
  const { t } = useI18n();
  const [activeTab, setActiveTab] = useState<string | null>('token');

  if (!module) {
    return null;
  }

  return (
    <>
      <Tabs value={activeTab} onChange={setActiveTab}>
        <Tabs.List>
          <Tabs.Tab value="token">Manuel</Tabs.Tab>
          <Tabs.Tab value="params">{t('w.dailySynchronization')}</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="token" pt={'md'}>
          <ByToken module={module} updateModuleParams={updateModuleParams} />
        </Tabs.Panel>
        <Tabs.Panel value="params" pt={'md'}>
          <ByParams module={module} updateModuleParams={updateModuleParams} />
        </Tabs.Panel>
      </Tabs>
    </>
  );
}
