import {
  ActionIcon,
  Group,
  Paper,
  SimpleGrid,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconHeart, IconTrash, IconX } from '@tabler/icons-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React from 'react';

import CustomTooltip from '../../../../components/CustomTooltip';
import { useI18n } from '../../../../contexts/I18nProvider';
import usePaymentAccounts from '../../../../hooks/usePaymentAccounts';
import PaymentAccountService from '../../../../services/PaymentAccountService';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
import Card from '../Overview/components/Card';
import Sepa from '../Overview/components/Sepa';

type Props = {
  paymentAccountId: string;
};

export default function PaymentMode({ paymentAccountId }: Props) {
  const { t } = useI18n();
  const queryClient = useQueryClient();
  const { queryKey, get } = usePaymentAccounts();
  const paymentAccount = get(paymentAccountId);
  const { data: paymentMethods } = useQuery({
    queryKey: ['PaymentAccountService.getPaymentMethods', paymentAccountId],
    queryFn: () => PaymentAccountService.getPaymentMethods(paymentAccountId),
  });

  const {
    mutate: deletePaymentMethod,
    isLoading: isLoadingDeletePaymentMethod,
  } = useMutation({
    mutationFn: (variables: {
      paymentAccountId: string;
      paymentMethodId: string;
    }) =>
      PaymentAccountService.deletePaymentMethod(
        variables.paymentAccountId,
        variables.paymentMethodId
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      queryClient.invalidateQueries({
        queryKey: ['PaymentAccountService.getPaymentMethods', paymentAccountId],
      });
      showNotification({
        id: 'payment-account-remove-payment-method-success',
        message: t('w.success'),
        icon: <IconCheck />,
        color: 'green',
      });
    },
    onError: (error) =>
      showNotification({
        id: 'payment-account-remove-payment-method-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        icon: <IconX />,
        color: 'red',
      }),
  });

  const {
    mutate: setDefaultPaymentMethod,
    isLoading: isLoadingSetDefaultPaymentMethod,
  } = useMutation({
    mutationFn: (variables: {
      paymentAccountId: string;
      paymentMethodId: string;
    }) =>
      PaymentAccountService.setDefaultPaymentMethod(
        variables.paymentAccountId,
        variables.paymentMethodId
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      showNotification({
        id: 'payment-account-set-default-payment-method-success',
        message: t('w.success'),
        icon: <IconCheck />,
        color: 'green',
      });
    },
    onError: (error) =>
      showNotification({
        id: 'payment-account-set-default-payment-method-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        icon: <IconX />,
        color: 'red',
      }),
  });

  function onSetDefaultClick(paymentMethodId: string) {
    setDefaultPaymentMethod({ paymentAccountId, paymentMethodId });
  }

  function onDeleteClick(paymentMethodId: string) {
    deletePaymentMethod({ paymentAccountId, paymentMethodId });
  }

  return (
    <SimpleGrid m={'xs'} cols={{ base: 1, md: 2 }}>
      {paymentMethods?.length !== 0 ? (
        paymentMethods?.map((paymentMethod) => (
          <Paper key={paymentMethod.id} withBorder p={'md'}>
            <Stack align="center">
              {paymentAccount?.defaultPayment?.id === paymentMethod.id ? (
                <Title size="h3">{t('w.paymentMethodUsed')}</Title>
              ) : (
                <Group gap={'md'}>
                  <CustomTooltip label={t('w.use')}>
                    <ActionIcon
                      onClick={() => onSetDefaultClick(paymentMethod.id)}
                      variant={'filled'}
                      color="blue"
                      loading={
                        isLoadingDeletePaymentMethod ||
                        isLoadingSetDefaultPaymentMethod
                      }
                    >
                      <IconHeart />
                    </ActionIcon>
                  </CustomTooltip>
                  <CustomTooltip label={t('w.delete')}>
                    <ActionIcon
                      onClick={() => onDeleteClick(paymentMethod.id)}
                      variant={'filled'}
                      color="red"
                      loading={
                        isLoadingDeletePaymentMethod ||
                        isLoadingSetDefaultPaymentMethod
                      }
                    >
                      <IconTrash />
                    </ActionIcon>
                  </CustomTooltip>
                </Group>
              )}
              {paymentMethod.card && (
                <Card card={paymentMethod.card} name={paymentMethod.name} />
              )}
              {paymentMethod.sepaDebit && (
                <Sepa
                  sepa={paymentMethod.sepaDebit}
                  name={paymentMethod.name}
                />
              )}
            </Stack>
          </Paper>
        ))
      ) : (
        <Text>{t('w.noPaymentMethod')}</Text>
      )}
    </SimpleGrid>
  );
}
