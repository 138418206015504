import { ContractRegime } from '../types/api/response/user';
import type { TranslateFunction } from '../types/front/common';

export function typesRegime(t: TranslateFunction) {
  return [
    { value: '', label: t('w.noValue') },
    { value: ContractRegime.EXECUTIVE, label: t('w.executive') },
    { value: ContractRegime.ETAM, label: t('w.etam') },
    { value: ContractRegime.WORKER, label: t('w.worker') },
    {
      value: ContractRegime.OTHER,
      label: t('w.other'),
    },
  ];
}
