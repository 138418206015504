import { AzureSyncResult } from '../types/types';
import { HFW_MODULES_ENDPOINT } from '../variables/GlobalVariables';
import request from './AxiosClient';

function syncUserAzureModule(
  companyId: string,
  by: 'token' | 'params',
  token?: string
): Promise<AzureSyncResult> {
  return request
    .post(`${HFW_MODULES_ENDPOINT}/v2/modules/azure/${companyId}/sync`, null, {
      params: {
        by,
        token,
      },
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json', // If you receieve JSON response.
      },
    })
    .then((res) => res?.data);
}

const HfwModulesService = {
  syncUserAzureModule,
};

export default HfwModulesService;
