import type { DateStringRequest2 } from '../common';
import type { IdentifiantCompany } from '../response/user';

export type DivisionEndOfMonthRequest = {
  monthConcerned: DateStringRequest2;
  divisionIds: string[];
};

export type ValidateEmployeeLeaveOfMonthQuery = {
  endOfMonthId: string;
  employeeId: string;
  leaveId: string;
  approved: boolean;
};

export type UpdateEmployeeEndOfMonthCommentQuery = {
  endOfMonthId: string;
  employeeId: string;
  comment: string | null;
};

export type ValidateListEmployeesEndOfMonthQuery = {
  endOfMonthId: string;
  employeesIds: string[];
};

export enum EndMonthExportType {
  XLSX = 'XLSX',
  Quadra = 'Quadra',
  Silae = 'Silae',
  DIAPAIE = 'DIAPAIE',
  ISAPAYE = 'ISAPAYE',
  SynchroOpenPaye = 'SynchroOpenPaye',
  ExportCegidExpert = 'ExportCegidExpert',
}

export type UpdateMailRequest = {
  divisionId: string;
  mail: string;
  userId: string;
};

export type UpdateIdentifiantsCompanyRequest = {
  userId: string;
  identifiantsCompany: IdentifiantCompany;
};
