import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import ScreenWrapper from '../../../core/layouts/components/ScreenWrapper';
import OauthServiceMS from '../../../services/OauthServiceMS';
import UserAccountSettingsTabs from '../component/UserAccountSettingsTabs';

export default function UserAccountSettings() {
  const { t } = useI18n();
  const { access_token } = useAuth();

  const { data } = useQuery({
    queryKey: ['getProfileToOauthUser.OauthServiceMS', access_token],
    queryFn: () => OauthServiceMS.getProfileToOauthUser(access_token),
  });

  const oAuthUser = useMemo(() => {
    if (!data) {
      return null;
    }
    return data;
  }, [data]);

  return (
    <ScreenWrapper title={t('w.accountSettings')}>
      <UserAccountSettingsTabs oAuthUser={oAuthUser} />
    </ScreenWrapper>
  );
}
