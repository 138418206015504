import { ActionIcon, Avatar, Group, Stack, Title } from '@mantine/core';
import { modals } from '@mantine/modals';
import {
  IconDeviceLaptop,
  IconFavicon,
  IconServer,
  IconSettings,
} from '@tabler/icons-react';
import React from 'react';

import CustomTooltip from '../../../../components/CustomTooltip';
import { useI18n } from '../../../../contexts/I18nProvider';
import type {
  PaymentAccount,
  WhiteLabel as WhiteLabelObject,
} from '../../../../types/types';
import WhiteLabel from '../DetailedView/WhiteLabel';

type Props = {
  paymentAccount: PaymentAccount;
  whiteLabel: WhiteLabelObject;
};

export default function WhiteLabelOverview({
  paymentAccount,
  whiteLabel,
}: Props) {
  const { t } = useI18n();

  function openWhiteLabel() {
    modals.open({
      modalId: 'WhiteLabel',
      size: 'xl',
      m: 'xl',
      withCloseButton: false,
      children: (
        <WhiteLabel
          paymentAccountId={paymentAccount.id}
          whiteLabel={whiteLabel}
        />
      ),
    });
  }

  return (
    <>
      <Group gap={'md'}>
        <CustomTooltip label={t('w.configure')}>
          <ActionIcon onClick={openWhiteLabel} variant={'filled'} color="blue">
            <IconSettings />
          </ActionIcon>
        </CustomTooltip>
      </Group>
      <Avatar
        variant="transparent"
        radius="xs"
        size="xl"
        src={whiteLabel.logoPath}
      >
        <IconFavicon size="sm" />
      </Avatar>
      <Stack>
        <Group>
          <IconServer color={whiteLabel.primaryColor} />
          <Title size="h4">{whiteLabel.name}</Title>
        </Group>
        <Group>
          <IconDeviceLaptop color={whiteLabel.secondaryColor} />
          <Title size="h4">{whiteLabel.dns}</Title>
        </Group>
      </Stack>
    </>
  );
}
