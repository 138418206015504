import { Group } from '@mantine/core';

import type { CompanyResponse } from '../../../../types/api/response/company';
import CompanyCountryFlag from './CompanyCountryFlag';
import CompanyCountryName from './CompanyCountryName';

type Props = {
  company: CompanyResponse;
};

export default function CompanyCountry({ company }: Props) {
  return (
    <Group>
      <CompanyCountryName company={company} />
      <CompanyCountryFlag company={company} />
    </Group>
  );
}
