import { Button, Group, Text } from '@mantine/core';
import type { FileWithPath } from '@mantine/dropzone';
import { useForm } from '@mantine/form';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconFileSpreadsheet, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import React from 'react';

import ButtonCancel from '../../../../components/ButtonCancel';
import ButtonSave from '../../../../components/ButtonSave';
import CustomDropzone from '../../../../components/CustomDropzone/CustomDropzone';
import { useI18n } from '../../../../contexts/I18nProvider';
import DailyWorkService from '../../../../services/DailyWorkService';
import handleErrorMessage from '../../../../utils/handleErrorMessage';

type FormValues = {
  files: FileWithPath[];
};

type Props = {
  exportClick: () => void;
  refresh: () => void;
};

export default function ImportActivitiesForm({ exportClick, refresh }: Props) {
  const { t } = useI18n();

  const { mutate, isLoading } = useMutation({
    mutationFn: (file: FileWithPath) => DailyWorkService.importDailyWork(file),
    onSuccess: () => {
      refresh();
      showNotification({
        id: 'import-activities-success',
        message: t('w.success'),
        color: 'green',
        icon: <IconCheck />,
      });
      modals.closeAll();
    },
    onError: (err) => {
      showNotification({
        id: 'import-activities-error',
        title: t('w.error'),
        message: handleErrorMessage(err, t),
        color: 'red',
        icon: <IconX />,
      });
    },
  });

  const form = useForm<FormValues>({
    initialValues: {
      files: [],
    },
  });
  function resetFile() {
    form.setFieldValue('files', []);
  }

  return (
    <form onSubmit={form.onSubmit((values) => mutate(values.files[0]))}>
      <CustomDropzone
        onDrop={(files) => form.setFieldValue('files', files)}
        files={form.values.files}
        mimeType={'excel'}
        resetFile={resetFile}
      />
      <Text mt="lg" fz={'sm'} c={'dimmed'}>
        {t('w.formattingCondition')}
      </Text>
      <Text fz={'sm'} fw={'bold'} c={'dimmed'}>
        Date, Périodes, Heures travaillées, Heures supplémentaires, Commentaire,
        Statut
      </Text>
      <Group mt="md">
        <Text fz={'sm'} c={'dimmed'}>
          {t('w.downloadTemplateFile')} :
        </Text>
        <Button
          variant={'subtle'}
          onClick={exportClick}
          leftSection={<IconFileSpreadsheet />}
        >
          XLSX
        </Button>
      </Group>

      <Group justify={'flex-end'} mt={'xl'}>
        <ButtonCancel onClose={modals.closeAll} />
        <ButtonSave isLoading={isLoading} />
      </Group>
    </form>
  );
}
