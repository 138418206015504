import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import ScreenWrapper from '../../../core/layouts/components/ScreenWrapper';
import useCompany from '../../../hooks/useCompany';
import ShuttleSheetsMonitoringTable from '../../shuttle-sheets/components/ShuttleSheetsMonitoringTable';

export default function AccountantCustomerShuttleSheet() {
  const { t } = useI18n();
  const { user } = useAuth();
  const { id: companyId } = useCompany(user);
  return (
    <ScreenWrapper title={t('w.customersShuttleSheets')}>
      <ShuttleSheetsMonitoringTable companyId={companyId} isAccountant />
    </ScreenWrapper>
  );
}
