import { ActionIcon, Group, Stack, Text } from '@mantine/core';
import { IconCheck, IconCircleCheckFilled, IconX } from '@tabler/icons-react';

import CustomTooltip from '../../../components/CustomTooltip';
import { useI18n } from '../../../contexts/I18nProvider';

type Props = {
  title: string | undefined;
  subtitle: string | undefined;
  description: string | undefined;
  onValidateClick: () => void;
  onRejectClick: () => void;
};

export default function PlanningLeaveDetails({
  title,
  subtitle,
  description,
  onValidateClick,
  onRejectClick,
}: Props) {
  const { t } = useI18n();
  return (
    <Stack>
      <Group>
        <Group>
          <Text
            fw={'bold'}
            fz={'sm'}
            c={subtitle === t('leave.status.valid') ? 'teal' : 'dimmed'}
          >
            {subtitle}
          </Text>
          {subtitle === t('leave.status.valid') && (
            <IconCircleCheckFilled
              size={15}
              style={{ color: 'var(--mantine-color-green-5)' }}
            />
          )}
        </Group>
        {subtitle === t('leave.status.waiting') && title !== 'Présence' && (
          <Group>
            <CustomTooltip label={t('w.validate')}>
              <ActionIcon
                size={'sm'}
                variant={'subtle'}
                color={'teal'}
                onClick={onValidateClick}
              >
                <IconCheck />
              </ActionIcon>
            </CustomTooltip>
            <CustomTooltip label={t('w.reject')}>
              <ActionIcon
                size={'sm'}
                variant={'subtle'}
                color={'red'}
                onClick={onRejectClick}
              >
                <IconX />
              </ActionIcon>
            </CustomTooltip>
          </Group>
        )}
      </Group>
      <Text fz={'sm'} c={'var(--mantine-color-hifivework-6'}>
        {description}
      </Text>
    </Stack>
  );
}
