import { Badge, Text } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';

import { useI18n } from '../../../contexts/I18nProvider';
import DivisionService from '../../../services/DivisionService';

type Props = {
  divisionId: string;
};

export default function ShuttleSheetDivisionName({ divisionId }: Props) {
  const { t } = useI18n();

  const { data: division } = useQuery({
    queryKey: ['DivisionService.getDivisionById', divisionId],
    queryFn: () => DivisionService.getDivisionById(divisionId),
  });

  if (division) {
    return (
      <Badge
        size={'lg'}
        color={'#FCF0E0'}
        styles={{ label: { textTransform: 'none', color: '#EDB464' } }}
      >
        {division.name}
      </Badge>
    );
  }
  return <Text fz={'sm'}>{t('w.noValue')}</Text>;
}
