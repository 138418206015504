export function getExcludeDivisionIds(
  array1: string[],
  array2: string[]
): string[] {
  const _array1 = [...array1];
  const _array2 = [...array2];

  return _array1.filter(function (obj) {
    return _array2.indexOf(obj) === -1;
  });
}
