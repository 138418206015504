import { Checkbox, CheckboxProps } from '@mantine/core';
import { ReactNode } from 'react';

import CustomTooltip from './CustomTooltip';

type Props = {
  box: {
    label: string;
    value: string;
    checked: boolean;
    description?: ReactNode;
  };
  props?: CheckboxProps;
};

export default function CustomCheckBox({ box, ...props }: Props) {
  return (
    <CustomTooltip label={box.description}>
      <span>
        <Checkbox
          label={box.label}
          checked={box.checked}
          {...props}
          styles={{
            root: {
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
              border: '0.5px solid var(--mantine-color-gray-3)',
              padding: 'var(--mantine-spacing-xs) var(--mantine-spacing-sm)',
              borderRadius: 'var(--mantine-radius-md)',
              fontWeight: 500,
              transition:
                'color 100ms ease, background-color 100ms ease, border-color 100ms ease',
              backgroundColor: box.checked
                ? 'var(--mantine-color-hifivework-1)'
                : undefined,
              color: box.checked ? 'var(--mantine-color-white)' : undefined,
            },
            body: {
              display: 'flex',
              alignItems: 'center',
            },
          }}
        />
      </span>
    </CustomTooltip>
  );
}
