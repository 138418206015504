import { Fieldset, Group, Text } from '@mantine/core';

import classes from './ExpenseReportsUser.module.sass';

export type ExpenseReportInfosWrapperProps = {
  title: string;
  value: string | number;
};

export default function ExpenseReportInfosWrapper({
  title,
  value,
}: ExpenseReportInfosWrapperProps) {
  return (
    <Fieldset
      legend={
        <Text fz={'xs'} fw={'bold'} c={'dimmed'}>
          {title}
        </Text>
      }
      className={classes.wrapper}
    >
      <Group wrap="nowrap">
        <Text fz="sm" fw={600} className={classes.name}>
          {value}
        </Text>
      </Group>
    </Fieldset>
  );
}
