import { Fieldset, Group, Text } from '@mantine/core';
import { JSX } from 'react';

import classes from './WorkUnitsInfos.module.sass';
import WorkUnitsTextInfos from './WorkUnitsTextInfos';

export type CompanyInfosWrapperProps = {
  icon: JSX.Element;
  title: string;
  value: string | number;
};

export default function CompanyInfosWrapper({
  icon,
  title,
  value,
}: CompanyInfosWrapperProps) {
  return (
    <Fieldset
      legend={
        <Text fz={'xs'} fw={'bold'} c={'dimmed'}>
          {title}
        </Text>
      }
      className={classes.wrapper}
    >
      <Group wrap="nowrap">
        {icon}
        <WorkUnitsTextInfos text={value.toString()} />
      </Group>
    </Fieldset>
  );
}
