import { Button, Menu, rem } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconReplace } from '@tabler/icons-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { Fragment } from 'react';

import { useI18n } from '../../../../contexts/I18nProvider';
import ExpenseReportService from '../../../../services/ExpenseReportService';
import { ExpenseStatusState } from '../../../../types/types';
import {
  getBadgeColor,
  getBadgeLabelColor,
} from '../../../../utils/expenseReport';

type Props = {
  companyId: string;
  expenseReportId: string;
  state: ExpenseStatusState;
};

export default function StatusCell({
  companyId,
  expenseReportId,
  state,
}: Props) {
  const { t } = useI18n();
  const queryClient = useQueryClient();
  const statuses = Object.values(ExpenseStatusState);

  const { mutate } = useMutation({
    mutationFn: (newState: ExpenseStatusState) =>
      ExpenseReportService.updateStatus(companyId, expenseReportId, newState),
    onSuccess: (_data, ids) => {
      queryClient.invalidateQueries({
        queryKey: [
          'ExpenseReportService.getExpenseReportsByDivisionsAndMonth',
          companyId,
        ],
      });
      showNotification({
        id: `update-status-expense-reports-${companyId}-${ids.toString()}`,
        message: t('w.success'),
        color: 'green',
        icon: <IconCheck />,
      });
    },
  });

  return (
    <Menu>
      <Menu.Target>
        <Button
          size="xs"
          radius="xl"
          styles={{
            label: {
              color: getBadgeLabelColor(state),
            },
          }}
          color={getBadgeColor(state)}
          rightSection={
            <IconReplace
              style={{ width: rem(14), height: rem(14) }}
              color={getBadgeLabelColor(state)}
            />
          }
        >
          {t(`expenseReport.status.state.${state}`)}
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>{t('w.newStatus')}</Menu.Label>
        {statuses.map((item) => (
          <Fragment key={item}>
            {state !== item && (
              <Menu.Item onClick={() => mutate(item)}>
                {t(`expenseReport.status.state.${item}`)}
              </Menu.Item>
            )}
          </Fragment>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
}
