import {
  ActionIcon,
  FileButton,
  Group,
  Image,
  LoadingOverlay,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconArrowNarrowRight, IconCheck, IconEdit } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { useRef } from 'react';

import CustomTransition from '../../../../components/CustomTransition';
import ActionDeleteIcon from '../../../../components/icons/ActionDeleteIcon';
import { useI18n } from '../../../../contexts/I18nProvider';
import OauthServiceMS from '../../../../services/OauthServiceMS';

interface FormValues {
  file: File | null;
}

export default function MyAccountSettingsAvatarForm() {
  const { t } = useI18n();

  const form = useForm<FormValues>({
    initialValues: {
      file: null,
    },
  });

  const resetRef = useRef<() => void>(null);

  function clearFile() {
    form.setFieldValue('logo', null);
    resetRef.current?.();
  }

  const { mutate: updateOauthUserAvatar, isLoading } = useMutation({
    mutationFn: (variables: { file: File; extension: string }) =>
      OauthServiceMS.updateOauthUserAvatar(variables.file, variables.extension),
    onSuccess: () => {
      showNotification({
        id: 'update-oauth-user-avatar',
        title: t('w.success'),
        message: "L'avatar a bien été mis à jour",
        color: 'green',
        icon: <IconCheck />,
      });
      setTimeout(() => window.location.reload(), 1500);
    },
  });

  function handleUpdateOauthUserAvatar(values: FormValues) {
    if (values.file) {
      updateOauthUserAvatar({
        file: values.file,
        extension: values.file?.name.split('.').pop() || '',
      });
    }
  }

  return (
    <form
      onSubmit={form.onSubmit((values) => handleUpdateOauthUserAvatar(values))}
    >
      <LoadingOverlay visible={isLoading} />
      <Group gap={'xs'}>
        <FileButton
          resetRef={resetRef}
          {...form.getInputProps('file')}
          accept="image/png,image/jpeg"
        >
          {(props) => (
            <ActionIcon variant={'subtle'} {...props}>
              <IconEdit />
            </ActionIcon>
          )}
        </FileButton>
        <CustomTransition
          opened={!!form.values.file}
          transition={'slide-left'}
          duration={400}
          timingFunction={'ease'}
        >
          <Group>
            <IconArrowNarrowRight color={'var(--mantine-color-hifivework-2)'} />
            {form.values.file && (
              <Image
                h={100}
                w={100}
                radius="xl"
                src={URL.createObjectURL(form.values.file)}
              />
            )}
            <ActionDeleteIcon label={t('w.delete')} onClick={clearFile} />
            <ActionIcon type={'submit'} variant={'subtle'} color={'green'}>
              <IconCheck />
            </ActionIcon>
          </Group>
        </CustomTransition>
      </Group>
    </form>
  );
}
