import { FileWithPath } from '@mantine/dropzone';

import type {
  ExpenseReportLabel,
  ExpenseReportObject,
  ExpenseReportType,
  ExpenseStatusState,
  UserExpenseReportSummaryDetail,
} from '../types/types';
import { MS_EXPENSEREPORT_ENDPOINT } from '../variables/GlobalVariables';
import request from './AxiosClient';

export type ExpenseReportDataPayloadData = {
  dateOfExpense?: string;
  amount?: number;
  label?: ExpenseReportLabel;
  type?: ExpenseReportType;
  currency?: string;
  comment?: string;
  internal?: string;
  external?: string;
  additionVATList?: number[];
  additionValueVATList?: number[];
  placeOfDeparture?: string;
  placeOfArrival?: string;
  distance?: number;
  state?: ExpenseStatusState;
};

function create(
  userId: string,
  data: ExpenseReportDataPayloadData,
  file?: FileWithPath | null
) {
  const formData = new FormData();
  formData.append(
    'data',
    new Blob([JSON.stringify(data)], {
      type: 'application/json',
    })
  );
  file && formData.append('file', file);
  file && formData.append('extension', file.name.split('.').pop() || '');

  return request
    .post(
      `${MS_EXPENSEREPORT_ENDPOINT}/v2/expensereport/user/${userId}`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
    .then((res) => res?.data);
}

function update(
  companyId: string,
  data: ExpenseReportDataPayloadData,
  expenseReportId?: string,
  file?: FileWithPath | null
) {
  const formData = new FormData();
  formData.append(
    'data',
    new Blob([JSON.stringify(data)], {
      type: 'application/json',
    })
  );
  file && formData.append('file', file);
  file && formData.append('extension', file.name.split('.').pop() || '');

  return request
    .patch(
      `${MS_EXPENSEREPORT_ENDPOINT}/v2/expensereport/${companyId}/${expenseReportId}`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
    .then((res) => res?.data);
}

function updateStatus(
  companyId: string,
  expenseReportId: string,
  newState: ExpenseStatusState
) {
  return request
    .patch(
      `${MS_EXPENSEREPORT_ENDPOINT}/v2/expensereport/${companyId}/${expenseReportId}/status`,
      {},
      { params: { newState } }
    )
    .then((res) => res?.data);
}

function getPendingValidations(
  companyId: string
): Promise<ExpenseReportObject[]> {
  return request
    .get(`${MS_EXPENSEREPORT_ENDPOINT}/v2/expensereport/${companyId}/to-valid`)
    .then((res) => res?.data);
}

export type ExpenseReportData = {
  monthConcerned: string;
  divisionIds: string[];
};

function getExpenseReportsByDivisionsAndMonth(
  companyId: string,
  data: ExpenseReportData
): Promise<UserExpenseReportSummaryDetail[]> {
  return request
    .post(
      `${MS_EXPENSEREPORT_ENDPOINT}/v2/expensereport/${companyId}/divisions`,
      data
    )
    .then((res) => res?.data);
}

function expenseReportAttachmentFile(
  companyId: string,
  expenseReportId: string
) {
  return `${MS_EXPENSEREPORT_ENDPOINT}/v2/expensereport/${companyId}/${expenseReportId}/attachment`;
}

function listByMonth(userId: string, month: number, year: number) {
  return request
    .get(
      `${MS_EXPENSEREPORT_ENDPOINT}/v2/expensereport/${userId}/${month}/${year}`
    )
    .then((res) => res?.data);
}

function getUserSummary(userId: string) {
  return request
    .get(`${MS_EXPENSEREPORT_ENDPOINT}/v2/expensereport/${userId}`)
    .then((res) => res?.data);
}

function deleteExpenseReport(companyId: string, expenseReportId: string) {
  return request
    .delete(
      `${MS_EXPENSEREPORT_ENDPOINT}/v2/expensereport/${companyId}/${expenseReportId}`
    )
    .then((res) => res?.data);
}

function getAttachment(companyId: string, expenseReportId: string) {
  return `${MS_EXPENSEREPORT_ENDPOINT}/v2/expensereport/${companyId}/${expenseReportId}/attachment`;
}

function reimburse(companyId: string, ids: string[]) {
  return request
    .post(
      `${MS_EXPENSEREPORT_ENDPOINT}/v2/expensereport/${companyId}/reimburse`,
      { ids }
    )
    .then((res) => res?.data);
}

export type ExportPayload = {
  monthConcerned: string;
  userIds: string[];
};

function exportByUserIds(companyId: string, payload: ExportPayload) {
  const type = 'xlsx';
  return request
    .post(
      `${MS_EXPENSEREPORT_ENDPOINT}/v2/expensereport/${companyId}/export/${type}`,
      payload,
      {
        responseType: 'blob',
      }
    )
    .then((res) => res?.data);
}

function accept(
  companyId: string,
  expenseReportId: string
): Promise<ExpenseReportObject> {
  return request
    .patch(
      `${MS_EXPENSEREPORT_ENDPOINT}/v2/expensereport/${companyId}/${expenseReportId}/accept`,
      {}
    )
    .then((res) => res?.data);
}

function reject(
  companyId: string,
  expenseReportId: string,
  comment: string
): Promise<ExpenseReportObject> {
  return request
    .patch(
      `${MS_EXPENSEREPORT_ENDPOINT}/v2/expensereport/${companyId}/${expenseReportId}/reject`,
      { comment }
    )
    .then((res) => res?.data);
}

const ExpenseReportService = {
  create,
  update,
  updateStatus,
  getPendingValidations,
  getExpenseReportsByDivisionsAndMonth,
  expenseReportAttachmentFile,
  getUserSummary,
  listByMonth,
  deleteExpenseReport,
  getAttachment,
  reimburse,
  exportByUserIds,
  accept,
  reject,
};

export default ExpenseReportService;
