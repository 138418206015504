import { Options, Table } from '@ckprivate/ckf-ui';
import { Switch } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { DataTableColumn } from 'mantine-datatable';
import React, { useState } from 'react';

import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import ScreenWrapper from '../../../core/layouts/components/ScreenWrapper';
import useCompany from '../../../hooks/useCompany';
import CompanyService from '../../../services/CompanyService';
import PlanningService from '../../../services/PlanningService';
import {
  CountryAreaEnum,
  CountryEnum,
} from '../../../types/api/response/company';
import type { PublicHoliday } from '../../../types/types';
import handleErrorMessage from '../../../utils/handleErrorMessage';
import {
  getHiddenColumns,
  saveHiddenColumns,
} from '../../../utils/optionsPreferences';
import { FranceAreaSelect } from './components/FranceAreaSelect';

const componentName = 'PublicHolidaysAndLeave';
export default function PublicHolidaysAndLeave() {
  const { user } = useAuth();
  const { id: companyId, company, refetch } = useCompany(user);
  const { t, lang } = useI18n();
  const columns: DataTableColumn<PublicHoliday>[] = [
    {
      accessor: 'key',
      title: t('w.name'),
      sortable: true,
      ellipsis: true,
    },
    {
      accessor: 'date',
      title: 'Date',
      sortable: true,
      ellipsis: true,
      render: ({ date }) => dayjs(date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
    },
    {
      accessor: 'worked',
      title: t('w.publicHoliday'),
      sortable: true,
      ellipsis: true,
      render: ({ worked, key }: { worked: boolean; key: string }) => (
        <Switch
          size="xs"
          checked={!worked}
          onChange={() => workedMutation(key)}
        />
      ),
    },
  ];

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    search: {
      accessor: 'key',
      label: t('w.name'),
    },
    hiddenColumns: getHiddenColumns({ componentName }),
    updateHiddenColumns,
  });

  const { mutate: workedMutation, isLoading: isUpdateLoading } = useMutation({
    mutationFn: (key: string) =>
      PlanningService.updatePublicHoliday(companyId, key),
    onSuccess: () => {
      refetchPublicHolidays();
      showNotification({
        id: 'update-publicHoliday-success',
        title: t('w.success'),
        message: t('success.publicHolidays.updated'),
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: (err) => {
      showNotification({
        id: 'update-publicHoliday-error',
        title: t('w.error'),
        message: handleErrorMessage(err, t),
        color: 'red',
        icon: <IconX />,
      });
    },
  });

  const {
    data: allPublicHolidays,
    isLoading,
    refetch: refetchPublicHolidays,
  } = useQuery({
    queryKey: ['PlanningService.getAllPublicHolidays', companyId],
    queryFn: () =>
      PlanningService.getAllPublicHolidays(
        companyId,
        dayjs().year(),
        dayjs().year()
      ),
    onError: (err) =>
      showNotification({
        id: 'get-publicHoliday-error',
        title: t('w.error'),
        message: handleErrorMessage(err, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const { mutate: updateCompanyCountryArea } = useMutation({
    mutationFn: (newArea: CountryAreaEnum) =>
      CompanyService.updateCompany(companyId, { countryArea: newArea }),
    onSuccess: () => {
      refetch();
      refetchPublicHolidays();
    },
  });

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  return (
    <ScreenWrapper title={t('w.publicHolidays')} paper>
      <Table
        idAccessor={'key'}
        options={options}
        columns={columns}
        rows={allPublicHolidays}
        withTableBorder={false}
        lang={lang}
        fetching={isLoading || isUpdateLoading}
        rightCustomActions={
          company?.countryCode === CountryEnum.FR
            ? [
                <FranceAreaSelect
                  value={company.countryArea}
                  onChange={(value) =>
                    updateCompanyCountryArea(value as CountryAreaEnum)
                  }
                />,
              ]
            : undefined
        }
      />
    </ScreenWrapper>
  );
}
