import { FilterOption, Options, Table } from '@ckprivate/ckf-ui';
import { ActionIcon, Group } from '@mantine/core';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { DataTableColumn } from 'mantine-datatable';
import React, { useMemo, useState } from 'react';

import LeaveStatusBadge from '../../../../components/badges/LeaveStatusBadge';
import NotYetValidatedBadge from '../../../../components/badges/NotYetValidatedBadge';
import UnitBadge from '../../../../components/badges/UnitBadge';
import CustomActionIconFile from '../../../../components/CustomActionIconFile';
import CustomTooltip from '../../../../components/CustomTooltip';
import GoToUserButton from '../../../../components/GoToUserButton/GoToUserButton';
import LeavePeriodDates from '../../../../components/LeavePeriodDates';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import { useModule } from '../../../../contexts/ModuleProvider';
import LeaveService from '../../../../services/LeaveService';
import type { LeaveResponse } from '../../../../types/api/response/leave';
import { ModuleName } from '../../../../types/api/response/module';
import { handleOpenLeaveAttachmentFile } from '../../../../utils/attachmentFile';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
import {
  getFilter,
  getHiddenColumns,
  saveFilter,
  saveHiddenColumns,
} from '../../../../utils/optionsPreferences';
import LeaveCreatedDate from '../../../home/components/leaves/components/LeaveCreatedDate';
import DeclineLeave from './DeclineLeave';

type Props = {
  leavesList: LeaveResponse[] | undefined;
  isLoading: boolean;
  refetchLeavesList: () => void;
};

const componentName = 'LeavesPendingValidationsList';
export default function LeavesPendingValidationsList({
  leavesList,
  isLoading,
  refetchLeavesList,
}: Props) {
  const { t, lang } = useI18n();
  const { user, access_token } = useAuth();
  const { company } = user;
  const { getModule } = useModule();

  const isModuleCompanyUseN2Active = getModule(ModuleName.CompanyUseN2)?.active;

  const { mutate: validateLeave } = useMutation({
    mutationFn: (variables: { leaveId: string }) =>
      LeaveService.validateRequest(company?.id!, variables.leaveId),
    onSuccess: () => {
      refetchLeavesList();

      showNotification({
        id: 'confirm-leave-validation-success',
        title: t('w.success'),
        message: t('leave.request.confirmValidation.success'),
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: (error) =>
      showNotification({
        id: 'confirm-leave-validation-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const actionsColumn: DataTableColumn<LeaveResponse> = useMemo(() => {
    return {
      accessor: 'actions',
      title: t('w.actions'),
      width: 100,
      textAlign: 'right',
      sortable: false,
      render: (leave) => (
        <Group gap="xs" justify="end" wrap={'nowrap'}>
          <ActionIcon
            variant={'subtle'}
            color="green"
            size={'sm'}
            onClick={() => {
              validateLeave({
                leaveId: leave.id,
              });
            }}
          >
            <IconCheck size={18} />
          </ActionIcon>
          <ActionIcon
            color="red"
            variant={'subtle'}
            size={'sm'}
            onClick={() => {
              openDeclineLeave(leave);
            }}
          >
            <IconX size={18} />
          </ActionIcon>
        </Group>
      ),
    };
  }, []);

  const columnsWithN2: DataTableColumn<LeaveResponse>[] = useMemo(
    () => [
      {
        accessor: 'employeeName',
        title: t('w.employees').slice(0, -1),
        sortable: true,
        ellipsis: true,
        render: ({ who, whoUser }) => (
          <GoToUserButton userId={who} userFullname={whoUser?.fullname!} />
        ),
      },
      {
        accessor: 'leaveType.name',
        title: 'Type',
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'comment',
        title: t('w.comment'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'period',
        title: 'Dates',
        sortable: true,
        ellipsis: true,
        width: 300,
        render: ({ period, startHalf, endHalf }) => (
          <LeavePeriodDates
            endDate={period.endDate}
            startDate={period.startDate}
            endHalf={endHalf}
            startHalf={startHalf}
          />
        ),
      },
      {
        accessor: 'created',
        title: t('w.requestedOn'),
        sortable: true,
        ellipsis: true,
        render: ({ created }) => <LeaveCreatedDate date={created} />,
      },
      {
        accessor: 'duration',
        title: t('w.numberOfDays'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'fileName',
        title: t('w.file'),
        sortable: true,
        ellipsis: true,
        render: ({ id, fileName, leaveType, whoUser }) => {
          return (
            <Group>
              {fileName && (
                <CustomTooltip label={t('w.seeAttachment')}>
                  <CustomActionIconFile
                    fileName={fileName}
                    onClick={() =>
                      handleOpenLeaveAttachmentFile(
                        id,
                        leaveType?.name!,
                        whoUser?.fullname!,
                        fileName,
                        access_token,
                        t
                      )
                    }
                  />
                </CustomTooltip>
              )}
            </Group>
          );
        },
      },
      {
        accessor: 'status',
        title: t('w.status'),
        sortable: true,
        ellipsis: true,
        render: ({ status }) => <LeaveStatusBadge status={status} />,
      },
      {
        accessor: 'validatorFullname',
        title: t('w.validator'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'statusDateN1',
        title: t('w.validationDateN1'),
        sortable: true,
        ellipsis: true,
        render: ({ statusDateN1 }) =>
          !statusDateN1 ? (
            <NotYetValidatedBadge />
          ) : (
            dayjs(statusDateN1).format('D MMM YYYY')
          ),
      },
      {
        accessor: 'statusDateN2',
        title: t('w.validationDateN2'),
        sortable: true,
        ellipsis: true,
        render: ({ statusDateN2 }) =>
          !statusDateN2 ? (
            <NotYetValidatedBadge />
          ) : (
            dayjs(statusDateN2).format('D MMM YYYY')
          ),
      },
      {
        accessor: 'division.name',
        title: user.company?.divisionLabel
          ? user.company.divisionLabel
          : t('w.unit'),
        sortable: true,
        ellipsis: true,
        render: ({ division }) => <UnitBadge unit={division?.name} />,
      },
      actionsColumn,
    ],
    []
  );

  const defaultColumns: DataTableColumn<LeaveResponse>[] = useMemo(
    () => [
      {
        accessor: 'employeeName',
        title: t('w.employees').slice(0, -1),
        sortable: true,
        ellipsis: true,
        render: ({ whoUser }) => (
          <GoToUserButton
            userId={whoUser?.id!}
            userFullname={whoUser?.fullname!}
          />
        ),
      },
      {
        accessor: 'leaveType.name',
        title: 'Type',
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'comment',
        title: t('w.comment'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'period',
        title: 'Dates',
        width: 300,
        sortable: true,
        ellipsis: true,
        render: ({ period, startHalf, endHalf }) => (
          <LeavePeriodDates
            endDate={period.endDate}
            startDate={period.startDate}
            endHalf={endHalf}
            startHalf={startHalf}
          />
        ),
      },
      {
        accessor: 'created',
        title: t('w.requestedOn'),
        sortable: true,
        ellipsis: true,
        render: ({ created }) => <LeaveCreatedDate date={created} />,
      },
      {
        accessor: 'duration',
        title: t('w.numberOfDays'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'fileName',
        title: t('w.file'),
        sortable: true,
        ellipsis: true,
        render: ({ id, fileName, leaveType, whoUser }) => {
          return (
            <Group>
              {fileName && (
                <CustomTooltip label={t('w.seeAttachment')}>
                  <CustomActionIconFile
                    fileName={fileName}
                    onClick={() =>
                      handleOpenLeaveAttachmentFile(
                        id,
                        leaveType?.name!,
                        whoUser?.fullname!,
                        fileName,
                        access_token,
                        t
                      )
                    }
                  />
                </CustomTooltip>
              )}
            </Group>
          );
        },
      },
      {
        accessor: 'status',
        title: t('w.status'),
        sortable: true,
        ellipsis: true,
        render: ({ status }) => <LeaveStatusBadge status={status} />,
      },
      {
        accessor: 'validatorFullname',
        title: t('w.validator'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'division.name',
        title: user.company?.divisionLabel
          ? user.company.divisionLabel
          : t('w.unit'),
        sortable: true,
        ellipsis: true,
        render: ({ division }) => <UnitBadge unit={division?.name} />,
      },
      actionsColumn,
    ],
    []
  );

  const leavesColumns: DataTableColumn<any>[] = useMemo(
    () => (isModuleCompanyUseN2Active ? columnsWithN2 : defaultColumns),
    [isModuleCompanyUseN2Active]
  );
  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    search: {
      accessor: 'leaveType.name',
      label: 'Type',
    },
    hiddenColumns: getHiddenColumns({
      componentName,
      hiddenColumns: ['comment'],
    }),
    updateHiddenColumns,
    filter: getFilter({
      componentName,
      filter: [
        {
          accessor: 'status.state',
          label: t('w.status'),
          choices: [
            { value: 'VALID', label: t('expenseReport.status.state.VALID') },
            {
              value: 'WAITING',
              label: t('expenseReport.status.state.WAITING'),
            },
            {
              value: 'REJECTED',
              label: t('expenseReport.status.state.REJECTED'),
            },
          ],
          selectedChoices: [],
        },
      ],
    }),
    updateFilter,
  });

  function updateFilter(newFilter: FilterOption[]) {
    const _options = { ...options };
    _options.filter = newFilter;
    saveFilter({ componentName, filter: newFilter });
    setOptions(_options);
  }

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  function openDeclineLeave(leave: LeaveResponse) {
    modals.open({
      modalId: 'DeclineLeave',
      title: t('leave.request.decline'),
      children: (
        <DeclineLeave leave={leave} refetchLeavesList={refetchLeavesList} />
      ),
      size: 'lg',
      m: 'xl',
    });
  }

  return (
    <Table
      pinFirstColumn
      pinLastColumn
      rows={leavesList}
      height={'calc(100vh - 200px)'}
      options={options}
      columns={leavesColumns}
      lang={lang}
      fetching={isLoading}
      withTableBorder={false}
    />
  );
}
