import { Tabs } from '@mantine/core';
import {
  type DatesRangeValue,
  DateValue,
} from '@mantine/dates/lib/types/DatePickerValue';
import React, { useMemo, useState } from 'react';

import IndicatorBadge from '../../../../components/badges/IndicatorBadge';
import { useI18n } from '../../../../contexts/I18nProvider';
import type { PayslipMonthResponse } from '../../../../types/types';
import EmployeeWithoutPayslip from './employee-without-payslip/EmployeeWithoutPayslip';
import PayslipsNotMatched from './not-matched/PayslipsNotMatched';
import PayslipsSourceFiles from './source-files/PayslipsSourceFiles';
import ValidatedPayslips from './validated/ValidatedPayslips';

type Props = {
  companyId: string;
  payslipsMonthInfo: PayslipMonthResponse | undefined;
  selectedDate: DateValue | DatesRangeValue | Date[];
};

export default function PayslipsStatusTabs({
  companyId,
  payslipsMonthInfo,
  selectedDate,
}: Props) {
  const { t } = useI18n();
  const [activeTab, setActiveTab] = useState<string | null>('validated');
  const tabs = useMemo(
    () => [
      {
        value: 'validated',
        label: (
          <>
            {t('w.validated')}{' '}
            <IndicatorBadge
              value={payslipsMonthInfo ? payslipsMonthInfo.ok.length : 0}
            />
          </>
        ),
        children: (
          <ValidatedPayslips
            companyId={companyId}
            payslipsMonthInfo={payslipsMonthInfo}
            selectedDate={selectedDate}
          />
        ),
      },
      {
        value: 'employeeWithoutPayslip',
        label: (
          <>
            {t('w.employeeWithoutPayslip')}{' '}
            <IndicatorBadge
              value={payslipsMonthInfo ? payslipsMonthInfo.notFound.length : 0}
            />
          </>
        ),
        children: (
          <EmployeeWithoutPayslip
            payslipsMonthInfo={payslipsMonthInfo}
            selectDate={selectedDate}
            companyId={companyId}
          />
        ),
      },
      {
        value: 'payslipNotMatched',
        label: (
          <>
            {t('w.payslipNotMatched')}{' '}
            <IndicatorBadge
              value={payslipsMonthInfo ? payslipsMonthInfo.new.length : 0}
            />
          </>
        ),
        children: (
          <PayslipsNotMatched
            payslipsMonthInfo={payslipsMonthInfo}
            selectedDate={selectedDate}
            companyId={companyId}
          />
        ),
      },
      {
        value: 'sourceFiles',
        label: t('w.sourceFiles'),
        children: <PayslipsSourceFiles payslipsMonthInfo={payslipsMonthInfo} />,
      },
    ],
    []
  );

  return (
    <Tabs value={activeTab} onChange={setActiveTab}>
      <Tabs.List>
        {tabs.map((tab, index) => (
          <Tabs.Tab key={`tab-${tab.value}-${index}`} value={tab.value}>
            {tab.label}
          </Tabs.Tab>
        ))}
      </Tabs.List>
      {tabs.map((tab, index) => (
        <Tabs.Panel
          key={`panel-${tab.value}-${index}`}
          value={tab.value}
          p={'md'}
        >
          {tab.children}
        </Tabs.Panel>
      ))}
    </Tabs>
  );
}
