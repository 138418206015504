import React from 'react';

import { Link } from '../../../types/types';
import LinksGroup from '../NavBarLinksGroup/NavbarLinksGroup';
import classes from './NavBarNested.module.sass';

type Props = {
  linksData: Link[];
};

export default function NavbarNested({ linksData }: Props) {
  return (
    <nav className={classes.navbar}>
      <div className={classes.linksInner}>
        {linksData.map((item, index) => (
          <LinksGroup {...item} key={`${item.link}-${index}`} />
        ))}
      </div>
    </nav>
  );
}
