import { Pill, Tabs } from '@mantine/core';
import React from 'react';

import { useI18n } from '../../../../../contexts/I18nProvider';
import type { AzureSyncResult as AzureSyncResultType } from '../../../../../types/types';

type Props = {
  result: AzureSyncResultType;
};

export default function AzureSyncResult({ result }: Props) {
  const { t } = useI18n();

  const tabs = Object.keys(result) as (keyof AzureSyncResultType)[];

  function renderTable(tab: keyof AzureSyncResultType) {
    return result[tab]?.map((value) => <Pill key={value}>{value}</Pill>);
  }

  return (
    <Tabs defaultValue={tabs[0]} orientation="vertical">
      <Tabs.List mr={'md'}>
        {tabs.map((tab) => (
          <Tabs.Tab key={tab} value={tab}>
            {`${t(`azureSyncResult.${tab}`)} : ${result[tab]?.length}`}
          </Tabs.Tab>
        ))}
      </Tabs.List>

      {tabs.map((tab) => (
        <Tabs.Panel key={tab} value={tab}>
          {renderTable(tab)}
        </Tabs.Panel>
      ))}
    </Tabs>
  );
}
