import {
  Box,
  Collapse,
  Divider,
  Group,
  rem,
  Text,
  UnstyledButton,
} from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';
import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Link as LinkType } from '../../../types/types';
import classes from '../NavBarLinksGroup/NavbarLinksGroup.module.sass';

interface LinksGroupProps {
  initiallyOpened?: boolean;
}

export default function LinksGroup({
  icon: Icon,
  label,
  link,
  divider,
  initiallyOpened,
  links,
}: LinksGroupProps & LinkType) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const hasLinks = Array.isArray(links);
  const [opened, setOpened] = useState(initiallyOpened || false);
  const items = useMemo(
    () =>
      hasLinks
        ? links
            ?.filter((link) => link?.isDisplayed)
            .map((link) => (
              <Text
                component={'div'}
                fz={'sm'}
                className={classes.link}
                onClick={() => navigate(link.link)}
                key={link.link}
                data-active={pathname === link.link || undefined}
              >
                {link.label}
              </Text>
            ))
        : [],
    [links, pathname]
  );

  return (
    <>
      <UnstyledButton
        onClick={() => setOpened((o) => !o)}
        className={classes.control}
        data-active={pathname === link || undefined}
      >
        <Group
          justify="space-between"
          gap={0}
          onClick={() => {
            if (link) {
              navigate(link);
            }
          }}
          className={classes.icon}
        >
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <Icon style={{ width: rem(28), height: rem(28) }} />
            <Text fz={'md'} ml="md" component={'div'}>
              {label}
            </Text>
          </Box>
          {hasLinks && (
            <IconChevronRight
              className={classes.chevron}
              stroke={1.5}
              style={{
                width: rem(16),
                height: rem(16),
                transform: opened ? 'rotate(90deg)' : 'none',
              }}
            />
          )}
        </Group>
      </UnstyledButton>
      {hasLinks && <Collapse in={opened}>{items}</Collapse>}
      {divider && <Divider m={'xs'} />}
    </>
  );
}
