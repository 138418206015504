import { Center, Stack, Title } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React from 'react';

import { LOGO_PATH, LOGO_SQUARE_PATH } from '../../variables/GlobalVariables';
import maintenanceSVG from './maintenance.svg';

export default function UnderMaintenance() {
  const largeScreen = useMediaQuery('(min-width: 990px)');
  return (
    <Center w="100vw" h="100vh" p="xl">
      <Stack align="center" justify="center">
        <img
          alt="logo"
          src={largeScreen ? LOGO_PATH : LOGO_SQUARE_PATH}
          width={largeScreen ? 400 : 80}
          height={largeScreen ? 80 : 80}
        />
        <img src={maintenanceSVG} width={'50%'} alt={'maintenance'} />

        <Title order={1}>
          Désolé pour le dérangement, nous comprenons que c’est frustrant.
        </Title>
        <Title order={1}>
          Merci de patienter pendant cette maintenance nécessaire.
        </Title>
      </Stack>
    </Center>
  );
}
