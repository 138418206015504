import { Button, Image, Space, Title } from '@mantine/core';
import { modals } from '@mantine/modals';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAuth } from '../../contexts/AuthProvider';
import { useI18n } from '../../contexts/I18nProvider';
import { useModule } from '../../contexts/ModuleProvider';
import ScreenWrapper from '../../core/layouts/components/ScreenWrapper';
import GererMesAffairesService from '../../services/GererMesAffairesService';
import PayslipService from '../../services/PayslipService';
import { ModuleName } from '../../types/api/response/module';
import type { PayslipsFromGma } from '../../types/types';
import { getPersistedObject, persistData } from '../../utils/localStorage';
import GmaInvitationForm from './components/gma/GmaInvitationForm';
import TutorialGma from './components/gma/TutorialGma';
import PayslipsUserTable from './components/PayslipsUserTable';
import RecentPayslipsUser from './components/RecentPayslipsUser';

export default function Payslips() {
  const { user, isWhiteLabelGma } = useAuth();
  const { userId } = useParams();
  const { t } = useI18n();
  const { getModule } = useModule();
  const [isUserGmaInvited, setIsUserGmaInvited] = useState<boolean>(true);
  const gmaModule = getModule(ModuleName.GererMesAffaires);
  const gmaKey = getPersistedObject('gma');

  const actionButtons = useMemo(
    () => [
      <Button
        key={'gma-access-to-portal'}
        variant={'outline'}
        leftSection={
          <Image
            src={'/images/features/gerer_mes_affaires.png'}
            w={35}
            h={35}
          />
        }
        onClick={() =>
          window.open('https://gerermesaffaires.com/proprietaire/login')
        }
      >
        {t('w.gmaAccessToPortal')}
      </Button>,
    ],
    []
  );

  const { data: payslips, isLoading: isPayslipsUserDataLoading } = useQuery({
    queryKey: ['PayslipService.getUserPayslips', userId],
    queryFn: () => PayslipService.getUserPayslips(userId, false),
  });

  const { refetch: refetchGmaUser, isFetching: isGmaUserLoading } = useQuery({
    enabled: gmaModule?.active && user.id === userId,
    queryKey: ['GererMesAffairesService.getMyGmaUser'],
    queryFn: () => GererMesAffairesService.getMyGmaUser(userId),
    onSuccess: (data) => {
      if (!data.invited) {
        setIsUserGmaInvited(false);
      } else {
        setIsUserGmaInvited(true);
      }
    },
  });

  const { data: payslipsFromGmaData } = useQuery({
    enabled:
      (isUserGmaInvited &&
        gmaModule?.active &&
        gmaKey &&
        gmaKey.scope === 'collaborator') ||
      user.id === userId,
    queryKey: ['GererMesAffairesService.getPayslipsFromGma', userId],
    queryFn: () =>
      GererMesAffairesService.getPayslipsFromGma(
        userId,
        gmaKey.access_token,
        gmaKey.refresh_token,
        gmaKey.scope
      ),
    onSuccess: (data: PayslipsFromGma) => {
      if (data) {
        if (data.accessToken !== gmaKey.access_token) {
          gmaKey.access_token = data.accessToken;
          gmaKey.refresh_token = data.refreshToken;
          persistData('gma', gmaKey);
        }
      }
    },
  });

  const payslipsFromGma: PayslipsFromGma | null = useMemo(() => {
    if (!payslipsFromGmaData) {
      return null;
    }
    return payslipsFromGmaData;
  }, [payslipsFromGmaData]);

  useEffect(() => {
    if (!isWhiteLabelGma && !isUserGmaInvited && gmaModule?.active) {
      modals.open({
        id: `invitation-to-join-gma-user-${userId}`,
        title: (
          <Title size={'h3'} component="p">
            {t('w.gmaInvitationToJoin')}
          </Title>
        ),
        size: 'xl',
        children: (
          <GmaInvitationForm
            refetchGmaUser={refetchGmaUser}
            onModalClose={modals.closeAll}
          />
        ),
      });
    }
  }, [isUserGmaInvited, gmaModule?.active]);

  function displayActionButtons() {
    return gmaModule?.active && isUserGmaInvited ? actionButtons : [];
  }

  if (isUserGmaInvited && gmaModule?.active) {
    if (!gmaKey) {
      if (user.id === userId || isWhiteLabelGma) {
        return (
          <ScreenWrapper
            title={t('w.payslips')}
            actionButtons={displayActionButtons()}
          >
            <TutorialGma />
          </ScreenWrapper>
        );
      }
    }
  }

  return (
    <ScreenWrapper
      title={t('w.payslips')}
      actionButtons={displayActionButtons()}
    >
      {/*TODO HistoPaie {!!userId && <HistoPaieConnector userId={userId} />}*/}
      {!!payslips?.length && (
        <>
          <RecentPayslipsUser
            myPayslips={payslips}
            userId={userId}
            userName={user.fullname!}
            companyId={user.company?.id!}
            gmaPayslips={payslipsFromGma}
          />
          <Space h="xl" />
        </>
      )}
      {payslips && (
        <PayslipsUserTable
          userName={user.fullname!}
          payslips={payslips}
          userId={userId}
          companyId={user.company?.id!}
          loading={isGmaUserLoading || isPayslipsUserDataLoading}
          gmaPayslips={payslipsFromGma}
        />
      )}
    </ScreenWrapper>
  );
}
