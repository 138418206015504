import { useQuery } from '@tanstack/react-query';

import { useAuth } from '../contexts/AuthProvider';
import CharteredAccountantService from '../services/CharteredAccountantService';
import useCompany from './useCompany';

export default function useFetchAccountants() {
  const { user } = useAuth();
  const { id: companyId } = useCompany(user);

  const {
    data: accountantsListData,
    refetch: refetchAccountantsListData,
    isLoading: isAccountantsListDataLoading,
  } = useQuery({
    queryKey: ['CharteredAccountantService.accountantsList', companyId],
    queryFn: () => CharteredAccountantService.accountantsList(companyId),
  });

  return {
    accountantsListData,
    refetchAccountantsListData,
    isAccountantsListDataLoading,
  };
}
