import { Group, Text } from '@mantine/core';
import {
  IconFile,
  IconFileTypeCsv,
  IconFileTypeDocx,
  IconFileTypeJpg,
  IconFileTypePdf,
  IconFileTypePng,
  IconFileTypeXls,
  IconFolder,
} from '@tabler/icons-react';
import React, { JSX } from 'react';

import { DocumentView } from '../../../types/types';
import classes from './Documents.module.sass';

type Props = {
  document: DocumentView;
  handleNavigateFolderOrOpenFilePathClick: () => void;
};

export default function DocumentFolderOrFileItem({
  document,
  handleNavigateFolderOrOpenFilePathClick,
}: Props) {
  function getIconFile(extension: string): JSX.Element {
    switch (extension) {
      case '.xlsx':
      case '.xls':
        return <IconFileTypeXls className={classes.icon} stroke={2.3} />;
      case '.csv':
        return <IconFileTypeCsv className={classes.icon} stroke={2.3} />;
      case '.pdf':
        return <IconFileTypePdf className={classes.icon} stroke={2.3} />;
      case '.png':
        return <IconFileTypePng className={classes.icon} stroke={2.3} />;
      case '.jpg':
      case '.jpeg':
        return <IconFileTypeJpg className={classes.icon} stroke={2.3} />;
      case '.docx':
      case '.doc':
        return <IconFileTypeDocx className={classes.icon} stroke={2.3} />;
      default:
        return <IconFile className={classes.icon} stroke={2.3} />;
    }
  }

  return (
    <Group
      gap={'xs'}
      styles={{ root: { cursor: 'pointer' } }}
      onClick={handleNavigateFolderOrOpenFilePathClick}
    >
      {document.path.type === 'FOLDER' ? (
        <IconFolder className={classes.icon} stroke={2.3} />
      ) : (
        getIconFile(document.path.extension)
      )}
      <Text c={'dimmed'} className={classes.folderOrFileText}>
        {document.meta.title}
      </Text>
    </Group>
  );
}
