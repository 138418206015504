import React from 'react';

import CustomUserAvatar from '../../../../../components/CustomAvatar/CustomUserAvatar';
import CustomTooltip from '../../../../../components/CustomTooltip';
import type { DivisionResponse } from '../../../../../types/api/response/division';

type Props = { name: string; id: string; division: DivisionResponse };

export default function AvatarTooltip({ name, id, division }: Props) {
  return (
    <CustomTooltip
      label={`${name} : ${name === division.n2User?.fullname ? 'N+2' : 'N+1'}`}
    >
      <div>
        <CustomUserAvatar userId={id} />
      </div>
    </CustomTooltip>
  );
}
