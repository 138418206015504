import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAuth } from '../../../contexts/AuthProvider';
import useDisplayTeamActivities from '../../../hooks/accessChecking/activities/useDisplayTeamActivities';
import useCompany from '../../../hooks/useCompany';
import TeamDailyWorks from './components/TeamDailyWorks';

export default function TeamActivites() {
  let location = useLocation();
  const { user } = useAuth();
  const { id: companyId } = useCompany(user);
  const isDisplayTeamActivities = useDisplayTeamActivities();

  if (!isDisplayTeamActivities) {
    return <Navigate to="/error404" state={{ from: location }} replace />;
  }

  return <TeamDailyWorks companyId={companyId} />;
}
