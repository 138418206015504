import {
  type DivisionEndOfMonthRequest,
  EndMonthExportType,
  type UpdateEmployeeEndOfMonthCommentQuery,
  type UpdateIdentifiantsCompanyRequest,
  type UpdateMailRequest,
  type ValidateEmployeeLeaveOfMonthQuery,
  type ValidateListEmployeesEndOfMonthQuery,
} from '../types/api/payload/endMonth';
import type {
  EndOfMonthValidationResponse,
  OpenpayeUserResponse,
} from '../types/api/response/endMonth';
import { MS_ENDMONTH_ENDPOINT } from '../variables/GlobalVariables';
import request from './AxiosClient';

function getEndOfMonthValidation(
  companyId: string,
  payload: DivisionEndOfMonthRequest
): Promise<EndOfMonthValidationResponse[]> {
  return request
    .post(`${MS_ENDMONTH_ENDPOINT}/v2/end-month/${companyId}/history`, payload)
    .then((res) => res?.data);
}

function validateUserLeaveRequest(
  companyId: string,
  payload: ValidateEmployeeLeaveOfMonthQuery
): Promise<EndOfMonthValidationResponse> {
  return request
    .post(
      `${MS_ENDMONTH_ENDPOINT}/v2/end-month/${companyId}/validate-employee-leave-of-month`,
      payload
    )
    .then((res) => res?.data);
}

function updateEmployeeComment(
  companyId: string,
  payload: UpdateEmployeeEndOfMonthCommentQuery
): Promise<EndOfMonthValidationResponse> {
  return request
    .patch(
      `${MS_ENDMONTH_ENDPOINT}/v2/end-month/${companyId}/employee/comment`,
      payload
    )
    .then((res) => res?.data);
}

function validateEmployeesEndOfMonth(
  companyId: string,
  payload: ValidateListEmployeesEndOfMonthQuery
): Promise<EndOfMonthValidationResponse> {
  return request
    .post(
      `${MS_ENDMONTH_ENDPOINT}/v2/end-month/${companyId}/validate-list-employees-end-of-month`,
      payload
    )
    .then((res) => res?.data);
}

function getEndOfMonthExport(
  companyId: string,
  type: EndMonthExportType,
  payload: DivisionEndOfMonthRequest
): Promise<any> {
  return request
    .post(
      `${MS_ENDMONTH_ENDPOINT}/v2/end-month/export/${companyId}/type/${type}`,
      payload,
      {
        responseType: 'blob',
      }
    )
    .then((res) => res?.data);
}

function validateDivisionEndOfMonth(
  companyId: string,
  endOfMonthId: string
): Promise<EndOfMonthValidationResponse> {
  return request
    .post(
      `${MS_ENDMONTH_ENDPOINT}/v2/end-month/${companyId}/validate-division-end-of-month/${endOfMonthId}`
    )
    .then((res) => res?.data);
}

function synchroEmployee(companyId: string): Promise<OpenpayeUserResponse[]> {
  return request
    .patch(
      `${MS_ENDMONTH_ENDPOINT}/v2/end-month/module/openpaye/${companyId}/employee`
    )
    .then((res) => res?.data);
}

function getSynchroOpenpaye(
  companyId: string
): Promise<OpenpayeUserResponse[]> {
  return request
    .get(
      `${MS_ENDMONTH_ENDPOINT}/v2/end-month/module/openpaye/${companyId}/employee`
    )
    .then((res) => res?.data);
}

function addMail(
  companyId: string,
  payload: UpdateMailRequest
): Promise<OpenpayeUserResponse> {
  return request
    .post(
      `${MS_ENDMONTH_ENDPOINT}/v2/end-month/module/openpaye/${companyId}/employee/add-mail`,
      payload
    )
    .then((res) => res?.data);
}

function addIdentifiantsCompany(
  companyId: string,
  payload: UpdateIdentifiantsCompanyRequest
): Promise<OpenpayeUserResponse> {
  return request
    .post(
      `${MS_ENDMONTH_ENDPOINT}/v2/end-month/module/openpaye/${companyId}/employee/identifiants-company`,
      payload
    )
    .then((res) => res?.data);
}

function synchroOpenpayeVariablePayroll(companyId: string): Promise<void> {
  return request
    .post(
      `${MS_ENDMONTH_ENDPOINT}/v2/end-month/module/openpaye/${companyId}/variable-payroll`
    )
    .then((res) => res?.data);
}

function synchroOpenpayePayslips(
  companyId: string,
  month: number,
  year: number
) {
  return request
    .get(
      `${MS_ENDMONTH_ENDPOINT}/v2/end-month/module/openpaye/payslips/${companyId}/${month}/${year}`
    )
    .then((res) => res?.data);
}

function createClientCompanyFromOpenpayeDossier(
  accountantCompanyId: string,
  dossierId: string
): Promise<OpenpayeUserResponse[]> {
  return request
    .post(
      `${MS_ENDMONTH_ENDPOINT}/v2/end-month/module/openpaye/${accountantCompanyId}/client?dossierId=${dossierId}`
    )
    .then((res) => res?.data);
}

function synchroOpenpayeDossierEmployeesToExistingClientCompany(
  accountantCompanyId: string,
  clientCompanyId: string,
  dossierId: string
): Promise<OpenpayeUserResponse[]> {
  return request
    .post(
      `${MS_ENDMONTH_ENDPOINT}/v2/end-month/module/openpaye/${accountantCompanyId}/client/${clientCompanyId}?dossierId=${dossierId}`
    )
    .then((res) => res?.data);
}

const EndOfMonthService = {
  getEndOfMonthValidation,
  validateUserLeaveRequest,
  updateEmployeeComment,
  validateEmployeesEndOfMonth,
  getEndOfMonthExport,
  validateDivisionEndOfMonth,
  synchroEmployee,
  getSynchroOpenpaye,
  addMail,
  addIdentifiantsCompany,
  synchroOpenpayeVariablePayroll,
  synchroOpenpayePayslips,
  createClientCompanyFromOpenpayeDossier,
  synchroOpenpayeDossierEmployeesToExistingClientCompany,
};

export default EndOfMonthService;
