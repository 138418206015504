import {
  ActionIcon,
  Button,
  Center,
  Fieldset,
  Flex,
  Group,
  SimpleGrid,
  Table,
  Title,
  Tooltip,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconArrowNarrowRight, IconCheck, IconX } from '@tabler/icons-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';

import ButtonSave from '../../../../../components/ButtonSave';
import { useI18n } from '../../../../../contexts/I18nProvider';
import CharteredAccountantService from '../../../../../services/CharteredAccountantService';
import EndOfMonthService from '../../../../../services/EndOfMonthService';
import ModuleWithParamsService from '../../../../../services/ModuleWithParamsService';
import type {
  DossierProps,
  ModuleDetails,
  SynchroOpenpayeModuleResponse,
  SynchroOpenpayeParams,
} from '../../../../../types/api/response/module';
import { milliToFullDateString } from '../../../../../utils/format';
import DossierField from './components/DossierField';
import LastSynchroOpenpaye from './components/LastSynchroOpenpaye';

interface FormValues {
  dossiers: DossierProps[] | undefined;
}

type Props = {
  module: ModuleDetails | null;
  moduleParams: SynchroOpenpayeParams;
  setModuleParams: React.Dispatch<React.SetStateAction<SynchroOpenpayeParams>>;
  companyId: string;
  isAccountant: boolean;
};

export default function SynchroOpenpayeFeatureDossierForm({
  module,
  moduleParams,
  setModuleParams,
  companyId,
  isAccountant,
}: Props) {
  const { t } = useI18n();
  const queryClient = useQueryClient();
  const form = useForm<FormValues>({
    initialValues: {
      dossiers: moduleParams?.dossiers || [],
    },
  });
  const [disableSynchroResult, setDisableSynchroResult] = useState(
    moduleParams?.lastSynchroEmployee === null
  );
  const [disableSynchro, setDisableSynchro] = useState(
    moduleParams?.dossiers?.length === 0
  );
  const { data: accountantCustomers } = useQuery({
    enabled: isAccountant,
    queryKey: ['CharteredAccountantService.accountantCustomersList', companyId],
    queryFn: () =>
      CharteredAccountantService.accountantCustomersList(companyId),
  });

  const { mutate: updateModuleParamsOpenpaye, isLoading } = useMutation({
    mutationFn: (variables: { apiUrl: string; payload: any }) =>
      ModuleWithParamsService.updateModuleParams(
        variables.apiUrl,
        companyId,
        variables.payload
      ),
    onSuccess: (data: SynchroOpenpayeModuleResponse) => {
      setDisableSynchro(
        data.params?.dossiers ? data.params.dossiers.length === 0 : true
      );
      setModuleParams(data.params);
      form.setFieldValue('dossiers', data.params?.dossiers);
      showNotification({
        id: `success-update-module-params`,
        message: t('w.success'),
        color: 'green',
        icon: <IconCheck />,
      });
      queryClient.invalidateQueries({
        queryKey: ['CkModuleManagerServiceMS.list', companyId],
      });
    },
    onError: () =>
      showNotification({
        id: `error-update-module-params`,
        title: t('w.error'),
        message: t('error.updateModule'),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const { mutate: synchroEmployee } = useMutation({
    mutationFn: () => EndOfMonthService.synchroEmployee(companyId),
    onSuccess: () => {
      setDisableSynchroResult(false);
      showNotification({
        id: `success-update-module-params`,
        message: t('w.success'),
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: () =>
      showNotification({
        id: `error-update-module-params`,
        title: t('w.error'),
        message: t(`com.feature.openPaye.errors.codeDossierInvalid`),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function handleUpdateOpenpayeParamsSubmit(
    values: ReturnType<(values: FormValues) => FormValues>,
    isTest: boolean
  ) {
    if (module) {
      updateModuleParamsOpenpaye({
        apiUrl: module.description.url,
        payload: {
          active: module?.active ? module.active : false,
          dossiers: values.dossiers,
          isTest,
          password: moduleParams?.password,
          username: moduleParams?.username,
        },
      });
    }
  }

  return (
    <SimpleGrid cols={2}>
      <Fieldset variant="filled" legend={t('openpaye.dossiersOnOpenpaye')}>
        {moduleParams?.dossiersFromOpenpaye?.length ? (
          <Table.ScrollContainer minWidth={500}>
            <Table striped highlightOnHover>
              <Table.Thead>
                <Table.Tr>
                  <Table.Td>
                    <Title order={5}>{t('w.id')}</Title>
                  </Table.Td>
                  <Table.Td>
                    <Title order={5}>{t('w.code')}</Title>
                  </Table.Td>
                  <Table.Td>
                    <Title order={5}>{t('w.name')}</Title>
                  </Table.Td>
                  <Table.Td>
                    <Title order={5}>{t('w.siret')}</Title>
                  </Table.Td>
                  <Table.Td />
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {moduleParams?.dossiersFromOpenpaye
                  .filter(
                    (elt) =>
                      !form.values.dossiers
                        ?.map((d) => d.dossierId)
                        .includes(elt.dossierId?.toString())
                  )
                  .map((item) => (
                    <Table.Tr key={item.dossierId}>
                      <Table.Td>{item.dossierId}</Table.Td>
                      <Table.Td>{item.code}</Table.Td>
                      <Table.Td>{item.nomDossier}</Table.Td>
                      <Table.Td>{item.siret}</Table.Td>
                      <Table.Td>
                        <Tooltip label={t('openpaye.useDossierForSynchro')}>
                          <ActionIcon
                            onClick={() =>
                              form.insertListItem('dossiers', {
                                dossierId: item.dossierId?.toString(),
                              })
                            }
                          >
                            <IconArrowNarrowRight />
                          </ActionIcon>
                        </Tooltip>
                      </Table.Td>
                    </Table.Tr>
                  ))}
              </Table.Tbody>
            </Table>
          </Table.ScrollContainer>
        ) : (
          <Center h="100%">
            <Button
              onClick={() =>
                handleUpdateOpenpayeParamsSubmit({ ...form.values }, false)
              }
              loading={isLoading}
            >
              {t('openpaye.getDossiers')}
            </Button>
          </Center>
        )}
      </Fieldset>
      <Fieldset
        variant="filled"
        legend={t('openpaye.selectedDossiersForSynchro')}
      >
        <form
          onSubmit={form.onSubmit((values) =>
            handleUpdateOpenpayeParamsSubmit(
              { dossiers: values.dossiers },
              false
            )
          )}
        >
          {!isAccountant && (
            <Group gap="sm" mb="md" grow>
              <Button
                key="synchro-button"
                onClick={() => synchroEmployee()}
                size={'sm'}
                disabled={disableSynchro}
              >
                {t('w.synchronize')}
              </Button>
              <Button
                key="last-synchro-button"
                variant={'outline'}
                size={'sm'}
                disabled={disableSynchroResult}
                onClick={() =>
                  modals.open({
                    modalId: `get-last-synchro-openpaye-modal`,
                    title: (
                      <Title size={'h3'} component="p">{`${t(
                        'module.lastSynchroOpenpaye'
                      )} : ${
                        moduleParams.lastSynchroEmployee
                          ? milliToFullDateString(
                              moduleParams.lastSynchroEmployee
                            )
                          : t('w.unknown')
                      }`}</Title>
                    ),
                    size: '100%',
                    children: <LastSynchroOpenpaye companyId={companyId} />,
                  })
                }
              >
                {t('module.lastSyncho')}
              </Button>
            </Group>
          )}
          <Table.ScrollContainer minWidth={500}>
            <Table striped highlightOnHover>
              <Table.Thead>
                <Table.Tr>
                  <Table.Td>
                    <Title order={5}>{t('w.code')}</Title>
                  </Table.Td>
                  <Table.Td>
                    <Title order={5}>{t('w.status')}</Title>
                  </Table.Td>
                  <Table.Td />
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {form.values.dossiers?.map((item, index) => (
                  <DossierField
                    key={index}
                    index={index}
                    form={form}
                    item={item}
                    accountantCompanyId={isAccountant ? companyId : undefined}
                    customers={accountantCustomers}
                  />
                ))}
              </Table.Tbody>
            </Table>
          </Table.ScrollContainer>
          <Flex
            justify={'flex-end'}
            gap={'md'}
            direction="row"
            wrap="wrap"
            mt={'md'}
          >
            <Button
              variant={'outline'}
              onClick={() =>
                handleUpdateOpenpayeParamsSubmit({ ...form.values }, true)
              }
              loading={isLoading}
            >
              {t('w.test')}
            </Button>
            <ButtonSave isLoading={isLoading} />
          </Flex>
        </form>
      </Fieldset>
    </SimpleGrid>
  );
}
