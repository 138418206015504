import { Group, LoadingOverlay, TextInput, Title } from '@mantine/core';
import { isNotEmpty, useForm } from '@mantine/form';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconAlertTriangle, IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import React from 'react';

import ButtonCancel from '../../../../components/ButtonCancel';
import ButtonSave from '../../../../components/ButtonSave';
import LabelText from '../../../../components/LabelText/LabelText';
import { useI18n } from '../../../../contexts/I18nProvider';
import SecurityRoleService from '../../../../services/SecurityRoleService';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
import { roleName } from '../../../../utils/roleName';
import AssignUsersRolesTable from './AssignUsersRolesTable';

type Props = {
  onClose: () => void;
  refresh: () => void;
  companyId: string;
};

export default function CreateRoleForm({ onClose, refresh, companyId }: Props) {
  const { t } = useI18n();
  const form = useForm<{ roleName: string }>({
    initialValues: { roleName: '' },
    validate: {
      roleName: isNotEmpty(t('w.required')),
    },
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: (roleName: string) =>
      SecurityRoleService.createRole(companyId, roleName),
    onSuccess: (data) => {
      refresh();
      showNotification({
        id: `create-role-${data.id}-success`,
        title: t('w.success'),
        message: `${t('w.role')} ${data.name} ${t('w.created').toLowerCase()}`,
        color: 'green',
        icon: <IconCheck />,
      });
      modals.open({
        modalId: `assign-role-${data.id}`,
        title: (
          <Title component="p">
            {t('division.message.assignRole', roleName(t, data.name))}
          </Title>
        ),
        fullScreen: true,
        children: (
          <AssignUsersRolesTable
            securityRoleId={data.id}
            companyId={companyId}
          />
        ),
        onClose: () => {
          refresh();
          onClose();
        },
      });
    },
    onError: (error: any, roleName: string) => {
      if (error?.response) {
        if (error.response.status === 400) {
          showNotification({
            id: `create-role-${roleName}-error`,
            title: t('w.warning'),
            message: t(error.response.data?.errorDescription, roleName),
            color: 'orange',
            icon: <IconAlertTriangle />,
          });
        } else {
          showNotification({
            id: `create-role-${roleName}-error`,
            title: t('w.error'),
            message: handleErrorMessage(error, t),
            color: 'red',
            icon: <IconX />,
          });
        }
      }
    },
  });

  return (
    <form onSubmit={form.onSubmit((values) => mutate(values.roleName))}>
      <LoadingOverlay visible={isLoading} />
      <TextInput
        required
        data-autofocus
        label={<LabelText text={t('w.name')} />}
        {...form.getInputProps('roleName')}
      />
      <Group justify={'flex-end'} mt={'xl'}>
        <ButtonCancel onClose={onClose} />
        <ButtonSave />
      </Group>
    </form>
  );
}
