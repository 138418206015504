import { Avatar } from '@mantine/core';
import { MantineSize } from '@mantine/core/lib/core';
import { IconBuilding } from '@tabler/icons-react';

import CompanyService from '../../services/CompanyService';

type Props = {
  companyId?: string;
  logoPath?: string;
  size?: MantineSize | (string & {}) | number;
};

export default function CustomCompanyAvatar({
  companyId,
  logoPath,
  size,
}: Props) {
  return (
    <Avatar
      src={companyId ? CompanyService.getLogoPath(companyId) : logoPath}
      color="var(--mantine-color-hifivework-2)"
      radius="md"
      size={size}
    >
      <IconBuilding size="1.5rem" />
    </Avatar>
  );
}
