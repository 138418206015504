import { Button } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconFileImport, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import * as React from 'react';

import { useI18n } from '../../../../contexts/I18nProvider';
import EndOfMonthService from '../../../../services/EndOfMonthService';
import { SynchroOpenpayeParams } from '../../../../types/api/response/module';
import handleErrorMessage from '../../../../utils/handleErrorMessage';

type Props = {
  selectedDate: dayjs.Dayjs;
  companyId: string;
  moduleParams: SynchroOpenpayeParams;
};

export default function SynchroOpenpayePayslipsButton({
  selectedDate,
  companyId,
}: Props) {
  const { t } = useI18n();
  const { mutate, isLoading } = useMutation({
    mutationFn: () =>
      EndOfMonthService.synchroOpenpayePayslips(
        companyId,
        dayjs(selectedDate).month() + 1,
        dayjs(selectedDate).year()
      ),
    onSuccess: () => {
      showNotification({
        id: 'success-synchroOpenpayePayslips',
        message: t('w.success'),
        color: 'green',
        icon: <IconX />,
      });
    },
    onError: (error) => {
      showNotification({
        id: 'error-synchroOpenpayePayslips',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      });
    },
  });

  return (
    <Button
      loading={isLoading}
      onClick={() => mutate()}
      leftSection={<IconFileImport size={15} />}
    >
      {t('openpaye.getPayslips')}
    </Button>
  );
}
