import { ActionIcon, Center } from '@mantine/core';
import {
  IconFile,
  IconFileTypeJpg,
  IconFileTypePdf,
  IconFileTypePng,
} from '@tabler/icons-react';
import React from 'react';

import { useI18n } from '../contexts/I18nProvider';
import CustomTooltip from './CustomTooltip';

type Props = {
  fileName: string;
  onClick: () => void;
};

export default function CustomActionIconFile({ fileName, onClick }: Props) {
  const { t } = useI18n();

  function displayIconFile(fileName: string) {
    const fileExtension = fileName.split('.').pop();
    switch (fileExtension) {
      case 'pdf':
        return <IconFileTypePdf />;
      case 'png':
        return <IconFileTypePng />;
      case 'jpg':
      case 'jpeg':
        return <IconFileTypeJpg />;
      default:
        return <IconFile />;
    }
  }

  return (
    <Center>
      <CustomTooltip label={t('w.open')}>
        <ActionIcon size={'md'} variant={'subtle'} onClick={onClick}>
          {displayIconFile(fileName)}
        </ActionIcon>
      </CustomTooltip>
    </Center>
  );
}
