import { ActionIcon } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import React from 'react';

import CustomTooltip from '../CustomTooltip';

export default function ActionDeleteIcon(props: {
  label: string;
  onClick: () => void;
}) {
  return (
    <CustomTooltip label={props.label}>
      <ActionIcon onClick={props.onClick} variant="transparent" color={'red'}>
        <IconTrash />
      </ActionIcon>
    </CustomTooltip>
  );
}
