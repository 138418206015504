import { Table } from '@ckprivate/ckf-ui';
import { Text } from '@mantine/core';
import { DataTableColumn } from 'mantine-datatable';
import * as React from 'react';
import { useMemo } from 'react';

import ErrorSuccessBadge from '../../../../components/badges/ErrorSuccessBadge';
import { useI18n } from '../../../../contexts/I18nProvider';
import {
  ImportClientReport,
  ImportClientsResponse,
  ImportClientStatus,
} from '../../../../types/api/response/accountant';
import CompanyInfoWrapper from '../../components/CompanyInfoWrapper';

type Props = {
  importResult: ImportClientsResponse;
};

export default function ImportClientsResult({ importResult }: Props) {
  const { t, lang } = useI18n();

  const columns: DataTableColumn<ImportClientReport>[] = useMemo(
    () => [
      {
        accessor: 'lineNb',
        title: t('w.lineNumber'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'companyName',
        title: t('w.companyName'),
        sortable: true,
        ellipsis: true,
        render: ({ companyName }) => (
          <CompanyInfoWrapper companyId={''} companyName={companyName} />
        ),
      },
      {
        accessor: 'status',
        title: t('w.status'),
        sortable: true,
        ellipsis: true,
        render: ({ status }) => (
          <ErrorSuccessBadge
            value={status === ImportClientStatus.OK ? 'success' : 'error'}
          />
        ),
      },
      {
        accessor: 'message',
        title: t('w.message'),
        sortable: true,
        ellipsis: true,
      },
    ],
    [lang]
  );

  return (
    <div>
      <Text fw={700}>
        {t('companyAccountant.import.fileName', importResult.fileName)}
      </Text>
      <Text c="green" fw={700}>
        {t('companyAccountant.import.nbCreated', importResult.nbCreated)}
      </Text>
      <Text c="red" fw={700} mb="md">
        {t('companyAccountant.import.nbErrors', importResult.nbErrors)}
      </Text>
      <Table
        pinFirstColumn
        pinLastColumn
        idAccessor={'lineNb'}
        columns={columns}
        rows={importResult.importClientReports}
        lang={lang}
        height={'calc(100vh - 300px)'}
      />
    </div>
  );
}
