import {
  Button,
  ColorPicker,
  Group,
  MultiSelect,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { isNotEmpty, useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconDeviceFloppy, IconX } from '@tabler/icons-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';

import { useI18n } from '../../../../contexts/I18nProvider';
import WhiteLabelService from '../../../../services/WhiteLabelService';
import { ModuleName } from '../../../../types/api/response/module';
import type { WhiteLabel as WhiteLabelObject } from '../../../../types/types';
import handleErrorMessage from '../../../../utils/handleErrorMessage';

type Props = {
  paymentAccountId: string;
  whiteLabel: WhiteLabelObject;
};

export default function WhiteLabel({ paymentAccountId, whiteLabel }: Props) {
  const { t } = useI18n();
  const theme = useMantineTheme();
  const queryClient = useQueryClient();
  const ALL_MODULES = Object.values(ModuleName).map((moduleName) => ({
    value: moduleName,
    label: t(`module.${moduleName}`),
  }));

  const { mutate: saveWhiteLabel, isLoading } = useMutation({
    mutationFn: (payload: WhiteLabelObject) =>
      WhiteLabelService.updateOrCreate(paymentAccountId, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['WhiteLabelService.getWhiteLabel', paymentAccountId],
      });
      showNotification({
        id: 'updateOrCreate-success',
        message: t('w.success'),
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: (error) => {
      showNotification({
        id: 'updateOrCreate-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        icon: <IconX />,
        color: 'red',
      });
    },
  });

  const form = useForm<WhiteLabelObject>({
    initialValues: {
      ...whiteLabel,
      paymentAccountId: whiteLabel.paymentAccountId || paymentAccountId,
      primaryColor: whiteLabel.primaryColor || theme.colors.indigo[5],
      secondaryColor: whiteLabel.secondaryColor || theme.colors.indigo[3],
      activeModules: whiteLabel.activeModules || [],
    },
    validate: {
      name: isNotEmpty(t('w.required')),
      dns: isNotEmpty(t('w.required')),
      logoPath: isNotEmpty(t('w.required')),
      favicon: isNotEmpty(t('w.required')),
      activeModules: isNotEmpty(t('w.required')),
    },
  });

  return (
    <form
      onSubmit={form.onSubmit((_whiteLabel) => saveWhiteLabel(_whiteLabel))}
    >
      <TextInput
        label={t('w.name')}
        placeholder={'name placeholder'}
        {...form.getInputProps('name')}
        mb={'md'}
      />
      <TextInput
        label={'DNS'}
        placeholder={'dns placeholder'}
        {...form.getInputProps('dns')}
        mb={'md'}
      />
      <TextInput
        label={'Logo'}
        placeholder={'logoPath placeholder'}
        {...form.getInputProps('logoPath')}
        mb={'md'}
      />
      <TextInput
        label={'Favicon'}
        placeholder={'favicon placeholder'}
        {...form.getInputProps('favicon')}
        mb={'md'}
      />

      <Group>
        <div>
          <Text size={'sm'}>{t('w.primaryColor')}</Text>
          <ColorPicker {...form.getInputProps('primaryColor')} />
        </div>
        <div>
          <Text size={'sm'}>{t('w.secondaryColor')}</Text>
          <ColorPicker {...form.getInputProps('secondaryColor')} />
        </div>
      </Group>

      <MultiSelect
        mt="md"
        mb="md"
        label={t('w.features')}
        data={ALL_MODULES}
        searchable
        hidePickedOptions
        {...form.getInputProps('activeModules')}
      />

      <Group justify={'flex-end'}>
        <Button
          type={'submit'}
          loading={isLoading}
          leftSection={<IconDeviceFloppy />}
        >
          {t('w.save')}
        </Button>
      </Group>
    </form>
  );
}
