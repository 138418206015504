import { SimpleGrid, Skeleton, Title } from '@mantine/core';
import { modals } from '@mantine/modals';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';

import FileViewer from '../../../components/FileViewer/FileViewer';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import GererMesAffairesService from '../../../services/GererMesAffairesService';
import PayslipService from '../../../services/PayslipService';
import { PayslipGma, PayslipsFromGma, PayslipUser } from '../../../types/types';
import { getPersistedObject } from '../../../utils/localStorage';
import RecentPayslipsUserItem from './RecentPayslipsUserItem';

type Props = {
  userName: string;
  myPayslips: PayslipUser[];
  userId: string | undefined;
  companyId: string;
  gmaPayslips?: PayslipsFromGma | null;
};

export default function RecentPayslipsUser({
  myPayslips,
  userId,
  userName,
  companyId,
  gmaPayslips,
}: Props) {
  const { access_token } = useAuth();
  const { t } = useI18n();
  const [loading, setLoading] = useState<boolean>(false);
  const [fileLeft, setFileLeft] = useState<string>('');
  const [fileRight, setFileRight] = useState<string>('');
  const [fileType, setFileType] = useState<string>('');

  const payslips: PayslipUser[] | PayslipGma[] | undefined = useMemo(() => {
    let _payslips;
    if (gmaPayslips) {
      _payslips = gmaPayslips.payslips;
    } else {
      _payslips = myPayslips;
    }

    if (_payslips?.length > 0) {
      return _payslips;
    }
  }, [gmaPayslips]);

  useEffect(() => {
    if (payslips && payslips?.length > 0) {
      loadPayslips(payslips[1], payslips[0]);
    }
  }, [payslips]);

  function loadPayslips(left: any, right?: any): void {
    loadPayslip(left?.year, left?.month, setFileLeft, left?.gmaPayslipId);
    if (right) {
      loadPayslip(right.year, right.month, setFileRight, right.gmaPayslipId);
    }
  }

  function loadPayslip(
    year: number,
    month: number,
    setFile: (file: string) => void,
    gmaPayslipId?: string
  ): void {
    let xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onloadstart = () => {
      setLoading(true);
    };
    xhr.onload = function () {
      if (xhr.status === 200) {
        const newBlob = new Blob([this.response], { type: 'application/pdf' });
        const data = window.URL.createObjectURL(newBlob);
        setFile(data);
      }

      setLoading(false);
      setFileType('pdf');
    };
    const gma = getPersistedObject('gma');
    xhr.open(
      'GET',
      gmaPayslips && gmaPayslipId
        ? GererMesAffairesService.getPrivatePayslip(
            userId,
            gmaPayslips.spaceId,
            gmaPayslipId,
            gmaPayslips.accessToken,
            gmaPayslips.refreshToken,
            gma?.scope ? gma.scope : 'collaborator'
          )
        : PayslipService.getUserPayslip(userId, year, month, companyId),
      true
    );
    xhr.setRequestHeader('Authorization', 'Bearer ' + access_token);
    xhr.send();
  }

  function handleClickPreviewPayroll(filePath: string, index: number): void {
    const month = myPayslips[index].month;
    const year = myPayslips[index].year;

    let link = document.createElement('a');
    link.href = filePath;
    link.download = `${userName}-${month}-${year}.pdf`;
    link.target = '_blank';

    const title = dayjs(new Date(year, month - 1)).format('MMMM YYYY');

    modals.openConfirmModal({
      id: `${userName}_${month}-${year}-modal`,
      title: (
        <Title size={'h3'} component="p">
          {title}
        </Title>
      ),
      fullScreen: true,
      children: (
        <FileViewer
          id={`${userName}_${month}-${year}`}
          srcUrl={filePath}
          title={`${userName}-${month}-${year}.pdf`}
        />
      ),
      labels: { cancel: t('w.cancel'), confirm: t('w.download') },
      onConfirm: () => link.click(),
    });
  }

  function getRecentPayslipUserTime(number: number): string | undefined {
    if (payslips) {
      return dayjs(
        new Date(payslips[number].year, payslips[number].month - 1)
      ).format('MMMM YYYY');
    }
  }

  return (
    <SimpleGrid cols={{ base: 1, md: 2 }} spacing="lg">
      {fileLeft && (
        <Skeleton visible={loading}>
          <RecentPayslipsUserItem
            time={getRecentPayslipUserTime(1)}
            fileType={fileType}
            filePath={fileLeft}
            onClick={() => handleClickPreviewPayroll(fileLeft, 1)}
          />
        </Skeleton>
      )}
      {fileRight && (
        <Skeleton visible={loading}>
          <RecentPayslipsUserItem
            time={getRecentPayslipUserTime(0)}
            fileType={fileType}
            filePath={fileRight}
            onClick={() => handleClickPreviewPayroll(fileRight, 0)}
          />
        </Skeleton>
      )}
    </SimpleGrid>
  );
}
