import type {
  BillingHistory,
  CreateCompanyPaymentAccountPayload,
  PaymentAccount,
  PaymentMethod,
} from '../types/types';
import { MS_BILLING_ENDPOINT } from '../variables/GlobalVariables';
import request from './AxiosClient';

function getPaymentAccounts(): Promise<PaymentAccount[]> {
  return request
    .get(`${MS_BILLING_ENDPOINT}/payment-account`)
    .then((res) => res?.data);
}

function updateStatus(
  newStatus: boolean,
  paymentAccountId: string
): Promise<PaymentAccount> {
  return request
    .patch(
      `${MS_BILLING_ENDPOINT}/payment-account/${paymentAccountId}/status`,
      null,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        params: { newStatus },
      }
    )
    .then((res) => res?.data);
}

function getBillingHistory(
  paymentAccountId: string
): Promise<BillingHistory[]> {
  return request
    .get(`${MS_BILLING_ENDPOINT}/history/payment-account/${paymentAccountId}`)
    .then((res) => res?.data);
}

function getInvoiceUrl(paymentAccountId: string, invoiceId: string): string {
  return `${MS_BILLING_ENDPOINT}/history/payment-account/${paymentAccountId}/${invoiceId}`;
}

function getStripePublishableKey(paymentAccountId: string) {
  return request
    .get(`${MS_BILLING_ENDPOINT}/stripe/${paymentAccountId}/publishable-key`)
    .then((res) => res?.data);
}

function removeCompany(paymentAccountId: string, companyId: string) {
  return request
    .delete(
      `${MS_BILLING_ENDPOINT}/payment-account/${paymentAccountId}/company/${companyId}`
    )
    .then((res) => res?.data);
}

function getPaymentMethods(paymentAccountId: string): Promise<PaymentMethod[]> {
  return request
    .get(
      `${MS_BILLING_ENDPOINT}/payment-account/${paymentAccountId}/payment-methods`
    )
    .then((res) => res?.data);
}

function deletePaymentMethod(
  paymentAccountId: string,
  paymentMethodId: string
) {
  return request
    .delete(
      `${MS_BILLING_ENDPOINT}/payment-account/${paymentAccountId}/payment-methods/${paymentMethodId}`
    )
    .then((res) => res?.data);
}

function setDefaultPaymentMethod(
  paymentAccountId: string,
  paymentMethodId: string
) {
  return request
    .patch(
      `${MS_BILLING_ENDPOINT}/payment-account/${paymentAccountId}/payment-methods/${paymentMethodId}`,
      null,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then((res) => res?.data);
}

function addPaymentMethod(
  paymentAccountId: string,
  setupIntentPaymentMethod: string
) {
  return request
    .post(
      `${MS_BILLING_ENDPOINT}/payment-account/${paymentAccountId}/payment-methods/${setupIntentPaymentMethod}`,
      null,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then((res) => res?.data);
}

function getStripeClientSecret(paymentAccountId: string) {
  return request
    .get(`${MS_BILLING_ENDPOINT}/stripe/${paymentAccountId}/client-secret`)
    .then((res) => res?.data);
}

function getCompanyPaymentAccount(companyId: string): Promise<PaymentAccount> {
  return request
    .get(`${MS_BILLING_ENDPOINT}/payment-account/company/${companyId}`)
    .then((res) => res?.data);
}

function createCompanyPaymentAccount(
  companyId: string,
  payload: CreateCompanyPaymentAccountPayload
): Promise<PaymentAccount> {
  return request
    .post(
      `${MS_BILLING_ENDPOINT}/payment-account/company/${companyId}`,
      payload
    )
    .then((res) => res?.data);
}

const PaymentAccountService = {
  getPaymentAccounts,
  updateStatus,
  getBillingHistory,
  getInvoiceUrl,
  getStripePublishableKey,
  removeCompany,
  getPaymentMethods,
  deletePaymentMethod,
  setDefaultPaymentMethod,
  addPaymentMethod,
  getStripeClientSecret,
  getCompanyPaymentAccount,
  createCompanyPaymentAccount,
};

export default PaymentAccountService;
