import { ActionIcon, Alert, Group, rem, Text, Tooltip } from '@mantine/core';
import { TimeInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import {
  IconArrowRight,
  IconCalendarClock,
  IconCheck,
  IconClock,
  IconClockPlay,
  IconDeviceFloppy,
  IconTrash,
  IconX,
} from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import * as React from 'react';
import { useRef } from 'react';

import { useAuth } from '../../contexts/AuthProvider';
import { useI18n } from '../../contexts/I18nProvider';
import TimeRecorderService from '../../services/TimeRecorderService';
import { BuiltInPermissions, ScopeEnum } from '../../types/api/response/role';
import { TimeRecorderRequest } from '../../types/types';
import { hasPermission } from '../../utils/authorization';
import handleErrorMessage from '../../utils/handleErrorMessage';
import { LeaveEvent } from './DayEventsCell';

dayjs.extend(duration);

type Props = {
  userId: string;
  timeRecorder: LeaveEvent;
  linkedDailyWork?: LeaveEvent;
  onClose: () => void;
  refresh?: () => void;
};

export default function EditTimeRecorder({
  userId,
  timeRecorder,
  linkedDailyWork,
  onClose,
  refresh,
}: Props) {
  const { user } = useAuth();
  const { t } = useI18n();
  const form = useForm({
    initialValues: {
      startDate: dayjs(timeRecorder.startDate).format('HH:mm'),
      endDate: dayjs(timeRecorder.endDate).format('HH:mm'),
    },
  });
  const canEdit = hasPermission(
    {
      permission: BuiltInPermissions.AuthorizedUpdateTimeRecorder,
      scope: ScopeEnum.ALL,
    },
    user
  ); //|| isAccountant(user, companyId);
  const refStart = useRef<HTMLInputElement>(null);
  const refEnd = useRef<HTMLInputElement>(null);
  const { mutate: updateTimeRecorder } = useMutation({
    mutationFn: (payload: TimeRecorderRequest) =>
      TimeRecorderService.update(userId, timeRecorder.id, payload),
    onSuccess: () => {
      showNotification({
        id: 'update-time-recorder-success',
        message: t('w.success'),
        color: 'green',
        icon: <IconCheck />,
      });
      refresh && refresh();
      onClose();
    },
    onError: (error) => {
      showNotification({
        id: 'update-time-recorder-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      });
    },
  });
  const { mutate: deleteTimeRecorder } = useMutation({
    mutationFn: () => TimeRecorderService.deleteOne(userId, timeRecorder.id),
    onSuccess: () => {
      showNotification({
        id: 'delete-time-recorder-success',
        message: t('w.success'),
        color: 'green',
        icon: <IconCheck />,
      });
      refresh && refresh();
      onClose();
    },
    onError: (error) => {
      showNotification({
        id: 'delete-time-recorder-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      });
    },
  });

  function pickerDateControl(ref: React.RefObject<HTMLInputElement>) {
    return (
      <ActionIcon
        variant="subtle"
        color="gray"
        onClick={() => ref.current?.showPicker()}
      >
        <IconClock style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
      </ActionIcon>
    );
  }

  function getTimesRecordedColor(): 'green' | 'orange' {
    if (!linkedDailyWork) {
      return 'green';
    }

    if (
      linkedDailyWork.startDate.toISOString() ===
        timeRecorder.startDate.toISOString() &&
      linkedDailyWork.endDate.toISOString() ===
        timeRecorder.endDate.toISOString()
    ) {
      return 'green';
    }

    return 'orange';
  }

  function getStartError(): string | undefined {
    if (!linkedDailyWork) {
      return undefined;
    }

    const diff =
      linkedDailyWork.startDate.valueOf() - timeRecorder.startDate.valueOf();

    if (diff === 0) {
      return undefined;
    }

    if (diff > 0) {
      return (
        'En avance de ' +
        dayjs.duration(diff, 'milliseconds').format('mm') +
        ' minutes'
      );
    }

    return (
      'En retard de ' +
      dayjs.duration(diff * -1, 'milliseconds').format('mm') +
      ' minutes'
    );
  }

  function getEndError(): string | undefined {
    if (!linkedDailyWork) {
      return undefined;
    }

    const diff =
      linkedDailyWork.endDate.valueOf() - timeRecorder.endDate.valueOf();

    if (diff === 0) {
      return undefined;
    }

    if (diff > 0) {
      return (
        'En avance de ' +
        dayjs.duration(diff, 'milliseconds').format('mm') +
        ' minutes'
      );
    }

    return (
      'En retard de ' +
      dayjs.duration(diff * -1, 'milliseconds').format('mm') +
      ' minutes'
    );
  }

  function deleteTimeRecorderClick(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    event.preventDefault();
    event.stopPropagation();
    deleteTimeRecorder();
  }

  return (
    <div>
      <Alert
        variant="light"
        color="blue"
        title={t('w.linkedPresencePeriod')}
        icon={<IconCalendarClock />}
      >
        {linkedDailyWork && (
          <Group>
            <Text>{linkedDailyWork.startDate.format('HH:mm')} </Text>
            <IconArrowRight />
            <Text>{linkedDailyWork.endDate.format('HH:mm')}</Text>
          </Group>
        )}
      </Alert>
      <Alert
        mt="md"
        variant="light"
        color={getTimesRecordedColor()}
        title={t('w.timesRecordered')}
        icon={<IconClockPlay />}
      >
        <form
          onSubmit={form.onSubmit((values) =>
            updateTimeRecorder({
              startDate: `${timeRecorder.startDate.format('DD/MM/YYYY')} ${
                values.startDate
              }`,
              endDate: `${timeRecorder.endDate.format('DD/MM/YYYY')} ${
                values.endDate
              }`,
              workUnitId: timeRecorder.division,
            })
          )}
        >
          <Group>
            {canEdit ? (
              <>
                <TimeInput
                  ref={refStart}
                  rightSection={pickerDateControl(refStart)}
                  {...form.getInputProps('startDate')}
                  error={getStartError()}
                />
                <IconArrowRight />
                <TimeInput
                  ref={refEnd}
                  rightSection={pickerDateControl(refEnd)}
                  {...form.getInputProps('endDate')}
                  error={getEndError()}
                />
                <Tooltip label={t('w.save')}>
                  <ActionIcon
                    variant={'light'}
                    color="blue"
                    size="xl"
                    type={'submit'}
                  >
                    <IconDeviceFloppy
                      style={{ width: '70%', height: '70%' }}
                      stroke={1.5}
                    />
                  </ActionIcon>
                </Tooltip>
                <Tooltip label={t('w.delete')}>
                  <ActionIcon
                    onClick={deleteTimeRecorderClick}
                    variant={'light'}
                    color="red"
                    size="xl"
                  >
                    <IconTrash
                      style={{ width: '70%', height: '70%' }}
                      stroke={1.5}
                    />
                  </ActionIcon>
                </Tooltip>
              </>
            ) : (
              <>
                <Text>{form.values.startDate}</Text>
                <IconArrowRight color={'var(--mantine-color-hifivework-5)'} />
                <Text>{form.values.endDate}</Text>
              </>
            )}
          </Group>
        </form>
      </Alert>
    </div>
  );
}
