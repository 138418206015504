import { Grid, TextInput } from '@mantine/core';
import React from 'react';

import ActionDeleteIcon from '../../../components/icons/ActionDeleteIcon';
import LabelText from '../../../components/LabelText/LabelText';
import { useI18n } from '../../../contexts/I18nProvider';

type Props = {
  index: number;
  form: any;
};

export default function UserIdentifiantsCompany({ index, form }: Props) {
  const { t } = useI18n();

  return (
    <Grid align="center" gutter="xl" mb={'sm'}>
      <Grid.Col span={{ xs: 5 }}>
        <TextInput
          label={<LabelText text={t('w.companyCode')} />}
          placeholder={t('w.noValue')}
          {...form.getInputProps(`identifiantsCompany.${index}.codeCompany`)}
        />
      </Grid.Col>
      <Grid.Col span={{ xs: 6 }}>
        <TextInput
          label={<LabelText text={t('w.serialNumber')} />}
          placeholder={t('w.noValue')}
          {...form.getInputProps(`identifiantsCompany.${index}.matricule`)}
        />
      </Grid.Col>
      <Grid.Col span={{ xs: 1 }}>
        <ActionDeleteIcon
          label={t('w.delete')}
          onClick={() => form.removeListItem('identifiantsCompany', index)}
        />
      </Grid.Col>
    </Grid>
  );
}
