import { Button, Group, SimpleGrid, Switch, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconDeviceFloppy } from '@tabler/icons-react';
import React from 'react';

import LabelText from '../../../../components/LabelText/LabelText';
import PhoneField from '../../../../components/PhoneField/PhoneField';
import { useI18n } from '../../../../contexts/I18nProvider';
import type {
  CharteredAccountantView,
  CompanyAccountantInfoViewClient,
} from '../../../../types/api/response/accountant';
import InputEmail from '../../../login/components/InputEmail';

type FormValues = {
  accountant: CharteredAccountantView;
  active: boolean;
  clientCompanyId: string;
};

type Props = {
  accountants: CompanyAccountantInfoViewClient;
  updateCustomerCompanyStatus: (payload: FormValues) => void;
};

export default function CustomerEditForm({
  accountants,
  updateCustomerCompanyStatus,
}: Props) {
  const { t } = useI18n();
  const form = useForm<FormValues>({
    initialValues: {
      accountant: accountants.accountant,
      active: accountants.active,
      clientCompanyId: accountants.clientCompanyId,
    },
    validate: {
      accountant: {
        mail: (val) =>
          val && (/^\S+@\S+$/.test(val) ? null : t('invalid.email')),
      },
    },
  });

  return (
    <form
      onSubmit={form.onSubmit((payload) =>
        updateCustomerCompanyStatus(payload)
      )}
    >
      <Switch
        label={t('w.active')}
        {...form.getInputProps('active', { type: 'checkbox' })}
      />
      <SimpleGrid cols={{ base: 1, sm: 2 }} mb="md" mt="md">
        <TextInput
          label={<LabelText text={t('w.name')} mb={2} />}
          {...form.getInputProps('accountant.name')}
        />
        <InputEmail
          value={form.values.accountant.mail}
          onChange={(event) =>
            form.setFieldValue('accountant.mail', event.currentTarget.value)
          }
          error={form.errors.email}
        />
        <PhoneField
          value={form.values.accountant.phone}
          onChange={(value) =>
            form.setFieldValue('accountant.phone', value as string)
          }
        />
        <TextInput
          label={<LabelText text={t('w.address')} mb={2} />}
          {...form.getInputProps('accountant.address')}
        />
      </SimpleGrid>
      <Group justify={'flex-end'}>
        <Button type={'submit'} leftSection={<IconDeviceFloppy />}>
          {t('w.save')}
        </Button>
      </Group>
    </form>
  );
}
