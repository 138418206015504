import { Group, Text } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';

import CustomUserAvatar from '../../../components/CustomAvatar/CustomUserAvatar';
import { useAuth } from '../../../contexts/AuthProvider';
import ProfileService from '../../../services/ProfileService';

type Props = {
  userId: string;
};

export default function ShuttleSheetCreatorWrapper({ userId }: Props) {
  const { access_token } = useAuth();

  const { data: userProfile } = useQuery({
    queryKey: ['ProfileService.getUserProfile', userId],
    queryFn: () => ProfileService.getUserProfile(access_token, userId),
  });

  return (
    <Group gap={'xs'}>
      <CustomUserAvatar userId={userId} size={'md'} />
      {userProfile && (
        <Text fz={'sm'} fw={'bold'}>
          {userProfile.fullname}
        </Text>
      )}
    </Group>
  );
}
