import { showNotification } from '@mantine/notifications';
import { IconX } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useMemo } from 'react';

import { useAuth } from '../contexts/AuthProvider';
import { useI18n } from '../contexts/I18nProvider';
import CompanyService from '../services/CompanyService';
import { BuiltInPermissions, ScopeEnum } from '../types/api/response/role';
import { hasPermission } from '../utils/authorization';
import useCompany from './useCompany';

export default function useFetchManagers() {
  const { user } = useAuth();
  const { t } = useI18n();
  const { id: companyId } = useCompany(user);

  const {
    data: employees,
    isLoading: employeesIsLoading,
    isError: employeesIsError,
  } = useQuery({
    queryKey: ['CompanyService.listEmployees', companyId],
    queryFn: () =>
      CompanyService.listEmployees(companyId, [
        'fullname',
        'firstname',
        'lastname',
        'lastLoginDate',
        'securityRoleId',
        'divisionId',
        'companyId',
        'valid',
        'mail',
        'employment',
        'identifiantsCompany',
        'paidFeatures',
        'fixedPriceManager',
        'onBoardingId',
        'onBoarding',
      ]),
  });

  const managers = useMemo(() => {
    if (!employees) {
      return [];
    }

    const employeesFilter = employees.filter(
      (employee) =>
        Boolean(employee.valid) &&
        hasPermission(
          [
            {
              permission: BuiltInPermissions.ConfigureEmployees,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.ValidateLeavesAbsences,
              scope: ScopeEnum.ALL,
            },
          ],
          employee
        )
    );
    employeesFilter.unshift({ id: '', mail: t('w.noValue') });

    return employeesFilter.map((employee) => {
      return {
        value: employee.id,
        label: employee.fullname
          ? `${employee.fullname} (${employee.mail})`
          : employee.mail!,
      };
    });
  }, [employees]);

  useEffect(() => {
    if (employeesIsError) {
      showNotification({
        id: 'error-response',
        title: t('w.error'),
        message: t('error.error_happened'),
        color: 'red',
        icon: <IconX />,
      });
    }
  }, [employeesIsError]);

  return { managers, loading: employeesIsLoading };
}
