import { Flex, Group, Paper, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import classes from './CustomStatsGrid.module.sass';

export type StatsGridData = {
  title: string;
  icon: any;
  value: number | string;
  url?: string;
};

type Props = {
  data: StatsGridData[];
};

export default function CustomStatsGrid({ data }: Props) {
  const navigate = useNavigate();

  const stats = data.map((stat, index) => {
    const Icon = stat.icon;

    return (
      <Paper
        styles={{
          root: {
            cursor: stat.url ? 'pointer' : 'default',
          },
        }}
        w={400}
        withBorder
        p="sm"
        radius="md"
        key={`${stat.title}-${index}`}
        onClick={() => (stat.url ? navigate(stat.url) : null)}
      >
        <Group justify="space-between">
          <Text fz={'sm'} c="dimmed" className={classes.title}>
            {stat.title}
          </Text>
          <Icon className={classes.icon} size="1.8rem" stroke={1.5} />
        </Group>

        <Group align="flex-end" gap="xs" mt={25}>
          <Text className={classes.value}>{stat.value}</Text>
        </Group>
      </Paper>
    );
  });

  return (
    <div className={classes.root}>
      <Flex
        direction={{ base: 'column', sm: 'row' }}
        gap={{ base: 'sm', sm: 'lg' }}
        justify={'space-between'}
      >
        {stats}
      </Flex>
    </div>
  );
}
