import { Box, Button, Flex, Paper, Space, Text } from '@mantine/core';
import { IconEye } from '@tabler/icons-react';

import FileViewer from '../../../components/FileViewer/FileViewer';
import { useI18n } from '../../../contexts/I18nProvider';

type Props = {
  time: string | undefined;
  fileType: string;
  filePath: string;
  onClick: () => void;
};

export default function RecentPayslipsUserItem({
  time,
  filePath,
  onClick,
}: Props) {
  const { t } = useI18n();
  return (
    <Paper p="md" withBorder h={400}>
      <Text fz={'lg'} fw={'bold'} c={'hifivework.5'}>
        {time}
      </Text>
      <Space h={'md'} />
      <Box h={250} style={{ overflow: 'hidden' }}>
        <FileViewer
          id={`payslip-${time}`}
          srcUrl={filePath}
          title={`payslip-${time}`}
        />
      </Box>
      <Space h={'md'} />
      <Flex justify={'flex-end'}>
        <Button leftSection={<IconEye />} variant={'outline'} onClick={onClick}>
          {t('w.see')}
        </Button>
      </Flex>
    </Paper>
  );
}
