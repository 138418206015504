import { MantineColor } from '@mantine/core';

import type { ExpenseStatusState } from '../types/types';

export function getBadgeColor(
  state: ExpenseStatusState
): MantineColor | undefined {
  switch (state) {
    case 'WAITING':
      return '#FCF0E0';
    case 'REJECTED':
      return '#FDE6ED';
    case 'REFUNDED':
      return '#CEE9E6';
    default:
      return '#cde1fc';
  }
}

export function getBadgeLabelColor(state: ExpenseStatusState): string {
  switch (state) {
    case 'WAITING':
      return '#EDB464';
    case 'REJECTED':
      return '#E70D4F';
    case 'REFUNDED':
      return '#0A9182';
    default:
      return '#10069F';
  }
}

export function getTotalHT(additionValueVATList: number[], amount: number) {
  let totalHT = amount;
  additionValueVATList?.forEach((VATValue) => (totalHT -= VATValue));
  return totalHT;
}

export function getTotalVAT(additionValueVATList: number[]) {
  let VAT = 0;
  additionValueVATList?.forEach((VATValue) => (VAT += VATValue));
  return VAT;
}
