import React from 'react';

import s from './FileView.module.sass';

type Props = {
  id: string;
  srcUrl: string;
  title: string;
};
export default function FileViewer({ id, srcUrl, title }: Props) {
  return (
    <div className={s.root}>
      <iframe id={id} src={srcUrl} title={title} className={s.iframe} />
    </div>
  );
}
