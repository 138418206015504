import { Button, Title } from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconFileImport } from '@tabler/icons-react';

import { useI18n } from '../../../../contexts/I18nProvider';
import ImportPayslipsFormStepper from './ImportPayslipsFormStepper';

type Props = {
  companyId: string;
};

export default function ImportPayslipsButton({ companyId }: Props) {
  const { t } = useI18n();
  function handleOpenImportPayslipsModalClick() {
    modals.open({
      modalId: 'import-payslips-modal',
      title: (
        <Title size={'h3'} component="p">{`${t('w.import')} ${t(
          'w.payslips'
        ).toLowerCase()}`}</Title>
      ),
      size: 'xxl',
      children: (
        <ImportPayslipsFormStepper
          companyId={companyId}
          closeModal={() => modals.close('import-payslips-modal')}
        />
      ),
    });
  }

  return (
    <Button
      leftSection={<IconFileImport size={15} />}
      onClick={handleOpenImportPayslipsModalClick}
    >
      {t('w.import')}
    </Button>
  );
}
