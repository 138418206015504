import { Image } from '@mantine/core';

import { useI18n } from '../../../../contexts/I18nProvider';
import type { CompanyResponse } from '../../../../types/api/response/company';

type Props = {
  company: CompanyResponse;
};

export default function CompanyCountryFlag({ company }: Props) {
  const { t } = useI18n();

  return (
    <Image
      alt={t(`key.${company.countryCode}`)}
      src={`/images/flags/${company?.countryCode?.toLowerCase()}.png`}
      h={30}
      w={60}
      radius="sm"
    />
  );
}
