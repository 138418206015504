import { Group, Select, SelectProps } from '@mantine/core';
import { useMemo } from 'react';

import LabelText from '../../../components/LabelText/LabelText';
import { useI18n } from '../../../contexts/I18nProvider';
import { CountryEnum } from '../../../types/api/response/company';

const countries = Object.values(CountryEnum);

type Props = {
  value: string | null;
  onChange?: (value: CountryEnum) => void;
  label: string;
};

export default function CountriesSelect({
  value,
  onChange,
  label,
  ...restProps
}: Props & SelectProps) {
  const { t } = useI18n();
  const countriesData = useMemo(
    () =>
      countries.map((country) => {
        const urlImg = `/images/flags/${country.toLowerCase()}.png`;
        return {
          image: urlImg,
          label: t(`countriesEnum.${country.toLowerCase()}`),
          value: country,
        };
      }),
    []
  );
  const flagSrc = useMemo(() => {
    if (value) {
      return `/images/flags/${countries
        .find((country) => country.toLowerCase() === value.toLowerCase())
        ?.toLowerCase()}.png`;
    }
  }, [value]);

  return (
    <Select
      label={
        <Group gap="xs">
          <LabelText text={label} />
          <img
            height={15}
            src={flagSrc}
            alt={t(`countriesEnum.${value?.toLowerCase()}`)}
          />
        </Group>
      }
      placeholder={t('w.selectYourCountry')}
      value={value}
      onChange={(value) => {
        onChange?.(value as CountryEnum);
      }}
      data={countriesData}
      searchable
      maxDropdownHeight={400}
      nothingFoundMessage={t('w.noCountry')}
      {...restProps}
    />
  );
}
