import { Fieldset, Flex, SimpleGrid, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';

import ButtonSave from '../../../../components/ButtonSave';
import LabelText from '../../../../components/LabelText/LabelText';
import PhoneField from '../../../../components/PhoneField/PhoneField';
import { useI18n } from '../../../../contexts/I18nProvider';
import CompanyService from '../../../../services/CompanyService';
import type { UpdateCompanyRequest } from '../../../../types/api/payload/company';
import type { CompanyResponse } from '../../../../types/api/response/company';
import handleErrorMessage from '../../../../utils/handleErrorMessage';

type Props = {
  company: CompanyResponse;
};

export default function CompanySettingsForm({ company }: Props) {
  const { t } = useI18n();
  const queryClient = useQueryClient();

  const form = useForm<UpdateCompanyRequest>({
    initialValues: {
      name: company.name ? company.name : '',
      siret: company.siret ? company.siret : '',
      mail: company.mail ? company.mail : '',
      phone: company.phone ? company.phone : '',
      address: {
        address1: company.address?.address1 ? company.address.address1 : '',
        address2: company.address?.address2 ? company.address.address2 : '',
        zipCode: company.address?.zipCode ? company.address.zipCode : '',
        city: company.address?.city ? company.address.city : '',
      },
      codeApe: company.codeApe ? company.codeApe : '',
      healthService: company.healthService ? company.healthService : '',
    },
  });

  const { mutate: updateCompany, isLoading } = useMutation({
    mutationFn: (payload: UpdateCompanyRequest) =>
      CompanyService.updateCompany(company.id, {
        ...payload,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['CompanyService.getCompany', company.id],
      });
      showNotification({
        id: `update-company-successful`,
        message: t('w.success'),
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: (error) =>
      showNotification({
        id: `update-company-error`,
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  return (
    <form onSubmit={form.onSubmit((values) => updateCompany(values))}>
      <Flex direction="column" gap="md">
        <Fieldset legend={t('w.general')}>
          <SimpleGrid cols={2}>
            <TextInput
              required
              label={<LabelText text={t('w.name')} />}
              {...form.getInputProps('name')}
            />
            <TextInput
              label={<LabelText text={t('w.duns')} />}
              {...form.getInputProps('siret')}
              placeholder={t('w.optional')}
            />
          </SimpleGrid>
          <SimpleGrid cols={2}>
            <TextInput
              label={<LabelText text={t('w.companyCodeApe')} />}
              {...form.getInputProps('codeApe')}
            />
            <TextInput
              label={<LabelText text={t('w.healthService')} />}
              {...form.getInputProps('healthService')}
            />
          </SimpleGrid>
        </Fieldset>
        <Fieldset legend={t('w.contactDetails')}>
          <SimpleGrid cols={2}>
            <TextInput
              disabled
              label={<LabelText text={t('w.companyMail')} />}
              {...form.getInputProps('mail')}
            />
            <PhoneField
              value={form.values.phone}
              onChange={(value) => form.setFieldValue('phone', value)}
            />
          </SimpleGrid>
        </Fieldset>
        <Fieldset legend={t('w.address')}>
          <SimpleGrid cols={4}>
            <TextInput
              label={<LabelText text={t('w.companyAddress')} />}
              {...form.getInputProps('address.address1')}
            />
            <TextInput
              label={<LabelText text={t('w.companyAddress2')} />}
              {...form.getInputProps('address.address2')}
            />
            <TextInput
              label={<LabelText text={t('w.zipCode')} />}
              {...form.getInputProps('address.zipCode')}
            />
            <TextInput
              label={<LabelText text={t('w.city')} />}
              {...form.getInputProps('address.city')}
            />
          </SimpleGrid>
        </Fieldset>

        <Flex justify={'flex-end'}>
          <ButtonSave isLoading={isLoading} />
        </Flex>
      </Flex>
    </form>
  );
}
