import { Button, Flex, Select, SimpleGrid, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconCheck,
  IconCirclePlus,
  IconEdit,
  IconX,
} from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';

import LabelText from '../../../../../../components/LabelText/LabelText';
import { useI18n } from '../../../../../../contexts/I18nProvider';
import VariablePayrollService, {
  CreateVariablePayrollPayload,
} from '../../../../../../services/VariablePayrollService';
import {
  type VariablePayroll,
  VariablePayrollType,
} from '../../../../../../types/api/response/module';
import handleErrorMessage from '../../../../../../utils/handleErrorMessage';

type Props = {
  companyId: string;
  isCegidExpertModuleActive: boolean | undefined;
  isOpenpayeModuleActive: boolean | undefined;
  isIsapayeModuleActive: boolean | undefined;
  isDiapaieModuleActive: boolean | undefined;
  isSilaeModuleActive: boolean | undefined;
  isQuadraModuleActive: boolean | undefined;
  refetch: () => void;
  variable?: VariablePayroll;
};

export default function VariablePayrollForm({
  variable,
  companyId,
  isCegidExpertModuleActive,
  isOpenpayeModuleActive,
  isIsapayeModuleActive,
  isDiapaieModuleActive,
  isSilaeModuleActive,
  isQuadraModuleActive,
  refetch,
}: Props) {
  const { t } = useI18n();
  const form = useForm<VariablePayroll>({
    initialValues: {
      label: '',
      type: VariablePayrollType.EUROS,
      editable: true,
    },
  });

  useEffect(() => {
    if (variable) {
      form.setInitialValues(variable);
      form.setValues(variable);
    }
  }, [variable]);

  const types: { value: string; label: string }[] = useMemo(
    () => [
      {
        value: VariablePayrollType.EUROS,
        label: t('addVariablePayroll.type.EUROS'),
      },
      {
        value: VariablePayrollType.DAYS,
        label: t('addVariablePayroll.type.DAYS'),
      },
      {
        value: VariablePayrollType.HOURS,
        label: t('addVariablePayroll.type.HOURS'),
      },
      {
        value: VariablePayrollType.NUMBERS,
        label: t('addVariablePayroll.type.NUMBERS'),
      },
      {
        value: VariablePayrollType.TEXT,
        label: t('addVariablePayroll.type.TEXT'),
      },
      {
        value: VariablePayrollType.FILE,
        label: t('addVariablePayroll.type.FILE'),
      },
    ],
    []
  );

  function showErrorNotification<TError>(error: TError, text: string) {
    return showNotification({
      id: `${text}-variable-payroll-error`,
      title: t('w.success'),
      message: handleErrorMessage(error, t),
      color: 'red',
      icon: <IconX />,
    });
  }

  const { mutate: addVariablePayroll, isLoading: isAddVariablePayrollLoading } =
    useMutation({
      mutationFn: (variables: CreateVariablePayrollPayload) =>
        VariablePayrollService.addVariablePayroll(companyId, variables),
      onSuccess: (data, variables) => {
        const { label } = variables;
        showNotification({
          id: `create-variable-payroll-${label}-successful`,
          title: t('w.success'),
          message: `${label} ${t('w.created').toLowerCase()}`,
          color: 'green',
          icon: <IconCheck />,
        });
        refetch();
        modals.close(`create-variable-payroll-modal`);
      },
      onError: (error) => showErrorNotification(error, 'create'),
    });

  const {
    mutate: updateVariablePayroll,
    isLoading: isUpdateVariablePayrollLoading,
  } = useMutation({
    mutationFn: (variables: VariablePayroll) =>
      VariablePayrollService.updateVariablePayroll(companyId, variables),
    onSuccess: (data, variables) => {
      const { id, label } = variables;
      showNotification({
        id: `update-variable-payroll-${id}-successful`,
        title: t('w.success'),
        message: `${label} ${t('w.updated').toLowerCase()}`,
        color: 'green',
        icon: <IconCheck />,
      });
      refetch();
      modals.close(`variable-payroll-${variable?.id}-edit-modal`);
    },
    onError: (error) => showErrorNotification(error, 'update'),
  });

  function handleVariablePayrollFormSubmit(values: VariablePayroll): void {
    if (variable) {
      updateVariablePayroll(values);
    } else {
      addVariablePayroll(values);
    }
  }

  return (
    <form onSubmit={form.onSubmit(handleVariablePayrollFormSubmit)}>
      <SimpleGrid cols={{ base: 1, md: 2 }}>
        <TextInput
          required
          label={<LabelText text={t('w.name')} />}
          {...form.getInputProps('label')}
          data-autofocus
          placeholder={t('w.enterText')}
        />
        <Select
          required
          data={types}
          label={<LabelText text={'Type'} />}
          {...form.getInputProps('type')}
          placeholder={t('w.select')}
        />
        {isQuadraModuleActive && (
          <TextInput
            label={<LabelText text={'Code Quadra'} />}
            {...form.getInputProps('codeQuadra')}
            placeholder={t('w.enterText')}
          />
        )}
        {isSilaeModuleActive && (
          <TextInput
            label={<LabelText text={'Code Silae'} />}
            {...form.getInputProps('codeSilae')}
            placeholder={t('w.enterText')}
          />
        )}
        {isDiapaieModuleActive && (
          <TextInput
            label={<LabelText text={'Code Diapaie'} />}
            {...form.getInputProps('codeDiapaie')}
            placeholder={t('w.enterText')}
          />
        )}
        {isIsapayeModuleActive && (
          <TextInput
            label={<LabelText text={'Code Isapaye'} />}
            {...form.getInputProps('codeIsapaye')}
            placeholder={t('w.enterText')}
          />
        )}
        {isOpenpayeModuleActive && (
          <TextInput
            label={<LabelText text={'Code Openpaye'} />}
            {...form.getInputProps('codeOpenpaye')}
            placeholder={t('w.enterText')}
          />
        )}
        {isCegidExpertModuleActive && (
          <TextInput
            label={<LabelText text={'Code Cegid Expert'} />}
            {...form.getInputProps('codeCegidExpert')}
            placeholder={t('w.enterText')}
          />
        )}
      </SimpleGrid>
      <Flex mt={'xl'} justify={'flex-end'}>
        <Button
          loading={
            isAddVariablePayrollLoading || isUpdateVariablePayrollLoading
          }
          type={'submit'}
          leftSection={variable ? <IconEdit /> : <IconCirclePlus />}
        >
          {variable ? t('w.edit') : t('w.add')}
        </Button>
      </Flex>
    </form>
  );
}
