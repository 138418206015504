import { ActionIcon, Badge, Popover, Select, Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import {
  IconBuilding,
  IconMessage,
  IconMessagePlus,
} from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useState } from 'react';

import { useI18n } from '../../contexts/I18nProvider';
import DailyWorkService, {
  UserPresenceHelper,
} from '../../services/DailyWorkService';
import type { DailyWorkDetails } from '../../types/api/response/dailyWork';
import handleErrorMessage from '../../utils/handleErrorMessage';
import CustomTooltip from '../CustomTooltip';
import AddComment from './AddComment';
import css from './MonthEventScheduler.module.sass';

export declare type SchedulerDailyWorkHeadDayCellProps = {
  dailyWork: DailyWorkDetails;
  refetchDailyWorksList: () => void;
  divisionsOptions: { value: string; label: string }[];
};

export default function SchedulerDailyWorkHeadDayCell({
  dailyWork,
  refetchDailyWorksList,
  divisionsOptions,
}: SchedulerDailyWorkHeadDayCellProps) {
  const { t } = useI18n();
  const isEditable: boolean = dailyWork.status === 'WAITING';
  const [opened, setOpened] = useState(false);

  const { mutate: updateComment } = useMutation({
    mutationFn: (newComment: string) => {
      const dailyWorkUpdate: DailyWorkDetails = {
        ...dailyWork,
        comment: newComment,
      };
      return DailyWorkService.updateUserDailyWork(
        dailyWork.user.id,
        UserPresenceHelper.toUserPresence(dailyWorkUpdate)
      );
    },
    onSuccess: () => {
      refetchDailyWorksList();
    },
    onError: (error) => handleErrorMessage(error, t),
  });

  const { mutate: updateDivisionConcerned } = useMutation({
    mutationFn: (divisionConcerned: string) => {
      const dailyWorkUpdate: DailyWorkDetails = {
        ...dailyWork,
        divisionConcerned: divisionConcerned,
      };
      return DailyWorkService.updateUserDailyWork(
        dailyWork.user.id,
        UserPresenceHelper.toUserPresence(dailyWorkUpdate)
      );
    },
    onSuccess: () => {
      refetchDailyWorksList();
      setOpened(false);
    },
    onError: (error) => handleErrorMessage(error, t),
  });

  function openAddComment() {
    modals.open({
      modalId: 'AddComment',
      title: t('dailyWork.addComment.title'),
      children: (
        <AddComment
          onCommentCreated={(comment: string) => {
            updateComment(comment);
          }}
          day={dayjs(dailyWork.date)}
          comment={dailyWork.comment}
        />
      ),
      size: 'lg',
      m: 'xl',
    });
  }

  function getDivisionConcernedLabel(
    divisionConcerned: string
  ): string | undefined {
    const _divisionConcerned = divisionConcerned
      ? divisionConcerned
      : dailyWork.user.division.id;
    const find = divisionsOptions.find(
      (item) => item.value === _divisionConcerned
    );
    return find?.label;
  }

  return (
    <div
      className={css['scheduler-header-row-scrollable-cells-cell']}
      key={dayjs(dailyWork.date).get('date')}
      style={{ position: 'relative' }}
    >
      <div style={{ position: 'absolute', left: 0, bottom: -5 }}>
        <Popover
          width={300}
          position="bottom"
          withArrow
          shadow="md"
          opened={opened}
          onChange={setOpened}
          trapFocus
        >
          <CustomTooltip
            label={getDivisionConcernedLabel(dailyWork.divisionConcerned)}
          >
            <Popover.Target>
              <ActionIcon
                onClick={() => setOpened(true)}
                variant={'subtle'}
                color="gray"
                size={32}
                disabled={!isEditable}
              >
                <IconBuilding />
              </ActionIcon>
            </Popover.Target>
          </CustomTooltip>
          <Popover.Dropdown>
            <Select
              value={
                dailyWork.divisionConcerned
                  ? dailyWork.divisionConcerned
                  : dailyWork.user.division.id
              }
              data={divisionsOptions}
              comboboxProps={{ withinPortal: false }}
              onChange={(value) => updateDivisionConcerned(value as string)}
            />
          </Popover.Dropdown>
        </Popover>
      </div>
      <Text size="sm">
        {dayjs(dailyWork.date).format('ddd')}
        <br />
        {dayjs(dailyWork.date).format('D')}
      </Text>
      <div style={{ position: 'absolute', right: 0, bottom: -5 }}>
        <CustomTooltip
          label={t('dailyWork.addComment.title')}
          disabled={!isEditable}
        >
          <ActionIcon
            variant={'subtle'}
            color="gray"
            size={32}
            onClick={openAddComment}
            disabled={!isEditable}
          >
            {dailyWork.comment != null ? <IconMessage /> : <IconMessagePlus />}
          </ActionIcon>
        </CustomTooltip>
        {dailyWork.comment != null &&
          dailyWork.comment?.trim().length !== 0 && (
            <div style={{ position: 'absolute', right: 5, bottom: 20 }}>
              <CustomTooltip label={dailyWork.comment}>
                <Badge size="sm" circle style={{ cursor: 'default' }}>
                  1
                </Badge>
              </CustomTooltip>
            </div>
          )}
      </div>
    </div>
  );
}
