import { Fieldset, Group, Text } from '@mantine/core';

import CustomUserAvatar from '../../../../components/CustomAvatar/CustomUserAvatar';
import classes from './WorkUnitsInfos.module.sass';
import WorkUnitsTextInfos from './WorkUnitsTextInfos';

export type ValidatorInfosWrapperProps = {
  userId: string;
  userFullName: string;
  validatorType: string;
};

export default function ValidatorInfosWrapper({
  userId,
  userFullName,
  validatorType,
}: ValidatorInfosWrapperProps) {
  return (
    <Fieldset
      legend={
        <Text fz={'xs'} fw={'bold'} c={'dimmed'}>
          {validatorType}
        </Text>
      }
      className={classes.wrapper}
    >
      <Group wrap="nowrap">
        <CustomUserAvatar userId={userId} />
        <WorkUnitsTextInfos text={userFullName} />
      </Group>
    </Fieldset>
  );
}
