import { Button, Flex, Grid, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { modals } from '@mantine/modals';
import { IconDeviceFloppy } from '@tabler/icons-react';
import React from 'react';

import { UpdateModuleParamsFunction } from '../../../../../components/ModuleToEditModal';
import { useI18n } from '../../../../../contexts/I18nProvider';
import type {
  DailyWorkParams,
  ModuleConfigurationParams,
  ModuleDetails,
} from '../../../../../types/api/response/module';

interface FormValues {
  payload: Payload;
}

type Props = {
  updateModuleParams: UpdateModuleParamsFunction;
  module: null | ModuleDetails;
  payload: null | Payload;
};

type Payload = {
  params: ModuleConfigurationParams;
  active: boolean | null | undefined;
};

export default function DailyworkConfirmToActive({
  updateModuleParams,
  module,
  payload,
}: Props) {
  const { t } = useI18n();
  const moduleParams = module?.configuration?.params as DailyWorkParams;
  const form = useForm<FormValues>({
    initialValues: {
      payload: {
        params: moduleParams,
        active: payload?.active,
      },
    },
  });

  function handleUpdateDailyworkModule(
    values: ReturnType<(values: FormValues) => FormValues>
  ) {
    if (module) {
      updateModuleParams({
        apiUrl: module.description.url,
        payload: values.payload,
      });
      modals.closeAll();
    }
  }

  return (
    <form
      onSubmit={form.onSubmit((values) =>
        handleUpdateDailyworkModule({
          payload: values.payload,
        })
      )}
    >
      <Grid gutter="xl">
        <Grid.Col>
          <Text>
            Vous allez activer la fonctionnalité payante gestion des temps. Vous
            pourrez l'activer individuellement pour vos employés. Cette
            fonctionnalité vous sera facturé **€HT/mois/utilisateur actif.
          </Text>
        </Grid.Col>
      </Grid>
      <Flex
        justify={'flex-end'}
        gap={'md'}
        direction="row"
        wrap="wrap"
        mt={'md'}
      >
        <Button type={'submit'} leftSection={<IconDeviceFloppy />}>
          {t('w.confirm')}
        </Button>
      </Flex>
    </form>
  );
}
