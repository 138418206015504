import { ActionIcon, Badge } from '@mantine/core';
import { MantineSize } from '@mantine/core/lib/core';
import { IconUserX } from '@tabler/icons-react';
import { ReactNode } from 'react';

import CustomUserAvatar from '../../../../components/CustomAvatar/CustomUserAvatar';
import CustomTooltip from '../../../../components/CustomTooltip';
import { useI18n } from '../../../../contexts/I18nProvider';

type Props = {
  children: ReactNode;
  onClick: () => void;
  size: MantineSize | (string & {});
  userId: string;
};

export default function AccountantBadge({
  size,
  children,
  onClick,
  userId,
}: Props) {
  const { t } = useI18n();
  return (
    <Badge
      size={size}
      variant={'outline'}
      leftSection={<CustomUserAvatar userId={userId} size={20} />}
      rightSection={
        <CustomTooltip label={t('w.withdraw')}>
          <ActionIcon variant={'subtle'} onClick={onClick}>
            <IconUserX size={15} color={'red'} />
          </ActionIcon>
        </CustomTooltip>
      }
      styles={{
        label: {
          textTransform: 'none',
        },
      }}
    >
      {children}
    </Badge>
  );
}
