import { useQuery } from '@tanstack/react-query';

import CkModuleManagerServiceMS from '../services/CkModuleManagerServiceMS';
import { ViewModule } from '../types/api/payload/module';
import type { ModuleDetails, ModuleName } from '../types/api/response/module';

export default function useCompanyModules(companyId: string) {
  const { data: modules } = useQuery({
    enabled: !!companyId,
    queryKey: ['CkModuleManagerServiceMS.list', companyId],
    queryFn: () =>
      CkModuleManagerServiceMS.list(companyId, ViewModule.companyFeature),
  });

  function getModule(moduleName: ModuleName): ModuleDetails | undefined {
    if (!modules?.length) return undefined;

    return modules.find((module) => module.name === moduleName);
  }

  return { modules, getModule };
}
