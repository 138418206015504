import { Options, Table } from '@ckprivate/ckf-ui';
import {
  ActionIcon,
  Button,
  Group,
  SimpleGrid,
  Text,
  Title,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import {
  IconCircleCheck,
  IconCirclePlus,
  IconCircleX,
  IconExclamationCircle,
  IconEye,
  IconHourglassEmpty,
  IconProgressCheck,
} from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import { DataTableColumn } from 'mantine-datatable';
import React, { ReactNode, useMemo, useState } from 'react';

import { useI18n } from '../../../contexts/I18nProvider';
import ScreenWrapper from '../../../core/layouts/components/ScreenWrapper';
import ExpenseReportService from '../../../services/ExpenseReportService';
import type { UserResponse } from '../../../types/api/response/user';
import {
  UserExpenseReportSummary,
  UserExpenseReportSummaryDetail,
} from '../../../types/types';
import { dateToDayMonthLocal } from '../../../utils/format';
import {
  getHiddenColumns,
  saveHiddenColumns,
} from '../../../utils/optionsPreferences';
import { isHiFiveWorkProject } from '../../../utils/projects';
import ExpenseReportByMonthView from './ExpenseReportByMonthView';
import ExpenseReportInfosWrapper from './ExpenseReportInfosWrapper';
import ExpenseReportRequestForm from './ExpenseReportRequestForm';
import s from './ExpenseReportsUser.module.sass';

type Props = {
  userProfile: UserResponse;
};

const componentName = 'ExpenseReportsUser';

export default function ExpenseReportsUser({ userProfile }: Props) {
  const { t, lang } = useI18n();
  const [rows, setRows] = useState<UserExpenseReportSummaryDetail[]>([]);

  const {
    data: userExpenseReportsDetail,
    isLoading: isExpenseReportDetailsLoading,
    refetch: refetchUserExpenseReportDetail,
  } = useQuery({
    queryKey: ['ExpenseReportService.getUserSummary', userProfile.id],
    queryFn: () => ExpenseReportService.getUserSummary(userProfile.id),
  });

  const userSummary: UserExpenseReportSummary = useMemo(() => {
    if (!userExpenseReportsDetail) {
      return [];
    }
    setRows(userExpenseReportsDetail.details);
    return userExpenseReportsDetail;
  }, [userExpenseReportsDetail]);

  function renderRefundProcessingAndProcessedAmountSum() {
    const sum =
      parseFloat(userSummary?.refundProcessingAmount) +
      parseFloat(userSummary?.processedAmount);
    return sum.toFixed(2);
  }

  const columns: DataTableColumn<UserExpenseReportSummaryDetail>[] = useMemo(
    () => [
      {
        accessor: 'month',
        title: t('w.month'),
        sortable: true,
        ellipsis: true,
        render: ({ month }) => <Text>{dateToDayMonthLocal(month)}</Text>,
      },
      {
        accessor: 'waiting',
        title: t('w.pending'),
        sortable: true,
        ellipsis: true,
        render: ({ waiting }) => {
          return (
            <Group>
              <IconExclamationCircle className={s.icon} />
              <Text>{waiting}</Text>
            </Group>
          );
        },
      },
      {
        accessor: 'refundProcessing',
        title: t('expenseReport.status.state.VALID'),
        sortable: true,
        ellipsis: true,
        render: ({ refundProcessing }) => {
          return (
            <Group>
              <IconHourglassEmpty className={s.icon} />
              <Text>{refundProcessing}</Text>
            </Group>
          );
        },
      },
      {
        accessor: 'processed',
        title: t('expenseReport.status.state.PROCESSED'),
        sortable: true,
        ellipsis: true,
        render: ({ processed }) => {
          return (
            <Group>
              <IconProgressCheck className={s.icon} />
              <Text>{processed}</Text>
            </Group>
          );
        },
      },
      {
        accessor: 'refunded',
        title: t('expenseReport.status.state.REFUNDED'),
        sortable: true,
        ellipsis: true,
        render: ({ refunded }) => {
          return (
            <Group>
              <IconCircleCheck className={s.icon} />
              <Text>{refunded}</Text>
            </Group>
          );
        },
      },
      {
        accessor: 'rejected',
        title: t('w.rejected'),
        sortable: true,
        ellipsis: true,
        render: ({ rejected }) => {
          return (
            <Group>
              <IconCircleX className={s.icon} />
              <Text>{rejected}</Text>
            </Group>
          );
        },
      },
      {
        accessor: 'totalTTC',
        title: t('w.totalInclVAT'),
        sortable: true,
        ellipsis: true,
        render: ({ totalTTC }) => {
          return <Text>{parseFloat(totalTTC).toFixed(2)}</Text>;
        },
      },
      {
        accessor: 'solde',
        title: t('w.inProgress'),
        sortable: true,
        ellipsis: true,
        render: ({ solde }) => {
          return <Text>{parseFloat(solde).toFixed(2)}</Text>;
        },
      },
      {
        accessor: 'refundProcessingAmount',
        title: t('w.totalRefund'),
        sortable: true,
        ellipsis: true,
        render: ({ refundProcessingAmount }) => {
          return <Text>{parseFloat(refundProcessingAmount).toFixed(2)}</Text>;
        },
      },
      {
        accessor: 'actions',
        title: t('w.actions'),
        width: 100,
        sortable: false,
        ellipsis: true,
        textAlign: 'right',
        render: ({ month }) => (
          <ActionIcon
            variant={'subtle'}
            color="green"
            size={'sm'}
            onClick={() => handleOpenClick(month)}
          >
            <IconEye size={18} />
          </ActionIcon>
        ),
      },
    ],
    []
  );

  const rightButton: ReactNode[] = useMemo(
    () => [
      <Button
        key={'expenseReport-button'}
        leftSection={<IconCirclePlus />}
        variant={'filled'}
        onClick={() =>
          modals.open({
            modalId: `add-new-expense-report-modal`,
            title: (
              <Title size={'h3'} component="p">
                {t('w.expenseReport')}
              </Title>
            ),
            size: '90%',
            children: (
              <ExpenseReportRequestForm
                closeModal={() => modals.close('add-new-expense-report-modal')}
                refetch={refetchUserExpenseReportDetail}
                userProfile={userProfile}
              />
            ),
          })
        }
      >
        {t('w.expenseReport')}
      </Button>,
    ],
    []
  );

  function handleOpenClick(month: string) {
    modals.open({
      modalId: `expense-report-${userProfile.id}-month-summary`,
      title: (
        <Title component="p">
          {t('w.expenseReportsOf', dateToDayMonthLocal(month))}
        </Title>
      ),
      fullScreen: true,
      children: (
        <ExpenseReportByMonthView
          month={month}
          refetch={refetchUserExpenseReportDetail}
          userProfile={userProfile}
        />
      ),
    });
  }

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: 'month', direction: 'desc' },
    hiddenColumns: getHiddenColumns({
      componentName,
      hiddenColumns: [],
    }),
    updateHiddenColumns,
  });

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  return (
    <ScreenWrapper title={`${t('w.expenseReports')} : ${userProfile.fullname}`}>
      <SimpleGrid cols={4} mb={'md'}>
        <ExpenseReportInfosWrapper
          title={t('w.pending')}
          value={
            isHiFiveWorkProject()
              ? userSummary?.waiting + ' (' + userSummary?.waitingAmount + ' €)'
              : userSummary?.waiting + ' (' + userSummary?.waitingAmount + ')'
          }
        />
        <ExpenseReportInfosWrapper
          title={t('expenseReport.validProcessed')}
          value={
            isHiFiveWorkProject()
              ? userSummary?.refundProcessing +
                userSummary?.processed +
                ` (${renderRefundProcessingAndProcessedAmountSum()} €)`
              : userSummary?.refundProcessing +
                userSummary?.processed +
                ` (${renderRefundProcessingAndProcessedAmountSum()})`
          }
        />
        <ExpenseReportInfosWrapper
          title={t('expenseReport.refundSince30days')}
          value={
            isHiFiveWorkProject()
              ? userSummary?.refunded +
                ' (' +
                userSummary?.refundedAmount +
                ' €)'
              : userSummary?.refunded + ' (' + userSummary?.refundedAmount + ')'
          }
        />
        <ExpenseReportInfosWrapper
          title={t('expenseReport.rejectedSince30days')}
          value={
            isHiFiveWorkProject()
              ? userSummary?.rejected +
                ' (' +
                userSummary?.rejectedAmount +
                ' €)'
              : userSummary?.rejected + ' (' + userSummary?.rejectedAmount + ')'
          }
        />
      </SimpleGrid>
      <Table
        idAccessor={'month'}
        pinFirstColumn
        pinLastColumn
        rows={rows}
        columns={columns}
        lang={lang}
        fetching={isExpenseReportDetailsLoading}
        options={options}
        rightCustomActions={rightButton}
        height={'calc(100vh - 240px)'}
      />
    </ScreenWrapper>
  );
}
