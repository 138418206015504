import { Button, Tabs, useMantineColorScheme } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { IconCirclePlus, IconX } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';

import InvitationsHistoryPayrollOfficerTable from '../../../components/InvitationsHistoryPayrollOfficerTable';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import ScreenWrapper from '../../../core/layouts/components/ScreenWrapper';
import useCompany from '../../../hooks/useCompany';
import CharteredAccountantService from '../../../services/CharteredAccountantService';
import handleErrorMessage from '../../../utils/handleErrorMessage';
import AddPayrollOfficerModal from './components/AddPayrollOfficerModal';
import PayrollOfficerTable from './components/PayrollOfficerTable';

export default function PayrollOfficer() {
  const { colorScheme } = useMantineColorScheme();
  const { t } = useI18n();
  const [activeTab, setActiveTab] = useState<string | null>('accountants');
  const { user } = useAuth();
  const { id: companyId } = useCompany(user);
  const [
    isAddAccountantModalOpened,
    {
      open: handleAddAccountantModalOpen,
      close: handleAddAccountantModalClose,
    },
  ] = useDisclosure(false);

  const {
    data: invitationAccountantHistoryData,
    isLoading: isInvitationAccountantHistoryLoading,
    refetch: refetchInvitationAccountantHistoryData,
  } = useQuery({
    queryKey: [
      'CharteredAccountantService.invitationAccountantHistory',
      companyId,
    ],
    queryFn: () =>
      CharteredAccountantService.invitationAccountantHistory(companyId),
    onError: (err) => {
      // @ts-ignore
      if (err.response.status !== 403) {
        showNotification({
          id: 'invitation-accountant-history-error',
          title: t('w.error'),
          message: handleErrorMessage(err, t),
          color: 'red',
          icon: <IconX />,
        });
      }
    },
  });

  const tabs = useMemo(
    () => [
      {
        value: 'accountants',
        label: t('w.payrollOfficers'),
        component: <PayrollOfficerTable isDocuments={false} />,
      },
      {
        value: 'history',
        label: t('w.history'),
        component: (
          <InvitationsHistoryPayrollOfficerTable
            invitationAccountantHistoryData={invitationAccountantHistoryData}
            isInvitationAccountantHistoryLoading={
              isInvitationAccountantHistoryLoading
            }
            refetchInvitationAccountantHistoryData={
              refetchInvitationAccountantHistoryData
            }
          />
        ),
      },
    ],
    [invitationAccountantHistoryData, isInvitationAccountantHistoryLoading]
  );

  const actionButtons = useMemo(
    () => [
      <Button
        leftSection={<IconCirclePlus />}
        key={'invite-new-accountant'}
        onClick={handleAddAccountantModalOpen}
      >
        {t('w.addPayrollOfficer')}
      </Button>,
    ],
    []
  );

  return (
    <ScreenWrapper
      title={t('w.payrollOfficer')}
      actionButtons={activeTab === 'accountants' ? actionButtons : undefined}
    >
      <Tabs value={activeTab} onChange={setActiveTab}>
        <Tabs.List>
          {tabs.map((tab, index) => (
            <Tabs.Tab
              key={`tab-${tab.value}-${index}`}
              value={tab.value}
              bg={
                activeTab === tab.value && colorScheme === 'light'
                  ? 'white'
                  : 'transparent'
              }
            >
              {tab.label}
            </Tabs.Tab>
          ))}
        </Tabs.List>
        {tabs.map((tab, index) => (
          <Tabs.Panel
            pt="xs"
            key={`panel-${tab.value}-${index}`}
            value={tab.value}
          >
            {tab.component}
          </Tabs.Panel>
        ))}
      </Tabs>
      <AddPayrollOfficerModal
        opened={isAddAccountantModalOpened}
        onClose={handleAddAccountantModalClose}
        setActiveTab={setActiveTab}
        companyId={companyId}
      />
    </ScreenWrapper>
  );
}
