import {
  Button,
  Center,
  Container,
  Flex,
  LoadingOverlay,
  Paper,
  Space,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { isEmail, useForm } from '@mantine/form';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CustomLink from '../../components/CustomLink';
import CustomTooltip from '../../components/CustomTooltip';
import CustomTransition from '../../components/CustomTransition';
import Logo from '../../components/Logo';
import { useI18n } from '../../contexts/I18nProvider';
import OauthServiceMS from '../../services/OauthServiceMS';
import InputEmail from '../login/components/InputEmail';

export default function ForgotPassword() {
  const { t } = useI18n();
  const [opened, setOpened] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => setOpened(true), 100);
  }, []);

  const form = useForm({
    initialValues: {
      email: '',
    },
    validate: {
      email: isEmail(t('invalid.email')),
    },
  });
  const navigate = useNavigate();

  function handleOpenNavigateToSignUpConfirmModal() {
    modals.openConfirmModal({
      withCloseButton: false,
      title: (
        <Title size={'h3'} component="p">
          {t('w.emailAddressUnknownToTheSystem')}
        </Title>
      ),
      children: (
        <Text fz={'sm'} fw={'bold'} c={'dimmed'}>
          {t('w.wouldYouLikeToCreateAnAccount')}
        </Text>
      ),
      size: 'lg',
      labels: { confirm: t('w.create'), cancel: t('w.cancel') },
      onConfirm: () => navigate(`/sign-up?email=${form.values.email}`),
    });
  }

  const { mutate: forgotPassword, isLoading } = useMutation({
    mutationFn: (mail: string) => OauthServiceMS.forgotPassword(mail),
    onSuccess: () => {
      showNotification({
        id: 'forgotPassword',
        title: t('w.success'),
        message: t(
          'w.youWillReceiveAnEmailInAFewMomentsToResetYourPasswordOn',
          form.values.email
        ),
        color: 'green',
        icon: <IconCheck />,
      });
      navigate('/');
    },
    onError: () => {
      showNotification({
        id: 'sendEmailToResetPasswordFailed',
        title: t('w.error'),
        message: t('w.emailAddressUnknownToTheSystem'),
        color: 'red',
        icon: <IconX />,
      });
      handleOpenNavigateToSignUpConfirmModal();
    },
  });

  const sendEmailToResetPasswordSubmit = useCallback(() => {
    forgotPassword(form.values.email);
  }, [form.values.email]);

  return (
    <Container
      display={'grid'}
      size={'xs'}
      style={{ height: '100%', alignContent: 'center' }}
    >
      <CustomTransition
        opened={opened}
        transition={'slide-up'}
        duration={600}
        timingFunction={'ease'}
      >
        <Center>
          <Logo />
        </Center>
        <Space h="xl" />
        <Paper radius="md" p="xl" withBorder shadow={'md'}>
          <LoadingOverlay visible={isLoading} />
          <Center>
            <CustomTooltip
              label={
                <Text fz={12} fw={'bold'}>
                  {t('w.resetPasswordInfoMessage')}
                </Text>
              }
            >
              <Title order={3} size={'h3'} c={'hifivework.9'}>
                {t('w.forgottenPassword')}
              </Title>
            </CustomTooltip>
          </Center>
          <Space h="sm" />
          <form onSubmit={form.onSubmit(sendEmailToResetPasswordSubmit)}>
            <Stack>
              <InputEmail
                value={form.values.email}
                onChange={(event) =>
                  form.setFieldValue('email', event.currentTarget.value)
                }
                error={form.errors.email}
              />
              <Flex justify="space-between" align="center">
                <CustomLink navigation={'/'} text={t('w.back')} />
                <Button type="submit">{t('w.reset')}</Button>
              </Flex>
            </Stack>
          </form>
        </Paper>
      </CustomTransition>
    </Container>
  );
}
