import React from 'react';
import { NavLink } from 'react-router-dom';

type Props = {
  navigation: string;
  text: string;
};

export default function CustomLink({ navigation, text }: Props) {
  return (
    <NavLink
      to={navigation}
      style={() => {
        return {
          color: 'hifivework',
          fontSize: 12,
          textDecoration: 'none',
        };
      }}
    >
      {text}
    </NavLink>
  );
}
