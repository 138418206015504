import type { ViewModule } from '../types/api/payload/module';
import type {
  ModuleConfiguration,
  ModuleDetails,
} from '../types/api/response/module';
import { CK_MODULE_MANAGER_ENDPOINT } from '../variables/GlobalVariables';
import request from './AxiosClient';

function list(companyId: string, view: ViewModule): Promise<ModuleDetails[]> {
  return request
    .get(
      `${CK_MODULE_MANAGER_ENDPOINT}/v2/module-manager/list/${companyId}?view=${view}`
    )
    .then((res) => res?.data);
}

function update(
  companyId: string,
  moduleDescriptionId: string,
  active: boolean
): Promise<ModuleConfiguration> {
  return request.patch(
    `${CK_MODULE_MANAGER_ENDPOINT}/v2/module-manager/module/${companyId}/${moduleDescriptionId}?active=${active}`
  );
}

const CkModuleManagerServiceMS = {
  list,
  update,
};

export default CkModuleManagerServiceMS;
