import React from 'react';

import { useI18n } from '../contexts/I18nProvider';
import Pappers from '../features/accountant/components/Pappers';
import Yoomonkeez from '../features/accountant/components/Yoomonkeez';
import CompanyDaysWorkedParams from '../features/company/features/components/CompanyDaysWorkedParams';
import DailyworkParamsFeature from '../features/company/features/components/Dailywork/DailyworkParamsFeature';
import ExpenseReportParams from '../features/company/features/components/ExpenseReportParams';
import ExportCegidExpertParams from '../features/company/features/components/ExportCegidExpertParams';
import ExportQuadraParams from '../features/company/features/components/ExportQuadraParams';
import ExportSilaeParams from '../features/company/features/components/ExportSilaeParams';
import HfwAzureSyncParams from '../features/company/features/components/HfwAzureSyncParams/HfwAzureSyncParams';
import ShowMessageBeforePaidLeaveRequestParams from '../features/company/features/components/ShowMessageBeforePaidLeaveRequestParams';
import FeatureSynchroOpenpayeParams from '../features/company/features/components/SynchroOpenpaye/FeatureSynchroOpenpayeParams';
import TimeRecorderParams from '../features/company/features/components/TimeRecorderParams';
import VariablesPayrollParams from '../features/company/features/components/variables-payroll/VariablesPayrollParams';
import { ModuleDetails, ModuleName } from '../types/api/response/module';
import CustomModal from './CustomModal';

export type UpdateModuleParamsFunction = (variables: {
  apiUrl: string;
  payload: any;
}) => void;

export type ModuleToEditComponentProps = {
  module: ModuleDetails;
  updateModuleParams: UpdateModuleParamsFunction;
};

type Props = {
  opened: boolean;
  onClose: () => void;
  moduleToEdit: null | ModuleDetails;
  updateModuleParams: UpdateModuleParamsFunction;
  companyId: string;
};

export default function ModuleToEditModal({
  opened,
  onClose,
  moduleToEdit,
  updateModuleParams,
  companyId,
}: Props) {
  const { t } = useI18n();

  function renderModuleToEditComponent() {
    if (!moduleToEdit) {
      return undefined;
    }

    switch (moduleToEdit.name) {
      case ModuleName.Pappers:
        return (
          <Pappers
            module={moduleToEdit}
            updateModuleParams={updateModuleParams}
          />
        );
      case ModuleName.Yoomonkeez:
        return (
          <Yoomonkeez
            module={moduleToEdit}
            updateModuleParams={updateModuleParams}
          />
        );
      case ModuleName.TimeRecorder:
        return (
          <TimeRecorderParams
            module={moduleToEdit}
            updateModuleParams={updateModuleParams}
          />
        );
      case ModuleName.SynchroOpenPaye:
        return (
          <FeatureSynchroOpenpayeParams
            module={moduleToEdit}
            companyId={companyId}
          />
        );
      case ModuleName.ShowMessageBeforePaidLeaveRequest:
        return (
          <ShowMessageBeforePaidLeaveRequestParams
            module={moduleToEdit}
            updateModuleParams={updateModuleParams}
          />
        );
      case ModuleName.ExportQuadra:
        return (
          <ExportQuadraParams
            module={moduleToEdit}
            updateModuleParams={updateModuleParams}
          />
        );
      case ModuleName.ExportSilae:
        return (
          <ExportSilaeParams
            module={moduleToEdit}
            updateModuleParams={updateModuleParams}
          />
        );
      case ModuleName.ExportCegidExpert:
        return (
          <ExportCegidExpertParams
            module={moduleToEdit}
            updateModuleParams={updateModuleParams}
          />
        );
      case ModuleName.HfwAzureSync:
        return (
          <HfwAzureSyncParams
            module={moduleToEdit}
            updateModuleParams={updateModuleParams}
          />
        );
      case ModuleName.CompanyDaysWorked:
        return (
          <CompanyDaysWorkedParams
            module={moduleToEdit}
            updateModuleParams={updateModuleParams}
          />
        );
      case ModuleName.ExpenseReport:
        return (
          <ExpenseReportParams
            module={moduleToEdit}
            updateModuleParams={updateModuleParams}
          />
        );
      case ModuleName.DailyWork:
        return (
          <DailyworkParamsFeature
            module={moduleToEdit}
            updateModuleParams={updateModuleParams}
            companyId={companyId}
          />
        );
      case ModuleName.VariablePayroll:
        return <VariablesPayrollParams companyId={companyId} />;
      /* case GererMesAffaires:
        return (
          <GererMesAffairesParams
            module={moduleToEdit}
            updateModuleParams={updateModuleParams}
          />
        );*/
      default:
        console.log(`module ${moduleToEdit.name}`);
        return undefined;
    }
  }

  return (
    <CustomModal
      opened={opened}
      onClose={onClose}
      title={moduleToEdit ? t(`module.${moduleToEdit?.name}`) : ''}
      size={
        moduleToEdit?.name === ModuleName.VariablePayroll ||
        ModuleName.DailyWork
          ? '80%'
          : 'xl'
      }
    >
      {renderModuleToEditComponent()}
    </CustomModal>
  );
}
