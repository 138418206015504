import { ActionIcon, Indicator } from '@mantine/core';
import { IconMessage } from '@tabler/icons-react';
import React from 'react';

type Props = {
  onClick: () => void;
  commentsNumber: number;
};

export default function ShuttleSheetCommentsActionIcon({
  onClick,
  commentsNumber,
}: Props) {
  return (
    <ActionIcon size={'xl'} variant={'subtle'} onClick={onClick}>
      <Indicator size={16} label={commentsNumber}>
        <IconMessage />
      </Indicator>
    </ActionIcon>
  );
}
