import { usePrevious } from '@mantine/hooks';
import dayjs from 'dayjs';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { cloneDeep, debounce, findLastIndex } from 'lodash';
import { useEffect, useRef, useState } from 'react';

import { useAuth } from '../contexts/AuthProvider';
import type { PlanningObject } from '../types/types';
import { MS_PLANNING_ENDPOINT } from '../variables/GlobalVariables';

type Props = {
  companyId: string;
  divisionIds: string[];
  start: Date;
  end: Date;
};

type EventData = {
  id: string;
  userId: string;
  payload: PlanningObject;
};

export default function usePlanningSSE({
  divisionIds,
  companyId,
  start,
  end,
}: Props) {
  const { user, access_token } = useAuth();
  const [debouncedDivisionIds, setDebouncedDivisionIds] = useState<string[]>(
    []
  );
  useEffect(() => {
    const debouncedUpdate = debounce(() => {
      setDebouncedDivisionIds(divisionIds);
    }, 1000);

    debouncedUpdate();

    return () => {
      debouncedUpdate.cancel();
    };
  }, [divisionIds]);

  const url = !!debouncedDivisionIds.length
    ? `${MS_PLANNING_ENDPOINT}/v2/planning/real-time/sse/${
        user.id
      }/subscriptions/company/${companyId}/${dayjs(start)
        .subtract(1, 'week')
        .valueOf()}/${dayjs(end)
        .add(1, 'week')
        .valueOf()}?${debouncedDivisionIds
        .map((item) => `divisionId=${encodeURIComponent(item)}`)
        .join('&')}`
    : undefined;

  const sourceRef = useRef<EventSource | null>(null);
  const previousUrl = usePrevious(url);
  const [events, setEvents] = useState<EventData[]>([]);

  useEffect(() => {
    if (url) {
      if (previousUrl !== url) {
        closeSSE();
      }

      setEvents([]);
      subscribe(url);
    }

    return () => {
      closeSSE();
    };
  }, [url]);

  function subscribe(_url: string) {
    try {
      sourceRef.current = new EventSourcePolyfill(_url, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });

      sourceRef.current.addEventListener(
        'message',
        function (e) {
          try {
            const newEventData: EventData = JSON.parse(e.data);
            if (newEventData && newEventData.payload) {
              setEvents((prevState) => {
                let result = cloneDeep(prevState);
                if (newEventData) {
                  const divisionIndexInData = result.findIndex(
                    (item) =>
                      item.payload?.division?.id ===
                      newEventData.payload?.division?.id
                  );
                  if (divisionIndexInData === -1) {
                    result.push(newEventData);
                  } else {
                    const users = result[
                      divisionIndexInData
                    ].payload.users.concat(newEventData.payload.users);
                    result[divisionIndexInData].payload.users = users.filter(
                      (value, index, self) => {
                        return (
                          findLastIndex(
                            self,
                            (v) => v.user.id === value.user.id
                          ) === index
                        );
                      }
                    );

                    const blockingPeriods = result[
                      divisionIndexInData
                    ].payload.blockingPeriods.concat(
                      newEventData.payload.blockingPeriods
                    );
                    result[divisionIndexInData].payload.blockingPeriods =
                      blockingPeriods.filter((value, index, self) => {
                        return (
                          self.findIndex(
                            (v) =>
                              v.period.start === value.period.start &&
                              v.period.end === value.period.end
                          ) === index
                        );
                      });
                  }
                }
                result = result.filter((item) =>
                  debouncedDivisionIds.includes(item.payload.division.id)
                );
                return result;
              });
            }
          } catch (_e) {}
        },
        false
      );

      sourceRef.current.addEventListener('error', closeSSE, false);
    } catch (_e) {
      closeSSE();
    }
  }

  function closeSSE() {
    if (sourceRef.current) {
      sourceRef.current.close();
      sourceRef.current = null;
    }
  }

  function refetch() {
    closeSSE();

    if (url) {
      subscribe(url);
    }
  }

  return {
    events,
    refetch,
    loading: events.length !== debouncedDivisionIds.length,
  };
}
