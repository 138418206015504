import { Flex, Group, LoadingOverlay, Space, Text } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import {
  IconAlertTriangle,
  IconCheck,
  IconMailFast,
  IconX,
} from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import AcceptOrRejectRequestWrapper from '../../../../components/AcceptOrRejectRequestWrapper';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import ScreenWrapper from '../../../../core/layouts/components/ScreenWrapper';
import useCompany from '../../../../hooks/useCompany';
import CharteredAccountantService from '../../../../services/CharteredAccountantService';
import type { ManageAccountant } from '../../../../types/api/response/accountant';
import handleErrorMessage from '../../../../utils/handleErrorMessage';

export default function ValidateInvitationCustomerScreen() {
  const { t } = useI18n();
  const { validationId } = useParams();
  const { user } = useAuth();
  const { id: companyId } = useCompany(user);

  const { data, error } = useQuery({
    queryKey: [
      'CharteredAccountantService.validateCustomerAccountantInvitation',
      companyId,
    ],
    queryFn: () =>
      CharteredAccountantService.validateCustomerAccountantInvitation(
        companyId,
        validationId as string,
        null
      ),
  });

  const invitationToAccept: ManageAccountant | null = useMemo(() => {
    if (!data) {
      return null;
    }
    return data;
  }, [data]);

  const { mutate: validateCustomerAccountantInvitation, isLoading } =
    useMutation({
      mutationFn: (variables: {
        validationId: string;
        status: boolean | null;
      }) =>
        CharteredAccountantService.validateCustomerAccountantInvitation(
          companyId,
          variables.validationId,
          variables.status
        ),
      onSuccess: (
        data,
        variables: { validationId: string; status: boolean | null }
      ) => {
        showNotification({
          id: variables.status
            ? `accept-customer-invitation-${variables.validationId}-successful`
            : `reject-customer-invitation-${variables.validationId}-successful`,
          title: t('w.success'),
          message: variables.status
            ? `${t('w.invitationAccepted')} !`
            : `${t('w.invitationRefused')} !`,
          color: 'green',
          icon: <IconCheck />,
        });
        setTimeout(() => window.close(), 1500);
      },
      onError: (error) =>
        showNotification({
          id: 'validate-customer-invitation-error',
          title: t('w.error'),
          message: handleErrorMessage(error, t),
          color: 'red',
          icon: <IconX />,
        }),
    });

  return (
    <ScreenWrapper title={t('w.validateCustomerInvitationRequest')}>
      <LoadingOverlay visible={isLoading} />
      <Space h={'xl'} />
      <Space h={'xl'} />
      <Space h={'xl'} />
      <Space h={'xl'} />
      <Space h={'xl'} />
      <Space h={'xl'} />
      {
        // @ts-ignore
        error?.response.status === 403 ||
          // @ts-ignore
          (error?.response.status === 400 && (
            <Flex direction={'column'} align={'center'}>
              <Group>
                <IconAlertTriangle color={'red'} size={60} />
                <Text fz={'xl'} fw={'bold'} c={'red'}>
                  {t(
                    'w.youDoNotHaveAuthorisationToValidateCustomerInvitationRequests'
                  )}
                </Text>
              </Group>
              <Group>
                <Text fz={'sm'} fw={'bold'} c="dimmed">
                  {t('w.validationCustomerInvitationRequestMessage')}
                </Text>
                <IconMailFast color={'#868e96'} />
              </Group>
            </Flex>
          ))
      }
      {invitationToAccept && (
        <AcceptOrRejectRequestWrapper
          handleValidateClick={(agree) =>
            validateCustomerAccountantInvitation({
              validationId: invitationToAccept.id,
              status: agree,
            })
          }
          userId={invitationToAccept.who.userId}
          fullname={invitationToAccept.who.fullname}
          created={invitationToAccept.created!}
          companyName={invitationToAccept.who.companyName}
        />
      )}
    </ScreenWrapper>
  );
}
