import { Autocomplete, Button, Center, Loader, Stack } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconExternalLink, IconX } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';

import { ModuleToEditComponentProps } from '../../../components/ModuleToEditModal';
import { useI18n } from '../../../contexts/I18nProvider';
import ToolBoxHRServices from '../../../services/ToolBoxHRServices';

export default function Pappers({ module }: ModuleToEditComponentProps) {
  const { t } = useI18n();
  const [value, setValue] = useState('');
  const [options, setOptions] = useState([]);

  const { isLoading } = useQuery({
    enabled: value !== '',
    queryKey: ['ToolBoxHRServices.getPappersSuggestions', value],
    queryFn: () => ToolBoxHRServices.getPappersSuggestions(value),
    onSuccess: (data) =>
      setOptions(
        data.resultats_nom_entreprise.map(
          (entreprise: any) =>
            entreprise.nom_entreprise + ' - ' + entreprise.siren
        )
      ),
    onError: (err: { message: string }) =>
      showNotification({
        id: 'pappers-error',
        title: t('w.error'),
        message: err?.message,
        icon: <IconX />,
        color: 'red',
      }),
  });

  useEffect(() => {
    const findCompany = options.find((option) => value === option);
    if (findCompany) {
      window.open(`https://www.pappers.fr/entreprise/${value}`);
    }
    if (value === '') {
      setOptions([]);
    }
  }, [value]);

  return (
    <Stack gap={'lg'} h={400}>
      <Button
        leftSection={<IconExternalLink />}
        component="a"
        target="_blank"
        href={module.description?.url}
        variant={'subtle'}
      >
        Fonctionnalité Pappers.fr
      </Button>
      <Autocomplete
        label={t('w.companyName')}
        placeholder={t('w.search')}
        data={options}
        value={value}
        onChange={setValue}
        limit={20}
      />
      {isLoading && value !== '' && (
        <Center mt={'xl'}>
          <Loader />
        </Center>
      )}
    </Stack>
  );
}
