import { Image, LoadingOverlay, Stack, Tabs, Title } from '@mantine/core';
import { useMemo } from 'react';

import { useAuth } from '../../contexts/AuthProvider';
import { useI18n } from '../../contexts/I18nProvider';
import ScreenWrapper from '../../core/layouts/components/ScreenWrapper';
import useCompany from '../../hooks/useCompany';
import useFetchAccountants from '../../hooks/useFetchAccountants';
import { BuiltInPermissions, ScopeEnum } from '../../types/api/response/role';
import { DocumentPropertySpace } from '../../types/types';
import { hasPermission } from '../../utils/authorization';
import DocumentsTable from './components/DocumentsTable';

export default function DocumentsAccountants() {
  const { t } = useI18n();
  const { user } = useAuth();
  const { id: companyId } = useCompany(user);

  const { accountantsListData, isAccountantsListDataLoading } =
    useFetchAccountants();

  const tabs = useMemo(() => {
    if (accountantsListData) {
      return accountantsListData.map((item) => item.accountant);
    } else {
      return [];
    }
  }, [accountantsListData]);

  return (
    <ScreenWrapper title={t('w.payrollOfficers')}>
      <LoadingOverlay visible={isAccountantsListDataLoading} />
      {tabs.length > 0 ? (
        <Tabs defaultValue={tabs[0].name} keepMounted={false}>
          <Tabs.List>
            {tabs.map((tab, index) => (
              <Tabs.Tab key={`tab-${tab.name}-${index}`} value={tab.name}>
                {tab.name}
              </Tabs.Tab>
            ))}
          </Tabs.List>
          {tabs.map((tab, index) => (
            <Tabs.Panel
              key={`tab-panel${tab.name}-${index}`}
              value={tab.name}
              pt={'md'}
            >
              <DocumentsTable
                hasPermissionToEditSpace={hasPermission(
                  {
                    permission:
                      BuiltInPermissions.AuthorizedEditAccountantSpace,
                    scope: ScopeEnum.ALL,
                  },
                  user
                )}
                space={DocumentPropertySpace.ACCOUNTANT}
                userId={user.id}
                companyId={companyId}
                accountantCompanyId={tab.id}
              />
            </Tabs.Panel>
          ))}
        </Tabs>
      ) : (
        <Stack align={'center'}>
          <Title size={'h2'}>{t('w.none')}</Title>
          <Image w={'50%'} src={'/images/documents/no_data.png'} />
        </Stack>
      )}
    </ScreenWrapper>
  );
}
