import { Button, Group, Space, Text } from '@mantine/core';
import React from 'react';

import CustomModal from '../../../components/CustomModal';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';

type Props = {
  opened: boolean;
  onClose: () => void;
  onClick: () => void;
};

export default function WarningPrivacyPolicyModal({
  opened,
  onClose,
  onClick,
}: Props) {
  const { logout } = useAuth();
  const { t } = useI18n();

  return (
    <CustomModal opened={opened} onClose={onClose}>
      <Text>{t('w.privacyPolicyTextPart5')}</Text>
      <Space h={'xl'} />
      <Group justify={'flex-end'}>
        <Button variant={'outline'} onClick={() => logout()}>
          {t('w.logOff')}
        </Button>
        <Button onClick={onClick}>{t('w.continue')}</Button>
      </Group>
    </CustomModal>
  );
}
