import { ActionIcon, SimpleGrid } from '@mantine/core';
import {
  IconBabyBottle,
  IconBabyCarriage,
  IconBeach,
  IconBed,
  IconBuildingHospital,
  IconCalendarClock,
  IconCalendarEvent,
  IconCertificate,
  IconClockPause,
  IconClockPlay,
  IconCoffin,
  IconConfetti,
  IconDisabled,
  IconFirstAidKit,
  IconFlag,
  IconGrave2,
  IconHeart,
  IconHome,
  IconHospitalCircle,
  IconLock,
  IconNurse,
  IconReport,
  IconSchool,
  IconScreenShare,
  IconStethoscope,
  IconSun,
} from '@tabler/icons-react';
import { ReactNode } from 'react';

export type IconName =
  | 'beach'
  | 'bed'
  | 'flag'
  | 'lock'
  | 'sun'
  | 'clock-play'
  | 'building-hospital'
  | 'heart'
  | 'baby-carriage'
  | 'baby-bottle'
  | 'hospital-circle'
  | 'first-aid-kit'
  | 'nurse'
  | 'confetti'
  | 'disabled'
  | 'grave-2'
  | 'coffin'
  | 'stethoscope'
  | 'certificate'
  | 'school'
  | 'home'
  | 'screen-share'
  | 'report'
  | 'clock-pause'
  | 'calendar-event'
  | 'calendar-clock';

type AvailableIcon = {
  name: IconName;
  icon: ReactNode;
};

type Props = {
  selected: string | undefined;
  onClick: (newIconName: IconName) => void;
};

const availableIcons: AvailableIcon[] = [
  { name: 'beach', icon: <IconBeach /> },
  { name: 'bed', icon: <IconBed /> },
  { name: 'flag', icon: <IconFlag /> },
  { name: 'lock', icon: <IconLock /> },
  { name: 'sun', icon: <IconSun /> },
  { name: 'clock-play', icon: <IconClockPlay /> },
  { name: 'building-hospital', icon: <IconBuildingHospital /> },
  { name: 'heart', icon: <IconHeart /> },
  { name: 'baby-carriage', icon: <IconBabyCarriage /> },
  { name: 'baby-bottle', icon: <IconBabyBottle /> },
  { name: 'hospital-circle', icon: <IconHospitalCircle /> },
  { name: 'first-aid-kit', icon: <IconFirstAidKit /> },
  { name: 'nurse', icon: <IconNurse /> },
  { name: 'confetti', icon: <IconConfetti /> },
  { name: 'disabled', icon: <IconDisabled /> },
  { name: 'grave-2', icon: <IconGrave2 /> },
  { name: 'coffin', icon: <IconCoffin /> },
  { name: 'stethoscope', icon: <IconStethoscope /> },
  { name: 'certificate', icon: <IconCertificate /> },
  { name: 'school', icon: <IconSchool /> },
  { name: 'home', icon: <IconHome /> },
  { name: 'screen-share', icon: <IconScreenShare /> },
  { name: 'report', icon: <IconReport /> },
  { name: 'clock-pause', icon: <IconClockPause /> },
  { name: 'calendar-event', icon: <IconCalendarEvent /> },
  { name: 'calendar-clock', icon: <IconCalendarClock /> },
];

export default function IconSelector({ selected, onClick }: Props) {
  return (
    <SimpleGrid cols={{ base: 6, sm: 12 }} spacing={{ base: 'md', sm: 'sm' }}>
      {availableIcons.map((icon) => (
        <div>
          <ActionIcon
            key={icon.name}
            variant={icon.name === selected ? 'filled' : 'subtle'}
            onClick={() => onClick(icon.name)}
          >
            {icon.icon}
          </ActionIcon>
        </div>
      ))}
    </SimpleGrid>
  );
}

type TablerIconsProps = {
  color: string;
  name?: IconName;
  size?: number;
};

type IconProps = {
  color: string;
  name?: IconName;
};

export function getIcon({
  color,
  name,
  ...restProps
}: IconProps & TablerIconsProps) {
  switch (name) {
    case 'bed':
      return <IconBed color={color} {...restProps} />;
    case 'flag':
      return <IconFlag color={color} {...restProps} />;
    case 'lock':
      return <IconLock color={color} {...restProps} />;
    case 'clock-play':
      return <IconClockPlay color={color} {...restProps} />;
    case 'building-hospital':
      return <IconBuildingHospital color={color} {...restProps} />;
    case 'heart':
      return <IconHeart color={color} {...restProps} />;
    case 'baby-carriage':
      return <IconBabyCarriage color={color} {...restProps} />;
    case 'baby-bottle':
      return <IconBabyBottle color={color} {...restProps} />;
    case 'hospital-circle':
      return <IconHospitalCircle color={color} {...restProps} />;
    case 'first-aid-kit':
      return <IconFirstAidKit color={color} {...restProps} />;
    case 'nurse':
      return <IconNurse color={color} {...restProps} />;
    case 'confetti':
      return <IconConfetti color={color} {...restProps} />;
    case 'disabled':
      return <IconDisabled color={color} {...restProps} />;
    case 'grave-2':
      return <IconGrave2 color={color} {...restProps} />;
    case 'coffin':
      return <IconCoffin color={color} {...restProps} />;
    case 'stethoscope':
      return <IconStethoscope color={color} {...restProps} />;
    case 'certificate':
      return <IconCertificate color={color} {...restProps} />;
    case 'school':
      return <IconSchool color={color} {...restProps} />;
    case 'home':
      return <IconHome color={color} {...restProps} />;
    case 'screen-share':
      return <IconScreenShare color={color} {...restProps} />;
    case 'report':
      return <IconReport color={color} {...restProps} />;
    case 'clock-pause':
      return <IconClockPause color={color} {...restProps} />;
    case 'calendar-event':
      return <IconCalendarEvent color={color} {...restProps} />;
    case 'calendar-clock':
      return <IconCalendarClock color={color} {...restProps} />;
    case 'sun':
      return <IconSun color={color} {...restProps} />;
    default:
      return <IconBeach color={color} {...restProps} />;
  }
}
