import { FilterOption, Options, Table } from '@ckprivate/ckf-ui';
import { Box, Button, Flex, SegmentedControl } from '@mantine/core';
import {
  IconChevronDownRight,
  IconChevronRight,
  IconSquareForbid2,
  IconUser,
} from '@tabler/icons-react';
import dayjs, { Dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import type {
  DataTableColumn,
  DataTableRowExpansionProps,
} from 'mantine-datatable';
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
} from 'react';

import UnitBadge from '../../../../components/badges/UnitBadge';
import YesNoBadge from '../../../../components/badges/YesNoBadge';
import HorizontalMonthDailyWorkInfo from '../../../../components/scheduler/HorizontalMonthDailyWorkInfo';
import MonthEventScheduler from '../../../../components/scheduler/MonthEventScheduler';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import DailyWorkService, {
  UserPresenceHelper,
} from '../../../../services/DailyWorkService';
import {
  type DailyWorkDetails,
  DailyWorkDetailsStatus,
} from '../../../../types/api/response/dailyWork';
import type { UserDailyWork } from '../../../../types/front/dailyWork';
import { getFilter, saveFilter } from '../../../../utils/optionsPreferences';
import DailyWorkStatusBadge from './DailyWorkStatusBadge';

dayjs.extend(duration);

type Props = {
  dailyWorkList: UserDailyWork[];
  isLoading?: boolean;
  refetchDailyWorkListOnPeriod: () => void;
  currentMonth: Dayjs;
  currentWeek: Dayjs;
  onMonthChange: (
    value: ((prevState: dayjs.Dayjs) => dayjs.Dayjs) | dayjs.Dayjs
  ) => void;
  onWeekChange: (
    value: ((prevState: dayjs.Dayjs) => dayjs.Dayjs) | dayjs.Dayjs
  ) => void;
  weekOrMonthPeriodShown: string;
  onChangeWeekOrMonthPeriodShown: Dispatch<SetStateAction<string>>;
  expandedUserIds: string[];
  onChangeExpandedUserIds: Dispatch<SetStateAction<string[]>>;
  companyId: string;
};

const componentName = 'DailyWorksPendingValidationsList';

export default function DailyWorkList({
  dailyWorkList,
  isLoading = false,
  refetchDailyWorkListOnPeriod,
  currentMonth,
  currentWeek,
  onMonthChange,
  onWeekChange,
  weekOrMonthPeriodShown,
  onChangeWeekOrMonthPeriodShown,
  expandedUserIds,
  onChangeExpandedUserIds,
  companyId,
}: Props) {
  const { t, lang } = useI18n();
  const { user } = useAuth();
  const [selectedDailyWorks, setSelectedDailyWorks] = useState<any>([]);

  const columns: DataTableColumn<UserDailyWork>[] = useMemo(
    () => [
      {
        accessor: 'employeeName',
        title: t('w.employees').slice(0, -1),
        sortable: true,
        ellipsis: true,
        render: (userDailyWork: UserDailyWork) => (
          <Flex gap="xs">
            {expandedUserIds.includes(userDailyWork.userId) ? (
              <IconChevronDownRight />
            ) : (
              <IconChevronRight />
            )}
            <IconUser />
            {userDailyWork.employeeName}
          </Flex>
        ),
      },
      {
        accessor: 'workedHours',
        title: t('w.workedHours'),
        ellipsis: true,
        render: (userDailyWork: UserDailyWork) =>
          userDailyWork.dailyWorks?.[0]?.user?.fixedPriceManager ? (
            <IconSquareForbid2 />
          ) : (
            dayjs
              .duration(
                userDailyWork.dailyWorks
                  ?.map((dailyWork) => dailyWork.workingTimeDay)
                  ?.reduce((acc, cur) => acc + cur, 0),
                'minutes'
              )
              .format('D jr HH:mm')
          ),
      },
      {
        accessor: 'overtime',
        title: t('w.overtime'),
        ellipsis: true,
        render: (userDailyWork: UserDailyWork) =>
          userDailyWork.dailyWorks?.[0]?.user?.fixedPriceManager ? (
            <IconSquareForbid2 />
          ) : (
            dayjs
              .duration(
                userDailyWork.dailyWorks
                  ?.map((dailyWork) => dailyWork.overtime)
                  ?.reduce((acc, cur) => acc + cur, 0),
                'minutes'
              )
              .format('D jr HH:mm')
          ),
      },
      {
        accessor: 'workedDays',
        title: t('w.workedDays'),
        sortable: true,
        ellipsis: true,
        render: (userDailyWork: UserDailyWork) =>
          userDailyWork.dailyWorks?.[0]?.user?.fixedPriceManager ? (
            userDailyWork.dailyWorks
              .map((dailyWork) =>
                Math.round(dailyWork.workingTimeDay / 60 / 24)
              )
              .reduce((acc, cur) => acc + cur, 0)
          ) : (
            <IconSquareForbid2 />
          ),
      },
      {
        accessor: 'fixedPriceManager',
        title: t('w.fixedPriceManager'),
        sortable: true,
        ellipsis: true,
        render: (userDailyWork: UserDailyWork) => (
          <YesNoBadge
            value={Boolean(
              userDailyWork.dailyWorks?.[0]?.user?.fixedPriceManager
            )}
          />
        ),
      },
      {
        accessor: 'divisionName',
        title: user.company?.divisionLabel
          ? user.company.divisionLabel
          : t('w.unit'),
        sortable: true,
        ellipsis: true,
        render: (userDailyWork: UserDailyWork) => (
          <UnitBadge
            unit={userDailyWork.dailyWorks?.[0]?.user?.division?.name}
          />
        ),
      },
      {
        accessor: 'status',
        title: t('statut'),
        sortable: true,
        ellipsis: true,
        render: (userDailyWork: UserDailyWork) => (
          <DailyWorkStatusBadge status={userDailyWork.status} />
        ),
      },
    ],
    [expandedUserIds]
  );

  const userDailyWorkSubSection: DataTableRowExpansionProps<any> =
    useMemo(() => {
      return {
        allowMultiple: true,
        expanded: {
          recordIds: expandedUserIds,
          onRecordIdsChange: onChangeExpandedUserIds,
        },
        content: (rUserDailyWork) => {
          const userDailyWork = rUserDailyWork.record as UserDailyWork;
          return (
            <Box>
              {!userDailyWork.dailyWorks?.[0]?.user?.fixedPriceManager ? (
                <MonthEventScheduler
                  periodDailyWorks={userDailyWork}
                  refetchDailyWorkListOnPeriod={refetchDailyWorkListOnPeriod}
                  userId={userDailyWork.userId}
                  key={`monthEventScheduler_${currentMonth.format(
                    'MM-YYYY'
                  )}_${weekOrMonthPeriodShown}_${currentWeek.format(
                    'DDMMYYYY'
                  )}`}
                  companyId={companyId}
                />
              ) : (
                <HorizontalMonthDailyWorkInfo
                  periodDailyWorks={userDailyWork}
                  refetchDailyWorkListOnPeriod={refetchDailyWorkListOnPeriod}
                  key={`horizontalMonthDailyWorkInfo_${currentMonth.format(
                    'MM-YYYY'
                  )}_${weekOrMonthPeriodShown}_${currentWeek.format(
                    'DDMMYYYY'
                  )}`}
                  companyId={companyId}
                />
              )}
            </Box>
          );
        },
      };
    }, [expandedUserIds, currentMonth, currentWeek]);

  const leftCustomActions: ReactNode[] = useMemo(
    () => [
      <Button size="sm" onClick={() => validateDailyWorks()}>
        {t('w.validateWaitingPresences')}
      </Button>,
    ],
    [selectedDailyWorks.length]
  );

  const monthOrWeekSelector = (origin: string) => {
    return (
      <div style={{ paddingRight: '16px', display: 'inline-block' }}>
        <SegmentedControl
          value={weekOrMonthPeriodShown}
          key={origin}
          onChange={(controlValue) => {
            onChangeWeekOrMonthPeriodShown(controlValue);
          }}
          data={[
            { value: 'month', label: 'Mois' },
            { value: 'week', label: 'Semaine' },
          ]}
        />
      </div>
    );
  };

  const rightCustomActionsMonth = useMemo(
    () => [
      <>
        <Button
          variant="default"
          size="xs"
          onClick={() =>
            onMonthChange(dayjs(currentMonth.subtract(1, 'month')))
          }
        >
          &lt;
        </Button>
        <Button variant="default" size="xs" ml="md" mr="md">
          {currentMonth.format('MMMM YYYY').charAt(0).toUpperCase()}
          {currentMonth.format('MMMM YYYY').slice(1)}
        </Button>
        <Button
          variant="default"
          size="xs"
          onClick={() => onMonthChange(dayjs(currentMonth.add(1, 'month')))}
        >
          &gt;
        </Button>
      </>,
    ],
    [currentMonth, weekOrMonthPeriodShown, monthOrWeekSelector]
  );

  const rightCustomActionsWeek = useMemo(
    () => [
      <>
        <Button
          variant="default"
          size="xs"
          onClick={() => {
            console.log('previous');
            onWeekChange(currentWeek.subtract(1, 'week'));
          }}
        >
          &lt;
        </Button>
        <Button variant="default" size="xs" ml="md" mr="md">
          {`${currentWeek.format('DD/MM')} - 
          ${currentWeek.endOf('week').format('DD/MM')}`}
        </Button>
        <Button
          variant="default"
          size="xs"
          onClick={() => {
            console.log('next');
            onWeekChange(currentWeek.add(1, 'week'));
          }}
        >
          &gt;
        </Button>
      </>,
    ],
    [currentWeek, weekOrMonthPeriodShown, monthOrWeekSelector]
  );

  const rightCustomActions = useMemo(
    () => [
      <Box>
        {monthOrWeekSelector(weekOrMonthPeriodShown)}
        {weekOrMonthPeriodShown === 'month'
          ? rightCustomActionsMonth
          : rightCustomActionsWeek}
      </Box>,
    ],
    [currentMonth, weekOrMonthPeriodShown, monthOrWeekSelector]
  );

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: 'employeeName', direction: 'asc' },
    search: {
      accessor: 'employeeName',
      label: t('w.name'),
    },
    hiddenColumns: [],
    updateHiddenColumns,
    filter: getFilter({
      componentName,
      filter: [
        {
          accessor: 'status',
          label: t('w.status'),
          choices: [
            { value: 'VALID', label: t('expenseReport.status.state.VALID') },
            {
              value: 'WAITING',
              label: t('expenseReport.status.state.WAITING'),
            },
          ],
          selectedChoices: [],
        },
      ],
    }),
    updateFilter,
  });

  function updateFilter(newFilter: FilterOption[]) {
    const _options = { ...options };
    _options.filter = newFilter;
    saveFilter({ componentName, filter: newFilter });
    setOptions(_options);
  }

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    setOptions(_options);
  }

  const updateUserPresence = (userId: string, dailyWork: DailyWorkDetails) => {
    return DailyWorkService.updateUserDailyWork(
      userId,
      UserPresenceHelper.toUserPresence(dailyWork, DailyWorkDetailsStatus.VALID)
    );
  };

  function validateDailyWorks() {
    const updatesReqs = selectedDailyWorks.map(
      (employee: { userId: string; dailyWorks: DailyWorkDetails[] }) => {
        return employee.dailyWorks
          .filter((dailyWork) => dailyWork.status === 'WAITING')
          .map((dailyWork) =>
            updateUserPresence(
              employee.userId,
              keepOnlyDailyWorkLeaves(dailyWork)
            )
          );
      }
    );

    updatesReqs.forEach((reqsByUser: [any]) => {
      Promise.all(reqsByUser).finally(() => {
        refetchDailyWorkListOnPeriod();
      });
    });
  }

  function keepOnlyDailyWorkLeaves(
    dailyWork: DailyWorkDetails
  ): DailyWorkDetails {
    const result = { ...dailyWork };

    result.leaves = result.leaves.filter(
      (item) => item.type?.name === 'Présence'
    );

    return result;
  }

  return (
    <Table
      idAccessor={'userId'}
      pinFirstColumn
      pinLastColumn
      rows={dailyWorkList}
      height={'calc(100vh - 200px)'}
      options={options}
      selectedRecords={selectedDailyWorks}
      onSelectedRecordsChange={setSelectedDailyWorks}
      columns={columns}
      lang={lang}
      fetching={isLoading}
      withTableBorder={false}
      leftCustomActions={leftCustomActions}
      rightCustomActions={rightCustomActions}
      rowExpansion={userDailyWorkSubSection}
      styles={{
        header: {
          zIndex: 10,
        },
      }}
    />
  );
}
