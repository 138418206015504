import type { FilterOption } from '@ckprivate/ckf-ui';

import { getPersistedObject, persistData } from './localStorage';

const HFW_USER_PREFERENCES_KEY = 'hfwup';

export function getFilter({
  componentName,
  filter,
}: {
  componentName: string;
  filter: FilterOption[];
}): FilterOption[] {
  let userPreferences = getPersistedObject(HFW_USER_PREFERENCES_KEY);
  const preferencesKey = `${componentName}.filter`;
  if (!userPreferences || !userPreferences[preferencesKey]) {
    userPreferences = {
      [preferencesKey]: filter,
    };
  }

  const result = [...userPreferences[preferencesKey]];

  result.forEach((item: FilterOption, index: number) => {
    if (filter?.[index]?.choices) {
      item.choices = filter[index].choices;
    }
  });

  return result;
}

export function saveFilter({
  componentName,
  filter,
}: {
  componentName: string;
  filter: FilterOption[];
}): void {
  let userPreferences = getPersistedObject(HFW_USER_PREFERENCES_KEY);
  const preferencesKey = `${componentName}.filter`;
  if (!userPreferences) userPreferences = {};
  userPreferences[preferencesKey] = filter;
  persistData(HFW_USER_PREFERENCES_KEY, userPreferences);
}

export function getHiddenColumns({
  componentName,
  hiddenColumns = [],
}: {
  componentName: string;
  hiddenColumns?: string[];
}): string[] {
  let userPreferences = getPersistedObject(HFW_USER_PREFERENCES_KEY);
  const preferencesKey = `${componentName}.hiddenColumns`;
  if (!userPreferences || !userPreferences[preferencesKey]) {
    userPreferences = {
      [preferencesKey]: hiddenColumns,
    };
  }
  return userPreferences[preferencesKey];
}

export function saveHiddenColumns({
  componentName,
  hiddenColumns,
}: {
  componentName: string;
  hiddenColumns: string[];
}): void {
  let userPreferences = getPersistedObject(HFW_USER_PREFERENCES_KEY);
  const preferencesKey = `${componentName}.hiddenColumns`;
  if (!userPreferences) userPreferences = {};
  userPreferences[preferencesKey] = hiddenColumns;
  persistData(HFW_USER_PREFERENCES_KEY, userPreferences);
}
