import 'react-credit-cards-2/dist/lib/styles.scss';

import React from 'react';
import Cards from 'react-credit-cards-2';

import { useI18n } from '../../../../../contexts/I18nProvider';
import type { PaymentCard } from '../../../../../types/types';

type Props = {
  card: PaymentCard;
  name?: string;
};

export default function Card({ card, name = '' }: Props) {
  const { t } = useI18n();

  return (
    <Cards
      preview
      number={`............${parseInt(card.last4)}`}
      issuer={card.brand}
      cvc={''}
      name={name}
      locale={{ valid: t('w.expiryDate') }}
      expiry={`${String(card.expMonth).padStart(2, '0')}/${card.expYear}`}
    />
  );
}
