import { Grid, Textarea, TextInput } from '@mantine/core';
import { IconMail } from '@tabler/icons-react';
import React from 'react';

import LabelText from '../../../components/LabelText/LabelText';
import PhoneField from '../../../components/PhoneField/PhoneField';
import { useI18n } from '../../../contexts/I18nProvider';
import s from '../../company/employees/components/AddEmployeeInformationForm/AddEmployeeInformationForm.module.sass';

type Props = {
  form: any;
};

export default function UserEmergencyContact({ form }: Props) {
  const { t } = useI18n();

  return (
    <Grid
      p={'md'}
      gutter="xl"
      key={`person-to-contact}`}
      styles={{
        root: {
          backgroundColor: 'var(--mantine-color-hifivework-0)',
          borderRadius: 8,
        },
      }}
    >
      <Grid.Col span={{ base: 12, md: 4 }}>
        <TextInput
          label={<LabelText text={t('w.firstname')} />}
          placeholder={t('w.noValue')}
          {...form.getInputProps(`firstname`)}
        />
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 4 }}>
        <TextInput
          label={<LabelText text={t('w.name')} />}
          placeholder={t('w.noValue')}
          {...form.getInputProps(`lastname`)}
        />
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 4 }}>
        <Textarea
          label={<LabelText text={t('w.comment')} />}
          placeholder={t('w.noValue')}
          {...form.getInputProps(`comment`)}
        />
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 4 }}>
        <TextInput
          label={<LabelText text={t('w.emailAddress')} />}
          rightSection={<IconMail className={s.icon} />}
          placeholder={t('w.noValue')}
          {...form.getInputProps(`email`)}
        />
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 4 }}>
        <PhoneField
          value={form.values.phone}
          onChange={(value) => form.setFieldValue(`phone`, value)}
        />
      </Grid.Col>
    </Grid>
  );
}
