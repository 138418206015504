import { ActionIcon, FileInput, Flex, rem } from '@mantine/core';
import { MIME_TYPES } from '@mantine/dropzone';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import {
  IconCheck,
  IconFile,
  IconTrash,
  IconUpload,
  IconX,
} from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import React from 'react';

import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import VariablePayrollService, {
  UpdateEmployeeVariablePayrollPayload,
} from '../../../../services/VariablePayrollService';
import { handleOpenVariablePayrollFile } from '../../../../utils/attachmentFile';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
import s from './EditVariablePayrollFileInput.module.sass';

type Props = {
  variablePayrollValue: string | undefined;
  disabled: boolean;
  companyId: string;
  endOfMonthId: string;
  employeeId: string;
  variablePayrollId: string;
  refetchAllData: () => void;
};

type FormValues = {
  variablePayrollValue: string | undefined;
  file: any;
};

export default function EditVariablePayrollFileInput({
  variablePayrollValue,
  disabled,
  companyId,
  endOfMonthId,
  employeeId,
  variablePayrollId,
  refetchAllData,
}: Props) {
  const { access_token } = useAuth();
  const { t } = useI18n();
  const form = useForm<FormValues>({
    initialValues: {
      variablePayrollValue: variablePayrollValue || '',
      file: null,
    },
  });

  const { mutate: updateEmployeeVariablePayroll } = useMutation({
    mutationFn: (variables: UpdateEmployeeVariablePayrollPayload) =>
      VariablePayrollService.updateEmployeeVariablePayroll(
        companyId,
        variables
      ),
    onSuccess: () => {
      refetchAllData();
    },
  });

  const {
    mutate: deleteVariablePayrollFile,
    isLoading: isDeleteVariablePayrollFileLoading,
  } = useMutation({
    mutationFn: () =>
      VariablePayrollService.deleteVariablePayrollFile(
        companyId,
        employeeId,
        endOfMonthId,
        variablePayrollId
      ),
    onSuccess: () => {
      showNotification({
        id: `delete-variable-payroll-file-successful`,
        title: t('w.success'),
        message: t('w.deleted').toLowerCase(),
        color: 'green',
        icon: <IconCheck />,
      });
      refetchAllData();
    },
    onError: (error) =>
      showNotification({
        id: `delete-variable-payroll-file-error`,
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function openFile() {
    handleOpenVariablePayrollFile(
      companyId,
      employeeId,
      endOfMonthId,
      variablePayrollId,
      variablePayrollValue as string,
      access_token,
      t
    );
  }

  function deleteFile() {
    deleteVariablePayrollFile();
  }

  function saveClick() {
    updateEmployeeVariablePayroll({
      employeeId,
      endOfMonthId,
      variablePayrollId,
      file: form.values.file,
      extension: form.values.file.name.split('.').pop(),
    });
  }

  return (
    <Flex gap="xs" align="center">
      <FileInput
        classNames={{ input: s.input }}
        disabled={disabled || isDeleteVariablePayrollFileLoading}
        leftSection={
          <IconUpload
            style={{ width: rem(16), height: rem(16) }}
            stroke={1.5}
          />
        }
        leftSectionPointerEvents="none"
        size={'xs'}
        accept={`${MIME_TYPES.png},
          ${MIME_TYPES.jpeg},
          ${MIME_TYPES.pdf},
          ${MIME_TYPES.doc},
          ${MIME_TYPES.docx}`}
        onChange={(file) => form.setFieldValue('file', file)}
        rightSection={
          <ActionIcon
            disabled={!form.values.file}
            size={'xs'}
            variant={'subtle'}
            onClick={saveClick}
          >
            <IconCheck />
          </ActionIcon>
        }
      />
      <ActionIcon
        disabled={!variablePayrollValue || isDeleteVariablePayrollFileLoading}
        size={'xs'}
        variant={'filled'}
        onClick={openFile}
      >
        <IconFile />
      </ActionIcon>
      <ActionIcon
        disabled={!variablePayrollValue || isDeleteVariablePayrollFileLoading}
        size={'xs'}
        variant={'filled'}
        color={'red'}
        onClick={deleteFile}
      >
        <IconTrash />
      </ActionIcon>
    </Flex>
  );
}
