import { Text, type TextProps, useMantineColorScheme } from '@mantine/core';
import React from 'react';

type Props = {
  text: string;
};

export default function LabelText({ text, ...restProps }: Props & TextProps) {
  const { colorScheme } = useMantineColorScheme();

  return (
    <Text
      {...restProps}
      fz={16}
      fw={'bold'}
      c={colorScheme === 'dark' ? 'hifivework.2' : 'hifivework.9'}
    >
      {text}
    </Text>
  );
}
