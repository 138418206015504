/**
 * store item to local storage by key
 * @param key
 * @param obj
 */
export function persistData(key: string, obj: string | object | any[]): void {
  switch (typeof obj) {
    case 'string':
      window.localStorage.setItem(key, obj);
      break;
    case 'object':
      window.localStorage.setItem(key, JSON.stringify(obj));
      break;
  }
}

/**
 *
 * @param key
 * @return {*} return String, Array, Object of stored local storage object
 * can return null if object with key param not found in local storage
 */
export function getPersistedObject(key: string): string | any {
  const strItem = window.localStorage.getItem(key);
  let result = null;
  if (strItem) {
    try {
      result = JSON.parse(strItem);
    } catch (e) {
      return strItem;
    }
    return result;
  }
  return null;
}

export function removePersistedObject(key: string): void {
  window.localStorage.removeItem(key);
}

export function persistUser(
  user?: any,
  access_token?: string,
  refresh_token?: string
) {
  if (user) {
    persistData('user', user);
  }
  if (access_token) {
    persistData('access_token', access_token);
  }
  if (refresh_token) {
    persistData('refresh_token', refresh_token);
  }
}
