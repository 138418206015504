import {
  Button,
  Flex,
  Grid,
  Group,
  Image,
  LoadingOverlay,
  rem,
  Select,
  Switch,
  TextInput,
  Title,
} from '@mantine/core';
import { FileWithPath } from '@mantine/dropzone';
import { isEmail, useForm } from '@mantine/form';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconCheck,
  IconCircleArrowRight,
  IconCopyCheck,
  IconDeviceFloppy,
  IconHelpHexagon,
  IconMail,
  IconX,
} from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useMemo, useRef } from 'react';

import CustomUserAvatar from '../../../../../components/CustomAvatar/CustomUserAvatar';
import CustomDropzone from '../../../../../components/CustomDropzone/CustomDropzone';
import CustomTooltip from '../../../../../components/CustomTooltip';
import ActionDeleteIcon from '../../../../../components/icons/ActionDeleteIcon';
import LabelText from '../../../../../components/LabelText/LabelText';
import { useAuth } from '../../../../../contexts/AuthProvider';
import { useI18n } from '../../../../../contexts/I18nProvider';
import useCompany from '../../../../../hooks/useCompany';
import useFetchManagers from '../../../../../hooks/useFetchManagers';
import CompanyService from '../../../../../services/CompanyService';
import ProfileService from '../../../../../services/ProfileService';
import {
  OnBoardingStatus,
  UserPayload,
} from '../../../../../types/api/payload/user';
import type { DivisionResponse } from '../../../../../types/api/response/division';
import type { HfwSecurityRoleResponse } from '../../../../../types/api/response/role';
import {
  type UserPersonalInfo,
  type UserPersonalSensitive,
  type UserResponse,
} from '../../../../../types/api/response/user';
import { Language } from '../../../../../types/i18n';
import handleErrorMessage from '../../../../../utils/handleErrorMessage';
import { roleName } from '../../../../../utils/roleName';
import { NO_WORK_UNIT } from '../../../../../variables/GlobalVariables';
import AddEmployeeGroupRadio from '../AddEmployeeGroupRadio';
import s from './AddEmployeeProfileForm.module.sass';

type Props = {
  divisions: DivisionResponse[];
  roles: HfwSecurityRoleResponse[];
  onClose: () => void;
  creationTypeValue: string;
  setCreationTypeValue: (value: string) => void;
  active: number;
  setActive: (number: number) => void;
  refetch: () => void;
  setAddedEmployee: (data: UserResponse) => void;
  employee: UserResponse | null;
  validateEmployeeOnBoarding: (variables: {
    userId: string;
    employee: boolean;
    accountant: boolean;
  }) => void;
  finalValidateEmployeeOnBoarding: (variables: { userId: string }) => void;
  userPersonalInfo: UserPersonalInfo | null;
  userSensitiveInfo?: UserPersonalSensitive | null;
};

interface FormValues {
  firstname: string;
  lastname: string;
  mail: string;
  lang: Language;
  securityRoleId: string;
  divisionId: string;
  valid: boolean;
  avatar: FileWithPath[];
  filename?: string | null;
  firstManager: string;
  secondManager: string;
}

export default function AddEmployeeProfileForm({
  roles,
  divisions,
  onClose,
  creationTypeValue,
  setCreationTypeValue,
  active,
  setActive,
  refetch,
  setAddedEmployee,
  employee,
  validateEmployeeOnBoarding,
  finalValidateEmployeeOnBoarding,
  userPersonalInfo,
  userSensitiveInfo,
}: Props) {
  const { t } = useI18n();
  const { user, access_token } = useAuth();
  const { id: companyId, company } = useCompany(user);
  const { managers } = useFetchManagers();

  const form = useForm<FormValues>({
    initialValues: {
      firstname: employee?.firstname ? employee.firstname : '',
      lastname: employee?.lastname ? employee.lastname : '',
      mail: employee?.mail ? employee.mail : '',
      lang: employee?.lang ? employee.lang : Language.fr,
      securityRoleId: employee?.securityRoleId ? employee.securityRoleId : '',
      divisionId: employee?.divisionId ? employee.divisionId : '',
      valid: employee ? (employee?.valid ? employee.valid : false) : true,
      avatar: [],
      filename: null,
      firstManager: employee?.firstManager ? employee.firstManager : '',
      secondManager: employee?.secondManager ? employee.secondManager : '',
    },
    validate: {
      mail: isEmail(t('invalid.email')),
    },
  });
  const resetFileRef = useRef<() => void>(null);

  const {
    mutate: createNewUserOnboarding,
    isLoading: isCreateNewUserOnboardingLoading,
  } = useMutation({
    mutationFn: (variables: {
      firstname: string;
      lastname: string;
      mail: string;
      lang: string;
      securityRoleId: string;
      divisionId: string;
      valid: boolean;
      firstManager: string;
      secondManager: string;
    }) =>
      CompanyService.createUser(
        companyId,
        creationTypeValue !== 'create-employee',
        variables,
        access_token
      ),
    onSuccess: (data) => {
      if (creationTypeValue === 'onBoarding') {
        onClose();
        refetch();
        showNotification({
          id: 'add-employee-profile-success',
          title: t('w.success'),
          message: `Une invitation a bien été envoyé à ${data.firstname}`,
          color: 'green',
          icon: <IconCheck />,
        });
      } else {
        showNotification({
          id: 'add-employee-profile-success',
          title: t('w.success'),
          message: `${data.firstname} a bien été ajouté à la liste des employés`,
          color: 'green',
          icon: <IconCheck />,
        });
        refetch();
        setActive(active + 1);
        setAddedEmployee(data);
      }
    },
    onError: (error) =>
      showNotification({
        id: 'add-employee-profile-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const {
    mutate: updateEmployeeAdded,
    isLoading: isLoadingUpdateEmmployeeAdded,
  } = useMutation({
    mutationFn: (variables: UserPayload) =>
      ProfileService.updateUserProfile(
        employee?.id,
        variables,
        creationTypeValue !== 'onBoarding' ? null : OnBoardingStatus.ONBOARDING
      ),
    onSuccess: (data) => {
      showNotification({
        id: 'udpate-added-employee-information-success',
        title: t('w.success'),
        message: t('success.profileUpdated'),
        color: 'green',
        icon: <IconCheck />,
      });
      refetch();
      setAddedEmployee(data);
      onClose();
    },
    onError: () =>
      showNotification({
        id: 'update-employee-added-error',
        title: t('w.error'),
        message: t('error.updateUserProfile', employee?.firstname),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const langsSelect = useMemo(
    () => [
      { value: 'fr', label: t('w.french') },
      { value: 'en', label: t('w.english') },
    ],
    []
  );

  const divisionsSelect = useMemo(() => {
    if (!divisions) {
      return [];
    }
    return divisions.map((division) => {
      return {
        value: division.id,
        label:
          division.name === NO_WORK_UNIT ? t('NO_WORK_UNIT') : division.name,
      };
    });
  }, [divisions]);

  const rolesSelect = useMemo(() => {
    if (!roles) {
      return [];
    }
    return roles.map((role) => {
      return {
        value: role.id,
        label: roleName(t, role.name),
      };
    });
  }, [roles]);

  useEffect(() => {
    if (!employee) {
      if (creationTypeValue === 'create-employee') {
        form.setFieldValue('valid', true);
      } else {
        form.setFieldValue('valid', false);
      }
    }
  }, [creationTypeValue]);

  function handleAddEmployeeProfileFormSubmit(
    values: ReturnType<(values: FormValues) => FormValues>
  ) {
    if (Boolean(employee)) {
      setActive(active + 1);
    } else {
      createNewUserOnboarding({
        firstname: values.firstname,
        lastname: values.lastname,
        mail: values.mail,
        lang: values.lang,
        securityRoleId: values.securityRoleId,
        divisionId: values.divisionId,
        valid: values.valid,
        firstManager: values.firstManager,
        secondManager: values.secondManager,
      });
    }
  }

  function handleCreateOrUpdateEmployee(values: FormValues) {
    if (!Boolean(employee)) {
      createNewUserOnboarding({
        firstname: values.firstname,
        lastname: values.lastname,
        mail: values.mail,
        lang: values.lang,
        securityRoleId: values.securityRoleId,
        divisionId: values.divisionId,
        valid: values.valid,
        firstManager: values.firstManager,
        secondManager: values.secondManager,
      });
    } else {
      let data;
      if (employee?.mail !== values.mail) {
        data = {
          firstname: values.firstname,
          lastname: values.lastname,
          mail: values.mail,
          lang: values.lang,
          securityRoleId: values.securityRoleId,
          divisionId: values.divisionId,
          valid: values.valid,
          firstManager: values.firstManager,
          secondManager: values.secondManager,
          avatar: form.values.avatar?.length > 0 ? form.values.avatar[0] : null,
          filename:
            form.values.avatar.length > 0 ? form.values.avatar[0].name : null,
        };
      } else {
        data = {
          firstname: values.firstname,
          lastname: values.lastname,
          lang: values.lang,
          securityRoleId: values.securityRoleId,
          divisionId: values.divisionId,
          valid: values.valid,
          firstManager: values.firstManager,
          secondManager: values.secondManager,
          avatar: form.values.avatar?.length > 0 ? form.values.avatar[0] : null,
          filename:
            form.values.avatar.length > 0 ? form.values.avatar[0].name : null,
        };
      }
      updateEmployeeAdded(data);
    }
  }

  function getImageUrl() {
    if (form.values.avatar.length > 0) {
      return URL.createObjectURL(form.values.avatar[0]);
    }
  }

  function clearFile() {
    form.setFieldValue('avatar', []);
    resetFileRef.current?.();
  }

  function openConfirmOnBoarding(values: FormValues) {
    modals.openConfirmModal({
      modalId: 'confirm-valid-on-boarding',
      title:
        user.id === employee?.id ? (
          <Title size={'h3'} component="p">
            {t('w.confirmOnBoardingInfos')}
          </Title>
        ) : employee?.onBoarding?.onBoardingEmployee?.validationDate !== null &&
          employee?.onBoarding?.onBoardingCompany !== null &&
          employee?.onBoarding?.onBoardingCompany?.validationDate &&
          employee?.onBoarding?.onBoardingCompany?.validationDate
            ?.dateOfUpdate !== null ? (
          <Title size={'h3'} component="p">
            {"Vous allez finaliser l'embauche de votre collaborateur"}
          </Title>
        ) : (
          <Title size={'h3'} component="p">
            {t('w.confirmOnBoardingInfosCompany')}
          </Title>
        ),
      size: 'xl',
      labels: { cancel: t('w.cancel'), confirm: t('w.confirm') },
      onConfirm: () => validateOnBoarding(values),
    });
  }

  function validateOnBoarding(values: FormValues) {
    if (employee !== null) {
      if (user.id !== employee.id) {
        if (
          employee.onBoarding?.onBoardingEmployee?.validationDate !== null &&
          employee?.onBoarding?.onBoardingCompany !== null &&
          employee?.onBoarding?.onBoardingCompany?.validationDate &&
          employee?.onBoarding?.onBoardingCompany?.validationDate
            ?.dateOfUpdate !== null
        ) {
          finalValidateEmployeeOnBoarding({ userId: employee.id });
        } else {
          validateEmployeeOnBoarding({
            employee: false,
            accountant: false,
            userId: employee.id,
          });
        }
      } else {
        validateEmployeeOnBoarding({
          employee: true,
          accountant: false,
          userId: employee.id,
        });
      }
      handleCreateOrUpdateEmployee(values);
    }
  }

  function isDisabledConfirmOnBoardingButton() {
    const isRequiredInfoMissing =
      userSensitiveInfo?.identityCardNumber === null ||
      userSensitiveInfo?.identityCardNumber === '' ||
      userSensitiveInfo?.ssNumber === null ||
      userSensitiveInfo?.ssNumber === '' ||
      userPersonalInfo?.address === null ||
      userPersonalInfo?.birthPlace === null ||
      userPersonalInfo?.birthDepartment === null ||
      userPersonalInfo?.birthday === null;

    const isMyProfileAndAlreadyValidated =
      employee?.id === user.id &&
      employee?.onBoarding?.onBoardingEmployee?.validationDate !== null;

    const isNotMyProfileAndNotValidatedYet =
      employee?.id !== user.id &&
      employee?.onBoarding?.onBoardingEmployee?.validationDate === null;

    return (
      isRequiredInfoMissing ||
      isMyProfileAndAlreadyValidated ||
      isNotMyProfileAndNotValidatedYet
    );
  }

  return (
    <form
      onSubmit={form.onSubmit((values) =>
        handleAddEmployeeProfileFormSubmit(values)
      )}
    >
      <LoadingOverlay
        visible={
          isCreateNewUserOnboardingLoading || isLoadingUpdateEmmployeeAdded
        }
      />
      <Flex justify={'space-between'}>
        <Title order={3}>{t('w.profile')}</Title>
        <Switch
          label={<LabelText text={t('w.active')} />}
          labelPosition={'left'}
          checked={form.values.valid}
          {...form.getInputProps('valid')}
          disabled={Boolean(employee?.onBoardingId) && user.id === employee?.id}
        />
      </Flex>
      <Grid grow gutter="xl" mt={'md'}>
        {employee && (
          <>
            <Grid.Col span={{ base: 12, lg: 3 }} style={{ minHeight: rem(80) }}>
              <CustomUserAvatar userId={employee.id} size={120} />
            </Grid.Col>
            <Grid.Col span={{ base: 12, lg: 9 }} style={{ minHeight: rem(80) }}>
              <Group justify={'space-between'}>
                <Group>
                  <CustomDropzone
                    onDrop={(files) => form.setFieldValue('avatar', files)}
                    files={form.values.avatar}
                    mimeType={'img'}
                    resetFiles={(index) => form.removeListItem('avatar', index)}
                  />
                  {form.values.avatar.length > 0 && (
                    <Image height={60} width={60} src={getImageUrl()} />
                  )}
                  {form.values.avatar.length > 0 && (
                    <ActionDeleteIcon
                      label={t('w.deletePhoto')}
                      onClick={clearFile}
                    />
                  )}
                </Group>
              </Group>
            </Grid.Col>
          </>
        )}
        <Grid.Col span={{ base: 12, md: 4 }}>
          <TextInput
            required
            label={<LabelText text={t('w.firstname')} />}
            placeholder={t('w.noValue')}
            {...form.getInputProps('firstname')}
            disabled={
              Boolean(employee?.onBoardingId) && user.id === employee?.id
            }
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 4 }}>
          <TextInput
            required
            label={<LabelText text={t('w.name')} />}
            placeholder={t('w.noValue')}
            {...form.getInputProps('lastname')}
            disabled={
              Boolean(employee?.onBoardingId) && user.id === employee?.id
            }
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 4 }}>
          <TextInput
            required
            label={<LabelText text={t('w.emailAddress')} />}
            leftSection={
              <CustomTooltip
                label={t('w.temporaryEmailThatCanBeChangedLater')}
                position={'bottom'}
              >
                <IconHelpHexagon className={s.icon} />
              </CustomTooltip>
            }
            rightSection={<IconMail className={s.icon} />}
            placeholder={t('w.noValue')}
            {...form.getInputProps('mail')}
            disabled={
              employee?.onBoardingId !== null && user.id === employee?.id
            }
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 4 }}>
          <Select
            required
            data={rolesSelect}
            label={<LabelText text={t('w.role')} />}
            placeholder={t('w.selectRole')}
            {...form.getInputProps('securityRoleId')}
            disabled={
              Boolean(employee?.onBoardingId) && user.id === employee?.id
            }
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 4 }}>
          <Select
            required
            data={divisionsSelect}
            label={
              <LabelText
                text={
                  user.company?.divisionLabel
                    ? user.company.divisionLabel
                    : t('w.unit')
                }
              />
            }
            placeholder={t('w.select')}
            {...form.getInputProps('divisionId')}
            disabled={
              Boolean(employee?.onBoardingId) && user.id === employee?.id
            }
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 4 }}>
          <Select
            required
            data={langsSelect}
            label={<LabelText text={t('w.lang')} />}
            placeholder={t('w.select')}
            {...form.getInputProps('lang')}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6 }}>
          <Select
            data={managers}
            label={<LabelText text={t('w.n1Validator')} />}
            placeholder={t('w.select')}
            {...form.getInputProps('firstManager')}
            disabled={
              Boolean(employee?.onBoardingId) && user.id === employee?.id
            }
          />
        </Grid.Col>
        {company?.useN2 && (
          <Grid.Col span={{ base: 12, md: 6 }}>
            <Select
              data={managers}
              label={<LabelText text={t('w.n2Validator')} />}
              placeholder={t('w.select')}
              {...form.getInputProps('secondManager')}
              disabled={
                Boolean(employee?.onBoardingId) && user.id === employee?.id
              }
            />
          </Grid.Col>
        )}
      </Grid>
      {employee === null && (
        <AddEmployeeGroupRadio
          value={creationTypeValue}
          onChange={setCreationTypeValue}
          radioItems={[
            {
              value: 'create-employee',
              label: t('w.fillInTheInformationByCompany'),
            },
            {
              value: 'onBoarding',
              label: t('w.fillInTheInformationByEmployee'),
            },
          ]}
          name={'creationType'}
          label={t('w.methodForAddingAnEmployee')}
          description={t('w.directlyInTheListOfEmployeesOrViaOnBoarding')}
        />
      )}
      <Group
        styles={{
          root: {
            position: 'sticky',
            bottom: 0,
            backgroundColor: 'var(--mantine-color-body)',
          },
        }}
        mt={'51px'}
        gap={'30px'}
        justify={'flex-end'}
      >
        {employee && Boolean(employee?.onBoardingId) && (
          <Button
            onClick={() => openConfirmOnBoarding(form.values)}
            color={'#0A9182'}
            leftSection={<IconCopyCheck />}
            disabled={isDisabledConfirmOnBoardingButton()}
          >
            {employee.onBoarding?.onBoardingEmployee?.validationDate !== null &&
            employee?.onBoarding?.onBoardingCompany !== null &&
            employee?.onBoarding?.onBoardingCompany?.validationDate &&
            employee?.onBoarding?.onBoardingCompany?.validationDate
              ?.dateOfUpdate !== null
              ? t('w.finalizeOnboarding')
              : t('w.validateInfos')}
          </Button>
        )}
        <Button
          disabled={
            !form.isValid() ||
            (Boolean(employee?.onBoardingId) &&
              employee?.id === user.id &&
              employee?.onBoarding?.onBoardingEmployee?.validationDate !== null)
          }
          leftSection={<IconDeviceFloppy />}
          onClick={() => handleCreateOrUpdateEmployee(form.values)}
        >
          {t('w.save')}
        </Button>
        {employee === null && (
          <Button
            leftSection={<IconCircleArrowRight />}
            disabled={!form.isValid()}
            type={'submit'}
          >
            {creationTypeValue === 'create-employee'
              ? t('w.next')
              : 'Envoyer l’invitation'}
          </Button>
        )}

        {!Boolean(employee?.onBoardingId) ||
          (Boolean(employee?.onBoardingId) && user.id !== employee?.id && (
            <Button variant={'subtle'} onClick={onClose}>
              {t('w.close')}
            </Button>
          ))}
      </Group>
    </form>
  );
}
