import { Fieldset, Flex, SimpleGrid, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import React from 'react';

import ButtonSave from '../../../../components/ButtonSave';
import LabelText from '../../../../components/LabelText/LabelText';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import useCompany from '../../../../hooks/useCompany';
import CompanyService from '../../../../services/CompanyService';
import type { Address } from '../../../../types/api/common';
import type { CompanyGroup } from '../../../../types/api/payload/company';
import type { CompanyResponse } from '../../../../types/api/response/company';
import handleErrorMessage from '../../../../utils/handleErrorMessage';

type FormValues = {
  companyId: string;
  label: string;
  siret: string;
  address: Address;
  codeApe: string;
  healthService: string;
  id?: string | null;
};

type Props = {
  company: CompanyResponse;
  companyGroup?: CompanyGroup;
  refetch?: () => void;
};

export default function CompanyGroupForm({
  company,
  companyGroup,
  refetch,
}: Props) {
  const { t } = useI18n();
  const { user } = useAuth();
  const { id: companyId } = useCompany(user);

  const form = useForm<FormValues>({
    initialValues: {
      id: companyGroup?.id ? companyGroup.id : null,
      companyId: companyId,
      label: companyGroup?.label ? companyGroup?.label : '',
      siret: companyGroup?.siret ? companyGroup?.siret : '',
      address: {
        address1: companyGroup?.address?.address1
          ? companyGroup?.address.address1
          : '',
        address2: companyGroup?.address?.address2
          ? companyGroup?.address.address2
          : '',
        zipCode: companyGroup?.address?.zipCode
          ? companyGroup?.address.zipCode
          : '',
        city: companyGroup?.address?.city ? companyGroup?.address.city : '',
      },
      codeApe: companyGroup?.codeApe ? companyGroup?.codeApe : '',
      healthService: companyGroup?.healthService
        ? companyGroup?.healthService
        : '',
    },
  });

  const { mutate: updateCompanyGroup, isLoading } = useMutation({
    mutationFn: (payload: FormValues) =>
      CompanyService.createOrUpdateCompanyGroup(company.id, {
        ...payload,
      }),
    onSuccess: () => {
      showNotification({
        id: `update-company-successful`,
        message: t('w.success'),
        color: 'green',
        icon: <IconCheck />,
      });
      if (refetch) {
        refetch();
      }
    },
    onError: (error) =>
      showNotification({
        id: `update-company-error`,
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function handleCreateOrUpdate(payload: FormValues) {
    if (payload.id !== null) {
      const data = {
        id: payload.id,
        companyId: companyId,
        label: payload.label,
        siret: payload.siret,
        address: payload.address,
        codeApe: payload.codeApe,
        healthService: payload.healthService,
      };
      updateCompanyGroup(data);
    } else {
      const data = {
        companyId: companyId,
        label: payload.label,
        siret: payload.siret,
        address: payload.address,
        codeApe: payload.codeApe,
        healthService: payload.healthService,
      };
      updateCompanyGroup(data);
    }
  }

  return (
    <form onSubmit={form.onSubmit((values) => handleCreateOrUpdate(values))}>
      <Flex direction="column" gap="md">
        <Fieldset legend={t('w.general')}>
          <SimpleGrid cols={2}>
            <TextInput
              required
              label={<LabelText text={t('w.name')} />}
              {...form.getInputProps('label')}
            />
            <TextInput
              required
              label={<LabelText text={t('w.duns')} />}
              {...form.getInputProps('siret')}
            />
            <TextInput
              required
              label={<LabelText text={t('w.companyCodeApe')} />}
              {...form.getInputProps('codeApe')}
            />
            <TextInput
              required
              label={<LabelText text={t('w.healthService')} />}
              {...form.getInputProps('healthService')}
            />
          </SimpleGrid>
        </Fieldset>
        <Fieldset legend={t('w.address')}>
          <SimpleGrid cols={4}>
            <TextInput
              required
              label={<LabelText text={t('w.companyAddress')} />}
              {...form.getInputProps('address.address1')}
            />
            <TextInput
              label={<LabelText text={t('w.companyAddress2')} />}
              {...form.getInputProps('address.address2')}
            />
            <TextInput
              required
              label={<LabelText text={t('w.zipCode')} />}
              {...form.getInputProps('address.zipCode')}
            />
            <TextInput
              required
              label={<LabelText text={t('w.city')} />}
              {...form.getInputProps('address.city')}
            />
          </SimpleGrid>
        </Fieldset>

        <Flex justify={'flex-end'} gap="md" m={'10px'}>
          <ButtonSave isLoading={isLoading} />
        </Flex>
      </Flex>
    </form>
  );
}
