import { type FileWithPath } from '@mantine/dropzone';

import type { LeaveTypeRequest } from '../types/api/payload/leaveType';
import type {
  ImportLeaveTypeResultResponse,
  LeaveTypeResponse,
} from '../types/api/response/leaveType';
import { MS_PLANNING_ENDPOINT } from '../variables/GlobalVariables';
import request from './AxiosClient';

function getLeaveTypes(
  companyId: string,
  userId?: string
): Promise<LeaveTypeResponse[]> {
  return request
    .get(`${MS_PLANNING_ENDPOINT}/v2/leave-type`, {
      params: { companyId, userId },
    })
    .then((res) => res?.data);
}

function createLeaveType(
  companyId: string,
  payload: LeaveTypeRequest
): Promise<LeaveTypeResponse> {
  return request
    .post(`${MS_PLANNING_ENDPOINT}/v2/leave-type/${companyId}`, payload)
    .then((res) => res?.data);
}

function updateLeaveType(
  companyId: string,
  leaveTypeId: string,
  payload: LeaveTypeRequest
): Promise<LeaveTypeResponse> {
  return request
    .patch(
      `${MS_PLANNING_ENDPOINT}/v2/leave-type/${companyId}/${leaveTypeId}`,
      payload
    )
    .then((res) => res?.data);
}

function exportLeaveTypes(
  companyId: string,
  type: 'XLSX' | 'CSV'
): Promise<any> {
  return request
    .get(`${MS_PLANNING_ENDPOINT}/v2/leave-type/${companyId}/export/${type}`, {
      responseType: 'blob',
    })
    .then((res) => res?.data);
}

function importLeaveTypes(
  companyId: string,
  file: FileWithPath
): Promise<ImportLeaveTypeResultResponse> {
  return request
    .post(
      `${MS_PLANNING_ENDPOINT}/v2/leave-type/${companyId}/import`,
      {
        file: file,
        filename: file.name.split('.')[0],
        extension: file.name.split('.').pop(),
      },
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
    .then((res) => res?.data);
}

const LeaveTypeService = {
  getLeaveTypes,
  createLeaveType,
  updateLeaveType,
  exportLeaveTypes,
  importLeaveTypes,
};

export default LeaveTypeService;
