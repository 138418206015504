export enum Language {
  fr = 'fr',
  en = 'en',
  ar = 'ar',
  es = 'es',
  pt = 'pt',
  ha = 'ha',
  sw = 'sw',
  yo = 'yo',
  vi = 'vi',
  de = 'de',
  it = 'it',
}
