import { Badge } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import React from 'react';

import css from '../../../../components/scheduler/MonthEventScheduler.module.sass';
import { useI18n } from '../../../../contexts/I18nProvider';

type DailyWorkStatusBadgeProps = {
  status: string;
  smallText?: boolean | false;
  withCheck?: boolean | undefined;
};

export default function DailyWorkStatusBadge(props: DailyWorkStatusBadgeProps) {
  const { t } = useI18n();

  const checkIconInlineStyle = {
    stroke: 1.5,
    color: 'currentColor',
    opacity: 0.6,
    size: 18,
  };

  const textDisplayed = (): string => {
    if (props.status === 'VALID') return t('dailyWork.status.state.VALID');
    if (props.smallText === true) return t('dailyWork.status.state.WAITING');

    return t('w.monthToValidate');
  };
  return (
    <Badge
      classNames={{ root: css['scheduler-status-root'] }}
      styles={{
        root: {
          backgroundColor: props.status === 'VALID' ? '#CEE9E6' : '#FCF0E0',
          color: 'var(--mantine-datatable-row-color)',
          fontWeight: 'normal',
          fontSize: `${!props.smallText ? 'var(--mantine-font-size-sm)' : ''}`,
          width: `${props.smallText ? '100%' : ''}`,
          height: '26px',
        },
        label: {
          textTransform: 'none',
        },
      }}
      variant="filled"
    >
      <div style={{ display: 'flex' }}>
        {textDisplayed()}

        {props.withCheck && (
          <IconCheck
            style={{ marginInlineStart: 'auto' }}
            {...checkIconInlineStyle}
          />
        )}
      </div>
    </Badge>
  );
}
