import React, { useMemo } from 'react';

import { useAuth } from '../../../../contexts/AuthProvider';
import useFetchDivisions from '../../../../hooks/useFetchDivisions';
import type { CompanyAccountantInfoViewClient } from '../../../../types/api/response/accountant';
import type { DivisionResponse } from '../../../../types/api/response/division';
import PlanningScheduler from '../../../planning/components/PlanningScheduler';

type Props = {
  accountants: CompanyAccountantInfoViewClient;
};

export default function CustomerPlanning({ accountants }: Props) {
  const { user } = useAuth();
  const { divisions } = useFetchDivisions({
    companyId: accountants.clientCompanyId,
  });

  const managedDivisions = useMemo(() => {
    if (!divisions?.length) {
      return [];
    }

    const isCompanyAccountant = Boolean(
      accountants.generalManagers?.find((item) => item.id === user.id)
    );

    if (isCompanyAccountant) return divisions;

    const managedDivisions: DivisionResponse[] = [];
    accountants.divisionAccountant?.forEach((item) => {
      const isDivisionAccountant = Boolean(
        item.accountantUser?.find((item) => item.id === user.id)
      );
      if (isDivisionAccountant) {
        const find = divisions.find((div) => div.id === item.id);
        if (find) managedDivisions.push(find);
      }
    });

    return managedDivisions;
  }, [accountants, divisions]);

  return (
    <PlanningScheduler
      planningId={`planning-${accountants.clientCompanyId}`}
      selectedDivisions={managedDivisions.map((item) => item.id)}
      divisions={managedDivisions}
      company={accountants.customerCompany}
      isRead
    />
  );
}
