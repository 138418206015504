export default function download(
  filename: string,
  url: string,
  access_token: string
) {
  const xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';
  xhr.onload = function () {
    let a = document.createElement('a');
    a.href = window.URL.createObjectURL(this.response); // xhr.response is a blob
    a.download = filename;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
  };
  xhr.open('GET', url, true);
  xhr.setRequestHeader('Authorization', 'Bearer ' + access_token);
  xhr.send();
}
