import {
  Badge,
  Button,
  Group,
  LoadingOverlay,
  Title,
  Tooltip,
} from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import ScreenWrapper from '../../../core/layouts/components/ScreenWrapper';
import useCompany from '../../../hooks/useCompany';
import usePaymentAccounts from '../../../hooks/usePaymentAccounts';
import PaymentAccountService from '../../../services/PaymentAccountService';
import CreatePaymentAccount from './CreatePaymentAccount';

export default function Billing() {
  const { t } = useI18n();
  const { user } = useAuth();
  const { id: companyId, company } = useCompany(user);
  const { get, paymentAccounts } = usePaymentAccounts();
  const navigate = useNavigate();
  const { data: paymentAccount, isLoading } = useQuery({
    queryKey: ['PaymentAccountService.getCompanyPaymentAccount', companyId],
    queryFn: () => PaymentAccountService.getCompanyPaymentAccount(companyId),
  });

  const canConfigure = useMemo(() => {
    if (!paymentAccount || !paymentAccounts?.length) {
      return false;
    }

    return Boolean(get(paymentAccount.id));
  }, [paymentAccount, paymentAccounts]);

  if (isLoading) {
    return <LoadingOverlay visible loaderProps={{ type: 'bars' }} />;
  }

  if (!company) return null;

  return (
    <ScreenWrapper title={t('w.billing')} paper>
      {paymentAccount ? (
        <Group justify="space-between" mb={'md'}>
          <Group>
            <Badge color={paymentAccount.active ? 'green' : 'gray'}>
              {paymentAccount.active ? t('w.active') : t('w.inactive')}
            </Badge>
            <Badge
              color={
                Boolean(paymentAccount?.payment?.defaultPaymentMethodId)
                  ? 'green'
                  : 'orange'
              }
            >
              {Boolean(paymentAccount?.payment?.defaultPaymentMethodId)
                ? t('paymentAccount.defaultPaymentMethodIsSet')
                : t('paymentAccount.noDefaultPaymentMethod')}
            </Badge>
            <Title size="h3">{paymentAccount.label}</Title>
          </Group>
          <Tooltip
            disabled={canConfigure}
            label={t('paymentAccount.contact.owner', paymentAccount.owner.mail)}
          >
            <Button
              disabled={!canConfigure}
              onClick={() => navigate('/manage-payment-account')}
            >
              {t('w.configure')}
            </Button>
          </Tooltip>
        </Group>
      ) : (
        <CreatePaymentAccount company={company} />
      )}
    </ScreenWrapper>
  );
}
