import type {
  CommonEntity,
  LocalDateString,
  LocalDateTimeString,
} from '../common';
import type { DivisionResponse } from './division';
import type { LeaveTypeResponse } from './leaveType';
import type { UserResponse } from './user';

export type LeaveResponse = CommonEntity & {
  who: string;
  divisionId?: string;
  companyId: string;
  type: string;
  period: PeriodResponse;
  comment?: string;
  validatorFullname?: string;
  fileName?: string;
  startHalf: boolean;
  endHalf: boolean;
  status: LeaveStatusResponse;
  statusDateN1?: LocalDateTimeString;
  statusDateN2?: LocalDateTimeString;
  endOfMonthValidationDate?: LocalDateString;
  whoUser?: UserResponse;
  division?: DivisionResponse;
  leaveType?: LeaveTypeResponse;
  duration?: number;
};

export type PeriodResponse = {
  start: LocalDateString;
  startDate: LocalDateTimeString;
  end: LocalDateString;
  endDate: LocalDateTimeString;
  valid: boolean;
};

export type LeaveStatusResponse = {
  state: LeaveStatusState;
  comments: Comment[];
  stateDate?: LocalDateTimeString;
};

type Comment = {
  date: LocalDateTimeString;
  username: string;
  comment: string;
};

export enum LeaveStatusState {
  WAITING = 'WAITING',
  VALIDN1 = 'VALIDN1',
  VALID = 'VALID',
  REJECTED = 'REJECTED',
}
