import { ActionIcon, Breadcrumbs, Paper, Text } from '@mantine/core';
import { IconFolderRoot } from '@tabler/icons-react';
import React from 'react';

import CustomTooltip from '../../../components/CustomTooltip';
import { useI18n } from '../../../contexts/I18nProvider';
import classes from './Documents.module.sass';

type Props = {
  folderPath: string[];
  handleNavigateFolderPathClick: (index: number) => void;
  handleBackToFolderRoot: () => void;
};

export default function DocumentsFolderPathWrapper({
  folderPath,
  handleNavigateFolderPathClick,
  handleBackToFolderRoot,
}: Props) {
  const { t } = useI18n();

  return (
    <Paper withBorder p="xs" radius="xs" mb="xs">
      <Breadcrumbs>
        <CustomTooltip label={t('w.backToTheRoots')}>
          <ActionIcon
            size="xl"
            variant={'subtle'}
            onClick={handleBackToFolderRoot}
          >
            <IconFolderRoot className={classes.icon} stroke={1.5} />
          </ActionIcon>
        </CustomTooltip>
        {folderPath.map(
          (item, index) =>
            item !== '/' && (
              <Text
                size="xl"
                key={`${item}-${index}`}
                className={classes.folderOrFileText}
                onClick={() => handleNavigateFolderPathClick(index)}
              >
                {item}
              </Text>
            )
        )}
      </Breadcrumbs>
    </Paper>
  );
}
