import { base64Encode } from '../utils/base64Encode';
import {
  FRONT_URL,
  GMA_OPENID_ENDPOINT,
  MS_GMA_ENDPOINT,
} from '../variables/GlobalVariables';
import request from './AxiosClient';

const REDIRECT_URI = `${FRONT_URL}/gma/callback`;

function getCollaboratorOpenidMailConnexionLink(
  collaboratorClientId: string,
  redirectUri: string
): string {
  const state = base64Encode(new Date().toUTCString());
  return `${GMA_OPENID_ENDPOINT}/authorize?response_type=code&client_id=${collaboratorClientId}&state=${state}&scope=collaborator+openid+email&redirect_uri=${redirectUri}`;
}

function getTokensByCode(
  code: string,
  collaboratorClientId: string,
  redirectUri?: string
) {
  const params = new URLSearchParams();
  params.append('grant_type', 'authorization_code');
  params.append('code', code);
  params.append('redirect_uri', redirectUri ? redirectUri : REDIRECT_URI);
  params.append('clientId', collaboratorClientId);
  return request
    .post(`${GMA_OPENID_ENDPOINT}/token`, params, {
      headers: { Accept: '*/*' },
    })
    .then((res) => res?.data);
}

function getMyGmaUser(userId: string | undefined) {
  return request
    .get(`${MS_GMA_ENDPOINT}/v2/gma/safe/user/${userId}`)
    .then((res) => res?.data);
}

function inviteUserToGma(phone: string | undefined) {
  return request
    .post(
      `${MS_GMA_ENDPOINT}/v2/gma/safe/user/invite`,
      { phone },
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    )
    .then((res) => res?.data);
}

function getPayslipsFromGma(
  userId: string | undefined,
  access_token: string,
  refresh_token: string,
  scope: 'owner' | 'collaborator'
) {
  request
    .get(
      `${MS_GMA_ENDPOINT}/v2/gma/payslip/all?access_token=${access_token}&refresh_token=${refresh_token}&scope=${scope}&userId=${userId}`
    )
    .then((res) => res?.data);
}

function getPrivatePayslip(
  userId: string | undefined,
  spaceId: string,
  payslipId: string,
  access_token: string,
  refresh_token: string,
  scope: 'owner' | 'collaborator'
) {
  return `${MS_GMA_ENDPOINT}/v2/gma/payslip/private?userId=${userId}&spaceId=${spaceId}&payslipId=${payslipId}&accessToken=${access_token}&refreshToken=${refresh_token}&scope=${scope}`;
}

const GererMesAffairesService = {
  getCollaboratorOpenidMailConnexionLink,
  getTokensByCode,
  getMyGmaUser,
  inviteUserToGma,
  getPayslipsFromGma,
  getPrivatePayslip,
};

export default GererMesAffairesService;
