import React from 'react';

import { useAuth } from '../../contexts/AuthProvider';
import useCompany from '../../hooks/useCompany';
import useFetchDivisions from '../../hooks/useFetchDivisions';
import WorkUnitDetails from './components/WorkUnitDetails';

export default function WorkUnitView() {
  const { user } = useAuth();
  const { id: companyId, company } = useCompany(user);
  const { divisions } = useFetchDivisions({ companyId });

  if (!company || !divisions) return null;

  return <WorkUnitDetails company={company} divisions={divisions} />;
}
