import { Gender } from '../types/api/response/user';
import type { TranslateFunction } from '../types/front/common';

export function gender(t: TranslateFunction) {
  return [
    { value: '', label: t('w.noValue') },
    { value: Gender.MALE, label: t('gender.MALE') },
    { value: Gender.FEMALE, label: t('gender.FEMALE') },
    { value: Gender.UNKNOWN, label: t('gender.UNKNOWN') },
  ];
}
