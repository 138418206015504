import type { CommonEntity, LocalDateString } from '../common';
import type { LeaveResponse } from './leave';
import type { UserResponse } from './user';

export type EndOfMonthValidation = CommonEntity & {
  companyId: string;
  divisionId: string;
  monthConcerned: LocalDateString;
  valid: boolean;
  validationDate: LocalDateString;
  data: EndOfMonthData[];
};

type EndOfMonthData = {
  employee: string;
  valid: boolean;
  comment: string;
  monthConcerned: LocalDateString;
  payslipStatus?: Payroll;
  variablesPayroll: MapVariablePayrollInfo;
};

export type MapVariablePayrollInfo = {
  [key: string]: VariablePayrollInfo;
};

type VariablePayrollInfo = {
  value: string;
  editable: boolean;
};

export enum Payroll {
  TODO = 'TODO',
  DONE = 'DONE',
}

export type EndOfMonthValidationResponse = {
  id: string;
  created: number;
  updated: number;
  companyId: string;
  divisionId: string;
  monthConcerned: LocalDateString;
  valid: boolean;
  validationDate: LocalDateString;
  data: EndOfMonthDataResponse[];
  monthConcernedEnd: LocalDateString;
  monthConcernedStart: LocalDateString;
};

export type EndOfMonthDataResponse = {
  employee: string;
  valid: boolean;
  comment?: string;
  monthConcerned: LocalDateString;
  payslipStatus?: Payroll;
  variablesPayroll: MapVariablePayrollInfo;
  leaves: LeaveResponse[];
  employeeObject: UserResponse;
  endOfMonthId: string;
};

export type OpenpayeUserResponse = {
  id: string;
  created: number;
  updated: number;
  openpayeId?: string;
  dossierId?: string;
  companyId: string;
  matricule_salarie?: string;
  civilite?: string;
  nom?: string;
  nom_naissance?: string;
  nom_jeune_fille?: string;
  prenom?: string;
  situation_familiale?: string;
  numero_ss_cle?: string;
  numero_ss_cle_temp?: string;
  date_naissance?: string;
  nationalite?: string;
  commune_naissance?: string;
  departement?: string;
  pays_naissance?: string;
  nbr_enfants_charge?: string;
  contrat_en_cours: boolean;
  adresse?: OpenpayeAdresse;
  email?: string;
  telephone?: string;
  banque?: OpenpayeBanque;
  status?: OpenpayeUserStatus;
  hfwUserId: string;
};

export type OpenpayeAdresse = {
  code_distribution_etranger?: string;
  code_insee?: string;
  code_postal?: string;
  complement?: string;
  domicilie_fiscal_hors_france?: boolean;
  frontalier?: boolean;
  numero_voie?: string;
  pays?: string;
  ville?: string;
};

export type OpenpayeBanque = {
  code_bic: string;
  iban: string;
  virement: boolean;
};

export type OpenpayeUserStatus = {
  comment: string;
  status: OpenpayeStatus;
};

export enum OpenpayeStatus {
  OP_CREATED = 'OP_CREATED', // Crée mais pas ajouter a la table User
  HFW_CREATED = 'HFW_CREATED', // Crée op vers hfw
  OP_IMPORTED = 'OP_IMPORTED', // Crée hfw vers op
  HFW_IMPORTED = 'HFW_IMPORTED', // Manque info pour etre envoyer a openpaye
  OP_UPDATED = 'OP_UPDATED', // Mis à jour mais pas ajouter a la table User
  SYNCHRONIZED = 'SYNCHRONIZED', // Synchro
  ERROR = 'ERROR',
  HFW_UPDATED = 'HFW_UPDATED', // mis à jour hfw depuis openpaye
}
