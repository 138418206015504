import {
  ActionIcon,
  Button,
  Fieldset,
  FileInput,
  Grid,
  Group,
  MultiSelect,
  NumberInput,
  Select,
  SimpleGrid,
  Space,
  Switch,
  TextInput,
} from '@mantine/core';
import { DateInput, DateTimePicker } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconCalendar,
  IconCalendarTime,
  IconCheck,
  IconClipboardPlus,
  IconFile,
  IconFileInfo,
  IconUsers,
  IconX,
} from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';

import ButtonCancel from '../../../../components/ButtonCancel';
import CustomTooltip from '../../../../components/CustomTooltip';
import LabelText from '../../../../components/LabelText/LabelText';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import useFetchUsers from '../../../../hooks/useFetchUsers';
import ShuttleSheetService from '../../../../services/ShuttleSheetService';
import { BuiltInRoles } from '../../../../types/api/response/role';
import {
  ShuttleSheetRequest,
  ShuttleSheetRequestPayload,
  ShuttleSheetRequestStatus,
  ShuttleSheetRequestStatusOwner,
  ShuttleSheetTypeField,
} from '../../../../types/types';
import { handleOpenShuttleSheetFile } from '../../../../utils/attachmentFile';
import handleErrorMessage from '../../../../utils/handleErrorMessage';

type Props = {
  companyId: string;
  modalId: string;
  shuttleSheetRequest?: ShuttleSheetRequest;
  refetch: () => void;
};

type ShuttleSheetFormValues = {
  shuttleSheetTypeId: string;
  shuttleSheet?: ShuttleSheetRequest;
};

export const requestStatuses: string[] = [
  'NEW',
  'WAITING',
  'INPROGRESS',
  'REJECTED',
  'VALID',
];

export const requestStatusesOwner: string[] = [
  'EMPLOYEE',
  'MANAGER',
  'DIRECTION',
  'ADMINISTRATOR',
  'ACCOUNTANT',
];

export default function ShuttleSheetForm({
  companyId,
  modalId,
  shuttleSheetRequest,
  refetch,
}: Props) {
  const { t } = useI18n();
  const { users } = useFetchUsers(companyId);
  const { user, access_token } = useAuth();

  const shuttleSheetForm = useForm<ShuttleSheetFormValues>({
    initialValues: {
      shuttleSheetTypeId: '',
      shuttleSheet: undefined,
    },
  });

  const { data: shuttleSheetTypeAllowedListData } = useQuery({
    queryKey: ['ShuttleSheetService.shuttleSheetTypeAllowedList', companyId],
    queryFn: () => ShuttleSheetService.shuttleSheetTypeAllowedList(companyId),
  });

  const shuttleSheetTypeAllowedList = useMemo(() => {
    if (!shuttleSheetTypeAllowedListData) {
      return [];
    }
    return shuttleSheetTypeAllowedListData;
  }, [shuttleSheetTypeAllowedListData]);

  const shuttleSheetTypes = useMemo(() => {
    return shuttleSheetTypeAllowedList
      .filter((type) => type.name !== 'Embauche')
      .map((type) => ({
        value: type.id ? type.id : '',
        label: type.name,
      }));
  }, [shuttleSheetTypeAllowedList]);

  useEffect(() => {
    if (shuttleSheetRequest) {
      shuttleSheetForm.setFieldValue('shuttleSheet', {
        ...shuttleSheetRequest,
        data: shuttleSheetRequest?.data?.map((field) => ({
          field: field.field,
          value:
            field.field.type === 'DATE' || field.field.type === 'DATETIME'
              ? dayjs(field.value).toDate()
              : field.value,
        })),
      });
    } else {
      const findShuttleSheetTypeAllowed = shuttleSheetTypeAllowedList.find(
        (item) => item.id === shuttleSheetForm.values.shuttleSheetTypeId
      );
      if (findShuttleSheetTypeAllowed) {
        shuttleSheetForm.setFieldValue('shuttleSheet', {
          creatorId: user.id,
          companyId: user.company?.id!,
          divisionId: user.division?.id!,
          shuttleSheetType: findShuttleSheetTypeAllowed,
          data: findShuttleSheetTypeAllowed.fields.map((field) => ({
            field: field,
            value: undefined,
          })),
        });
      }
    }
  }, [
    shuttleSheetForm.values.shuttleSheetTypeId,
    shuttleSheetRequest,
    shuttleSheetTypeAllowedList,
  ]);

  const {
    mutate: updateShuttleSheetFile,
    isLoading: isUpdateShuttleSheetFileLoading,
  } = useMutation({
    mutationFn: (variables: {
      shuttleSheetId: string;
      file: File;
      type: string;
    }) =>
      ShuttleSheetService.updateShuttleSheetFile(
        companyId,
        variables.shuttleSheetId,
        variables.file,
        variables.type
      ),
    onError: (error) =>
      showNotification({
        id: 'update-shuttle-sheet-file-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function handleUpdateShuttleSheetFile(data: ShuttleSheetRequest) {
    if (data) {
      const { id } = data;
      if (shuttleSheetForm.values.shuttleSheet?.data) {
        shuttleSheetForm.values.shuttleSheet.data
          ?.filter((item) => item.field.type === 'FILE')
          .forEach((item) =>
            updateShuttleSheetFile({
              shuttleSheetId: id ? id : '',
              file: item.value,
              type: item.value.name?.split('.').pop(),
            })
          );
      }
    }
  }

  const { mutate: createShuttleSheet, isLoading: isCreateShuttleSheetLoading } =
    useMutation({
      mutationFn: (variables: ShuttleSheetRequestPayload) =>
        ShuttleSheetService.createShuttleSheet(companyId, variables),
      onSuccess: (data: ShuttleSheetRequest) => {
        handleUpdateShuttleSheetFile(data);

        setTimeout(() => {
          refetch();
          closeModal();
          showNotification({
            id: 'create-shuttle-sheet-successful',
            title: t('w.success'),
            message: `${t('w.yourRequestHasBeenSent')}`,
            color: 'green',
            icon: <IconCheck />,
          });
        }, 500);
      },
      onError: (error) =>
        showNotification({
          id: 'create-shuttle-sheet-error',
          title: t('w.error'),
          message: handleErrorMessage(error, t),
          color: 'red',
          icon: <IconX />,
        }),
    });

  const { mutate: updateShuttleSheet, isLoading: isUpdateShuttleSheetLoading } =
    useMutation({
      mutationFn: (variables: {
        shuttleSheetId: string;
        payload: ShuttleSheetRequestPayload;
      }) =>
        ShuttleSheetService.updateShuttleSheet(
          companyId,
          variables.shuttleSheetId,
          variables.payload
        ),
      onSuccess: (data: ShuttleSheetRequest) => {
        handleUpdateShuttleSheetFile(data);

        setTimeout(() => {
          refetch();
          closeModal();
          showNotification({
            id: 'update-shuttle-sheet-successful',
            title: t('w.success'),
            message: `${t('w.updated')}`,
            color: 'green',
            icon: <IconCheck />,
          });
        }, 500);
      },
      onError: (error) =>
        showNotification({
          id: 'update-shuttle-sheet-error',
          title: t('w.error'),
          message: handleErrorMessage(error, t),
          color: 'red',
          icon: <IconX />,
        }),
    });

  function closeModal() {
    modals.close(modalId);
  }

  function renderFieldComponent(field: ShuttleSheetTypeField) {
    const index = shuttleSheetForm.values.shuttleSheet?.data?.findIndex(
      (item) => item.field.type === field.type && item.field.name === field.name
    );

    if (index === undefined || index === -1) {
      return <div />;
    }

    switch (field.type) {
      case 'NUMBER':
        return (
          <NumberInput
            required={field.required}
            label={<LabelText text={field.name} />}
            placeholder={t('w.enterNumber')}
            {...shuttleSheetForm.getInputProps(
              `shuttleSheet.data.${index}.value`
            )}
          />
        );
      case 'BOOLEAN':
        return (
          <Switch
            required={field.required}
            checked={field.value}
            label={<LabelText text={field.name} />}
            {...shuttleSheetForm.getInputProps(
              `shuttleSheet.data.${index}.value`
            )}
          />
        );
      case 'SELECT':
      case 'USER':
        return (
          <Select
            data={
              field.type === 'USER'
                ? users.map((user) => ({
                    value: user.id,
                    label: user.fullname!,
                  }))
                : field.availableChoices
            }
            required={field.required}
            label={<LabelText text={field.name} />}
            placeholder={t('w.select')}
            rightSection={field.type === 'USER' ? <IconUsers /> : undefined}
            {...shuttleSheetForm.getInputProps(
              `shuttleSheet.data.${index}.value`
            )}
          />
        );
      case 'MULTIPLESELECT':
        return (
          <MultiSelect
            data={field.availableChoices}
            required={field.required}
            label={<LabelText text={field.name} />}
            placeholder={t('w.select')}
            {...shuttleSheetForm.getInputProps(
              `shuttleSheet.data.${index}.value`
            )}
          />
        );
      case 'FILE':
        return (
          <Grid align={'flex-end'}>
            <Grid.Col span={10}>
              <FileInput
                accept={'image/png,image/jpeg,application/pdf'}
                required={field.required}
                label={<LabelText text={field.name} />}
                placeholder={t('w.select')}
                rightSection={<IconFile />}
                {...shuttleSheetForm.getInputProps(
                  `shuttleSheet.data.${index}.value`
                )}
              />
            </Grid.Col>
            {shuttleSheetForm.values.shuttleSheet?.data &&
              shuttleSheetForm.values.shuttleSheet.data[index].value && (
                <Grid.Col span={2}>
                  <CustomTooltip label={t('w.see')}>
                    <ActionIcon
                      size={'lg'}
                      variant={'subtle'}
                      onClick={() =>
                        handleOpenShuttleSheetFile(
                          shuttleSheetForm.values.shuttleSheet?.companyId,
                          shuttleSheetForm.values.shuttleSheet?.id,
                          field.name,
                          field.type,
                          shuttleSheetForm.values.shuttleSheet?.data
                            ? shuttleSheetForm.values.shuttleSheet.data[index]
                                .value
                            : undefined,
                          access_token,
                          t
                        )
                      }
                    >
                      <IconFileInfo />
                    </ActionIcon>
                  </CustomTooltip>
                </Grid.Col>
              )}
          </Grid>
        );
      case 'DATE':
        return (
          <DateInput
            required={field.required}
            valueFormat="DD/MM/YYYY"
            label={<LabelText text={field.name} />}
            placeholder={t('w.select')}
            rightSection={<IconCalendar />}
            {...shuttleSheetForm.getInputProps(
              `shuttleSheet.data.${index}.value`
            )}
          />
        );
      case 'DATETIME':
        return (
          <DateTimePicker
            required={field.required}
            label={<LabelText text={field.name} />}
            placeholder={t('w.select')}
            rightSection={<IconCalendarTime />}
            {...shuttleSheetForm.getInputProps(
              `shuttleSheet.data.${index}.value`
            )}
          />
        );
      default:
        return (
          <TextInput
            required={field.required}
            label={<LabelText text={field.name} />}
            placeholder={t('w.enterText')}
            {...shuttleSheetForm.getInputProps(
              `shuttleSheet.data.${index}.value`
            )}
          />
        );
    }
  }

  function getStatusOwner(): ShuttleSheetRequestStatusOwner {
    switch (user.role?.name) {
      case BuiltInRoles.ADMINISTRATOR:
        return ShuttleSheetRequestStatusOwner.ADMINISTRATOR;
      case BuiltInRoles.MANAGER:
        return ShuttleSheetRequestStatusOwner.MANAGER;
      case BuiltInRoles.DIRECTION:
        return ShuttleSheetRequestStatusOwner.DIRECTION;
      default:
        return ShuttleSheetRequestStatusOwner.EMPLOYEE;
    }
  }

  function handleShuttleSheetFormSubmit(values: ShuttleSheetFormValues) {
    let data: ShuttleSheetRequestPayload = {
      divisionId: values.shuttleSheet?.divisionId
        ? values.shuttleSheet.divisionId
        : user.division?.id!,
      creatorId: values.shuttleSheet?.creatorId
        ? values.shuttleSheet.creatorId
        : user.id,
      shuttleSheetTypeId: values.shuttleSheet?.shuttleSheetType.id
        ? values.shuttleSheet?.shuttleSheetType.id
        : values.shuttleSheetTypeId,
      status: values.shuttleSheet?.status
        ? values.shuttleSheet.status
        : ShuttleSheetRequestStatus.NEW,
      statusOwner: values.shuttleSheet?.statusOwner
        ? values.shuttleSheet.statusOwner
        : getStatusOwner(),
    };

    const findStringFields = values.shuttleSheet?.shuttleSheetType?.fields.find(
      (field) => field.type === 'STRING'
    );
    const findNumberFields = values.shuttleSheet?.shuttleSheetType?.fields.find(
      (field) => field.type === 'NUMBER'
    );
    const findBooleanFields =
      values.shuttleSheet?.shuttleSheetType?.fields.find(
        (field) => field.type === 'BOOLEAN'
      );
    const findDateFields = values.shuttleSheet?.shuttleSheetType?.fields.find(
      (field) => field.type === 'DATE'
    );
    const findDateTimeFields =
      values.shuttleSheet?.shuttleSheetType?.fields.find(
        (field) => field.type === 'DATETIME'
      );
    const findFileFields = values.shuttleSheet?.shuttleSheetType?.fields.find(
      (field) => field.type === 'FILE'
    );
    const findUserFields = values.shuttleSheet?.shuttleSheetType?.fields.find(
      (field) => field.type === 'USER'
    );
    const findSelectFields = values.shuttleSheet?.shuttleSheetType?.fields.find(
      (field) => field.type === 'SELECT'
    );
    const findMultiSelectFields =
      values.shuttleSheet?.shuttleSheetType?.fields.find(
        (field) => field.type === 'MULTIPLESELECT'
      );

    if (findStringFields) {
      data = {
        ...data,
        stringFields: values?.shuttleSheet?.data
          ? values.shuttleSheet.data
              .filter((item) => item.field.type === 'STRING')
              .map((field) => ({
                field: field.field,
                value: field.value,
              }))
          : [],
      };
    }
    if (findNumberFields) {
      data = {
        ...data,
        numberFields: values?.shuttleSheet?.data
          ? values.shuttleSheet.data
              .filter((item) => item.field.type === 'NUMBER')
              .map((field) => ({
                field: field.field,
                value: field.value,
              }))
          : [],
      };
    }
    if (findBooleanFields) {
      data = {
        ...data,
        booleanFields: values?.shuttleSheet?.data
          ? values.shuttleSheet.data
              .filter((item) => item.field.type === 'BOOLEAN')
              .map((field) => ({
                field: field.field,
                value: field.value,
              }))
          : [],
      };
    }
    if (findDateFields) {
      data = {
        ...data,
        dateFields: values?.shuttleSheet?.data
          ? values.shuttleSheet.data
              .filter((item) => item.field.type === 'DATETIME')
              .map((field) => ({
                field: field.field,
                value: dayjs(field.value).format('YYYY-MM-DD'),
              }))
          : [],
      };
    }
    if (findDateTimeFields) {
      data = {
        ...data,
        dateTimeFields: values?.shuttleSheet?.data
          ? values.shuttleSheet.data
              .filter((item) => item.field.type === 'DATETIME')
              .map((field) => ({
                field: field.field,
                value: dayjs(field.value).format('YYYY-MM-DDTHH:mm:ss'),
              }))
          : [],
      };
    }
    if (findFileFields) {
      data = {
        ...data,
        fileFields: values?.shuttleSheet?.data
          ? values.shuttleSheet.data
              .filter((item) => item.field.type === 'FILE')
              .map((field) => ({
                field: field.field,
                value: field.value,
              }))
          : [],
      };
    }
    if (findUserFields) {
      data = {
        ...data,
        userFields: values?.shuttleSheet?.data
          ? values.shuttleSheet.data
              .filter((item) => item.field.type === 'USER')
              .map((field) => ({
                field: field.field,
                value: field.value,
              }))
          : [],
      };
    }
    if (findSelectFields) {
      data = {
        ...data,
        selectFields: values?.shuttleSheet?.data
          ? values.shuttleSheet.data
              .filter((item) => item.field.type === 'SELECT')
              .map((field) => ({
                field: field.field,
                value: field.value,
              }))
          : [],
      };
    }
    if (findMultiSelectFields) {
      data = {
        ...data,
        multipleSelectFields: values?.shuttleSheet?.data
          ? values.shuttleSheet.data
              .filter((item) => item.field.type === 'MULTIPLESELECT')
              .map((field) => ({
                field: field.field,
                value: field.value,
              }))
          : [],
      };
    }

    if (!Boolean(shuttleSheetRequest)) {
      createShuttleSheet(data);
    } else {
      updateShuttleSheet({
        shuttleSheetId: values.shuttleSheet?.id ? values.shuttleSheet.id : '',
        payload: data,
      });
    }
  }

  return (
    <form onSubmit={shuttleSheetForm.onSubmit(handleShuttleSheetFormSubmit)}>
      {!Boolean(shuttleSheetRequest) ? (
        <Select
          required
          data={shuttleSheetTypes}
          label={<LabelText text={t('w.shuttleSheetTypeChoice')} />}
          {...shuttleSheetForm.getInputProps('shuttleSheetTypeId')}
          placeholder={t('w.select')}
        />
      ) : (
        <Fieldset legend={t('w.status')}>
          <SimpleGrid cols={{ base: 1, lg: 2 }}>
            <Select
              data={requestStatuses.map((status) => ({
                value: status,
                label: t(`shuttleSheet.statusEnum.${status}`),
              }))}
              label={<LabelText text={t('w.shuttleSheets').slice(0, -1)} />}
              {...shuttleSheetForm.getInputProps('shuttleSheet.status')}
            />
            <Select
              data={requestStatusesOwner.map((status) => ({
                value: status,
                label: t(`shuttleSheet.statusOwnerEnum.${status}`),
              }))}
              label={<LabelText text={t('w.requester')} />}
              {...shuttleSheetForm.getInputProps('shuttleSheet.statusOwner')}
            />
          </SimpleGrid>
        </Fieldset>
      )}
      <Space h={'xl'} />
      {shuttleSheetForm?.values?.shuttleSheet && (
        <Fieldset legend={t('w.fields')}>
          <Grid>
            {shuttleSheetForm.values.shuttleSheet.shuttleSheetType.fields.map(
              (field, index) => (
                <Grid.Col key={`field-${index}`} span={{ base: 12, lg: 6 }}>
                  {renderFieldComponent(field)}
                </Grid.Col>
              )
            )}
          </Grid>
        </Fieldset>
      )}
      <Group justify={'flex-end'} mt={'xl'}>
        <ButtonCancel onClose={closeModal} />
        <Button
          loading={
            isCreateShuttleSheetLoading ||
            isUpdateShuttleSheetLoading ||
            isUpdateShuttleSheetFileLoading
          }
          leftSection={<IconClipboardPlus />}
          type={'submit'}
        >
          {!Boolean(shuttleSheetRequest) ? t('w.create') : t('w.modify')}
        </Button>
      </Group>
    </form>
  );
}
