import { Box, Menu } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import { Link } from '../../../../types/types';
import IconLink from './IconLink';

type Props = {
  item: Link;
  pathname: string;
};

export default function SubLinksMenu({ item, pathname }: Props) {
  const navigate = useNavigate();

  return (
    <Menu
      shadow="md"
      width={200}
      withArrow
      position={'right'}
      transitionProps={{
        transition: 'fade',
        duration: 150,
        timingFunction: 'ease',
      }}
    >
      <Menu.Target>
        <Box>
          <IconLink
            item={item}
            onClick={() => {
              if (item.link) {
                navigate(item.link);
              }
            }}
            pathname={pathname}
          />
        </Box>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label c={'hifivework.5'} fw={'bold'}>
          {item.label}
        </Menu.Label>
        {item?.links
          ?.filter((link) => link.isDisplayed)
          .map((subLink) => (
            <Menu.Item
              onClick={() => {
                if (subLink.link) {
                  navigate(subLink.link);
                }
              }}
              key={subLink.link}
            >
              {subLink.label}
            </Menu.Item>
          ))}
      </Menu.Dropdown>
    </Menu>
  );
}
