import { Button, Group, SimpleGrid, TextInput, Title } from '@mantine/core';
import { isNotEmpty, useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconDeviceFloppy, IconX } from '@tabler/icons-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';

import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import PaymentAccountService from '../../../services/PaymentAccountService';
import type { CompanyResponse } from '../../../types/api/response/company';
import type { CreateCompanyPaymentAccountPayload } from '../../../types/types';
import handleErrorMessage from '../../../utils/handleErrorMessage';

type Props = {
  company: CompanyResponse;
};

export default function CreatePaymentAccount({ company }: Props) {
  const { t } = useI18n();
  const { user } = useAuth();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: (payload: CreateCompanyPaymentAccountPayload) =>
      PaymentAccountService.createCompanyPaymentAccount(company.id, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          'PaymentAccountService.getCompanyPaymentAccount',
          company.id,
        ],
      });
      queryClient.invalidateQueries({
        queryKey: ['PaymentAccountService.getPaymentAccounts', user.id],
      });
      showNotification({
        id: 'create-payment-account-success',
        message: t('w.success'),
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: (error) => {
      showNotification({
        id: 'create-payment-account-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        icon: <IconX />,
        color: 'red',
      });
    },
  });

  const form = useForm<CreateCompanyPaymentAccountPayload>({
    initialValues: {
      mail: user.mail!,
      companyName: company?.name || '',
      address: company?.address?.address1 || '',
      zipCode: company?.address?.zipCode || '',
      city: company?.address?.city || '',
      siret: company?.siret || '',
    },
    validate: {
      companyName: isNotEmpty(t('w.required')),
      address: isNotEmpty(t('w.required')),
      zipCode: isNotEmpty(t('w.required')),
      city: isNotEmpty(t('w.required')),
      siret: isNotEmpty(t('w.required')),
    },
  });

  return (
    <form onSubmit={form.onSubmit((payload) => mutate(payload))}>
      <Title size={'md'} mb={'md'}>
        {t('w.billingAddress')}
      </Title>
      <SimpleGrid cols={{ base: 1, sm: 2, md: 3 }}>
        <TextInput
          label={t('w.companyName')}
          {...form.getInputProps('companyName')}
          mb={'md'}
        />
        <TextInput
          label={t('w.address')}
          {...form.getInputProps('address')}
          mb={'md'}
        />
        <TextInput
          label={t('w.zipCode')}
          {...form.getInputProps('zipCode')}
          mb={'md'}
        />
        <TextInput
          label={t('w.city')}
          {...form.getInputProps('city')}
          mb={'md'}
        />
        <TextInput
          label={t('w.siret')}
          {...form.getInputProps('siret')}
          mb={'md'}
        />
      </SimpleGrid>
      <Group justify={'flex-end'}>
        <Button
          type={'submit'}
          loading={isLoading}
          leftSection={<IconDeviceFloppy />}
        >
          {t('w.createBillingAccount')}
        </Button>
      </Group>
    </form>
  );
}
