import axios from 'axios';
import qs from 'qs';

import {
  getPersistedObject,
  persistUser,
  removePersistedObject,
} from '../utils/localStorage';
import { MS_OAUTH_ENDPOINT } from '../variables/GlobalVariables';

const request = axios.create();

// Adding Authorization header for all requests
request.interceptors.request.use(
  (config) => {
    const token = getPersistedObject('access_token');
    if (token) {
      config.headers!['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Refresh and relaunch request with same params when access token was expired
request.interceptors.response.use(
  (res) => res,
  async (err) => {
    const originalConfig = err.config;

    if (!originalConfig.url.includes('/v2/oauth/token') && err.response) {
      // Access Token was expired
      if (
        (err.response.status === 406 || err.response.status === 401) &&
        !originalConfig._retry
      ) {
        originalConfig._retry = true;
        try {
          const rs = await axios.post(
            `${MS_OAUTH_ENDPOINT}/v2/oauth/token`,
            qs.stringify({
              grant_type: 'refresh_token',
              refresh_token: getPersistedObject('refresh_token')!,
            })
          );

          persistUser(undefined, rs.data.access_token, rs.data.refresh_token);

          return request(originalConfig);
        } catch (_error) {
          // Logging out the user by removing all the tokens from local
          removePersistedObject('user');
          removePersistedObject('access_token');
          removePersistedObject('refresh_token');
          // Redirecting the user to the landing page
          window.location.href = window.location.origin;
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);

export default request;
