import { Button, Container, Group,Text, Title } from '@mantine/core';
import { Link } from 'react-router-dom';

import s from './errors.module.sass';

export default function Error404() {
  return (
    <Container className={s.root}>
      <div className={s.label}>404</div>
      <Title className={s.title}>You have found a secret place.</Title>
      <Text c="dimmed" size="lg" className={s.description}>
        Unfortunately, this is only a 404 page. You may have mistyped the
        address, or the page has been moved to another URL.
      </Text>
      <Group justify="center">
        <Link to="/">
          <Button variant="subtle" size="md">
            Take me back to the application
          </Button>
        </Link>
      </Group>
    </Container>
  );
}
