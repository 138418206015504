import { FilterOption, Options, Table } from '@ckprivate/ckf-ui';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { DataTableColumn } from 'mantine-datatable';
import React, { useEffect, useMemo, useState } from 'react';

import GoToUserButton from '../../../components/GoToUserButton/GoToUserButton';
import { useI18n } from '../../../contexts/I18nProvider';
import PlanningService from '../../../services/PlanningService';
import type { LeaveDetails } from '../../../types/api/response/dailyWork';
import type { PlanningUser } from '../../../types/types';
import {
  getFilter,
  getHiddenColumns,
  saveFilter,
  saveHiddenColumns,
} from '../../../utils/optionsPreferences';

const componentName = 'FixedPriceManagers';

type Props = {
  companyId: string;
  selectedDivisions: string[];
};

export default function WorkUnitsFixedPriceManagerTable({
  companyId,
  selectedDivisions,
}: Props) {
  const { t, lang } = useI18n();

  const {
    data: planningData,
    isLoading: isLoadingPlanningData,
    refetch,
  } = useQuery({
    enabled: false,
    queryKey: [
      'PlanningServiceMS.getPlanningFromStartAndEndDateByDivision',
      companyId,
      selectedDivisions[0],
    ],
    queryFn: () =>
      PlanningService.getPlanningFromStartAndEndDateByDivision(
        companyId,
        dayjs().endOf('year').valueOf(),
        dayjs().startOf('year').valueOf(),
        selectedDivisions
      ),
  });

  useEffect(() => {
    if (selectedDivisions.length > 0) {
      refetch();
    }
  }, [selectedDivisions]);

  const fixedPriceManagers: PlanningUser[] = useMemo(() => {
    if (!planningData) {
      return [];
    }

    const userFixedpriceManagers: PlanningUser[] = [];

    planningData.forEach((item) =>
      item.users.forEach((user) => userFixedpriceManagers.push({ ...user }))
    );

    return userFixedpriceManagers.filter((item) => item.user.fixedPriceManager);
  }, [planningData, selectedDivisions]);

  function renderNumberWorkedDays(periods: LeaveDetails[]): string {
    const nbMinutesTotal = periods
      .filter((period: any) => period.nbMinutes)
      .reduce((sum: number, period: any) => sum + period.nbMinutes, 0);
    const workedDays = nbMinutesTotal / 60 / 24;
    return Math.round(workedDays).toFixed(0);
  }

  const columns: DataTableColumn<PlanningUser>[] = useMemo(
    () => [
      {
        accessor: 'user.fullname',
        title: t('w.name'),
        sortable: true,
        ellipsis: true,
        render: ({ user }) => (
          <GoToUserButton userId={user.id} userFullname={user.fullname} />
        ),
      },
      {
        accessor: 'workedDays',
        title: t('w.workedDays'),
        sortable: true,
        ellipsis: true,
        render: ({ periods }) => renderNumberWorkedDays(periods),
      },
    ],
    []
  );
  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    search: {
      accessor: 'user.fullname',
      label: t('w.name'),
    },
    hiddenColumns: getHiddenColumns({
      componentName,
      hiddenColumns: [],
    }),
    updateHiddenColumns,
    filter: getFilter({
      componentName,
      filter: [],
    }),
    updateFilter,
  });

  function updateFilter(newFilter: FilterOption[]) {
    const _options = { ...options };
    _options.filter = newFilter;
    saveFilter({ componentName, filter: newFilter });
    setOptions(_options);
  }

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  return (
    <Table
      columns={columns}
      rows={fixedPriceManagers}
      options={options}
      lang={lang}
      fetching={isLoadingPlanningData}
      idAccessor={'user.id'}
    />
  );
}
