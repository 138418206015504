import { LoadingOverlay } from '@mantine/core';
import { Navigate } from 'react-router-dom';

import { useI18n } from '../../contexts/I18nProvider';
import ScreenWrapper from '../../core/layouts/components/ScreenWrapper';
import usePaymentAccounts from '../../hooks/usePaymentAccounts';
import Dashboard from './components/Dashboard';
import s from './PaymentAccount.module.sass';

export default function PaymentAccount() {
  const { t } = useI18n();
  const { paymentAccounts, isLoading } = usePaymentAccounts();

  if (isLoading) {
    return <LoadingOverlay visible loaderProps={{ type: 'bars' }} />;
  }

  if (!Boolean(paymentAccounts?.length)) {
    return <Navigate to="/error/404" replace={true} />;
  }

  return (
    <ScreenWrapper title={t('w.billing')} paper>
      {paymentAccounts?.map((paymentAccount) => (
        <div key={paymentAccount.id} className={s.paymentAccount}>
          <Dashboard paymentAccount={paymentAccount} />
        </div>
      ))}
    </ScreenWrapper>
  );
}
