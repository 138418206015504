import { Options, Table } from '@ckprivate/ckf-ui';
import {
  Badge,
  Button,
  Flex,
  rem,
  Switch,
  Tabs,
  Text,
  Title,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconFileDescription, IconSettings } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import { DataTableColumn } from 'mantine-datatable';
import React, { ReactNode, useMemo, useState } from 'react';

import DailyworkTypeMode from '../../../../../components/DailyworkTypeMode';
import type { ModuleToEditComponentProps } from '../../../../../components/ModuleToEditModal';
import { useAuth } from '../../../../../contexts/AuthProvider';
import { useI18n } from '../../../../../contexts/I18nProvider';
import useFetchUsers from '../../../../../hooks/useFetchUsers';
import ModuleWithParamsService from '../../../../../services/ModuleWithParamsService';
import { DailyWorkParams } from '../../../../../types/api/response/module';
import type { UserResponse } from '../../../../../types/api/response/user';
import {
  getHiddenColumns,
  saveHiddenColumns,
} from '../../../../../utils/optionsPreferences';
import { NO_WORK_UNIT } from '../../../../../variables/GlobalVariables';
import ShowMessageBeforeActivateOrDeactivateUser from './components/ShowMessageBeforeActivateOrDeactivateUser';
import VariablePayrollDailywork from './components/VariablePayrollDailywork';

const componentName = 'DailyworkParamsFeature';

type Props = {
  companyId: string;
};

enum Tab {
  CONFIG = 'CONFIG',
  VARIABLE_PAYROLL = 'VARIABLE_PAYROLL',
}

export default function DailyworkParamsFeature({
  module,
  companyId,
}: ModuleToEditComponentProps & Props) {
  const { t, lang } = useI18n();
  const { user } = useAuth();
  const {
    users,
    loading: isUsersLoading,
    refetchUsers,
  } = useFetchUsers(companyId);
  const [selectedEmployees, setSelectedEmployees] = useState<UserResponse[]>(
    []
  );
  const { data: moduleDailyworkData, refetch: refetchModuleDailyworkData } =
    useQuery({
      queryKey: ['ModuleWithParamsService.getModuleParams', companyId],
      queryFn: () =>
        ModuleWithParamsService.getModuleParams(
          module.description.url,
          companyId
        ),
    });

  const moduleParams: DailyWorkParams = useMemo(() => {
    if (!moduleDailyworkData) {
      return module?.configuration?.params as DailyWorkParams;
    }

    return moduleDailyworkData;
  }, [moduleDailyworkData]);

  const columns: DataTableColumn<UserResponse>[] = useMemo(
    () => [
      {
        accessor: 'fullname',
        title: t('w.name'),
        sortable: true,
        ellipsis: true,
        render: ({ fullname }) => <Text fz={'sm'}>{fullname}</Text>,
      },
      {
        accessor: 'employment',
        title: t('w.jobType'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'division.name',
        title: user.company?.divisionLabel
          ? user.company.divisionLabel
          : t('w.unit'),
        sortable: true,
        ellipsis: true,
        render: ({ division }) => (
          <Badge
            color={'#FCF0E0'}
            styles={{ label: { textTransform: 'none', color: '#EDB464' } }}
          >
            {division?.name === NO_WORK_UNIT
              ? t(`NO_WORK_UNIT`)
              : division?.name}
          </Badge>
        ),
      },
      {
        accessor: 'mode',
        title: 'Mode',
        sortable: true,
        ellipsis: true,
        render: ({ id, companyId, fixedPriceManager, paidFeatures }) => (
          <DailyworkTypeMode
            t={t}
            id={id}
            companyId={companyId!}
            fixedPriceManager={Boolean(fixedPriceManager)}
            paidFeatures={paidFeatures!}
            refetchUsers={refetchUsers}
          />
        ),
      },
    ],
    [isUsersLoading]
  );

  function onChangeActiveForAll(event: any) {
    modals.open({
      modalId: `confirm-to-active-user-dailywork`,
      title: (
        <Title size={'h3'} component="p">
          {t('w.confirmation')}
        </Title>
      ),
      size: '80%',
      children: (
        <ShowMessageBeforeActivateOrDeactivateUser
          selectedEmployees={selectedEmployees}
          activate={false}
          companyId={companyId}
          activeForAll={event.currentTarget.checked}
          allUsers={true}
          apiUrl={module.description.url}
          refetchModule={refetchModuleDailyworkData}
          moduleParams={moduleParams}
        />
      ),
    });
  }

  const rightCustomActions: ReactNode[] = useMemo(
    () => [
      <Button
        key={'dailywork-active-user-button'}
        onClick={() =>
          modals.open({
            modalId: `confirm-to-active-user-dailywork`,
            title: (
              <Title size={'h3'} component="p">
                {t('w.confirmation')}
              </Title>
            ),
            size: '80%',
            children: (
              <ShowMessageBeforeActivateOrDeactivateUser
                selectedEmployees={selectedEmployees}
                activate={true}
                companyId={companyId}
                activeForAll={false}
                allUsers={false}
                apiUrl={module.description.url}
                refetchModule={refetchModuleDailyworkData}
                moduleParams={moduleParams}
              />
            ),
          })
        }
      >
        {t('w.activate')}
      </Button>,
      <Button
        key={'dailywork-desactive-user-button'}
        onClick={() =>
          modals.open({
            modalId: `confirm-to-deactivate-user-dailywork`,
            title: (
              <Title size={'h3'} component="p">
                {t('w.confirmation')}
              </Title>
            ),
            size: '80%',
            children: (
              <ShowMessageBeforeActivateOrDeactivateUser
                selectedEmployees={selectedEmployees}
                activate={false}
                companyId={companyId}
                activeForAll={false}
                allUsers={false}
                apiUrl={module.description.url}
                refetchModule={refetchModuleDailyworkData}
                moduleParams={moduleParams}
              />
            ),
          })
        }
      >
        {t('w.deactivate')}
      </Button>,
    ],
    [selectedEmployees.length]
  );

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    search: {
      accessor: 'fullname',
      label: t('w.name'),
    },
    hiddenColumns: getHiddenColumns({
      componentName,
      hiddenColumns: [],
    }),
    updateHiddenColumns,
  });

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  const iconStyle = { width: rem(12), height: rem(12) };

  return (
    <>
      <Tabs orientation="vertical" defaultValue={Tab.CONFIG}>
        <Tabs.List>
          <Tabs.Tab
            leftSection={<IconSettings style={iconStyle} />}
            value={Tab.CONFIG}
          >
            {t('w.configuration')}
          </Tabs.Tab>
          <Tabs.Tab
            leftSection={<IconFileDescription style={iconStyle} />}
            value={Tab.VARIABLE_PAYROLL}
          >
            {t('module.VariablePayroll')}
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value={Tab.CONFIG} pl="xs">
          <Flex direction={'column'} gap={'sm'}>
            <Flex align={'center'} gap={'sm'}>
              <Switch
                checked={
                  moduleParams?.activeForAll ? moduleParams.activeForAll : false
                }
                onChange={(event) => {
                  onChangeActiveForAll(event);
                }}
              />
              <Text>{t('w.activeForAllUsers')}</Text>
            </Flex>
            {!Boolean(moduleParams?.activeForAll) && (
              <Table
                pinFirstColumn
                pinLastColumn
                onSelect={(employees) =>
                  setSelectedEmployees(employees as UserResponse[])
                }
                rows={users.filter((user) => Boolean(user.valid))}
                columns={columns}
                lang={lang}
                fetching={isUsersLoading}
                withTableBorder={false}
                rightCustomActions={
                  !!selectedEmployees.length ? rightCustomActions : []
                }
                options={options}
              />
            )}
          </Flex>
        </Tabs.Panel>

        <Tabs.Panel value={Tab.VARIABLE_PAYROLL} pl="xs">
          <VariablePayrollDailywork
            companyId={companyId}
            apiUrl={module.description.url}
            module={module}
            refetchModule={refetchModuleDailyworkData}
          />
        </Tabs.Panel>
      </Tabs>
    </>
  );
}
