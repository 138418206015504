import { Transition } from '@mantine/core';
import { MantineTransition } from '@mantine/core/lib/components/Transition/transitions';
import { ReactNode } from 'react';

type Props = {
  opened: boolean;
  transition: MantineTransition;
  duration: number;
  timingFunction: string;
  children: ReactNode;
};

export default function CustomTransition({
  opened,
  transition,
  duration,
  timingFunction,
  children,
  ...props
}: Props) {
  return (
    <Transition
      mounted={opened}
      transition={transition}
      duration={duration}
      timingFunction={timingFunction}
      {...props}
    >
      {(styles) => <div style={styles}>{children}</div>}
    </Transition>
  );
}
