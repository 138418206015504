import dayjs from 'dayjs';

export function initPickerDate(): Date {
  const date = new Date();
  const dateMoment = dayjs(date).get('date');
  if (dateMoment < 15) {
    return dayjs(date).subtract(1, 'month').toDate();
  } else {
    return date;
  }
}
