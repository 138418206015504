import CustomModal from '../../../../components/CustomModal';
import { useI18n } from '../../../../contexts/I18nProvider';
import InviteProfileForm from './InviteProfileForm';

type Props = {
  opened: boolean;
  onClose: () => void;
};

export default function InviteProfileModal({ opened, onClose }: Props) {
  const { t } = useI18n();

  return (
    <CustomModal
      size={'xl'}
      opened={opened}
      onClose={onClose}
      title={t('w.sendInvitation')}
    >
      <InviteProfileForm onClose={onClose} />
    </CustomModal>
  );
}
