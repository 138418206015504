import {
  Button,
  Flex,
  LoadingOverlay,
  PasswordInput,
  Stack,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import React from 'react';

import { useI18n } from '../../../../contexts/I18nProvider';
import OauthServiceMS from '../../../../services/OauthServiceMS';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
import { validatePassword } from '../../../../utils/validators';
import { PasswordStrength } from '../../../reset-password/components/PasswordStrength';

type FormValues = {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword?: string;
};

export default function OauthUpdatePasswordForm() {
  const { t } = useI18n();

  const form = useForm<FormValues>({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validate: {
      oldPassword: (value) =>
        validatePassword(value)
          ? null
          : t('w.yourPasswordMustContainAtLeast8characters'),
      newPassword: (value, values) =>
        value !== values.confirmNewPassword
          ? 'Le nouveau mot de passe doit être identique à la confirmation du mot de passe'
          : null,
      confirmNewPassword: (value, values) =>
        value !== values.newPassword
          ? 'La confirmation du nouveau mot de passe doit être identique au nouveau mot de passe'
          : null,
    },
  });

  const { mutate: updatePassword, isLoading: isUpdatePasswordLoading } =
    useMutation({
      mutationFn: (variables: FormValues) =>
        OauthServiceMS.updatePassword(
          variables.oldPassword,
          variables.newPassword
        ),
      onSuccess: () => {
        showNotification({
          id: 'update-password-successful',
          title: t('w.success'),
          message: t('success.password.updated'),
          color: 'green',
          icon: <IconCheck />,
        });
        form.reset();
      },
      onError: (error) =>
        showNotification({
          id: 'update-password-error',
          title: t('w.error'),
          message: handleErrorMessage(error, t),
          color: 'red',
          icon: <IconX />,
        }),
    });

  function handleUpdatePasswordSubmit(values: FormValues) {
    updatePassword({
      oldPassword: values.oldPassword,
      newPassword: values.confirmNewPassword ? values.confirmNewPassword : '',
    });
  }

  return (
    <form
      onSubmit={form.onSubmit((values) => handleUpdatePasswordSubmit(values))}
    >
      <LoadingOverlay visible={isUpdatePasswordLoading} />
      <Stack>
        <Title size={'h4'}>{`${t('w.modify')} ${t(
          'w.yourPassword'
        ).toLowerCase()}`}</Title>
        <PasswordInput
          id={'actualPassword'}
          required
          label={t('w.actualPassword')}
          placeholder={t('w.yourActualPassword')}
          {...form.getInputProps('oldPassword')}
        />
        <PasswordInput
          id={'newPassword'}
          required
          label={t('w.newPassword')}
          placeholder={t('w.yourNewPassword')}
          {...form.getInputProps('newPassword')}
        />
        <PasswordStrength
          label={t('w.confirmNewPassword')}
          placeholder={t('w.yourNewPassword')}
          value={
            form.values.confirmNewPassword ? form.values.confirmNewPassword : ''
          }
          onChange={(event) =>
            form.setFieldValue('confirmNewPassword', event.currentTarget.value)
          }
          error={form.errors.confirmNewPassword}
        />
        <Flex justify={'flex-end'} mt={'md'}>
          <Button type={'submit'}>{t('w.modify')}</Button>
        </Flex>
      </Stack>
    </form>
  );
}
