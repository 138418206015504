import { Button, Chip, Flex } from '@mantine/core';
import React, { useMemo } from 'react';

import CustomModal from '../../../components/CustomModal';
import { useI18n } from '../../../contexts/I18nProvider';

type Props = {
  opened: boolean;
  onClose: () => void;
  title: string;
  value: string | undefined;
  onChange: (value: string) => void;
  day: string | undefined;
};

export default function UserWeekRepartitionModal({
  opened,
  onClose,
  title,
  value,
  onChange,
  day,
}: Props) {
  const { t } = useI18n();

  const BREAKDAY_REPEAT_MODES = useMemo(
    () => [
      'EVERY_WEEKS',
      'EVERY_FIRST_OF_MONTH',
      'EVERY_SECOND_OF_MONTH',
      'EVERY_THIRD_OF_MONTH',
      'EVEN_WEEKS',
      'ODD_WEEKS',
    ],
    []
  );

  return (
    <CustomModal size={'md'} opened={opened} onClose={onClose} title={title}>
      <Chip.Group multiple={false} value={value} onChange={onChange}>
        <Flex direction={'column'} gap={'md'}>
          {BREAKDAY_REPEAT_MODES.map((mode) => (
            <Chip key={mode} value={mode} size="xs">
              {t('breakDays.repeat.' + mode, t('daysEnum.' + day))}
            </Chip>
          ))}
        </Flex>
      </Chip.Group>
      <Flex justify={'flex-end'}>
        <Button onClick={onClose}>{t('w.validate')}</Button>
      </Flex>
    </CustomModal>
  );
}
