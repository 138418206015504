import { Flex, Image, Paper, Stack, Stepper, Text, Title } from '@mantine/core';
import { useState } from 'react';

import { useI18n } from '../../../../contexts/I18nProvider';

export default function TutorialGma() {
  const { t } = useI18n();
  const [active, setActive] = useState(0);
  return (
    <Flex justify={'center'} mt={'xl'}>
      <Paper shadow="xl" p="xl" withBorder>
        <Stack gap={'lg'}>
          <Title size={'h3'} c={'hifivework.5'}>
            Information
          </Title>
          <Text fz={'sm'}>
            Pour visualiser vos bulletins, suivez le tutoriel étape par étape
          </Text>
          <Stepper active={active} onStepClick={setActive}>
            <Stepper.Step>
              <Text fz={'sm'} c={'dimmed'}>
                {t('w.logout')}
              </Text>
            </Stepper.Step>
            <Stepper.Step>
              <Text fz={'sm'} c={'dimmed'}>
                {t('gma.connectWithGMAButton')}
              </Text>
            </Stepper.Step>
            <Stepper.Step>
              <Text fz={'sm'} c={'dimmed'}>
                {t('gma.returnToPayslipArea')}
              </Text>
            </Stepper.Step>
          </Stepper>
          <Image src={'/images/payslips/undraw_Information_tab.png'} />
        </Stack>
      </Paper>
    </Flex>
  );
}
