import { Grid, Paper, Radio } from '@mantine/core';
import React from 'react';

type Props = {
  value: string;
  onChange: (value: string) => void;
  radioItems: { value: string; label: string; disabled?: boolean }[];
  name: string;
  label: React.ReactNode;
  description: React.ReactNode;
};

export default function AddEmployeeGroupRadio({
  value,
  onChange,
  radioItems,
  name,
  label,
  description,
}: Props) {
  return (
    <Radio.Group
      value={value}
      onChange={onChange}
      name={name}
      label={label}
      description={description}
      mt={'xl'}
    >
      {radioItems.length > 0 && (
        <Grid>
          {radioItems.map((item, index) => (
            <Grid.Col span={{ base: 12, md: 6 }} key={`${item.value}-${index}`}>
              <Paper shadow="xs" p={'md'} radius={'md'} withBorder>
                <Radio
                  value={item.value}
                  label={item.label}
                  disabled={item.disabled}
                />
              </Paper>
            </Grid.Col>
          ))}
        </Grid>
      )}
    </Radio.Group>
  );
}
