import { Flex, Skeleton, Space } from '@mantine/core';
import React from 'react';

export default function UserProfileSkeleton() {
  return (
    <>
      <Skeleton height={50} width="20%" radius="md" />
      <Space h={'xl'} />
      <Skeleton height={25} mt={6} width="60%" radius="md" />
      <Space h={'xl'} />
      <Flex ml={50} gap={'xl'}>
        <Skeleton height={120} circle mb="xl" />
        <Skeleton height={240} width="30%" radius="md" />
        <Skeleton height={60} width="10%" radius="md" />
      </Flex>
    </>
  );
}
