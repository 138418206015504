import { Badge } from '@mantine/core';
import React from 'react';

import { useI18n } from '../../contexts/I18nProvider';

export default function NotYetValidatedBadge() {
  const { t } = useI18n();
  return (
    <Badge
      styles={{
        root: {
          backgroundColor: '#E70D4F1A',
          color: 'red',
        },
        label: {
          textTransform: 'none',
        },
      }}
      variant="filled"
    >
      {t('w.notYetValidated')}
    </Badge>
  );
}
