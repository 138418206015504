import { Anchor, Button, Flex, Space, Text } from '@mantine/core';
import React from 'react';

import CustomModal from '../../../components/CustomModal';
import { useI18n } from '../../../contexts/I18nProvider';
import { MS_WHITE_LABEL_ID } from '../../../variables/GlobalVariables';

type Props = {
  opened: boolean;
  onClose: () => void;
  onClick: () => void;
};

export default function PrivacyPolicyModal({
  opened,
  onClose,
  onClick,
}: Props) {
  const { t } = useI18n();

  return (
    <CustomModal opened={opened} onClose={onClose}>
      <Text>
        {t('w.privacyPolicyTextPart3')}
        <Anchor
          href={
            MS_WHITE_LABEL_ID === 'AFRICAPAIERH'
              ? 'https://africapaierh.com/politique-de-confidentialite'
              : 'https://www.hifivework.com/politique-de-confidentialite'
          }
          target="_blank"
        >
          {t('w.privacyPolicy')}
        </Anchor>
      </Text>
      <Space h={'xl'} />
      <Flex justify={'flex-end'}>
        <Button onClick={onClick}>{t('w.iAgree')}</Button>
      </Flex>
    </CustomModal>
  );
}
