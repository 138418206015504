import { LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import React, { ReactNode } from 'react';

type MicrosoftProviderProps = {
  children: ReactNode;
};

export default function MicrosoftProvider({
  children,
}: MicrosoftProviderProps) {
  const msalInstance = new PublicClientApplication({
    auth: {
      clientId: '5cb3e1c9-a858-4ae4-b4a3-1a0e0ddef42f',
      authority: 'https://login.microsoftonline.com/common',
      redirectUri: '/',
    },
    cache: {
      cacheLocation: 'sessionStorage', // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {
        loggerCallback: (
          level: number,
          message: string,
          containsPii: boolean
        ) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              // console.info(message);
              return;
            case LogLevel.Verbose:
              // console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
          }
        },
      },
    },
  });

  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
}
