import { TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDebouncedCallback } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect } from 'react';

import { useI18n } from '../../../../contexts/I18nProvider';
import VariablePayrollService, {
  UpdateEmployeeVariablePayrollPayload,
} from '../../../../services/VariablePayrollService';
import handleErrorMessage from '../../../../utils/handleErrorMessage';

type Props = {
  variablePayrollValue: string | undefined;
  disabled: boolean;
  companyId: string;
  endOfMonthId: string;
  employeeId: string;
  variablePayrollId: string;
  refetchAllData: () => void;
};

type FormValues = {
  variablePayrollValue: string | undefined;
  isInit?: boolean;
};

export default function EditVariablePayrollTextInput({
  variablePayrollValue,
  disabled,
  companyId,
  endOfMonthId,
  employeeId,
  variablePayrollId,
  refetchAllData,
}: Props) {
  const { t } = useI18n();
  const form = useForm<FormValues>({
    initialValues: {
      variablePayrollValue: variablePayrollValue || '',
      isInit: true,
    },
    onValuesChange: (values) => {
      if (!values.isInit) {
        handleAutoSave(values.variablePayrollValue as string);
      }
    },
  });

  useEffect(() => {
    form.setValues({
      variablePayrollValue: variablePayrollValue || '',
      isInit: true,
    });
  }, [variablePayrollValue]);

  const { mutate: updateEmployeeVariablePayroll } = useMutation({
    mutationFn: (variables: UpdateEmployeeVariablePayrollPayload) =>
      VariablePayrollService.updateEmployeeVariablePayroll(
        companyId,
        variables
      ),
    onSuccess: () => {
      refetchAllData();
    },
    onError: (error) =>
      showNotification({
        id: 'update-employee-variable-payroll',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const handleAutoSave = useDebouncedCallback(
    async (_variablePayrollValue: string) => {
      updateEmployeeVariablePayroll({
        employeeId,
        endOfMonthId,
        variablePayrollId,
        variablePayrollValue: _variablePayrollValue,
      });
    },
    1000
  );

  return (
    <TextInput
      disabled={disabled}
      size={'xs'}
      {...form.getInputProps('variablePayrollValue')}
      onChange={(event) =>
        form.setValues({
          variablePayrollValue: event.currentTarget.value,
          isInit: false,
        })
      }
    />
  );
}
