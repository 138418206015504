import { List } from '@mantine/core';

import { useI18n } from '../../../../contexts/I18nProvider';

export default function ImportPayslipsInfoList() {
  const { t } = useI18n();

  return (
    <List size="sm">
      {[
        t('w.downloadPdfFileFirstInfoMessage'),
        t('w.downloadPdfFileSecondInfoMessage'),
      ].map((item, index) => (
        <List.Item key={`${item}-${index}`}>{item}</List.Item>
      ))}
    </List>
  );
}
