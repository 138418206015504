import { Flex, Group, Image, Paper, Stack, Text } from '@mantine/core';

import { useAuth } from '../../../contexts/AuthProvider';
import { ShuttleSheetComment } from '../../../types/types';
import { milliToFullDateString } from '../../../utils/format';

type Props = {
  comments: ShuttleSheetComment[];
};

export default function ShuttleSheetCommentsItem({ comments }: Props) {
  const { user } = useAuth();

  return (
    <Stack>
      {comments?.length === 0 ? (
        <Image src={'/images/shuttle-sheets/chat.png'} />
      ) : (
        comments?.map((comment) => (
          <Flex
            justify={
              user.fullname === comment.username ? 'flex-end' : 'flex-start'
            }
          >
            <Paper w={'70%'} bg={'hifivework.1'} p={'xs'} key={comment.date}>
              <Group gap={'xs'} align={'flex-end'}>
                <Text fz={'sm'} fw={'bold'}>
                  {comment.username}
                </Text>
                <Text>-</Text>
                <Text fz={'xs'}>{milliToFullDateString(comment.date)}</Text>
              </Group>
              <Text fz={'xs'} fw={'bold'} c={'hifivework.5'}>
                {comment.comment}
              </Text>
            </Paper>
          </Flex>
        ))
      )}
    </Stack>
  );
}
