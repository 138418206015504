import { Tabs } from '@mantine/core';
import { useMemo } from 'react';

import { useAuth } from '../../contexts/AuthProvider';
import { useI18n } from '../../contexts/I18nProvider';
import ScreenWrapper from '../../core/layouts/components/ScreenWrapper';
import useCompany from '../../hooks/useCompany';
import { BuiltInPermissions, ScopeEnum } from '../../types/api/response/role';
import { hasPermission } from '../../utils/authorization';
import MyShuttleSheetsTable from './components/MyShuttleSheetsTable';
import ShuttleSheetsMonitoringTable from './components/ShuttleSheetsMonitoringTable';

export default function ShuttleSheets() {
  const { t } = useI18n();
  const { user } = useAuth();
  const { id: companyId } = useCompany(user);

  const tabs = useMemo(
    () => [
      {
        value: 'my-shuttle-sheets',
        label: t('w.myShuttleSheets'),
        component: (
          <MyShuttleSheetsTable companyId={companyId} userId={user.id} />
        ),
        isDisplayed: true,
      },
      {
        value: 'monitoring',
        label: t('w.monitoring'),
        component: (
          <ShuttleSheetsMonitoringTable
            companyId={companyId}
            isAccountant={false}
          />
        ),
        isDisplayed: hasPermission(
          [
            {
              permission: BuiltInPermissions.ValidateShuttleSheets,
              scope: ScopeEnum.ALL,
            },
          ],
          user
        ),
      },
    ],
    []
  );

  return (
    <ScreenWrapper title={t('w.shuttleSheets')}>
      <Tabs defaultValue="my-shuttle-sheets">
        <Tabs.List>
          {tabs
            .filter((tab) => tab.isDisplayed)
            .map((tab, index) => (
              <Tabs.Tab key={`tab-${index}`} value={tab.value}>
                {tab.label}
              </Tabs.Tab>
            ))}
        </Tabs.List>
        {tabs
          .filter((tab) => tab.isDisplayed)
          .map((tab, index) => (
            <Tabs.Panel pt={'md'} key={`panel-${index}`} value={tab.value}>
              {tab.component}
            </Tabs.Panel>
          ))}
      </Tabs>
    </ScreenWrapper>
  );
}
