import type {
  HfwSecurityRoleResponse,
  OauthRoleUsed,
} from '../types/api/response/role';
import { MS_HIFIVEWORK_PROFILE_ENDPOINT } from '../variables/GlobalVariables';
import request from './AxiosClient';

function list(companyId: string): Promise<HfwSecurityRoleResponse[]> {
  return request
    .get(`${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/roles/${companyId}`)
    .then((res) => res?.data);
}

function deleteRole(companyId: string, roleId: string): Promise<void> {
  return request
    .delete(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/roles/${roleId}/company/${companyId}`
    )
    .then((res) => res?.data);
}

function createRole(
  companyId: string,
  roleName: string
): Promise<HfwSecurityRoleResponse> {
  return request
    .post(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/roles/${roleName}/company/${companyId}`
    )
    .then((res) => res?.data);
}

export interface PermissionData {
  permissionId: string;
  scope: string | undefined;
}

function update(
  companyId: string,
  roleId: string,
  data: { permissionScoped: PermissionData[] | undefined }
): Promise<HfwSecurityRoleResponse> {
  return request
    .patch(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/roles/${companyId}/${roleId}`,
      data
    )
    .then((res) => res?.data);
}

function getLinkedEntities(
  companyId: string,
  roleId: string
): Promise<OauthRoleUsed[]> {
  return request
    .get(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/roles/${roleId}/company/${companyId}/entities`
    )
    .then((res) => res?.data);
}

const SecurityRoleService = {
  list,
  deleteRole,
  createRole,
  update,
  getLinkedEntities,
};

export default SecurityRoleService;
