// @ts-nocheck
export const API_BASE_URL = window._env_.API_ENDPOINT;
export const LOGO_PATH = window._env_.LOGO_PATH;
export const LOGO_SQUARE_PATH = window._env_.LOGO_SQUARE_PATH;
export const APP_FAVICON = window._env_.APP_FAVICON;
export const APP_NAME = window._env_.APP_NAME;
export const MS_WHITE_LABEL_ENDPOINT = window._env_.MS_WHITE_LABEL_ENDPOINT;
export const MS_WHITE_LABEL_ID = window._env_.MS_WHITE_LABEL_ID;
export const MS_PLANNING_ENDPOINT = window._env_.MS_PLANNING_ENDPOINT;
export const MS_GMA_ENDPOINT = window._env_.MS_GMA_ENDPOINT;
export const MS_PAYSLIP_ENDPOINT = window._env_.MS_PAYSLIP_ENDPOINT;
export const MS_MAITREDATA_ENDPOINT = window._env_.MS_MAITREDATA_ENDPOINT;
export const MS_OAUTH_ENDPOINT = window._env_.MS_OAUTH_ENDPOINT;
export const MS_EXPENSEREPORT_ENDPOINT = window._env_.MS_EXPENSEREPORT_ENDPOINT;
export const MS_HIFIVEWORK_PROFILE_ENDPOINT =
  window._env_.MS_HIFIVEWORK_PROFILE_ENDPOINT;
export const MS_BILLING_ENDPOINT = window._env_.MS_BILLING_ENDPOINT;
export const GMA_OPENID_ENDPOINT = window._env_.GMA_OPENID_ENDPOINT;
export const FRONT_URL = window.location.origin;
export const GMA_HFW_COLLABORATOR_CLIENT_ID =
  window._env_.GMA_HFW_COLLABORATOR_CLIENT_ID;
export const CK_MODULE_MANAGER_ENDPOINT =
  window._env_.CK_MODULE_MANAGER_ENDPOINT;
export const HFW_MODULES_ENDPOINT = window._env_.HFW_MODULES_ENDPOINT;
export const MS_ENDMONTH_ENDPOINT = window._env_.MS_ENDMONTH_ENDPOINT;
export const MS_CHARTERED_ACCOUNTANT_ENDPOINT =
  window._env_.MS_CHARTERED_ACCOUNTANT_ENDPOINT;
export const MS_NOTIFCATION_ENDPOINT = window._env_.MS_NOTIFCATION_ENDPOINT;
export const MS_SHUTTLESHEET_ENDPOINT = window._env_.MS_SHUTTLESHEET_ENDPOINT;
export const MS_DOCUMENTS_ENDPOINT = window._env_.MS_DOCUMENTS_ENDPOINT;
export const GOOGLE_CLIENT_ID = window._env_.GOOGLE_CLIENT_ID;

export const NO_WORK_UNIT = 'NO_WORK_UNIT';
export const UNDER_MAINTENANCE = window._env_.UNDER_MAINTENANCE;
export const HISTOPAIE_HOST = window._env_.HISTOPAIE_HOST;
