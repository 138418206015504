import {
  Button,
  Container,
  Flex,
  Grid,
  Skeleton,
  Space,
  Stepper,
  Text,
  Title,
} from '@mantine/core';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Logo from '../../components/Logo';
import { useI18n } from '../../contexts/I18nProvider';
import { useQueryString } from '../../hooks/useQueryString';
import CompanyForm from './components/CompanyForm';
import UserForm from './components/UserForm';
import signUpSvg from './logos/undraw_sign_up.svg';

function UserFormSkeleton() {
  return (
    <Grid>
      <Grid.Col span={6}>
        <Skeleton height={36} radius="sm" />
      </Grid.Col>
      <Grid.Col span={6}>
        <Skeleton height={36} radius="sm" />
      </Grid.Col>
      <Grid.Col span={6}>
        <Skeleton height={36} radius="sm" />
      </Grid.Col>
      <Grid.Col span={6}>
        <Skeleton height={36} radius="sm" />
      </Grid.Col>
      <Grid.Col span={6}>
        <Skeleton height={36} radius="sm" />
      </Grid.Col>
      <Grid.Col span={6}>
        <Skeleton height={36} radius="sm" />
      </Grid.Col>
    </Grid>
  );
}

export default function ConfirmAccount() {
  const { t } = useI18n();
  const queryString = useQueryString();
  const [tokenPath, setTokenPath] = useState<string | null>(null);
  const [user, setUser] = useState<any>(null);
  const [newUser, setNewUser] = useState({
    access_token: '',
    refresh_token: '',
  });
  const [active, setActive] = useState(0);
  const [userPhone, setUserPhone] = useState<string | undefined>('');
  const [companyPhone, setCompanyPhone] = useState<string | undefined>('');

  const navigate = useNavigate();

  useEffect(() => {
    setTokenPath(queryString.get('token'));
  }, [queryString]);

  useEffect(() => {
    if (tokenPath !== null) {
      setUser(jwtDecode<JwtPayload>(tokenPath));
    }
  }, [tokenPath]);

  function handleNextStep() {
    setActive(1);
  }

  return (
    <Grid style={{ height: '100vh' }}>
      <Grid.Col span={{ md: 6 }} style={{ backgroundColor: 'white' }}>
        <Flex pt={20} px={20}>
          <Logo />
        </Flex>
        <Container
          display={'grid'}
          size={'sm'}
          style={{ height: '100vh', alignContent: 'center' }}
        >
          <Title order={1} size={'h1'} c={'hifivework.9'}>
            {t('w.configureYourAccount')}
          </Title>
          <Space h="xl" />
          <Space h="xl" />
          {user ? (
            user.username ? (
              <Stepper active={active} orientation="vertical">
                <Stepper.Step
                  label={
                    active !== 0
                      ? t('w.passwordCreated')
                      : t('w.passwordCreation')
                  }
                  description={
                    active !== 0
                      ? t('w.completedForm')
                      : t('w.fillOutFollowingForm')
                  }
                >
                  <UserForm
                    user={user}
                    tokenPath={tokenPath}
                    handleNextStep={handleNextStep}
                    userPhone={userPhone}
                    onUserPhoneChange={(value) => setUserPhone(value)}
                    setNewUser={setNewUser}
                  />
                </Stepper.Step>
                <Stepper.Step
                  label={
                    active === 2
                      ? t('w.companyCreated')
                      : t('w.creationOfYourCompany')
                  }
                  description={
                    active === 2
                      ? t('w.completedForm')
                      : t('w.fillOutFollowingForm')
                  }
                >
                  <CompanyForm
                    user={user}
                    userPhone={userPhone}
                    userAccessToken={newUser.access_token}
                    userRefreshToken={newUser.refresh_token}
                    companyPhone={companyPhone}
                    onCompanyPhoneChange={(value) => setCompanyPhone(value)}
                    handleBackStep={() => setActive(0)}
                    handleFinalStep={() => setActive(2)}
                  />
                </Stepper.Step>
                <Stepper.Step
                  label={
                    active !== 0 && active !== 1
                      ? t('w.yourAccountHasBeenSuccessfullyConfigured')
                      : t('w.accountAwaitingConfiguration')
                  }
                  description={
                    active !== 0 && active !== 1
                      ? t('w.youCanGoToTheApp')
                      : t('w.completeSteps1And2')
                  }
                >
                  <Flex justify={'flex-end'}>
                    <Button onClick={() => navigate('/home')}>
                      {t('w.goToTheApp')}
                    </Button>
                  </Flex>
                </Stepper.Step>
              </Stepper>
            ) : (
              <Text>{t('w.accessTokenIsNoLongerValid')}</Text>
            )
          ) : (
            <UserFormSkeleton />
          )}
        </Container>
      </Grid.Col>
      <Grid.Col span={{ md: 6 }}>
        <Container
          display={'grid'}
          size={'xs'}
          style={{
            height: '100vh',
            alignContent: 'center',
          }}
        >
          <img src={signUpSvg} width={'100%'} alt={'sign-up'} />
        </Container>
      </Grid.Col>
    </Grid>
  );
}
