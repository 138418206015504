import { FileWithPath } from '@mantine/dropzone';
import dayjs from 'dayjs';

import {
  CompanyPayslipExtractLocationTemplate,
  CompanyPayslipExtractLocationTemplatePayload,
  PayslipExtractLocation,
  PayslipExtractLocationPayload,
  PayslipMonthResponse,
  PayslipsMonthTreatment,
} from '../types/types';
import { PayslipUser, ValidatePayslipPayload } from '../types/types';
import { MS_PAYSLIP_ENDPOINT } from '../variables/GlobalVariables';
import request from './AxiosClient';

function getPayslipExtractorConfig(
  companyId: string
): Promise<PayslipExtractLocation> {
  return request
    .get(
      `${MS_PAYSLIP_ENDPOINT}/v2/payslip/company/${companyId}/extractor/config`
    )
    .then((res) => res?.data);
}

function updatePayslipExtractorConfig(
  companyId: string,
  data: PayslipExtractLocationPayload
): Promise<PayslipExtractLocation> {
  return request
    .post(
      `${MS_PAYSLIP_ENDPOINT}/v2/payslip/company/${companyId}/extractor/config`,
      data
    )
    .then((res) => res?.data);
}

function listCompanyPayslipExtractLocationTemplate(
  companyId: string
): Promise<CompanyPayslipExtractLocationTemplate[]> {
  return request
    .get(
      `${MS_PAYSLIP_ENDPOINT}/v2/payslip/company/${companyId}/extractor/config/template`
    )
    .then((res) => res?.data);
}

function deleteCompanyPayslipExtractLocationTemplate(
  companyId: string,
  templateId: string
): Promise<void> {
  return request
    .delete(
      `${MS_PAYSLIP_ENDPOINT}/v2/payslip/company/${companyId}/extractor/config/template/${templateId}`
    )
    .then((res) => res?.data);
}

function createCompanyPayslipExtractLocationTemplate(
  companyId: string,
  data: CompanyPayslipExtractLocationTemplatePayload
): Promise<CompanyPayslipExtractLocationTemplate> {
  return request
    .post(
      `${MS_PAYSLIP_ENDPOINT}/v2/payslip/company/${companyId}/extractor/config/template`,
      data
    )
    .then((res) => res?.data);
}

function updateCompanyPayslipExtractLocationTemplate(
  companyId: string,
  data: CompanyPayslipExtractLocationTemplatePayload
): Promise<CompanyPayslipExtractLocationTemplate> {
  return request
    .patch(
      `${MS_PAYSLIP_ENDPOINT}/v2/payslip/company/${companyId}/extractor/config/template`,
      data
    )
    .then((res) => res?.data);
}

function getUserPayslip(
  userId: string | undefined,
  year: number,
  month: number,
  companyId: string
): string {
  return `${MS_PAYSLIP_ENDPOINT}/v2/payslip/user/${userId}/attachment/${year}/${month}?companyId=${companyId}`;
}

function getPayslipsMonthInfo(
  companyId: string,
  month: number,
  year: number
): Promise<PayslipMonthResponse> {
  return request
    .get(
      `${MS_PAYSLIP_ENDPOINT}/v2/payslip/month/${companyId}/${month}/${year}`
    )
    .then((res) => res?.data);
}

function getUserPayslips(
  userId: string | undefined,
  tmp: boolean
): Promise<PayslipUser[]> {
  return request
    .get(`${MS_PAYSLIP_ENDPOINT}/v2/payslip/user/${userId}/list?tmp=${tmp}`)
    .then((res) => {
      return res?.data.sort(
        (a: PayslipUser, b: PayslipUser) =>
          dayjs(`15/${b.month}/${b.year}`, 'D/M/YYYY').valueOf() -
          dayjs(`15/${a.month}/${a.year}`, 'D/M/YYYY').valueOf()
      );
    });
}

function getUserPayslipMonth(
  userId: string,
  year: number,
  month: number,
  companyId: string | undefined,
  accessToken?: string,
  refreshToken?: string
): string {
  let path = `${MS_PAYSLIP_ENDPOINT}/v2/payslip/month/${userId}/attachment/${year}/${month}?companyId=${companyId}`;
  if (accessToken && refreshToken) {
    path += '&accessToken=' + accessToken + '&refreshToken=' + refreshToken;
  }
  return path;
}

function uploadPayslipMonth(
  companyId: string,
  autoLocation: boolean,
  force: boolean,
  file: FileWithPath
) {
  return request
    .post(
      `${MS_PAYSLIP_ENDPOINT}/v2/payslip/month/upload/${companyId}/${autoLocation}/${force}`,
      { file, fileName: file.name },
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
    .then((res) => res?.data);
}

function reassignPayslip(
  oldUserId: string,
  userId: string,
  year: number,
  month: number
) {
  return request
    .patch(
      `${MS_PAYSLIP_ENDPOINT}/v2/payslip/month/${oldUserId}/${userId}/updatePayslip/${year}/${month}`
    )
    .then((res) => res?.data);
}

function removeUserPayslip(
  companyId: string,
  tmpUserId: string,
  month: number,
  year: number
) {
  return request
    .delete(
      `${MS_PAYSLIP_ENDPOINT}/v2/payslip/month/${companyId}/${tmpUserId}/${month}/${year}`
    )
    .then((res) => res?.data);
}

function getListPayslipSourceFile(companyId: string, payslipMonthId: string) {
  return request
    .get(
      `${MS_PAYSLIP_ENDPOINT}/v2/payslip/source/list/${companyId}/${payslipMonthId}`
    )
    .then((res) => res?.data);
}

function downloadPayslipSourceFile(
  payslipMonthId: string,
  sourceKey: number,
  companyId: string
): string {
  return `${MS_PAYSLIP_ENDPOINT}/v2/payslip/source/${companyId}/${payslipMonthId}/${sourceKey}`;
}

function getPayslipsTreatmentForCompanyId(
  companyId: string
): Promise<PayslipsMonthTreatment[]> {
  return request
    .get(`${MS_PAYSLIP_ENDPOINT}/v2/payslip/month/treatments/${companyId}`)
    .then((res) => res?.data);
}

function validateAndNotifyPayslips(
  companyId: string,
  month: number,
  year: number,
  payload: ValidatePayslipPayload,
  collaborator_access_token?: string,
  collaborator_refresh_token?: string
): Promise<any> {
  if (collaborator_access_token && collaborator_refresh_token) {
    return request
      .patch(
        `${MS_PAYSLIP_ENDPOINT}/v2/payslip/month/validate/${companyId}/${month}/${year}?accessToken=${collaborator_access_token}&refreshToken=${collaborator_refresh_token}`,
        payload
      )
      .then((res) => res?.data);
  } else {
    return request
      .patch(
        `${MS_PAYSLIP_ENDPOINT}/v2/payslip/month/validate/${companyId}/${month}/${year}`,
        payload
      )
      .then((res) => res?.data);
  }
}

const PayslipService = {
  getPayslipExtractorConfig,
  updatePayslipExtractorConfig,
  listCompanyPayslipExtractLocationTemplate,
  deleteCompanyPayslipExtractLocationTemplate,
  createCompanyPayslipExtractLocationTemplate,
  updateCompanyPayslipExtractLocationTemplate,
  getUserPayslip,
  getUserPayslips,
  getPayslipsMonthInfo,
  getUserPayslipMonth,
  validateAndNotifyPayslips,
  uploadPayslipMonth,
  reassignPayslip,
  removeUserPayslip,
  getListPayslipSourceFile,
  downloadPayslipSourceFile,
  getPayslipsTreatmentForCompanyId,
};

export default PayslipService;
