import { Text } from '@mantine/core';

import { useI18n } from '../../../../contexts/I18nProvider';
import type { CompanyResponse } from '../../../../types/api/response/company';

type Props = {
  company: CompanyResponse;
};

export default function CompanyCountryName({ company }: Props) {
  const { t } = useI18n();

  return (
    <Text>{t(`countriesEnum.${company?.countryCode?.toLowerCase()}`)}</Text>
  );
}
