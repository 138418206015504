import { Skeleton } from '@mantine/core';
import React from 'react';

export default function InputSkeleton() {
  return (
    <>
      <Skeleton height={20} mb={5} width="20%" radius="sm" />
      <Skeleton height={50} width="100%" radius="sm" />
    </>
  );
}
