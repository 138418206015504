import {
  ActionIcon,
  Badge,
  Button,
  Center,
  Fieldset,
  Flex,
  Grid,
  Group,
  MultiSelect,
  Paper,
  Select,
  Space,
  Switch,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconCheck,
  IconCirclePlus,
  IconTrash,
  IconX,
} from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { useMemo } from 'react';

import CustomTooltip from '../../../../components/CustomTooltip';
import LabelText from '../../../../components/LabelText/LabelText';
import { useI18n } from '../../../../contexts/I18nProvider';
import ShuttleSheetService from '../../../../services/ShuttleSheetService';
import type { HfwSecurityRoleResponse } from '../../../../types/api/response/role';
import {
  FieldType,
  ShuttleSheetType,
  ShuttleSheetTypeField,
} from '../../../../types/types';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
import styles from './ShuttleSheetTypeForm.module.sass';

type Props = {
  shuttleSheetType: ShuttleSheetType;
  roles: HfwSecurityRoleResponse[] | undefined;
  isEditable: boolean;
  companyId: string;
  refetch: () => void;
};

const types: string[] = [
  'STRING',
  'NUMBER',
  'BOOLEAN',
  'DATE',
  'DATETIME',
  'FILE',
  'USER',
  'SELECT',
  'MULTIPLESELECT',
];

export default function ShuttleSheetTypeForm({
  shuttleSheetType,
  roles,
  isEditable,
  companyId,
  refetch,
}: Props) {
  const { t } = useI18n();

  const _roles = useMemo(() => {
    if (roles) {
      return roles.map((role) => ({
        value: role.id,
        label: t(`role.${role.name}`),
      }));
    } else {
      return [];
    }
  }, [roles]);

  const _types = useMemo(() => {
    return types.map((type) => ({
      value: type,
      label: t(`shuttleSheet.type.${type}`),
    }));
  }, []);

  const shuttleSheetTypeForm = useForm<ShuttleSheetType>({
    initialValues: {
      ...shuttleSheetType,
      usableBy: shuttleSheetType.usableBy
        ? shuttleSheetType.id === ''
          ? _roles.map((role) => role.value)
          : shuttleSheetType.usableBy
        : [],
    },
  });

  function handleAddFieldClick(): void {
    const newField: ShuttleSheetTypeField = {
      required: false,
      name: '',
      type: FieldType.STRING,
      availableChoices: [],
      editableBy: _roles.map((role) => role.value),
    };
    shuttleSheetTypeForm.insertListItem('fields', newField);
  }

  function handleDeleteFieldClick(index: number): void {
    shuttleSheetTypeForm.removeListItem('fields', index);
  }

  function handleAddAvailableChoiceClick(index: number): void {
    shuttleSheetTypeForm.validate();
    if (shuttleSheetTypeForm.isValid('availableChoiceName')) {
      shuttleSheetTypeForm.insertListItem(
        `fields.${index}.availableChoices`,
        shuttleSheetTypeForm.values.availableChoiceName
      );
      setTimeout(
        () => shuttleSheetTypeForm.setFieldValue('availableChoiceName', ''),
        500
      );
    }
  }

  function handleDeleteAvailableChoiceClick(
    index: number,
    choiceIndex: number
  ) {
    shuttleSheetTypeForm.removeListItem(
      `fields.${index}.availableChoices`,
      choiceIndex
    );
  }

  function renderAvailableChoicesWrapper(index: number) {
    return (
      <Group mt={'sm'} align={'flex-end'}>
        <TextInput
          label={<LabelText text={'Choix'} />}
          placeholder={'Saisir un choix'}
          {...shuttleSheetTypeForm.getInputProps('availableChoiceName')}
        />
        <CustomTooltip label={`${t('w.add')}`}>
          <ActionIcon
            variant={'subtle'}
            size={'xl'}
            onClick={() => handleAddAvailableChoiceClick(index)}
            disabled={!isEditable}
          >
            <IconCirclePlus />
          </ActionIcon>
        </CustomTooltip>
        {shuttleSheetTypeForm.values.fields[index].availableChoices.length >
          0 && <Text>Choix possibles</Text>}
        {shuttleSheetTypeForm.values.fields[index].availableChoices.map(
          (choice, choiceIndex) => (
            <Badge
              styles={{
                label: {
                  textTransform: 'none',
                },
              }}
              leftSection={
                <ActionIcon
                  size={'xs'}
                  variant={'subtle'}
                  color={'white'}
                  onClick={() =>
                    handleDeleteAvailableChoiceClick(index, choiceIndex)
                  }
                >
                  <IconTrash />
                </ActionIcon>
              }
              key={`${choice}-${choiceIndex}`}
            >
              {choice}
            </Badge>
          )
        )}
      </Group>
    );
  }

  const {
    mutate: createShuttleSheetType,
    isLoading: isCreateShuttleSheetTypeLoading,
  } = useMutation({
    mutationFn: (variables: ShuttleSheetType) =>
      ShuttleSheetService.createShuttleSheetType(companyId, variables),
    onSuccess: (data) => {
      const { id, name } = data;
      showNotification({
        id: `create-shuttle-sheet-type-${id}-successful`,
        title: t('w.success'),
        message: `${t('w.shuttleSheetType')} ${name} ${t('w.created')}`,
        color: 'green',
        icon: <IconCheck />,
      });
      modals.closeAll();
      refetch();
    },
    onError: (error) =>
      showNotification({
        id: `create-shuttle-sheet-type-error`,
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const {
    mutate: updateShuttleSheetType,
    isLoading: isUpdateShuttleSheetTypeLoading,
  } = useMutation({
    mutationFn: (variables: ShuttleSheetType) =>
      ShuttleSheetService.updateShuttleSheetType(
        companyId,
        variables?.id ? variables.id : '',
        variables
      ),
    onSuccess: (data) => {
      const { id, name } = data;
      showNotification({
        id: `update-shuttle-sheet-type-${id}-successful`,
        title: t('w.success'),
        message: `${t('w.shuttleSheetType')} ${name} ${t('w.updated')}`,
        color: 'green',
        icon: <IconCheck />,
      });
      modals.closeAll();
      refetch();
    },
    onError: (error) =>
      showNotification({
        id: `update-shuttle-sheet-type-error`,
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function handleFormSubmit(values: ShuttleSheetType): void {
    if (values.id === '') {
      createShuttleSheetType({
        companyId: companyId,
        name: values.name,
        deletable: true,
        transmittedToAccountant: values.transmittedToAccountant,
        usableBy: values.usableBy,
        fields: values.fields,
      });
    } else {
      updateShuttleSheetType({
        companyId: values.companyId,
        id: values.id,
        name: values.name,
        deletable: true,
        transmittedToAccountant: values.transmittedToAccountant,
        usableBy: values.usableBy,
        fields: values.fields,
      });
    }
  }

  return (
    <form onSubmit={shuttleSheetTypeForm.onSubmit(handleFormSubmit)}>
      <Flex justify={'flex-end'}>
        <Switch
          className={styles.root}
          labelPosition="left"
          label={<LabelText text={t('w.forwardedToPayrollOfficer')} />}
          checked={shuttleSheetTypeForm.values.transmittedToAccountant}
          {...shuttleSheetTypeForm.getInputProps('transmittedToAccountant')}
          disabled={!isEditable}
        />
      </Flex>
      <Space h={'md'} />
      <Fieldset legend={'Configuration nom et rôles'}>
        <TextInput
          required
          label={<LabelText text={t('w.name')} />}
          placeholder="Saisir un nom"
          {...shuttleSheetTypeForm.getInputProps('name')}
          disabled={!isEditable}
          data-autofocus
        />
        <Space h={'xs'} />
        <MultiSelect
          data={_roles}
          label={<LabelText text={t('w.usableBy')} />}
          {...shuttleSheetTypeForm.getInputProps('usableBy')}
          placeholder="Sélectionner un ou plusieurs rôles"
          searchable
          disabled={!isEditable}
        />
      </Fieldset>
      <Space h={'md'} />
      <Flex justify={'flex-end'}>
        <CustomTooltip label={`${t('w.add')} ${t('w.field').toLowerCase()}`}>
          <ActionIcon
            variant={'subtle'}
            size={'xl'}
            onClick={handleAddFieldClick}
            disabled={!isEditable}
          >
            <IconCirclePlus />
          </ActionIcon>
        </CustomTooltip>
      </Flex>
      <Fieldset legend={'Configuration des champs'}>
        {shuttleSheetTypeForm.values.fields.map((field, index) => (
          <Paper
            key={`Paper-${index}`}
            shadow="md"
            withBorder
            mb={'md'}
            p="sm"
            bg={'var(--mantine-color-hifivework-0)'}
          >
            <Grid align={'center'} justify="space-between">
              <Grid.Col span={{ base: 12, md: 4 }}>
                <TextInput
                  key={`textInput-${index}`}
                  label={<LabelText text={t('w.name')} />}
                  {...shuttleSheetTypeForm.getInputProps(
                    `fields.${index}.name`
                  )}
                  placeholder="Saisir un nom"
                  disabled={!isEditable}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 4 }}>
                <Select
                  data={_types}
                  label={<LabelText text={'Type'} />}
                  {...shuttleSheetTypeForm.getInputProps(
                    `fields.${index}.type`
                  )}
                  placeholder="Sélectionner un type"
                  disabled={!isEditable}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 2 }}>
                <Center>
                  <Switch
                    className={styles.root}
                    size={'xs'}
                    label={<LabelText text={t('w.required')} />}
                    checked={field.required}
                    {...shuttleSheetTypeForm.getInputProps(
                      `fields.${index}.required`
                    )}
                    disabled={!isEditable}
                  />
                </Center>
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 2 }}>
                <Center>
                  <CustomTooltip label={t('w.delete')}>
                    <ActionIcon
                      size="xl"
                      color="red"
                      variant={'subtle'}
                      onClick={() => handleDeleteFieldClick(index)}
                      disabled={!isEditable}
                    >
                      <IconTrash />
                    </ActionIcon>
                  </CustomTooltip>
                </Center>
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={12}>
                <MultiSelect
                  data={_roles}
                  label={<LabelText text={'Modifiable par'} />}
                  {...shuttleSheetTypeForm.getInputProps(
                    `fields.${index}.editableBy`
                  )}
                  placeholder="Sélectionner un ou plusieurs rôles"
                  searchable
                  disabled={!isEditable}
                />
              </Grid.Col>
            </Grid>
            {field.type === 'MULTIPLESELECT' &&
              renderAvailableChoicesWrapper(index)}
            {field.type === 'SELECT' && renderAvailableChoicesWrapper(index)}
          </Paper>
        ))}
      </Fieldset>
      {isEditable && (
        <Group mt={'xl'} justify={'flex-end'}>
          <Button variant={'subtle'} onClick={modals.closeAll}>
            {t('w.cancel')}
          </Button>
          <Button
            type={'submit'}
            loading={
              isCreateShuttleSheetTypeLoading || isUpdateShuttleSheetTypeLoading
            }
          >
            {shuttleSheetType.id === '' ? t('w.add') : t('w.modify')}
          </Button>
        </Group>
      )}
    </form>
  );
}
