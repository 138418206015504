import {
  ActionIcon,
  Container,
  Group,
  List,
  Paper,
  Text,
  Title,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  IconCalendarDue,
  IconInfoCircle,
  IconPencil,
  IconPencilCancel,
} from '@tabler/icons-react';
import React, { ReactNode } from 'react';

import CustomModal from '../../../components/CustomModal';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import WorkUnitLabelForm from '../../../features/company/work-units/components/WorkUnitLabelForm';
import useCompany from '../../../hooks/useCompany';
import { toDateOfDayString } from '../../../utils/format';
import s from './ScreenWrapper.module.sass';

type Props = {
  title: string | undefined;
  date?: boolean;
  fixedPriceManager?: boolean;
  children: ReactNode;
  actionButtons?: ReactNode[];
  paper?: boolean;
  isSetTitle?: boolean;
};

export default function ScreenWrapper({
  title,
  date = false,
  fixedPriceManager = false,
  actionButtons,
  children,
  paper,
  isSetTitle,
}: Props) {
  const { t } = useI18n();
  const { user } = useAuth();
  const { refetch: refetchCompanyData } = useCompany(user);
  const [editTitle, { toggle }] = useDisclosure(false);
  const [openedModal, { open, close }] = useDisclosure(false);

  return (
    <Container fluid mt={'md'}>
      <Group justify={'space-between'} mb={'md'}>
        {isSetTitle ? (
          <Group>
            {editTitle ? (
              <WorkUnitLabelForm
                label={title}
                toggle={toggle}
                refetchCompanyData={refetchCompanyData}
              />
            ) : (
              <Title size={'36px'} order={1}>
                {title}
              </Title>
            )}
            <Group>
              <ActionIcon
                variant={'subtle'}
                onClick={toggle}
                color={editTitle ? 'red' : undefined}
              >
                {editTitle ? <IconPencilCancel /> : <IconPencil />}
              </ActionIcon>
              <ActionIcon variant={'subtle'} onClick={open}>
                <IconInfoCircle />
              </ActionIcon>
            </Group>
          </Group>
        ) : (
          <Title size={'36px'} order={1}>
            {title}
          </Title>
        )}

        {actionButtons && (
          <Group gap="xs" align="flex-end">
            {actionButtons}
          </Group>
        )}
      </Group>
      {fixedPriceManager && (
        <Text mb={'md'} c={'dimmed'}>
          {t('w.fixedPriceManager')}
        </Text>
      )}
      {date && (
        <Group gap="xs" mb={'md'}>
          <IconCalendarDue className={s.icon} />
          <Text fz={'xl'} fw={600} c={'hifivework.3'}>
            {toDateOfDayString()}
          </Text>
        </Group>
      )}
      {paper ? (
        <Paper p="md" radius={'md'} withBorder mt={'sm'}>
          {children}
        </Paper>
      ) : (
        children
      )}
      <CustomModal
        opened={openedModal}
        onClose={close}
        title={'Information'}
        size={'xl'}
      >
        <Text fz={'sm'} fw={'bold'} c={'dimmed'}>
          {t('division.message.edit')}
        </Text>
        <List withPadding my={'xs'}>
          <List.Item fz={'sm'} fw={'bold'} c={'dimmed'}>
            {t('w.shop')}
          </List.Item>
          <List.Item fz={'sm'} fw={'bold'} c={'dimmed'}>
            {t('w.department')}
          </List.Item>
          <List.Item fz={'sm'} fw={'bold'} c={'dimmed'}>
            {t('w.factory')}
          </List.Item>
          <List.Item fz={'sm'} fw={'bold'} c={'dimmed'}>
            {t('w.company')} etc...
          </List.Item>
        </List>
        <Text fz={'sm'} fw={'bold'} c={'dimmed'}>
          {t('division.message.editInfoNoWorkkUnit')}
        </Text>
      </CustomModal>
    </Container>
  );
}
