import { NumberInput } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { useDebouncedCallback } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs, { type Dayjs } from 'dayjs';
import React from 'react';

import { useI18n } from '../../../../contexts/I18nProvider';
import CounterUserService from '../../../../services/CounterUserService';
import type { UpdateCounterUserPayload } from '../../../../types/api/payload/counterUser';
import type { CounterType } from '../../../../types/api/response/counterType';
import s from './EditUserCounterForm.module.sass';

type Props = {
  userId: string;
  counterType: CounterType;
  value?: number;
  date?: string;
  disabled: boolean;
};

type FormValues = {
  value?: number;
  date?: Dayjs;
};

export default function EditUserCounterForm({
  userId,
  counterType,
  value,
  date,
  disabled,
}: Props) {
  const { t } = useI18n();
  const queryClient = useQueryClient();
  const form = useForm<FormValues>({
    initialValues: {
      value,
      date: date ? dayjs(date, 'YYYY-MM-DD') : undefined,
    },
    onValuesChange: (values) => handleAutoSave(values),
  });

  const { mutate } = useMutation({
    mutationFn: (variables: UpdateCounterUserPayload) =>
      CounterUserService.update(userId, variables),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['CounterUserService.getByUser', userId],
      });
    },
    onError: () => {
      showNotification({
        id: `update-user-counter-error-${userId}-${counterType.id}`,
        message: t('w.error'),
        color: 'red',
        icon: <IconCheck />,
      });
    },
  });

  const handleAutoSave = useDebouncedCallback(async (values: FormValues) => {
    const _date = values.date ? dayjs(values.date).format('DD-MM-YYYY') : '';
    mutate({
      counterTypeId: counterType.id,
      value: values.value || 0,
      date: _date,
      isIncrement: false,
    });
  }, 1000);

  if (disabled) {
    return null;
  }

  return (
    <div className={s.root}>
      <NumberInput
        {...form.getInputProps('value')}
        size="xs"
        classNames={{ input: s.input }}
      />
      <DatePickerInput
        {...form.getInputProps('date')}
        classNames={{ root: s.root, input: s.input }}
        size="sm"
        label={t('w.at').toLowerCase()}
        valueFormat="DD/MM/YYYY"
      />
    </div>
  );
}
