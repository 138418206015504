import { ActionIcon, Button, Group, Loader, Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, type StripeElementLocale } from '@stripe/stripe-js';
import {
  IconBuildingBank,
  IconCreditCard,
  IconSettings,
} from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import React, { useMemo } from 'react';

import CustomTooltip from '../../../../components/CustomTooltip';
import { useI18n } from '../../../../contexts/I18nProvider';
import useStripeConfig from '../../../../hooks/useStripeConfig';
import PaymentAccountService from '../../../../services/PaymentAccountService';
import type {
  PaymentAccount,
  PaymentCard,
  PaymentSepaDebit,
} from '../../../../types/types';
import PaymentMode from '../DetailedView/PaymentMode';
import Card from './components/Card';
import CheckoutForm from './components/CheckoutForm';
import Sepa from './components/Sepa';

type Props = {
  paymentAccount: PaymentAccount;
};

export default function PaymentModeOverview({ paymentAccount }: Props) {
  const { t, lang } = useI18n();
  const { data: paymentMethods } = useQuery({
    queryKey: ['PaymentAccountService.getPaymentMethods', paymentAccount.id],
    queryFn: () => PaymentAccountService.getPaymentMethods(paymentAccount.id),
  });
  const { publishableKey } = useStripeConfig(paymentAccount.id);
  const defaultCard: PaymentCard | undefined =
    paymentAccount.defaultPayment?.card;
  const defaultSepaDebit: PaymentSepaDebit | undefined =
    paymentAccount.defaultPayment?.sepaDebit;

  const stripePromise = useMemo(() => {
    if (publishableKey) {
      return loadStripe(publishableKey);
    }

    return null;
  }, [publishableKey]);

  function addCard() {
    modals.open({
      modalId: 'CheckoutForm',
      children: (
        <Elements
          options={{ locale: lang as StripeElementLocale }}
          stripe={stripePromise}
        >
          <CheckoutForm type={'card'} paymentAccountId={paymentAccount.id} />
        </Elements>
      ),
      m: 'xl',
      withCloseButton: false,
    });
  }

  function addIban() {
    modals.open({
      modalId: 'CheckoutForm',
      children: (
        <Elements
          options={{ locale: lang as StripeElementLocale }}
          stripe={stripePromise}
        >
          <CheckoutForm type={'iban'} paymentAccountId={paymentAccount.id} />
        </Elements>
      ),
      m: 'xl',
      withCloseButton: false,
    });
  }

  function openPaymentMode() {
    if (!paymentMethods) return;

    modals.open({
      modalId: 'PaymentMode',
      title: (
        <Group gap={'md'}>
          <Button
            variant="light"
            leftSection={<IconCreditCard />}
            onClick={addCard}
          >
            {t('w.addCard')}
          </Button>
          <Button
            variant="light"
            leftSection={<IconBuildingBank />}
            onClick={addIban}
          >
            {t('w.addIban')}
          </Button>
        </Group>
      ),
      children: <PaymentMode paymentAccountId={paymentAccount.id} />,
      size: 'xl',
      m: 'xl',
      withCloseButton: false,
    });
  }

  if (!Boolean(stripePromise)) return <Loader type={'bars'} m={'auto'} />;

  return (
    <>
      <Group gap={'md'}>
        <CustomTooltip label={t('w.configurePaymentMethods')}>
          <ActionIcon onClick={openPaymentMode} variant={'filled'} color="blue">
            <IconSettings />
          </ActionIcon>
        </CustomTooltip>
      </Group>

      {paymentAccount.defaultPayment && (defaultCard || defaultSepaDebit) ? (
        <>
          {defaultCard && (
            <Card
              card={defaultCard}
              name={paymentAccount.defaultPayment.name}
            />
          )}
          {defaultSepaDebit && (
            <Sepa
              sepa={defaultSepaDebit}
              name={paymentAccount.defaultPayment.name}
            />
          )}
        </>
      ) : (
        <Text>{t('w.noPaymentMethod')}</Text>
      )}
    </>
  );
}
