import { FileWithPath } from '@mantine/dropzone';

import {
  DocumentPathType,
  DocumentPropertySpace,
  DocumentView,
} from '../types/types';
import { MS_DOCUMENTS_ENDPOINT } from '../variables/GlobalVariables';
import request from './AxiosClient';

function getFoldersList(
  pathFolder: string,
  space: DocumentPropertySpace,
  userId: string
): Promise<DocumentView[]> {
  return request
    .get(
      `${MS_DOCUMENTS_ENDPOINT}/v2/document/user/${space}/${userId}/${encodeURIComponent(
        pathFolder
      )}`
    )
    .then((res) => res?.data);
}

function getCompanyDocumentsList(
  companyId: string,
  pathFolder: string,
  space: DocumentPropertySpace,
  accountantCompanyId: string | null
): Promise<DocumentView[]> {
  return request
    .get(
      accountantCompanyId
        ? `${MS_DOCUMENTS_ENDPOINT}/v2/document/company/${space}/${companyId}/${encodeURIComponent(
            pathFolder
          )}?accountantCompanyId=${accountantCompanyId}`
        : `${MS_DOCUMENTS_ENDPOINT}/v2/document/company/${space}/${companyId}/${encodeURIComponent(
            pathFolder
          )}`
    )
    .then((res) => res?.data);
}

export type UpdateOrCreateDocumentPayload = {
  title: string;
  comment: string;
  path: string;
  documentId: string | null;
  type: DocumentPathType | undefined;
  space: DocumentPropertySpace;
  userId: string;
  fileName: string | null;
  file: FileWithPath | null;
};

function updateOrCreateDocument(
  companyId: string,
  accountantCompanyId: string | null,
  data: UpdateOrCreateDocumentPayload
): Promise<DocumentView> {
  return request
    .patch(
      accountantCompanyId
        ? `${MS_DOCUMENTS_ENDPOINT}/v2/document/company/${companyId}?accountantCompanyId=${accountantCompanyId}`
        : `${MS_DOCUMENTS_ENDPOINT}/v2/document/company/${companyId}`,
      data,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    )
    .then((res) => res?.data);
}

function deleteDocument(companyId: string, documentId: string) {
  return request
    .delete(
      `${MS_DOCUMENTS_ENDPOINT}/v2/document/company/${companyId}/${documentId}`
    )
    .then((res) => res?.data);
}

function downloadDocumentFile(companyId: string, documentId: string) {
  return `${MS_DOCUMENTS_ENDPOINT}/v2/document/company/${companyId}/${documentId}`;
}

const DocumentsService = {
  getFoldersList,
  getCompanyDocumentsList,
  updateOrCreateDocument,
  deleteDocument,
  downloadDocumentFile,
};

export default DocumentsService;
