import {
  Button,
  Flex,
  Grid,
  LoadingOverlay,
  PasswordInput,
  Stack,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconAlertTriangle, IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import React from 'react';

import LabelText from '../../../components/LabelText/LabelText';
import PhoneField from '../../../components/PhoneField/PhoneField';
import { useI18n } from '../../../contexts/I18nProvider';
import OauthServiceMS from '../../../services/OauthServiceMS';
import { validatePassword } from '../../../utils/validators';
import InputEmail from '../../login/components/InputEmail';

type Props = {
  user: {
    lastname: string;
    firstname: string;
    username: string;
  };
  tokenPath: string | null;
  handleNextStep: () => void;
  userPhone: string | undefined;
  onUserPhoneChange: (value: string | undefined) => void;
  setNewUser: (response: any) => void;
};

export default function UserForm({
  user,
  tokenPath,
  handleNextStep,
  userPhone,
  onUserPhoneChange,
  setNewUser,
}: Props) {
  const { t } = useI18n();
  const form = useForm({
    initialValues: {
      lastname: user?.lastname,
      firstname: user?.firstname,
      email: user?.username,
      firstPassword: '',
      secondPassword: '',
    },
    validate: {
      email: (val) => (/^\S+@\S+$/.test(val) ? null : t('invalid.email')),
      firstPassword: (val) =>
        validatePassword(val)
          ? null
          : t('w.yourPasswordMustContainAtLeast8characters'),
    },
  });
  const { mutate: resetPassword, isLoading } = useMutation({
    mutationFn: (variables: { email: string; firstPassword: string }) =>
      OauthServiceMS.resetPassword(
        variables.firstPassword,
        variables.email,
        tokenPath as string
      ),
    onSuccess: (data) => {
      setNewUser({
        access_token: data.access_token,
        refresh_token: data.refresh_token,
      });
      showNotification({
        id: 'userAccountConfirmed',
        title: t('w.success'),
        message: t('w.yourUserAccountHasBeenConfirmed'),
        color: 'green',
        icon: <IconCheck />,
      });
      handleNextStep();
    },
    onError: (error: any) => {
      if (
        error?.status === 400 &&
        error?.response?.data?.error_key === 'password_invalid_format'
      ) {
        showNotification({
          id: 'password_invalid_format',
          title: t('w.error'),
          message: t(
            'w.passwordMustContainAtLeast8CharactersIncludingUupperCaseLowerCaseNumbersSpecialCharacters'
          ),
          color: 'red',
          icon: <IconX />,
        });
      }
      if (error?.status === 401) {
        showNotification({
          id: 'accessTokenIsNoLongerValid',
          title: t('w.error'),
          message: t('w.accessTokenIsNoLongerValid'),
          color: 'red',
          icon: <IconX />,
        });
      }
    },
  });

  function confirmUserAccount(values: {
    firstname: string | undefined;
    secondPassword: string;
    firstPassword: string;
    email: string;
    lastname: string | undefined;
  }) {
    if (values.firstPassword !== values.secondPassword) {
      showNotification({
        id: 'differentPasswords',
        title: t('w.warning'),
        message: 'Les mots de passe doivent être identiques.',
        color: 'orange',
        icon: <IconAlertTriangle />,
      });
    } else {
      resetPassword({
        email: values.email,
        firstPassword: values.firstPassword,
      });
    }
  }

  return (
    <>
      <LoadingOverlay visible={isLoading} />
      <form onSubmit={form.onSubmit((values) => confirmUserAccount(values))}>
        <Stack>
          <Grid>
            <Grid.Col span={{ sm: 12, md: 6 }}>
              <TextInput
                label={<LabelText text={t('w.name')} />}
                placeholder={t('w.yourLastname')}
                required
                value={form.values.lastname}
                onChange={(event) =>
                  form.setFieldValue('lastname', event.currentTarget.value)
                }
              />
            </Grid.Col>
            <Grid.Col span={{ sm: 12, md: 6 }}>
              <TextInput
                label={<LabelText text={t('w.firstname')} />}
                placeholder={t('w.yourFirstname')}
                required
                value={form.values.firstname}
                onChange={(event) =>
                  form.setFieldValue('firstname', event.currentTarget.value)
                }
              />
            </Grid.Col>
            <Grid.Col span={{ sm: 12, md: 6 }}>
              <InputEmail
                value={form.values.email}
                onChange={(event) =>
                  form.setFieldValue('email', event.currentTarget.value)
                }
                error={form.errors.email}
              />
            </Grid.Col>
            <Grid.Col span={{ sm: 12, md: 6 }}>
              <PhoneField onChange={onUserPhoneChange} value={userPhone} />
            </Grid.Col>
            <Grid.Col span={{ sm: 12, md: 6 }}>
              <PasswordInput
                id={'firstPassword'}
                required
                label={<LabelText text={t('w.newPassword')} />}
                placeholder={t('w.yourNewPassword')}
                value={form.values.firstPassword}
                onChange={(event) =>
                  form.setFieldValue('firstPassword', event.currentTarget.value)
                }
                error={form.errors.firstPassword}
              />
            </Grid.Col>
            <Grid.Col span={{ sm: 12, md: 6 }}>
              <PasswordInput
                id={'secondPassword'}
                required
                label={<LabelText text={t('w.confirmNewPassword')} />}
                placeholder={t('w.yourNewPassword')}
                value={form.values.secondPassword}
                onChange={(event) =>
                  form.setFieldValue(
                    'secondPassword',
                    event.currentTarget.value
                  )
                }
              />
            </Grid.Col>
          </Grid>
          <Flex justify={'flex-end'}>
            <Button type={'submit'}>{t('w.next')}</Button>
          </Flex>
        </Stack>
      </form>
    </>
  );
}
