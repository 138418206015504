import {
  Button,
  Flex,
  PasswordInput,
  SimpleGrid,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconRefresh, IconToggleRight, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import React from 'react';

import { ModuleToEditComponentProps } from '../../../../../components/ModuleToEditModal';
import { useI18n } from '../../../../../contexts/I18nProvider';
import HfwModulesService from '../../../../../services/HfwModulesService';
import type { HfwAzureSyncParams as HfwAzureSyncParamsType } from '../../../../../types/api/response/module';
import handleErrorMessage from '../../../../../utils/handleErrorMessage';
import AzureSyncResult from './AzureSyncResult';

export default function ByParams({
  module,
  updateModuleParams,
}: ModuleToEditComponentProps) {
  const { t } = useI18n();
  const { mutate: syncUserAzureModule, isLoading } = useMutation({
    mutationFn: (variables: { companyId: string }) =>
      HfwModulesService.syncUserAzureModule(variables.companyId, 'params'),
    onSuccess: (data) => {
      modals.open({
        title: t('w.synchronizationResult'),
        modalId: 'hfw-azure-sync-by-params',
        withCloseButton: true,
        size: 'xl',
        children: <AzureSyncResult result={data} />,
      });
    },
    onError: (error) => {
      showNotification({
        id: `error-syncUserAzureModule`,
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      });
    },
  });
  const moduleParams = module?.configuration?.params as HfwAzureSyncParamsType;

  const form = useForm<HfwAzureSyncParamsType>({
    initialValues: {
      login: moduleParams?.login || '',
      password: moduleParams?.password || '',
      tenant: moduleParams?.tenant || '',
      clientId: moduleParams?.clientId || '',
      clientSecret: moduleParams?.clientSecret || '',
      scope: moduleParams?.scope || 'https://graph.microsoft.com/.default',
    },
  });

  function submit(values: HfwAzureSyncParamsType, active: boolean) {
    if (module) {
      updateModuleParams({
        apiUrl: module.description.url,
        payload: {
          active,
          ...values,
        },
      });
      if (module?.configuration?.clientId) {
        syncUserAzureModule({
          companyId: module.configuration.clientId,
        });
      }
    }
  }

  function onSaveClick() {
    submit(form.values, Boolean(module?.configuration?.active));
  }

  return (
    <form onSubmit={form.onSubmit((values) => submit(values, true))}>
      <SimpleGrid cols={{ base: 1, md: 2 }}>
        <TextInput
          label={t('w.id')}
          placeholder="xxxxxxxx"
          disabled={isLoading}
          {...form.getInputProps('login')}
        />
        <PasswordInput
          label={t('w.password')}
          placeholder="xxxxxxxx"
          disabled={isLoading}
          {...form.getInputProps('password')}
        />
        <TextInput
          label="tenant"
          placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
          disabled={isLoading}
          {...form.getInputProps('tenant')}
        />
        <TextInput
          label="clientId"
          placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
          disabled={isLoading}
          {...form.getInputProps('clientId')}
        />
        <TextInput
          label="clientSecret"
          placeholder="xxxxxxxx"
          disabled={isLoading}
          {...form.getInputProps('clientSecret')}
        />
        <TextInput
          label="scope"
          placeholder="https://graph.microsoft.com/.default"
          disabled={isLoading}
          {...form.getInputProps('scope')}
        />
      </SimpleGrid>
      <Flex justify={'flex-end'} mt={'xl'}>
        <Button
          onClick={onSaveClick}
          variant={'outline'}
          leftSection={<IconRefresh />}
          loading={isLoading}
        >
          {t('w.synchronize')}
        </Button>
        <Button
          type={'submit'}
          leftSection={<IconToggleRight />}
          ml={'md'}
          loading={isLoading}
        >
          {t('w.dailySynchronization')}
        </Button>
      </Flex>
    </form>
  );
}
