import { useAuth } from '../../../contexts/AuthProvider';
import { useModule } from '../../../contexts/ModuleProvider';
import {
  type DailyWorkParams,
  ModuleName,
} from '../../../types/api/response/module';
import {
  BuiltInPermissions,
  ScopeEnum,
} from '../../../types/api/response/role';
import { hasPermission } from '../../../utils/authorization';

export default function useDisplayUserActivities(): boolean {
  const { getModule } = useModule();
  const { user } = useAuth();
  const isModuleDailyWorkActiveForCompany = getModule(
    ModuleName.DailyWork
  )?.active;
  const isModuleDailyWorkActiveForAll = (
    getModule(ModuleName.DailyWork)?.configuration?.params as DailyWorkParams
  )?.activeForAll;
  const isActiveForUser = Boolean(
    user.paidFeatures?.find(
      (item) => item.name === ModuleName.DailyWork && item.active
    )
  );
  const isUserAuthorized = hasPermission(
    [
      {
        permission: BuiltInPermissions.AuthorizedUpsertDailyWork,
        scope: ScopeEnum.ALL,
      },
      {
        permission: BuiltInPermissions.AuthorizedReadDailyWork,
        scope: ScopeEnum.ALL,
      },
    ],
    user
  );

  return Boolean(
    isModuleDailyWorkActiveForCompany &&
      isUserAuthorized &&
      (isActiveForUser || isModuleDailyWorkActiveForAll)
  );
}
