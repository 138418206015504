import { MonthPickerInput } from '@mantine/dates';
import type {
  DatePickerType,
  DatesRangeValue,
  DateValue,
} from '@mantine/dates/lib/types/DatePickerValue';

import { useI18n } from '../contexts/I18nProvider';
import LabelText from './LabelText/LabelText';

type Props = {
  value: DateValue | DatesRangeValue | Date[];
  onChange: (value: DateValue | DatesRangeValue | Date[]) => void;
  type?: DatePickerType;
};

export default function CustomMonthPickerInput({
  value,
  onChange,
  type = 'default',
}: Props) {
  const { t } = useI18n();
  return (
    <MonthPickerInput
      label={<LabelText text={t('w.monthConcerned')} />}
      value={value}
      onChange={onChange}
      type={type}
      w={300}
    />
  );
}
