import { Badge, type MantineSize, Text } from '@mantine/core';
import dayjs from 'dayjs';
import React from 'react';

import { useI18n } from '../../contexts/I18nProvider';
import type { LeaveStatusResponse } from '../../types/api/response/leave';
import { dateString } from '../../utils/format';
import { getTranslatedStatusState } from '../../utils/leaveType';
import CustomTooltip from '../CustomTooltip';

type Props = {
  status?: LeaveStatusResponse;
  size?: string | MantineSize;
  fullWidth?: boolean;
};

export default function LeaveStatusBadge({
  status,
  size = 'md',
  fullWidth = false,
}: Props) {
  const { t } = useI18n();
  return (
    <CustomTooltip
      label={
        status?.state === 'REJECTED' ? (
          <div>
            <Text size="xs">
              {t('w.validator')}: {status.comments?.[0]?.username}
            </Text>
            <Text size="xs">
              {t('w.refusedComment')}:{' '}
              {status.comments?.[0]?.comment === '' ? (
                <q>{t('w.noCommentsLeft')}</q>
              ) : (
                <q>{status.comments?.[0]?.comment}</q>
              )}
            </Text>
            <Text size="xs">
              Date:
              {dateString(status?.comments?.[0]?.date)}
            </Text>
          </div>
        ) : (
          <Text size="xs">{dayjs(status?.stateDate).format('DD/MM/YYYY')}</Text>
        )
      }
      disabled={!status?.stateDate}
    >
      <Badge
        size={size}
        fullWidth={fullWidth}
        styles={{
          root: {
            backgroundColor:
              status?.state === 'REJECTED'
                ? '#E70D4F1A'
                : status?.state === 'VALID'
                ? '#CEE9E6'
                : '#FCF0E0',
            color:
              status?.state === 'REJECTED'
                ? 'red'
                : status?.state === 'VALID'
                ? '#0A9182'
                : '#EDB464',
          },
          label: {
            textTransform: 'none',
          },
        }}
        variant="filled"
      >
        {getTranslatedStatusState(t, status?.state)}
      </Badge>
    </CustomTooltip>
  );
}
