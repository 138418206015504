import { Flex, Grid } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import React from 'react';

import ButtonSave from '../../../../../../components/ButtonSave';
import { useI18n } from '../../../../../../contexts/I18nProvider';
import EndOfMonthService from '../../../../../../services/EndOfMonthService';
import type { UpdateIdentifiantsCompanyRequest } from '../../../../../../types/api/payload/endMonth';
import type { IdentifiantCompany } from '../../../../../../types/api/response/user';
import UserIdentifiantsCompany from '../../../../../user-profile/components/UserIdentifiantsCompany';
import UserIdentifiantsCompanyButton from '../../../../../user-profile/components/UserIdentifiantsCompanyButton';

type Props = {
  id: string;
  companyId: string;
};

interface FormValues {
  identifiantsCompany: IdentifiantCompany[];
}

export default function IdentifiantsCompanyForm({ id, companyId }: Props) {
  const { t } = useI18n();
  const form = useForm<FormValues>({
    initialValues: {
      identifiantsCompany: [],
    },
  });

  function handleUpdateIdentifiantsCompany(
    values: ReturnType<(values: FormValues) => FormValues>
  ) {
    if (module) {
      addIdentifiantsCompany({
        payload: {
          userId: id,
          identifiantsCompany: values.identifiantsCompany[0],
        },
      });
    }
  }

  const { mutate: addIdentifiantsCompany } = useMutation({
    mutationFn: (variables: { payload: UpdateIdentifiantsCompanyRequest }) =>
      EndOfMonthService.addIdentifiantsCompany(companyId, variables.payload),
    onSuccess: () => {
      showNotification({
        id: `success-add-mail-openpaye-user`,
        message: t('w.success'),
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: () =>
      showNotification({
        id: `error-add-mail-openpaye-user`,
        title: t('w.error'),
        message: t('error.updateModule'),
        color: 'red',
        icon: <IconX />,
      }),
  });

  return (
    <form
      onSubmit={form.onSubmit((values) =>
        handleUpdateIdentifiantsCompany({
          identifiantsCompany: values.identifiantsCompany,
        })
      )}
    >
      <Grid gutter="xl">
        <Grid.Col>
          {form?.values?.identifiantsCompany &&
            form.values.identifiantsCompany.length >= 0 &&
            form.values.identifiantsCompany.map((item, index) => (
              <UserIdentifiantsCompany key={index} index={index} form={form} />
            ))}
        </Grid.Col>
        <Grid.Col>
          {form?.values?.identifiantsCompany &&
            form.values.identifiantsCompany.length === 0 && (
              <UserIdentifiantsCompanyButton
                onClick={() =>
                  form.insertListItem('identifiantsCompany', {
                    matricule: '',
                    codeCompany: '',
                  })
                }
              />
            )}
        </Grid.Col>
      </Grid>
      <Flex
        justify={'flex-end'}
        gap={'md'}
        direction="row"
        wrap="wrap"
        mt={'md'}
      >
        <ButtonSave />
      </Flex>
    </form>
  );
}
