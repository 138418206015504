import { Badge, Group } from '@mantine/core';
import React from 'react';

import { IdentifiantCompany } from '../../types/api/response/user';

type Props = {
  identifiantsCompany?: IdentifiantCompany[];
};

export default function IdentifiantsCompanyBadge({
  identifiantsCompany,
}: Props) {
  return (
    <Group gap="xs">
      {identifiantsCompany?.map((identifiantCompany: IdentifiantCompany) => (
        <Badge
          key={`${identifiantCompany.codeCompany}-${identifiantCompany.matricule}`}
          styles={{
            root: { textTransform: 'none' },
            label: { color: '#ebf2ff' },
          }}
          color="#647fcc"
        >
          {`${identifiantCompany.codeCompany} - ${identifiantCompany.matricule}`}
        </Badge>
      ))}
    </Group>
  );
}
