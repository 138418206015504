import { Group, Text, Title } from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconAlertTriangle } from '@tabler/icons-react';
import { type NavigateFunction } from 'react-router/dist/lib/hooks';

import {
  BuiltInPermissions,
  BuiltInRoles,
  ScopeEnum,
} from '../types/api/response/role';
import type { UserResponse } from '../types/api/response/user';
import type { TranslateFunction } from '../types/front/common';
import { hasPermission, hasRole } from './authorization';

export default function goToUserProfile(
  currentUser: UserResponse,
  navigate: NavigateFunction,
  t: TranslateFunction,
  userId: string,
  userFullname: string
) {
  if (
    hasRole(BuiltInRoles.ADMINISTRATOR, currentUser) ||
    hasPermission(
      {
        permission: BuiltInPermissions.AuthorizedReadAllUsersInfo,
        scope: ScopeEnum.ALL,
      },
      currentUser
    ) ||
    hasPermission(
      [
        {
          permission: BuiltInPermissions.ConfigureEmployees,
          scope: ScopeEnum.ALL,
        },
        {
          permission: BuiltInPermissions.ValidateLeavesAbsences,
          scope: ScopeEnum.ALL,
        },
        {
          permission: BuiltInPermissions.AuthorizedReadEmployeesPayslips,
          scope: ScopeEnum.ALL,
        },
      ],
      currentUser
    )
  ) {
    navigate(`/profile/user/${userId}`);
  } else {
    modals.open({
      id: 'alert-navigate-to-currentUser-profile-modal',
      title: (
        <Group>
          <Title size={'h3'} component="p">
            {t('w.warning')}
          </Title>
          <IconAlertTriangle color={'orange'} />
        </Group>
      ),
      children: (
        <Text fz={'sm'} fw={'bold'} c={'dimmed'}>{`${t(
          'w.YouAreNotAuthorisedToConsultTheProfileOf'
        )} ${userFullname}`}</Text>
      ),
    });
  }
}
