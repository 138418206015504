import request from './AxiosClient';

function getModuleParams(apiUrl: string, companyId: string) {
  return request.get(`${apiUrl}/${companyId}/params`).then((res) => res?.data);
}

function updateModuleParams(apiUrl: string, companyId: string, payload: any) {
  return request
    .patch(`${apiUrl}/${companyId}/params`, payload)
    .then((res) => res?.data);
}

const ModuleWithParamsService = {
  getModuleParams,
  updateModuleParams,
};

export default ModuleWithParamsService;
