import { Text, type TextProps } from '@mantine/core';
import dayjs from 'dayjs';
import React from 'react';

type Props = {
  date?: string;
};

export default function LeaveCreatedDate({
  date,
  ...restProps
}: Props & TextProps) {
  return (
    <Text fz={'sm'} {...restProps}>
      {dayjs(date).format('D MMM YYYY  HH:mm')}
    </Text>
  );
}
