import { FamilyState } from '../types/api/response/user';
import type { TranslateFunction } from '../types/front/common';

export function familyState(t: TranslateFunction) {
  return [
    { value: '', label: t('w.noValue') },
    { value: FamilyState.married, label: t('familyState.married') },
    { value: FamilyState.single, label: t('familyState.single') },
    { value: FamilyState.civil_union, label: t('familyState.civil_union') },
    {
      value: FamilyState.divorced,
      label: t('familyState.divorced'),
    },
    { value: FamilyState.separated, label: t('familyState.separated') },
    { value: FamilyState.widowed, label: t('familyState.widowed') },
  ];
}
