import { FilterOption, Options, Table } from '@ckprivate/ckf-ui';
import {
  ActionIcon,
  Button,
  Divider,
  Flex,
  Group,
  LoadingOverlay,
  Paper,
  Text,
  Title,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconCalendarPlus,
  IconCheck,
  IconEye,
  IconTrash,
  IconX,
} from '@tabler/icons-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import type { DataTableColumn } from 'mantine-datatable';
import React, { useMemo, useState } from 'react';

import LeaveStatusBadge from '../../../../components/badges/LeaveStatusBadge';
import NotYetValidatedBadge from '../../../../components/badges/NotYetValidatedBadge';
import CustomActionIconFile from '../../../../components/CustomActionIconFile';
import CustomModal from '../../../../components/CustomModal';
import CustomTooltip from '../../../../components/CustomTooltip';
import LeavePeriodDates from '../../../../components/LeavePeriodDates';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import LeaveService from '../../../../services/LeaveService';
import type { LeaveResponse } from '../../../../types/api/response/leave';
import {
  BuiltInPermissions,
  ScopeEnum,
} from '../../../../types/api/response/role';
import type { UserResponse } from '../../../../types/api/response/user';
import { handleOpenLeaveAttachmentFile } from '../../../../utils/attachmentFile';
import { hasPermission, isAccountant } from '../../../../utils/authorization';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
import {
  getFilter,
  getHiddenColumns,
  saveFilter,
  saveHiddenColumns,
} from '../../../../utils/optionsPreferences';
import LeaveCreatedDate from './components/LeaveCreatedDate';
import LeavesRequestFormModal from './LeavesRequestFormModal';

type Props = {
  leavesList: LeaveResponse[];
  isLoading: boolean;
  refetchLeavesList: any;
  userProfile: UserResponse;
  showTakeALeaveButton?: boolean;
};

const componentName = 'LeavesList';
export default function LeavesList({
  leavesList,
  isLoading,
  refetchLeavesList,
  userProfile,
  showTakeALeaveButton = false,
}: Props) {
  const { user, access_token } = useAuth();
  const { t, lang } = useI18n();
  const [leaveToEdit, setLeaveToEdit] = useState<LeaveResponse | null>(null);
  const [editMode, setEditMode] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const { company } = userProfile;

  const hasPermissionToDeleteLeaves =
    hasPermission(
      [
        {
          permission: BuiltInPermissions.ValidateLeavesAbsences,
          scope: ScopeEnum.ALL,
        },
        {
          permission: BuiltInPermissions.AuthorizedDeleteLeaves,
          scope: ScopeEnum.ALL,
        },
      ],
      user
    ) || isAccountant(user, userProfile.company?.id!);

  const { mutate: deleteLeave, isLoading: deleteLeaveIsLoading } = useMutation({
    mutationFn: (variables: { leaveId: string }) =>
      LeaveService.cancel(company?.id!, variables.leaveId),
    onSuccess: () => {
      refetchLeavesList();
      queryClient.invalidateQueries({
        queryKey: ['CounterUserService.getByUser', userProfile.id],
      });
      showNotification({
        id: 'confirm-leave-deletion-success',
        title: t('w.success'),
        message: t('leave.confirmDeletion.success'),
        color: 'green',
        icon: <IconCheck />,
      });
      closeModal();
    },
    onError: (error) => {
      showNotification({
        id: 'delete-user-notification-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      });
    },
  });

  const actionsColumn: DataTableColumn<LeaveResponse> = useMemo(() => {
    return {
      accessor: 'actions',
      title: t('w.actions'),
      width: 100,
      sortable: true,
      ellipsis: true,
      render: (leave) => {
        return (
          <Group gap={'xs'}>
            <ActionIcon
              variant={'subtle'}
              color="green"
              size={'sm'}
              onClick={() => {
                setEditMode(false);
                setLeaveToEdit(leave);
              }}
            >
              <IconEye size={18} />
            </ActionIcon>
            {hasPermissionToDeleteLeaves && (
              <ActionIcon
                color="red"
                variant={'subtle'}
                size={'sm'}
                onClick={() => {
                  setEditMode(true);
                  setLeaveToEdit(leave);
                }}
              >
                <IconTrash size={18} />
              </ActionIcon>
            )}
          </Group>
        );
      },
    };
  }, [hasPermissionToDeleteLeaves]);

  const columnsWithN2: DataTableColumn<LeaveResponse>[] = useMemo(
    () => [
      {
        accessor: 'leaveType.name',
        title: 'Type',
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'comment',
        title: t('w.comment'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'period.startDate',
        title: 'Dates',
        sortable: true,
        ellipsis: true,
        render: ({ period, startHalf, endHalf }) => (
          <LeavePeriodDates
            endDate={period.endDate}
            startDate={period.startDate}
            endHalf={endHalf}
            startHalf={startHalf}
          />
        ),
      },
      {
        accessor: 'created',
        title: t('w.requestedOn'),
        sortable: true,
        ellipsis: true,
        render: ({ created }) => <LeaveCreatedDate date={`${created}Z`} />,
      },
      {
        accessor: 'duration',
        title: t('w.numberOfDays'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'fileName',
        title: t('w.file'),
        sortable: true,
        ellipsis: true,
        render: ({ id, fileName, leaveType, whoUser }) => {
          return (
            <Group>
              {fileName && (
                <CustomTooltip label={t('w.seeAttachment')}>
                  <CustomActionIconFile
                    fileName={fileName}
                    onClick={() =>
                      handleOpenLeaveAttachmentFile(
                        id,
                        leaveType?.name!,
                        whoUser?.fullname!,
                        fileName,
                        access_token,
                        t
                      )
                    }
                  />
                </CustomTooltip>
              )}
            </Group>
          );
        },
      },
      {
        accessor: 'status',
        title: t('w.status'),
        sortable: true,
        ellipsis: true,
        render: ({ status }) => <LeaveStatusBadge status={status} />,
      },
      {
        accessor: 'validatorFullname',
        title: t('w.validator'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'statusDateN1',
        title: t('w.validationDateN1'),
        sortable: true,
        ellipsis: true,
        render: ({ statusDateN1 }) =>
          !statusDateN1 ? (
            <NotYetValidatedBadge />
          ) : (
            dayjs(statusDateN1).format('D MMM YYYY')
          ),
      },
      {
        accessor: 'statusDateN2',
        title: t('w.validationDateN2'),
        sortable: true,
        ellipsis: true,
        render: ({ statusDateN2 }) =>
          !statusDateN2 ? (
            <NotYetValidatedBadge />
          ) : (
            dayjs(statusDateN2).format('D MMM YYYY')
          ),
      },
      actionsColumn,
    ],
    [hasPermissionToDeleteLeaves]
  );

  const defaultColumns: DataTableColumn<LeaveResponse>[] = useMemo(
    () => [
      {
        accessor: 'leaveType.name',
        title: 'Type',
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'comment',
        title: t('w.comment'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'period.startDate',
        title: 'Dates',
        sortable: true,
        ellipsis: true,
        render: ({ period, startHalf, endHalf }) => (
          <LeavePeriodDates
            endDate={period.endDate}
            startDate={period.startDate}
            endHalf={endHalf}
            startHalf={startHalf}
          />
        ),
      },
      {
        accessor: 'created',
        title: t('w.requestedOn'),
        sortable: true,
        ellipsis: true,
        render: ({ created }) => <LeaveCreatedDate date={`${created}Z`} />,
      },
      {
        accessor: 'duration',
        title: t('w.numberOfDays'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'fileName',
        title: t('w.file'),
        sortable: true,
        ellipsis: true,
        render: ({ id, fileName, leaveType, whoUser }) => {
          return (
            <Group>
              {fileName && (
                <CustomTooltip label={t('w.seeAttachment')}>
                  <CustomActionIconFile
                    fileName={fileName}
                    onClick={() =>
                      handleOpenLeaveAttachmentFile(
                        id,
                        leaveType?.name!,
                        whoUser?.fullname!,
                        fileName,
                        access_token,
                        t
                      )
                    }
                  />
                </CustomTooltip>
              )}
            </Group>
          );
        },
      },
      {
        accessor: 'status',
        title: t('w.status'),
        sortable: true,
        ellipsis: true,
        render: ({ status }) => <LeaveStatusBadge status={status} />,
      },
      {
        accessor: 'validatorFullname',
        title: t('w.validator'),
        sortable: true,
        ellipsis: true,
      },
      actionsColumn,
    ],
    [hasPermissionToDeleteLeaves]
  );

  const leavesColumns: DataTableColumn<LeaveResponse>[] = useMemo(
    () => (company?.useN2! ? columnsWithN2 : defaultColumns),
    [hasPermissionToDeleteLeaves]
  );
  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    search: {
      accessor: 'leaveType.name',
      label: 'Type',
    },
    hiddenColumns: getHiddenColumns({
      componentName,
      hiddenColumns: ['comment'],
    }),
    updateHiddenColumns,
    filter: getFilter({
      componentName,
      filter: [
        {
          accessor: 'status.state',
          label: t('w.status'),
          choices: [
            { value: 'VALID', label: t('leave.status.valid') },
            { value: 'VALIDN1', label: t('leave.status.validn1') },
            {
              value: 'WAITING',
              label: t('leave.status.waiting'),
            },
            {
              value: 'REJECTED',
              label: t('leave.status.rejected'),
            },
          ],
          selectedChoices: [],
        },
      ],
    }),
    updateFilter,
  });

  function updateFilter(newFilter: FilterOption[]) {
    const _options = { ...options };
    _options.filter = newFilter;
    saveFilter({ componentName, filter: newFilter });
    setOptions(_options);
  }

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  function closeModal(): void {
    setLeaveToEdit(null);
  }

  function openLeaveRequestForm() {
    modals.open({
      modalId: 'leave-request-form',
      title: (
        <Title size={'h3'} component="p">
          {t('w.requestForLeaveOrAbsence', userProfile.fullname)}
        </Title>
      ),
      size: '90%',
      children: (
        <LeavesRequestFormModal
          onClose={() => modals.close('leave-request-form')}
          user={userProfile}
        />
      ),
    });
  }

  return (
    <Paper shadow="0px 3px 3px #00000029" p="md" withBorder>
      <Group justify="space-between" mb="md">
        <Title size={'24px'} order={3}>
          {t('w.leavesAndAbsences')}
        </Title>
        {showTakeALeaveButton && (
          <Button
            key={'requestAbsence-button'}
            leftSection={<IconCalendarPlus />}
            onClick={openLeaveRequestForm}
          >
            {t('w.requestAbsence')}
          </Button>
        )}
      </Group>
      <Table
        pinFirstColumn
        pinLastColumn
        rows={leavesList}
        options={options}
        columns={leavesColumns}
        lang={lang}
        fetching={isLoading}
        withTableBorder={false}
        height={'calc(100vh - 500px)'}
      />
      <CustomModal
        opened={leaveToEdit !== null}
        onClose={closeModal}
        title={leaveToEdit?.leaveType?.name}
        size={'lg'}
      >
        <LoadingOverlay visible={deleteLeaveIsLoading} />
        <Flex direction={'column'} mt={'lg'}>
          <Group justify={'space-between'}>
            <Text fz={'md'} fw={'medium'}>
              Dates:
            </Text>
            {leaveToEdit && (
              <LeavePeriodDates
                endDate={leaveToEdit.period.endDate}
                startDate={leaveToEdit.period.startDate}
                endHalf={leaveToEdit.endHalf}
                startHalf={leaveToEdit.startHalf}
                c={'dimmed'}
              />
            )}
          </Group>
          <Divider my="sm" />
          <Group justify={'space-between'}>
            <Text fz={'md'} fw={'medium'}>{`${t('w.comment')}:`}</Text>
            <Text fz={'sm'} fw={'medium'} c={'dimmed'}>{`${
              !!leaveToEdit?.comment ? leaveToEdit?.comment : ''
            }`}</Text>
          </Group>
          <Divider my="sm" />
          <Group justify={'space-between'}>
            <Text fz={'md'} fw={'medium'}>
              {`${t('w.numberOfDays')}:`}
            </Text>
            <Text
              fz={'sm'}
              fw={'medium'}
              c={'dimmed'}
            >{`${leaveToEdit?.duration}`}</Text>
          </Group>
          <Divider my="sm" />
          <Group justify={'space-between'}>
            <Text fz={'md'} fw={'medium'}>
              {`${t('w.requestedOn')}:`}
            </Text>
            <LeaveCreatedDate date={leaveToEdit?.created} c={'dimmed'} />
          </Group>
          <Divider my="sm" />
          {Boolean(company?.useN2) ? (
            <>
              {!leaveToEdit?.statusDateN1 ? (
                <Group justify={'space-between'}>
                  <Text fz={'md'} fw={'medium'}>
                    {`${t('w.validationN1')}:`}
                  </Text>
                  <NotYetValidatedBadge />
                </Group>
              ) : (
                <Group justify={'space-between'}>
                  <Text fz={'md'} fw={'medium'}>
                    {`${t('w.validationN1')}:`}
                  </Text>
                  <Text fz={'sm'} fw={'medium'} c={'dimmed'}>
                    {t(
                      leaveToEdit.status.state === 'REJECTED'
                        ? 'w.rejectedOnBy'
                        : 'w.validatedOnBy',
                      dayjs(leaveToEdit?.statusDateN1).format('D MMM YYYY'),
                      leaveToEdit?.validatorFullname
                    )}
                  </Text>
                </Group>
              )}
              <Divider my="sm" />
              {!leaveToEdit?.statusDateN2 ? (
                <Group justify={'space-between'}>
                  <Text fz={'md'} fw={'medium'}>
                    {`${t('w.validationN2')}:`}
                  </Text>
                  <NotYetValidatedBadge />
                </Group>
              ) : (
                <Group justify={'space-between'}>
                  <Text fz={'md'} fw={'medium'}>
                    {`${t('w.validationN2')}:`}
                  </Text>
                  <Text fz={'sm'} fw={'medium'} c={'dimmed'}>
                    {t(
                      leaveToEdit.status.state === 'REJECTED'
                        ? 'w.rejectedOnBy'
                        : 'w.validatedOnBy',
                      dayjs(leaveToEdit?.statusDateN2).format('D MMM YYYY'),
                      leaveToEdit?.validatorFullname
                    )}
                  </Text>
                </Group>
              )}
              <Divider my="sm" />
            </>
          ) : (
            <>
              <Group justify={'space-between'}>
                <Text fz={'md'} fw={'medium'}>
                  {`${t('w.validation')}:`}
                </Text>
                {!leaveToEdit?.statusDateN1 && !leaveToEdit?.statusDateN2 ? (
                  <NotYetValidatedBadge />
                ) : (
                  <Text fz={'sm'} fw={'medium'} c={'dimmed'}>
                    {t(
                      leaveToEdit.status.state === 'REJECTED'
                        ? 'w.rejectedOnBy'
                        : 'w.validatedOnBy',
                      dayjs(
                        leaveToEdit?.statusDateN1
                          ? leaveToEdit?.statusDateN1
                          : leaveToEdit?.statusDateN2
                      ).format('D MMM YYYY'),
                      leaveToEdit?.validatorFullname
                        ? leaveToEdit?.validatorFullname
                        : '-'
                    )}
                  </Text>
                )}
              </Group>
              <Divider my="sm" />
            </>
          )}
          <Group justify={'space-between'}>
            <Text fz={'md'} fw={'medium'}>
              {`${t('w.status')}`}
            </Text>
            <LeaveStatusBadge status={leaveToEdit?.status} />
          </Group>
          <Divider my="sm" />
          {leaveToEdit?.status.state === 'REJECTED' && (
            <>
              <Group justify={'space-between'}>
                <Text fz={'md'} fw={'medium'}>
                  {`${t('w.comment')} ${t('w.refusal').toLowerCase()}`}
                </Text>
                <Text fz={'sm'} fw={'medium'} c={'dimmed'}>
                  {!!leaveToEdit?.status?.comments?.[0]?.comment
                    ? leaveToEdit.status.comments[0].comment
                    : ''}
                </Text>
              </Group>
              <Divider my="sm" />
            </>
          )}
        </Flex>
        {editMode && (
          <Group justify={'flex-end'} mt={'lg'}>
            <Button onClick={closeModal} variant={'subtle'}>
              {t('w.cancel')}
            </Button>
            <Button
              onClick={() =>
                deleteLeave({ leaveId: leaveToEdit?.id as string })
              }
            >
              {t('w.confirmDeletion')}
            </Button>
          </Group>
        )}
      </CustomModal>
    </Paper>
  );
}
