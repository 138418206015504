import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAuth } from '../../../contexts/AuthProvider';
import useDisplayUserActivities from '../../../hooks/accessChecking/activities/useDisplayUserActivities';
import UserActivities from './components/UserActivities';

export default function MyActivities() {
  const isDisplayMyActivities = useDisplayUserActivities();
  let location = useLocation();
  const { user } = useAuth();

  if (!isDisplayMyActivities) {
    return <Navigate to="/error404" state={{ from: location }} replace />;
  }

  return <UserActivities userProfile={user} />;
}
