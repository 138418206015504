import {
  ActionIcon,
  Button,
  Flex,
  Group,
  LoadingOverlay,
  rem,
  Stack,
  Switch,
  TextInput,
  Title,
  Tooltip,
} from '@mantine/core';
import { TimeInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import {
  IconArrowRight,
  IconCheck,
  IconCirclePlus,
  IconClock,
  IconTrash,
  IconX,
} from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { useRef } from 'react';

import LabelText from '../../../../../components/LabelText/LabelText';
import { useAuth } from '../../../../../contexts/AuthProvider';
import { useI18n } from '../../../../../contexts/I18nProvider';
import useCompany from '../../../../../hooks/useCompany';
import DailyWorkService from '../../../../../services/DailyWorkService';
import type { DailyWorkDailyTemplateRequest } from '../../../../../types/api/payload/dailyWork';
import type {
  DailyWorkDailyTemplateResponse,
  PeriodTemplate,
} from '../../../../../types/api/response/dailyWork';
import handleErrorMessage from '../../../../../utils/handleErrorMessage';

type Props = {
  template: DailyWorkDailyTemplateResponse;
  refetchDailyTemplatesData: () => void;
  onClose: () => void;
  isEditing?: boolean;
};

type FormValues = {
  period: PeriodTemplate[];
  name: string;
  shared?: boolean;
};
export default function DailyTemplateForm({
  template,
  refetchDailyTemplatesData,
  onClose,
  isEditing = false,
}: Props) {
  const { user } = useAuth();
  const canEdit = !isEditing || template.creatorId === user.id;
  const { t } = useI18n();
  const { id: companyId } = useCompany(user);
  const form = useForm<FormValues>({ initialValues: { ...template } });

  const startTimeInputRefs = useRef<any[]>([]);
  const endTimeInputRefs = useRef<any[]>([]);

  function pickerStartDateControl(index: number) {
    return (
      <ActionIcon
        variant="subtle"
        color="gray"
        onClick={() => startTimeInputRefs.current[index]?.showPicker()}
      >
        <IconClock style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
      </ActionIcon>
    );
  }
  function pickerEndDateControl(index: number) {
    return (
      <ActionIcon
        variant="subtle"
        color="gray"
        onClick={() => endTimeInputRefs.current[index]?.showPicker()}
      >
        <IconClock style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
      </ActionIcon>
    );
  }

  const {
    mutate: updateDailyTemplate,
    isLoading: isUpdateDailyTemplateLoading,
  } = useMutation({
    mutationFn: (variables: DailyWorkDailyTemplateRequest) =>
      DailyWorkService.updateDailyTemplate(companyId, template.id, variables),
    onSuccess: (data: DailyWorkDailyTemplateResponse) => {
      const { id, name } = data;
      refetchDailyTemplatesData();
      onClose();
      showNotification({
        id: `update-daily-template-${id}-successful`,
        title: t('w.success'),
        message: `Modèle ${name} ${t('w.updated').toLowerCase()}`,
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: (error) =>
      showNotification({
        id: `update-daily-template-error`,
        title: t('w.success'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });
  const {
    mutate: createDailyTemplate,
    isLoading: isCreateDailyTemplateLoading,
  } = useMutation({
    mutationFn: (variables: DailyWorkDailyTemplateRequest) =>
      DailyWorkService.createDailyTemplate(companyId, variables),
    onSuccess: (data: DailyWorkDailyTemplateResponse) => {
      const { id, name } = data;
      refetchDailyTemplatesData();
      onClose();
      showNotification({
        id: `create-daily-template-${id}-successful`,
        title: t('w.success'),
        message: `Modèle ${name} ${t('w.created').toLowerCase()}`,
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: (error) =>
      showNotification({
        id: `create-daily-template-error`,
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function handleDailyTemplateSubmit(values: FormValues) {
    if (isEditing) {
      updateDailyTemplate({ ...values });
    } else {
      createDailyTemplate({ ...values });
    }
  }

  return (
    <form
      onSubmit={form.onSubmit((values) => handleDailyTemplateSubmit(values))}
    >
      <LoadingOverlay
        visible={isUpdateDailyTemplateLoading || isCreateDailyTemplateLoading}
      />
      <Stack>
        <Flex align={'flex-end'} justify={'space-between'}>
          <TextInput
            disabled={!canEdit}
            label={<LabelText text={t('w.modelName')} />}
            required
            {...form.getInputProps('name')}
          />
          <Switch
            disabled={!canEdit}
            labelPosition="left"
            label={<LabelText text={t('w.sharedTemplate')} />}
            checked={form.values.shared}
            onLabel={t('w.yes')}
            offLabel={t('w.no')}
            size="md"
            {...form.getInputProps('shared')}
          />
        </Flex>
        <Group>
          <Title size={'h4'} c={'dimmed'}>
            {t('w.dailyPeriods')}
          </Title>
          {canEdit && (
            <Tooltip label={t('w.add')} position={'right'}>
              <ActionIcon
                variant={'subtle'}
                onClick={() =>
                  form.insertListItem('period', {
                    startDate: '00:00',
                    endDate: '00:00',
                  })
                }
              >
                <IconCirclePlus />
              </ActionIcon>
            </Tooltip>
          )}
        </Group>
        {form.values.period.map((item, index) => (
          <Group key={`group-${index}`} gap={'xl'}>
            <TimeInput
              id={`start-${index}`}
              label={<LabelText text={'Début'} />}
              {...form.getInputProps(`period.${index}.startDate`)}
              ref={(element) => (startTimeInputRefs.current[index] = element)}
              rightSection={pickerStartDateControl(index)}
              disabled={!canEdit}
            />
            <IconArrowRight
              style={{ marginTop: 'var(--mantine-spacing-md)' }}
              color={'var(--mantine-color-hifivework-5)'}
            />
            <TimeInput
              id={`end-${index}`}
              label={<LabelText text={'Fin'} />}
              {...form.getInputProps(`period.${index}.endDate`)}
              ref={(element) => (endTimeInputRefs.current[index] = element)}
              rightSection={pickerEndDateControl(index)}
              disabled={!canEdit}
            />
            {canEdit && (
              <ActionIcon
                mt={'md'}
                variant={'subtle'}
                size={'sm'}
                color={'red'}
                onClick={() => form.removeListItem('period', index)}
              >
                <IconTrash />
              </ActionIcon>
            )}
          </Group>
        ))}
        {canEdit && (
          <Flex justify={'flex-end'}>
            <Button type={'submit'}>
              {isEditing ? t('w.modify') : t('w.create')}
            </Button>
          </Flex>
        )}
      </Stack>
    </form>
  );
}
