import { ActionIcon, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconMessagePlus, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';

import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import ShuttleSheetService from '../../../services/ShuttleSheetService';
import { ShuttleSheetRequest } from '../../../types/types';
import handleErrorMessage from '../../../utils/handleErrorMessage';

type Props = {
  shuttleSheetRequest: ShuttleSheetRequest | undefined;
  refetch: () => void;
};

type FormValues = {
  comment: string;
};

export default function ShuttleSheetAddCommentForm({
  shuttleSheetRequest,
  refetch,
}: Props) {
  const { t } = useI18n();
  const { user } = useAuth();

  const form = useForm<FormValues>({
    initialValues: {
      comment: '',
    },
  });

  const { mutate: addShuttleSheetComment, isLoading } = useMutation({
    mutationFn: (variables: string) =>
      ShuttleSheetService.addShuttleSheetComment(
        shuttleSheetRequest?.companyId,
        shuttleSheetRequest?.id,
        user.id,
        variables
      ),
    onSuccess: () => {
      form.setFieldValue('comment', '');
      refetch();
    },
    onError: (error) =>
      showNotification({
        id: 'add-shuttle-sheet-comment-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function handleAddCommentSubmit(values: FormValues) {
    addShuttleSheetComment(values.comment);
  }

  return (
    <form onSubmit={form.onSubmit(handleAddCommentSubmit)}>
      <Textarea
        variant="filled"
        placeholder={`${t('w.add')} ${t('w.comment').toLowerCase()}`}
        rightSection={
          <ActionIcon
            size={'md'}
            variant={'subtle'}
            type={'submit'}
            disabled={form.values.comment === ''}
            loading={isLoading}
          >
            <IconMessagePlus />
          </ActionIcon>
        }
        styles={{
          input: { backgroundColor: 'var(--mantine-color-hifivework-0)' },
        }}
        {...form.getInputProps('comment')}
      />
    </form>
  );
}
