import { Flex, ScrollArea, Space } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';

import ShuttleSheetService from '../../../services/ShuttleSheetService';
import ShuttleSheetAddCommentForm from './ShuttleSheetAddCommentForm';
import ShuttleSheetCommentsItem from './ShuttleSheetCommentsItem';

type Props = {
  companyId: string;
  shuttleSheetId: string;
  refetch: () => void;
};

export default function ShuttleSheetComments({
  companyId,
  shuttleSheetId,
  refetch,
}: Props) {
  const viewport = useRef<HTMLDivElement>(null);

  const { data: shuttleSheet, refetch: refetchShuttleSheetData } = useQuery({
    queryKey: [
      'ShuttleSheetService.getShuttleSheet',
      companyId,
      shuttleSheetId,
    ],
    queryFn: () =>
      ShuttleSheetService.getShuttleSheet(companyId, shuttleSheetId),
  });

  useEffect(() => {
    viewport.current!.scrollTo({
      top: viewport.current!.scrollHeight,
      behavior: 'smooth',
    });
  }, []);

  return (
    <Flex direction={'column'} justify={'space-between'} h={'100%'}>
      <ScrollArea viewportRef={viewport}>
        <ShuttleSheetCommentsItem
          comments={shuttleSheet?.comments ? shuttleSheet.comments : []}
        />
      </ScrollArea>
      <Space h="md" />
      <ShuttleSheetAddCommentForm
        shuttleSheetRequest={shuttleSheet}
        refetch={() => {
          refetch();
          refetchShuttleSheetData();
        }}
      />
    </Flex>
  );
}
