import { Stack } from '@mantine/core';
import type {
  DatesRangeValue,
  DateValue,
} from '@mantine/dates/lib/types/DatePickerValue';

import { useI18n } from '../../../../../contexts/I18nProvider';
import type { PayslipMonthResponse } from '../../../../../types/types';
import ImportPayslipsTitle from '../ImportPayslipsTitle';
import AssignEmployeeWithoutPayslipTable from './AssignEmployeeWithoutPayslipTable';

type Props = {
  payslipsMonthInfo: PayslipMonthResponse | undefined;
  selectDate: DateValue | DatesRangeValue | Date[];
  selectedUserId: string;
  companyId: string;
};

export default function AssignEmployeeWithoutPayslip({
  payslipsMonthInfo,
  selectDate,
  selectedUserId,
  companyId,
}: Props) {
  const { t } = useI18n();
  return (
    <Stack gap={'xl'}>
      <ImportPayslipsTitle title={t('w.listOfPaySlipsWithoutAnEmployee')} />
      <AssignEmployeeWithoutPayslipTable
        payslipsMonthInfo={payslipsMonthInfo}
        selectDate={selectDate}
        selectedUserId={selectedUserId}
        companyId={companyId}
      />
    </Stack>
  );
}
