import type { CommonEntity } from '../common';

export type HfwSecurityRoleResponse = CommonEntity & {
  companyId: string;
  name: BuiltInRoles;
  permissions?: PermissionScopedResponse[];
};

export enum ScopeEnum {
  ALL = 'ALL',
  TEAM = 'TEAM',
  MINE = 'MINE',
}

export type PermissionResponse = {
  id: string;
  created: number;
  updated: number;
  name: string;
  type: string;
  key: BuiltInPermissions;
};

export type PermissionScopedResponse = {
  permission: PermissionResponse;
  scope: ScopeEnum;
};

export type OauthRoleUsed = CommonEntity & {
  roleId: string;
  usedByObjectId: string;
  usedByType: string;
};

export enum BuiltInPermissions {
  ConfigureWorkUnit = 'configure.workunit', // Configurer les unités de travail
  ConfigureEmployees = 'configure.employees', // Configurer les employés
  ConfigureLeaveTypes = 'configure.leavetypes', // Configurer les types de congés
  ConfigureBlockingPeriods = 'configure.blockingperiods', // Configurer les périodes de blocage
  ConfigurePublicHolidaysCompanyLeaves = 'configure.publicholidayscompanyleaves', // Configurer les jours fériés et congés entreprise
  ConfigureShuttleSheetTypes = 'configure.shuttlesheettypes', // Configurer les types de demande
  ConfigureAccountant = 'configure.accountant', // Configurer le gestionnaire de paie
  ConfigurePayment = 'configure.payment', // Configurer les informations de paiement
  ConfigureFeatures = 'configure.features', // Configurer les fonctionnalités
  ConfigurePermissions = 'configure.permissions', // Configurer les permissions
  ConfigureCompany = 'configure.company', // Configurer les informations de l'entreprise
  ConfigureTools = 'configure.tools', // Configurer les outils de l'entreprise
  ConfigureCompanyGroup = 'configure.companygroup', // Configurer les entreprises rattachées
  ValidateLeavesAbsences = 'validate.leavesabsences', // Valider et modifier les absences et présences
  ValidateExpenseReports = 'validate.expensereports', // Valider et modifier les notes de frais
  ValidateEndOfMonth = 'validate.endofmonth', // Valider et modifier les fins de mois
  ValidatePayrollVariables = 'validate.payrollvariables', // Valider et modifier les variables de paie
  ValidateHolidayCounter = 'validate.holidaycounter', // Valider et modifier les compteurs de congés
  ValidatePayslips = 'validate.payslips', // Valider et modifier les bulletins de paie
  ValidateShuttleSheets = 'validate.shuttlesheets', // Valider et modifier les demandes
  AuthorizedReadPlanning = 'authorized.readplanning', // Autoriser à voir le planning
  AuthorizedReadEmployeesPayslips = 'authorized.reademployeespayslips', // Autoriser à voir les bulletins de paie des employés
  AuthorizedReadEmployeesPersonalSpace = 'authorized.reademployeespersonalspace', // Autoriser à voir l'espace personnel des employés
  AuthorizedEditEmployeesPersonalSpace = 'authorized.editemployeespersonalspace', // Autoriser à modifier l'espace personnel des employés
  AuthorizedReadMyPersonalSpace = 'authorized.readmypersonalspace', // Autoriser à voir mon espace personnel
  AuthorizedEditMyPersonalSpace = 'authorized.editmypersonalspace', // Autoriser à modifier mon espace personnel
  AuthorizedReadCommonSpace = 'authorized.readcommonspace', // Autoriser à voir l'espace partagé
  AuthorizedEditCommonSpace = 'authorized.editcommonspace', // Autoriser à modifier l'espace partagé
  AuthorizedDeleteLeaves = 'authorized.deleteleaves', // Autoriser à supprimer les congés
  AuthorizedNegativeLeaveBalance = 'authorized.negativeleavebalance', // Autoriser à poser des congés quand le solde est insuffisant
  AuthorizedReadDailyWork = 'authorized.readdailywork', // Autoriser à voir les présences
  AuthorizedUpsertDailyWork = 'authorized.upsertdailywork', // Autoriser à upsert les présences
  AuthorizedReadAllUsersInfo = 'authorized.readallusersinfo', // Autoriser à voir les plannings, les bulletins de paie, les espaces personnels de tous employés
  AuthorizedStartStopTimeRecorder = 'authorized.startstoptimerecorder', // Autoriser à pointer ou dépointer
  AuthorizedUpdateTimeRecorder = 'authorized.updatetimerecorder', // Autoriser à modifier un time recorder
  AuthorizedNotificationReceive = 'authorized.notificationreceive', // Autoriser recevoir toute les notif de l'entreprise
  AuthorizedEditAccountantSpace = 'authorized.editaccountantspace', // Autoriser lire / ecrire les document de l'espace gestionnaire
  AccountantViewClient = 'accountant.viewClient',
  AccountantUpdateClient = 'accountant.updateClient',
  AccountantManageTeam = 'accountant.manageTeam',
  AccountantNotificationReceive = 'accountant.notificationreceive',
  UserEmergencyContactEdit = 'user.emergencycontactedit',
  UserEmergencyContactShow = 'user.emergencycontactshow',
  UserPersonalInfoEdit = 'user.personalinfoedit',
  UserPersonalInfoShow = 'user.personalinfoshow',
  UserContractualInfoEdit = 'user.contractualinfoedit',
  UserContractualInfoShow = 'user.contractualinfoshow',
}

export enum BuiltInRoles {
  CODERKAINE = 'coderkaine',
  ADMINISTRATOR = 'administrator',
  DIRECTION = 'direction',
  MANAGER = 'manager',
  EMPLOYEE = 'employee',
}
