import { ActionIcon, Stack, Text } from '@mantine/core';
import { IconFileTypePdf } from '@tabler/icons-react';

import { milliToFullDateString } from '../../../../../utils/format';

type Props = {
  date: number;
  onClick: () => void;
};
export default function PayslipsSourceFileIcon({ date, onClick }: Props) {
  return (
    <Stack align={'center'} gap={'xs'}>
      <ActionIcon variant={'subtle'} size={80} onClick={onClick}>
        <IconFileTypePdf size={80} />
      </ActionIcon>
      <Text fz={'xs'} fw={'bold'} c={'dimmed'}>
        {milliToFullDateString(date)}
      </Text>
    </Stack>
  );
}
