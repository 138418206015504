import { Button, Group, Tabs, Title } from '@mantine/core';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconCirclePlus, IconFolders, IconX } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';

import CustomTooltip from '../../../components/CustomTooltip';
import InvitationsHistoryPayrollOfficerTable from '../../../components/InvitationsHistoryPayrollOfficerTable';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import { useModule } from '../../../contexts/ModuleProvider';
import ScreenWrapper from '../../../core/layouts/components/ScreenWrapper';
import useCompany from '../../../hooks/useCompany';
import CharteredAccountantService from '../../../services/CharteredAccountantService';
import PaymentAccountService from '../../../services/PaymentAccountService';
import { ModuleName } from '../../../types/api/response/module';
import {
  BuiltInPermissions,
  ScopeEnum,
} from '../../../types/api/response/role';
import { hasPermission } from '../../../utils/authorization';
import handleErrorMessage from '../../../utils/handleErrorMessage';
import FeatureSynchroOpenpayeParams from '../../company/features/components/SynchroOpenpaye/FeatureSynchroOpenpayeParams';
import AccountantCustomersTable from './component/AccountantCustomersTable';
import AddCustomerCompanyForm from './component/AddCustomerCompanyForm';

export default function AccountantCustomerManagement() {
  const { t } = useI18n();
  const [activeTab, setActiveTab] = useState<string | null>('customers');
  const { user } = useAuth();
  const { id: companyId } = useCompany(user);
  const { getModule } = useModule();
  const openpayeModule = getModule(ModuleName.SynchroOpenPaye);
  const isOpenpayeModuleActive = openpayeModule?.active;
  const {
    data: invitationAccountantHistoryData,
    isLoading: isInvitationAccountantHistoryLoading,
    refetch: refetchInvitationAccountantHistoryData,
    error,
  } = useQuery({
    queryKey: [
      'CharteredAccountantService.invitationAccountantHistory',
      companyId,
    ],
    queryFn: () =>
      CharteredAccountantService.invitationAccountantHistory(companyId),
    onError: (err: any) => {
      if (err.response.status !== 403) {
        showNotification({
          id: 'invitation-accountant-history-error',
          title: t('w.error'),
          message: handleErrorMessage(err, t),
          color: 'red',
          icon: <IconX />,
        });
      }
    },
  });

  const { data: companyPaymentAccount } = useQuery({
    queryKey: ['PaymentAccountService.getCompanyPaymentAccount', companyId],
    queryFn: () => PaymentAccountService.getCompanyPaymentAccount(companyId),
  });

  const isPaymentAccountActive =
    Boolean(companyPaymentAccount?.active) &&
    Boolean(
      companyPaymentAccount?.companies?.find(
        (paymentCompany) =>
          paymentCompany.id === companyId && paymentCompany.active
      )
    );

  const actionButtons = useMemo(() => {
    return [
      <CustomTooltip
        key={'add-customer-company'}
        label={t('w.yourCompanyBillingAccountIsNotActivated')}
        position={'left'}
        disabled={isPaymentAccountActive}
      >
        <Group>
          {isOpenpayeModuleActive && (
            <Button
              leftSection={<IconFolders />}
              onClick={() => {
                modals.open({
                  modalId: 'add-customer-company',
                  size: '80%',
                  title: (
                    <Title size={'h3'} component="p">
                      {t('openpaye.synchroDossiers')}
                    </Title>
                  ),
                  children: (
                    <FeatureSynchroOpenpayeParams
                      module={openpayeModule}
                      companyId={companyId}
                      isAccountant
                    />
                  ),
                });
              }}
              disabled={!isPaymentAccountActive}
            >
              {t('openpaye.synchroDossiers')}
            </Button>
          )}
          <Button
            leftSection={<IconCirclePlus />}
            onClick={() => {
              modals.open({
                modalId: 'add-customer-company',
                fullScreen: true,
                children: <AddCustomerCompanyForm linkAsAccountant={true} />,
              });
            }}
            disabled={!isPaymentAccountActive}
          >
            {`${t('w.add')} ${t('w.customers').slice(0, -1).toLowerCase()}`}
          </Button>
        </Group>
      </CustomTooltip>,
    ];
  }, [companyPaymentAccount, isPaymentAccountActive, openpayeModule]);

  const tabs = useMemo(() => {
    // @ts-ignore
    if (error?.response.status === 403) {
      return [
        {
          value: 'customers',
          label: t('w.customers'),
          component: (
            <AccountantCustomersTable
              isPaymentAccountActive={isPaymentAccountActive}
            />
          ),
        },
      ];
    }
    return [
      {
        value: 'customers',
        label: t('w.customers'),
        component: (
          <AccountantCustomersTable
            isPaymentAccountActive={isPaymentAccountActive}
          />
        ),
      },
      {
        value: 'history',
        label: t('w.history'),
        component: (
          <InvitationsHistoryPayrollOfficerTable
            invitationAccountantHistoryData={invitationAccountantHistoryData}
            isInvitationAccountantHistoryLoading={
              isInvitationAccountantHistoryLoading
            }
            refetchInvitationAccountantHistoryData={
              refetchInvitationAccountantHistoryData
            }
          />
        ),
      },
    ];
  }, [error, isInvitationAccountantHistoryLoading, isPaymentAccountActive]);

  function hasPermissionToAddCustomerCompany(): boolean {
    return hasPermission(
      { permission: BuiltInPermissions.ConfigurePayment, scope: ScopeEnum.ALL },
      user
    );
  }

  return (
    <ScreenWrapper
      title={t('w.firmClients')}
      actionButtons={hasPermissionToAddCustomerCompany() ? actionButtons : []}
    >
      <Tabs value={activeTab} onChange={setActiveTab}>
        <Tabs.List>
          {tabs.map((tab, index) => (
            <Tabs.Tab key={`tab-${tab.value}-${index}`} value={tab.value}>
              {tab.label}
            </Tabs.Tab>
          ))}
        </Tabs.List>
        {tabs.map((tab, index) => (
          <Tabs.Panel
            pt={'md'}
            key={`panel-${tab.value}-${index}`}
            value={tab.value}
          >
            {tab.component}
          </Tabs.Panel>
        ))}
      </Tabs>
    </ScreenWrapper>
  );
}
