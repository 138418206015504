import {
  Combobox,
  Tooltip,
  UnstyledButton,
  useCombobox,
  useDirection,
} from '@mantine/core';
import { useMutation } from '@tanstack/react-query';
import React, { forwardRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import ProfileService from '../../../services/ProfileService';
import { UserPayload } from '../../../types/api/payload/user';
import { Language } from '../../../types/i18n';
import { persistData } from '../../../utils/localStorage';
import { isHiFiveWorkProject } from '../../../utils/projects';

const LocaleSwitcherNaked = forwardRef<HTMLDivElement, Props>(
  ({ useLocalStorage = false }, ref) => {
    const { user, updateUser, access_token, refresh_token } = useAuth();
    const navigate = useNavigate();
    const { lang } = useI18n();
    const { toggleDirection, dir } = useDirection();
    const allLocales = isHiFiveWorkProject()
      ? [
          Language.fr,
          Language.en,
          Language.es,
          Language.pt,
          Language.de,
          Language.it,
        ]
      : Object.values(Language);
    const combobox = useCombobox({
      onDropdownClose: () => combobox.resetSelectedOption(),
    });

    const { mutate } = useMutation({
      mutationFn: (variables: UserPayload) =>
        ProfileService.updateUserProfile(user.id, variables),
      onSuccess: (data) => {
        updateUser(data, access_token, refresh_token, null);
      },
    });

    useEffect(() => {
      if (lang === Language.ar && dir === 'ltr') {
        toggleDirection();
      } else if (lang !== Language.ar && dir === 'rtl') {
        toggleDirection();
      }
    }, [lang]);

    function updateLanguage(lang: Language) {
      persistData('lang', lang);
      combobox.closeDropdown();
      if (useLocalStorage) {
        navigate(0);
      } else {
        mutate({ lang });
      }
    }

    return (
      <div ref={ref}>
        <Combobox
          variant="unstyled"
          store={combobox}
          onOptionSubmit={(value) => {
            updateLanguage(value as Language);
          }}
          width="max-content"
        >
          <Combobox.Target>
            <UnstyledButton
              display="flex"
              onClick={() => combobox.toggleDropdown()}
            >
              <img
                alt={lang}
                src={`/images/langs/${lang}.png`}
                width={24}
                height={24}
              />
            </UnstyledButton>
          </Combobox.Target>

          <Combobox.Dropdown>
            <Combobox.Options>
              {allLocales.map((item) => (
                <Combobox.Option value={item} key={item}>
                  <img
                    alt={item}
                    src={`/images/langs/${item}.png`}
                    width={24}
                    height={24}
                  />
                </Combobox.Option>
              ))}
            </Combobox.Options>
          </Combobox.Dropdown>
        </Combobox>
      </div>
    );
  }
);

type Props = {
  useLocalStorage?: boolean;
};

export default function LocaleSwitcher(props: Props): JSX.Element {
  const { t } = useI18n();
  return (
    <Tooltip label={t('w.changeLanguage')}>
      <LocaleSwitcherNaked {...props} />
    </Tooltip>
  );
}
