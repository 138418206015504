import { ActionIcon, Group, Title } from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconCirclePlus, IconEye } from '@tabler/icons-react';
import React from 'react';

import CustomTooltip from '../../../../components/CustomTooltip';
import { useI18n } from '../../../../contexts/I18nProvider';
import type { PaymentAccount } from '../../../../types/types';
import AddCustomerCompanyForm from '../../../accountant/customer-management/component/AddCustomerCompanyForm';
import s from '../../PaymentAccount.module.sass';
import Companies from '../DetailedView/Companies';

type Props = {
  paymentAccount: PaymentAccount;
};

export default function CompaniesOverview({ paymentAccount }: Props) {
  const { t } = useI18n();
  const activeCompanies = paymentAccount.companies.filter((elt) => elt.active);

  function openCompanies() {
    modals.open({
      modalId: 'Companies',
      children: <Companies paymentAccountId={paymentAccount.id} />,
      size: 'xl',
      m: 'xl',
      withCloseButton: false,
    });
  }

  function addCompanyClick() {
    modals.open({
      modalId: 'add-company-to-payment-account',
      fullScreen: true,
      withCloseButton: true,
      children: <AddCustomerCompanyForm />,
    });
  }

  return (
    <>
      <Group gap={'md'}>
        <CustomTooltip label={t('w.seeCompanies')}>
          <ActionIcon onClick={openCompanies} variant={'filled'} color="blue">
            <IconEye />
          </ActionIcon>
        </CustomTooltip>
        <CustomTooltip label={t('w.addCompany')}>
          <ActionIcon
            onClick={addCompanyClick}
            variant={'filled'}
            color="green"
          >
            <IconCirclePlus />
          </ActionIcon>
        </CustomTooltip>
      </Group>

      {Boolean(activeCompanies.length) && activeCompanies.length < 3 ? (
        <div className={s.fullwidth}>
          <Companies paymentAccountId={paymentAccount.id} variant={'small'} />
        </div>
      ) : (
        <Title size="h3" m={'auto'}>
          {t('w.activeCompanies', activeCompanies.length)}
        </Title>
      )}
    </>
  );
}
