import { ActionIcon, Group, Loader, Title } from '@mantine/core';
import { modals } from '@mantine/modals';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, type StripeElementLocale } from '@stripe/stripe-js';
import { IconEye } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import React, { useMemo } from 'react';

import CustomTooltip from '../../../../components/CustomTooltip';
import { useI18n } from '../../../../contexts/I18nProvider';
import useStripeConfig from '../../../../hooks/useStripeConfig';
import PaymentAccountService from '../../../../services/PaymentAccountService';
import type { PaymentAccount } from '../../../../types/types';
import s from '../../PaymentAccount.module.sass';
import Invoices from '../DetailedView/Invoices';

dayjs.extend(localizedFormat);

type Props = {
  paymentAccount: PaymentAccount;
};

export default function InvoicesOverview({ paymentAccount }: Props) {
  const { t, lang } = useI18n();
  const { data: invoices, isLoading: invoicesLoading } = useQuery({
    queryKey: ['PaymentAccountService.getBillingHistory', paymentAccount.id],
    queryFn: () => PaymentAccountService.getBillingHistory(paymentAccount.id),
  });
  const { publishableKey } = useStripeConfig(paymentAccount.id);

  const stripePromise = useMemo(() => {
    if (publishableKey) {
      return loadStripe(publishableKey);
    }

    return null;
  }, [publishableKey]);

  function openInvoices() {
    modals.open({
      modalId: 'Invoices',
      children: (
        <Elements
          options={{ locale: lang as StripeElementLocale }}
          stripe={stripePromise}
        >
          <Invoices invoices={invoices || []} paymentAccount={paymentAccount} />
        </Elements>
      ),
      size: 'xl',
      m: 'xl',
      withCloseButton: false,
    });
  }

  if (invoicesLoading) return <Loader type={'bars'} m={'auto'} />;

  if (!invoices?.length)
    return (
      <Title size="h5" m={'auto'}>
        {t('w.noInvoiceYet')}
      </Title>
    );

  return (
    <>
      <Group gap={'md'}>
        <CustomTooltip label={t('w.seeInvoices')}>
          <ActionIcon onClick={openInvoices} variant={'filled'} color="blue">
            <IconEye />
          </ActionIcon>
        </CustomTooltip>
      </Group>

      {Boolean(invoices.length) && invoices.length < 3 ? (
        <div className={s.fullwidth}>
          <Elements
            options={{ locale: lang as StripeElementLocale }}
            stripe={stripePromise}
          >
            <Invoices
              invoices={invoices || []}
              paymentAccount={paymentAccount}
              variant="small"
            />
          </Elements>
        </div>
      ) : (
        <Title size="h3" m={'auto'}>
          {t('w.nInvoices', invoices.length)}
        </Title>
      )}
    </>
  );
}
