import { Button, Code, rem, Text, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { openSpotlight } from '@mantine/spotlight';
import { IconSearch } from '@tabler/icons-react';
import React from 'react';

import { useI18n } from '../../../../contexts/I18nProvider';
import s from './SearchButton.module.css';

export default function SearchButton() {
  const { t } = useI18n();
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  return (
    <Button
      size="sm"
      c="dimmed"
      variant="default"
      radius="md"
      leftSection={
        isMobile ? undefined : (
          <IconSearch
            style={{ width: rem(12), height: rem(12) }}
            stroke={1.5}
          />
        )
      }
      onClick={openSpotlight}
      rightSection={
        isMobile ? undefined : <Code className={s.searchCode}>Ctrl + K</Code>
      }
    >
      <Text size="sm">{t('w.search')}</Text>
    </Button>
  );
}
