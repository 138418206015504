import { Group, Text } from '@mantine/core';

import CustomUserAvatar from './CustomAvatar/CustomUserAvatar';

type Props = {
  userId: string;
  userFullname: string;
};

export default function ConfirmValidateEmployeesList({
  userId,
  userFullname,
}: Props) {
  return (
    <Group>
      <CustomUserAvatar size={'md'} userId={userId} />
      <Text fz={'sm'} fw={'bold'} c={'dimmed'}>
        {userFullname}
      </Text>
    </Group>
  );
}
