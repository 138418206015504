import { Flex, Text } from '@mantine/core';
import { IconAlertTriangle } from '@tabler/icons-react';
import React from 'react';

import { useI18n } from '../../../../contexts/I18nProvider';

export default function CompanyFeaturesWarningMessage() {
  const { t } = useI18n();

  return (
    <Flex align={'center'} mb={'xl'} gap={'md'}>
      <IconAlertTriangle size={30} color={'red'} />
      <Flex direction={'column'} gap={'xs'}>
        <Text fz={'xs'} c={'red'} fw={'bold'}>
          {t('w.featuresFirstWaringMessage')}
        </Text>
        <Text fz={'xs'} c={'red'} fw={'bold'}>
          {t('w.featuresSecondWaringMessage')}
        </Text>
      </Flex>
    </Flex>
  );
}
