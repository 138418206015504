import { Badge } from '@mantine/core';
import React from 'react';

import { useI18n } from '../../../contexts/I18nProvider';
import {
  ShuttleSheetRequestStatus,
  ShuttleSheetRequestStatusOwner,
} from '../../../types/types';

type Props = {
  status: ShuttleSheetRequestStatus | undefined;
  statusOwner: ShuttleSheetRequestStatusOwner | undefined;
  transmittedToAccountant: boolean;
};

export default function ShuttleSheetStatusBadge({
  status,
  statusOwner,
  transmittedToAccountant,
}: Props) {
  const { t } = useI18n();

  function getBadge(status: ShuttleSheetRequestStatus | undefined): {
    colorBadge: string;
    colorText: string;
  } {
    let colorBadge: string;
    let colorText: string;
    switch (status) {
      case 'VALID':
        colorBadge = '#CEE9E6';
        colorText = '#0A9182';
        break;
      case 'REJECTED':
        colorBadge = '#E70D4F1A';
        colorText = '#E70D4F';
        break;
      case 'WAITING':
        colorBadge = '#FCF0E0';
        colorText = '#EDB464';
        break;
      default:
        colorBadge = 'hifivework.4';
        colorText = 'white';
    }
    return { colorBadge: colorBadge, colorText: colorText };
  }

  function getShuttleSheetStatusLabel(
    status: ShuttleSheetRequestStatus | undefined,
    statusOwner: ShuttleSheetRequestStatusOwner | undefined,
    transmittedToAccountant: boolean
  ): string {
    const statusLabel = t(`shuttleSheet.statusEnum.${status}`);
    const statusOwnerLabel = t(`shuttleSheet.statusOwnerEnum.${statusOwner}`);

    if (statusOwner && transmittedToAccountant) {
      return `${statusLabel} - ${statusOwnerLabel}`;
    }
    return statusLabel;
  }

  return (
    <Badge
      color={getBadge(status).colorBadge}
      styles={{
        label: {
          textTransform: 'none',
          color: getBadge(status).colorText,
        },
      }}
    >
      {getShuttleSheetStatusLabel(status, statusOwner, transmittedToAccountant)}
    </Badge>
  );
}
