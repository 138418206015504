import {
  Chip,
  Container,
  Group,
  LoadingOverlay,
  Tabs,
  Text,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect, useMemo, useState } from 'react';

import ButtonSave from '../../../../components/ButtonSave';
import { useI18n } from '../../../../contexts/I18nProvider';
import { useModule } from '../../../../contexts/ModuleProvider';
import PermissionService from '../../../../services/PermissionService';
import SecurityRoleService from '../../../../services/SecurityRoleService';
import { ModuleName } from '../../../../types/api/response/module';
import {
  type HfwSecurityRoleResponse,
  type PermissionResponse,
  ScopeEnum,
} from '../../../../types/api/response/role';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
import { roleName } from '../../../../utils/roleName';
import s from '../Roles.module.sass';

export type FormPermissionsValues = {
  permissionScoped: (PermissionResponse & { scope?: ScopeEnum })[];
};

type Props = {
  role: HfwSecurityRoleResponse;
  refetchSecurityRolesData: () => void;
  companyId: string;
};

export default function EditRole({
  role,
  refetchSecurityRolesData,
  companyId,
}: Props) {
  const { t } = useI18n();
  const { getModule } = useModule();
  const [activeTab, setActiveTab] = useState<string>('configure');
  const charteredAccountantModule = getModule(ModuleName.CharteredAccountant);
  const {
    data: permissions,
    isLoading: isPermissionListLoading,
    refetch: refetchPermissionListData,
  } = useQuery({
    queryKey: ['PermissionService.list'],
    queryFn: () => PermissionService.list(),
  });

  const form = useForm<FormPermissionsValues>({
    initialValues: {
      permissionScoped: [],
    },
  });

  const tabs = useMemo(
    () => [
      {
        value: 'configure',
        label: t('permissions.configure.title'),
        isDisplayed: true,
      },
      {
        value: 'validate',
        label: t('permissions.validate.title'),
        isDisplayed: true,
      },
      {
        value: 'authorized',
        label: t('permissions.authorized.title'),
        isDisplayed: true,
      },
      {
        value: 'user',
        label: t('permissions.user.title'),
        isDisplayed: true,
      },
      {
        value: 'accountant',
        label: t('permissions.accountant.title'),
        isDisplayed: charteredAccountantModule?.active,
      },
    ],
    [permissions, isPermissionListLoading, charteredAccountantModule]
  );

  const { mutate: update, isLoading: isUpdateSecurityRoleLoading } =
    useMutation({
      mutationFn: (variables: {
        permissionScoped:
          | { permissionId: string; scope: string | undefined }[]
          | undefined;
      }) => SecurityRoleService.update(companyId, role?.id, variables),
      onSuccess: () => {
        refetchPermissionListData();
        refetchSecurityRolesData();
        showNotification({
          id: `update-security-role-${role?.id}-successful`,
          title: t('w.success'),
          message: `${t('w.role')} ${role ? roleName(t, role.name) : ''} ${t(
            'w.updated'
          ).toLowerCase()}`,
          color: 'green',
          icon: <IconCheck />,
        });
        modals.closeAll();
      },
      onError: (error) =>
        showNotification({
          id: `update-security-role-${role?.id}-error`,
          title: t('w.error'),
          message: handleErrorMessage(error, t),
          color: 'red',
          icon: <IconX />,
        }),
    });

  useEffect(() => {
    if (role && permissions) {
      const permissionScoped = permissions.map((permission) => ({
        ...permission,
        scope: role.permissions?.find(
          (item) => item.permission.id === permission.id
        )?.scope,
      }));
      form.setFieldValue('permissionScoped', permissionScoped);
    }
  }, [permissions, role]);

  function handleUpdateSecurityRoleSubmit(values: FormPermissionsValues) {
    if (values.permissionScoped) {
      const data = values.permissionScoped
        .filter((item) => item.scope)
        .map((item) => {
          return {
            permissionId: item.id,
            scope: item.scope,
          };
        });
      update({ permissionScoped: data });
    }
  }

  function setPermissionScope(
    permission: PermissionResponse & { scope?: ScopeEnum },
    index: number
  ) {
    if (permission.scope !== undefined) {
      form.setFieldValue(`permissionScoped.${index}.scope`, undefined);
    }
  }

  return (
    <form
      onSubmit={form.onSubmit((values) =>
        handleUpdateSecurityRoleSubmit(values)
      )}
    >
      <ButtonSave />
      <Tabs
        mt="md"
        value={activeTab}
        onChange={(value) => setActiveTab(value as string)}
      >
        <Tabs.List>
          {tabs
            .filter((tab) => tab.isDisplayed)
            .map((tab, index) => (
              <Tabs.Tab key={`tab-${tab.value}-${index}`} value={tab.value}>
                {tab.label}
              </Tabs.Tab>
            ))}
        </Tabs.List>
        {tabs
          .filter((tab) => tab.isDisplayed)
          .map((tab, index) => (
            <Tabs.Panel
              key={`tab-panel${tab.value}-${index}`}
              py={'md'}
              px={'xs'}
              value={tab.value}
              className={s.tabPanel}
            >
              <Container fluid>
                <LoadingOverlay
                  visible={
                    isPermissionListLoading || isUpdateSecurityRoleLoading
                  }
                />
                {form.values.permissionScoped
                  .filter((item) => item.name === tab.value)
                  .map((permission) => {
                    const index = permissions?.findIndex(
                      (item) => item.id === permission.id
                    ) as number;
                    return (
                      <Group
                        justify={'space-between'}
                        key={`${permission.id}`}
                        py={'xs'}
                        styles={{
                          root: { borderBottom: '0.5px grey solid' },
                        }}
                      >
                        <Text fz={'sm'} fw={'bold'} c={'dimmed'}>
                          {t(`permissions.${permission.key}`)}
                        </Text>
                        <Group gap={4} wrap={'nowrap'}>
                          <Chip.Group
                            multiple={false}
                            {...form.getInputProps(
                              `permissionScoped.${index}.scope`
                            )}
                          >
                            <Chip
                              size="xs"
                              value={ScopeEnum.MINE}
                              onClick={() => {
                                setPermissionScope(permission, index);
                              }}
                              disabled={
                                permission.key !== 'authorized.readplanning'
                              }
                            >
                              {t('w.user')}
                            </Chip>
                            <Chip
                              size="xs"
                              value={ScopeEnum.TEAM}
                              onClick={() => {
                                setPermissionScope(permission, index);
                              }}
                              disabled={
                                permission.key !== 'authorized.readplanning'
                              }
                            >
                              {t('w.team')}
                            </Chip>
                            <Chip
                              size="xs"
                              value={ScopeEnum.ALL}
                              onClick={() => {
                                setPermissionScope(permission, index);
                              }}
                            >
                              {t('w.all')}
                            </Chip>
                            <Chip
                              size="xs"
                              radius="xs"
                              value={undefined}
                              onClick={() => {
                                setPermissionScope(permission, index);
                              }}
                              classNames={{
                                label: s.chipLabel,
                              }}
                            >
                              <IconX
                                color="red"
                                style={{ width: '70%', height: '70%' }}
                              />
                            </Chip>
                          </Chip.Group>
                        </Group>
                      </Group>
                    );
                  })}
              </Container>
            </Tabs.Panel>
          ))}
      </Tabs>
    </form>
  );
}
