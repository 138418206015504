import type {
  DailyWorkDailyTemplateResponse,
  DailyWorkWeeklyTemplateResponse,
} from '../api/response/dailyWork';

export function isDailyTemplate(
  template?: DailyWorkDailyTemplateResponse | DailyWorkWeeklyTemplateResponse
): template is DailyWorkDailyTemplateResponse {
  return (
    Boolean(template) &&
    (template as DailyWorkDailyTemplateResponse).period !== undefined
  );
}

export function isWeeklyTemplate(
  template?: DailyWorkDailyTemplateResponse | DailyWorkWeeklyTemplateResponse
): template is DailyWorkWeeklyTemplateResponse {
  return (
    Boolean(template) &&
    (template as DailyWorkWeeklyTemplateResponse).weeklyPeriods !== undefined
  );
}
