import { Button, Title } from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconCirclePlus } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import React, { useMemo } from 'react';

import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import ScreenWrapper from '../../../core/layouts/components/ScreenWrapper';
import useCompany from '../../../hooks/useCompany';
import useFetchUsers from '../../../hooks/useFetchUsers';
import ShuttleSheetService from '../../../services/ShuttleSheetService';
import { ShuttleSheetType } from '../../../types/types';
import ShuttleSheetTypeForm from './components/ShuttleSheetTypeForm';
import ShuttleSheetTypeTable from './components/ShuttleSheetTypeTable';

export default function ShuttleSheetTypes() {
  const { t } = useI18n();
  const { user } = useAuth();
  const { id: companyId } = useCompany(user);
  const { roles } = useFetchUsers(companyId);

  const actionButtons = useMemo(
    () => [
      <Button
        key={'add-shuttle-sheet-type-button'}
        leftSection={<IconCirclePlus />}
        onClick={handleOpenAddShuttleSheetTypeModal}
      >
        {t('w.add')}
      </Button>,
    ],
    []
  );

  const {
    data: shuttleSheetTypeListData,
    isLoading: isShuttleSheetTypeListLoading,
    refetch,
  } = useQuery({
    queryKey: ['ShuttleSheetService.shuttleSheetTypeList', companyId],
    queryFn: () => ShuttleSheetService.shuttleSheetTypeList(companyId),
  });

  const shuttleSheetTypeList: ShuttleSheetType[] = useMemo(() => {
    if (!shuttleSheetTypeListData) {
      return [];
    }
    return shuttleSheetTypeListData;
  }, [shuttleSheetTypeListData]);

  function handleOpenAddShuttleSheetTypeModal() {
    const initialValues = {
      id: '',
      created: '',
      updated: '',
      companyId: '',
      name: '',
      deletable: false,
      transmittedToAccountant: false,
      usableBy: [],
      fields: [],
      availableChoiceName: '',
    };

    modals.open({
      modalId: 'add-shuttle-sheet-type-modal',
      title: (
        <Title size={'h3'} component="p">{`${t('w.add')} ${t(
          'w.shuttleSheetType'
        ).toLowerCase()}`}</Title>
      ),
      size: '90%',
      children: (
        <ShuttleSheetTypeForm
          shuttleSheetType={initialValues}
          roles={roles}
          companyId={companyId}
          refetch={refetch}
          isEditable
        />
      ),
    });
  }

  return (
    <ScreenWrapper
      title={t('w.shuttleSheetTypes')}
      actionButtons={actionButtons}
      paper
    >
      <ShuttleSheetTypeTable
        rows={shuttleSheetTypeList}
        isLoading={isShuttleSheetTypeListLoading}
        companyId={companyId}
        roles={roles}
        refetch={refetch}
      />
    </ScreenWrapper>
  );
}
