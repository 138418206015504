import type { YearMonth } from '../types/api/common';
import {
  CompanyAccountantInfoClientRequest,
  EndMonthPrepareRequest,
} from '../types/api/payload/accountant';
import type {
  CompanyAccountantInfoViewClient,
  ManageAccountant,
} from '../types/api/response/accountant';
import type { EndOfMonthValidation } from '../types/api/response/endMonth';
import { MS_CHARTERED_ACCOUNTANT_ENDPOINT } from '../variables/GlobalVariables';
import request from './AxiosClient';

function accountantsList(
  companyId: string
): Promise<CompanyAccountantInfoViewClient[]> {
  return request
    .get(
      `${MS_CHARTERED_ACCOUNTANT_ENDPOINT}/v2/chartered-accountant/client/${companyId}/my-accountants`
    )
    .then((res) => res?.data);
}

function removeAccountantAccess(
  accountantCompanyId: string,
  companyId: string
): Promise<CompanyAccountantInfoViewClient[]> {
  return request
    .delete(
      `${MS_CHARTERED_ACCOUNTANT_ENDPOINT}/v2/chartered-accountant/client/${companyId}/accountant/remove/${accountantCompanyId}`
    )
    .then((res) => res?.data);
}

function inviteAccountantToAccess(
  companyId: string,
  mail: string
): Promise<ManageAccountant> {
  return request
    .post(
      `${MS_CHARTERED_ACCOUNTANT_ENDPOINT}/v2/chartered-accountant/client/${companyId}/accountant/invite?mail=${encodeURIComponent(
        mail
      )}`
    )
    .then((res) => res?.data);
}

function invitationAccountantHistory(
  companyId: string
): Promise<ManageAccountant[]> {
  return request
    .get(
      `${MS_CHARTERED_ACCOUNTANT_ENDPOINT}/v2/chartered-accountant/client/${companyId}/accountant/history`
    )
    .then((res) => res?.data);
}

function validateCustomerAccountantInvitation(
  companyId: string,
  validationId: string,
  status: boolean | null
): Promise<ManageAccountant> {
  let url = `${MS_CHARTERED_ACCOUNTANT_ENDPOINT}/v2/chartered-accountant/client/${companyId}/accountant/validation/${validationId}`;
  if (status !== null) {
    url = `${url}?status=${status}`;
  }

  return request.post(url).then((res) => res?.data);
}

function updateCustomerUsersPayslipStatusEndOfMonth(
  companyId: string,
  data: EndMonthPrepareRequest[]
): Promise<EndOfMonthValidation[]> {
  return request
    .patch(
      `${MS_CHARTERED_ACCOUNTANT_ENDPOINT}/v2/chartered-accountant/client/${companyId}/endMonth/validation`,
      data
    )
    .then((res) => res?.data);
}

function accountantCustomersList(
  companyId: string,
  monthConcerned?: YearMonth
): Promise<CompanyAccountantInfoViewClient[]> {
  let url = `${MS_CHARTERED_ACCOUNTANT_ENDPOINT}/v2/chartered-accountant/office/${companyId}/my-clients`;
  if (Boolean(monthConcerned)) {
    url = `${url}?monthConcerned=${monthConcerned}`;
  }

  return request.get(url).then((res) => res?.data);
}

function updateCustomerCompanyInformation(
  companyId: string,
  payload: CompanyAccountantInfoClientRequest
): Promise<CompanyAccountantInfoViewClient[]> {
  return request.post(
    `${MS_CHARTERED_ACCOUNTANT_ENDPOINT}/v2/chartered-accountant/office/${companyId}/client`,
    payload
  );
}

function assignAccountantToCustomerCompany(
  clientCompanyId: string,
  companyId: string,
  userAccountantId: string,
  divisionId: string | null | undefined,
  generalManager: boolean
): Promise<CompanyAccountantInfoViewClient[]> {
  let url = `${MS_CHARTERED_ACCOUNTANT_ENDPOINT}/v2/chartered-accountant/office/${companyId}/assign/${userAccountantId}/to/${clientCompanyId}?generalManager=${generalManager}`;
  if (divisionId) {
    url = `${url}&divisionId=${divisionId}`;
  }

  return request.post(url).then((res) => res?.data);
}

function unAssignAccountantToCustomerCompany(
  clientCompanyId: string,
  companyId: string,
  userAccountantId: string,
  divisionId: string | null | undefined,
  generalManager: boolean
): Promise<CompanyAccountantInfoViewClient[]> {
  let url = `${MS_CHARTERED_ACCOUNTANT_ENDPOINT}/v2/chartered-accountant/office/${companyId}/unassign/${userAccountantId}/to/${clientCompanyId}?generalManager=${generalManager}`;
  if (divisionId) {
    url = `${url}?divisionId=${divisionId}`;
  }

  return request.delete(url).then((res) => res?.data);
}

const CharteredAccountantService = {
  accountantsList,
  removeAccountantAccess,
  inviteAccountantToAccess,
  invitationAccountantHistory,
  validateCustomerAccountantInvitation,
  updateCustomerUsersPayslipStatusEndOfMonth,
  accountantCustomersList,
  updateCustomerCompanyInformation,
  assignAccountantToCustomerCompany,
  unAssignAccountantToCustomerCompany,
};

export default CharteredAccountantService;
