import { Button, Group } from '@mantine/core';
import { FileWithPath } from '@mantine/dropzone';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconTableImport, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import * as React from 'react';
import { useState } from 'react';

import ButtonCancel from '../../../../components/ButtonCancel';
import CustomDropzone from '../../../../components/CustomDropzone/CustomDropzone';
import InformationMessage from '../../../../components/InformationMessage';
import { useI18n } from '../../../../contexts/I18nProvider';
import CompanyAccountantService from '../../../../services/CompanyAccountantService';
import { ImportClientsResponse } from '../../../../types/api/response/accountant';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
import ImportClientsResult from './ImportClientsResult';

type FormValues = {
  files: FileWithPath[];
};
type Props = {
  accountantCompanyId: string;
  refresh: () => void;
  onClose: () => void;
};

export default function ImportClientsForm({
  accountantCompanyId,
  refresh,
  onClose,
}: Props) {
  const { t } = useI18n();
  const [importResult, setImportResult] =
    useState<ImportClientsResponse | null>(null);

  const { mutate: importEmployees, isLoading } = useMutation({
    mutationFn: (file: FileWithPath) =>
      CompanyAccountantService.importClients(accountantCompanyId, file),
    onSuccess: (data) => {
      refresh();
      showNotification({
        id: 'accountant-import-clients-success',
        message: t('w.success'),
        icon: <IconCheck />,
        color: 'green',
      });
      setImportResult(data);
    },
    onError: (error) =>
      showNotification({
        id: 'accountant-import-clients-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        icon: <IconX />,
        color: 'red',
      }),
  });

  const form = useForm<FormValues>({
    initialValues: {
      files: [],
    },
  });

  function resetFiles() {
    form.setFieldValue('files', []);
  }

  return (
    <>
      {importResult ? (
        <ImportClientsResult importResult={importResult} />
      ) : (
        <form
          onSubmit={form.onSubmit((values) => importEmployees(values.files[0]))}
        >
          <InformationMessage
            message={t('companyAccountant.import.warningMessage')}
            variant="warning"
          />
          <CustomDropzone
            files={form.values.files}
            onDrop={(files) => form.setFieldValue('files', files)}
            mimeType={'excel'}
            resetFiles={resetFiles}
          />

          <Group justify={'flex-end'} mt={'xl'}>
            <ButtonCancel onClose={onClose} isLoading={isLoading} />
            <Button
              loading={isLoading}
              size="sm"
              type={'submit'}
              leftSection={<IconTableImport />}
              disabled={!form.values.files.length}
            >
              {t('w.import')}
            </Button>
          </Group>
        </form>
      )}
    </>
  );
}
