import type { DayOfWeek, HourMinute } from '../common';
import type { LeaveStatusResponse } from './leave';
import type { LeaveTypeResponse } from './leaveType';
import type { PaidFeature } from './user';

type DailyWorkTemplateResponse = {
  id: string;
  name: string;
  creatorId: string;
  shared: boolean;
};

export type DailyWorkDailyTemplateResponse = DailyWorkTemplateResponse & {
  period: PeriodTemplate[];
};

export type DailyWorkWeeklyTemplateResponse = DailyWorkTemplateResponse & {
  weeklyPeriods: WeeklyPeriod[];
};

export type PeriodTemplate = {
  startDate: HourMinute;
  endDate: HourMinute;
};

export type WeeklyPeriod = {
  day: DayOfWeek;
  period: PeriodTemplate[];
};

export type DailyWorkDetails = {
  id: string;
  user: UserDetails;
  date: number;
  leaves: LeaveDetails[];
  overtime: number;
  comment?: string;
  status: DailyWorkDetailsStatus;
  workingTimeDay: number;
  divisionConcerned: string;
  incrementCounter: boolean;
};

export enum DailyWorkDetailsStatus {
  WAITING = 'WAITING',
  VALID = 'VALID',
}

export type UserDetails = {
  id: string;
  firstname: string;
  lastname: string;
  fullname: string;
  division: DivisionDetails;
  n1?: string;
  n2?: string;
  divisionN1?: string;
  divisionN2?: string;
  divisionAssistant?: string;
  fixedPriceManager: boolean;
  paidFeatures: PaidFeature[];
};

export type DivisionDetails = {
  id: string;
  name: string;
  n1?: string;
  n2?: string;
  assistant?: string;
};

export type LeaveDetails = {
  id?: string;
  period: PeriodDetails;
  nbMinutes?: number;
  type?: LeaveTypeResponse;
  leaveConflict?: LeaveDetails[];
  status?: LeaveStatusResponse;
  validatorFullname?: string;
  divisionConcerned?: string;
  duration?: number;
};

export type PeriodDetails = {
  start: number;
  end: number;
};

export type ImportDailyWorkWarningResponse = {
  sheetname: string;
  key: string;
  col: string;
  row: number;
};
