import { Flex, Select } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';

import LabelText from '../../../../../../components/LabelText/LabelText';
import { useI18n } from '../../../../../../contexts/I18nProvider';
import CounterTypeService from '../../../../../../services/CounterTypeService';
import ModuleWithParamsService from '../../../../../../services/ModuleWithParamsService';
import VariablePayrollService from '../../../../../../services/VariablePayrollService';
import type {
  DailyworkModuleResponse,
  DailyWorkParams,
  ModuleDetails,
  VariablePayroll,
} from '../../../../../../types/api/response/module';
import { getTranslatedKey } from '../../../../../../utils/counterTypesFormatter';

type Props = {
  companyId: string;
  apiUrl: string;
  module: ModuleDetails;
  refetchModule: () => void;
};

export default function VariablePayrollDailywork({
  companyId,
  apiUrl,
  module,
  refetchModule,
}: Props) {
  const { t } = useI18n();
  const [moduleParams, setModuleParams] = useState(
    module?.configuration?.params as DailyWorkParams
  );
  const { data: variablesPayrollData, refetch: refetchVariablesPayrollData } =
    useQuery({
      queryKey: ['VariableParollService.getVariables', companyId],
      queryFn: () => VariablePayrollService.getVariables(companyId),
    });

  const { data: counterTypes } = useQuery({
    enabled: !!companyId,
    queryKey: ['CounterTypeService.list', companyId],
    queryFn: () => CounterTypeService.list(companyId),
  });

  const variablesPayroll: VariablePayroll[] = useMemo(() => {
    if (!variablesPayrollData) {
      return [];
    }

    if (variablesPayrollData.variablesPayroll) {
      return variablesPayrollData.variablesPayroll;
    }

    return [];
  }, [variablesPayrollData]);

  function onChangeVariable(value: string | null, label: string) {
    let payload: any = {};
    if (label === 'workedHours') {
      payload = {
        active: true,
        ...moduleParams,
        variableIdWorkingTime: value,
      };
    } else if (label === 'workedDays') {
      payload = {
        active: true,
        ...moduleParams,
        variableIdWorkingDays: value,
      };
    } else {
      payload = {
        active: true,
        ...moduleParams,
        variableIdOvertime: value,
      };
    }
    updateModuleParamsDailywork({
      apiUrl: apiUrl,
      payload: payload,
    });
  }

  function onChangeOvertimeCounterType(counterTypeId: string | null) {
    updateModuleParamsDailywork({
      apiUrl: apiUrl,
      payload: {
        active: true,
        ...moduleParams,
        counterTypeIdOvertime: counterTypeId,
      },
    });
  }

  const { mutate: updateModuleParamsDailywork } = useMutation({
    mutationFn: (variables: { apiUrl: string; payload: any }) =>
      ModuleWithParamsService.updateModuleParams(
        variables.apiUrl,
        companyId,
        variables.payload
      ),
    onSuccess: (data: DailyworkModuleResponse) => {
      refetchVariablesPayrollData();
      refetchModule();
      setModuleParams(data.params);
      showNotification({
        id: `success-update-module-params`,
        message: t('w.success'),
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: () =>
      showNotification({
        id: `error-update-module-params`,
        title: t('w.error'),
        message: t('error.updateModule'),
        color: 'red',
        icon: <IconX />,
      }),
  });

  return (
    <Flex direction="column" gap="md">
      <Select
        data={variablesPayroll.map((variable) => {
          return {
            value: variable.id ? variable.id : '',
            label: variable.label,
          };
        })}
        label={<LabelText text={t('w.workedHours')} />}
        disabled={variablesPayroll.length === 0}
        onChange={(value) => onChangeVariable(value, 'workedHours')}
        clearable
        value={
          moduleParams?.variableIdWorkingTime
            ? moduleParams.variableIdWorkingTime
            : ''
        }
        onClear={() => onChangeVariable(null, 'workedHours')}
      />
      <Select
        data={variablesPayroll.map((variable) => {
          return {
            value: variable.id ? variable.id : '',
            label: variable.label,
          };
        })}
        label={<LabelText text={t('w.overtime')} />}
        disabled={variablesPayroll.length === 0}
        onChange={(value) => onChangeVariable(value, 'overtime')}
        clearable
        value={
          moduleParams?.variableIdOvertime
            ? moduleParams.variableIdOvertime
            : ''
        }
        onClear={() => onChangeVariable(null, 'overtime')}
      />
      <Select
        data={variablesPayroll.map((variable) => {
          return {
            value: variable.id ? variable.id : '',
            label: variable.label,
          };
        })}
        label={<LabelText text={t('w.workedDays')} />}
        disabled={variablesPayroll.length === 0}
        onChange={(value) => onChangeVariable(value, 'workedDays')}
        clearable
        value={
          moduleParams?.variableIdWorkingDays
            ? moduleParams.variableIdWorkingDays
            : ''
        }
        onClear={() => onChangeVariable(null, 'workedDays')}
      />
      <Select
        data={counterTypes
          ?.filter((item) => item.counterType.active)
          .map((counterType) => {
            return {
              value: counterType.counterType.id,
              label: getTranslatedKey(t, counterType.counterType.key),
            };
          })}
        label={<LabelText text={t('w.overtimeCounter')} />}
        disabled={!counterTypes?.length}
        onChange={(value) => onChangeOvertimeCounterType(value)}
        clearable
        value={
          moduleParams?.counterTypeIdOvertime
            ? moduleParams.counterTypeIdOvertime
            : ''
        }
        onClear={() => onChangeOvertimeCounterType(null)}
      />
    </Flex>
  );
}
