import { FilterOption, Options, Table } from '@ckprivate/ckf-ui';
import { ActionIcon, Button, Flex, Group, Text, Title } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconCheck,
  IconEdit,
  IconFolders,
  IconHomeCog,
  IconTableDown,
  IconTableImport,
  IconUserHexagon,
  IconUsers,
  IconX,
} from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { DataTableColumn } from 'mantine-datatable';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ActiveInactiveBadge from '../../../../components/badges/ActiveInactiveBadge';
import CustomModal from '../../../../components/CustomModal';
import CustomTooltip from '../../../../components/CustomTooltip';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import useCompany from '../../../../hooks/useCompany';
import CharteredAccountantService from '../../../../services/CharteredAccountantService';
import CompanyAccountantService from '../../../../services/CompanyAccountantService';
import type {
  CharteredAccountantView,
  CompanyAccountantInfoViewClient,
} from '../../../../types/api/response/accountant';
import type { CompanyResponse } from '../../../../types/api/response/company';
import { DocumentPropertySpace } from '../../../../types/types';
import { milliToFullDateString } from '../../../../utils/format';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
import {
  getFilter,
  getHiddenColumns,
  saveFilter,
  saveHiddenColumns,
} from '../../../../utils/optionsPreferences';
import saveDownloadedfile from '../../../../utils/saveDownloadedFile';
import Employees from '../../../company/employees/Employees';
import DocumentsTable from '../../../documents/components/DocumentsTable';
import CompanyInfoWrapper from '../../components/CompanyInfoWrapper';
import AccountantConfigModal from './AccountantConfigModal';
import CustomerEditForm from './CustomerEditForm';
import ImportClientsForm from './ImportClientsForm';

const componentName = 'AccountantCustomersTable';

type Props = {
  isPaymentAccountActive: boolean;
};

export default function AccountantCustomersTable({
  isPaymentAccountActive,
}: Props) {
  const { t, lang } = useI18n();
  const { user } = useAuth();
  const { id: companyId } = useCompany(user);
  const navigate = useNavigate();

  const [
    accountantConfigModalOpened,
    {
      open: handleAccountantConfigModalOpen,
      close: handleAccountantConfigClose,
    },
  ] = useDisclosure(false);
  const [accountantsToConfigure, setAccountantsToConfigure] =
    useState<CompanyAccountantInfoViewClient | null>(null);
  const [customerCompany, setCustomerCompany] =
    useState<CompanyResponse | null>(null);
  const matches = useMediaQuery('(min-width: 1441px)');
  const { mutate: exportClients, isLoading: isExportLoading } = useMutation({
    mutationFn: (payload: { selectValid: boolean; selectInvalid: boolean }) =>
      CompanyAccountantService.exportClients(
        companyId,
        payload.selectValid,
        payload.selectInvalid
      ),
    onSuccess: (data) => {
      saveDownloadedfile(data);
    },
    onError: (error) =>
      showNotification({
        id: 'export-employees-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        icon: <IconCheck />,
        color: 'red',
      }),
  });

  function onExportClick() {
    let selectValid = !!options?.filter
      ?.find((item) => item.accessor === 'active')
      ?.selectedChoices?.find((item) => item.label === t('w.active'));

    let selectInvalid = !!options?.filter
      ?.find((item) => item.accessor === 'active')
      ?.selectedChoices?.find((item) => item.label === t('w.inactive'));

    if (!selectValid && !selectInvalid) {
      selectValid = true;
      selectInvalid = true;
    }

    exportClients({ selectValid, selectInvalid });
  }

  function importClientsClick() {
    modals.open({
      modalId: 'accountant-import-clients-modal',
      title: (
        <Title size={'h3'} component="p">
          {t('w.bulkClientsCreate')}
        </Title>
      ),
      size: '90%',
      children: (
        <ImportClientsForm
          accountantCompanyId={companyId}
          refresh={refetchAccountantCustomersData}
          onClose={() => modals.close('accountant-import-clients-modal')}
        />
      ),
    });
  }

  const rightCustomActions = useMemo(() => {
    return [
      <CustomTooltip
        key={'import-export-customer-company'}
        label={t('w.yourCompanyBillingAccountIsNotActivated')}
        position={'left'}
        disabled={isPaymentAccountActive}
      >
        <Group>
          <Button
            key={'import-button'}
            leftSection={<IconTableImport />}
            bg={'white'}
            variant={'subtle'}
            disabled={!isPaymentAccountActive}
            onClick={importClientsClick}
          >
            {t('w.bulkClientsCreate')}
          </Button>
          <Button
            key={'export-button'}
            leftSection={<IconTableDown />}
            variant={'filled'}
            disabled={!isPaymentAccountActive}
            onClick={onExportClick}
            loading={isExportLoading}
          >
            {t('w.export')}
          </Button>
        </Group>
      </CustomTooltip>,
    ];
  }, [isPaymentAccountActive, isExportLoading]);

  const {
    data: accountantCustomersData,
    isLoading: isAccountantCustomersDataLoading,
    refetch: refetchAccountantCustomersData,
  } = useQuery({
    queryKey: ['CharteredAccountantService.accountantCustomersList', companyId],
    queryFn: () =>
      CharteredAccountantService.accountantCustomersList(companyId),
  });

  const rows: CompanyAccountantInfoViewClient[] = useMemo(() => {
    if (!accountantCustomersData) {
      return [];
    }

    if (accountantsToConfigure?.clientCompanyId) {
      const _updatedAccountantsToConfigure = accountantCustomersData.find(
        (item) =>
          item.clientCompanyId === accountantsToConfigure.clientCompanyId
      );

      if (_updatedAccountantsToConfigure) {
        setAccountantsToConfigure(_updatedAccountantsToConfigure);
      }
    }

    return accountantCustomersData;
  }, [accountantCustomersData]);

  const { mutate: updateCustomerCompany } = useMutation({
    mutationFn: (variables: {
      accountant: CharteredAccountantView;
      active: boolean;
      clientCompanyId: string;
    }) =>
      CharteredAccountantService.updateCustomerCompanyInformation(
        companyId,
        variables
      ),
    onSuccess: () => {
      refetchAccountantCustomersData();
      showNotification({
        id: 'update-customer-company-status-successful',
        message: t('w.success'),
        color: 'green',
        icon: <IconCheck />,
      });
      modals.closeAll();
    },
    onError: (error) =>
      showNotification({
        id: 'update-customer-company-status-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function onConfigureUsersClick(customerCompany: CompanyResponse) {
    modals.open({
      modalId: 'accountant-configure-clients-users-modal',
      size: '90%',
      children: (
        <Employees company={customerCompany} importModalInPortal={false} />
      ),
    });
  }

  const columns: DataTableColumn<CompanyAccountantInfoViewClient>[] = useMemo(
    () => [
      {
        accessor: 'customerCompany.name',
        title: t('w.companyName'),
        sortable: true,
        ellipsis: true,
        render: ({ customerCompany }) => (
          <CompanyInfoWrapper
            companyId={customerCompany.id}
            companyName={customerCompany.name}
          />
        ),
      },
      {
        accessor: 'active',
        title: t('w.status'),
        sortable: true,
        ellipsis: true,
        render: ({ active }) => <ActiveInactiveBadge value={active} />,
      },
      {
        accessor: 'customerCompany.address',
        title: t('w.companyAddress'),
        sortable: true,
        ellipsis: true,
        render: ({ customerCompany }) => (
          <Flex direction={'column'}>
            <Group gap={4}>
              <Text fz={'xs'} fw={'bold'}>
                {customerCompany?.address?.address1}
              </Text>
              {customerCompany?.address?.address2 && (
                <Text fz={'xs'} fw={'bold'}>
                  {' '}
                  - {customerCompany.address.address2}
                </Text>
              )}
            </Group>
            <Group gap={4}>
              <Text c={'dimmed'} fz={'xs'}>
                {customerCompany?.address?.zipCode}
              </Text>
              <Text c={'dimmed'} fz={'xs'}>
                {customerCompany?.address?.city}
              </Text>
            </Group>
          </Flex>
        ),
      },
      {
        accessor: 'accountant',
        title: t('w.infoAccountantShowToClient'),
        sortable: false,
        ellipsis: true,
        render: ({ accountant }) => (
          <Flex direction={'column'}>
            <Text fz={'xs'} fw={'bold'}>
              {accountant.name} - {accountant.address}
            </Text>
            <Group gap={4}>
              <Text c={'dimmed'} fz={'xs'}>
                {accountant.mail}
              </Text>
              {accountant.phone && (
                <Text c={'dimmed'} fz={'xs'}>
                  {` / ${accountant.phone}`}
                </Text>
              )}
            </Group>
          </Flex>
        ),
      },
      {
        accessor: 'customerCompany.mail',
        title: t('w.companyMail'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'customerCompany.phone',
        title: t('w.phone'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'customerCompany.created',
        title: t('w.created'),
        sortable: true,
        ellipsis: true,
        render: ({ customerCompany }) =>
          customerCompany.created
            ? milliToFullDateString(customerCompany.created)
            : '',
      },
      {
        accessor: 'customerCompany.updated',
        title: t('w.updated'),
        sortable: true,
        ellipsis: true,
        render: ({ customerCompany }) =>
          customerCompany.updated
            ? milliToFullDateString(customerCompany.updated)
            : '',
      },
      {
        accessor: 'actions',
        title: t('w.actions'),
        textAlign: 'right',
        sortable: false,
        render: (row) => (
          <Group gap={6} justify="end" wrap={'nowrap'}>
            {/* TODO
            <Button
              onClick={() => {
                modals.open({
                  modalId: 'last-syncrho',
                  title: 'Test',
                  fullScreen: true,
                  children: (
                    <LastSynchroOpenpaye companyId={row.clientCompanyId} />
                  ),
                });
              }}
            >
              Dernière synchro
            </Button>*/}
            <CustomTooltip
              label={`${t('w.configure')} ${t(
                'w.payrollOfficers'
              ).toLowerCase()}`}
            >
              <ActionIcon
                onClick={() => {
                  handleAccountantConfigModalOpen();
                  setAccountantsToConfigure(row);
                }}
                size="sm"
                color="green"
                variant={'subtle'}
              >
                <IconUserHexagon size={16} />
              </ActionIcon>
            </CustomTooltip>
            <CustomTooltip
              label={`${t('w.configure')} ${t(
                'w.customerCompany'
              ).toLowerCase()}`}
            >
              <ActionIcon
                size="sm"
                color="green"
                variant={'subtle'}
                onClick={() => navigate(`/company/${row.clientCompanyId}/home`)}
              >
                <IconHomeCog size={16} />
              </ActionIcon>
            </CustomTooltip>
            <CustomTooltip
              label={`${t('w.configure')} ${t('w.employees').toLowerCase()}`}
            >
              <ActionIcon
                size="sm"
                color="blue"
                variant={'subtle'}
                onClick={() => onConfigureUsersClick(row.customerCompany)}
              >
                <IconUsers size={16} />
              </ActionIcon>
            </CustomTooltip>
            <CustomTooltip label={t('w.sharedDocuments')}>
              <ActionIcon
                size="sm"
                color="blue"
                variant={'subtle'}
                onClick={() => setCustomerCompany(row.customerCompany)}
              >
                <IconFolders size={16} />
              </ActionIcon>
            </CustomTooltip>
            <CustomTooltip label={t('w.edit')}>
              <ActionIcon
                variant={'subtle'}
                size="sm"
                color="blue"
                onClick={() => openEditModal(row)}
              >
                <IconEdit size={16} />
              </ActionIcon>
            </CustomTooltip>
          </Group>
        ),
      },
    ],
    [matches]
  );

  function openEditModal(accountants: CompanyAccountantInfoViewClient) {
    modals.open({
      modalId: `customer-${accountants.clientCompanyId}-edit-modal`,
      title: (
        <Title size={'h3'} component="p">
          {t('w.edit')}
        </Title>
      ),
      size: '90%',
      children: (
        <CustomerEditForm
          accountants={accountants}
          updateCustomerCompanyStatus={updateCustomerCompany}
        />
      ),
    });
  }

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    search: {
      accessor: 'customerCompany.name',
      label: t('w.name'),
    },
    hiddenColumns: getHiddenColumns({ componentName }),
    updateHiddenColumns,
    filter: getFilter({
      componentName,
      filter: [
        {
          accessor: 'active',
          label: t('w.status'),
          choices: [
            { value: 'true', label: t('w.active') },
            { value: 'false', label: t('w.inactive') },
          ],
          selectedChoices: [],
        },
      ],
    }),
    updateFilter,
  });

  function updateFilter(newFilter: FilterOption[]) {
    const _options = { ...options };
    _options.filter = newFilter;
    saveFilter({ componentName, filter: newFilter });
    setOptions(_options);
  }

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  return (
    <>
      <Table
        pinFirstColumn
        pinLastColumn
        idAccessor={'customerCompany.id'}
        options={options}
        columns={columns}
        rows={rows}
        lang={lang}
        fetching={isAccountantCustomersDataLoading}
        height={'calc(100vh - 220px)'}
        rightCustomActions={rightCustomActions}
      />
      <AccountantConfigModal
        opened={accountantConfigModalOpened}
        onClose={handleAccountantConfigClose}
        accountantsToConfigure={accountantsToConfigure}
        refetchAccountantCustomersData={refetchAccountantCustomersData}
      />
      <CustomModal
        opened={customerCompany !== null}
        onClose={() => setCustomerCompany(null)}
        title={`${t('w.sharedDocuments')} - ${customerCompany?.name}`}
        fullScreen
      >
        {customerCompany !== null && (
          <DocumentsTable
            hasPermissionToEditSpace={true}
            space={DocumentPropertySpace.ACCOUNTANT}
            userId={user.id}
            companyId={customerCompany.id}
            accountantCompanyId={companyId}
          />
        )}
      </CustomModal>
    </>
  );
}
