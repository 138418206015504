import {
  Blockquote,
  Button,
  Grid,
  Group,
  Loader,
  SimpleGrid,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconBasketDollar,
  IconBuilding,
  IconCalendarTime,
  IconChartLine,
  IconCheck,
  IconDatabase,
  IconLock,
  IconUserDollar,
  IconUserPlus,
  IconX,
} from '@tabler/icons-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { ReactNode, useMemo } from 'react';

import ActiveInactiveBadge from '../../../components/badges/ActiveInactiveBadge';
import { useI18n } from '../../../contexts/I18nProvider';
import usePaymentAccounts from '../../../hooks/usePaymentAccounts';
import useStripeConfig from '../../../hooks/useStripeConfig';
import PaymentAccountService from '../../../services/PaymentAccountService';
import WhiteLabelService from '../../../services/WhiteLabelService';
import type { PaymentAccount } from '../../../types/types';
import handleErrorMessage from '../../../utils/handleErrorMessage';
import s from '../PaymentAccount.module.sass';
import CompaniesOverview from './Overview/CompaniesOverview';
import InvoicesOverview from './Overview/InvoicesOverview';
import PaymentModeOverview from './Overview/PaymentModeOverview';
import WhiteLabelOverview from './Overview/WhiteLabelOverview';

type Stat = {
  value: any;
  label: string;
  icon: ReactNode;
  color: string;
};

type Props = {
  paymentAccount: PaymentAccount;
};

export default function Dashboard({ paymentAccount }: Props) {
  const { t } = useI18n();
  const queryClient = useQueryClient();
  const { queryKey } = usePaymentAccounts();
  const { isLoading: stripeConfigLoading } = useStripeConfig(paymentAccount.id);
  const { data: whiteLabel } = useQuery({
    queryKey: ['WhiteLabelService.getWhiteLabel', paymentAccount.id],
    queryFn: () => WhiteLabelService.getWhiteLabel(paymentAccount.id),
  });
  const { mutate: updateStatus, isLoading } = useMutation({
    mutationFn: (variables: { newStatus: boolean; paymentAccountId: string }) =>
      PaymentAccountService.updateStatus(
        variables.newStatus,
        variables.paymentAccountId
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      showNotification({
        id: 'updatePaymentAccountStatus',
        title: t('w.success'),
        message: paymentAccount.active
          ? t('w.theAccountHasBeenDeactivated')
          : t('w.theAccountHasBeenActivated'),
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: (error) => {
      showNotification({
        id: 'updatePaymentAccountStatus',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        icon: <IconX />,
        color: 'red',
      });
    },
  });
  const stats: Stat[] = useMemo(() => {
    const result: Stat[] = [];
    result.push({
      value: paymentAccount.companies.filter((elt) => elt.active).length,
      label: t('w.activeCompaniesNb'),
      icon: <IconBuilding />,
      color: 'orange',
    });
    result.push({
      value: paymentAccount.nbActiveUsers,
      label: t('w.activeUsers'),
      icon: <IconUserPlus />,
      color: 'pink',
    });
    if (paymentAccount.nbDailyWorkActiveUsers > 0) {
      result.push({
        value: paymentAccount.nbDailyWorkActiveUsers,
        label: t('w.dailyWorkUsersNb'),
        icon: <IconCalendarTime />,
        color: 'grape',
      });
    }
    if (paymentAccount.nbActiveUsersForGma > 0) {
      result.push({
        value: paymentAccount.nbActiveUsersForGma,
        label: t('w.gmaUsersNb'),
        icon: <IconLock />,
        color: 'violet',
      });
    }
    result.push({
      value: `${paymentAccount.totalHT}  € HT`,
      label: t('w.estimatedMonthlyBill'),
      icon: <IconChartLine />,
      color: 'indigo',
    });
    result.push({
      value: `${paymentAccount.properties.unitPrice}€ HT`,
      label: t('w.pricePerMonthPerUser'),
      icon: <IconUserDollar />,
      color: 'blue',
    });
    result.push({
      value: `${paymentAccount.asset} €`,
      label: t('w.asset'),
      icon: <IconBasketDollar />,
      color: 'cyan',
    });
    result.push({
      value: '1 Go',
      label: t('w.storageLimitPerUser'),
      icon: <IconDatabase />,
      color: 'teal',
    });

    return result;
  }, [paymentAccount]);

  const overviews = useMemo(() => {
    const result = [
      <CompaniesOverview paymentAccount={paymentAccount} />,
      <InvoicesOverview paymentAccount={paymentAccount} />,
      <PaymentModeOverview paymentAccount={paymentAccount} />,
      // <SettingsOverview paymentAccount={paymentAccount} />,
    ];

    if (whiteLabel) {
      result.push(
        <WhiteLabelOverview
          paymentAccount={paymentAccount}
          whiteLabel={whiteLabel}
        />
      );
    }

    return result;
  }, [paymentAccount, whiteLabel]);

  function deactivateAccount() {
    modals.openConfirmModal({
      title: t('paymentAccount.deactivateModalTitle'),
      children: (
        <Text size="sm">{t('paymentAccount.deactivateModalContent')}</Text>
      ),
      labels: { confirm: t('w.yes'), cancel: t('w.cancel') },
      onConfirm: () =>
        updateStatus({
          newStatus: !paymentAccount.active,
          paymentAccountId: paymentAccount.id,
        }),
    });
  }

  function activateAccount() {
    updateStatus({
      newStatus: !paymentAccount.active,
      paymentAccountId: paymentAccount.id,
    });
  }
  /*
  function openSettings() {
    modals.open({
      modalId: 'Settings',
      title: t('w.settings'),
      children: <Settings paymentAccount={paymentAccount} />,
    });
  }*/

  if (stripeConfigLoading) return <Loader type={'bars'} m={'auto'} />;

  return (
    <>
      <Group justify="space-between" mb={'md'}>
        <Group>
          <ActiveInactiveBadge value={paymentAccount.active} />
          <Title size="h3">{paymentAccount.label}</Title>
        </Group>
        <Button
          loading={isLoading}
          variant={'filled'}
          onClick={() =>
            paymentAccount.active ? deactivateAccount() : activateAccount()
          }
        >
          {paymentAccount.active ? t('w.deactivate') : t('w.activate')}
        </Button>
      </Group>

      <Grid>
        <Grid.Col span={{ base: 12, sm: 3 }}>
          <Stack>
            {stats.map((stat) => (
              <Blockquote
                classNames={{ cite: s.blockquoteCite }}
                key={stat.label}
                color={stat.color}
                cite={stat.label}
                icon={stat.icon}
                pl={'xl'}
                p={'xs'}
                ml={'xs'}
                mt={'xs'}
              >
                <Title size="h4">{stat.value}</Title>
              </Blockquote>
            ))}
          </Stack>
        </Grid.Col>
        <Grid.Col span={{ base: 12, sm: 9 }}>
          <SimpleGrid m={'xs'} cols={{ base: 1, md: 2 }}>
            {overviews.map((overview, index) => (
              <Stack align="center" key={index} className={s.overviewWrapper}>
                {overview}
              </Stack>
            ))}
          </SimpleGrid>
        </Grid.Col>
      </Grid>
    </>
  );
}
