import type { IconName } from '../../../features/company/period-types/components/IconSelector';
import type { CommonEntity } from '../common';
import type { HfwSecurityRoleResponse } from './role';

export type LeaveTypeResponse = CommonEntity & {
  companyId: string;
  name: string;
  order: number;
  description?: string;
  maxDays: number;
  legalDelay: number;
  autoValid: boolean;
  blockingPeriodAllowed: boolean;
  requiredComment: boolean;
  requiredAttachment: boolean;
  companyMandatoryLeaveType: boolean;
  useAllDays: boolean;
  codeQuadra?: string;
  codeDiapaie?: string;
  codeIsapaye?: string;
  codeSilae?: string;
  codeOpenpaye?: string;
  codeCegidExpert?: string;
  color?: string;
  icon?: IconName;
  showLabel: boolean;
  excludeEndMonth: boolean;
  repeatable: boolean;
  cancelOthersLeaves: boolean;
  associateCounter?: LeaveCounter[];
  securityRoles?: HfwSecurityRoleResponse[];
  tag?: LeaveTypeTag;
};

type LeaveCounter = {
  order: number;
  counterTypeId: string;
};

export enum LeaveTypeTag {
  Recovery = 'Recovery',
  RTT = 'RTT',
  Work = 'Work',
  Leave = 'Leave',
  PublicHoliday = 'PublicHoliday',
  Breakday = 'Breakday',
  BlockingPeriod = 'BlockingPeriod',
  Repos = 'Repos',
}

export type ImportLeaveTypeResultResponse = {
  nbCreated: number;
  nbErrors: number;
  fileName: string;
  nbUpdated: number;
  importReportList: ImportLeaveTypeReport[];
};

type ImportLeaveTypeReport = {
  name: string;
  lineNb: number;
  message: string;
  status: ImportLeaveTypeStatus;
};

enum ImportLeaveTypeStatus {
  OK = 'OK',
  NOK = 'NOK',
}
