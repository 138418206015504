import {
  ActionIcon,
  Button,
  Chip,
  Fieldset,
  Flex,
  Group,
  Radio,
  Select,
  Text,
  TextInput,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconCirclePlus, IconX } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { useMemo, useState } from 'react';

import InformationMessage from '../../../../components/InformationMessage';
import { useI18n } from '../../../../contexts/I18nProvider';
import PayslipService from '../../../../services/PayslipService';
import { CounterTypeResponse } from '../../../../types/api/response/counterType';
import { CompanyPayslipExtractLocationTemplate } from '../../../../types/types';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
import {
  dataToFormData,
  formDataToPayload,
} from '../../../../utils/payslipConfigurations';
import { ImportPayslipsFormValues } from './ImportPayslipsFormStepper';

type Props = {
  companyId: string;
  form: UseFormReturnType<
    ImportPayslipsFormValues,
    (values: ImportPayslipsFormValues) => ImportPayslipsFormValues
  >;
  canEditLocations: boolean;
  counterTypes?: CounterTypeResponse[];
};

enum ConfigSelectEnum {
  PREVIOUS_CONFIG = 'PREVIOUS_CONFIG',
  SELECT_PAYSLIP_SOFT = 'SELECT_PAYSLIP_SOFT',
}

export default function PayslipConfigSelector({
  companyId,
  form,
  canEditLocations,
  counterTypes,
}: Props) {
  const { t } = useI18n();
  const { data: templates, refetch } = useQuery({
    enabled: !!companyId,
    queryKey: [
      'PayslipService.listCompanyPayslipExtractLocationTemplate',
      companyId,
    ],
    queryFn: () =>
      PayslipService.listCompanyPayslipExtractLocationTemplate(companyId),
  });
  const payslipSofts: string[] = useMemo(
    () => Array.from(new Set(templates?.map((obj) => obj.payslipSoft))),
    [templates]
  );
  const [searchValue, setSearchValue] = useState('');

  function onPayslipConfigChange(value: string) {
    if (value === ConfigSelectEnum.PREVIOUS_CONFIG) {
      if (form.values.previous.config) {
        form.setFieldValue('payslipConfig', form.values.previous.config);
      }
      form.setFieldValue('previous.isUsed', true);
      resetSoftTemplate();
      setSearchValue('');
    } else {
      form.setFieldValue('previous.isUsed', false);
    }
  }

  const {
    mutate: onSaveNewPayslipSoft,
    isLoading: isSaveNewPayslipSoftLoading,
  } = useMutation({
    mutationFn: () =>
      PayslipService.createCompanyPayslipExtractLocationTemplate(companyId, {
        name: form.values.softTemplate.name,
        payslipSoft: form.values.softTemplate.payslipSoft,
        template: formDataToPayload(
          companyId,
          counterTypes || [],
          form.values.payslipConfig
        ),
      }),
    onSuccess,
    onError,
  });

  const { mutate: deleteTemplate, isLoading: isDeleteTemplateLoading } =
    useMutation({
      mutationFn: () =>
        PayslipService.deleteCompanyPayslipExtractLocationTemplate(
          companyId,
          form.values.softTemplate?.template?.id!
        ),
      onSuccess: () => {
        onSuccess();
        form.setFieldValue('softTemplate', {
          ...form.values.softTemplate,
          name: '',
          template: undefined,
          mode: undefined,
        });
      },
      onError,
    });

  const {
    mutate: onUpdatePayslipSoft,
    isLoading: isOnUpdatePayslipSoftLoading,
  } = useMutation({
    mutationFn: () =>
      PayslipService.updateCompanyPayslipExtractLocationTemplate(companyId, {
        id: form.values.softTemplate?.template?.id,
        name: form.values.softTemplate.name,
        payslipSoft: form.values.softTemplate.payslipSoft,
        template: {
          ...formDataToPayload(
            companyId,
            counterTypes || [],
            form.values.payslipConfig
          ),
        },
      }),
    onSuccess,
    onError,
  });

  function onSuccess() {
    showNotification({
      id: 'payslipSoft-successful',
      message: t('w.success'),
      color: 'green',
      icon: <IconCheck />,
    });
    refetch();
  }

  function onError(error: any) {
    showNotification({
      id: 'payslipSoft-error',
      title: t('w.error'),
      message: handleErrorMessage(error, t),
      color: 'red',
      icon: <IconX />,
    });
  }

  function onSelectTemplate(_template: CompanyPayslipExtractLocationTemplate) {
    form.setFieldValue('payslipConfig', dataToFormData(_template.template));
    form.setFieldValue('softTemplate', {
      ...form.values.softTemplate,
      mode: 'UPDATE',
      template: _template,
      name: _template.name,
    });
  }

  function resetSoftTemplate() {
    form.setFieldValue('softTemplate', {
      payslipSoft: '',
      name: '',
      template: undefined,
      mode: undefined,
    });
  }

  return (
    <Flex direction="column" gap="sm">
      <Fieldset legend={t('payslips.import.methodChoice')}>
        <Radio.Group
          maw={600}
          name="payslip-config-select"
          value={
            form.values.previous.isUsed
              ? ConfigSelectEnum.PREVIOUS_CONFIG
              : ConfigSelectEnum.SELECT_PAYSLIP_SOFT
          }
          onChange={onPayslipConfigChange}
        >
          <Group mt="xs">
            <Radio
              value={ConfigSelectEnum.PREVIOUS_CONFIG}
              label={t('payslips.import.reuseConfig')}
            />
            <Radio
              value={ConfigSelectEnum.SELECT_PAYSLIP_SOFT}
              label={t('payslips.import.useOther')}
            />
          </Group>
        </Radio.Group>
      </Fieldset>
      {!form.values.previous.isUsed && (
        <Fieldset legend={t('payslips.import.selectOrAddSoft')}>
          <Select
            label={t('payslips.import.soft')}
            data={
              !!form.values.softTemplate.payslipSoft
                ? Array.from(
                    new Set(
                      payslipSofts.concat(form.values.softTemplate.payslipSoft)
                    )
                  )
                : payslipSofts
            }
            nothingFoundMessage={
              <Button
                onClick={() => {
                  form.setFieldValue('softTemplate', {
                    ...form.values.softTemplate,
                    payslipSoft: searchValue,
                    name: '',
                    mode: undefined,
                  });
                }}
              >
                {t('payslips.import.addSoft')}
              </Button>
            }
            required
            searchable
            searchValue={searchValue}
            onSearchChange={setSearchValue}
            allowDeselect={false}
            {...form.getInputProps('softTemplate.payslipSoft')}
          />
          {!!form.values.softTemplate.payslipSoft && (
            <Fieldset
              variant="filled"
              mt="sm"
              legend={t(
                'payslips.import.selectOrAddConfig',
                form.values.softTemplate.payslipSoft
              )}
            >
              <Group>
                {templates
                  ?.filter(
                    (elt) =>
                      elt.payslipSoft === form.values.softTemplate.payslipSoft
                  )
                  .map((item) => (
                    <Chip
                      checked={
                        form.values.softTemplate.template?.id === item.id
                      }
                      key={item.id}
                      onClick={() => onSelectTemplate(item)}
                    >
                      {item.name}
                    </Chip>
                  ))}
                <ActionIcon
                  variant="subtle"
                  onClick={() => {
                    form.setFieldValue('softTemplate', {
                      ...form.values.softTemplate,
                      name: '',
                      template: undefined,
                      mode: 'CREATE',
                    });
                  }}
                >
                  <IconCirclePlus />
                </ActionIcon>
              </Group>
            </Fieldset>
          )}

          {!!form.values.softTemplate.mode && (
            <Fieldset
              variant="filled"
              mt="sm"
              legend={
                form.values.softTemplate.mode === 'CREATE'
                  ? t('payslips.import.addNew')
                  : t('payslips.import.editConfig')
              }
            >
              {form.values.softTemplate.mode !== 'CREATE' &&
              !form.values.softTemplate.template?.template?.companyId ? (
                <InformationMessage
                  maw={498}
                  variant="warning"
                  message={
                    <Text size="sm" c="dimmed">
                      {t('payslips.import.initialConfigNotEditable')}
                    </Text>
                  }
                />
              ) : (
                <>
                  <TextInput
                    mt="sm"
                    required
                    label={t('payslips.import.configName')}
                    placeholder={t('w.enterText')}
                    {...form.getInputProps('softTemplate.name')}
                  />
                  <InformationMessage
                    maw={498}
                    message={
                      <Text size="sm" c="dimmed">
                        {t(
                          'payslips.import.infoMessage',
                          form.values.softTemplate.mode === 'CREATE'
                            ? t('w.create')
                            : t('w.update')
                        )}
                      </Text>
                    }
                  />
                  <Group>
                    {!!form.values.softTemplate?.template?.id &&
                      form.values.softTemplate.mode === 'UPDATE' && (
                        <Button
                          variant="light"
                          color="red"
                          onClick={() => deleteTemplate()}
                        >
                          {`${t('w.delete')} ${form.values.softTemplate.name}`}
                        </Button>
                      )}
                    <Button
                      onClick={() => {
                        if (form.values.softTemplate.mode === 'CREATE') {
                          onSaveNewPayslipSoft();
                        } else {
                          onUpdatePayslipSoft();
                        }
                      }}
                      disabled={!canEditLocations}
                      loading={
                        isSaveNewPayslipSoftLoading ||
                        isOnUpdatePayslipSoftLoading ||
                        isDeleteTemplateLoading
                      }
                    >
                      {form.values.softTemplate.mode === 'CREATE'
                        ? t('w.create')
                        : t('w.update')}
                    </Button>
                  </Group>
                </>
              )}
            </Fieldset>
          )}
        </Fieldset>
      )}
    </Flex>
  );
}
