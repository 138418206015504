import { Button, Flex } from '@mantine/core';

import CustomModal from '../../../../components/CustomModal';
import { useI18n } from '../../../../contexts/I18nProvider';

type Props = {
  opened: boolean;
  onClose: () => void;
  isAgreed: boolean;
  onClick: () => void;
  isValidateOauthUserLinkLoading: boolean;
};

export default function ValidateOauthUserModal({
  opened,
  onClose,
  isAgreed,
  onClick,
  isValidateOauthUserLinkLoading,
}: Props) {
  const { t } = useI18n();
  return (
    <CustomModal
      size={'xl'}
      opened={opened}
      onClose={onClose}
      title={
        isAgreed
          ? `${t('w.accept')} ${t('w.request').toLowerCase()} ?`
          : `${t('w.reject')} ${t('w.request').toLowerCase()} ?`
      }
    >
      <Flex justify={'flex-end'}>
        <Button loading={isValidateOauthUserLinkLoading} onClick={onClick}>
          {isAgreed ? t('w.iAgree') : t('w.iReject')}
        </Button>
      </Flex>
    </CustomModal>
  );
}
