import type { AccountInfo } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { Button } from '@mantine/core';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import React from 'react';

import { MicrosoftIcon } from '../../../../../components/icons/MicrosoftIcon';
import type { ModuleToEditComponentProps } from '../../../../../components/ModuleToEditModal';
import { useI18n } from '../../../../../contexts/I18nProvider';
import HfwModulesService from '../../../../../services/HfwModulesService';
import handleErrorMessage from '../../../../../utils/handleErrorMessage';
import { loginRequest } from '../../../../login/components/sso/MicrosoftLogin';
import AzureSyncResult from './AzureSyncResult';

export default function ByToken({ module }: ModuleToEditComponentProps) {
  const { t } = useI18n();
  const { instance } = useMsal();
  const { mutate: syncUserAzureModule, isLoading } = useMutation({
    mutationFn: (variables: { token: string; companyId: string }) =>
      HfwModulesService.syncUserAzureModule(
        variables.companyId,
        'token',
        variables.token
      ),
    onSuccess: (data) => {
      showNotification({
        id: `success-syncUserAzureModule`,
        message: t('w.success'),
        color: 'green',
        icon: <IconCheck />,
      });
      modals.open({
        title: t('w.synchronizationResult'),
        modalId: 'hfw-azure-sync-by-token',
        withCloseButton: true,
        size: 'xl',
        children: <AzureSyncResult result={data} />,
      });
    },
    onError: (error) => {
      showNotification({
        id: `error-syncUserAzureModule`,
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      });
    },
  });

  function onLoginClick() {
    instance
      .loginPopup(loginRequest)
      .then((result) => {
        if (result?.account) {
          requestProfileData(result.account);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function requestProfileData(account: AccountInfo) {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account,
      })
      .then((response) => {
        callMsGraph(response.accessToken).then(() => {
          if (module?.configuration?.clientId) {
            syncUserAzureModule({
              companyId: module.configuration.clientId,
              token: response.accessToken,
            });
          }
        });
      });
  }

  async function callMsGraph(accessToken: string) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append('Authorization', bearer);

    const options = {
      method: 'GET',
      headers: headers,
    };

    return fetch('https://graph.microsoft.com/v1.0/me', options)
      .then((response) => response.json())
      .catch((error) => console.log(error));
  }

  return (
    <Button
      fullWidth
      leftSection={<MicrosoftIcon />}
      onClick={onLoginClick}
      loading={isLoading}
    >
      {t('w.synchronizeByLoginWithMicrosoft')}
    </Button>
  );
}
