import { Button, Flex, Select, Stack } from '@mantine/core';
import type {
  DatesRangeValue,
  DateValue,
} from '@mantine/dates/lib/types/DatePickerValue';
import { useForm } from '@mantine/form';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';

import { useI18n } from '../../../../../contexts/I18nProvider';
import PayslipService from '../../../../../services/PayslipService';
import type { PayslipMonthResponse } from '../../../../../types/types';
import handleErrorMessage from '../../../../../utils/handleErrorMessage';

type Props = {
  payslipsMonthInfo: PayslipMonthResponse | undefined;
  selectedUserId: string;
  companyId: string;
  selectedDate: DateValue | DatesRangeValue | Date[];
};

type FormValues = {
  userId: string | null;
};

export default function AssignPayslipNotMatchedForm({
  payslipsMonthInfo,
  selectedUserId,
  companyId,
  selectedDate,
}: Props) {
  const { t } = useI18n();
  const queryClient = useQueryClient();

  const form = useForm<FormValues>({
    initialValues: {
      userId: '',
    },
  });

  const usersToSelect = useMemo(() => {
    return payslipsMonthInfo?.notFound.map((item) => ({
      value: item.userId,
      label: item.fullname,
    }));
  }, [payslipsMonthInfo]);

  const { mutate: reassignPayslip, isLoading: isReassignPayslipLoading } =
    useMutation({
      mutationFn: (variables: {
        userId: string;
        year: number;
        month: number;
      }) =>
        PayslipService.reassignPayslip(
          selectedUserId,
          variables.userId,
          variables.year,
          variables.month
        ),
      onSuccess: () => {
        showNotification({
          id: 'reassign-payslip-successful',
          title: t('w.success'),
          message: t('w.assignedPayslip'),
          color: 'green',
          icon: <IconCheck />,
        });
        queryClient.invalidateQueries({
          queryKey: [
            'PayslipService.getPayslipsTreatmentForCompanyId',
            companyId,
          ],
        });
        modals.close(`assign-payslip-not-matched-to-user-${selectedUserId}`);
      },
      onError: (error) =>
        showNotification({
          id: 'reassign-payslip-error',
          title: t('w.error'),
          message: handleErrorMessage(error, t),
          color: 'red',
          icon: <IconX />,
        }),
    });

  function handleAssignPayslipNotMatched(values: FormValues) {
    reassignPayslip({
      userId: values.userId ? values.userId : '',
      // @ts-ignore
      year: dayjs(selectedDate).year(),
      // @ts-ignore
      month: dayjs(selectedDate).month() + 1,
    });
  }

  return (
    <form onSubmit={form.onSubmit(handleAssignPayslipNotMatched)}>
      <Stack gap={'xl'}>
        <Select
          data={usersToSelect}
          {...form.getInputProps('userId')}
          searchable
        />
        <Flex justify={'flex-end'}>
          <Button
            loading={isReassignPayslipLoading}
            disabled={!Boolean(form.values.userId)}
            type={'submit'}
          >
            {t('w.assign')}
          </Button>
        </Flex>
      </Stack>
    </form>
  );
}
