import { FilterOption, Options, Table } from '@ckprivate/ckf-ui';
import { ActionIcon, Group, Text } from '@mantine/core';
import type {
  DatesRangeValue,
  DateValue,
} from '@mantine/dates/lib/types/DatePickerValue';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconCheck,
  IconFileTypePdf,
  IconUserUp,
  IconX,
} from '@tabler/icons-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { DataTableColumn } from 'mantine-datatable';
import React, { useMemo, useState } from 'react';

import CustomTooltip from '../../../../../components/CustomTooltip';
import { useAuth } from '../../../../../contexts/AuthProvider';
import { useI18n } from '../../../../../contexts/I18nProvider';
import PayslipService from '../../../../../services/PayslipService';
import type { PayslipMonthResponse } from '../../../../../types/types';
import { handleOpenPayslipPdfFile } from '../../../../../utils/attachmentFile';
import handleErrorMessage from '../../../../../utils/handleErrorMessage';
import {
  getFilter,
  getHiddenColumns,
  saveFilter,
  saveHiddenColumns,
} from '../../../../../utils/optionsPreferences';

const componentName = 'AssignEmployeeWithoutPayslipTable';

type Props = {
  payslipsMonthInfo: PayslipMonthResponse | undefined;
  selectDate: DateValue | DatesRangeValue | Date[];
  selectedUserId: string;
  companyId: string;
};

export default function AssignEmployeeWithoutPayslipTable({
  payslipsMonthInfo,
  selectDate,
  selectedUserId,
  companyId,
}: Props) {
  const { access_token } = useAuth();
  const { t, lang } = useI18n();
  const queryClient = useQueryClient();

  const { mutate: reassignPayslip, isLoading: isReassignPayslipLoading } =
    useMutation({
      mutationFn: (variables: {
        userId: string;
        year: number;
        month: number;
      }) =>
        PayslipService.reassignPayslip(
          selectedUserId,
          variables.userId,
          variables.year,
          variables.month
        ),
      onSuccess: () => {
        showNotification({
          id: 'reassign-payslip-successful',
          title: t('w.success'),
          message: t('w.assignedPayslip'),
          color: 'green',
          icon: <IconCheck />,
        });
        queryClient.invalidateQueries({
          queryKey: [
            'PayslipService.getPayslipsTreatmentForCompanyId',
            companyId,
          ],
        });
        modals.close(`assign-payslip-to-user-${selectedUserId}`);
      },
      onError: (error) =>
        showNotification({
          id: 'reassign-payslip-error',
          title: t('w.error'),
          message: handleErrorMessage(error, t),
          color: 'red',
          icon: <IconX />,
        }),
    });

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    search: {
      accessor: 'fullname',
      label: t('w.name'),
    },
    hiddenColumns: getHiddenColumns({
      componentName,
      hiddenColumns: [''],
    }),
    updateHiddenColumns,
    filter: getFilter({
      componentName,
      filter: [],
    }),
    updateFilter,
  });

  const columns: DataTableColumn<any>[] = useMemo(
    () => [
      {
        accessor: 'fullname',
        title: t('w.name'),
        sortable: true,
        ellipsis: true,
        render: ({ fullname }) => <Text fz={'sm'}>{fullname}</Text>,
      },
      {
        accessor: 'userId',
        title: t('w.payslip'),
        sortable: true,
        ellipsis: true,
        render: ({ userId, fullname }) => (
          <CustomTooltip label={t('w.see')}>
            <ActionIcon
              variant={'subtle'}
              onClick={() =>
                handleOpenPayslipPdfFile(
                  userId,
                  fullname,
                  selectDate,
                  payslipsMonthInfo,
                  access_token,
                  t
                )
              }
            >
              <IconFileTypePdf />
            </ActionIcon>
          </CustomTooltip>
        ),
      },
      {
        accessor: 'actions',
        title: 'Actions',
        width: '100px',
        sortable: true,
        ellipsis: true,
        render: ({ userId }) => (
          <Group gap={4} justify="center" wrap={'nowrap'}>
            <CustomTooltip label={t('w.assign')}>
              <ActionIcon
                size="sm"
                color="blue"
                variant={'subtle'}
                onClick={() =>
                  reassignPayslip({
                    userId: userId,
                    // @ts-ignore
                    year: dayjs(selectDate).year(),
                    // @ts-ignore
                    month: dayjs(selectDate).month() + 1,
                  })
                }
              >
                <IconUserUp size={16} />
              </ActionIcon>
            </CustomTooltip>
          </Group>
        ),
      },
    ],
    []
  );

  function updateFilter(newFilter: FilterOption[]) {
    const _options = { ...options };
    _options.filter = newFilter;
    saveFilter({ componentName, filter: newFilter });
    setOptions(_options);
  }

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  return (
    <Table
      options={options}
      columns={columns}
      rows={payslipsMonthInfo?.new}
      lang={lang}
      idAccessor={'userId'}
      fetching={isReassignPayslipLoading}
    />
  );
}
