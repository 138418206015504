import { showNotification } from '@mantine/notifications';
import { IconX } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import React, { useMemo } from 'react';

import { useI18n } from '../contexts/I18nProvider';
import DocumentsService from '../services/DocumentsService';
import { DocumentPropertySpace, DocumentView } from '../types/types';
import { getFolderPathToString } from '../utils/documents';
import handleErrorMessage from '../utils/handleErrorMessage';

export default function useFetchDocuments(
  folderPath: string[],
  space: DocumentPropertySpace,
  companyId: string,
  userId: string,
  accountantCompanyId: string | null
) {
  const { t } = useI18n();

  const {
    data: folderListData,
    isLoading,
    isFetching,
    refetch: refetchDocuments,
  } = useQuery({
    enabled: false,
    queryKey:
      space === 'SHARED' || space === 'ACCOUNTANT'
        ? [
            'DocumentsService.getCompanyDocumentsList',
            folderPath,
            space,
            companyId,
          ]
        : ['DocumentsService.getFoldersList', folderPath, space, userId],
    queryFn: () =>
      space === 'SHARED' || space === 'ACCOUNTANT'
        ? DocumentsService.getCompanyDocumentsList(
            companyId,
            getFolderPathToString(folderPath),
            space,
            accountantCompanyId
          )
        : DocumentsService.getFoldersList(
            getFolderPathToString(folderPath),
            space,
            userId
          ),
    onError: (err) =>
      showNotification({
        id: `get-document-error`,
        title: t('w.error'),
        message: handleErrorMessage(err, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const folderList: DocumentView[] = useMemo(() => {
    if (!folderListData) {
      return [];
    }
    return folderListData;
  }, [folderListData]);

  return {
    folderList,
    isLoading,
    isFetching,
    refetchDocuments,
  };
}
