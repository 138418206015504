import { FilterOption, Options, Table } from '@ckprivate/ckf-ui';
import { Chip, Flex, TextInput } from '@mantine/core';
import { DataTableColumn } from 'mantine-datatable';
import React, { useMemo, useState } from 'react';

import { useI18n } from '../contexts/I18nProvider';
import ImportEmployeesReportStats from '../features/company/employees/components/ImportEmployeesReportStats/ImportEmployeesReportStats';
import {
  ImportDivisionReport,
  ImportDivisionResultResponse,
  ImportEmployeeReport,
  ImportEmployeeResultResponse,
} from '../types/api/response/company';
import {
  getFilter,
  getHiddenColumns,
  saveFilter,
  saveHiddenColumns,
} from '../utils/optionsPreferences';
import { NO_WORK_UNIT } from '../variables/GlobalVariables';
import CustomModal from './CustomModal';
import LabelText from './LabelText/LabelText';

type Props = {
  opened: boolean;
  onClose: () => void;
  resultImportModal:
    | ImportDivisionResultResponse
    | ImportEmployeeResultResponse
    | null;
  importType: string;
};

const componentName = 'ImportReportModal';
export default function ImportReportModal({
  opened,
  onClose,
  resultImportModal,
  importType,
}: Props) {
  const { t } = useI18n();
  const columns: DataTableColumn<
    ImportDivisionReport | ImportEmployeeReport
  >[] = useMemo(
    () =>
      importType === 'employees'
        ? [
            {
              accessor: 'lineNb',
              title: t('w.lineNumber'),
              sortable: true,
              ellipsis: true,
            },
            {
              accessor: 'role',
              title: t('w.role'),
              sortable: true,
              ellipsis: true,
              render: (row) => {
                const role = (row as ImportEmployeeReport).role;
                return t(`${role.toLowerCase()}`);
              },
            },
            {
              accessor: 'mail',
              title: 'Email',
              sortable: true,
              ellipsis: true,
            },
            {
              accessor: 'status',
              title: t('w.status'),
              sortable: true,
              ellipsis: true,
              render: ({ message, status }) => {
                if (status === 'OK') {
                  return (
                    <Chip
                      defaultChecked
                      color={message === 'Created' ? 'green' : 'hifivework.5'}
                    >
                      {t(`w.${message.toLowerCase()}`)}
                    </Chip>
                  );
                } else {
                  return (
                    <Chip defaultChecked color={'red'}>
                      {message}
                    </Chip>
                  );
                }
              },
            },
          ]
        : [
            {
              accessor: 'lineNb',
              title: t('w.lineNumber'),
              sortable: true,
              ellipsis: true,
            },
            {
              accessor: 'divisionName',
              title: t('w.name'),
              sortable: true,
              ellipsis: true,
              render: (row) => {
                const divisionName = (row as ImportDivisionReport).divisionName;
                return divisionName === NO_WORK_UNIT
                  ? t('NO_WORK_UNIT')
                  : divisionName;
              },
            },
            {
              accessor: 'status',
              title: t('w.status'),
              sortable: true,
              ellipsis: true,
              render: ({ message, status }) => {
                if (status === 'OK') {
                  return (
                    <Chip
                      defaultChecked
                      color={message === 'Created' ? 'green' : 'hifivework.5'}
                    >
                      {message}
                    </Chip>
                  );
                } else {
                  return (
                    <Chip defaultChecked color={'red'}>
                      {message}
                    </Chip>
                  );
                }
              },
            },
          ],
    [importType]
  );

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    search: {
      accessor: importType === 'employees' ? 'mail' : 'divisionName',
      label: importType === 'employees' ? 'mail' : t('w.name'),
    },
    hiddenColumns: getHiddenColumns({ componentName }),
    updateHiddenColumns,
    filter: getFilter({
      componentName,
      filter: [
        {
          accessor: 'status',
          label: t('w.status'),
          choices: [
            { value: 'ok', label: 'Mis à jour' },
            { value: 'NOK', label: 'Non mis à jour' },
          ],
          selectedChoices: [],
        },
      ],
    }),
    updateFilter,
  });

  const results = useMemo(() => {
    if (!resultImportModal) {
      return [];
    } else {
      return resultImportModal.importReportList;
    }
  }, [resultImportModal]);

  function updateFilter(newFilter: FilterOption[]) {
    const _options = { ...options };
    _options.filter = newFilter;
    saveFilter({ componentName, filter: newFilter });
    setOptions(_options);
  }

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  return (
    <CustomModal
      opened={opened}
      onClose={onClose}
      title={t('w.importResult')}
      size={'80%'}
    >
      <Flex direction={'column'} gap={'xl'}>
        <TextInput
          disabled
          label={<LabelText text={t('w.fileName')} />}
          value={resultImportModal?.fileName}
        />
        <ImportEmployeesReportStats resultImportModal={resultImportModal} />
        <Table
          options={options}
          rows={results}
          columns={columns}
          height={'50vh'}
        />
      </Flex>
    </CustomModal>
  );
}
