import { Tabs } from '@mantine/core';
import { useMemo, useState } from 'react';

import { useI18n } from '../../../contexts/I18nProvider';
import { OauthUserProfile } from '../../../types/types';
import MyAccountSettings from './my-account-settings/MyAccountSettings';
import MyProfilesSettings from './my-profiles-settings/MyProfilesSettings';
import OauthUserSecurity from './oauth-security/OauthUserSecurity';

type Props = {
  oAuthUser: OauthUserProfile | null;
};

export default function UserAccountSettingsTabs({ oAuthUser }: Props) {
  const { t } = useI18n();
  const [activeTab, setActiveTab] = useState<string | null>('my-account');

  const tabs = useMemo(
    () => [
      {
        value: 'my-account',
        label: t('w.myAccount'),
        disabled: false,
        component: <MyAccountSettings oAuthUser={oAuthUser} />,
        isDisplayed: true,
      },
      {
        value: 'my-profiles',
        label: t('w.myProfiles'),
        disabled: false,
        component: <MyProfilesSettings />,
        isDisplayed: true,
      },
      {
        value: 'oauth-providers',
        label: t('w.security'),
        disabled: false,
        component: <OauthUserSecurity />,
        isDisplayed: true,
      },
    ],
    [oAuthUser]
  );

  return (
    <Tabs value={activeTab} onChange={setActiveTab}>
      <Tabs.List>
        {tabs
          .filter((tab) => tab.isDisplayed)
          .map((tab, index) => (
            <Tabs.Tab
              value={tab.value}
              key={`tab-${tab.value}-${index}`}
              disabled={tab.disabled}
            >
              {tab.label}
            </Tabs.Tab>
          ))}
      </Tabs.List>
      {tabs
        .filter((tab) => tab.isDisplayed)
        .map((tab, index) => (
          <Tabs.Panel
            p={'md'}
            value={tab.value}
            key={`tab-${tab.value}-${index}`}
          >
            {tab.component}
          </Tabs.Panel>
        ))}
    </Tabs>
  );
}
