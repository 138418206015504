import { Button } from '@mantine/core';
import React from 'react';

import { useI18n } from '../contexts/I18nProvider';

type Props = {
  onClose: () => void;
  isLoading?: boolean;
};

export default function ButtonCancel({ onClose, isLoading = false }: Props) {
  const { t } = useI18n();

  return (
    <Button loading={isLoading} onClick={onClose} variant={'subtle'}>
      {t('w.cancel')}
    </Button>
  );
}
