import React from 'react';

import { useAuth } from '../../contexts/AuthProvider';
import ExpenseReportsUser from './components/ExpenseReportsUser';

export default function ExpenseReports() {
  const { user } = useAuth();

  return <ExpenseReportsUser userProfile={user} />;
}
