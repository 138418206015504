import {
  ActionIcon,
  Badge,
  Button,
  Fieldset,
  Flex,
  Group,
  SimpleGrid,
  Stack,
  Stepper,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import { FileWithPath } from '@mantine/dropzone';
import { useForm } from '@mantine/form';
import { useMediaQuery } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconAlertTriangle,
  IconCheck,
  IconEdit,
  IconFileSettings,
  IconFileTypePdf,
  IconTrash,
  IconX,
} from '@tabler/icons-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';

import CustomDropzone from '../../../../components/CustomDropzone/CustomDropzone';
import { useI18n } from '../../../../contexts/I18nProvider';
import { useModule } from '../../../../contexts/ModuleProvider';
import CompanyService from '../../../../services/CompanyService';
import CounterTypeService from '../../../../services/CounterTypeService';
import PayslipService from '../../../../services/PayslipService';
import { ModuleName } from '../../../../types/api/response/module';
import {
  CompanyPayslipExtractLocationTemplate,
  PayslipExtractLocationPayload,
} from '../../../../types/types';
import { getTranslatedKey } from '../../../../utils/counterTypesFormatter';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
import {
  dataToFormData,
  formDataToPayload,
  getColorByKey,
  type PayslipConfigurationFormObject,
  STATIC_FIELDS,
} from '../../../../utils/payslipConfigurations';
import ImportPayslipsInfoList from './ImportPayslipsInfoList';
import PayslipConfigSelector from './PayslipConfigSelector';
import PayslipConfigShape from './PayslipConfigShape';

export type ImportPayslipsFormValues = {
  files: FileWithPath[];
  payslipConfig: Map<string, PayslipConfigurationFormObject>;
  previous: {
    isUsed: boolean;
    config?: Map<string, PayslipConfigurationFormObject>;
  };
  softTemplate: {
    payslipSoft: string;
    name: string;
    template?: CompanyPayslipExtractLocationTemplate;
    mode?: 'CREATE' | 'UPDATE';
  };
};

type Props = {
  companyId: string;
  closeModal: () => void;
};

export default function ImportPayslipsFormStepper({
  companyId,
  closeModal,
}: Props) {
  const { t } = useI18n();
  const { getModule } = useModule();
  const cogilogModule = getModule(ModuleName.BulletinCogilog);
  const isapayeModule = getModule(ModuleName.BulletinIsapaye);
  const queryClient = useQueryClient();
  const matches = useMediaQuery('(max-width: 1023px)');
  const { data: users } = useQuery({
    queryKey: ['CompanyService.listEmployees', companyId],
    queryFn: () =>
      CompanyService.listEmployees(companyId, [
        'fullname',
        'valid',
        'identifiantsCompany',
      ]),
  });
  const form = useForm<ImportPayslipsFormValues>({
    initialValues: {
      files: [],
      payslipConfig: new Map(),
      previous: {
        isUsed: true,
        config: undefined,
      },
      softTemplate: {
        payslipSoft: '',
        name: '',
        template: undefined,
        mode: undefined,
      },
    },
  });
  const [active, setActive] = useState(0);
  const { data: counterTypes } = useQuery({
    queryKey: ['CounterTypeService.list', companyId],
    queryFn: () => CounterTypeService.list(companyId),
  });
  useQuery({
    enabled: !!companyId,
    queryKey: ['PayslipService.getPayslipExtractorConfig', companyId],
    queryFn: () => PayslipService.getPayslipExtractorConfig(companyId),
    onSuccess: (data) => {
      const _previousPayslipConfig = dataToFormData(data);
      form.setFieldValue('payslipConfig', _previousPayslipConfig);
      form.setFieldValue('previous', {
        ...form.values.previous,
        config: _previousPayslipConfig,
      });
    },
  });
  const { mutate: uploadPayslipMonth, isLoading: isUploadPayslipMonthLoading } =
    useMutation({
      mutationFn: (variables: { file: FileWithPath }) =>
        PayslipService.uploadPayslipMonth(
          companyId,
          false,
          true,
          variables.file
        ),
      onSuccess: () => {
        showNotification({
          id: 'upload-payslips-successful',
          title: t('w.success'),
          message: t('payslips.import.success'),
          color: 'green',
          icon: <IconCheck />,
        });
        closeModal();
        setTimeout(
          () =>
            queryClient.invalidateQueries({
              queryKey: [
                'PayslipService.getPayslipsTreatmentForCompanyId',
                companyId,
              ],
            }),
          300
        );
      },
      onError: (error) =>
        showNotification({
          id: 'upload-payslips-error',
          title: t('w.error'),
          message: handleErrorMessage(error, t),
          color: 'red',
          icon: <IconX />,
        }),
    });
  const {
    mutate: updatePayslipExtractorConfig,
    isLoading: isLoadingUpdatePayslipExtractorConfig,
  } = useMutation({
    mutationFn: (payload: PayslipExtractLocationPayload) => {
      return PayslipService.updatePayslipExtractorConfig(companyId, payload);
    },
    onSuccess: (_data, payload) => {
      if (!!payload.matricule?.location || !!payload.codeCompany?.location) {
        const usersWithoutIdentifiantsCompany = users?.filter(
          (item) => item.valid && !item.identifiantsCompany?.length
        );

        if (usersWithoutIdentifiantsCompany?.length) {
          modals.openConfirmModal({
            withCloseButton: false,
            title: (
              <Group>
                <Title size={'h3'} component="p">
                  {t('w.warning')}
                </Title>
                <IconAlertTriangle color={'orange'} />
              </Group>
            ),
            children: (
              <>
                <Text fz={'sm'} mb="md">
                  {t('payslips.import.usersWithoutIdentifiantsCompany')} :
                </Text>
                <Text fz={'sm'} fw="bold">
                  {usersWithoutIdentifiantsCompany
                    .map((item) => item.fullname)
                    .join(', ')}
                </Text>
              </>
            ),
            size: 'lg',
            labels: {
              confirm: t('payslips.import.continue'),
              cancel: t('w.cancel'),
            },
            onConfirm: () => uploadPayslipMonth({ file: form.values.files[0] }),
            onClose: () => modals.closeAll(),
          });
        } else {
          uploadPayslipMonth({ file: form.values.files[0] });
        }
      } else {
        uploadPayslipMonth({ file: form.values.files[0] });
      }
    },
    onError: (error) =>
      showNotification({
        id: 'update-payslips-config-fieldPosition-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const hasPdfFile = form.values.files.length > 0;
  const canEditLocations =
    form.values.previous.isUsed ||
    (!!form.values.softTemplate.payslipSoft &&
      !!form.values.softTemplate.name) ||
    !!form.values.softTemplate.template;

  const fieldsUsage: {
    used: PayslipConfigurationFormObject[];
    unused: PayslipConfigurationFormObject[];
  } = useMemo(() => {
    let used: PayslipConfigurationFormObject[] = [];
    let unused: PayslipConfigurationFormObject[] = [];

    if (!counterTypes) return { used, unused };

    used = Array.from(form.values.payslipConfig).map(([, value]) => value);

    const unusedCounterTypes = counterTypes
      .map((counterTypeResponse) => counterTypeResponse.counterType)
      .filter(
        (counterType) =>
          counterType.active &&
          counterType.extractToPayslip &&
          !Boolean(
            used.find(
              (payslipConfigurationFormObject) =>
                payslipConfigurationFormObject.id === counterType.key
            )
          )
      );

    unused = unusedCounterTypes.map((item) => ({
      id: item.key,
      color: getColorByKey(item.key, counterTypes),
    }));

    const usedKeys = used.map((item) => item.id);
    const unusedKeys = unused.map((item) => item.id);
    STATIC_FIELDS.forEach((key) => {
      if (!usedKeys.includes(key) && !unusedKeys.includes(key)) {
        unused.push({
          id: key,
          color: getColorByKey(key, counterTypes),
        });
      }
    });

    if (cogilogModule?.active || isapayeModule?.active) {
      console.warn(
        "Bulletin Cogilog/IsaPaye actif, pas de champs 'acquisN', 'acquisN1', 'matricule', 'codeCompany'"
      );
      used = used.filter(
        (item) =>
          item.id !== 'acquisN' &&
          item.id !== 'acquisN1' &&
          item.id !== 'matricule' &&
          item.id !== 'codeCompany'
      );
      unused = unused.filter(
        (item) =>
          item.id !== 'acquisN' &&
          item.id !== 'acquisN1' &&
          item.id !== 'matricule' &&
          item.id !== 'codeCompany'
      );
    }

    return { used, unused };
  }, [form.values.payslipConfig, counterTypes]);

  function renderPayslipConfigFieldBadge(
    key: string,
    color: string,
    isUsed: boolean
  ) {
    return (
      <Group key={key}>
        <Badge
          styles={{ label: { textTransform: 'none' } }}
          color={color}
          leftSection={
            <ActionIcon
              size={'xs'}
              color={'white'}
              variant={'subtle'}
              onClick={() => handleConfigLocationClick(key, color)}
            >
              <IconEdit />
            </ActionIcon>
          }
          rightSection={
            isUsed &&
            key !== 'name' &&
            key !== 'periodImported' && (
              <ActionIcon
                size={'xs'}
                color={'white'}
                variant={'subtle'}
                onClick={() => handleDeleteLocation(key)}
              >
                <IconTrash />
              </ActionIcon>
            )
          }
        >
          {getTranslatedKey(t, key)}
        </Badge>
      </Group>
    );
  }

  function handleConfigLocationClick(key: string, color: string) {
    let cloneMap = new Map(form.values.payslipConfig);
    cloneMap.set(key, {
      x: 100,
      y: 100,
      width: 100,
      height: 100,
      id: key,
      color,
    });
    form.setFieldValue('payslipConfig', cloneMap);
  }

  function handleDeleteLocation(key: string) {
    const cloneMap = new Map(form.values.payslipConfig);
    cloneMap.delete(key);
    form.setFieldValue('payslipConfig', cloneMap);
  }

  function handleImportPayslipsSubmit(values: ImportPayslipsFormValues): void {
    if (!counterTypes?.length) return;

    if (values.payslipConfig.size === 0) {
      showNotification({
        id: 'no-field-detection',
        title: t('w.error'),
        message: t('w.noFieldDetection'),
        color: 'red',
        icon: <IconX />,
      });
    } else {
      updatePayslipExtractorConfig(
        formDataToPayload(companyId, counterTypes, values.payslipConfig)
      );
    }
  }

  return (
    <form onSubmit={form.onSubmit(handleImportPayslipsSubmit)}>
      <Stack gap={'xl'}>
        <Stepper
          active={active}
          onStepClick={hasPdfFile ? setActive : undefined}
          styles={{ stepBody: { display: matches ? 'none' : undefined } }}
        >
          <Stepper.Step
            my={'lg'}
            label={t('w.uploadPdfFile')}
            icon={
              <Tooltip label={t('w.uploadPdfFile')}>
                <IconFileTypePdf />
              </Tooltip>
            }
            description={<ImportPayslipsInfoList />}
          >
            <CustomDropzone
              onDrop={(files) => form.setFieldValue('files', files)}
              files={form.values.files}
              mimeType={'pdf'}
              resetFile={() => form.setFieldValue('files', [])}
            />
          </Stepper.Step>
          <Stepper.Step
            my={'lg'}
            label={t('payslips.import.configDetectionFields')}
            description={t(
              'payslips.import.configDetectionFieldsDesc',
              t('w.uploadPdfFile')
            )}
            icon={
              <Tooltip label={t('payslips.import.configDetectionFields')}>
                <IconFileSettings />
              </Tooltip>
            }
          >
            <Group align="flex-start" wrap="nowrap">
              {hasPdfFile && (
                <PayslipConfigShape
                  pdfUrl={form.values.files[0]}
                  defaultPayslipConfig={form.values.payslipConfig}
                  setDefaultPayslipConfig={(rects) =>
                    form.setFieldValue('payslipConfig', rects)
                  }
                />
              )}

              <div>
                <PayslipConfigSelector
                  companyId={companyId}
                  form={form}
                  canEditLocations={canEditLocations}
                  counterTypes={counterTypes}
                />
                <Fieldset
                  mt="md"
                  legend={t('w.locations')}
                  disabled={!canEditLocations}
                >
                  <SimpleGrid cols={2}>
                    <Fieldset variant="filled" legend={t('w.used')}>
                      <Flex direction={'column'} gap={'md'}>
                        {fieldsUsage.used.map((field) =>
                          renderPayslipConfigFieldBadge(
                            field.id,
                            field.color as string,
                            true
                          )
                        )}
                      </Flex>
                    </Fieldset>
                    <Fieldset variant="filled" legend={t('w.unused')}>
                      <Flex direction={'column'} gap={'md'}>
                        {fieldsUsage.unused.map((field) =>
                          renderPayslipConfigFieldBadge(
                            field.id,
                            field.color as string,
                            false
                          )
                        )}
                      </Flex>
                    </Fieldset>
                  </SimpleGrid>
                </Fieldset>
              </div>
            </Group>
          </Stepper.Step>
        </Stepper>
        <Group justify={'flex-end'}>
          {active > 0 && (
            <Button variant={'subtle'} onClick={() => setActive(active - 1)}>
              {t('w.back')}
            </Button>
          )}
          {active === 0 && (
            <Tooltip
              disabled={!!form.values.files.length}
              label={t(
                'payslips.import.configDetectionFieldsDesc',
                t('w.uploadPdfFile')
              )}
            >
              <Button
                disabled={active === 0 && form.values.files.length === 0}
                onClick={() => setActive(active + 1)}
              >
                {t('w.next')}
              </Button>
            </Tooltip>
          )}
          {active === 1 && (
            <Button
              loading={
                isLoadingUpdatePayslipExtractorConfig ||
                isUploadPayslipMonthLoading
              }
              type={'submit'}
            >
              {t('w.import')}
            </Button>
          )}
        </Group>
      </Stack>
    </form>
  );
}
