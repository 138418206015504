export function normalizeFileName(fileName: string): string {
  // Replace spaces with underscores and remove special characters
  return fileName.replace(/[^a-zA-Z0-9_.-]/g, '_');
}

export function normalizeFile(file: File): File {
  // Normalize the filename
  const normalizedFileName = normalizeFileName(file.name);

  // Create a new File object with the normalized filename
  return new File([file], normalizedFileName, { type: file.type });
}
