import { rem, Title } from '@mantine/core';
import { modals } from '@mantine/modals';
import {
  Spotlight as MantineSpotlight,
  SpotlightActionData,
  SpotlightActionGroupData,
} from '@mantine/spotlight';
import {
  IconBeach,
  IconBook,
  IconBuilding,
  IconBuildingStore,
  IconCalendarClock,
  IconCalendarEvent,
  IconCalendarPlus,
  IconClipboardCheck,
  IconClockEdit,
  IconConfetti,
  IconFileDescription,
  IconFolders,
  IconHeadset,
  IconHome,
  IconListDetails,
  IconPasswordUser,
  IconReceipt,
  IconReceiptEuro,
  IconReport,
  IconSearch,
  IconSettings,
  IconUserPlus,
  IconUsers,
  IconUserStar,
} from '@tabler/icons-react';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import CustomUserAvatar from '../components/CustomAvatar/CustomUserAvatar';
import { UrssafModule } from '../components/UrssafSimulator/UrssafModule';
import UrssafSimulator from '../components/UrssafSimulator/UrssafSimulator';
import { useAuth } from '../contexts/AuthProvider';
import { useI18n } from '../contexts/I18nProvider';
import { useModule } from '../contexts/ModuleProvider';
import AddEmployeeSteppers from '../features/company/employees/components/AddEmployeeSteppers/AddEmployeeSteppers';
import EditWorkUnitForm from '../features/company/work-units/components/EditWorkUnitForm';
import ExpenseReportRequestForm from '../features/expense-reports/components/ExpenseReportRequestForm';
import LeavesRequestFormModal from '../features/home/components/leaves/LeavesRequestFormModal';
import useFetchUsers from '../hooks/useFetchUsers';
import { ModuleName } from '../types/api/response/module';
import { BuiltInPermissions, ScopeEnum } from '../types/api/response/role';
import { hasPermission } from '../utils/authorization';
import { roleName } from '../utils/roleName';
import { MS_WHITE_LABEL_ID, NO_WORK_UNIT } from '../variables/GlobalVariables';

export default function Spotlight() {
  const { user, access_token } = useAuth();
  const { t } = useI18n();
  const navigate = useNavigate();
  const { getModule } = useModule();
  const { users, divisions, roles, refetchUsers } = useFetchUsers(
    user.company?.id!
  );

  const iconStyle = {
    style: { width: rem(24), height: rem(24) },
    stroke: 1.5,
  };

  const actions = useMemo(() => {
    const result: (SpotlightActionGroupData | SpotlightActionData)[] = [
      { group: t('w.usefulLinks'), actions: buildUsefulLinks() },
      { group: t('w.myQuickActions'), actions: buildMyQuickActions() },
    ];

    if (
      hasPermission(
        [
          {
            permission: BuiltInPermissions.ConfigureWorkUnit,
            scope: ScopeEnum.ALL,
          },
          {
            permission: BuiltInPermissions.ConfigureWorkUnit,
            scope: ScopeEnum.TEAM,
          },
          {
            permission: BuiltInPermissions.ConfigureEmployees,
            scope: ScopeEnum.ALL,
          },
          {
            permission: BuiltInPermissions.ConfigureLeaveTypes,
            scope: ScopeEnum.ALL,
          },
          {
            permission: BuiltInPermissions.ConfigureBlockingPeriods,
            scope: ScopeEnum.ALL,
          },
          {
            permission: BuiltInPermissions.ConfigurePublicHolidaysCompanyLeaves,
            scope: ScopeEnum.ALL,
          },
          {
            permission: BuiltInPermissions.ConfigureShuttleSheetTypes,
            scope: ScopeEnum.ALL,
          },
          {
            permission: BuiltInPermissions.ConfigureAccountant,
            scope: ScopeEnum.ALL,
          },
          {
            permission: BuiltInPermissions.ConfigureFeatures,
            scope: ScopeEnum.ALL,
          },
          {
            permission: BuiltInPermissions.ConfigurePermissions,
            scope: ScopeEnum.ALL,
          },
          {
            permission: BuiltInPermissions.ConfigureCompany,
            scope: ScopeEnum.ALL,
          },
        ],
        user
      )
    ) {
      result.push({
        group: t('w.administration'),
        actions: buildAdministration(),
      });
    }

    if (
      hasPermission(
        {
          permission: BuiltInPermissions.ConfigureEmployees,
          scope: ScopeEnum.ALL,
        },
        user
      )
    ) {
      result.push({ group: t('w.userProfiles'), actions: buildUserProfiles() });
    }

    if (MS_WHITE_LABEL_ID !== 'AFRICAPAIERH') {
      result.push({
        group: t('w.urssafAssistantOrSimulator'),
        actions: buildUrssafAssistantOrSimulators(),
      });
    }

    return result;
  }, [user, access_token, users, divisions]);

  function buildUsefulLinks() {
    const result: SpotlightActionData[] = [
      {
        id: 'tutorials',
        label: t('w.tutorials'),
        description: t('w.tutorialsInformations'),
        onClick: () =>
          window.open(
            MS_WHITE_LABEL_ID === 'AFRICAPAIERH'
              ? 'https://africapaierh.com/tutoriels'
              : 'https://www.youtube.com/watch?v=L8iWQ0pEdqE&list=PLr10pG_NRUrY-8zcR7bRvtnWWAYEcc7Y6'
          ),
        leftSection: <IconBook {...iconStyle} />,
      },
      {
        id: 'contactSupport',
        label: t('w.contactSupport'),
        description: t('w.contactSupportInformations'),
        onClick: () =>
          window.open(
            MS_WHITE_LABEL_ID === 'AFRICAPAIERH'
              ? 'https://africapaierh.com/contact'
              : 'https://hifivework.com/contacter-le-support/'
          ),
        leftSection: <IconHeadset {...iconStyle} />,
      },
    ];

    return result;
  }

  function buildMyQuickActions() {
    const result: SpotlightActionData[] = [
      {
        id: 'home',
        label: t('w.home'),
        description: t('w.getToHomePage'),
        onClick: () => navigate('/home'),
        leftSection: <IconHome {...iconStyle} />,
      },
    ];

    if (
      hasPermission(
        [
          {
            permission: BuiltInPermissions.AuthorizedReadPlanning,
            scope: ScopeEnum.ALL,
          },
          {
            permission: BuiltInPermissions.AuthorizedReadPlanning,
            scope: ScopeEnum.TEAM,
          },
          {
            permission: BuiltInPermissions.AuthorizedReadPlanning,
            scope: ScopeEnum.MINE,
          },
          {
            permission: BuiltInPermissions.AuthorizedReadAllUsersInfo,
            scope: ScopeEnum.ALL,
          },
        ],
        user
      )
    ) {
      result.push({
        id: 'planning',
        label: t('w.planning'),
        description: t('w.getToPlanningPage'),
        onClick: () => navigate('/planning'),
        leftSection: <IconCalendarEvent {...iconStyle} />,
      });
    }

    result.push({
      id: 'requestAbsence',
      label: t('w.requestAbsence'),
      description: t('w.takingLeaveOrAbsenceForMyself'),
      onClick: () =>
        modals.open({
          modalId: 'leave-request-form',
          title: (
            <Title size={'h3'} component="p">
              {t('w.requestForLeaveOrAbsence', user.fullname)}
            </Title>
          ),
          size: '90%',
          children: (
            <LeavesRequestFormModal
              onClose={() => modals.close('leave-request-form')}
              user={user}
            />
          ),
        }),
      leftSection: <IconCalendarPlus {...iconStyle} />,
    });

    if (getModule(ModuleName.ExpenseReport)?.active) {
      result.push({
        id: 'addExpenseReport',
        label: t('w.addExpenseReport'),
        description: t('w.takingExpenseReportForMyself'),
        onClick: () =>
          modals.open({
            modalId: `add-new-expense-report-modal`,
            title: (
              <Title size={'h3'} component="p">
                {t('w.expenseReport')}
              </Title>
            ),
            size: '90%',
            children: (
              <ExpenseReportRequestForm
                closeModal={() => modals.close('add-new-expense-report-modal')}
                userProfile={user}
              />
            ),
          }),
        leftSection: <IconReceipt {...iconStyle} />,
      });
    }

    if (
      hasPermission(
        {
          permission: BuiltInPermissions.ConfigureEmployees,
          scope: ScopeEnum.ALL,
        },
        user
      ) &&
      divisions &&
      roles
    ) {
      result.push({
        id: 'addEmployee',
        label: t('w.addEmployee'),
        description: t('w.inMyCompany', user.company?.name),
        onClick: () =>
          modals.open({
            modalId: `add-new-employee`,
            title: (
              <Title size={'h3'} component="p">
                {t('w.addAnEmployee')}
              </Title>
            ),
            fullScreen: true,
            children: (
              <AddEmployeeSteppers
                divisions={divisions}
                roles={roles}
                onClose={() => modals.close('add-new-employee-modal')}
                refetch={refetchUsers}
                userProfile={null}
              />
            ),
          }),
        leftSection: <IconUserPlus {...iconStyle} />,
      });
    }

    if (
      hasPermission(
        [
          {
            permission: BuiltInPermissions.ConfigureWorkUnit,
            scope: ScopeEnum.ALL,
          },
          {
            permission: BuiltInPermissions.ConfigureWorkUnit,
            scope: ScopeEnum.TEAM,
          },
        ],
        user
      )
    ) {
      result.push({
        id: 'addWorkUnit',
        label: `${t('w.add')} ${
          user.company?.divisionLabel ? user.company.divisionLabel : t('w.unit')
        }`,
        description: t('w.inMyCompany', user.company?.name),
        onClick: () =>
          modals.open({
            modalId: `add-new-work-unit`,
            title: (
              <Title size={'h3'} component="p">
                {`${t('w.add')} ${
                  user.company?.divisionLabel
                    ? user.company.divisionLabel
                    : t('w.unit')
                }`}
              </Title>
            ),
            size: 'xl',
            children: (
              <EditWorkUnitForm
                onClose={() => modals.close(`add-new-work-unit`)}
                refetch={() => {}}
                company={user.company!}
              />
            ),
          }),
        leftSection: <IconBuilding {...iconStyle} />,
      });
    }

    if (
      hasPermission(
        {
          permission: BuiltInPermissions.ValidateLeavesAbsences,
          scope: ScopeEnum.ALL,
        },
        user
      )
    ) {
      result.push({
        id: 'validations-leavesAndAbsences',
        label: `${t('w.validations')} ${t(
          'w.leavesAndAbsences'
        ).toLowerCase()}`,
        description: t('w.inMyCompany', user.company?.name),
        onClick: () => navigate(`/validation/leaves`),
        leftSection: <IconClipboardCheck {...iconStyle} />,
      });
    }

    if (
      getModule(ModuleName.ExpenseReport)?.active &&
      hasPermission(
        [
          {
            permission: BuiltInPermissions.ValidateExpenseReports,
            scope: ScopeEnum.ALL,
          },
        ],
        user
      )
    ) {
      result.push({
        id: 'validations-expenseReports',
        label: `${t('w.validations')} ${t('w.expenseReports').toLowerCase()}`,
        description: t('w.inMyCompany', user.company?.name),
        onClick: () => navigate(`/validation/expense-reports`),
        leftSection: <IconReport {...iconStyle} />,
      });
    }

    if (
      hasPermission(
        {
          permission: BuiltInPermissions.AuthorizedReadMyPersonalSpace,
          scope: ScopeEnum.ALL,
        },
        user
      )
    ) {
      result.push({
        id: 'documents-personal',
        label: `${t('w.documents')} ${t('w.personal').toLowerCase()}`,
        description: t('w.inMyCompany', user.company?.name),
        onClick: () => navigate(`/documents/personal`),
        leftSection: <IconFolders {...iconStyle} />,
      });
    }

    if (
      hasPermission(
        {
          permission: BuiltInPermissions.AuthorizedReadCommonSpace,
          scope: ScopeEnum.ALL,
        },
        user
      )
    ) {
      result.push({
        id: 'documents-company',
        label: `${t('w.documents')} ${t('w.company').toLowerCase()}`,
        description: t('w.inMyCompany', user.company?.name),
        onClick: () => navigate(`/documents/shared`),
        leftSection: <IconFolders {...iconStyle} />,
      });
    }

    if (
      hasPermission(
        {
          permission: BuiltInPermissions.AuthorizedEditAccountantSpace,
          scope: ScopeEnum.ALL,
        },
        user
      )
    ) {
      result.push({
        id: 'documents-payrollOfficers',
        label: `${t('w.documents')} ${t('w.payrollOfficers').toLowerCase()}`,
        description: t('w.inMyCompany', user.company?.name),
        onClick: () => navigate(`/documents/accountant`),
        leftSection: <IconFolders {...iconStyle} />,
      });
    }

    if (getModule(ModuleName.ExpenseReport)?.active) {
      result.push({
        id: 'myExpenseReports',
        label: t('w.myExpenseReports'),
        description: t('w.inMyCompany', user.company?.name),
        onClick: () => navigate(`/expense-reports/user/${user.id}`),
        leftSection: <IconReceipt {...iconStyle} />,
      });
    }

    if (getModule(ModuleName.ShuttleSheet)?.active) {
      result.push({
        id: 'shuttleSheets',
        label: t('w.shuttleSheets'),
        description: t('w.inMyCompany', user.company?.name),
        onClick: () => navigate(`/shuttle-sheets/user/${user.id}`),
        leftSection: <IconListDetails {...iconStyle} />,
      });
    }

    if (user.division?.name !== NO_WORK_UNIT) {
      result.push({
        id: 'myPayslips',
        label: t('w.myPayslips'),
        description: t('w.inMyCompany', user.company?.name),
        onClick: () => navigate(`/payslips/user/${user.id}`),
        leftSection: <IconFileDescription {...iconStyle} />,
      });
    }

    return result;
  }

  function buildUrssafAssistantOrSimulators() {
    const result: SpotlightActionData[] = [];
    Object.keys(UrssafModule).forEach((key) => {
      result.push({
        id: `urssaf-assistant-or-simulator-${key}`,
        label: t(`urssaf.${key}`),
        description: t('w.urssafAssistantOrSimulator'),
        onClick: () =>
          modals.open({
            modalId: `urssaf-assistant-or-simulator-modal-${key}`,
            title: (
              <Title size={'h3'} component="p">
                {t('w.urssafAssistantOrSimulator')} - {t(`urssaf.${key}`)}
              </Title>
            ),
            fullScreen: true,
            children: (
              <div id="urssaf-simulator-script-content-id">
                <UrssafSimulator
                  dataModule={key as keyof typeof UrssafModule}
                />
              </div>
            ),
          }),
        leftSection: <IconFileDescription {...iconStyle} />,
      });
    });

    return result;
  }

  function buildUserProfiles() {
    const result: SpotlightActionData[] = [];

    users?.forEach((_user) => {
      result.push({
        id: `user-${_user.id}`,
        label: _user.fullname,
        description: `${roleName(t, _user.role?.name!)} - ${
          !Boolean(_user.onBoardingId)
            ? _user.valid
              ? t('w.active')
              : t('w.inactive')
            : _user.valid
            ? t('w.active')
            : t('w.onBoarding')
        } - ${
          _user?.division?.name === NO_WORK_UNIT
            ? t(`NO_WORK_UNIT`)
            : _user.division?.name
        }`,
        onClick: () => navigate(`/profile/user/${_user.id}`),
        leftSection: <CustomUserAvatar userId={_user.id} size={26} />,
      });
    });

    return result;
  }

  function buildAdministration() {
    const result: SpotlightActionData[] = [];

    if (
      hasPermission(
        {
          permission: BuiltInPermissions.ConfigureEmployees,
          scope: ScopeEnum.ALL,
        },
        user
      )
    ) {
      result.push({
        id: 'employees',
        label: t('w.employees'),
        description: t('w.inMyCompany', user.company?.name),
        onClick: () => navigate(`/company/${user.company?.id}/employees`),
        leftSection: <IconUsers {...iconStyle} />,
      });
    }

    if (
      hasPermission(
        [
          {
            permission: BuiltInPermissions.ConfigureWorkUnit,
            scope: ScopeEnum.ALL,
          },
          {
            permission: BuiltInPermissions.ConfigureWorkUnit,
            scope: ScopeEnum.TEAM,
          },
        ],
        user
      )
    ) {
      result.push({
        id: 'units',
        label: user.company?.divisionLabel
          ? user.company.divisionLabel
          : t('w.units'),
        description: t('w.inMyCompany', user.company?.name),
        onClick: () => navigate(`/company/${user.company?.id}/work-units`),
        leftSection: <IconBuilding {...iconStyle} />,
      });
    }

    if (
      hasPermission(
        {
          permission: BuiltInPermissions.ConfigureCompany,
          scope: ScopeEnum.ALL,
        },
        user
      )
    ) {
      result.push({
        id: 'counterTypes',
        label: t('w.counterTypes'),
        description: t('w.inMyCompany', user.company?.name),
        onClick: () => navigate(`/company/${user.company?.id}/counter-types`),
        leftSection: <IconClockEdit {...iconStyle} />,
      });
    }

    if (
      hasPermission(
        {
          permission: BuiltInPermissions.ConfigureLeaveTypes,
          scope: ScopeEnum.ALL,
        },
        user
      )
    ) {
      result.push({
        id: 'typesOfPeriod',
        label: t('w.typesOfPeriod'),
        description: t('w.inMyCompany', user.company?.name),
        onClick: () => navigate(`/company/${user.company?.id}/period-types`),
        leftSection: <IconCalendarClock {...iconStyle} />,
      });
    }

    if (
      hasPermission(
        [
          {
            permission: BuiltInPermissions.ConfigureBlockingPeriods,
            scope: ScopeEnum.ALL,
          },
          {
            permission: BuiltInPermissions.ConfigurePublicHolidaysCompanyLeaves,
            scope: ScopeEnum.ALL,
          },
        ],
        user
      )
    ) {
      result.push({
        id: 'periods',
        label: t('w.periods'),
        description: t('w.inMyCompany', user.company?.name),
        onClick: () => navigate(`/company/${user.company?.id}/periods`),
        leftSection: <IconCalendarEvent {...iconStyle} />,
      });
    }

    if (
      hasPermission(
        {
          permission: BuiltInPermissions.ConfigurePublicHolidaysCompanyLeaves,
          scope: ScopeEnum.ALL,
        },
        user
      )
    ) {
      result.push({
        id: 'publicHolidays',
        label: t('w.publicHolidays'),
        description: t('w.inMyCompany', user.company?.name),
        onClick: () =>
          navigate(`/company/${user.company?.id}/public-holidays-and-leave`),
        leftSection: <IconBeach {...iconStyle} />,
      });
    }

    if (
      getModule(ModuleName.ShuttleSheet)?.active &&
      hasPermission(
        {
          permission: BuiltInPermissions.ConfigureShuttleSheetTypes,
          scope: ScopeEnum.ALL,
        },
        user
      )
    ) {
      result.push({
        id: 'shuttleSheetTypes',
        label: t('w.shuttleSheetTypes'),
        description: t('w.inMyCompany', user.company?.name),
        onClick: () =>
          navigate(`/company/${user.company?.id}/shuttle-sheet-type`),
        leftSection: <IconListDetails {...iconStyle} />,
      });
    }

    if (
      hasPermission(
        {
          permission: BuiltInPermissions.ConfigureAccountant,
          scope: ScopeEnum.ALL,
        },
        user
      )
    ) {
      result.push({
        id: 'payrollOfficer',
        label: t('w.payrollOfficer'),
        description: t('w.inMyCompany', user.company?.name),
        onClick: () => navigate(`/company/${user.company?.id}/payroll-officer`),
        leftSection: <IconUserStar {...iconStyle} />,
      });
    }

    if (
      hasPermission(
        [
          {
            permission: BuiltInPermissions.ConfigureFeatures,
            scope: ScopeEnum.ALL,
          },
          {
            permission: BuiltInPermissions.ConfigureTools,
            scope: ScopeEnum.ALL,
          },
        ],
        user
      )
    ) {
      result.push({
        id: 'marketPlace',
        label: t('w.marketPlace'),
        description: t('w.inMyCompany', user.company?.name),
        onClick: () => navigate(`/company/${user.company?.id}/market-place`),
        leftSection: <IconBuildingStore {...iconStyle} />,
      });
    }

    if (
      hasPermission(
        {
          permission: BuiltInPermissions.ConfigureFeatures,
          scope: ScopeEnum.ALL,
        },
        user
      )
    ) {
      result.push({
        id: 'features',
        label: t('w.features'),
        description: t('w.inMyCompany', user.company?.name),
        onClick: () => navigate(`/company/${user.company?.id}/features`),
        leftSection: <IconConfetti {...iconStyle} />,
      });
    }

    if (
      hasPermission(
        {
          permission: BuiltInPermissions.ConfigurePermissions,
          scope: ScopeEnum.ALL,
        },
        user
      )
    ) {
      result.push({
        id: 'userRoles',
        label: t('w.userRoles'),
        description: t('w.inMyCompany', user.company?.name),
        onClick: () => navigate(`/company/${user.company?.id}/roles`),
        leftSection: <IconPasswordUser {...iconStyle} />,
      });
    }

    if (
      hasPermission(
        {
          permission: BuiltInPermissions.ConfigurePayment,
          scope: ScopeEnum.ALL,
        },
        user
      )
    ) {
      result.push({
        id: 'billing',
        label: t('w.billing'),
        description: t('w.inMyCompany', user.company?.name),
        onClick: () => navigate(`/company/${user.company?.id}/billing`),
        leftSection: <IconReceiptEuro {...iconStyle} />,
      });
    }

    if (
      hasPermission(
        {
          permission: BuiltInPermissions.ConfigureCompany,
          scope: ScopeEnum.ALL,
        },
        user
      )
    ) {
      result.push({
        id: 'settings',
        label: t('w.settings'),
        description: t('w.inMyCompany', user.company?.name),
        onClick: () => navigate(`/company/${user.company?.id}/settings`),
        leftSection: <IconSettings {...iconStyle} />,
      });
    }

    return result;
  }

  return (
    <MantineSpotlight
      actions={actions}
      nothingFound={t('w.noResults')}
      highlightQuery
      searchProps={{
        leftSection: (
          <IconSearch
            style={{ width: rem(20), height: rem(20) }}
            stroke={1.5}
          />
        ),
        placeholder: t('w.search'),
      }}
      scrollable
      maxHeight={500}
    />
  );
}
