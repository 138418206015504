import { rem, UnstyledButton } from '@mantine/core';

import CustomTooltip from '../../../../components/CustomTooltip';
import { Link } from '../../../../types/types';
import classes from '../DoubleNavBar.module.sass';

type Props = {
  item: Link;
  onClick: () => void;
  pathname: string;
};

export default function IconLink({ item, onClick, pathname }: Props) {
  return (
    <CustomTooltip label={item.label} position="right" withinPortal={false}>
      <UnstyledButton
        onClick={onClick}
        className={classes.mainLink}
        style={{ width: rem(44), height: rem(44) }}
        data-active={item.link === pathname || undefined}
      >
        <item.icon style={{ width: rem(28), height: rem(28) }} stroke={1.5} />
      </UnstyledButton>
    </CustomTooltip>
  );
}
