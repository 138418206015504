import React, { createContext, ReactNode, useContext } from 'react';

import { Language } from '../types/i18n';
import { getPersistedObject } from '../utils/localStorage';
import translate from '../utils/translate';
import { useAuth } from './AuthProvider';

type I18nContextType = {
  lang: Language;
  t: (key: string, ...args: Array<any>) => string;
  formatNumber: (
    value?: number,
    options?: Intl.NumberFormatOptions
  ) => string | undefined;
};

export const I18nContext = createContext<I18nContextType>(
  {} as I18nContextType
);
const useI18nContext = () => useContext(I18nContext);
export const useI18n = useI18nContext;

type I18nProviderProps = {
  children: ReactNode;
};

export default function I18nProvider({ children }: I18nProviderProps) {
  const { user } = useAuth();
  const lang = user?.lang || getPersistedObject('lang') || Language.fr;

  function t(key: string, ...args: Array<any>) {
    return translate(lang, key, ...args);
  }

  function formatNumber(
    value?: number,
    options?: Intl.NumberFormatOptions
  ): string | undefined {
    if (value == null) {
      return value;
    }

    let _options = options;

    if (!_options) {
      _options = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
    }

    return new Intl.NumberFormat(lang, _options).format(value);
  }

  return (
    <I18nContext.Provider value={{ lang, t, formatNumber }}>
      {children}
    </I18nContext.Provider>
  );
}
