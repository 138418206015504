import { Options, Table } from '@ckprivate/ckf-ui';
import { ActionIcon, Button, Group } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconTrash, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { DataTableColumn } from 'mantine-datatable';
import React, { useMemo, useState } from 'react';

import MoreItemsBadge from '../../../../../components/badges/MoreItemsBadge';
import CustomTooltip from '../../../../../components/CustomTooltip';
import { useI18n } from '../../../../../contexts/I18nProvider';
import MandatoryLeaveService from '../../../../../services/MandatoryLeaveService';
import type { CompanyResponse } from '../../../../../types/api/response/company';
import type { DivisionResponse } from '../../../../../types/api/response/division';
import type { LeaveTypeResponse } from '../../../../../types/api/response/leaveType';
import type { CompanyLeave } from '../../../../../types/types';
import { convertSession, dateString } from '../../../../../utils/format';
import handleErrorMessage from '../../../../../utils/handleErrorMessage';
import {
  getHiddenColumns,
  saveHiddenColumns,
} from '../../../../../utils/optionsPreferences';
import { NO_WORK_UNIT } from '../../../../../variables/GlobalVariables';

type Props = {
  companyLeavesListData: CompanyLeave[];
  isCompanyLeavesListDataLoading: boolean;
  allCompanyLeaveTypesListData?: LeaveTypeResponse[];
  divisions: DivisionResponse[];
  refetchCompanyLeavesListData: () => void;
  company: CompanyResponse;
};

const componentName = 'CompanyMandatoryLeavesTable';
export default function CompanyMandatoryLeavesTable({
  companyLeavesListData,
  isCompanyLeavesListDataLoading,
  allCompanyLeaveTypesListData,
  divisions,
  refetchCompanyLeavesListData,
  company,
}: Props) {
  const { t, lang } = useI18n();
  const [selectedCompanyLeaves, setSelectedCompanyLeaves] = useState<any>([]);
  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    search: {
      accessor: 'name',
      label: t('w.name'),
    },
    hiddenColumns: getHiddenColumns({ componentName }),
    updateHiddenColumns,
  });

  const { mutate: deleteCompanyLeave } = useMutation({
    mutationFn: (variables: { leaveId: string }) =>
      MandatoryLeaveService.deleteCompanyLeave(company.id, variables.leaveId),
    onSuccess: (data, variables) => {
      const { leaveId } = variables;
      refetchCompanyLeavesListData();
      showNotification({
        id: `delete-company-leave-id-${leaveId}-successful`,
        title: t('w.success'),
        message: t('w.deleted'),
        color: 'green',
        icon: <IconCheck />,
      });
      setSelectedCompanyLeaves([]);
    },
    onError: (error) =>
      showNotification({
        id: 'delete-company-leave-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const companyLeavesList = useMemo(() => {
    if (!companyLeavesListData) {
      return [];
    }
    return companyLeavesListData;
  }, [companyLeavesListData]);

  function getLeaveTypeName(leaveTypeId: string): string | undefined {
    if (allCompanyLeaveTypesListData) {
      const findLeaveType = allCompanyLeaveTypesListData.find(
        (leave) => leave.id === leaveTypeId
      );
      if (findLeaveType) {
        return findLeaveType.name;
      }
    }
  }

  function getDivisionsName(excludeDivisions: DivisionResponse[]): string[] {
    const result = divisions?.filter((division) => {
      return (
        excludeDivisions
          .map((excludeDivision) => excludeDivision.id)
          .indexOf(division.id) === -1
      );
    });

    return result?.map((division) =>
      division.name === NO_WORK_UNIT ? t('NO_WORK_UNIT') : division.name
    );
  }

  const columns: DataTableColumn<any>[] = useMemo(
    () => [
      {
        accessor: 'name',
        title: t('w.name'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'description',
        title: 'Description',
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'period.startDate',
        title: t('w.startDate'),
        sortable: true,
        ellipsis: true,
        render: ({ period }) =>
          `${dateString(`${period.startDate}Z`)} - (${convertSession(
            `${period.startDate}Z`,
            t
          )})`,
      },
      {
        accessor: 'period.endDate',
        title: t('w.endDate'),
        sortable: true,
        ellipsis: true,
        render: ({ period }) =>
          `${dateString(`${period.endDate}Z`)} - (${convertSession(
            `${period.endDate}Z`,
            t
          )})`,
      },
      {
        accessor: 'leaveTypeId',
        title: t('w.typeOfPeriod'),
        sortable: true,
        ellipsis: true,
        render: ({ leaveTypeId }) => getLeaveTypeName(leaveTypeId),
      },
      {
        accessor: 'divisions',
        title: company?.divisionLabel ? company.divisionLabel : t('w.unit'),
        sortable: true,
        ellipsis: true,
        render: ({ excludeDivisions }) => (
          <MoreItemsBadge items={getDivisionsName(excludeDivisions)} />
        ),
      },
      {
        accessor: 'actions',
        title: t('w.actions'),
        textAlign: 'right',
        sortable: false,
        render: ({ id }) => (
          <Group gap={4} justify="end" wrap={'nowrap'}>
            <CustomTooltip label={t('w.delete')}>
              <ActionIcon
                variant={'subtle'}
                size="sm"
                color="red"
                onClick={() => deleteCompanyLeave({ leaveId: id })}
              >
                <IconTrash size={16} />
              </ActionIcon>
            </CustomTooltip>
          </Group>
        ),
      },
    ],
    [company?.divisionLabel, allCompanyLeaveTypesListData]
  );

  const rightCustomActions = useMemo(
    () => [
      <Button
        leftSection={<IconTrash />}
        disabled={selectedCompanyLeaves.length === 0}
        key={'delete-selected-company-leaves'}
        onClick={handleDeleteSelectedCompanyLeavesClick}
      >
        {t('w.delete')}
      </Button>,
    ],
    [selectedCompanyLeaves.length]
  );

  function handleDeleteSelectedCompanyLeavesClick() {
    selectedCompanyLeaves.forEach((item: CompanyLeave) =>
      deleteCompanyLeave({ leaveId: item.id })
    );
  }

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  return (
    <Table
      pinFirstColumn
      pinLastColumn
      onSelect={(companyLeave) => setSelectedCompanyLeaves(companyLeave)}
      rows={companyLeavesList}
      fetching={isCompanyLeavesListDataLoading}
      options={options}
      columns={columns}
      height={'calc(100vh - 220px)'}
      lang={lang}
      rightCustomActions={rightCustomActions}
    />
  );
}
