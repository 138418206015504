import {
  Button,
  Group,
  List,
  LoadingOverlay,
  rem,
  Stack,
  Text,
  ThemeIcon,
} from '@mantine/core';
import { matches, useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import {
  IconAlertTriangle,
  IconCheck,
  IconMail,
  IconMailFast,
  IconPhone,
  IconX,
} from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';

import PhoneField from '../../../../components/PhoneField/PhoneField';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import GererMesAffairesService from '../../../../services/GererMesAffairesService';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
import { internationalPhoneNumberRegex } from '../../../../utils/validators';

type Props = {
  onModalClose: () => void;
  refetchGmaUser: () => void;
};

type FormValues = {
  phone: string | undefined;
};

export default function GmaInvitationForm({
  onModalClose,
  refetchGmaUser,
}: Props) {
  const { t } = useI18n();
  const { user } = useAuth();
  const form = useForm<FormValues>({
    initialValues: {
      phone: '',
    },
    validate: {
      phone: matches(internationalPhoneNumberRegex(), t('invalid.phone')),
    },
  });

  const { mutate: inviteUserToGma, isLoading } = useMutation({
    mutationFn: (variables: FormValues) =>
      GererMesAffairesService.inviteUserToGma(variables.phone),
    onSuccess: () => {
      onModalClose();
      refetchGmaUser();
      showNotification({
        id: 'invitation-to-join-gma-sent-successful',
        title: t('w.success'),
        message: t('w.gmaInvitationMailSent', user.mail),
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: (error) =>
      showNotification({
        id: 'invitation-to-join-gma-sent-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function handleErrorSubmit(errors: typeof form.errors) {
    if (errors.phone) {
      showNotification({
        id: 'gma-to-join-phone-error',
        title: t('w.warning'),
        message: t('invalid.phone'),
        color: 'orange',
        icon: <IconAlertTriangle />,
      });
    }
  }

  return (
    <form
      onSubmit={form.onSubmit(
        (values) => inviteUserToGma({ phone: values.phone }),
        (errors) => handleErrorSubmit(errors)
      )}
    >
      <Stack gap="lg">
        <LoadingOverlay visible={isLoading} />
        <Text fz={'sm'}>{t('w.gmaSubscriptionMessage')}</Text>
        <Text fz={'sm'}>{t('w.gmaInformationMessage')}</Text>
        <Text fz={'sm'} fw={'bold'} c={'dimmed'}>
          {t('w.gmaAdditionalInformationMessage')} :
        </Text>
        <List size="sm" withPadding spacing="xs">
          <List.Item
            icon={
              <ThemeIcon color="hifivework.5" size={24} radius="xl">
                <IconMail style={{ width: rem(16), height: rem(16) }} />
              </ThemeIcon>
            }
          >
            {t('w.gmaPersonalMailMessage')}
          </List.Item>
          <List.Item
            icon={
              <ThemeIcon color="hifivework.5" size={24} radius="xl">
                <IconPhone style={{ width: rem(16), height: rem(16) }} />
              </ThemeIcon>
            }
          >
            {t('w.gmaMobilePhoneNumberMessage')}
          </List.Item>
        </List>
        <Stack gap="xl">
          <PhoneField
            value={form.values.phone}
            onChange={(newValue) => form.setFieldValue('phone', newValue)}
          />
          <Group justify={'flex-end'}>
            <Button leftSection={<IconMailFast />} type={'submit'}>
              {t('w.sendInvitation')}
            </Button>
          </Group>
        </Stack>
      </Stack>
    </form>
  );
}
