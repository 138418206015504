import { Stack } from '@mantine/core';
import {
  IconCash,
  IconCashBanknote,
  IconReceiptEuro,
  IconReceiptOff,
  IconReceiptRefund,
  IconReceiptTax,
} from '@tabler/icons-react';
import { useMemo } from 'react';

import CustomStatsGrid, {
  StatsGridData,
} from '../../../../components/CustomStatsGrid/CustomStatsGrid';
import { useI18n } from '../../../../contexts/I18nProvider';
import type { ExpenseReportObject } from '../../../../types/types';
import ExpenseReportsList from './ExpenseReportsList';

type Props = {
  expenseReports: ExpenseReportObject[];
  userFullname: string;
};

export default function EndOfMonthValidationExpenseReports({
  expenseReports,
  userFullname,
}: Props) {
  const { t } = useI18n();

  const dashboardData: StatsGridData[] | undefined = useMemo(() => {
    if (expenseReports) {
      return [
        {
          title: t('w.inProgress'),
          icon: IconReceiptEuro,
          value: getExpenseReportCount('INPROGRESS'),
        },
        {
          title: t('expenseReport.status.state.REFUNDED'),
          icon: IconReceiptRefund,
          value: getExpenseReportCount('REFUNDED'),
        },
        {
          title: t('expenseReport.status.state.REJECTED'),
          icon: IconReceiptOff,
          value: getExpenseReportCount('REJECTED'),
        },
        {
          title: t('w.totalExcludingTax'),
          icon: IconCashBanknote,
          value: getTotalExcludingTax().toFixed(2),
        },
        {
          title: t('w.totalVAT'),
          icon: IconReceiptTax,
          value: getTotalVAT().toFixed(2),
        },
        {
          title: t('w.totalInclVAT'),
          icon: IconCash,
          value: getTotalIncludingTax().toFixed(2),
        },
      ];
    }
  }, [expenseReports]);

  function getExpenseReportCount(
    state: 'REFUNDED' | 'REJECTED' | 'INPROGRESS'
  ) {
    switch (state) {
      case 'INPROGRESS':
        return expenseReports?.filter(
          (expenseReport) =>
            expenseReport.status.state === 'WAITING' ||
            expenseReport.status.state === 'VALID' ||
            expenseReport.status.state === 'PROCESSED'
        )?.length;
      default:
        return expenseReports?.filter(
          (expenseReport) => expenseReport.status.state === state
        )?.length;
    }
  }

  function getTotalIncludingTax() {
    return expenseReports?.reduce(
      (accumulator, expenseReport) => accumulator + expenseReport.amount,
      0
    );
  }

  function getTotalVAT() {
    const result: number[] = [];
    if (expenseReports) {
      expenseReports?.forEach((expenseReport) =>
        expenseReport.additionValueVATList?.forEach((value) =>
          result.push(value)
        )
      );
    }
    return result.reduce((accumulator, value) => accumulator + value, 0);
  }

  function getTotalExcludingTax() {
    return getTotalIncludingTax() - getTotalVAT();
  }

  return (
    <Stack gap={'xl'}>
      {dashboardData && <CustomStatsGrid data={dashboardData} />}
      <ExpenseReportsList
        expenseReports={expenseReports}
        userFullname={userFullname}
      />
    </Stack>
  );
}
