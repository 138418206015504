import { Options, Table } from '@ckprivate/ckf-ui';
import { DataTableColumn } from 'mantine-datatable';
import React, { useMemo, useState } from 'react';

import UnitBadge from '../../../../components/badges/UnitBadge';
import { useI18n } from '../../../../contexts/I18nProvider';
import type { DivisionAccountant } from '../../../../types/api/response/accountant';
import type { CompanyResponse } from '../../../../types/api/response/company';
import {
  getHiddenColumns,
  saveHiddenColumns,
} from '../../../../utils/optionsPreferences';
import PayrollOfficerBadge from './PayrollOfficerBadge';

type Props = {
  divisionAccountant?: DivisionAccountant[];
  company: CompanyResponse;
};

const componentName = 'PayrollOfficerDivisionTable';
export default function PayrollOfficerDivisionTable({
  divisionAccountant,
  company,
}: Props) {
  const { t, lang } = useI18n();

  const columns: DataTableColumn<DivisionAccountant>[] = useMemo(
    () => [
      {
        accessor: 'name',
        title: company?.divisionLabel ? company.divisionLabel : t('w.unit'),
        sortable: true,
        ellipsis: true,
        render: ({ name }) => <UnitBadge unit={name} />,
      },
      {
        accessor: 'accountantUser',
        title: t('w.followedBy'),
        sortable: true,
        ellipsis: true,
        render: ({ accountantUser }) => (
          <>
            {accountantUser?.map((user) => (
              <PayrollOfficerBadge key={user.id} payrollOfficer={user} />
            ))}
          </>
        ),
      },
    ],
    []
  );

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    search: {
      accessor: 'name',
      label: t('w.name'),
    },
    hiddenColumns: getHiddenColumns({ componentName }),
    updateHiddenColumns,
  });

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }
  return (
    <Table
      pinFirstColumn
      pinLastColumn
      options={options}
      columns={columns}
      rows={divisionAccountant}
      lang={lang}
    />
  );
}
