import { FilterOption, Options, Table } from '@ckprivate/ckf-ui';
import { ActionIcon, Drawer, Group, Title } from '@mantine/core';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconArrowRightCircle,
  IconCheck,
  IconEdit,
  IconFileDownload,
  IconFilePlus,
  IconFolderPlus,
  IconTrash,
} from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { DataTableColumn } from 'mantine-datatable';
import React, { useEffect, useMemo, useState } from 'react';

import CustomTooltip from '../../../components/CustomTooltip';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import useFetchDocuments from '../../../hooks/useFetchDocuments';
import DocumentsService from '../../../services/DocumentsService';
import {
  DocumentPathType,
  DocumentPropertySpace,
  DocumentView,
} from '../../../types/types';
import { handleOpenDocumentFile } from '../../../utils/attachmentFile';
import { getFolderPathToString } from '../../../utils/documents';
import { milliToFullDateString } from '../../../utils/format';
import {
  getFilter,
  getHiddenColumns,
  saveFilter,
  saveHiddenColumns,
} from '../../../utils/optionsPreferences';
import DocumentFolderOrFileItem from './DocumentFolderOrFileItem';
import DocumentsFolderPathWrapper from './DocumentsFolderPathWrapper';
import DocumentsForm from './DocumentsForm';
import DocumentsMoveFileWrapper from './DocumentsMoveFileWrapper';

const componentName = 'DocumentsTable';

type Props = {
  hasPermissionToEditSpace: boolean | undefined;
  space: DocumentPropertySpace;
  userId: string;
  companyId: string;
  accountantCompanyId: string | null;
};

export default function DocumentsTable({
  hasPermissionToEditSpace,
  space,
  userId,
  companyId,
  accountantCompanyId,
}: Props) {
  const { t, lang } = useI18n();
  const { access_token } = useAuth();
  const [folderPath, setFolderPath] = useState<string[]>(['/']);
  const { folderList, refetchDocuments, isLoading, isFetching } =
    useFetchDocuments(
      folderPath,
      space,
      companyId,
      userId,
      accountantCompanyId
    );
  const [drawer, setDrawer] = useState<{
    opened: boolean;
    isFolder: boolean;
    document: DocumentView | null;
  }>({
    opened: false,
    isFolder: false,
    document: null,
  });
  const [selectedDocuments, setSelectedDocuments] = useState<any>([]);

  useEffect(() => {
    if (folderPath) refetchDocuments();
  }, [folderPath]);

  const rightCustomActions = useMemo(
    () => [
      <CustomTooltip
        disabled={!selectedDocuments.length}
        label={t('w.delete')}
        key={'delete-files-button'}
      >
        <ActionIcon
          variant="light"
          disabled={!selectedDocuments.length}
          onClick={handleDeleteSelectedDocuments}
        >
          <IconTrash style={{ width: '70%', height: '70%' }} stroke={1.5} />
        </ActionIcon>
      </CustomTooltip>,
      <CustomTooltip
        label={`${t('w.add')} ${t('w.file').toLowerCase()}`}
        key={'add-file-button'}
      >
        <ActionIcon
          variant="light"
          onClick={() =>
            setDrawer({ ...drawer, opened: true, isFolder: false })
          }
        >
          <IconFilePlus style={{ width: '70%', height: '70%' }} stroke={1.5} />
        </ActionIcon>
      </CustomTooltip>,
      <CustomTooltip
        label={`${t('w.add')} ${t('w.folder').toLowerCase()}`}
        key={'add-folder-button'}
      >
        <ActionIcon
          variant="light"
          onClick={() => setDrawer({ ...drawer, opened: true, isFolder: true })}
        >
          <IconFolderPlus
            style={{ width: '70%', height: '70%' }}
            stroke={1.5}
          />
        </ActionIcon>
      </CustomTooltip>,
    ],
    [selectedDocuments, hasPermissionToEditSpace]
  );

  function handleNavigateFolderOrOpenFilePathClick(
    title: string,
    isFolder: boolean,
    documentId: string,
    extension: string
  ) {
    if (isFolder) {
      const _path = [...folderPath];
      _path.push(title);
      setFolderPath(_path);
    } else {
      handleOpenDocumentFile(
        companyId,
        documentId,
        title,
        extension?.replace('.', ''),
        access_token,
        t
      );
    }
  }

  function handleOpenMoveFileModal(
    documentId: string,
    title: string,
    comment: string,
    type: DocumentPathType
  ): void {
    modals.open({
      modalId: `open-move-file-${documentId}-modal`,
      title: (
        <Title size={'h3'} component="p">
          {title}
        </Title>
      ),
      size: 'xl',
      children: (
        <DocumentsMoveFileWrapper
          userId={userId}
          space={space}
          companyId={companyId}
          documentId={documentId}
          documentTitle={title}
          comment={comment}
          type={type}
          refetchDocumentsTable={refetchDocuments}
          accountantCompanyId={accountantCompanyId}
        />
      ),
    });
  }

  const columns: DataTableColumn<DocumentView>[] = useMemo(
    () => [
      {
        accessor: 'meta.title',
        title: t('w.name'),
        sortable: true,
        ellipsis: true,
        render: (document) => (
          <DocumentFolderOrFileItem
            key={document.id}
            document={document}
            handleNavigateFolderOrOpenFilePathClick={() =>
              handleNavigateFolderOrOpenFilePathClick(
                document.meta.title,
                document.path.type === 'FOLDER',
                document.id,
                document.path.extension
              )
            }
          />
        ),
      },
      {
        accessor: 'meta.comment',
        title: t('w.comment'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'created',
        title: t('w.creationDate'),
        sortable: true,
        ellipsis: true,
        render: ({ created }) =>
          created ? milliToFullDateString(created) : '',
      },
      {
        accessor: 'actions',
        title: t('w.actions'),
        textAlign: 'right',
        sortable: false,
        width: 110,
        render: (document) => (
          <Group gap={4} justify="end" wrap={'nowrap'}>
            {!Boolean(document.path.type === 'FOLDER') && (
              <CustomTooltip label={'Déplacer'}>
                <ActionIcon
                  size="sm"
                  color="blue"
                  variant={'subtle'}
                  disabled={!hasPermissionToEditSpace}
                  onClick={() =>
                    handleOpenMoveFileModal(
                      document.id,
                      document.meta.title,
                      document.meta.comment,
                      document.path.type
                    )
                  }
                >
                  <IconArrowRightCircle size={16} />
                </ActionIcon>
              </CustomTooltip>
            )}
            {!Boolean(document.path.type === 'FOLDER') && (
              <CustomTooltip label={t('w.download')}>
                <ActionIcon
                  size="sm"
                  color="blue"
                  variant={'subtle'}
                  onClick={() =>
                    handleOpenDocumentFile(
                      companyId,
                      document.id,
                      document.meta.title,
                      document.path.extension?.replace('.', ''),
                      access_token,
                      t
                    )
                  }
                >
                  <IconFileDownload size={16} />
                </ActionIcon>
              </CustomTooltip>
            )}
            <CustomTooltip label={t('w.modify')}>
              <ActionIcon
                size="sm"
                color="green"
                variant={'subtle'}
                disabled={!hasPermissionToEditSpace}
                onClick={() =>
                  setDrawer({
                    opened: true,
                    document: document,
                    isFolder: document.path.type === 'FOLDER',
                  })
                }
              >
                <IconEdit size={16} />
              </ActionIcon>
            </CustomTooltip>
            <CustomTooltip label={t('w.delete')}>
              <ActionIcon
                size="sm"
                color="red"
                variant={'subtle'}
                disabled={!hasPermissionToEditSpace}
                onClick={() =>
                  handleOpenConfirmDeleteDocument(
                    document.id,
                    document.meta.title,
                    document.path.type === 'FOLDER'
                  )
                }
              >
                <IconTrash size={16} />
              </ActionIcon>
            </CustomTooltip>
          </Group>
        ),
      },
    ],
    [folderPath, hasPermissionToEditSpace]
  );

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    search: {
      accessor: 'meta.title',
      label: t('w.name'),
    },
    hiddenColumns: getHiddenColumns({
      componentName,
      hiddenColumns: [],
    }),
    updateHiddenColumns,
    filter: getFilter({
      componentName,
      filter: [],
    }),
    updateFilter,
  });

  function updateFilter(newFilter: FilterOption[]): void {
    const _options = { ...options };
    _options.filter = newFilter;
    saveFilter({ componentName, filter: newFilter });
    setOptions(_options);
  }

  function updateHiddenColumns(newHiddenColumns: string[]): void {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  function handleCloseDrawer(): void {
    setDrawer({ opened: false, document: null, isFolder: false });
  }

  function handleNavigateFolderPathClick(index: number): void {
    const _folderPath = [...folderPath];
    if (index !== _folderPath.length - 1) {
      const result = _folderPath.slice(0, index + 1);
      setFolderPath(result);
    }
  }

  const { mutate: deleteDocument } = useMutation({
    mutationFn: (variables: string) =>
      DocumentsService.deleteDocument(companyId, variables),
    onSuccess: () => {
      refetchDocuments();
      setSelectedDocuments([]);
      showNotification({
        id: `delete-document-successful`,
        message: t('w.success'),
        color: 'green',
        icon: <IconCheck />,
      });
    },
  });

  function handleOpenConfirmDeleteDocument(
    documentId: string,
    documentTitle: string,
    isFolder: boolean
  ) {
    modals.openConfirmModal({
      modalId: `confirm-delete-document${documentId}-modal`,
      title: (
        <Title size={'h3'} component="p">{`${t('w.confirmDeletion')} ${
          isFolder ? t('w.folder').toLowerCase() : t('w.file').toLowerCase()
        } "${documentTitle}"`}</Title>
      ),
      size: 'xl',
      labels: { confirm: t('w.delete'), cancel: t('w.cancel') },
      onConfirm: () => deleteDocument(documentId),
    });
  }

  function handleDeleteSelectedDocuments() {
    selectedDocuments.forEach((document: DocumentView) =>
      deleteDocument(document.id)
    );
  }

  return (
    <>
      <DocumentsFolderPathWrapper
        key={'breadcrumbs'}
        folderPath={folderPath}
        handleNavigateFolderPathClick={handleNavigateFolderPathClick}
        handleBackToFolderRoot={() => setFolderPath(['/'])}
      />
      <Table
        onSelect={(documents) => {
          setSelectedDocuments(documents);
        }}
        options={options}
        rows={folderList}
        columns={columns}
        lang={lang}
        fetching={isLoading || isFetching}
        rightCustomActions={hasPermissionToEditSpace ? rightCustomActions : []}
        height={'calc(100vh - 300px)'}
      />
      <Drawer
        opened={drawer.opened}
        onClose={handleCloseDrawer}
        position={'right'}
        title={
          <Title size={'h3'} component={'p'}>{`${
            Boolean(drawer.document) ? t('w.modify') : t('w.new')
          } ${
            Boolean(drawer.document)
              ? drawer.isFolder
                ? t('w.folder').toLowerCase()
                : t('w.file').toLowerCase()
              : ''
          } ${drawer.document ? drawer.document?.meta.title : ''} ${
            Boolean(drawer.document)
              ? ''
              : drawer.isFolder
              ? t('w.folder').toLowerCase()
              : t('w.file').toLowerCase()
          }`}</Title>
        }
      >
        <DocumentsForm
          isFolder={drawer.isFolder}
          userId={userId}
          space={space}
          document={drawer.document}
          path={getFolderPathToString(folderPath)}
          companyId={companyId}
          handleCloseDrawer={handleCloseDrawer}
          refetch={refetchDocuments}
          accountantCompanyId={accountantCompanyId}
        />
      </Drawer>
    </>
  );
}
