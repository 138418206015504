import { Button, Menu } from '@mantine/core';
import { FloatingPosition } from '@mantine/core/lib/components/Floating/types';
import { PopoverWidth } from '@mantine/core/lib/components/Popover/Popover.types';
import { ReactNode } from 'react';

import { useI18n } from '../contexts/I18nProvider';

type Props = {
  buttonLabel: string;
  buttonVariant:
    | 'filled'
    | 'outline'
    | 'light'
    | 'white'
    | 'default'
    | 'subtle'
    | 'gradient';
  leftSection: ReactNode;
  menuLabel: string;
  menuItems: { label: string; icon: ReactNode; onClick: () => void }[];
  buttonDisabled?: boolean;
  position?: FloatingPosition;
  buttonLoading?: boolean;
  width?: PopoverWidth;
  rightSection?: ReactNode;
};

export default function CustomMenu({
  buttonLabel,
  buttonVariant,
  leftSection,
  rightSection,
  menuLabel,
  menuItems,
  buttonDisabled,
  position,
  buttonLoading,
  width = 200,
}: Props) {
  const { t } = useI18n();

  return (
    <Menu shadow="md" width={width} position={position}>
      <Menu.Target>
        <Button
          disabled={buttonDisabled}
          variant={buttonVariant}
          leftSection={leftSection}
          rightSection={rightSection}
          loading={buttonLoading}
        >
          {buttonLabel}
        </Button>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>{menuLabel}</Menu.Label>
        {menuItems.length > 0 ? (
          menuItems.map((menuItem, index) => (
            <Menu.Item
              c={'hifivework.2'}
              leftSection={menuItem.icon}
              key={`${menuItem.label}-${index}`}
              onClick={menuItem.onClick}
            >
              {menuItem.label}
            </Menu.Item>
          ))
        ) : (
          <Menu.Item>{t('w.noValue')}</Menu.Item>
        )}
      </Menu.Dropdown>
    </Menu>
  );
}
