import {
  Button,
  Container,
  Flex,
  Group,
  LoadingOverlay,
  rem,
  SimpleGrid,
  Space,
  Stack,
  Stepper,
  Text,
  TextInput,
} from '@mantine/core';
import { isEmail, isNotEmpty, useForm } from '@mantine/form';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconAddressBook,
  IconAlertTriangle,
  IconAt,
  IconBuilding,
  IconCheck,
  IconChecks,
  IconCirclePlus,
  IconPhone,
  IconUserStar,
  IconX,
} from '@tabler/icons-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import ReactFlagsSelect from 'react-flags-select';

import CustomCompanyAvatar from '../../../../components/CustomAvatar/CustomCompanyAvatar';
import CustomUserAvatar from '../../../../components/CustomAvatar/CustomUserAvatar';
import LabelText from '../../../../components/LabelText/LabelText';
import PhoneField from '../../../../components/PhoneField/PhoneField';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import useCompany from '../../../../hooks/useCompany';
import CompanyService from '../../../../services/CompanyService';
import type { SubscribeRequest } from '../../../../types/api/payload/company';
import { CountryEnum } from '../../../../types/api/response/company';
import handleErrorMessage from '../../../../utils/handleErrorMessage';

interface FormValues {
  companyName: string;
  companyMail: string;
  companyPhone: string | undefined;
  companyAddress1: string;
  companyZipCode: string;
  companyCity: string;
  companyCountryCode: CountryEnum;
  userMail: string;
  userFirstname: string;
  userLastname: string;
}

type Props = {
  linkAsAccountant?: boolean;
};
export default function AddCustomerCompanyForm({
  linkAsAccountant = false,
}: Props) {
  const { user } = useAuth();
  const { id: companyId } = useCompany(user);
  const { t } = useI18n();
  const queryClient = useQueryClient();
  const [active, setActive] = useState(0);

  const form = useForm<FormValues>({
    initialValues: {
      companyName: '',
      companyMail: '',
      companyPhone: '',
      companyAddress1: '',
      companyZipCode: '',
      companyCity: '',
      companyCountryCode: user?.company?.countryCode || CountryEnum.FR,
      userMail: '',
      userFirstname: '',
      userLastname: '',
    },
    validate: {
      userFirstname: isNotEmpty(t('w.required')),
      userLastname: isNotEmpty(t('w.required')),
      companyName: isNotEmpty(t('w.required')),
      companyMail: isEmail(t('invalid.email')),
      userMail: isEmail(t('invalid.email')),
    },
  });

  const { mutate: addCustomerCompanyViaPaymentAccountCompany, isLoading } =
    useMutation({
      mutationFn: (variables: SubscribeRequest) =>
        CompanyService.subscribeCompanyViaPaymentAccountCompany(
          user.company?.id!,
          variables,
          linkAsAccountant
        ),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [
            'CharteredAccountantService.accountantCustomersList',
            companyId,
          ],
        });
        showNotification({
          id: 'subscribe-company-via-payment-account-successful',
          title: t('w.success'),
          message: `${t('w.customerCompanyAdded')}`,
          color: 'green',
          icon: <IconCheck />,
        });
        modals.closeAll();
      },
      onError: (error) => {
        // @ts-ignore
        if (error?.response.status === 400) {
          showNotification({
            id: 'subscribe-company-via-payment-account-error-payment-account-required',
            title: t('w.paymentAccountRequired'),
            message: t('w.paymentAccountRequiredAdvice'),
            color: 'orange',
            icon: <IconAlertTriangle />,
          });
        } else {
          showNotification({
            id: 'subscribe-company-via-payment-account-error',
            title: t('w.error'),
            message: handleErrorMessage(error, t),
            color: 'red',
            icon: <IconX />,
          });
        }
      },
    });

  function handleValidateFormClick() {
    if (active === 0) {
      form.validateField('userFirstname');
      form.validateField('userLastname');
      form.validateField('userMail');
      if (
        form.isValid('userFirstname') &&
        form.isValid('userLastname') &&
        form.isValid('userMail')
      ) {
        setActive(active + 1);
      }
    }
    if (active === 1) {
      form.validateField('companyName');
      form.validateField('companyMail');
      if (form.isValid('companyName') && form.isValid('companyMail')) {
        setActive(active + 1);
      }
    }
  }

  function handleAddCustomerCompanyFormSubmit(values: FormValues) {
    const data: SubscribeRequest = {
      company: {
        name: values.companyName,
        mail: values.companyMail,
        phone: values.companyPhone,
        address: {
          address1: values.companyAddress1,
          address2: '',
          zipCode: values.companyZipCode,
          city: values.companyCity,
        },
        countryCode: values.companyCountryCode,
      },
      user: {
        mail: values.userMail,
        firstname: values.userFirstname,
        lastname: values.userLastname,
        phone: values.companyPhone,
      },
    };
    addCustomerCompanyViaPaymentAccountCompany(data);
  }

  const colorIcon = 'var(--mantine-color-hifivework-5)';

  return (
    <form
      onSubmit={form.onSubmit((values) =>
        handleAddCustomerCompanyFormSubmit(values)
      )}
      style={{
        height: '90vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <LoadingOverlay visible={isLoading} />
      <Space h={'xl'} />
      <Stepper
        active={active}
        styles={{
          stepLabel: { fontWeight: 'bold' },
          stepDescription: { fontWeight: 'bold' },
          content: { marginTop: 96 },
          root: { height: '90%' },
        }}
      >
        <Stepper.Step
          label={t('w.adminInformation')}
          description={t('w.newCompany')}
          icon={<IconUserStar style={{ width: rem(18), height: rem(18) }} />}
        >
          <SimpleGrid cols={2} spacing="xl">
            <TextInput
              placeholder={"Nom de l'administrateur"}
              required
              label={<LabelText text={t('w.name')} />}
              {...form.getInputProps('userLastname')}
            />
            <TextInput
              placeholder={"Prénom de l'administrateur"}
              required
              label={<LabelText text={t('w.firstname')} />}
              {...form.getInputProps('userFirstname')}
            />
            <TextInput
              placeholder={"Adresse e-mail de l'administrateur"}
              required
              label={<LabelText text={t('w.emailAddress')} />}
              {...form.getInputProps('userMail')}
            />
          </SimpleGrid>
        </Stepper.Step>
        <Stepper.Step
          label={t('w.generalInformations')}
          description={t('w.newCompany')}
          icon={<IconBuilding style={{ width: rem(18), height: rem(18) }} />}
        >
          <SimpleGrid cols={2} spacing="xl">
            <TextInput
              placeholder={t('w.companyName')}
              required
              label={<LabelText text={t('w.companyName')} />}
              {...form.getInputProps('companyName')}
            />
            <TextInput
              placeholder={t('w.address')}
              label={<LabelText text={t('w.address')} />}
              {...form.getInputProps('companyAddress1')}
            />
            <TextInput
              placeholder={t('w.zipCode')}
              label={<LabelText text={t('w.zipCode')} />}
              {...form.getInputProps('companyZipCode')}
            />
            <TextInput
              placeholder={t('w.city')}
              label={<LabelText text={t('w.city')} />}
              {...form.getInputProps('companyCity')}
            />
            <TextInput
              placeholder={t('w.emailAddress')}
              required
              label={<LabelText text={t('w.emailAddress')} />}
              {...form.getInputProps('companyMail')}
            />
            <PhoneField
              value={form.values.companyPhone}
              onChange={(newValue) =>
                form.setFieldValue('companyPhone', newValue)
              }
            />
            <Stack gap={2}>
              <LabelText text={t('w.countries')} />
              <ReactFlagsSelect
                selected={form.values.companyCountryCode!}
                onSelect={(code) =>
                  form.setFieldValue('companyCountryCode', code as CountryEnum)
                }
                searchable
                searchPlaceholder={t('w.search')}
              />
            </Stack>
          </SimpleGrid>
        </Stepper.Step>
        <Stepper.Step
          label={'Récap et validation'}
          icon={<IconChecks style={{ width: rem(18), height: rem(18) }} />}
        >
          <Space h={'xl'} />
          <Space h={'xl'} />
          <Container size={'lg'}>
            <Flex
              direction={{ base: 'column', sm: 'row' }}
              gap={{ base: 'xl', sm: 'lg' }}
              justify={'space-between'}
              align={'center'}
            >
              <Group wrap="nowrap">
                <CustomUserAvatar userId={''} size={94} />
                <div>
                  <Text fz="xs" fw={700} c="dimmed">
                    {t('administrator')}
                  </Text>
                  <Text fz="lg" fw={'bold'}>
                    {`${form.values.userFirstname} ${form.values.userLastname}`}
                  </Text>
                  <Group wrap="nowrap" gap={10} mt={3}>
                    <IconAt stroke={1.5} size="1rem" color={colorIcon} />
                    <Text fz="xs" c="dimmed">
                      {form.values.userMail}
                    </Text>
                  </Group>
                </div>
              </Group>
              <Group wrap="nowrap">
                <CustomCompanyAvatar size={120} />
                <div>
                  <Text fz="xs" fw={700} c="dimmed">
                    {t('w.customerCompany')}
                  </Text>
                  <Text fz="lg" fw={'bold'}>
                    {form.values.companyName}
                  </Text>
                  <Group wrap="nowrap" gap={10} mt={3}>
                    <IconAt stroke={1.5} size="1rem" color={colorIcon} />
                    <Text fz="xs" c="dimmed">
                      {form.values.companyMail}
                    </Text>
                  </Group>
                  <Group wrap="nowrap" gap={10} mt={5}>
                    <IconPhone stroke={1.5} size="1rem" color={colorIcon} />
                    <Text fz="xs" c="dimmed">
                      {form.values.companyPhone === ''
                        ? t('w.noValue')
                        : form.values.companyPhone}
                    </Text>
                  </Group>
                  <Group wrap="nowrap" gap={10} mt={5}>
                    <IconAddressBook
                      stroke={1.5}
                      size="1rem"
                      color={colorIcon}
                    />
                    <Text fz="xs" c="dimmed">
                      {form.values.companyAddress1 === ''
                        ? t('noValue')
                        : `${form.values.companyAddress1} - ${form.values.companyZipCode} ${form.values.companyCity}`}
                    </Text>
                  </Group>
                </div>
              </Group>
            </Flex>
            <Space h={'xl'} />
            <Space h={'xl'} />
            <Space h={'xl'} />
            <Space h={'xl'} />
          </Container>
        </Stepper.Step>
      </Stepper>
      <Group justify={'flex-end'}>
        {active !== 0 && (
          <Button onClick={() => setActive(active - 1)}>{t('w.back')}</Button>
        )}
        {active !== 2 && (
          <Button onClick={handleValidateFormClick}>{t('w.next')}</Button>
        )}
        {active === 2 && (
          <Button leftSection={<IconCirclePlus />} type={'submit'}>
            {`${t('w.add')} ${t('w.customers').slice(0, -1).toLowerCase()}`}
          </Button>
        )}
      </Group>
    </form>
  );
}
