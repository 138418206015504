import { ActionIcon, Center, Flex, Loader, Pill, Text } from '@mantine/core';
import { IconRefresh } from '@tabler/icons-react';
import * as React from 'react';

import { useI18n } from '../contexts/I18nProvider';

type Props = {
  loading: boolean;
  refresh: () => void;
};

export default function LoadingBadge({ loading, refresh }: Props) {
  const { t } = useI18n();
  return (
    <Center mt={-12} mb={4}>
      <Pill size="xl" display="flex">
        {loading ? (
          <Flex h="100%" justify="center" align="center" gap="sm">
            <Loader size={25} />
            <ActionIcon variant="subtle" aria-label="refresh" onClick={refresh}>
              <IconRefresh
                style={{ width: '70%', height: '70%' }}
                stroke={1.5}
              />
            </ActionIcon>
            <Text>{t('w.loading')}</Text>
          </Flex>
        ) : (
          <Flex h="100%" justify="center" align="center" gap="sm">
            <ActionIcon variant="subtle" aria-label="refresh" onClick={refresh}>
              <IconRefresh
                style={{ width: '70%', height: '70%' }}
                stroke={1.5}
              />
            </ActionIcon>
            <Text>{t('w.refresh')}</Text>
          </Flex>
        )}
      </Pill>
    </Center>
  );
}
