import { Badge } from '@mantine/core';
import React from 'react';

import { useI18n } from '../../contexts/I18nProvider';

type Props = {
  value: 'error' | 'success';
};

export default function ActiveInactiveBadge({ value }: Props) {
  const { t } = useI18n();

  return (
    <Badge
      styles={{
        root: { textTransform: 'none' },
        label: { color: value === 'success' ? '#0A9182' : '#E70D4F' },
      }}
      color={value === 'success' ? '#CEE9E6' : '#FDE6ED'}
    >
      {t(`w.${value}`)}
    </Badge>
  );
}
