import { Button, Flex, LoadingOverlay, Stack } from '@mantine/core';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconArrowRightCircle, IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';

import { useI18n } from '../../../contexts/I18nProvider';
import useFetchDocuments from '../../../hooks/useFetchDocuments';
import DocumentsService, {
  UpdateOrCreateDocumentPayload,
} from '../../../services/DocumentsService';
import { DocumentPathType, DocumentPropertySpace } from '../../../types/types';
import { getFolderPathToString } from '../../../utils/documents';
import handleErrorMessage from '../../../utils/handleErrorMessage';
import DocumentFolderOrFileItem from './DocumentFolderOrFileItem';
import DocumentsFolderPathWrapper from './DocumentsFolderPathWrapper';

type Props = {
  space: DocumentPropertySpace;
  companyId: string;
  documentId: string;
  documentTitle: string;
  comment: string;
  type: DocumentPathType;
  refetchDocumentsTable: () => void;
  userId: string;
  accountantCompanyId: string | null;
};

export default function DocumentsMoveFileWrapper({
  space,
  userId,
  companyId,
  documentId,
  documentTitle,
  comment,
  type,
  refetchDocumentsTable,
  accountantCompanyId,
}: Props) {
  const { t } = useI18n();
  const [folderPath, setFolderPath] = useState<string[]>(['/']);
  const { folderList, refetchDocuments, isLoading, isFetching } =
    useFetchDocuments(
      folderPath,
      space,
      companyId,
      userId,
      accountantCompanyId
    );

  useEffect(() => {
    if (folderPath) refetchDocuments();
  }, [folderPath]);

  function handleNavigateFolderOrOpenFilePathClick(title: string): void {
    const _path = [...folderPath];
    _path.push(title);
    setFolderPath(_path);
  }

  function handleNavigateFolderPathClick(index: number): void {
    const _folderPath = [...folderPath];
    if (index !== _folderPath.length - 1) {
      const result = _folderPath.slice(0, index + 1);
      setFolderPath(result);
    }
  }

  const { mutate: moveFileToFolder, isLoading: isMoveFileToFolderLoading } =
    useMutation({
      mutationFn: (variables: UpdateOrCreateDocumentPayload) =>
        DocumentsService.updateOrCreateDocument(
          companyId,
          accountantCompanyId,
          variables
        ),
      onSuccess: (data) => {
        const { id } = data;
        modals.close(`open-move-file-${documentId}-modal`);
        refetchDocumentsTable();
        showNotification({
          id: `move-file-${id}-successful`,
          title: t('w.success'),
          message: `${t('w.file')} déplacé`,
          color: 'green',
          icon: <IconCheck />,
        });
      },
      onError: (error) =>
        showNotification({
          id: `move-file-error`,
          title: t('w.error'),
          message: handleErrorMessage(error, t),
          color: 'red',
          icon: <IconX />,
        }),
    });

  function handleMoveFileToFolderClick() {
    moveFileToFolder({
      title: documentTitle,
      comment: comment,
      path: getFolderPathToString(folderPath),
      documentId: documentId,
      type: type,
      space: space,
      userId: userId,
      fileName: null,
      file: null,
    });
  }

  return (
    <Stack>
      <LoadingOverlay visible={isLoading || isFetching} />
      <DocumentsFolderPathWrapper
        folderPath={folderPath}
        handleNavigateFolderPathClick={handleNavigateFolderPathClick}
        handleBackToFolderRoot={() => setFolderPath(['/'])}
      />
      {folderList
        .filter((document) => document.path.type === 'FOLDER')
        .map((folder) => (
          <DocumentFolderOrFileItem
            key={folder.id}
            document={folder}
            handleNavigateFolderOrOpenFilePathClick={() =>
              handleNavigateFolderOrOpenFilePathClick(folder.meta.title)
            }
          />
        ))}
      <Flex justify={'flex-end'} mt={'md'}>
        <Button
          loading={isMoveFileToFolderLoading}
          leftSection={<IconArrowRightCircle />}
          onClick={handleMoveFileToFolderClick}
        >
          Déplacer
        </Button>
      </Flex>
    </Stack>
  );
}
