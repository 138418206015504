import { Button, Flex, LoadingOverlay, Select } from '@mantine/core';
import { isNotEmpty, useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';

import LabelText from '../../../../components/LabelText/LabelText';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import useCompany from '../../../../hooks/useCompany';
import CharteredAccountantService from '../../../../services/CharteredAccountantService';
import type { CompanyAccountantInfoViewClient } from '../../../../types/api/response/accountant';
import type { CompanyResponse } from '../../../../types/api/response/company';
import handleErrorMessage from '../../../../utils/handleErrorMessage';

type Props = {
  accountantEmployees: { value: string; label: string }[];
  customerDivisionCompany: CompanyResponse | null;
  refetchCustomerDivisionsData: () => void;
  onClose: () => void;
  accountantsToConfigure: CompanyAccountantInfoViewClient | null;
  refetchAccountantCustomersData: () => void;
};

export default function AddAccountantToCustomerDivisionForm({
  accountantEmployees,
  customerDivisionCompany,
  refetchCustomerDivisionsData,
  accountantsToConfigure,
  onClose,
  refetchAccountantCustomersData,
}: Props) {
  const { t } = useI18n();
  const { user } = useAuth();
  const { id: companyId } = useCompany(user);

  const form = useForm({
    initialValues: {
      accountantId: '',
    },
    validate: {
      accountantId: isNotEmpty(t('w.required')),
    },
  });

  const { mutate: assignAccountantToCustomerDivisionCompany, isLoading } =
    useMutation({
      mutationFn: (variables: {
        userAccountantId: string;
        divisionId: string | undefined;
        generalManger: boolean;
      }) =>
        CharteredAccountantService.assignAccountantToCustomerCompany(
          accountantsToConfigure?.clientCompanyId!,
          companyId,
          variables.userAccountantId,
          variables.divisionId,
          variables.generalManger
        ),
      onSuccess: (data) => {
        if (data) {
          refetchAccountantCustomersData();
          refetchCustomerDivisionsData();
          onClose();
          showNotification({
            id: `assign-accountant-to-customer-company-division-${customerDivisionCompany?.name}`,
            title: t('w.success'),
            message: t(
              'w.payrollOfficerHasBeenAssignedToDivision',
              customerDivisionCompany?.name
            ),
            color: 'green',
            icon: <IconCheck />,
          });
        }
      },
      onError: (error) =>
        showNotification({
          id: 'assign-accountant-to-customer-company-division-error',
          title: t('w.error'),
          message: handleErrorMessage(error, t),
          color: 'red',
          icon: <IconX />,
        }),
    });

  function handleAssignAccountantToCustomerDivision(
    values: ReturnType<
      (values: { accountantId: string }) => {
        accountantId: string;
      }
    >
  ) {
    assignAccountantToCustomerDivisionCompany({
      userAccountantId: values.accountantId,
      divisionId: customerDivisionCompany?.id,
      generalManger: false,
    });
  }

  return (
    <form
      onSubmit={form.onSubmit((values) =>
        handleAssignAccountantToCustomerDivision(values)
      )}
    >
      <LoadingOverlay visible={isLoading} />
      <Flex align={'flex-end'} justify={'space-between'}>
        <Select
          required
          w={'50%'}
          label={<LabelText text={t('w.payrollOfficers')} />}
          data={accountantEmployees}
          placeholder={`${t('w.select')} ${t(
            'w.payrollOfficer'
          ).toLowerCase()}`}
          {...form.getInputProps('accountantId')}
        />
        <Button type={'submit'}>{t('w.add')}</Button>
      </Flex>
    </form>
  );
}
