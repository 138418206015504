import React, { useEffect } from 'react';

import { useAuth } from '../../contexts/AuthProvider';

export default function Logout() {
  const { logout } = useAuth();
  useEffect(() => {
    logout();
  }, []);

  return <div />;
}
