import { useQuery } from '@tanstack/react-query';

import { useAuth } from '../contexts/AuthProvider';
import PaymentAccountService from '../services/PaymentAccountService';
import { PaymentAccount } from '../types/types';

export default function usePaymentAccounts() {
  const fiveMinutes = 300000;
  const { user } = useAuth();
  const queryKey = ['PaymentAccountService.getPaymentAccounts', user?.id];

  const { data, isLoading } = useQuery({
    enabled: !!user?.id,
    queryKey,
    queryFn: PaymentAccountService.getPaymentAccounts,
    staleTime: fiveMinutes,
  });

  function get(paymentAccountId: string): PaymentAccount | undefined {
    if (!data?.length) return undefined;

    return data.find((item) => item.id === paymentAccountId);
  }

  return { paymentAccounts: data, isLoading, queryKey, get };
}
