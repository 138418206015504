import { Badge } from '@mantine/core';
import React from 'react';

import { useI18n } from '../../contexts/I18nProvider';
import type { BuiltInRoles } from '../../types/api/response/role';
import { roleName } from '../../utils/roleName';

type Props = {
  role: BuiltInRoles;
};

export default function RoleBadge({ role }: Props) {
  const { t } = useI18n();

  return (
    <Badge
      styles={{
        root: { textTransform: 'none' },
        label: { color: '#ebf2ff' },
      }}
      color="#647fcc"
    >
      {roleName(t, role)}
    </Badge>
  );
}
