import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';

import type { TranslateFunction } from '../types/front/common';

export function htmlToPdf(
  elementId: string,
  t: TranslateFunction,
  fileName?: string
) {
  const elementById: any = document.getElementById(elementId);
  html2canvas(elementById).then((canvas) => {
    const imgData = canvas.toDataURL('image/png');
    const pdf = new JsPDF('l', 'mm', 'a4');
    const imgProps = pdf.getImageProperties(imgData);
    let position = 20;
    const pdfWidth = 280;
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    const pageHeight = pdf.internal.pageSize.getHeight();
    let heightLeft = pdfHeight;
    // Capture first image
    pdf.addImage(imgData, 'PNG', 10, position, pdfWidth, pdfHeight);
    heightLeft -= pageHeight;
    while (heightLeft >= 0) {
      position = heightLeft - pdfHeight;
      pdf.addPage();
      pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, pdfHeight);
      heightLeft -= pageHeight;
    }

    pdf.save(fileName ? `${fileName}.pdf` : `${t(elementId)}.pdf`);
  });
}
