import type { IdentifiantCompany } from '../response/user';

export enum UserShowFieldEnum {
  mail = 'mail',
  firstname = 'firstname',
  lastname = 'lastname',
  fullname = 'fullname',
  phone = 'phone',
  lang = 'lang',
  employment = 'employment',
  valid = 'valid',
  termsAcceptance = 'termsAcceptance',
  fixedPriceManager = 'fixedPriceManager',
  securityRoleId = 'securityRoleId',
  companyId = 'companyId',
  divisionId = 'divisionId',
  firstManager = 'firstManager',
  secondManager = 'secondManager',
  onBoardingId = 'onBoardingId',
  identifiantsCompany = 'identifiantsCompany',
  personalBreakDay = 'personalBreakDay',
  paidFeatures = 'paidFeatures',
  lastLoginDate = 'lastLoginDate',
  company = 'company',
  division = 'division',
  nameOfN1 = 'nameOfN1',
  nameOfN2 = 'nameOfN2',
  myCompanies = 'myCompanies',
  role = 'role',
  onBoarding = 'onBoarding',
}

export type UserPayload = {
  firstname?: string;
  lastname?: string;
  mail?: string;
  lang?: string;
  securityRoleId?: string;
  divisionId?: string;
  valid?: boolean;
  avatar?: File | null;
  filename?: string | null;
  firstManager?: string;
  secondManager?: string;
  identifiantsCompany?: IdentifiantCompany[] | undefined;
  fixedPriceManager?: boolean;
  employment?: string;
};

export type UserPersonalBreakDay = {
  morning: boolean;
  afternoon: boolean;
  day: string;
  repeat?: string;
  editable?: boolean;
};

export enum OnBoardingStatus {
  ACTIVE = 'ACTIVE',
  ONBOARDING = 'ONBOARDING',
  ONBOARDING_EMPLOYE = 'ONBOARDING_EMPLOYE',
  ONBOARDING_VALIDATION = 'ONBOARDING_VALIDATION',
}
