import {
  ActionIcon,
  Badge,
  Button,
  Flex,
  Group,
  Table,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconCheck,
  IconClipboardPlus,
  IconDeviceFloppy,
  IconRefresh,
} from '@tabler/icons-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';

import ButtonCancel from '../../../../../../components/ButtonCancel';
import CustomTooltip from '../../../../../../components/CustomTooltip';
import ActionDeleteIcon from '../../../../../../components/icons/ActionDeleteIcon';
import { useI18n } from '../../../../../../contexts/I18nProvider';
import EndOfMonthService from '../../../../../../services/EndOfMonthService';
import { CompanyAccountantInfoViewClient } from '../../../../../../types/api/response/accountant';
import type { DossierProps } from '../../../../../../types/api/response/module';
import handleErrorMessage from '../../../../../../utils/handleErrorMessage';
import CompanyInfoWrapper from '../../../../../accountant/components/CompanyInfoWrapper';

type Props = {
  index: number;
  form: any;
  item: DossierProps;
  accountantCompanyId?: string;
  customers?: CompanyAccountantInfoViewClient[];
};

export default function DossierField({
  index,
  form,
  item,
  accountantCompanyId,
  customers,
}: Props) {
  const { t } = useI18n();
  const theme = useMantineTheme();
  const queryClient = useQueryClient();

  const {
    mutate: createClientCompanyFromOpenpayeDossier,
    isLoading: createClientCompanyFromOpenpayeDossierLoading,
  } = useMutation({
    mutationFn: (payload: { accountantCompanyId: string; dossierId: string }) =>
      EndOfMonthService.createClientCompanyFromOpenpayeDossier(
        payload.accountantCompanyId,
        payload.dossierId
      ),
    onSuccess: () => {
      showNotification({
        id: 'createClientFromDossier-success',
        message: t('w.success'),
        color: 'green',
        icon: <IconCheck />,
      });

      queryClient.invalidateQueries({
        queryKey: [
          'CharteredAccountantService.accountantCustomersList',
          accountantCompanyId,
        ],
      });

      modals.closeAll();
    },
    onError: (error) => {
      showNotification({
        id: 'createClientCompanyFromOpenpayeDossier-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        icon: <IconCheck />,
        color: 'red',
      });
    },
  });

  const {
    mutate: synchroOpenpayeDossierEmployeesToExistingClientCompany,
    isLoading: synchroOpenpayeDossierEmployeesToExistingClientCompanyLoading,
  } = useMutation({
    mutationFn: (payload: {
      accountantCompanyId: string;
      clientCompanyId: string;
      dossierId: string;
    }) =>
      EndOfMonthService.synchroOpenpayeDossierEmployeesToExistingClientCompany(
        payload.accountantCompanyId,
        payload.clientCompanyId,
        payload.dossierId
      ),
    onSuccess: () => {
      showNotification({
        id: 'createClientFromDossier-success',
        message: t('w.success'),
        color: 'green',
        icon: <IconCheck />,
      });

      queryClient.invalidateQueries({
        queryKey: [
          'CharteredAccountantService.accountantCustomersList',
          accountantCompanyId,
        ],
      });

      modals.closeAll();
    },
    onError: (error) => {
      showNotification({
        id: 'createClientCompanyFromOpenpayeDossier-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        icon: <IconCheck />,
        color: 'red',
      });
    },
  });

  const statusLabel = item.status
    ? item.employeeCount
      ? item.employeeCount > 0
        ? `${item.employeeCount} ${t('w.employees')}`
        : `${item.employeeCount} ${t('w.employee')}`
      : t('w.toTest')
    : item.status == null
    ? t('w.toTest')
    : t('com.feature.openPaye.fileCodeDoesNotExist');

  function statusColor() {
    switch (item.status) {
      case true:
        return theme.colors.green[4];
      case false:
        return theme.colors.red[4];
      default:
        return theme.colors.gray[4];
    }
  }

  function createClientFromDossierClick(_accountantCompanyId: string) {
    modals.open({
      modalId: 'createClientFromDossierConfirmMessage-modal',
      title: (
        <Title size={'h3'} component="p">{`${t(
          'w.pleaseConfirmYourAction'
        )}`}</Title>
      ),
      size: 'xl',
      children: (
        <Group>
          <Text size="sm">
            {t(
              'openpaye.createClientFromDossierConfirmMessage',
              item.dossierId
            )}
          </Text>
          <Flex
            justify={'flex-end'}
            gap={'md'}
            direction="row"
            wrap="wrap"
            mt={'md'}
            w="100%"
          >
            <ButtonCancel
              onClose={() =>
                modals.close('createClientFromDossierConfirmMessage-modal')
              }
            />
            <Button
              leftSection={<IconDeviceFloppy />}
              onClick={() => {
                showNotification({
                  id: 'createClientFromDossier-loading',
                  title: t('w.success'),
                  message: t('w.treatmentInProgress'),
                  color: 'green',
                  icon: <IconCheck />,
                });
                createClientCompanyFromOpenpayeDossier({
                  accountantCompanyId: _accountantCompanyId,
                  dossierId: item.dossierId,
                });
              }}
            >
              {t('w.confirm')}
            </Button>
          </Flex>
        </Group>
      ),
    });
  }

  function synchroDossierEmployeesToExistingClientClick(
    _accountantCompanyId: string
  ) {
    modals.open({
      modalId: 'synchroDossierEmployeesToExistingClient-chose-company-modal',
      title: (
        <Title size={'h3'} component="p">
          {t('openpaye.clickOnClientCompanyToStartTheSynchro', item.dossierId)}
        </Title>
      ),
      size: 'xl',
      children: (
        <Group>
          {customers?.map((customer) => (
            <Button
              key={customer.customerCompany.id}
              size="xl"
              variant="light"
              radius="xs"
              onClick={() => {
                showNotification({
                  id: 'synchroDossierEmployeesToExistingClient-loading',
                  title: t('w.success'),
                  message: t('w.treatmentInProgress'),
                  color: 'green',
                  icon: <IconCheck />,
                });
                synchroOpenpayeDossierEmployeesToExistingClientCompany({
                  accountantCompanyId: _accountantCompanyId,
                  clientCompanyId: customer.clientCompanyId,
                  dossierId: item.dossierId,
                });
              }}
            >
              <CompanyInfoWrapper
                companyId={customer.customerCompany.id}
                companyName={customer.customerCompany.name}
              />
            </Button>
          ))}
        </Group>
      ),
    });
  }

  return (
    <Table.Tr>
      <Table.Td>
        {form.getInputProps(`dossiers.${index}.dossierId`).value}
      </Table.Td>
      <Table.Td>
        <Badge color={statusColor()}>{statusLabel}</Badge>
      </Table.Td>
      <Table.Td>
        <Group wrap="wrap" gap="md">
          {!!accountantCompanyId && (
            <>
              <CustomTooltip label={t('openpaye.createClientFromDossier')}>
                <ActionIcon
                  variant="transparent"
                  onClick={() =>
                    createClientFromDossierClick(accountantCompanyId)
                  }
                  disabled={!item.status}
                  loading={
                    createClientCompanyFromOpenpayeDossierLoading ||
                    synchroOpenpayeDossierEmployeesToExistingClientCompanyLoading
                  }
                >
                  <IconClipboardPlus />
                </ActionIcon>
              </CustomTooltip>
              <CustomTooltip
                label={t('openpaye.synchroDossierUsersToExistingClient')}
              >
                <ActionIcon
                  variant="transparent"
                  onClick={() =>
                    synchroDossierEmployeesToExistingClientClick(
                      accountantCompanyId
                    )
                  }
                  disabled={!item.status || !customers?.length}
                  loading={
                    createClientCompanyFromOpenpayeDossierLoading ||
                    synchroOpenpayeDossierEmployeesToExistingClientCompanyLoading
                  }
                >
                  <IconRefresh />
                </ActionIcon>
              </CustomTooltip>
            </>
          )}
          <ActionDeleteIcon
            label={t('w.delete')}
            onClick={() => form.removeListItem('dossiers', index)}
          />
        </Group>
      </Table.Td>
    </Table.Tr>
  );
}
