import { Flex } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import React from 'react';

import LeaveService from '../../../services/LeaveService';
import type { UserResponse } from '../../../types/api/response/user';
import LeavesList from '../../home/components/leaves/LeavesList';
import UserCounterDashboard from '../../home/components/UserCounterDashboard';

type Props = {
  userProfile: UserResponse;
  showTakeALeaveButton?: boolean;
};

export default function UserProfileLeaves({
  userProfile,
  showTakeALeaveButton = false,
}: Props) {
  const {
    data: leavesList,
    isLoading: leaveslistIsLoading,
    refetch: refetchLeavesList,
  } = useQuery({
    queryKey: ['LeaveService.getLeavesHistory', userProfile.id],
    queryFn: () => LeaveService.getLeavesHistory({ userId: userProfile.id }),
  });

  return (
    <Flex direction={'column'} gap={'xl'}>
      <UserCounterDashboard userProfile={userProfile} />
      <LeavesList
        leavesList={leavesList ? leavesList : []}
        isLoading={leaveslistIsLoading}
        refetchLeavesList={refetchLeavesList}
        userProfile={userProfile}
        showTakeALeaveButton={showTakeALeaveButton}
      />
    </Flex>
  );
}
