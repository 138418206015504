import { useMediaQuery } from '@mantine/hooks';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { COMPANY_MODE } from '../types/types';
import { persistData } from '../utils/localStorage';
import { LOGO_PATH, LOGO_SQUARE_PATH } from '../variables/GlobalVariables';

export default function Logo() {
  const largeScreen = useMediaQuery('(min-width: 990px)');
  const navigate = useNavigate();

  function onClick() {
    persistData('mode', COMPANY_MODE);
    navigate('/home');
  }

  return (
    <img
      onClick={onClick}
      style={{ cursor: 'pointer' }}
      alt="logo"
      src={largeScreen ? LOGO_PATH : LOGO_SQUARE_PATH}
      width={largeScreen ? 200 : 32}
      height={largeScreen ? 40 : 32}
    />
  );
}
