import {
  FilterOption,
  FilterOptionChoice,
  Options,
  Table,
} from '@ckprivate/ckf-ui';
import { Button } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconUsersGroup } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { DataTableColumn } from 'mantine-datatable';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import ActiveInactiveBadge from '../../../../components/badges/ActiveInactiveBadge';
import RoleBadge from '../../../../components/badges/RoleBadge';
import UnitBadge from '../../../../components/badges/UnitBadge';
import { useI18n } from '../../../../contexts/I18nProvider';
import useFetchDivisions from '../../../../hooks/useFetchDivisions';
import CompanyService from '../../../../services/CompanyService';
import ProfileService from '../../../../services/ProfileService';
import SecurityRoleService from '../../../../services/SecurityRoleService';
import type { CompanyResponse } from '../../../../types/api/response/company';
import type { DivisionResponse } from '../../../../types/api/response/division';
import type { UserResponse } from '../../../../types/api/response/user';
import { milliToFullDateString } from '../../../../utils/format';
import {
  getFilter,
  getHiddenColumns,
  saveFilter,
  saveHiddenColumns,
} from '../../../../utils/optionsPreferences';
import { NO_WORK_UNIT } from '../../../../variables/GlobalVariables';

type Props = {
  division?: DivisionResponse | null;
  company: CompanyResponse;
};

const componentName = 'AssignEmployeesToDivisionTable';
export default function AssignEmployeesToDivisionTable({
  division,
  company,
}: Props) {
  const { t, lang } = useI18n();
  const [selectedEmployees, setSelectedEmployees] = useState<UserResponse[]>(
    []
  );

  const columns: DataTableColumn<UserResponse>[] = useMemo(
    () => [
      {
        accessor: 'fullname',
        title: t('w.name'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'employment',
        title: t('w.jobType'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'role.name',
        title: t('w.role'),
        sortable: true,
        ellipsis: true,
        render: ({ role }) => <RoleBadge role={role?.name!} />,
      },
      {
        accessor: 'division.name',
        title: company?.divisionLabel ? company.divisionLabel : t('w.unit'),
        sortable: true,
        ellipsis: true,
        render: ({ division }) => <UnitBadge unit={division?.name!} />,
      },
      {
        accessor: 'valid',
        title: t('w.status'),
        sortable: true,
        ellipsis: true,
        render: ({ valid }) => <ActiveInactiveBadge value={valid!} />,
      },
      {
        accessor: 'updated',
        title: t('w.lastUpdated'),
        sortable: true,
        ellipsis: true,
        render: ({ updated }) =>
          updated ? milliToFullDateString(updated) : '',
      },
    ],
    []
  );

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    search: {
      accessor: 'fullname',
      label: t('w.name'),
    },
    hiddenColumns: getHiddenColumns({ componentName }),
    updateHiddenColumns,
    filter: getFilter({
      componentName,
      filter: [
        {
          accessor: 'valid',
          label: t('w.status'),
          choices: [
            { value: 'true', label: t('w.active') },
            { value: 'false', label: t('w.inactive') },
          ],
          selectedChoices: [],
        },
        {
          accessor: 'division.name',
          label: company?.divisionLabel ? company.divisionLabel : t('w.unit'),
          choices: [],
          selectedChoices: [],
        },
      ],
    }),
    updateFilter,
  });

  function updateFilter(newFilter: FilterOption[]) {
    const _options = { ...options };
    _options.filter = newFilter;
    saveFilter({ componentName, filter: newFilter });
    setOptions(_options);
  }

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  const rightCustomActions: ReactNode[] = useMemo(
    () => [
      <Button
        leftSection={<IconUsersGroup />}
        key={'assign-employees'}
        disabled={selectedEmployees.length === 0}
        onClick={handleAssignSelectedEmployeesClick}
      >
        {t('division.message.assign', '').split(' ')[0]}
      </Button>,
    ],
    [selectedEmployees.length]
  );

  const {
    divisions,
    isLoading: divisionsIsLoading,
    refetch: refetchDivisions,
  } = useFetchDivisions({ companyId: company.id });

  const {
    data: roles,
    isLoading: rolesIsLoading,
    refetch: refetchRoles,
  } = useQuery({
    queryKey: ['SecurityRoleService.list', company.id],
    queryFn: () => SecurityRoleService.list(company.id),
  });

  const {
    data: employees,
    isLoading: employeesIsLoading,
    refetch: refetchEmployees,
  } = useQuery({
    queryKey: ['CompanyService.listEmployees', company.id],
    queryFn: () =>
      CompanyService.listEmployees(company.id, [
        'fullname',
        'firstname',
        'lastname',
        'lastLoginDate',
        'securityRoleId',
        'divisionId',
        'companyId',
        'valid',
        'mail',
        'employment',
        'identifiantsCompany',
      ]),
  });

  const loading: boolean =
    divisionsIsLoading || rolesIsLoading || employeesIsLoading;

  const users = useMemo(() => {
    if (!employees || !roles || !divisions) {
      return [];
    }

    return employees.map((employee: any) => {
      employee.role = roles.find(
        (_elt: any) => _elt.id === employee.securityRoleId
      );
      employee.division = divisions.find(
        (_elt: any) => _elt.id === employee.divisionId
      );
      return employee;
    });
  }, [employees, roles, divisions]);

  const {
    mutate: assignSeletedEmployees,
    isSuccess: isAssignSeletedEmployeesSuccess,
  } = useMutation({
    mutationFn: (variables: {
      userId: string;
      divisionId: string | undefined;
    }) =>
      ProfileService.updateUserProfile(variables.userId, {
        divisionId: variables.divisionId,
      }),
  });

  useEffect(() => {
    if (isAssignSeletedEmployeesSuccess) {
      showNotification({
        id: `assign-divisionId-${division?.id}-successful`,
        title: t('w.success'),
        message: t('division.message.assignSuccessful', division?.name),
        color: 'green',
        icon: <IconCheck />,
      });
      refetchAll();
    }
  }, [isAssignSeletedEmployeesSuccess]);

  useEffect(() => {
    if (divisions?.length) {
      const _unitNames: FilterOptionChoice[] = [];
      divisions.forEach((item: any) => {
        if (item.name === NO_WORK_UNIT) {
          _unitNames.push({ value: item.name, label: t('NO_WORK_UNIT') });
        } else {
          _unitNames.push({ value: item.name, label: item.name });
        }
      });
      const _options: Options = { ...options };
      if (_options?.filter?.[1].choices) {
        _options.filter[1].choices = _unitNames;
      }
      setOptions(_options);
    }
  }, [divisions]);

  function refetchAll() {
    refetchDivisions();
    refetchRoles();
    refetchEmployees();
  }

  function handleAssignSelectedEmployeesClick() {
    selectedEmployees.forEach((employee) => {
      assignSeletedEmployees({ userId: employee.id, divisionId: division?.id });
    });
  }

  return (
    <Table
      pinFirstColumn
      onSelect={(employees) =>
        setSelectedEmployees(employees as UserResponse[])
      }
      rows={users}
      options={options}
      columns={columns}
      lang={lang}
      fetching={loading}
      withTableBorder={false}
      rightCustomActions={rightCustomActions}
    />
  );
}
