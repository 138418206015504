import { Badge } from '@mantine/core';
import React from 'react';

import { useI18n } from '../../contexts/I18nProvider';

type Props = {
  value: boolean;
};

export default function ActiveInactiveBadge({ value }: Props) {
  const { t } = useI18n();

  return (
    <Badge
      styles={{
        root: { textTransform: 'none' },
        label: { color: value ? '#0A9182' : '#E70D4F' },
      }}
      color={value ? '#CEE9E6' : '#FDE6ED'}
    >
      {value ? t('w.active') : t('w.inactive')}
    </Badge>
  );
}
