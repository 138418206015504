import { useComputedColorScheme, useMantineColorScheme } from '@mantine/core';
import { useHotkeys } from '@mantine/hooks';
import { openSpotlight } from '@mantine/spotlight';

export default function HotKeysHandler() {
  const { setColorScheme } = useMantineColorScheme();
  const computedColorScheme = useComputedColorScheme('light', { getInitialValueInEffect: true });

  useHotkeys([
    ['mod + J', () => setColorScheme(computedColorScheme === 'light' ? 'dark' : 'light')],
    ['mod+K', openSpotlight],
  ]);

  return null;
}
