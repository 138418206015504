import { Badge, Group } from '@mantine/core';

import CustomTooltip from '../CustomTooltip';

type Props = {
  items: string[];
};

export default function MoreItemsBadge({ items }: Props) {
  const displayedItems = items?.slice(0, 1);
  const hiddenItems = items?.slice(1, items?.length);

  return (
    <Group gap={'xs'}>
      {displayedItems?.map((item, index) => (
        <Badge
          key={`${item}-${index}`}
          styles={{
            root: { textTransform: 'none' },
            label: { color: '#ebf2ff' },
          }}
          color="#647fcc"
        >
          {item}
        </Badge>
      ))}
      {hiddenItems?.length > 0 && (
        <CustomTooltip label={hiddenItems?.map((elt) => elt).join(', ')}>
          <Badge
            styles={{
              root: { textTransform: 'none', cursor: 'pointer' },
              label: { color: '#ebf2ff' },
            }}
            color="#647fcc"
          >
            + {hiddenItems?.length}
          </Badge>
        </CustomTooltip>
      )}
    </Group>
  );
}
