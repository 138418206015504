import React from 'react';
import { useRoutes } from 'react-router-dom';

import AccountantConfigurations from '../features/accountant/configurations/AccountantConfigurations';
import AccountantCustomerEndOfMonth from '../features/accountant/customer-end-of-month/AccountantCustomerEndOfMonth';
import AccountantCustomerManagement from '../features/accountant/customer-management/AccountantCustomerManagement';
import ValidateInvitationCustomerScreen from '../features/accountant/customer-management/component/ValidateInvitationCustomerScreen';
import AccountantCustomerShuttleSheet from '../features/accountant/customer-shuttle-sheet/AccountantCustomerShuttleSheet';
import AccountantMarketPlace from '../features/accountant/market-place/AccountantMarketPlace';
import MyActivities from '../features/activities/my-activities/MyActivities';
import TeamActivites from '../features/activities/team-activities/TeamActivites';
import Billing from '../features/company/billing/Billing';
import CounterTypes from '../features/company/counter-types/CounterTypes';
import EmployeeOnBoarding from '../features/company/employees/EmployeeOnBoarding';
import Employees from '../features/company/employees/Employees';
import CompanyFeatures from '../features/company/features/CompanyFeatures';
import CompanyHome from '../features/company/home/CompanyHome';
import PayrollOfficer from '../features/company/payroll-officer/PayrollOfficer';
import PeriodTypes from '../features/company/period-types/PeriodTypes';
import Periods from '../features/company/periods/Periods';
import PublicHolidaysAndLeave from '../features/company/public-holidays-and-leaves/PublicHolidaysAndLeave';
import Roles from '../features/company/roles/Roles';
import CompanySettings from '../features/company/settings/CompanySettings';
import ShuttleSheetTypes from '../features/company/shuttle-sheet-type/ShuttleSheetTypes';
import WorkUnits from '../features/company/work-units/WorkUnits';
import DocumentsAccountants from '../features/documents/DocumentsAccountants';
import DocumentsPersonal from '../features/documents/DocumentsPersonal';
import DocumentsShared from '../features/documents/DocumentsShared';
import EndOfMonthExpenseReports from '../features/end-of-month/expense-reports/EndOfMonthExpenseReports';
import EndOfMonthHolidayCounter from '../features/end-of-month/holiday-counter/EndOfMonthHolidayCounter';
import EndOfMonthPayslips from '../features/end-of-month/payslips/EndOfMonthPayslips';
import EndOfMonthValidations from '../features/end-of-month/validations/EndOfMonthValidations';
import Error404 from '../features/errors/Error404';
import ExpenseReports from '../features/expense-reports/ExpenseReports';
import ForgotPassword from '../features/forgot-password/ForgotPassword';
import Home from '../features/home/Home';
import Login from '../features/login/Login';
import Logout from '../features/logout/Logout';
import CompanyMarketPlace from '../features/market-place/CompanyMarketPlace';
import PaymentAccount from '../features/payment-account/PaymentAccount';
import Payslips from '../features/payslips/Payslips';
import PlanningView from '../features/planning/PlanningView';
import ResetPassword from '../features/reset-password/ResetPassword';
import ShuttleSheets from '../features/shuttle-sheets/ShuttleSheets';
import ConfirmAccount from '../features/sign-up/ConfirmAccount';
import SignUp from '../features/sign-up/SignUp';
import SignUpInfo from '../features/sign-up/SignUpInfo';
import ValidateOauthUserScreen from '../features/user-account/component/my-profiles-settings/ValidateOauthUserScreen';
import UserAccountSettings from '../features/user-account/settings/UserAccountSettings';
import UserProfileSettings from '../features/user-profile/settings/UserProfileSettings';
import ExpenseReportsPendingValidation from '../features/validations/expense-reports/ExpenseReportsPendingValidation';
import LeavesAndAbsencesPendingRequests from '../features/validations/leaves/LeavesAndAbsencesPendingRequests';
import WorkUnitView from '../features/work-units/WorkUnitView';
import { BuiltInPermissions, ScopeEnum } from '../types/api/response/role';
import RequireAuth from './RequireAuth';

export default function Router() {
  return useRoutes([
    { path: '/', element: <Login /> },
    { path: '/forgot-password', element: <ForgotPassword /> },
    { path: '/reset_password', element: <ResetPassword /> },
    { path: '/sign-up', element: <SignUp /> },
    { path: '/confirm_account', element: <ConfirmAccount /> },
    {
      path: '/accept/link/:linkId',
      element: <ValidateOauthUserScreen />,
    },
    {
      path: '/sign-up/info',
      element: <SignUpInfo />,
    },
    {
      path: '/chartered-accountant/accept/customer/invitation/:validationId',
      element: (
        <RequireAuth>
          <ValidateInvitationCustomerScreen />
        </RequireAuth>
      ),
    },
    {
      path: '/logout',
      element: (
        <RequireAuth>
          <Logout />
        </RequireAuth>
      ),
    },
    {
      path: '/home',
      element: (
        <RequireAuth>
          <Home />
        </RequireAuth>
      ),
    },
    {
      path: '/planning',
      element: (
        <RequireAuth
          permissions={[
            {
              permission: BuiltInPermissions.AuthorizedReadPlanning,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.AuthorizedReadPlanning,
              scope: ScopeEnum.TEAM,
            },
            {
              permission: BuiltInPermissions.AuthorizedReadPlanning,
              scope: ScopeEnum.MINE,
            },
            {
              permission: BuiltInPermissions.AuthorizedReadAllUsersInfo,
              scope: ScopeEnum.ALL,
            },
          ]}
        >
          <PlanningView />
        </RequireAuth>
      ),
    },
    {
      path: '/activities/user',
      element: (
        <RequireAuth
          permissions={[
            {
              permission: BuiltInPermissions.AuthorizedReadDailyWork,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.AuthorizedUpsertDailyWork,
              scope: ScopeEnum.ALL,
            },
          ]}
        >
          <MyActivities />
        </RequireAuth>
      ),
    },
    {
      path: '/activities/team',
      element: (
        <RequireAuth
          permissions={[
            {
              permission: BuiltInPermissions.ValidateLeavesAbsences,
              scope: ScopeEnum.ALL,
            },
          ]}
        >
          <TeamActivites />
        </RequireAuth>
      ),
    },
    {
      path: '/work-units',
      element: (
        <RequireAuth
          permissions={[
            {
              permission: BuiltInPermissions.AuthorizedReadAllUsersInfo,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.AuthorizedReadAllUsersInfo,
              scope: ScopeEnum.TEAM,
            },
            {
              permission: BuiltInPermissions.ConfigureEmployees,
              scope: ScopeEnum.ALL,
            },
          ]}
        >
          <WorkUnitView />
        </RequireAuth>
      ),
    },
    {
      path: `/validation/leaves`,
      element: (
        <RequireAuth
          permissions={[
            {
              permission: BuiltInPermissions.ValidateLeavesAbsences,
              scope: ScopeEnum.ALL,
            },
          ]}
        >
          <LeavesAndAbsencesPendingRequests />
        </RequireAuth>
      ),
    },
    {
      path: `/validation/expense-reports`,
      element: (
        <RequireAuth
          permissions={[
            {
              permission: BuiltInPermissions.ValidateExpenseReports,
              scope: ScopeEnum.ALL,
            },
          ]}
        >
          <ExpenseReportsPendingValidation />
        </RequireAuth>
      ),
    },
    {
      path: `/end-of-month/validation`,
      element: (
        <RequireAuth
          permissions={[
            {
              permission: BuiltInPermissions.ValidateLeavesAbsences,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.ValidateEndOfMonth,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.ValidatePayrollVariables,
              scope: ScopeEnum.ALL,
            },
          ]}
        >
          <EndOfMonthValidations />
        </RequireAuth>
      ),
    },
    {
      path: `/end-of-month/holiday-counter`,
      element: (
        <RequireAuth
          permissions={[
            {
              permission: BuiltInPermissions.ValidateHolidayCounter,
              scope: ScopeEnum.ALL,
            },
          ]}
        >
          <EndOfMonthHolidayCounter />
        </RequireAuth>
      ),
    },
    {
      path: `/end-of-month/payslips`,
      element: (
        <RequireAuth
          permissions={[
            {
              permission: BuiltInPermissions.ValidatePayslips,
              scope: ScopeEnum.ALL,
            },
          ]}
        >
          <EndOfMonthPayslips />
        </RequireAuth>
      ),
    },
    {
      path: `/end-of-month/expense-reports`,
      element: (
        <RequireAuth
          permissions={[
            {
              permission: BuiltInPermissions.ValidateExpenseReports,
              scope: ScopeEnum.ALL,
            },
          ]}
        >
          <EndOfMonthExpenseReports />
        </RequireAuth>
      ),
    },
    {
      path: `/expense-reports/user/:userId`,
      element: (
        <RequireAuth>
          <ExpenseReports />
        </RequireAuth>
      ),
    },
    {
      path: `/payslips/user/:userId`,
      element: (
        <RequireAuth>
          <Payslips />
        </RequireAuth>
      ),
    },
    {
      path: `/documents/personal`,
      element: (
        <RequireAuth
          permissions={[
            {
              permission: BuiltInPermissions.AuthorizedReadMyPersonalSpace,
              scope: ScopeEnum.ALL,
            },
          ]}
        >
          <DocumentsPersonal />
        </RequireAuth>
      ),
    },
    {
      path: `/documents/shared`,
      element: (
        <RequireAuth
          permissions={[
            {
              permission: BuiltInPermissions.AuthorizedReadCommonSpace,
              scope: ScopeEnum.ALL,
            },
          ]}
        >
          <DocumentsShared />
        </RequireAuth>
      ),
    },
    {
      path: `/documents/accountant`,
      element: (
        <RequireAuth
          permissions={[
            {
              permission: BuiltInPermissions.AuthorizedEditAccountantSpace,
              scope: ScopeEnum.ALL,
            },
          ]}
        >
          <DocumentsAccountants />
        </RequireAuth>
      ),
    },
    {
      path: `/shuttle-sheets/user/:userId`,
      element: (
        <RequireAuth>
          <ShuttleSheets />
        </RequireAuth>
      ),
    },
    {
      path: '/company/:companyId/home',
      element: (
        <RequireAuth
          permissions={[
            {
              permission: BuiltInPermissions.ConfigureWorkUnit,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.ConfigureWorkUnit,
              scope: ScopeEnum.TEAM,
            },
            {
              permission: BuiltInPermissions.ConfigureEmployees,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.ConfigureLeaveTypes,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.ConfigureBlockingPeriods,
              scope: ScopeEnum.ALL,
            },
            {
              permission:
                BuiltInPermissions.ConfigurePublicHolidaysCompanyLeaves,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.ConfigureShuttleSheetTypes,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.ConfigureAccountant,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.ConfigureFeatures,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.ConfigurePermissions,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.ConfigureCompany,
              scope: ScopeEnum.ALL,
            },
          ]}
        >
          <CompanyHome />
        </RequireAuth>
      ),
    },
    {
      path: '/company/:companyId/employees',
      element: (
        <RequireAuth
          permissions={[
            {
              permission: BuiltInPermissions.ConfigureEmployees,
              scope: ScopeEnum.ALL,
            },
          ]}
        >
          <Employees />
        </RequireAuth>
      ),
    },
    {
      path: `/company/:companyId/work-units`,
      element: (
        <RequireAuth
          permissions={[
            {
              permission: BuiltInPermissions.ConfigureWorkUnit,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.ConfigureWorkUnit,
              scope: ScopeEnum.TEAM,
            },
          ]}
        >
          <WorkUnits />
        </RequireAuth>
      ),
    },
    {
      path: `/company/:companyId/counter-types`,
      element: (
        <RequireAuth
          permissions={[
            {
              permission: BuiltInPermissions.ConfigureCompany,
              scope: ScopeEnum.ALL,
            },
          ]}
        >
          <CounterTypes />
        </RequireAuth>
      ),
    },
    {
      path: `/company/:companyId/period-types`,
      element: (
        <RequireAuth
          permissions={[
            {
              permission: BuiltInPermissions.ConfigureLeaveTypes,
              scope: ScopeEnum.ALL,
            },
          ]}
        >
          <PeriodTypes />
        </RequireAuth>
      ),
    },
    {
      path: `/company/:companyId/periods`,
      element: (
        <RequireAuth
          permissions={[
            {
              permission: BuiltInPermissions.ConfigureBlockingPeriods,
              scope: ScopeEnum.ALL,
            },
            {
              permission:
                BuiltInPermissions.ConfigurePublicHolidaysCompanyLeaves,
              scope: ScopeEnum.ALL,
            },
          ]}
        >
          <Periods />
        </RequireAuth>
      ),
    },
    {
      path: `/company/:companyId/public-holidays-and-leave`,
      element: (
        <RequireAuth
          permissions={[
            {
              permission:
                BuiltInPermissions.ConfigurePublicHolidaysCompanyLeaves,
              scope: ScopeEnum.ALL,
            },
          ]}
        >
          <PublicHolidaysAndLeave />
        </RequireAuth>
      ),
    },
    {
      path: `/company/:companyId/shuttle-sheet-type`,
      element: (
        <RequireAuth
          permissions={[
            {
              permission: BuiltInPermissions.ConfigureShuttleSheetTypes,
              scope: ScopeEnum.ALL,
            },
          ]}
        >
          <ShuttleSheetTypes />
        </RequireAuth>
      ),
    },
    {
      path: `/company/:companyId/payroll-officer`,
      element: (
        <RequireAuth
          permissions={[
            {
              permission: BuiltInPermissions.ConfigureAccountant,
              scope: ScopeEnum.ALL,
            },
          ]}
        >
          <PayrollOfficer />
        </RequireAuth>
      ),
    },
    {
      path: `/company/:companyId/market-place`,
      element: (
        <RequireAuth
          permissions={[
            {
              permission: BuiltInPermissions.ConfigureTools,
              scope: ScopeEnum.ALL,
            },
          ]}
        >
          <CompanyMarketPlace />
        </RequireAuth>
      ),
    },
    {
      path: `/company/:companyId/features`,
      element: (
        <RequireAuth
          permissions={[
            {
              permission: BuiltInPermissions.ConfigureFeatures,
              scope: ScopeEnum.ALL,
            },
          ]}
        >
          <CompanyFeatures />
        </RequireAuth>
      ),
    },
    {
      path: `/company/:companyId/roles`,
      element: (
        <RequireAuth
          permissions={[
            {
              permission: BuiltInPermissions.ConfigurePermissions,
              scope: ScopeEnum.ALL,
            },
          ]}
        >
          <Roles />
        </RequireAuth>
      ),
    },
    {
      path: `/company/:companyId/billing`,
      element: (
        <RequireAuth
          permissions={[
            {
              permission: BuiltInPermissions.ConfigurePayment,
              scope: ScopeEnum.ALL,
            },
          ]}
        >
          <Billing />
        </RequireAuth>
      ),
    },
    {
      path: `/company/:companyId/settings`,
      element: (
        <RequireAuth
          permissions={[
            {
              permission: BuiltInPermissions.ConfigureWorkUnit,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.ConfigureEmployees,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.ConfigureLeaveTypes,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.ConfigureBlockingPeriods,
              scope: ScopeEnum.ALL,
            },
            {
              permission:
                BuiltInPermissions.ConfigurePublicHolidaysCompanyLeaves,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.ConfigureShuttleSheetTypes,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.ConfigureAccountant,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.ConfigureFeatures,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.ConfigurePermissions,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.ConfigureCompany,
              scope: ScopeEnum.ALL,
            },
          ]}
        >
          <CompanySettings />
        </RequireAuth>
      ),
    },
    {
      path: `/company/:companyId/profile/user/:userId`,
      element: (
        <RequireAuth>
          <UserProfileSettings />
        </RequireAuth>
      ),
    },
    {
      path: `/manage-payment-account`,
      element: (
        <RequireAuth
          permissions={[
            {
              permission: BuiltInPermissions.ConfigurePayment,
              scope: ScopeEnum.ALL,
            },
          ]}
        >
          <PaymentAccount />
        </RequireAuth>
      ),
    },
    {
      path: `/profile/user/:userId`,
      element: (
        <RequireAuth>
          <UserProfileSettings />
        </RequireAuth>
      ),
    },
    {
      path: `/account/user/:userId`,
      element: (
        <RequireAuth>
          <UserAccountSettings />
        </RequireAuth>
      ),
    },
    {
      path: '/chartered-accountant/market-place',
      element: (
        <RequireAuth>
          <AccountantMarketPlace />
        </RequireAuth>
      ),
    },
    {
      path: '/chartered-accountant/customer-management',
      element: (
        <RequireAuth>
          <AccountantCustomerManagement />
        </RequireAuth>
      ),
    },
    {
      path: '/chartered-accountant/customer-end-of-month',
      element: (
        <RequireAuth>
          <AccountantCustomerEndOfMonth />
        </RequireAuth>
      ),
    },
    {
      path: '/chartered-accountant/customer-shuttle-sheet',
      element: (
        <RequireAuth>
          <AccountantCustomerShuttleSheet />
        </RequireAuth>
      ),
    },
    {
      path: '/chartered-accountant/configurations',
      element: (
        <RequireAuth
          permissions={[
            {
              permission: BuiltInPermissions.AccountantManageTeam,
              scope: ScopeEnum.ALL,
            },
          ]}
        >
          <AccountantConfigurations />
        </RequireAuth>
      ),
    },
    {
      path: '/employee/on-boarding',
      element: (
        <RequireAuth>
          <EmployeeOnBoarding />
        </RequireAuth>
      ),
    },
    {
      path: '*',
      element: <Error404 />,
    },
  ]);
}
