import CustomModal from '../../../../components/CustomModal';
import { useI18n } from '../../../../contexts/I18nProvider';
import AddPayrollOfficerForm from './AddPayrollOfficerForm';

type Props = {
  opened: boolean;
  onClose: () => void;
  setActiveTab: (value: string | null) => void;
  companyId: string;
};

export default function AddPayrollOfficerModal({
  opened,
  onClose,
  setActiveTab,
  companyId,
}: Props) {
  const { t } = useI18n();
  return (
    <CustomModal
      opened={opened}
      onClose={onClose}
      title={t('w.invitePayrollOfficer')}
      size={'xl'}
    >
      <AddPayrollOfficerForm
        setActiveTab={setActiveTab}
        onClose={onClose}
        companyId={companyId}
      />
    </CustomModal>
  );
}
