import type { PermissionResponse } from '../types/api/response/role';
import { MS_HIFIVEWORK_PROFILE_ENDPOINT } from '../variables/GlobalVariables';
import request from './AxiosClient';

function list(): Promise<PermissionResponse[]> {
  return request
    .get(`${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/permission/list`)
    .then((res) => res?.data);
}

const PermissionService = { list };

export default PermissionService;
