export type AdressProps = {
  address1?: string;
  address2?: string;
  zipCode?: string;
  city?: string;
};

export function validateAddressPredicate(
  address: AdressProps,
  filterValue: string
): boolean {
  if (address) {
    let stringAddress = '';
    if (address.address1) {
      stringAddress += ' ' + address.address1;
    }
    if (address.address2) {
      stringAddress += ' ' + address.address2;
    }
    if (address.zipCode) {
      stringAddress += ' ' + address.zipCode;
    }
    if (address.city) {
      stringAddress += ' ' + address.city;
    }
    return stringAddress.toLowerCase().includes(filterValue.toLowerCase());
  }

  return false;
}

export function validateEmail(email: string): boolean {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
}

export function validatePhone(phone: string): boolean {
  const phoneRegex = /^((\+)33)[1-9](\d{2}){4}$/g; // indicatif (+33) + suivi de 9
  return phoneRegex.test(phone);
}

export function internationalPhoneNumberRegex(): any {
  return /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
}

export function validatePassword(password: string): boolean {
  return password.length >= 8;
}

export function validateSiret(siret: string): boolean {
  const siretRegex = /^\d{14}$/g; // 14 numbers
  return siretRegex.test(siret);
}

export function validatePresenceString(presence: string): boolean {
  const presenceRegex =
    /^(((2[0-3]|[0-1][0-9]):([0-5][0-9]))-((2[0-3]|[0-1][0-9]):([0-5][0-9])))(,(((2[0-3]|[0-1][0-9]):([0-5][0-9]))-((2[0-3]|[0-1][0-9]):([0-5][0-9]))))*$/;
  return presenceRegex.test(presence?.replace(/ /g, ''));
}
