import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import type { TranslateFunction } from '../types/front/common';
dayjs.extend(utc);

export function toUTC(date: Date): Date {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
    date.getUTCMilliseconds()
  );
}

export function toDayMonthYearWithSession(date: string, t: TranslateFunction) {
  return `${dayjs(`${date}Z`).utc().format('D MMM YYYY')} (${
    dayjs(`${date}Z`).utc().format('A') === 'PM'
      ? t('leave.type.afternoon').toLowerCase()
      : t('leave.type.morning').toLowerCase()
  })`;
}

export function isSameDay(date1: string, date2: string): boolean {
  return (
    dayjs(`${date1}Z`).utc().format('DD/MM/YYYY') ===
    dayjs(`${date2}Z`).utc().format('DD/MM/YYYY')
  );
}

export function toDayMonthYearWithoutSession(date: string) {
  return dayjs(`${date}Z`).utc().format('D MMM YYYY');
}

export function dateString(milliseconds: number | Date | string): string {
  return dayjs(milliseconds).utc().format('DD/MM/YYYY');
}

export function milliToFullDateString(milliseconds: number | string): string {
  return dayjs(milliseconds).format('DD/MM/YYYY HH:mm');
}

// TODO garder que string
export function milliToHourMinuteStringUtc(
  milliseconds: number | string
): string {
  return dayjs(toUTC(new Date(milliseconds))).format('HH:mm');
}

export function toDateOfDayString(): string {
  return (
    dayjs().format('dddd D MMMM')[0].toUpperCase() +
    dayjs().format('dddd D MMMM').slice(1)
  );
}

export function dateToDayMonthLocal(date: any): string {
  return dayjs(date).local().format('MMMM YYYY');
}

export function convertSession(date: any, t: TranslateFunction) {
  if (dayjs(date).utc().format('A') === 'PM') {
    return t('leave.type.afternoon').toLowerCase();
  } else {
    return t('leave.type.morning').toLowerCase();
  }
}

export function convertMinutesToHourMinutes(nbMinutes: number): string {
  const hours = Math.floor(nbMinutes / 60);
  const minutes = nbMinutes % 60;
  const hoursToDisplay = hours < 10 ? '0' + hours : hours;
  const minutesToDisplay = minutes < 10 ? '0' + minutes : minutes;
  return hoursToDisplay + ':' + minutesToDisplay;
}
