import React from 'react';

import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import ScreenWrapper from '../../../core/layouts/components/ScreenWrapper';
import useCompany from '../../../hooks/useCompany';
import HolidayCounters from './components/HolidayCounters';

export default function EndOfMonthHolidayCounter() {
  const { user } = useAuth();
  const { t } = useI18n();
  const { id: companyId } = useCompany(user);

  return (
    <ScreenWrapper title={t('w.holidayCounters')} paper>
      <HolidayCounters companyId={companyId} />
    </ScreenWrapper>
  );
}
