import {
  Button,
  Group,
  LoadingOverlay,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';

import LabelText from '../../../components/LabelText/LabelText';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import useCompany from '../../../hooks/useCompany';
import LeaveService from '../../../services/LeaveService';
import handleErrorMessage from '../../../utils/handleErrorMessage';

type Props = {
  id: string;
  title: string;
  description: string | undefined;
  refetchPlanningData: () => void;
};

type FormValues = {
  comment: string;
};

export default function RejectLeaveRequestForm({
  id,
  title,
  description,
  refetchPlanningData,
}: Props) {
  const { user } = useAuth();
  const { t } = useI18n();
  const { id: companyId } = useCompany(user);

  const form = useForm<FormValues>({
    initialValues: {
      comment: '',
    },
  });

  const { mutate: rejectLeaveRequest, isLoading: isRejectLeaveRequestLoading } =
    useMutation({
      mutationFn: (variables: { id: string; comment: string }) =>
        LeaveService.declineRequest(companyId, variables.id, variables.comment),
      onSuccess: () => {
        refetchPlanningData();
        modals.closeAll();
        showNotification({
          id: `reject-leave-request-successful`,
          message: t('w.success'),
          color: 'green',
          icon: <IconCheck />,
        });
      },
      onError: (error) =>
        showNotification({
          id: `reject-leave-request-error`,
          title: t('w.error'),
          message: handleErrorMessage(error, t),
          color: 'red',
          icon: <IconX />,
        }),
    });

  return (
    <form
      onSubmit={form.onSubmit((values) =>
        rejectLeaveRequest({ id: id, comment: values.comment })
      )}
    >
      <LoadingOverlay visible={isRejectLeaveRequestLoading} />
      <Stack>
        <Text fz={'sm'} fw={'bold'} c={'dimmed'}>
          {`${t('w.youAreAboutToRefuseRequest')} : `}
        </Text>
        <Text
          fz={'xs'}
          fw={'bold'}
          c={'var(--mantine-color-hifivework-6'}
        >{`${title} - ${description}`}</Text>
        <TextInput
          label={<LabelText text={t('w.comment')} />}
          required
          {...form.getInputProps('comment')}
        />
        <Group justify={'flex-end'}>
          <Button type={'submit'}>{t('w.reject')}</Button>
        </Group>
      </Stack>
    </form>
  );
}
