import { Button, Flex, Grid, Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconDeviceFloppy, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import React from 'react';

import { useI18n } from '../../../../../../contexts/I18nProvider';
import useFetchUsers from '../../../../../../hooks/useFetchUsers';
import DailyWorkService from '../../../../../../services/DailyWorkService';
import ModuleWithParamsService from '../../../../../../services/ModuleWithParamsService';
import type { DailyWorkParams } from '../../../../../../types/api/response/module';
import type { UserResponse } from '../../../../../../types/api/response/user';
import handleErrorMessage from '../../../../../../utils/handleErrorMessage';

type Props = {
  selectedEmployees?: UserResponse[];
  activate: boolean;
  companyId: string;
  activeForAll: boolean;
  allUsers: boolean;
  apiUrl: string;
  refetchModule: () => void;
  moduleParams: DailyWorkParams;
};

export default function ShowMessageBeforeActivateOrDeactivateUser({
  selectedEmployees,
  activate,
  companyId,
  activeForAll,
  allUsers,
  apiUrl,
  refetchModule,
  moduleParams,
}: Props) {
  const { t } = useI18n();
  const { refetchUsers } = useFetchUsers(companyId);

  function handleChangeUserStatusForDailywork() {
    if (allUsers) {
      updateModuleParamsDailywork({
        apiUrl: apiUrl,
        payload: {
          ...moduleParams,
          active: true,
          activeForAll: activeForAll,
        },
      });
    } else {
      const ids: string[] = [];
      selectedEmployees?.forEach((employee) => {
        if (employee?.id) {
          ids.push(employee.id);
        }
      });
      if (activate) {
        activateDailyworkUser({
          companyId: companyId,
          data: ids,
        });
      } else {
        deactivateDailyworkUser({
          companyId: companyId,
          data: ids,
        });
      }
    }
    modals.closeAll();
  }

  const { mutate: updateModuleParamsDailywork } = useMutation({
    mutationFn: (variables: { apiUrl: string; payload: any }) =>
      ModuleWithParamsService.updateModuleParams(
        variables.apiUrl,
        companyId,
        variables.payload
      ),
    onSuccess: () => {
      refetchUsers();
      refetchModule();
      showNotification({
        id: `success-update-module-params`,
        message: t('w.success'),
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: () =>
      showNotification({
        id: `error-update-module-params`,
        title: t('w.error'),
        message: t('error.updateModule'),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const { mutate: activateDailyworkUser } = useMutation({
    mutationFn: (variables: { companyId: string; data: string[] }) =>
      DailyWorkService.activateUsers(variables.companyId, {
        ids: variables.data,
      }),
    onSuccess: () => {
      refetchUsers();
      showNotification({
        id: `activate-dailywork-user-successful`,
        title: t('w.success'),
        message: t('success.moduleDailywork.activate'),
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: (error) =>
      showNotification({
        id: 'activate-dailywork-user-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const { mutate: deactivateDailyworkUser } = useMutation({
    mutationFn: (variables: { companyId: string; data: string[] }) =>
      DailyWorkService.deactivateUsers(variables.companyId, {
        ids: variables.data,
      }),
    onSuccess: () => {
      refetchUsers();
      showNotification({
        id: `deactivate-dailywork-user-successful`,
        title: t('w.success'),
        message: t('success.moduleDailywork.deactivate'),
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: (error) =>
      showNotification({
        id: 'deactivate-dailywork-user-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  return (
    <>
      <Grid gutter="xl">
        <Grid.Col>
          {
            //TODO CHANGER TEXTE AVEC PRIX
          }
          {activate ? (
            <Text>
              {t(
                'dailyWork.beforeEmployeeActivation',
                selectedEmployees?.map((employee) => employee.fullname),
                '*'
              )}
            </Text>
          ) : !allUsers ? (
            <Text>
              {t(
                'dailyWork.beforeEmployeeDisable',
                selectedEmployees?.map((employee) => employee.fullname),
                '*'
              )}
            </Text>
          ) : (
            <></>
          )}
          {allUsers && activeForAll ? (
            <Text>{t('dailyWork.beforeForAllEmployeesActivation')}</Text>
          ) : allUsers ? (
            <Text>{t('dailyWork.beforeForAllEmployeesDisable', '*')}</Text>
          ) : (
            <></>
          )}
        </Grid.Col>
      </Grid>
      <Flex
        justify={'flex-end'}
        gap={'md'}
        direction="row"
        wrap="wrap"
        mt={'md'}
      >
        <Button
          onClick={handleChangeUserStatusForDailywork}
          leftSection={<IconDeviceFloppy />}
        >
          {t('w.confirm')}
        </Button>
      </Flex>
    </>
  );
}
