import type { CommonEntity } from '../common';
import type { CounterTypePayload } from '../payload/counterType';

export type CounterTypeResponse = {
  counterType: CounterType;
  associateType: AssociateType[];
};

export type AssociateType = {
  name: string;
  leaveTypeId: string;
  counterTypeId: string[];
};

export enum CounterRule {
  DAY = 'DAY',
  HOURS = 'HOURS',
  WORKING_DAY = 'WORKING_DAY',
  OPEN_DAY = 'OPEN_DAY',
}

export type CounterType = CommonEntity & CounterTypePayload;
