import { Alert } from '@mantine/core';
import { AlertProps } from '@mantine/core/lib/components/Alert/Alert';
import { IconInfoCircle } from '@tabler/icons-react';
import * as React from 'react';

import { useI18n } from '../contexts/I18nProvider';

type Props = {
  title?: string;
  message: string | React.ReactNode;
  variant?: 'info' | 'success' | 'warning' | 'error';
};

enum Variant {
  'info' = 'blue',
  'success' = 'green',
  'warning' = 'orange',
  'error' = 'red',
}

export default function InformationMessage({
  title,
  message,
  variant = 'info',
  ...restProps
}: Props & AlertProps) {
  const { t } = useI18n();

  return (
    <Alert
      variant="light"
      color={Variant[variant]}
      title={title ? title : t(`informationMessage.${variant}`)}
      icon={<IconInfoCircle />}
      my="xs"
      {...restProps}
    >
      {message}
    </Alert>
  );
}
