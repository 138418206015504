import { FilterOption, Options, Table } from '@ckprivate/ckf-ui';
import { ActionIcon } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconTrash, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { DataTableColumn } from 'mantine-datatable';
import React, { useMemo, useState } from 'react';

import LeaveStatusBadge from '../../../../components/badges/LeaveStatusBadge';
import CustomActionIconFile from '../../../../components/CustomActionIconFile';
import CustomTooltip from '../../../../components/CustomTooltip';
import LeavePeriodDates from '../../../../components/LeavePeriodDates';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import EndOfMonthService from '../../../../services/EndOfMonthService';
import type { ValidateEmployeeLeaveOfMonthQuery } from '../../../../types/api/payload/endMonth';
import type { EndOfMonthValidationResponse } from '../../../../types/api/response/endMonth';
import {
  type LeaveResponse,
  LeaveStatusState,
} from '../../../../types/api/response/leave';
import { handleOpenLeaveAttachmentFile } from '../../../../utils/attachmentFile';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
import {
  getFilter,
  getHiddenColumns,
  saveFilter,
  saveHiddenColumns,
} from '../../../../utils/optionsPreferences';

const componentName = 'EndOfMonthValidationLeaveRequests';

type Props = {
  leaves: LeaveResponse[];
  companyId: string;
  endOfMonthValidation: EndOfMonthValidationResponse[];
  refetchEndOfMonthValidationData: () => void;
};

export default function EndOfMonthValidationLeaveRequestsTable({
  leaves,
  companyId,
  endOfMonthValidation,
  refetchEndOfMonthValidationData,
}: Props) {
  const { access_token } = useAuth();
  const { t, lang } = useI18n();
  const [userLeaves, setUserLeaves] = useState<LeaveResponse[]>(leaves);

  const {
    mutate: validateUserLeaveRequest,
    isLoading: isValidateUserLeaveRequestLoading,
  } = useMutation({
    mutationFn: (variables: ValidateEmployeeLeaveOfMonthQuery) =>
      EndOfMonthService.validateUserLeaveRequest(companyId, variables),
    onSuccess: (data, variables) => {
      refetchEndOfMonthValidationData();
      showNotification({
        id: `validate-leave-${variables.leaveId}-successful`,
        title: t('w.success'),
        message: t('w.updated'),
        color: 'green',
        icon: <IconCheck />,
      });
      const _userLeaves = [...userLeaves];
      const findUserLeavesIndex = _userLeaves.findIndex(
        (leave) => leave.id === variables.leaveId
      );

      if (findUserLeavesIndex !== -1) {
        if (variables.approved) {
          _userLeaves[findUserLeavesIndex].status.state =
            LeaveStatusState.VALID;
        } else {
          _userLeaves.splice(findUserLeavesIndex, 1);
        }
        setUserLeaves(_userLeaves);
      }
    },
    onError: (error) =>
      showNotification({
        id: `validate-leave-error`,
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function displayActionIcon(
    state: LeaveStatusState,
    leaveId: string,
    employeeId: string
  ) {
    switch (state) {
      case LeaveStatusState.WAITING:
      case LeaveStatusState.VALIDN1:
        return (
          <CustomTooltip label={t('w.validate')}>
            <ActionIcon
              size={'sm'}
              variant={'subtle'}
              color={'green'}
              onClick={() =>
                validateUserLeaveRequest({
                  endOfMonthId: endOfMonthValidation[0].id,
                  employeeId: employeeId,
                  leaveId: leaveId,
                  approved: true,
                })
              }
            >
              <IconCheck />
            </ActionIcon>
          </CustomTooltip>
        );
      case LeaveStatusState.VALID:
        return (
          <CustomTooltip label={t('w.reject')}>
            <ActionIcon
              size={'sm'}
              variant={'subtle'}
              color={'red'}
              onClick={() =>
                validateUserLeaveRequest({
                  endOfMonthId: endOfMonthValidation[0].id,
                  employeeId: employeeId,
                  leaveId: leaveId,
                  approved: false,
                })
              }
            >
              <IconTrash />
            </ActionIcon>
          </CustomTooltip>
        );
    }
  }

  const columns: DataTableColumn<LeaveResponse>[] = useMemo(
    () => [
      {
        accessor: 'status',
        title: t('w.status'),
        sortable: true,
        ellipsis: true,
        render: ({ status }) => <LeaveStatusBadge status={status} />,
      },
      {
        accessor: 'leaveType.name',
        title: 'Type',
        sortable: true,
        ellipsis: true,
        render: ({ leaveType }) => leaveType?.name,
      },
      {
        accessor: 'dates',
        title: 'Dates',
        sortable: true,
        ellipsis: true,
        render: ({ startHalf, endHalf, period }) => (
          <LeavePeriodDates
            startHalf={startHalf}
            endHalf={endHalf}
            startDate={period.startDate}
            endDate={period.endDate}
          />
        ),
      },
      {
        accessor: 'duration',
        title: t('w.numberOfDays'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'comment',
        title: t('w.comment'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'fileName',
        title: t('w.attachment'),
        sortable: true,
        ellipsis: true,
        render: ({ fileName, id, leaveType, whoUser }) =>
          fileName && (
            <CustomActionIconFile
              fileName={fileName}
              onClick={() =>
                handleOpenLeaveAttachmentFile(
                  id,
                  leaveType?.name!,
                  whoUser?.fullname!,
                  fileName,
                  access_token,
                  t
                )
              }
            />
          ),
      },
      {
        accessor: 'actions',
        title: t('w.actions'),
        textAlign: 'right',
        sortable: false,
        width: 100,
        render: ({ status, who, id }) =>
          displayActionIcon(status.state, id, who),
      },
    ],
    [isValidateUserLeaveRequestLoading, userLeaves]
  );

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    search: {
      accessor: 'leaveType.name',
      label: t('w.type'),
    },
    hiddenColumns: getHiddenColumns({
      componentName,
      hiddenColumns: [],
    }),
    updateHiddenColumns,
    filter: getFilter({
      componentName,
      filter: [
        {
          accessor: 'status.state',
          label: t('w.status'),
          choices: [
            { value: LeaveStatusState.VALID, label: t('leave.status.valid') },
            {
              value: LeaveStatusState.VALIDN1,
              label: t('leave.status.validn1'),
            },
            {
              value: LeaveStatusState.WAITING,
              label: t('leave.status.waiting'),
            },
            {
              value: LeaveStatusState.REJECTED,
              label: t('leave.status.rejected'),
            },
          ],
          selectedChoices: [],
        },
      ],
    }),
    updateFilter,
  });

  function updateFilter(newFilter: FilterOption[]) {
    const _options = { ...options };
    _options.filter = newFilter;
    saveFilter({ componentName, filter: newFilter });
    setOptions(_options);
  }

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  return (
    <Table
      columns={columns}
      rows={userLeaves}
      options={options}
      lang={lang}
      fetching={isValidateUserLeaveRequestLoading}
    />
  );
}
