import {
  ActionIcon,
  Group,
  Indicator,
  LoadingOverlay,
  Popover,
  ScrollArea,
  Space,
  Text,
  Title,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconBell,
  IconCheck,
  IconEyeCheck,
  IconTrash,
  IconX,
} from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';

import CustomTooltip from '../../../../components/CustomTooltip';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import NotificationService from '../../../../services/NotificationService';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
import UserAppNotificationsItem from './UserAppNotificationsItem';

export default function UserAppNotifications() {
  const { user } = useAuth();
  const { t } = useI18n();
  const [opened, setOpened] = useState(false);

  const { data: notifications, refetch: refetchUserAppNotifications } =
    useQuery({
      queryKey: ['NotificationService.getUserNotificationList', user.id],
      queryFn: () => NotificationService.getUserNotificationList(user.id),
    });

  const {
    mutate: markUserNotificationAsViewed,
    isLoading: isMarkUserNotificationAsViewedLoading,
  } = useMutation({
    mutationFn: (variables: { notificationId: string }) =>
      NotificationService.markUserNotificationAsViewed(
        variables.notificationId,
        user.id
      ),
    onSuccess: () => {
      refetchUserAppNotifications();
    },
    onError: (error) =>
      showNotification({
        id: 'mark-user-notification-as-viewed-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const {
    mutate: deleteUserNotification,
    isLoading: isDeleteUserNotificationLoading,
  } = useMutation({
    mutationFn: (variables: { notificationId: string }) =>
      NotificationService.deleteUserNotification(
        variables.notificationId,
        user.id
      ),
    onSuccess: () => {
      refetchUserAppNotifications();
      modals.closeAll();
      showNotification({
        id: `delete-user-notification-successful`,
        title: t('w.success'),
        message: 'Notification(s) supprimée(s)',
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: (error) =>
      showNotification({
        id: 'delete-user-notification-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function handleDeleteAllNotifications() {
    notifications?.forEach((notification) =>
      deleteUserNotification({ notificationId: notification.id })
    );
  }

  function handleMarkAllUserNotificationClick() {
    notifications?.forEach((notification) =>
      markUserNotificationAsViewed({ notificationId: notification.id })
    );
  }

  return (
    <Popover
      width={700}
      position="bottom"
      withArrow
      shadow="md"
      opened={opened}
      onChange={setOpened}
    >
      <Popover.Target>
        <ActionIcon
          variant="subtle"
          size={'xl'}
          color={'grey'}
          onClick={() => setOpened((o) => !o)}
        >
          <Indicator
            inline
            label={!!notifications?.length ? notifications.length : ''}
            disabled={
              notifications?.filter((notification) => !notification.viewed)
                .length === 0
            }
            offset={5}
            size={16}
          >
            <CustomTooltip label={t('w.notifications')}>
              <IconBell />
            </CustomTooltip>
          </Indicator>
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown w={400}>
        <LoadingOverlay
          visible={
            isMarkUserNotificationAsViewedLoading ||
            isDeleteUserNotificationLoading
          }
        />
        <Group justify={'space-between'} align={'center'}>
          <Title
            size={'h4'}
          >{`Notifications (${notifications?.length})`}</Title>
          {!!notifications?.length && (
            <Group justify={'space-between'} mt={'xs'}>
              <CustomTooltip label={t('w.markAllAsRead')}>
                <ActionIcon
                  variant="filled"
                  color="blue"
                  onClick={handleMarkAllUserNotificationClick}
                >
                  <IconEyeCheck
                    style={{ width: '70%', height: '70%' }}
                    stroke={1.5}
                  />
                </ActionIcon>
              </CustomTooltip>
              <CustomTooltip label={t('w.deleteAll')}>
                <ActionIcon
                  variant="filled"
                  color="red"
                  onClick={handleDeleteAllNotifications}
                >
                  <IconTrash
                    style={{ width: '70%', height: '70%' }}
                    stroke={1.5}
                  />
                </ActionIcon>
              </CustomTooltip>
            </Group>
          )}
        </Group>
        <Space h={'sm'} />
        <ScrollArea
          h={
            !!notifications?.length && notifications.length > 4
              ? 400
              : undefined
          }
        >
          {!!notifications?.length ? (
            notifications.map((notification) => (
              <UserAppNotificationsItem
                key={notification.id}
                notification={notification}
                markUserNotificationAsViewed={markUserNotificationAsViewed}
              />
            ))
          ) : (
            <Text fz={'sm'} fw={'bold'} c={'dimmed'}>
              {t('w.noNotification')}...
            </Text>
          )}
        </ScrollArea>
      </Popover.Dropdown>
    </Popover>
  );
}
