import type { CounterTypeResponse } from '../types/api/response/counterType';
import type {
  PayslipExtractLocation,
  PayslipExtractLocationPayload,
  PayslipLocation,
} from '../types/types';

export const PAYSLIP_CONFIG_COLORS = {
  name: '#800000',
  periodImported: '#9A6324',
  matricule: '#e6194B',
  codeCompany: '#f58231',
  siret: '#ffe119',
  grossAmount: '#469990',
  netAmount: '#000075',
  acquisN: '#f032e6',
  acquisN1: '#42d4f4',
  counterColors: [
    '#4363d8',
    '#3cb44b',
    '#fabed4',
    '#fffac8',
    '#aaffc3',
    '#dcbeff',
  ],
};

export const STATIC_FIELDS = Object.keys(PAYSLIP_CONFIG_COLORS).filter(
  (e) => e !== 'counterColors'
);

export type PayslipConfigurationFormObject = {
  id: string;
  color?: string;
  height?: number;
  width?: number;
  x?: number;
  y?: number;
};

export function getColorByKey(
  key: string,
  counterTypes: CounterTypeResponse[]
) {
  if (STATIC_FIELDS.includes(key))
    // @ts-ignore
    return PAYSLIP_CONFIG_COLORS[key];

  const counterTypesKeys = counterTypes
    .map((item) => item.counterType.key)
    .filter((elt) => elt !== 'acquisN' && elt !== 'acquisN1');
  const index = counterTypesKeys.findIndex((item) => item === key);

  return index !== -1 && index < 6
    ? PAYSLIP_CONFIG_COLORS.counterColors[index]
    : '#000000';
}

export function dataToFormData(
  data: PayslipExtractLocation
): Map<string, PayslipConfigurationFormObject> {
  const map: Map<string, PayslipConfigurationFormObject> = new Map();

  if (data.name?.location) {
    map.set('name', {
      id: 'name',
      color: PAYSLIP_CONFIG_COLORS.name,
      height: data.name.location?.height,
      width: data.name.location?.width,
      x: data.name.location?.x,
      y: data.name.location?.y,
    });
  }
  if (data.periodImported?.location) {
    map.set('periodImported', {
      id: 'periodImported',
      color: PAYSLIP_CONFIG_COLORS.periodImported,
      height: data.periodImported.location?.height,
      width: data.periodImported.location?.width,
      x: data.periodImported.location?.x,
      y: data.periodImported.location?.y,
    });
  }
  if (data.matricule?.location) {
    map.set('matricule', {
      id: 'matricule',
      color: PAYSLIP_CONFIG_COLORS.matricule,
      height: data.matricule.location?.height,
      width: data.matricule.location?.width,
      x: data.matricule.location?.x,
      y: data.matricule.location?.y,
    });
  }
  if (data.codeCompany?.location) {
    map.set('codeCompany', {
      id: 'codeCompany',
      color: PAYSLIP_CONFIG_COLORS.codeCompany,
      height: data.codeCompany.location?.height,
      width: data.codeCompany.location?.width,
      x: data.codeCompany.location?.x,
      y: data.codeCompany.location?.y,
    });
  }
  if (data.siret?.location) {
    map.set('siret', {
      id: 'siret',
      color: PAYSLIP_CONFIG_COLORS.siret,
      height: data.siret.location?.height,
      width: data.siret.location?.width,
      x: data.siret.location?.x,
      y: data.siret.location?.y,
    });
  }
  if (data.grossAmount?.location) {
    map.set('grossAmount', {
      id: 'grossAmount',
      color: PAYSLIP_CONFIG_COLORS.grossAmount,
      height: data.grossAmount.location?.height,
      width: data.grossAmount.location?.width,
      x: data.grossAmount.location?.x,
      y: data.grossAmount.location?.y,
    });
  }
  if (data.netAmount?.location) {
    map.set('netAmount', {
      id: 'netAmount',
      color: PAYSLIP_CONFIG_COLORS.netAmount,
      height: data.netAmount.location?.height,
      width: data.netAmount.location?.width,
      x: data.netAmount.location?.x,
      y: data.netAmount.location?.y,
    });
  }

  let index = 0;
  data.counter?.forEach((item) => {
    if (item.location) {
      switch (item.key) {
        case 'acquisN':
          map.set('acquisN', {
            id: 'acquisN',
            color: PAYSLIP_CONFIG_COLORS.acquisN,
            height: item.location.height,
            width: item.location.width,
            x: item.location.x,
            y: item.location.y,
          });
          break;
        case 'acquisN1':
          map.set('acquisN1', {
            id: 'acquisN1',
            color: PAYSLIP_CONFIG_COLORS.acquisN1,
            height: item.location.height,
            width: item.location.width,
            x: item.location.x,
            y: item.location.y,
          });
          break;
        default:
          map.set(item.key, {
            id: item.key,
            color: PAYSLIP_CONFIG_COLORS.counterColors[index]
              ? PAYSLIP_CONFIG_COLORS.counterColors[index]
              : '#000000',
            height: item.location.height,
            width: item.location.width,
            x: item.location.x,
            y: item.location.y,
          });
          index++;
      }
    }
  });

  return map;
}

export function formDataToPayload(
  companyId: string,
  counterTypes: CounterTypeResponse[],
  formData: Map<string, PayslipConfigurationFormObject>
): PayslipExtractLocationPayload {
  let data: PayslipExtractLocationPayload = {
    companyId,
  };

  const counter: PayslipLocation[] = [];

  formData.forEach((value: PayslipConfigurationFormObject, key: string) => {
    if (
      value.x != null &&
      value.y != null &&
      value.width != null &&
      value.height != null
    ) {
      const payslipLocation: PayslipLocation = {
        key,
        counterTypeId: counterTypes.find((item) => item.counterType.key === key)
          ?.counterType?.id,
        location: {
          x: Math.round(value.x),
          y:
            value.height > 0
              ? Math.round(value.y)
              : Math.round(value.y) + Math.round(value.height),
          width: Math.round(value.width),
          height: Math.abs(Math.round(value.height)),
        },
      };

      switch (value.id) {
        case 'name':
        case 'periodImported':
        case 'matricule':
        case 'codeCompany':
        case 'siret':
        case 'grossAmount':
        case 'netAmount':
          data = { ...data, [key]: payslipLocation };
          break;
        default:
          counter.push(payslipLocation);
      }
    }
  });

  if (counter?.length) {
    data.counter = counter;
  }

  return data;
}

export function getQuadraConfig(): Map<string, PayslipConfigurationFormObject> {
  const config: Map<string, PayslipConfigurationFormObject> = new Map();
  config.set('name', {
    id: 'name',
    color: PAYSLIP_CONFIG_COLORS.name,
    height: 15,
    width: 223,
    x: 324,
    y: 98,
  });
  config.set('acquisN', {
    id: 'acquisN',
    color: PAYSLIP_CONFIG_COLORS.acquisN,
    height: 13,
    width: 29,
    x: 126,
    y: 129,
  });
  config.set('acquisN1', {
    id: 'acquisN1',
    color: PAYSLIP_CONFIG_COLORS.acquisN1,
    height: 13,
    width: 29,
    x: 80,
    y: 129,
  });
  config.set('periodImported', {
    id: 'periodImported',
    color: PAYSLIP_CONFIG_COLORS.periodImported,
    height: 15,
    width: 68,
    x: 391,
    y: 46,
  });
  config.set('siret', {
    id: 'siret',
    color: PAYSLIP_CONFIG_COLORS.siret,
    height: 12,
    width: 79,
    x: 54,
    y: 74,
  });
  config.set('netAmount', {
    id: 'netAmount',
    color: PAYSLIP_CONFIG_COLORS.netAmount,
    height: 16,
    width: 100,
    x: 465,
    y: 672,
  });
  config.set('grossAmount', {
    id: 'grossAmount',
    color: PAYSLIP_CONFIG_COLORS.grossAmount,
    height: 14,
    width: 76,
    x: 357,
    y: 234,
  });

  return config;
}

export function getSilaeConfig(): Map<string, PayslipConfigurationFormObject> {
  const config = new Map();
  config.set('name', {
    id: 'name',
    color: PAYSLIP_CONFIG_COLORS.name,
    height: 16,
    width: 232,
    x: 296,
    y: 119,
  });
  config.set('acquisN', {
    id: 'acquisN',
    color: PAYSLIP_CONFIG_COLORS.acquisN,
    height: 10,
    width: 28,
    x: 130,
    y: 822,
  });
  config.set('acquisN1', {
    id: 'acquisN1',
    color: PAYSLIP_CONFIG_COLORS.acquisN1,
    height: 10,
    width: 28,
    x: 71,
    y: 822,
  });
  config.set('periodImported', {
    id: 'periodImported',
    color: PAYSLIP_CONFIG_COLORS.periodImported,
    height: 15,
    width: 74,
    x: 365,
    y: 27,
  });
  config.set('siret', {
    id: 'siret',
    color: PAYSLIP_CONFIG_COLORS.siret,
    height: 10,
    width: 74,
    x: 33,
    y: 54,
  });
  config.set('netAmount', {
    id: 'netAmount',
    color: PAYSLIP_CONFIG_COLORS.netAmount,
    height: 18,
    width: 47,
    x: 481,
    y: 799,
  });
  config.set('grossAmount', {
    id: 'grossAmount',
    color: PAYSLIP_CONFIG_COLORS.grossAmount,
    height: 11,
    width: 45,
    x: 401,
    y: 333,
  });

  return config;
}

export function getCogilogConfig(): Map<
  string,
  PayslipConfigurationFormObject
> {
  const config = new Map();
  config.set('name', {
    id: 'name',
    color: PAYSLIP_CONFIG_COLORS.name,
    height: 13,
    width: 234,
    x: 303,
    y: 140,
  });
  config.set('periodImported', {
    id: 'periodImported',
    color: PAYSLIP_CONFIG_COLORS.periodImported,
    height: 12,
    width: 48,
    x: 134,
    y: 133,
  });

  return config;
}

export function getPegaseConfig(): Map<string, PayslipConfigurationFormObject> {
  const config = new Map();
  config.set('name', {
    id: 'name',
    color: PAYSLIP_CONFIG_COLORS.name,
    height: 13,
    width: 203,
    x: 334,
    y: 140,
  });
  config.set('acquisN', {
    id: 'acquisN',
    color: PAYSLIP_CONFIG_COLORS.acquisN,
    height: 11,
    width: 27,
    x: 83,
    y: 739,
  });
  config.set('acquisN1', {
    id: 'acquisN1',
    color: PAYSLIP_CONFIG_COLORS.acquisN1,
    height: 13,
    width: 28,
    x: 83,
    y: 756,
  });
  config.set('periodImported', {
    id: 'periodImported',
    color: PAYSLIP_CONFIG_COLORS.periodImported,
    height: 12,
    width: 48,
    x: 153,
    y: 113,
  });
  config.set('matricule', {
    id: 'matricule',
    color: PAYSLIP_CONFIG_COLORS.matricule,
    height: 12,
    width: 100,
    x: 67,
    y: 143,
  });
  config.set('codeCompany', {
    id: 'codeCompany',
    color: PAYSLIP_CONFIG_COLORS.codeCompany,
    height: 12,
    width: 59,
    x: 524,
    y: 92,
  });
  config.set('siret', {
    id: 'siret',
    color: PAYSLIP_CONFIG_COLORS.siret,
    height: 11,
    width: 81,
    x: 366,
    y: 103,
  });
  config.set('netAmount', {
    id: 'netAmount',
    color: PAYSLIP_CONFIG_COLORS.netAmount,
    height: 15,
    width: 88,
    x: 440,
    y: 723,
  });
  config.set('grossAmount', {
    id: 'grossAmount',
    color: PAYSLIP_CONFIG_COLORS.grossAmount,
    height: 14,
    width: 44,
    x: 467,
    y: 263,
  });

  return config;
}

export function getIsapayeConfig(): Map<
  string,
  PayslipConfigurationFormObject
> {
  const config = new Map();
  config.set('name', {
    id: 'name',
    color: PAYSLIP_CONFIG_COLORS.name,
    height: 16,
    width: 241,
    x: 296,
    y: 150,
  });
  config.set('periodImported', {
    id: 'periodImported',
    color: PAYSLIP_CONFIG_COLORS.periodImported,
    height: 25,
    width: 122,
    x: 445,
    y: 15,
  });

  return config;
}

export function getDiapaieConfig(): Map<
  string,
  PayslipConfigurationFormObject
> {
  const config = new Map();
  config.set('name', {
    id: 'name',
    color: PAYSLIP_CONFIG_COLORS.name,
    height: 17,
    width: 236,
    x: 307,
    y: 157,
  });
  config.set('acquisN', {
    id: 'acquisN',
    color: PAYSLIP_CONFIG_COLORS.acquisN,
    height: 10,
    width: 39,
    x: 183,
    y: 785,
  });
  config.set('acquisN1', {
    id: 'acquisN1',
    color: PAYSLIP_CONFIG_COLORS.acquisN1,
    height: 10,
    width: 40,
    x: 183,
    y: 795,
  });
  config.set('periodImported', {
    id: 'periodImported',
    color: PAYSLIP_CONFIG_COLORS.periodImported,
    height: 13,
    width: 58,
    x: 301,
    y: 40,
  });
  config.set('matricule', {
    id: 'matricule',
    color: PAYSLIP_CONFIG_COLORS.matricule,
    height: 11,
    width: 124,
    x: 270,
    y: 68,
  });
  config.set('siret', {
    id: 'siret',
    color: PAYSLIP_CONFIG_COLORS.siret,
    height: 13,
    width: 79,
    x: 83.89,
    y: 137.86,
  });
  config.set('netAmount', {
    id: 'netAmount',
    color: PAYSLIP_CONFIG_COLORS.netAmount,
    height: 15,
    width: 92,
    x: 460,
    y: 761,
  });
  config.set('grossAmount', {
    id: 'grossAmount',
    color: PAYSLIP_CONFIG_COLORS.grossAmount,
    height: 11,
    width: 52,
    x: 203,
    y: 726,
  });

  return config;
}

export function getOpenpayeConfig(): Map<
  string,
  PayslipConfigurationFormObject
> {
  const config = new Map();
  config.set('name', {
    id: 'name',
    color: PAYSLIP_CONFIG_COLORS.name,
    height: 15,
    width: 223,
    x: 357,
    y: 121,
  });
  config.set('acquisN', {
    id: 'acquisN',
    color: PAYSLIP_CONFIG_COLORS.acquisN,
    height: 13,
    width: 28,
    x: 114,
    y: 806,
  });
  config.set('acquisN1', {
    id: 'acquisN1',
    color: PAYSLIP_CONFIG_COLORS.acquisN1,
    height: 11,
    width: 29,
    x: 45,
    y: 806,
  });
  config.set('periodImported', {
    id: 'periodImported',
    color: PAYSLIP_CONFIG_COLORS.periodImported,
    height: 15,
    width: 68,
    x: 446,
    y: 35,
  });
  config.set('siret', {
    id: 'siret',
    color: PAYSLIP_CONFIG_COLORS.siret,
    height: 12,
    width: 82,
    x: 45,
    y: 78,
  });
  config.set('netAmount', {
    id: 'netAmount',
    color: PAYSLIP_CONFIG_COLORS.netAmount,
    height: 20,
    width: 33,
    x: 476,
    y: 787,
  });
  config.set('grossAmount', {
    id: 'grossAmount',
    color: PAYSLIP_CONFIG_COLORS.grossAmount,
    height: 14,
    width: 37,
    x: 45,
    y: 720,
  });

  return config;
}

export function getFullConfig(): Map<string, PayslipConfigurationFormObject> {
  const config = new Map();
  config.set('name', {
    id: 'name',
    color: PAYSLIP_CONFIG_COLORS.name,
    height: 15,
    width: 223,
    x: 308,
    y: 141,
  });
  config.set('acquisN', {
    id: 'acquisN',
    color: PAYSLIP_CONFIG_COLORS.acquisN,
    height: 13,
    width: 33,
    x: 91,
    y: 750,
  });
  config.set('acquisN1', {
    id: 'acquisN1',
    color: PAYSLIP_CONFIG_COLORS.acquisN1,
    height: 13,
    width: 34,
    x: 49,
    y: 750,
  });
  config.set('periodImported', {
    id: 'periodImported',
    color: PAYSLIP_CONFIG_COLORS.periodImported,
    height: 11,
    width: 67,
    x: 423,
    y: 41,
  });
  config.set('siret', {
    id: 'siret',
    color: PAYSLIP_CONFIG_COLORS.siret,
    height: 11,
    width: 67,
    x: 49,
    y: 86,
  });
  config.set('netAmount', {
    id: 'netAmount',
    color: PAYSLIP_CONFIG_COLORS.netAmount,
    height: 16,
    width: 53,
    x: 479,
    y: 707,
  });
  config.set('grossAmount', {
    id: 'grossAmount',
    color: PAYSLIP_CONFIG_COLORS.grossAmount,
    height: 10,
    width: 47,
    x: 108,
    y: 791,
  });

  return config;
}

export function getSageConfig(): Map<string, PayslipConfigurationFormObject> {
  const config = new Map();
  config.set('name', {
    id: 'name',
    color: PAYSLIP_CONFIG_COLORS.name,
    height: 15,
    width: 223,
    x: 302,
    y: 126,
  });
  config.set('acquisN', {
    id: 'acquisN',
    color: PAYSLIP_CONFIG_COLORS.acquisN,
    height: 10,
    width: 28,
    x: 545,
    y: 751,
  });
  config.set('acquisN1', {
    id: 'acquisN1',
    color: PAYSLIP_CONFIG_COLORS.acquisN1,
    height: 10,
    width: 28,
    x: 545,
    y: 762,
  });
  config.set('periodImported', {
    id: 'periodImported',
    color: PAYSLIP_CONFIG_COLORS.periodImported,
    height: 14,
    width: 67,
    x: 467,
    y: 59,
  });
  config.set('siret', {
    id: 'siret',
    color: PAYSLIP_CONFIG_COLORS.siret,
    height: 12,
    width: 74,
    x: 62,
    y: 92,
  });
  config.set('netAmount', {
    id: 'netAmount',
    color: PAYSLIP_CONFIG_COLORS.netAmount,
    height: 10,
    width: 54,
    x: 519,
    y: 702,
  });
  config.set('grossAmount', {
    id: 'grossAmount',
    color: PAYSLIP_CONFIG_COLORS.grossAmount,
    height: 16,
    width: 47,
    x: 83,
    y: 698,
  });

  return config;
}

export function getCegidExpertConfig(): Map<
  string,
  PayslipConfigurationFormObject
> {
  const config = new Map();
  config.set('name', {
    id: 'name',
    color: PAYSLIP_CONFIG_COLORS.name,
    height: 15,
    width: 223,
    x: 302,
    y: 103,
  });
  config.set('acquisN', {
    id: 'acquisN',
    color: PAYSLIP_CONFIG_COLORS.acquisN,
    height: 8,
    width: 28,
    x: 128,
    y: 758,
  });
  config.set('acquisN1', {
    id: 'acquisN1',
    color: PAYSLIP_CONFIG_COLORS.acquisN1,
    height: 8,
    width: 28,
    x: 68,
    y: 758,
  });
  config.set('periodImported', {
    id: 'periodImported',
    color: PAYSLIP_CONFIG_COLORS.periodImported,
    height: 15,
    width: 68,
    x: 156,
    y: 31,
  });
  config.set('siret', {
    id: 'siret',
    color: PAYSLIP_CONFIG_COLORS.siret,
    height: 12,
    width: 74,
    x: 345,
    y: 53,
  });
  config.set('netAmount', {
    id: 'netAmount',
    color: PAYSLIP_CONFIG_COLORS.netAmount,
    height: 14,
    width: 61,
    x: 381,
    y: 702,
  });

  return config;
}
