import { Badge } from '@mantine/core';
import React from 'react';

type Props = {
  value: number;
};

export default function IndicatorBadge({ value }: Props) {
  return (
    <Badge
      size={value > 9 ? (value > 99 ? 'lg' : 'md') : 'sm'}
      circle
      ml="xs"
      styles={{ label: { fontSize: 11 } }}
    >
      {value}
    </Badge>
  );
}
