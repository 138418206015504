import { usePrevious } from '@mantine/hooks';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { cloneDeep, debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';

import { useAuth } from '../contexts/AuthProvider';
import type { DailyWorkDetails } from '../types/api/response/dailyWork';
import type { UserDailyWork } from '../types/front/dailyWork';
import type { Period } from '../types/types';
import { MS_PLANNING_ENDPOINT } from '../variables/GlobalVariables';

type Props = {
  divisionIds?: string[];
  period: Period;
};

type EventData = {
  id: string;
  userId: string;
  payload: DailyWorkDetails[];
};

export default function useActivitiesSSE({ period, divisionIds }: Props) {
  const { user, access_token } = useAuth();
  const [debouncedDivisionIds, setDebouncedDivisionIds] = useState<string[]>(
    []
  );
  useEffect(() => {
    const debouncedUpdate = debounce(() => {
      setDebouncedDivisionIds(divisionIds || []);
    }, 1000);

    debouncedUpdate();

    return () => {
      debouncedUpdate.cancel();
    };
  }, [divisionIds]);
  const url = !!debouncedDivisionIds.length
    ? `${MS_PLANNING_ENDPOINT}/v2/planning/real-time/sse/${
        user.id
      }/subscriptions/${
        period.start.month() + 1
      }/${period.start.year()}?${debouncedDivisionIds
        ?.filter((divisionId) => divisionId !== 'all')
        ?.map((item) => `divisionId=${encodeURIComponent(item)}`)
        .join('&')}`
    : undefined;
  const sourceRef = useRef<EventSource | null>(null);
  const previousUrl = usePrevious(url);
  const [events, setEvents] = useState<EventData[]>([]);
  const [usersActivities, setUsersActivities] = useState<UserDailyWork[]>([]);

  useEffect(() => {
    if (!!url) {
      if (previousUrl !== url) {
        closeSSE();
      }

      setEvents([]);
      subscribe(url);
    }

    return () => {
      closeSSE();
    };
  }, [url]);

  useEffect(() => {
    if (!!events.length) {
    }
  }, [events]);

  function subscribe(_url: string) {
    try {
      sourceRef.current = new EventSourcePolyfill(_url, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });

      sourceRef.current.addEventListener(
        'message',
        function (e) {
          try {
            const newEventData: EventData = JSON.parse(e.data);
            const daylies = newEventData?.payload;
            if (!!newEventData && !!daylies?.length) {
              setEvents((prevState) => {
                const result = cloneDeep(prevState);
                setUsersActivities((prevState) => {
                  const items: UserDailyWork[] = cloneDeep(prevState);
                  const indexInData = items.findIndex(
                    (elt) => elt.userId === daylies[0].user.id
                  );

                  const newItem = {
                    userId: daylies[0].user.id,
                    employeeName: `${daylies[0].user.firstname} ${daylies[0].user.lastname}`,
                    status:
                      daylies.find(
                        (dailyWork) => dailyWork.status === 'WAITING'
                      ) !== undefined
                        ? 'WAITING'
                        : 'VALID',
                    dailyWorks: daylies,
                  };
                  if (indexInData === -1) {
                    items.push(newItem);
                  } else {
                    items[indexInData] = newItem;
                  }

                  return items;
                });

                result.push(newEventData);
                return result;
              });
            }
          } catch (_e) {}
        },
        false
      );

      sourceRef.current.addEventListener('error', closeSSE, false);
    } catch (_e) {
      closeSSE();
    }
  }

  function closeSSE() {
    if (sourceRef.current) {
      sourceRef.current.close();
      sourceRef.current = null;
    }
  }

  function refetch() {
    closeSSE();

    if (!!url) {
      subscribe(url);
    }
  }

  return {
    usersActivities,
    refetch,
    loading: events.length !== debouncedDivisionIds.length,
  };
}
