import { FilterOption, Options, Table } from '@ckprivate/ckf-ui';
import { ActionIcon, Group, Text, Title } from '@mantine/core';
import type {
  DatesRangeValue,
  DateValue,
} from '@mantine/dates/lib/types/DatePickerValue';
import { useDisclosure } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconCheck,
  IconFileTypePdf,
  IconTrash,
  IconUserPlus,
  IconUserUp,
  IconX,
} from '@tabler/icons-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { DataTableColumn } from 'mantine-datatable';
import React, { useMemo, useState } from 'react';

import CustomTooltip from '../../../../../components/CustomTooltip';
import { useAuth } from '../../../../../contexts/AuthProvider';
import { useI18n } from '../../../../../contexts/I18nProvider';
import useFetchUsers from '../../../../../hooks/useFetchUsers';
import PayslipService from '../../../../../services/PayslipService';
import type { PayslipMonthResponse } from '../../../../../types/types';
import { handleOpenPayslipPdfFile } from '../../../../../utils/attachmentFile';
import handleErrorMessage from '../../../../../utils/handleErrorMessage';
import {
  getFilter,
  getHiddenColumns,
  saveFilter,
  saveHiddenColumns,
} from '../../../../../utils/optionsPreferences';
import AddEmployeeModal from '../../../../company/employees/components/AddEmployeeModal';
import AssignPayslipNotMatchedForm from './AssignPayslipNotMatchedForm';

const componentName = 'PayslipsNotMatchedTable';

type Props = {
  payslipsMonthInfo: PayslipMonthResponse | undefined;
  selectedDate: DateValue | DatesRangeValue | Date[];
  companyId: string;
};

export default function PayslipsNotMatchedTable({
  payslipsMonthInfo,
  selectedDate,
  companyId,
}: Props) {
  const { t, lang } = useI18n();
  const { access_token } = useAuth();
  const { divisions, roles, refetchUsers } = useFetchUsers(companyId);
  const queryClient = useQueryClient();
  const [
    openedAddEmployeeModal,
    { open: handleOpenAddEmployeeModal, close: handleCloseAddEmployeeModal },
  ] = useDisclosure(false);

  const columns: DataTableColumn<any>[] = useMemo(
    () => [
      {
        accessor: 'fullname',
        title: t('w.name'),
        sortable: true,
        ellipsis: true,
        render: ({ fullname }) => <Text fz={'sm'}>{fullname}</Text>,
      },
      {
        accessor: 'userId',
        title: t('w.payslip'),
        sortable: true,
        ellipsis: true,
        render: ({ userId, fullname }) => (
          <CustomTooltip label={t('w.see')}>
            <ActionIcon
              variant={'subtle'}
              onClick={() =>
                handleOpenPayslipPdfFile(
                  userId,
                  fullname,
                  selectedDate,
                  payslipsMonthInfo,
                  access_token,
                  t
                )
              }
            >
              <IconFileTypePdf />
            </ActionIcon>
          </CustomTooltip>
        ),
      },
      {
        accessor: 'actions',
        title: 'Actions',
        width: '100px',
        sortable: true,
        ellipsis: true,
        render: ({ userId, fullname }) => (
          <Group gap={'sm'} justify="center" wrap={'nowrap'}>
            <CustomTooltip label={t('w.assign')}>
              <ActionIcon
                size="sm"
                color="blue"
                variant={'subtle'}
                onClick={() => handleOpenAssignPayslipToEmployeeModal(userId)}
              >
                <IconUserUp size={16} />
              </ActionIcon>
            </CustomTooltip>
            <CustomTooltip label={t('w.add')}>
              <ActionIcon
                size="sm"
                color="green"
                variant={'subtle'}
                onClick={handleOpenAddEmployeeModal}
              >
                <IconUserPlus size={16} />
              </ActionIcon>
            </CustomTooltip>
            <CustomTooltip label={t('w.delete')}>
              <ActionIcon
                size="sm"
                color="red"
                variant={'subtle'}
                onClick={() => handleOpenDeletePayslipModal(userId, fullname)}
              >
                <IconTrash size={16} />
              </ActionIcon>
            </CustomTooltip>
          </Group>
        ),
      },
    ],
    []
  );

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    search: {
      accessor: 'fullname',
      label: t('w.name'),
    },
    hiddenColumns: getHiddenColumns({
      componentName,
      hiddenColumns: [''],
    }),
    updateHiddenColumns,
    filter: getFilter({
      componentName,
      filter: [],
    }),
    updateFilter,
  });

  function updateFilter(newFilter: FilterOption[]) {
    const _options = { ...options };
    _options.filter = newFilter;
    saveFilter({ componentName, filter: newFilter });
    setOptions(_options);
  }

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  const { mutate: removeUserPayslip } = useMutation({
    mutationFn: (variables: { userId: string }) =>
      PayslipService.removeUserPayslip(
        companyId,
        variables.userId,
        // @ts-ignore
        dayjs(selectedDate).month() + 1,
        // @ts-ignore
        dayjs(selectedDate).year()
      ),
    onSuccess: () => {
      showNotification({
        id: 'delete-user-payslip-successful',
        title: t('w.success'),
        message: t('w.deleted'),
        color: 'green',
        icon: <IconCheck />,
      });
      queryClient.invalidateQueries({
        queryKey: [
          'PayslipService.getPayslipsTreatmentForCompanyId',
          companyId,
        ],
      });
    },
    onError: (error) =>
      showNotification({
        id: 'delete-user-payslip-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function handleOpenAssignPayslipToEmployeeModal(userId: string) {
    modals.open({
      modalId: `assign-payslip-not-matched-to-user-${userId}`,
      title: (
        <Title size={'h3'} component="p">
          {t('w.assignmentToAnEmployee')}
        </Title>
      ),
      size: 'xl',
      children: (
        <AssignPayslipNotMatchedForm
          payslipsMonthInfo={payslipsMonthInfo}
          selectedUserId={userId}
          companyId={companyId}
          selectedDate={selectedDate}
        />
      ),
    });
  }

  function handleOpenDeletePayslipModal(userId: string, fullname: string) {
    modals.openConfirmModal({
      modalId: `delete-payslip-not-matched-for-user-${userId}`,
      title: (
        <Title size={'h3'} component="p">
          {`${t('w.confirmDeletePayslip')} - ${fullname}`} ?
        </Title>
      ),
      size: 'xl',
      labels: { confirm: t('w.delete'), cancel: t('w.cancel') },
      onConfirm: () => removeUserPayslip({ userId }),
    });
  }

  return (
    <>
      <Table
        options={options}
        columns={columns}
        rows={payslipsMonthInfo?.new}
        lang={lang}
        idAccessor={'userId'}
        height={'calc(100vh - 360px)'}
      />
      <AddEmployeeModal
        opened={openedAddEmployeeModal}
        onClose={handleCloseAddEmployeeModal}
        divisions={divisions ? divisions : []}
        roles={roles ? roles : []}
        refetch={refetchUsers}
      />
    </>
  );
}
