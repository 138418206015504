import type { CommonEntity, YearMonth } from '../common';
import type { CompanyResponse } from './company';

export type CompanyAccountantInfoViewClient = {
  accountant: CharteredAccountantView;
  paiementAccount: boolean;
  active: boolean;
  divisionAccountant?: DivisionAccountant[];
  generalManagers?: AccountantUserView[];
  managedDivision?: DivisionView[];
  clientCompanyId: string;
  customerCompany: CompanyResponse;
  status?: MonthStatus;
};

export type CharteredAccountantView = {
  id: string;
  name: string;
  address: string;
  mail: string;
  phone: string;
};

export type DivisionAccountant = {
  id: string;
  name: string;
  accountantUser?: AccountantUserView[];
};

export type AccountantUserView = {
  id: string;
  firstname: string;
  lastname: string;
  mail: string;
};

type DivisionView = {
  id: string;
  name: string;
};

export type MonthStatus = {
  monthConcerned: YearMonth;
  globalValidation: boolean;
  divisions?: DivisionMonthStatus[];
};

type DivisionMonthStatus = {
  divisionId: string;
  validation: boolean;
  date?: number;
};

export type ManageAccountant = CommonEntity & {
  who: UserInfo;
  toMail: string;
  toManage: UserInfo;
  requestType: ManageAccountantType;
  status: ManageAccountantStatus;
};

export type UserInfo = {
  fullname: string;
  userId: string;
  mail: string;
  companyId: string;
  companyName: string;
};

enum ManageAccountantStatus {
  SEND = 'send',
  PROCCESSING = 'proccessing',
  REMOVE = 'remove',
  REJECT = 'reject',
  ACCEPT = 'accept',
}

enum ManageAccountantType {
  LINK_CLIENT_TO_ACCOUNTANT = 'linkClientToAccountant',
  DELETE = 'delete',
}

export type ImportClientsResponse = {
  nbCreated: number;
  nbErrors: number;
  fileName: string;
  importClientReports: ImportClientReport[];
};

export type ImportClientReport = {
  companyName: string;
  lineNb: number;
  message: string;
  status: ImportClientStatus;
};

export enum ImportClientStatus {
  OK = 'OK',
  NOK = 'NOK',
}
