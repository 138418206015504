import { Container } from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';

import CustomModal from '../../../components/CustomModal';
import { useI18n } from '../../../contexts/I18nProvider';
import useCompany from '../../../hooks/useCompany';
import useFetchUsers from '../../../hooks/useFetchUsers';
import type {
  UserContractualInfoType,
  UserEmergencyContactType,
  UserPersonalFamily,
  UserPersonalInfo,
  UserPersonalSensitive,
  UserResponse,
} from '../../../types/api/response/user';
import AddEmployeeSteppers from '../../company/employees/components/AddEmployeeSteppers/AddEmployeeSteppers';

type Props = {
  opened: boolean;
  onClose: () => void;
  userProfile: UserResponse;
  userContractualInfo: UserContractualInfoType | undefined;
  userFamilySituation: UserPersonalFamily | undefined;
  userPersonalInfo: UserPersonalInfo | undefined;
  userSensitiveInfo: UserPersonalSensitive | undefined;
  userEmergencyContact: UserEmergencyContactType[] | undefined;
  refetchUserEmergencyContact: () => void;
  refetchUserPersonalInfo: () => void;
  refetchUserSensitiveInfo: () => void;
  refetchUserFamilySituation: () => void;
  refetchUserContractualInfo: () => void;
  refetchOBInfos: () => void;
};

export default function UserProfileEditModal({
  opened,
  onClose,
  userProfile,
  userContractualInfo,
  userFamilySituation,
  userPersonalInfo,
  userSensitiveInfo,
  userEmergencyContact,
  refetchUserEmergencyContact,
  refetchUserContractualInfo,
  refetchUserSensitiveInfo,
  refetchUserPersonalInfo,
  refetchUserFamilySituation,
  refetchOBInfos,
}: Props) {
  const { t } = useI18n();
  const queryClient = useQueryClient();
  const { id: companyId } = useCompany(userProfile);
  const { divisions, roles } = useFetchUsers(companyId);

  function refetchUserProfile() {
    queryClient.invalidateQueries({
      queryKey: ['ProfileService.getUserProfile', userProfile.id],
    });
  }

  return (
    <CustomModal
      opened={opened}
      onClose={onClose}
      fullScreen
      title={t('w.modifyEmployee', userProfile.fullname)}
    >
      <Container fluid>
        <AddEmployeeSteppers
          divisions={divisions ? divisions : []}
          roles={roles ? roles : []}
          onClose={onClose}
          refetch={refetchUserProfile}
          userProfile={userProfile}
          userPersonalInfo={userPersonalInfo}
          userSensitiveInfo={userSensitiveInfo}
          userContractualInfo={userContractualInfo}
          userFamilySituation={userFamilySituation}
          userEmergencyContact={userEmergencyContact}
          refetchUserEmergencyContact={refetchUserEmergencyContact}
          refetchUserPersonalInfo={refetchUserPersonalInfo}
          refetchUserSensitiveInfo={refetchUserSensitiveInfo}
          refetchUserFamilySituation={refetchUserFamilySituation}
          refetchUserContractualInfo={refetchUserContractualInfo}
          refetchOBInfos={refetchOBInfos}
        />
      </Container>
    </CustomModal>
  );
}
