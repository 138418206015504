import { Button } from '@mantine/core';
import { IconCirclePlus } from '@tabler/icons-react';
import React from 'react';

import { useI18n } from '../../../contexts/I18nProvider';

type Props = {
  onClick: () => void;
};

export default function UserIdentifiantsCompanyButton({ onClick }: Props) {
  const { t } = useI18n();

  return (
    <Button
      leftSection={<IconCirclePlus />}
      variant={'outline'}
      onClick={onClick}
    >
      {`${t('w.add')} ${t('w.companyCode')} / ${t('w.serialNumber')}`}
    </Button>
  );
}
