import type { FileWithPath } from '@mantine/dropzone';
import dayjs from 'dayjs';
import qs from 'qs';

import type {
  DailyWorkDailyTemplateRequest,
  DailyWorkRequest,
  DailyWorkWeeklyTemplateRequest,
} from '../types/api/payload/dailyWork';
import type { IdsRequest } from '../types/api/payload/module';
import type {
  DailyWorkDailyTemplateResponse,
  DailyWorkDetails,
  DailyWorkDetailsStatus,
  DailyWorkWeeklyTemplateResponse,
  ImportDailyWorkWarningResponse,
} from '../types/api/response/dailyWork';
import { MS_PLANNING_ENDPOINT } from '../variables/GlobalVariables';
import request from './AxiosClient';

function getDailyTemplates(
  userId: string
): Promise<DailyWorkDailyTemplateResponse[]> {
  return request
    .get(`${MS_PLANNING_ENDPOINT}/v2/dailyWork/dailyTemplates/user/${userId}`)
    .then((res) => res?.data);
}

function updateDailyTemplate(
  companyId: string,
  id: string,
  template: DailyWorkDailyTemplateRequest
): Promise<DailyWorkDailyTemplateResponse> {
  return request
    .patch(
      `${MS_PLANNING_ENDPOINT}/v2/dailyWork/dailyTemplate/${companyId}/${id}`,
      template
    )
    .then((res) => res?.data);
}

function createDailyTemplate(
  companyId: string,
  template: DailyWorkDailyTemplateRequest
): Promise<DailyWorkDailyTemplateResponse> {
  return request
    .post(
      `${MS_PLANNING_ENDPOINT}/v2/dailyWork/dailyTemplate/${companyId}`,
      template
    )
    .then((res) => res?.data);
}

function deleteDailyTemplate(id: string): Promise<void> {
  return request
    .delete(`${MS_PLANNING_ENDPOINT}/v2/dailyWork/dailyTemplate/${id}`)
    .then((res) => res?.data);
}

function getWeeklyTemplates(
  userId: string
): Promise<DailyWorkWeeklyTemplateResponse[]> {
  return request
    .get(`${MS_PLANNING_ENDPOINT}/v2/dailyWork/weeklyTemplates/user/${userId}`)
    .then((res) => res?.data);
}

function updateWeeklyTemplate(
  companyId: string,
  id: string,
  template: DailyWorkWeeklyTemplateRequest
): Promise<DailyWorkWeeklyTemplateResponse> {
  return request
    .patch(
      `${MS_PLANNING_ENDPOINT}/v2/dailyWork/weeklyTemplate/${companyId}/${id}`,
      template
    )
    .then((res) => res?.data);
}

function createWeeklyTemplate(
  companyId: string,
  template: DailyWorkWeeklyTemplateRequest
): Promise<DailyWorkWeeklyTemplateResponse> {
  return request
    .post(
      `${MS_PLANNING_ENDPOINT}/v2/dailyWork/weeklyTemplate/${companyId}`,
      template
    )
    .then((res) => res?.data);
}

function deleteWeeklyTemplate(id: string): Promise<void> {
  return request
    .delete(`${MS_PLANNING_ENDPOINT}/v2/dailyWork/weeklyTemplate/${id}`)
    .then((res) => res?.data);
}

function activateUsers(companyId: string, data: IdsRequest): Promise<void> {
  return request
    .patch(
      `${MS_PLANNING_ENDPOINT}/v2/dailyWork/module/${companyId}/activate-users`,
      data
    )
    .then((res) => res?.data);
}

function deactivateUsers(companyId: string, data: IdsRequest): Promise<void> {
  return request
    .patch(
      `${MS_PLANNING_ENDPOINT}/v2/dailyWork/module/${companyId}/disable-users`,
      data
    )
    .then((res) => res?.data);
}

function getUserDailyWork(
  userId: string,
  month: number,
  year: number
): Promise<DailyWorkDetails[]> {
  return request
    .get(`${MS_PLANNING_ENDPOINT}/v2/dailyWork/user/${userId}`, {
      params: { month, year },
    })
    .then((res) => res?.data);
}

function updateUserDailyWork(
  userId: string,
  data: DailyWorkRequest
): Promise<DailyWorkDetails> {
  return request
    .patch(
      `${MS_PLANNING_ENDPOINT}/v2/dailyWork/user/${userId}`,
      {
        ...data,
        presence: data.presence?.trim(),
      },
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    )
    .then((res) => res?.data);
}

function exportUserDailyWork(
  userId: string,
  month: number,
  year: number
): Promise<any> {
  return request
    .get(`${MS_PLANNING_ENDPOINT}/v2/dailyWork/user/${userId}/export`, {
      responseType: 'blob',
      params: { month, year },
    })
    .then((res) => res?.data);
}

function importDailyWork(
  file: FileWithPath
): Promise<ImportDailyWorkWarningResponse[]> {
  const formData = new FormData();
  formData.append('file', file);
  return request
    .patch(`${MS_PLANNING_ENDPOINT}/v2/dailyWork/import`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((res) => res?.data);
}

function exportUserDailyWorkByDivisions(
  divisionIds: string[],
  month: number,
  year: number
): Promise<any> {
  return request
    .get(`${MS_PLANNING_ENDPOINT}/v2/dailyWork/divisionsId/export`, {
      responseType: 'blob',
      params: { month, year, divisionId: divisionIds },
      paramsSerializer: function (params) {
        return qs.stringify(params, { indices: false });
      },
    })
    .then((res) => res?.data);
}

const DailyWorkService = {
  getDailyTemplates,
  updateDailyTemplate,
  createDailyTemplate,
  deleteDailyTemplate,
  getWeeklyTemplates,
  updateWeeklyTemplate,
  createWeeklyTemplate,
  deleteWeeklyTemplate,
  activateUsers,
  deactivateUsers,
  getUserDailyWork,
  updateUserDailyWork,
  exportUserDailyWork,
  importDailyWork,
  exportUserDailyWorkByDivisions,
};

export default DailyWorkService;

const UserPresenceHelper = {
  toUserPresence: (
    dailyWork: DailyWorkDetails,
    newStatus?: DailyWorkDetailsStatus
  ) => {
    return {
      id: dailyWork.id,
      date: dayjs(dailyWork.date).format('DD/MM/YYYY'),
      presence: dailyWork.leaves
        .map(
          (leave) =>
            `${dayjs(leave.period.start).utc(false).format('HH:mm')}-${dayjs(
              leave.period.end
            )
              .utc(false)
              .format('HH:mm')}`
        )
        .join(','),
      overtime: dailyWork.overtime,
      comment: dailyWork.comment,
      status: newStatus ? newStatus : dailyWork.status,
      divisionConcerned: dailyWork.divisionConcerned
        ? dailyWork.divisionConcerned
        : dailyWork.user.division.id,
      incrementCounter: dailyWork.incrementCounter,
    };
  },
};

export { UserPresenceHelper };
