export function defaultCurrencies(): string[] {
	return ["EUR"];
}

export function africaCurrencies(): string[] {
	return [
		"EUR",
		"USD",
		"MAD",
		"DZD",
		"TND",
		"MRU",
		"MUR",
		"BIF",
		"KMF",
		"DJF",
		"GNF",
		"MGA",
		"CDF",
		"SCR",
		"RWF",
		"XAF",
		"XOF",
	];
}
