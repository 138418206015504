import React, { createContext, ReactNode, useContext, useState } from 'react';

export const AppBarContext = createContext<any>({} as any);

export const useAppBar = () => useContext(AppBarContext);

type AppBarProviderProps = {
  children: ReactNode;
};

export default function AppBarProvider({ children }: AppBarProviderProps) {
  const [isAppBarUsed, setIsAppBarUsed] = useState<boolean>(false);
  const [appBarSelectedDivisions, setAppBarSelectedDivisions] = useState<
    string[]
  >([]);
  const [divisionsToSelect, setDivisionsToSelect] = useState<string[]>([]);

  return (
    <AppBarContext.Provider
      value={{
        isAppBarUsed,
        setIsAppBarUsed,
        appBarSelectedDivisions,
        setAppBarSelectedDivisions,
        divisionsToSelect,
        setDivisionsToSelect,
      }}
    >
      {children}
    </AppBarContext.Provider>
  );
}
