import React from 'react';

import VariablesPayrollTable from './components/variables-payroll-table/VariablesPayrollTable';

type Props = {
  companyId: string;
};

export default function VariablesPayrollParams({ companyId }: Props) {
  return <VariablesPayrollTable companyId={companyId} />;
}
