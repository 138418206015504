import { TextInput } from '@mantine/core';
import React, { ChangeEvent, ReactNode } from 'react';

import LabelText from '../../../components/LabelText/LabelText';
import { useI18n } from '../../../contexts/I18nProvider';

type Props = {
  value: string | ReadonlyArray<string> | number | undefined;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  error: ReactNode;
};

export default function InputEmail({ value, onChange, error }: Props) {
  const { t } = useI18n();

  return (
    <TextInput
      required
      label={<LabelText text={t('w.emailAddress')} />}
      placeholder={t('w.yourEmailAddress')}
      value={value}
      onChange={onChange}
      error={error}
    />
  );
}
