import { Group, Space, Text } from '@mantine/core';
import { IconAlertTriangle } from '@tabler/icons-react';

import CustomUserAvatar from '../../../../components/CustomAvatar/CustomUserAvatar';
import { OauthUserProfile } from '../../../../types/types';
import MyAccountSettingsAvatarForm from './MyAccountSettingsAvatarForm';
import MyAccountSettingsForm from './MyAccountSettingsForm';

type Props = {
  oAuthUser: OauthUserProfile | null;
};

export default function MyAccountSettings({ oAuthUser }: Props) {
  return (
    <>
      <Group justify={'space-between'}>
        <Group>
          {oAuthUser && (
            <CustomUserAvatar isOauth userId={oAuthUser.id} size={100} />
          )}
          <MyAccountSettingsAvatarForm />
        </Group>
      </Group>
      <Space h={'xl'} />
      <Group gap={'xs'}>
        <IconAlertTriangle color={'red'} />
        <Text fw={'bold'} fz={'xs'} c="red">
          La configuration suivante concerne vos informations de connexion
        </Text>
      </Group>
      <Space h={'md'} />
      <MyAccountSettingsForm oAuthUser={oAuthUser} />
    </>
  );
}
