import { SegmentedControl } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import React from 'react';

import s from '../features/company/features/components/Dailywork/DailyworkParamsFeature.module.sass';
import DailyWorkService from '../services/DailyWorkService';
import ProfileService from '../services/ProfileService';
import { ModuleName } from '../types/api/response/module';
import type { TranslateFunction } from '../types/front/common';
import { PaidFeatures } from '../types/types';
import handleErrorMessage from '../utils/handleErrorMessage';

type Props = {
  t: TranslateFunction;
  id: string;
  companyId: string;
  fixedPriceManager: boolean;
  paidFeatures: PaidFeatures[];
  refetchUsers?: () => void;
};

export default function DailyworkTypeMode({
  t,
  id,
  companyId,
  fixedPriceManager,
  paidFeatures,
  refetchUsers,
}: Props) {
  const { mutate: activate, isLoading: activateIsLoading } = useMutation({
    mutationFn: (variables: { companyId: string; userIds: string[] }) =>
      DailyWorkService.activateUsers(variables.companyId, {
        ids: variables.userIds,
      }),
    onSuccess: () => {
      refetchUsers && refetchUsers();
      showNotification({
        id: `activate-dailywork-user-successful`,
        title: t('w.success'),
        message: t('success.moduleDailywork.activate'),
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: (error) =>
      showNotification({
        id: 'activate-dailywork-user-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const { mutate: deactivate, isLoading: deactivateIsLoading } = useMutation({
    mutationFn: (variables: { companyId: string; userIds: string[] }) =>
      DailyWorkService.deactivateUsers(variables.companyId, {
        ids: variables.userIds,
      }),
    onSuccess: () => {
      refetchUsers && refetchUsers();
      showNotification({
        id: `deactivate-dailywork-user-successful`,
        title: t('w.success'),
        message: t('success.moduleDailywork.deactivate'),
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: (error) =>
      showNotification({
        id: 'deactivate-dailywork-user-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const {
    mutate: updateUserFixedPriceManager,
    isLoading: updateUserFixedPriceManagerIsLoading,
  } = useMutation({
    mutationFn: (variables: { userId: string; fixedPriceManager: boolean }) =>
      ProfileService.updateUserProfile(variables.userId, {
        fixedPriceManager: variables.fixedPriceManager,
      }),
    onSuccess: (data) => {
      if (data) {
        const { firstname, id } = data;
        refetchUsers && refetchUsers();
        showNotification({
          id: `update-user-${id}-fixedPricedManager-successful`,
          title: t('w.success'),
          message: t('success.updateUserProfile', firstname as string),
          color: 'green',
          icon: <IconCheck />,
        });
      }
    },
    onError: (error) =>
      showNotification({
        id: 'update-user-fixedPricedManager-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function getModeValue(
    fixedPriceManager: boolean,
    paidFeatures: PaidFeatures[]
  ) {
    if (
      paidFeatures?.find(
        (item: PaidFeatures) =>
          item.name === ModuleName.DailyWork && item.active
      )
    ) {
      if (fixedPriceManager) {
        return 'FIXEDPRICEMANAGER';
      }

      return 'TIMEENTRY';
    }

    return 'INACTIVE';
  }

  function onModeChange({
    userId,
    companyId,
    fixedPriceManager,
    paidFeatures,
    newMode,
  }: {
    userId: string;
    companyId: string;
    fixedPriceManager: boolean;
    paidFeatures: PaidFeatures[];
    newMode: string;
  }) {
    switch (newMode) {
      case 'INACTIVE':
        deactivate({ companyId, userIds: [userId] });
        break;
      case 'TIMEENTRY':
        if (
          !paidFeatures?.find(
            (item: PaidFeatures) =>
              item.name === ModuleName.DailyWork && item.active
          )
        ) {
          activate({ companyId, userIds: [userId] });
        }
        if (fixedPriceManager) {
          updateUserFixedPriceManager({ userId, fixedPriceManager: false });
        }
        break;
      case 'FIXEDPRICEMANAGER':
        if (
          !paidFeatures?.find(
            (item: PaidFeatures) =>
              item.name === ModuleName.DailyWork && item.active
          )
        ) {
          activate({ companyId, userIds: [userId] });
        }
        if (!fixedPriceManager) {
          updateUserFixedPriceManager({ userId, fixedPriceManager: true });
        }
        break;
    }
  }

  return (
    <SegmentedControl
      disabled={
        activateIsLoading ||
        deactivateIsLoading ||
        updateUserFixedPriceManagerIsLoading
      }
      className={s.segmentedControl}
      color="hifivework.5"
      size="xs"
      radius="xl"
      value={getModeValue(fixedPriceManager, paidFeatures)}
      onChange={(value: string) =>
        onModeChange({
          userId: id,
          companyId,
          fixedPriceManager,
          paidFeatures,
          newMode: value,
        })
      }
      data={[
        { label: t('w.inactive'), value: 'INACTIVE' },
        { label: t('w.timeEntry'), value: 'TIMEENTRY' },
        { label: t('w.fixedPriceManager'), value: 'FIXEDPRICEMANAGER' },
      ]}
    />
  );
}
