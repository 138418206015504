import { ContractType } from '../types/api/response/user';
import type { TranslateFunction } from '../types/front/common';

export function typesContract(t: TranslateFunction) {
  return [
    { value: '', label: t('w.noValue') },
    { value: ContractType.CA, label: t('type.contract.apprentice') },
    { value: ContractType.CDD, label: t('type.contract.CDD') },
    { value: ContractType.CDI, label: t('type.contract.CDI') },
    {
      value: ContractType.CP,
      label: t('type.contract.professionalizationContract'),
    },
    { value: ContractType.CTT, label: t('type.contract.temporary') },
    { value: ContractType.OTHER, label: t('w.other') },
  ];
}
