import { UrssafModule } from './UrssafModule';
import useScript from './useScript';

type Props = {
  dataModule: keyof typeof UrssafModule;
};

export default function UrssafSimulator({ dataModule }: Props) {
  useScript(
    'urssaf-simulator-script-content-id',
    `https://mon-entreprise.urssaf.fr/simulateur-iframe-integration.js`,
    dataModule,
    {
      removeOnUnmount: true,
    }
  );

  return null;
}
