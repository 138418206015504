import { useMutation, useQuery } from '@tanstack/react-query';
import { useCallback, useState } from 'react';

import type {
  LoginError,
  LoginResponse,
} from '../../../../../hooks/useHfwLogin';
import OauthServiceMS from '../../../../../services/OauthServiceMS';
import ProfileService from '../../../../../services/ProfileService';

export default function useGoogleLogin(googleAccessToken?: string | undefined) {
  const initialError: LoginError = {
    isError: false,
    details: undefined,
  };
  const [loginResponse, setLoginResponse] = useState<LoginResponse | undefined>(
    undefined
  );
  const [error, setError] = useState<LoginError>(initialError);
  const [accessToken, setAccessToken] = useState<string>('');
  const [refreshToken, setRefreshToken] = useState<string>('');

  useQuery({
    enabled: !!accessToken,
    queryKey: ['OauthServiceMS.getProfileToOauthUser', accessToken],
    queryFn: () => OauthServiceMS.getProfileToOauthUser(accessToken),
    onSuccess: (data) => {
      const profileIds = data.profileIds;
      if (profileIds.length === 1) {
        getCurrentUserProfile();
      } else if (profileIds.length > 1) {
        getUserHfwProfiles();
      }
    },
    onError: (error) => {
      setError({ isError: true, details: error });
    },
  });

  const { refetch: getUserHfwProfiles } = useQuery({
    enabled: false,
    queryKey: ['ProfileService.getUserHfwProfiles', accessToken],
    queryFn: () => ProfileService.getUserHfwProfiles(accessToken),
    onSuccess: (data) => {
      setError(initialError);
      setLoginResponse({
        access_token: accessToken,
        refresh_token: refreshToken,
        users: data,
      });
    },
    onError: (error) => {
      setError({ isError: true, details: error });
    },
  });

  const { refetch: getCurrentUserProfile } = useQuery({
    enabled: false,
    queryKey: ['ProfileService.getCurrentUserProfile', accessToken],
    queryFn: () => ProfileService.getCurrentUserProfile(accessToken),
    onSuccess: (data) => {
      setError(initialError);
      setLoginResponse({
        access_token: accessToken,
        refresh_token: refreshToken,
        users: [data],
      });
    },
    onError: (error) => {
      setError({ isError: true, details: error });
    },
  });

  const { mutate: mutateLogin } = useMutation({
    mutationFn: () =>
      OauthServiceMS.login(
        'google',
        undefined,
        undefined,
        undefined,
        googleAccessToken
      ),
    onSuccess: (data) => {
      setAccessToken(data.access_token);
      setRefreshToken(data.refresh_token);
    },
    onError: (error) => {
      setError({ isError: true, details: error });
    },
  });

  const login = useCallback(() => {
    if (googleAccessToken) {
      if (loginResponse) {
        setLoginResponse(undefined);
      }
      mutateLogin();
    }
  }, [googleAccessToken]);

  return { login, error, loginResponse };
}
