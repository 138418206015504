import { Button, Stack, Text } from '@mantine/core';
import { IconExternalLink } from '@tabler/icons-react';
import React from 'react';

import { ModuleToEditComponentProps } from '../../../components/ModuleToEditModal';

export default function Yoomonkeez({ module }: ModuleToEditComponentProps) {
  return (
    <Stack gap={'lg'} h={160}>
      <Text c={'dimmed'} fz={'sm'}>
        Suivez un parcours "spécial dirigeants de TPE/PME" de 4 jours sur l'une
        des 4 thématiques. Découvrez les opportunités numériques pour booster
        votre entreprise.
      </Text>
      <Text c={'dimmed'} fz={'sm'}>
        Programme 100% financé par l'état à l'initiative de France Num avec BPI,
        conçu par les CCI et Yoomonkeez.
      </Text>
      <Button
        leftSection={<IconExternalLink />}
        component="a"
        target="_blank"
        href={module.description?.url}
        variant={'subtle'}
      >
        Cliquez ici pour vous inscrire
      </Button>
    </Stack>
  );
}
