import { Button, Center, Flex, LoadingOverlay, Stack } from '@mantine/core';
import { MonthPicker } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { useI18n } from '../../../contexts/I18nProvider';
import PlanningService from '../../../services/PlanningService';
import handleErrorMessage from '../../../utils/handleErrorMessage';
import saveDownloadedfile from '../../../utils/saveDownloadedFile';

type Props = {
  selectedDivisions: string[];
  closeModal: () => void;
  companyId: string;
};
export default function PlanningExportExcelMonthPicker({
  selectedDivisions,
  closeModal,
  companyId,
}: Props) {
  const { t } = useI18n();
  const form = useForm({
    initialValues: {
      date: dayjs().toDate(),
    },
  });

  const {
    mutate: exportExcelPlanningByDivision,
    isLoading: isExportExcelPlanningByDivisionLoading,
  } = useMutation({
    mutationFn: () =>
      PlanningService.getExportPlanningFromStartAndEndDateByDivision(
        companyId,
        dayjs(form.values.date).endOf('month').valueOf(),
        dayjs(form.values.date).startOf('month').add(2, 'hours').valueOf(),
        selectedDivisions
      ),
    onSuccess: (response) => {
      closeModal();
      saveDownloadedfile(response.data, response.headers);
      showNotification({
        id: 'export-excel-planning-successful',
        title: t('w.success'),
        message: 'Planning exporté en excel',
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: (error) =>
      showNotification({
        id: 'export-excel-planning-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  return (
    <form>
      <LoadingOverlay visible={isExportExcelPlanningByDivisionLoading} />
      <Stack>
        <Center>
          <MonthPicker {...form.getInputProps('date')} />
        </Center>
        <Flex justify={'flex-end'}>
          <Button onClick={() => exportExcelPlanningByDivision()}>
            {t('w.validate')}
          </Button>
        </Flex>
      </Stack>
    </form>
  );
}
