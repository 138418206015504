import { NotificationCard } from '../../../components/NotificationCard/NotificationCard';
import ScreenWrapper from '../../../core/layouts/components/ScreenWrapper';

export default function AccountantConfigurations() {
  return (
    <ScreenWrapper title={'Configurations'}>
      <NotificationCard type={'accountant'} />
    </ScreenWrapper>
  );
}
