import { Fieldset, Group, Text } from '@mantine/core';
import React from 'react';

import s from '../MyActivities.module.sass';

type Props = {
  title: string;
  value: string | number;
};

export default function DashboardItem({ title, value }: Props) {
  return (
    <Fieldset
      legend={
        <Text fz={'xs'} fw={'bold'} c={'dimmed'}>
          {title}
        </Text>
      }
      className={s.dashboardRoot}
    >
      <Group wrap="nowrap">
        <Text fz="xl" fw={600} c="hifivework.3">
          {value}
        </Text>
      </Group>
    </Fieldset>
  );
}
