import { Flex, Group, Text } from '@mantine/core';

import CustomUserAvatar from '../../../../components/CustomAvatar/CustomUserAvatar';
import { User } from './Notifications';
import s from './Notifications.module.sass';

type Props = {
  users: User[];
  onClick: (index: number) => void;
};

export default function UserProfilesNotifications({ users, onClick }: Props) {
  return (
    <Group>
      {users?.map((user, index) => (
        <Group
          key={user.id}
          gap={'xs'}
          className={s.overviewWrapper}
          styles={{
            root: {
              backgroundColor: user.selected
                ? 'var(--mantine-color-hifivework-0)'
                : 'var(--mantine-color-body)',
              cursor: 'pointer',
              border: `1px solid ${
                user.selected
                  ? 'var(--mantine-color-hifivework-2)'
                  : 'var(--mantine-primary-color-light)'
              }`,
            },
          }}
          onClick={() => onClick(index)}
        >
          <CustomUserAvatar userId={user.id} />
          <Flex direction={'column'}>
            <Text fz={'sm'} fw={'bold'}>
              {user.fullname}
            </Text>
            <Text fz={'xs'} fw={'bold'} c={'dimmed'}>
              {user.companyName}
            </Text>
          </Flex>
        </Group>
      ))}
    </Group>
  );
}
