import { SimpleGrid, Stack } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useAuth } from '../../../../../contexts/AuthProvider';
import { useI18n } from '../../../../../contexts/I18nProvider';
import PayslipService from '../../../../../services/PayslipService';
import type { PayslipMonthResponse } from '../../../../../types/types';
import { handleOpenPayslipPdfSourceFile } from '../../../../../utils/attachmentFile';
import ImportPayslipsTitle from '../ImportPayslipsTitle';
import PayslipsSourceFileIcon from './PayslipsSourceFileIcon';

type Props = {
  payslipsMonthInfo: PayslipMonthResponse | undefined;
};

export default function PayslipsSourceFiles({ payslipsMonthInfo }: Props) {
  const { t } = useI18n();
  const { access_token } = useAuth();
  const { data: payslipsSourceFilesData } = useQuery({
    queryKey: [
      'PayslipService.getListPayslipSourceFile',
      payslipsMonthInfo?.companyId,
      payslipsMonthInfo?.id,
    ],
    queryFn: () =>
      PayslipService.getListPayslipSourceFile(
        payslipsMonthInfo ? payslipsMonthInfo?.companyId : '',
        payslipsMonthInfo ? payslipsMonthInfo?.id : ''
      ),
  });

  const payslipsSourceFiles: PayslipMonthResponse | null = useMemo(() => {
    if (!payslipsSourceFilesData) {
      return null;
    }
    return payslipsSourceFilesData;
  }, [payslipsSourceFilesData]);

  return (
    <Stack gap={'xl'}>
      <ImportPayslipsTitle title={t('w.listSourceFile')} />
      {payslipsSourceFiles && (
        <SimpleGrid cols={{ base: 3, md: 4, lg: 6 }}>
          {payslipsSourceFiles.sourceKey.map((item, index) => (
            <PayslipsSourceFileIcon
              key={item.date}
              date={item.date}
              onClick={() =>
                handleOpenPayslipPdfSourceFile(
                  item,
                  index,
                  payslipsMonthInfo,
                  access_token,
                  t
                )
              }
            />
          ))}
        </SimpleGrid>
      )}
    </Stack>
  );
}
