import {
  Button,
  Center,
  Flex,
  Grid,
  Group,
  LoadingOverlay,
  NumberInput,
  Select,
  Space,
  Stack,
  Stepper,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { useMediaQuery, useViewportSize } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconAddressBook,
  IconCheck,
  IconCircleArrowLeft,
  IconCircleArrowRight,
  IconCopyCheck,
  IconDeviceFloppy,
  IconPhone,
  IconUser,
  IconUserPlus,
  IconUsers,
  IconX,
} from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import ReactFlagsSelect from 'react-flags-select';

import EditEmergencyContactForm from '../../../../../components/EditEmergencyContactForm';
import LabelText from '../../../../../components/LabelText/LabelText';
import PhoneField from '../../../../../components/PhoneField/PhoneField';
import { useAuth } from '../../../../../contexts/AuthProvider';
import { useI18n } from '../../../../../contexts/I18nProvider';
import ProfileService from '../../../../../services/ProfileService';
import type {
  FamilyState,
  Gender,
  UserEmergencyContactType,
  UserPersonalFamily,
  UserPersonalInfo,
  UserPersonalSensitive,
  UserResponse,
} from '../../../../../types/api/response/user';
import { familyState } from '../../../../../utils/familyState';
import { gender } from '../../../../../utils/gender';
import { MS_WHITE_LABEL_ID } from '../../../../../variables/GlobalVariables';
import EmergencyContactTable from '../../../../user-profile/components/EmergencyContactTable';
import s from './AddEmployeeInformationForm.module.sass';

type Props = {
  onClose: () => void;
  employee: UserResponse | null;
  active: number;
  setActive: (number: number) => void;
  refetch: () => void;
  setUserPersonalInfo: (data: UserPersonalInfo) => void;
  setUserFamilySituation: (data: UserPersonalFamily) => void;
  setUserEmergencyContact: (data: UserEmergencyContactType[]) => void;
  userPersonalInfo?: UserPersonalInfo | null;
  userFamilySituation?: UserPersonalFamily | null;
  userEmergencyContact?: UserEmergencyContactType[] | [];
  refetchUserEmergencyContact?: () => void;
  refetchUserPersonalInfo?: () => void;
  refetchUserFamilySituation?: () => void;
  validateEmployeeOnBoarding: (variables: {
    userId: string;
    employee: boolean;
    accountant: boolean;
  }) => void;
  finalValidateEmployeeOnBoarding: (variables: { userId: string }) => void;
  refetchOBInfos?: () => void;
  userSensitiveInfos?: UserPersonalSensitive | null;
};

interface FormValues {
  personalInfoId?: string | null;
  gender?: Gender | string;
  nationality: string;
  birthday?: Date | string | number | null | undefined;
  birthPlace?: string;
  birthDepartment?: string;
  phoneNumber?: string | undefined;
  personsToContact?:
    | {
        id?: string | null;
        lastname?: string;
        firstname?: string;
        comment?: string;
        email?: string;
        phone?: string | undefined;
        userId?: string;
      }[]
    | [];
  familySituationId?: string | null;
  familyState?: FamilyState | string;
  numberDependents?: number | string;
  numberDependentsChildren?: number | string;
  address: string | null;
  address1?: string | null;
  address2?: string | null;
  zipCode?: string | null;
  city?: string | null;
  birthCountry: string | null;
}

export default function AddEmployeeInformationForm({
  onClose,
  employee,
  active,
  setActive,
  refetch,
  setUserPersonalInfo,
  setUserFamilySituation,
  userPersonalInfo,
  userFamilySituation,
  userEmergencyContact,
  refetchUserEmergencyContact,
  setUserEmergencyContact,
  refetchUserPersonalInfo,
  refetchUserFamilySituation,
  validateEmployeeOnBoarding,
  refetchOBInfos,
  userSensitiveInfos,
  finalValidateEmployeeOnBoarding,
}: Props) {
  const { t, lang } = useI18n();
  const { user, access_token } = useAuth();
  const [activeStepper, setActiveStepper] = useState(0);
  const checkMediaQuery = useMediaQuery('(min-width: 1025px)');
  const { height } = useViewportSize();

  const form = useForm<FormValues>({
    initialValues: {
      personalInfoId:
        userPersonalInfo !== null
          ? userPersonalInfo?.id
            ? userPersonalInfo.id
            : null
          : null,
      gender:
        userPersonalInfo !== null
          ? userPersonalInfo?.gender
            ? userPersonalInfo.gender
            : 'MALE'
          : 'MALE',
      nationality:
        userPersonalInfo !== null
          ? userPersonalInfo?.nationality
            ? userPersonalInfo.nationality
            : user.company?.countryCode
            ? user.company.countryCode
            : 'FR'
          : user.company?.countryCode
          ? user.company.countryCode
          : 'FR',
      birthday:
        userPersonalInfo !== null
          ? userPersonalInfo?.birthday
            ? dayjs(userPersonalInfo.birthday).toDate()
            : null
          : null,
      birthPlace:
        userPersonalInfo !== null
          ? userPersonalInfo?.birthPlace
            ? userPersonalInfo.birthPlace
            : ''
          : '',
      birthDepartment:
        userPersonalInfo !== null
          ? userPersonalInfo?.birthDepartment
            ? userPersonalInfo.birthDepartment
            : ''
          : '',
      phoneNumber:
        userPersonalInfo !== null
          ? userPersonalInfo?.phoneNumber
            ? userPersonalInfo.phoneNumber
            : ''
          : '',
      personsToContact:
        userEmergencyContact !== null &&
        userEmergencyContact &&
        userEmergencyContact?.length > 0
          ? userEmergencyContact?.map((item) => ({
              id: item.id,
              userId: employee?.id,
              lastname: item.lastname,
              firstname: item.firstname,
              comment: item.comment,
              email: item.email,
              phone: item.phone,
            }))
          : [],
      familySituationId:
        userFamilySituation !== null
          ? userFamilySituation?.id
            ? userFamilySituation.id
            : null
          : null,
      familyState:
        userFamilySituation !== null
          ? userFamilySituation?.familyState
            ? userFamilySituation.familyState
            : ''
          : '',
      numberDependents:
        userFamilySituation !== null
          ? userFamilySituation?.numberDependents
            ? userFamilySituation.numberDependents
            : ''
          : '',
      numberDependentsChildren:
        userFamilySituation !== null
          ? userFamilySituation?.numberDependentsChildren
            ? userFamilySituation.numberDependentsChildren
            : ''
          : '',
      address: null,
      address1:
        userPersonalInfo !== null
          ? userPersonalInfo?.address
            ? userPersonalInfo.address.address1
            : ''
          : '',
      address2:
        userPersonalInfo !== null
          ? userPersonalInfo?.address
            ? userPersonalInfo.address.address2
            : ''
          : '',
      zipCode:
        userPersonalInfo !== null
          ? userPersonalInfo?.address
            ? userPersonalInfo.address.zipCode
            : ''
          : '',
      city:
        userPersonalInfo !== null
          ? userPersonalInfo?.address
            ? userPersonalInfo.address.city
            : ''
          : '',
      birthCountry:
        userPersonalInfo !== null
          ? userPersonalInfo?.birthCountry
            ? userPersonalInfo.birthCountry
            : user.company?.countryCode
            ? user.company.countryCode
            : 'FR'
          : user.company?.countryCode
          ? user.company.countryCode
          : 'FR',
    },
  });

  const {
    mutate: updateUserFamilySituation,
    isLoading: isLoadingUserFamilySituation,
  } = useMutation({
    mutationFn: (variables: any) =>
      ProfileService.createOrUpdateUserFamilySituation(
        employee?.id,
        variables,
        access_token
      ),
    onSuccess: (data) => {
      setUserFamilySituation(data);
      if (refetchUserFamilySituation) {
        refetchUserFamilySituation();
      }
      if (refetchOBInfos) {
        refetchOBInfos();
      }
      form.setFieldValue('familySituationId', data.id);
      showNotification({
        id: 'udpate-added-employee-family-situation-success',
        title: t('w.success'),
        message: t('success.familySituationUpdated'),
        color: 'green',
        icon: <IconCheck />,
      });
      refetch();
    },
    onError: () =>
      showNotification({
        id: 'update-employee-added-family-situation-error',
        title: t('w.error'),
        message: t('error.updateUserFamilySituation', employee?.firstname),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const {
    mutate: updateUserPersonalInfo,
    isLoading: isLoadingUserPersonalInfo,
  } = useMutation({
    mutationFn: (variables: any) =>
      ProfileService.createOrUpdateUserPersonalInfo(
        employee?.id,
        variables,
        access_token
      ),
    onSuccess: (data) => {
      setUserPersonalInfo(data);
      if (refetchUserPersonalInfo) {
        refetchUserPersonalInfo();
      }
      if (refetchOBInfos) {
        refetchOBInfos();
      }

      form.setFieldValue('personalInfoId', data.id);
      showNotification({
        id: 'udpate-added-employee-personal-info-success',
        title: t('w.success'),
        message: t('success.personalInfoUpdated'),
        color: 'green',
        icon: <IconCheck />,
      });
      refetch();
    },
    onError: () =>
      showNotification({
        id: 'update-employee-added-personal-info-error',
        title: t('w.error'),
        message: t('error.updateUserPersonalInfo', employee?.firstname),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function handleUpdateEmployeeAddedFormSubmit(values: FormValues) {
    if (values.personalInfoId !== null) {
      updateUserPersonalInfo({
        id: values.personalInfoId,
        userId: employee?.id,
        gender: values.gender === '' ? null : values.gender,
        birthday: values.birthday
          ? dayjs(values.birthday).format('YYYY-MM-DD')
          : null,
        nationality: values.nationality,
        phoneNumber: values.phoneNumber,
        birthPlace: values.birthPlace,
        birthDepartment: values.birthDepartment,
        address: {
          address1: values.address1,
          address2: values.address2,
          zipCode: values.zipCode,
          city: values.city,
        },
      });
    } else {
      updateUserPersonalInfo({
        userId: employee?.id,
        gender: values.gender === '' ? null : values.gender,
        birthday: values.birthday
          ? dayjs(values.birthday).format('YYYY-MM-DD')
          : null,
        nationality: values.nationality,
        phoneNumber: values.phoneNumber,
        birthPlace: values.birthPlace,
        birthDepartment: values.birthDepartment,
        address: {
          address1: values.address1,
          address2: values.address2,
          zipCode: values.zipCode,
          city: values.city,
        },
      });
    }
    if (
      values.familyState !== '' ||
      values.numberDependentsChildren !== '' ||
      values.numberDependents !== ''
    ) {
      if (values.familySituationId !== null) {
        updateUserFamilySituation({
          id: values.familySituationId,
          familyState: values.familyState === '' ? null : values.familyState,
          numberDependents:
            values.numberDependents === '' ? null : values.numberDependents,
          numberDependentsChildren:
            values.numberDependentsChildren === ''
              ? null
              : values.numberDependentsChildren,
          userId: employee?.id,
        });
      } else {
        updateUserFamilySituation({
          familyState: values.familyState === '' ? null : values.familyState,
          numberDependents:
            values.numberDependents === '' ? null : values.numberDependents,
          numberDependentsChildren:
            values.numberDependentsChildren === ''
              ? null
              : values.numberDependentsChildren,
          userId: employee?.id,
        });
      }
    }
  }

  function openConfirmOnBoarding(values: FormValues) {
    modals.openConfirmModal({
      modalId: 'confirm-valid-on-boarding',
      title:
        user.id === employee?.id ? (
          <Title size={'h3'} component="p">
            {t('w.confirmOnBoardingInfos')}
          </Title>
        ) : employee?.onBoarding?.onBoardingEmployee?.validationDate !== null &&
          employee?.onBoarding?.onBoardingCompany !== null &&
          employee?.onBoarding?.onBoardingCompany?.validationDate &&
          employee?.onBoarding?.onBoardingCompany?.validationDate
            ?.dateOfUpdate !== null ? (
          <Title size={'h3'} component="p">
            {'Vous allez finaliser la pré-embauche de votre collaborateur'}
          </Title>
        ) : (
          <Title size={'h3'} component="p">
            {t('w.confirmOnBoardingInfosCompany')}
          </Title>
        ),
      size: 'xl',
      labels: { cancel: t('w.cancel'), confirm: t('w.confirm') },
      onConfirm: () => validateOnBoarding(values),
    });
  }

  function validateOnBoarding(values: FormValues) {
    if (employee !== null) {
      if (user.id !== employee.id) {
        if (
          employee.onBoarding?.onBoardingEmployee?.validationDate !== null &&
          employee?.onBoarding?.onBoardingCompany !== null &&
          employee?.onBoarding?.onBoardingCompany?.validationDate &&
          employee?.onBoarding?.onBoardingCompany?.validationDate
            ?.dateOfUpdate !== null
        ) {
          finalValidateEmployeeOnBoarding({ userId: employee.id });
        } else {
          validateEmployeeOnBoarding({
            userId: employee.id,
            employee: false,
            accountant: false,
          });
        }
      } else {
        validateEmployeeOnBoarding({
          userId: employee.id,
          employee: true,
          accountant: false,
        });
      }
      handleUpdateEmployeeAddedFormSubmit(values);
    }
  }

  function renderComponent() {
    switch (activeStepper) {
      case 1:
        return (
          <>
            {!checkMediaQuery && (
              <Title mb={'lg'} order={3}>
                {t('w.employeeAddress')}
              </Title>
            )}
            <Grid grow gutter="xl">
              <Grid.Col span={{ base: 12, md: 6 }}>
                <TextInput
                  label={<LabelText text={t('w.postalAddress')} />}
                  placeholder={t('w.noValue')}
                  {...form.getInputProps('address1')}
                  required={Boolean(employee?.onBoardingId)}
                  disabled={
                    Boolean(employee?.onBoardingId) &&
                    user.id === employee?.id &&
                    employee?.onBoarding?.onBoardingEmployee?.validationDate !==
                      null
                  }
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <TextInput
                  label={
                    <LabelText
                      text={`${t('w.additionalAddress')} (${t(
                        'w.optional'
                      ).toLowerCase()})`}
                    />
                  }
                  placeholder={t('w.noValue')}
                  {...form.getInputProps('address2')}
                  disabled={
                    Boolean(employee?.onBoardingId) &&
                    user.id === employee?.id &&
                    employee?.onBoarding?.onBoardingEmployee?.validationDate !==
                      null
                  }
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <TextInput
                  required={Boolean(employee?.onBoardingId)}
                  label={<LabelText text={t('w.zipCode')} />}
                  placeholder={t('w.noValue')}
                  {...form.getInputProps('zipCode')}
                  disabled={
                    Boolean(employee?.onBoardingId) &&
                    user.id === employee?.id &&
                    employee?.onBoarding?.onBoardingEmployee?.validationDate !==
                      null
                  }
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <TextInput
                  required={Boolean(employee?.onBoardingId)}
                  label={<LabelText text={t('w.city')} />}
                  placeholder={t('w.noValue')}
                  {...form.getInputProps('city')}
                  disabled={
                    Boolean(employee?.onBoardingId) &&
                    user.id === employee?.id &&
                    employee?.onBoarding?.onBoardingEmployee?.validationDate !==
                      null
                  }
                />
              </Grid.Col>
            </Grid>
          </>
        );
      case 2:
        return (
          <>
            {!checkMediaQuery && (
              <Title mb={'lg'} order={3}>
                {t('w.familySituation')}
              </Title>
            )}
            <Grid grow gutter="xl">
              <Grid.Col span={{ base: 12, md: 6 }}>
                <Select
                  placeholder={t('w.noValue')}
                  data={familyState(t)}
                  label={<LabelText text={t('w.familySituation')} />}
                  {...form.getInputProps('familyState')}
                  disabled={
                    Boolean(employee?.onBoardingId) &&
                    user.id === employee?.id &&
                    employee?.onBoarding?.onBoardingEmployee?.validationDate !==
                      null
                  }
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <NumberInput
                  min={0}
                  label={<LabelText text={t('w.numberOfDependentChildren')} />}
                  {...form.getInputProps('numberDependentsChildren')}
                  disabled={
                    Boolean(employee?.onBoardingId) &&
                    user.id === employee?.id &&
                    employee?.onBoarding?.onBoardingEmployee?.validationDate !==
                      null
                  }
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <NumberInput
                  min={0}
                  description={
                    'Ni votre conjoint ni l’un de vos enfants à charge'
                  }
                  label={<LabelText text={t('w.numberOfDependants')} />}
                  {...form.getInputProps('numberDependents')}
                  disabled={
                    Boolean(employee?.onBoardingId) &&
                    user.id === employee?.id &&
                    employee?.onBoarding?.onBoardingEmployee?.validationDate !==
                      null
                  }
                />
              </Grid.Col>
            </Grid>
          </>
        );
      case 3:
        return (
          <Stack gap="lg">
            {!checkMediaQuery && (
              <Title mb={'lg'} order={3}>
                {t('w.personToContact')}
              </Title>
            )}
            <Flex justify={'flex-end'}>
              <Button
                variant={'outline'}
                leftSection={<IconUserPlus />}
                onClick={() =>
                  modals.open({
                    modalId: `add-new-emergency-contact-modal`,
                    title: (
                      <Title size={'h3'} component="p">
                        {t('w.emergencyContactAdding')}
                      </Title>
                    ),
                    size: '90%',
                    children: (
                      <EditEmergencyContactForm
                        employee={employee}
                        refetchUserEmergencyContact={
                          refetchUserEmergencyContact
                        }
                        onClose={() =>
                          modals.close('add-new-emergency-contact-modal')
                        }
                        parentForm={form}
                        setUserEmergencyContact={setUserEmergencyContact}
                      />
                    ),
                  })
                }
                disabled={
                  Boolean(employee?.onBoardingId) &&
                  user.id === employee?.id &&
                  employee?.onBoarding?.onBoardingEmployee?.validationDate !==
                    null
                }
              >
                {t('w.add')}
              </Button>
            </Flex>
            {form.values?.personsToContact?.length === 0 ? (
              <Center>
                <Text fw={'bold'} c={'dimmed'}>
                  {t('w.noOneToContact')}
                </Text>
              </Center>
            ) : (
              <EmergencyContactTable
                userEmergencyContact={userEmergencyContact}
                refetchUserEmergencyContact={refetchUserEmergencyContact}
                user={user}
                userProfile={employee}
              />
            )}
          </Stack>
        );
      default:
        return (
          <>
            {!checkMediaQuery && (
              <Title mb={'lg'} order={3}>
                {t('w.personalInformation')}
              </Title>
            )}
            <Grid gutter="xl">
              <Grid.Col span={{ base: 12, md: 6 }}>
                <PhoneField
                  value={form.values.phoneNumber}
                  onChange={(value) => form.setFieldValue('phoneNumber', value)}
                  disabled={
                    Boolean(employee?.onBoardingId) &&
                    user.id === employee?.id &&
                    employee?.onBoarding?.onBoardingEmployee?.validationDate !==
                      null
                  }
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <Select
                  label={<LabelText text={t('w.civilStatus')} />}
                  data={gender(t)}
                  {...form.getInputProps('gender')}
                  disabled={
                    Boolean(employee?.onBoardingId) &&
                    user.id === employee?.id &&
                    employee?.onBoarding?.onBoardingEmployee?.validationDate !==
                      null
                  }
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <DatePickerInput
                  label={<LabelText text={t('w.dateOfBirth')} />}
                  valueFormat={'D MMMM YYYY'}
                  locale={lang}
                  placeholder={t('w.noValue')}
                  clearable
                  {...form.getInputProps('birthday')}
                  required={Boolean(employee?.onBoardingId)}
                  disabled={
                    Boolean(employee?.onBoardingId) &&
                    user.id === employee?.id &&
                    employee?.onBoarding?.onBoardingEmployee?.validationDate !==
                      null
                  }
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <TextInput
                  label={<LabelText text={t('w.placeOfBirth')} />}
                  placeholder={t('w.noValue')}
                  {...form.getInputProps('birthPlace')}
                  required={Boolean(employee?.onBoardingId)}
                  disabled={
                    Boolean(employee?.onBoardingId) &&
                    user.id === employee?.id &&
                    employee?.onBoarding?.onBoardingEmployee?.validationDate !==
                      null
                  }
                />
              </Grid.Col>
              {MS_WHITE_LABEL_ID === 'HIFIVEWORK' && (
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <NumberInput
                    allowNegative={false}
                    label={<LabelText text={t('w.departmentOfBirth')} />}
                    placeholder={t('w.noValue')}
                    {...form.getInputProps('birthDepartment')}
                    required={Boolean(employee?.onBoardingId)}
                    disabled={
                      Boolean(employee?.onBoardingId) &&
                      user.id === employee?.id &&
                      employee?.onBoarding?.onBoardingEmployee
                        ?.validationDate !== null
                    }
                  />
                </Grid.Col>
              )}
              <Grid.Col span={{ base: 12, md: 6 }}>
                <LabelText text={t('w.countryOfBirth')} mb="xs" />
                <ReactFlagsSelect
                  selected={form.values.birthCountry!}
                  onSelect={(code) => form.setFieldValue('birthCountry', code)}
                  searchable
                  searchPlaceholder={t('w.search')}
                  disabled={
                    Boolean(employee?.onBoardingId) &&
                    user.id === employee?.id &&
                    employee?.onBoarding?.onBoardingEmployee?.validationDate !==
                      null
                  }
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <LabelText text={t('w.nationality')} mb="xs" />
                <ReactFlagsSelect
                  selected={form.values.nationality!}
                  onSelect={(code) => form.setFieldValue('nationality', code)}
                  searchable
                  searchPlaceholder={t('w.search')}
                  disabled={
                    Boolean(employee?.onBoardingId) &&
                    user.id === employee?.id &&
                    employee?.onBoarding?.onBoardingEmployee?.validationDate !==
                      null
                  }
                />
              </Grid.Col>
            </Grid>
          </>
        );
    }
  }

  function isDisabledConfirmOnBoardingButton() {
    const isRequiredInfoMissing =
      userSensitiveInfos?.identityCardNumber === null ||
      userSensitiveInfos?.ssNumber === null ||
      form.values.address1 === null ||
      form.values.address1 === '' ||
      form.values.zipCode === '' ||
      form.values.zipCode === null ||
      form.values.city === '' ||
      form.values.city === null ||
      form.values.nationality === null ||
      form.values.nationality === '' ||
      form.values.birthday === null ||
      form.values.birthday === '' ||
      form.values.birthPlace === '' ||
      form.values.birthDepartment === '';

    const isMyProfileAndAlreadyValidated =
      employee?.id === user.id &&
      employee?.onBoarding?.onBoardingEmployee?.validationDate !== null;

    const isNotMyProfileAndNotValidatedYet =
      employee?.id !== user.id &&
      employee?.onBoarding?.onBoardingEmployee?.validationDate === null;

    return (
      isRequiredInfoMissing ||
      isMyProfileAndAlreadyValidated ||
      isNotMyProfileAndNotValidatedYet
    );
  }

  return (
    <form
      onSubmit={form.onSubmit((values) =>
        handleUpdateEmployeeAddedFormSubmit(values)
      )}
    >
      <LoadingOverlay
        visible={isLoadingUserFamilySituation && isLoadingUserPersonalInfo}
      />
      <Title order={3}>{t('w.fillInTheInformation')}</Title>
      <Space h={'xl'} />
      <Grid
        gutter={'xl'}
        styles={{
          root: {
            position: 'sticky',
            top: 120,
          },
        }}
      >
        <Grid.Col span={2}>
          <Stepper
            className={checkMediaQuery ? s.stepper : undefined}
            active={activeStepper}
            orientation={'vertical'}
            onStepClick={setActiveStepper}
          >
            <Stepper.Step
              icon={<IconUser size="1.1rem" />}
              label={checkMediaQuery ? 'Informations' : ''}
              description={checkMediaQuery ? 'Personnelles' : ''}
            />
            <Stepper.Step
              icon={<IconAddressBook size="1.1rem" />}
              label={checkMediaQuery ? 'Adresse' : ''}
              description={checkMediaQuery ? 'Employé(e)' : ''}
            />
            <Stepper.Step
              icon={<IconUsers size="1.1rem" />}
              label={checkMediaQuery ? 'Situation' : ''}
              description={checkMediaQuery ? 'Familiale' : ''}
            />
            <Stepper.Step
              icon={<IconPhone size="1.1rem" />}
              label={
                checkMediaQuery
                  ? `${t('w.personToContact').split(' ')[0]} ${
                      t('w.personToContact').split(' ')[1]
                    } ${t('w.personToContact').split(' ')[2]}`
                  : ''
              }
              description={
                checkMediaQuery
                  ? `${t('w.personToContact').split(' ')[3]} ${
                      t('w.personToContact').split(' ')[4]
                    } ${t('w.personToContact').split(' ')[5]}`
                  : ''
              }
            />
          </Stepper>
        </Grid.Col>
        <Grid.Col
          styles={{
            col: {
              overflowY: 'scroll',
              height: (height * 3) / 5,
            },
          }}
          span={'auto'}
          pl={checkMediaQuery ? 'xl' : undefined}
        >
          {renderComponent()}
        </Grid.Col>
      </Grid>
      <Group justify={'flex-end'} mt={'lg'} gap={'30px'}>
        {employee && Boolean(employee?.onBoardingId) && (
          <Button
            onClick={() => openConfirmOnBoarding(form.values)}
            color={'#0A9182'}
            leftSection={<IconCopyCheck />}
            disabled={isDisabledConfirmOnBoardingButton()}
          >
            {employee.onBoarding?.onBoardingEmployee?.validationDate !== null &&
            employee?.onBoarding?.onBoardingCompany !== null &&
            employee?.onBoarding?.onBoardingCompany?.validationDate &&
            employee?.onBoarding?.onBoardingCompany?.validationDate
              ?.dateOfUpdate !== null
              ? t('w.finalizeOnboarding')
              : t('w.validateInfos')}
          </Button>
        )}
        <Button
          disabled={
            !Boolean(employee) ||
            (Boolean(employee?.onBoardingId) &&
              employee?.id === user.id &&
              employee?.onBoarding?.onBoardingEmployee?.validationDate !== null)
          }
          leftSection={<IconDeviceFloppy />}
          type={'submit'}
        >
          {t('w.save')}
        </Button>
        <Button
          onClick={() => setActive(active - 1)}
          leftSection={<IconCircleArrowLeft />}
          variant={'outline'}
        >
          {t('w.back')}
        </Button>
        <Button
          onClick={() => setActive(active + 1)}
          leftSection={<IconCircleArrowRight />}
          variant={'outline'}
          type={'submit'}
        >
          {t('w.next')}
        </Button>
        <Button
          onClick={onClose}
          variant={'outline'}
          disabled={Boolean(employee?.onBoardingId) && employee?.id === user.id}
        >
          {t('w.close')}
        </Button>
      </Group>
    </form>
  );
}
