import { Text } from '@mantine/core';

import classes from './WorkUnitsInfos.module.sass';

type Props = {
  text: string;
};

export default function WorkUnitsTextInfos({ text }: Props) {
  return (
    <div>
      <Text fz="sm" fw={600} className={classes.name}>
        {text}
      </Text>
    </div>
  );
}
