import { Group, LoadingOverlay, TextInput } from '@mantine/core';
import { isEmail, useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import React from 'react';

import ButtonSendInvitation from '../../../../components/ButtonSendInvitation';
import LabelText from '../../../../components/LabelText/LabelText';
import { useI18n } from '../../../../contexts/I18nProvider';
import ProfileService from '../../../../services/ProfileService';
import handleErrorMessage from '../../../../utils/handleErrorMessage';

type Props = {
  onClose: () => void;
};

interface FormValues {
  mail: string;
}

export default function InviteProfileForm({ onClose }: Props) {
  const { t } = useI18n();
  const form = useForm<FormValues>({
    initialValues: {
      mail: '',
    },
    validate: {
      mail: isEmail(t('invalid.email')),
    },
  });

  const { mutate: sendMailToLinkCurrentUserToOauthUser, isLoading } =
    useMutation({
      mutationFn: (variables: FormValues) =>
        ProfileService.sendMailToLinkCurrentUserToOauthUser(variables.mail),
      onSuccess: () => {
        onClose();
        showNotification({
          id: 'send-mail-to-link-current-user-to-oauth-user-successful',
          title: t('w.success'),
          message: t('w.mailSent', form.values.mail),
          color: 'green',
          icon: <IconCheck />,
        });
      },
      onError: (error) =>
        showNotification({
          id: 'send-mail-to-link-current-user-to-oauth-user-error',
          title: t('w.error'),
          message: handleErrorMessage(error, t),
          color: 'red',
          icon: <IconX />,
        }),
    });

  function handleSendMailToLinkCurrentUserToOauthUser(values: FormValues) {
    sendMailToLinkCurrentUserToOauthUser({ mail: values.mail });
  }

  return (
    <form
      onSubmit={form.onSubmit((values) =>
        handleSendMailToLinkCurrentUserToOauthUser(values)
      )}
    >
      <LoadingOverlay visible={isLoading} />
      <TextInput
        label={<LabelText text={t('w.emailAddress')} />}
        {...form.getInputProps('mail')}
        data-autofocus
        required
      />
      <Group justify={'flex-end'} mt={'xl'}>
        <ButtonSendInvitation />
      </Group>
    </form>
  );
}
