import { Group, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React from 'react';

import CustomCompanyAvatar from '../../../components/CustomAvatar/CustomCompanyAvatar';

type Props = {
  companyId: string;
  companyName: string | undefined;
};

export default function CompanyInfoWrapper({ companyId, companyName }: Props) {
  const matches = useMediaQuery('(min-width: 1441px)');

  return (
    <Group>
      {matches && <CustomCompanyAvatar companyId={companyId} size={'md'} />}
      <Text>{companyName}</Text>
    </Group>
  );
}
