import { Group, Text } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';

import CustomCompanyAvatar from '../../../components/CustomAvatar/CustomCompanyAvatar';
import CompanyService from '../../../services/CompanyService';

type Props = {
  companyId: string;
};

export default function ShuttleSheetCompanyWrapper({ companyId }: Props) {
  const { data: company } = useQuery({
    queryKey: ['CompanyService.getCompany', companyId],
    queryFn: () => CompanyService.getCompany(companyId),
  });

  return (
    <Group gap={'xs'}>
      <CustomCompanyAvatar companyId={companyId} size={'md'} />
      {company && <Text fz={'sm'}>{company.name}</Text>}
    </Group>
  );
}
