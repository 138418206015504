import {
  Avatar,
  Group,
  Text,
  Title,
  UnstyledButton,
  UnstyledButtonProps,
} from '@mantine/core';

import CompanyService from '../../../../services/CompanyService';
import s from './CompanyButton.module.sass';

interface UserButtonProps extends UnstyledButtonProps {
  user: any;
  onClick: () => void;
}

export function CompanyButton({ user, onClick, ...others }: UserButtonProps) {
  return (
    <UnstyledButton className={s.user} {...others} onClick={onClick}>
      <Group>
        <Avatar src={CompanyService.getLogoPath(user.company.id)} radius="xl" />
        <div style={{ flex: 1 }}>
          <Title order={4} c={'hifivework.3'}>
            {user.company?.name}
          </Title>
          <Text c="dimmed" fz="sm">
            {user.fullname}
          </Text>
        </div>
      </Group>
    </UnstyledButton>
  );
}
