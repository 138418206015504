import {
  Chip,
  createTheme,
  Loader,
  LoadingOverlay,
  MantineProvider,
} from '@mantine/core';
import React, { ReactNode } from 'react';

import { MS_WHITE_LABEL_ID } from '../variables/GlobalVariables';

type Props = {
  children: ReactNode;
};

export default function ThemeProvider({ children }: Props) {
  const aprColors: string[] = [
    '#d7e4d9',
    '#b0cab5',
    '#89b092',
    '#639670',
    '#639670',
    '#3b7d4f',
    '#006430',
    '#0a5329',
    '#0f4322',
    '#10331c',
  ];

  const hfwColors: string[] = [
    '#ebf2ff',
    '#94b5e8',
    '#647fcc',
    '#4055a6',
    '#1c2a8b',
    '#171e96',
    '#10069f',
    '#100691',
    '#0e057a',
    '#0b055e',
  ];

  const theme = createTheme({
    cursorType: 'pointer',
    fontFamily:
      'Manrope, apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif',
    headings: {
      fontFamily:
        'Manrope, apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif',
    },
    colors: {
      // @ts-ignore
      hifivework: MS_WHITE_LABEL_ID === 'AFRICAPAIERH' ? aprColors : hfwColors,
    },
    primaryColor: 'hifivework',
    components: {
      Button: {
        defaultProps: {
          radius: 'md',
        },
      },
      Chip: Chip.extend({
        defaultProps: {
          variant: 'filled',
        },
      }),
      ColorInput: {
        styles: () => ({
          label: {
            display: 'flex',
            gap: '4px',
          },
        }),
      },
      Dropzone: {
        styles: () => ({
          root: {
            borderRadius: 10,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        }),
      },
      Fieldset: {
        styles: () => ({
          legend: {
            fontWeight: 'bold',
          },
        }),
      },
      Loader: Loader.extend({
        defaultProps: {
          type: 'bars',
        },
      }),
      LoadingOverlay: LoadingOverlay.extend({
        defaultProps: {
          overlayProps: {
            blur: 2,
          },
          loaderProps: {
            type: 'bars',
          },
        },
      }),
      Modal: {
        styles: () => ({
          close: {
            color: '#707070',
          },
        }),
      },
      Badge: {
        styles: () => ({
          inner: {
            textTransform: 'none',
            fontWeight: 'normal',
          },
        }),
      },
      RadioGroup: {
        styles: () => ({
          label: {
            marginBottom: 10,
          },
          description: {
            color: 'black',
            marginBottom: 10,
          },
        }),
      },
      Tabs: {
        styles: () => ({
          tab: {
            fontWeight: 'bold',
          },
        }),
        defaultProps: {
          variant: 'outline',
          radius: 'md',
        },
      },
      TextInput: {
        styles: () => ({
          label: {
            display: 'flex',
            gap: '4px',
          },
        }),
      },
      FileInput: {
        styles: () => ({
          label: {
            display: 'flex',
            gap: '4px',
          },
        }),
      },
      DatePickerInput: {
        styles: () => ({
          label: {
            display: 'flex',
            gap: '4px',
          },
        }),
      },
      DateTimePicker: {
        styles: () => ({
          label: {
            display: 'flex',
            gap: '4px',
          },
        }),
      },
      NumberInput: {
        styles: () => ({
          label: {
            display: 'flex',
            gap: '4px',
          },
        }),
      },
      PasswordInput: {
        styles: () => ({
          label: {
            display: 'flex',
            gap: '4px',
          },
        }),
      },
      Select: {
        styles: () => ({
          label: {
            display: 'flex',
            gap: '4px',
          },
        }),
      },
      MultiSelect: {
        styles: () => ({
          label: {
            display: 'flex',
            gap: '4px',
          },
        }),
      },
    },
  });

  return (
    <MantineProvider defaultColorScheme="light" theme={theme}>
      {children}
    </MantineProvider>
  );
}
