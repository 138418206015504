import {
  Center,
  Grid,
  Stepper,
  Switch,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useMediaQuery, useViewportSize } from '@mantine/hooks';
import {
  IconCalendarTime,
  IconCheck,
  IconFile,
  IconUser,
} from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';

import { useAuth } from '../contexts/AuthProvider';
import { useI18n } from '../contexts/I18nProvider';
import s from '../features/company/employees/components/AddEmployeeInformationForm/AddEmployeeInformationForm.module.sass';
import ProfileService from '../services/ProfileService';
import { BuiltInPermissions, ScopeEnum } from '../types/api/response/role';
import type {
  UserPersonalInfo,
  UserResponse,
} from '../types/api/response/user';
import { hasPermission } from '../utils/authorization';
import { MS_WHITE_LABEL_ID } from '../variables/GlobalVariables';
import BankForm from './BankForm';
import CustomDropzone from './CustomDropzone/CustomDropzone';
import FileViewer from './FileViewer/FileViewer';
import LabelText from './LabelText/LabelText';

type Props = {
  form: any;
  request: boolean;
  edit: boolean;
  userPersonalInfo?: UserPersonalInfo | null;
  employee: UserResponse | null;
};

export default function SensitiveInfoStepper({
  form,
  request,
  edit,
  userPersonalInfo,
  employee,
}: Props) {
  const { t } = useI18n();
  const checkMediaQuery = useMediaQuery('(min-width: 1025px)');
  const [activeStepper, setActiveStepper] = useState(0);
  const { height } = useViewportSize();
  const { access_token, user } = useAuth();
  const [file, setFile] = useState({
    opened: false,
    path: '',
    type: '',
    loading: false,
    blob: new Blob([JSON.stringify(true)]),
  });
  const [filePath, setFilePath] = useState<string | null>(null);

  useEffect(() => {
    if (activeStepper === 0 || activeStepper === 1 || activeStepper === 3) {
      openImage();
    }
  }, [activeStepper]);

  useEffect(() => {
    if (form.values.identityCardFiles.length > 0 && activeStepper === 0) {
      setFilePath(URL.createObjectURL(form.values.identityCardFiles[0]));
    } else {
      setFilePath(null);
    }
  }, [form.values.identityCardFiles, activeStepper]);

  useEffect(() => {
    if (form.values.ssFiles.length > 0 && activeStepper === 1) {
      setFilePath(URL.createObjectURL(form.values.ssFiles[0]));
    } else {
      setFilePath(null);
    }
  }, [form.values.ssFiles, activeStepper]);

  useEffect(() => {
    if (form.values.residencePermitFiles.length > 0 && activeStepper === 3) {
      setFilePath(URL.createObjectURL(form.values.residencePermitFiles[0]));
    } else {
      setFilePath(null);
    }
  }, [form.values.residencePermitFiles, activeStepper]);

  function openImage() {
    setFile({ ...file, loading: true });
    let xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';

    xhr.onload = function () {
      if (xhr.status === 200) {
        const newBlob = new Blob([this.response], {
          type: 'application/pdf',
        });
        const filePath = window.URL.createObjectURL(newBlob);

        let link = document.createElement('a');
        link.href = filePath;
        link.download = `exportpdf`;
        link.target = '_blank';
        setFile({
          path: filePath,
          type: newBlob.type,
          opened: true,
          loading: false,
          blob: newBlob,
        });
        setFilePath(filePath);
      }
    };
    if (activeStepper === 1) {
      xhr.open('GET', ProfileService.getSecuritySocial(employee?.id), true);
    } else if (activeStepper === 3) {
      xhr.open('GET', ProfileService.getResidencePermit(employee?.id), true);
    } else {
      xhr.open('GET', ProfileService.getIdentityCard(employee?.id), true);
    }

    xhr.setRequestHeader('Authorization', 'Bearer ' + access_token);
    xhr.send();
  }

  function renderComponentRequest() {
    switch (activeStepper) {
      case 1:
        return (
          <Grid gutter={'xl'}>
            <Grid.Col span={{ base: 12, md: 6 }}>
              <TextInput
                label={<LabelText text={t('w.socialSecurityNumber')} />}
                placeholder={t('w.noValue')}
                {...form.getInputProps('ssNumber')}
                disabled={
                  Boolean(employee?.onBoardingId) &&
                  user.id === employee?.id &&
                  employee?.onBoarding?.onBoardingEmployee?.validationDate !==
                    null
                }
                required={Boolean(employee?.onBoardingId)}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6 }}>
              <Title order={3}>{t('w.vitaleCard')}</Title>
              <CustomDropzone
                onDrop={(files) => form.setFieldValue('ssFiles', files)}
                files={form.values.ssFiles}
                mimeType={'pdf'}
                resetFiles={(index) => form.removeListItem('ssFiles', index)}
                disabled={
                  Boolean(employee?.onBoardingId) &&
                  user.id === employee?.id &&
                  employee?.onBoarding?.onBoardingEmployee?.validationDate !==
                    null
                }
              />
              {filePath ? (
                <Center>
                  <FileViewer
                    id={`document-${form.values.id}`}
                    srcUrl={filePath}
                    title={'contract.pdf'}
                  />
                </Center>
              ) : (
                <Text fz={'sm'} fw={'600'} c={undefined}>
                  {t('w.noFile')}
                </Text>
              )}
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6 }}>
              <Switch
                label={<LabelText text={t('w.handicappedWorker')} />}
                labelPosition={'left'}
                checked={form.values.handicappedWorker}
                {...form.getInputProps('handicappedWorker')}
                disabled={
                  Boolean(employee?.onBoardingId) &&
                  user.id === employee?.id &&
                  employee?.onBoarding?.onBoardingEmployee?.validationDate !==
                    null
                }
              />
            </Grid.Col>
          </Grid>
        );
      case 2:
        return !edit ? (
          <BankForm
            form={form}
            disabled={
              Boolean(employee?.onBoardingId) &&
              user.id === employee?.id &&
              employee?.onBoarding?.onBoardingEmployee?.validationDate !== null
            }
          />
        ) : null;
      case 3:
        return (
          <Grid gutter={'xl'}>
            <Grid.Col span={{ base: 12, md: 6 }}>
              <TextInput
                label={<LabelText text={t('w.residenceNumber')} />}
                placeholder={t('w.noValue')}
                {...form.getInputProps('residencePermitNumber')}
                disabled={
                  Boolean(employee?.onBoardingId) &&
                  user.id === employee?.id &&
                  employee?.onBoarding?.onBoardingEmployee?.validationDate !==
                    null
                }
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6 }}>
              <Title order={3}>{t('w.residencePermitFile')}</Title>
              <CustomDropzone
                onDrop={(files) =>
                  form.setFieldValue('residencePermitFiles', files)
                }
                files={form.values.residencePermitFiles}
                mimeType={'pdf'}
                resetFiles={(index) =>
                  form.removeListItem('residencePermitFiles', index)
                }
                disabled={
                  Boolean(employee?.onBoardingId) &&
                  user.id === employee?.id &&
                  employee?.onBoarding?.onBoardingEmployee?.validationDate !==
                    null
                }
              />
              {filePath ? (
                <Center>
                  <FileViewer
                    id={`document-${form.values.id}`}
                    srcUrl={filePath}
                    title={'residence_permit.pdf'}
                  />
                </Center>
              ) : (
                <Text fz={'sm'} fw={'600'} c={undefined}>
                  {t('w.noFile')}
                </Text>
              )}
            </Grid.Col>
          </Grid>
        );

      default:
        return (
          <>
            <Grid gutter="xl">
              <Grid.Col span={{ base: 12, md: 6 }}>
                <TextInput
                  label={<LabelText text={t('w.nationalIdentityCardNumber')} />}
                  placeholder={t('w.noValue')}
                  {...form.getInputProps('identityCardNumber')}
                  disabled={
                    Boolean(employee?.onBoardingId) &&
                    user.id === employee?.id &&
                    employee?.onBoarding?.onBoardingEmployee?.validationDate !==
                      null
                  }
                  required={Boolean(employee?.onBoardingId)}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <Title order={3}>{t('w.identityDocument')}</Title>
                <CustomDropzone
                  onDrop={(files) =>
                    form.setFieldValue('identityCardFiles', files)
                  }
                  files={form.values.identityCardFiles}
                  mimeType={'pdf'}
                  resetFiles={(index) =>
                    form.removeListItem('identityCardFiles', index)
                  }
                  disabled={
                    Boolean(employee?.onBoardingId) &&
                    user.id === employee?.id &&
                    employee?.onBoarding?.onBoardingEmployee?.validationDate !==
                      null
                  }
                />
                {filePath ? (
                  <Center>
                    <FileViewer
                      id={`document-${form.values.id}`}
                      srcUrl={filePath}
                      title={'contract.pdf'}
                    />
                  </Center>
                ) : (
                  <Text fz={'sm'} fw={'600'} c={undefined}>
                    {t('w.noFile')}
                  </Text>
                )}
              </Grid.Col>
            </Grid>
          </>
        );
    }
  }

  return (
    <Grid
      gutter="lg"
      styles={{
        root: {
          position: 'sticky',
          top: 120,
          zIndex: 50,
        },
      }}
    >
      <Grid.Col span={2}>
        <Stepper
          className={checkMediaQuery ? s.stepper : undefined}
          active={activeStepper}
          orientation={'vertical'}
          onStepClick={setActiveStepper}
        >
          <Stepper.Step
            icon={<IconUser size="1.1rem" />}
            label={checkMediaQuery ? "Pièce d'identité" : ''}
            description={checkMediaQuery ? t('w.documents') : ''}
            completedIcon={
              request ? (
                <IconCheck size={'1.1rem'} />
              ) : (
                <IconUser size="1.1rem" />
              )
            }
          />
          <Stepper.Step
            style={
              MS_WHITE_LABEL_ID === 'AFRICAPAIERH'
                ? { display: 'none' }
                : undefined
            }
            icon={<IconCalendarTime size="1.1rem" />}
            label={checkMediaQuery ? 'Sécurité Sociale' : ''}
            description={checkMediaQuery ? t('w.documents') : ''}
            completedIcon={
              request ? (
                <IconCheck size={'1.1rem'} />
              ) : (
                <IconCalendarTime size="1.1rem" />
              )
            }
          />
          {!edit && (
            <Stepper.Step
              icon={<IconFile size="1.1rem" />}
              label={checkMediaQuery ? 'Informations' : ''}
              description={checkMediaQuery ? 'Bancaires' : ''}
              completedIcon={
                request ? (
                  <IconCheck size={'1.1rem'} />
                ) : (
                  <IconFile size="1.1rem" />
                )
              }
            />
          )}
          {userPersonalInfo &&
            userPersonalInfo.nationality !== 'FR' &&
            hasPermission(
              [
                {
                  permission: BuiltInPermissions.UserPersonalInfoEdit,
                  scope: ScopeEnum.ALL,
                },
              ],
              user
            ) && (
              <Stepper.Step
                icon={<IconFile size="1.1rem" />}
                label={checkMediaQuery ? 'Travailleur' : ''}
                description={checkMediaQuery ? 'Étranger' : ''}
                completedIcon={
                  request ? (
                    <IconCheck size={'1.1rem'} />
                  ) : (
                    <IconFile size="1.1rem" />
                  )
                }
              />
            )}
        </Stepper>
      </Grid.Col>
      <Grid.Col
        styles={{
          col: {
            overflowY: 'scroll',
            height: (height * 3) / 5,
          },
        }}
        span={'auto'}
        pl={'xl'}
        pr={'100px'}
      >
        {renderComponentRequest()}
      </Grid.Col>
    </Grid>
  );
}
