import qs from 'qs';

import { Provider } from '../types/types';
import { MS_OAUTH_ENDPOINT } from '../variables/GlobalVariables';
import request from './AxiosClient';

// Oauth Controller
function createNewOauthUser(
  username: string | null,
  lastname: string | null,
  firstname: string | null,
  phone: string | null,
  linkId: string | null
) {
  return request
    .post(
      `${MS_OAUTH_ENDPOINT}/v2/oauth`,
      qs.stringify({
        username,
        lastname,
        firstname,
        phone,
        linkId,
      })
    )
    .then((res) => res?.data);
}

function resetPassword(
  password: string,
  username?: string,
  temporary_token?: string | null
) {
  return request
    .post(
      `${MS_OAUTH_ENDPOINT}/v2/oauth/password/reset`,
      qs.stringify({
        temporary_token,
        username,
        password,
      })
    )
    .then((res) => res?.data);
}
function forgotPassword(username: string) {
  return request
    .post(
      `${MS_OAUTH_ENDPOINT}/v2/oauth/password/forgot`,
      qs.stringify({
        username,
      })
    )
    .then((res) => res?.data);
}

function updatePassword(oldPassword: string, newPassword: string) {
  return request
    .post(
      `${MS_OAUTH_ENDPOINT}/v2/oauth/password/update`,
      qs.stringify({ oldPassword, newPassword })
    )
    .then((res) => res?.data);
}

function login(
  grant_type:
    | 'password'
    | 'refresh_token'
    | 'microsoft'
    | 'google'
    | 'gma'
    | 'client_credentials',
  username?: string,
  password?: string,
  redirect_uri?: string,
  refresh_token?: string
) {
  // NOTE @DEV : https://axios-http.com/fr/docs/urlencoded
  return request
    .post(
      `${MS_OAUTH_ENDPOINT}/v2/oauth/token`,
      qs.stringify({
        grant_type,
        redirect_uri,
        refresh_token,
        username,
        password,
      })
    )
    .then((res) => res?.data);
}

//Profile Controller
function getProfileToOauthUser(access_token: string) {
  return request
    .get(`${MS_OAUTH_ENDPOINT}/v2/oauth/profile`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    })
    .then((res) => res?.data);
}

function setCurrentProfileToOauthUser(access_token: string, profileId: string) {
  return request
    .post(`${MS_OAUTH_ENDPOINT}/v2/oauth/profile/current/${profileId}`, null, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    })
    .then((res) => res?.data);
}

function logout() {
  return request.get(`${MS_OAUTH_ENDPOINT}/v2/oauth/logout`);
}

function getAvatarPath(id: string) {
  return `${MS_OAUTH_ENDPOINT}/v2/oauth/avatar/${id}/200/200`;
}

function getOauthUser(access_token: string) {
  return request
    .get(`${MS_OAUTH_ENDPOINT}/v2/oauth`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    })
    .then((res) => res?.data);
}

function updateOauthUserAvatar(file: File, extension: string) {
  return request
    .patch(
      `${MS_OAUTH_ENDPOINT}/v2/oauth/avatar`,
      { file, extension },
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
    .then((res) => res?.data);
}

export interface OauthUser {
  username: string;
  lastname: string;
  firstname: string;
  password: string | null;
  phone: string | undefined;
}

function updateOauthUser(access_token: string, data: OauthUser) {
  return request
    .patch(
      `${MS_OAUTH_ENDPOINT}/v2/oauth`,
      qs.stringify({
        username: data.username,
        lastname: data.lastname,
        firstname: data.firstname,
        password: data.password,
        phone: data.phone,
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${access_token}`,
        },
      }
    )
    .then((res) => res?.data);
}

function providersList() {
  return request
    .get(`${MS_OAUTH_ENDPOINT}/v2/oauth/id-providers`)
    .then((res) => res?.data);
}

function addNewProvider(provider: Provider, access_token: string) {
  return request
    .post(
      `${MS_OAUTH_ENDPOINT}/v2/oauth/id-providers`,
      qs.stringify({
        provider,
        access_token,
      })
    )
    .then((res) => res?.data);
}

function removeProviderToLoggedOauth(provider: Provider) {
  return request
    .delete(`${MS_OAUTH_ENDPOINT}/v2/oauth/id-providers`, {
      data: { provider },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then((res) => res?.data);
}

const OauthServiceMS = {
  createNewOauthUser,
  login,
  resetPassword,
  forgotPassword,
  updatePassword,
  getProfileToOauthUser,
  setCurrentProfileToOauthUser,
  logout,
  getAvatarPath,
  getOauthUser,
  updateOauthUserAvatar,
  updateOauthUser,
  providersList,
  addNewProvider,
  removeProviderToLoggedOauth,
};

export default OauthServiceMS;
