import axios from 'axios';

function getPappersSuggestions(value: string) {
  return axios
    .get(`https://suggestions.pappers.fr/v2`, { params: { q: value } })
    .then((res) => res?.data);
}

const ToolBoxHRServices = {
  getPappersSuggestions,
};

export default ToolBoxHRServices;
