import type { DivisionRequest } from '../types/api/payload/division';
import type { DivisionResponse } from '../types/api/response/division';
import { MS_HIFIVEWORK_PROFILE_ENDPOINT } from '../variables/GlobalVariables';
import request from './AxiosClient';

function list(companyId: string): Promise<DivisionResponse[]> {
  return request
    .get(`${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/division?companyId=${companyId}`)
    .then((res) => res?.data);
}

function getDivisionById(divisionId: string | null): Promise<DivisionResponse> {
  return request
    .get(`${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/division/${divisionId}`)
    .then((res) => res?.data);
}

function create(
  companyId: string,
  data: DivisionRequest
): Promise<DivisionResponse> {
  return request
    .post(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/division/create/${companyId}`,
      data
    )
    .then((res) => res?.data);
}

function update(
  divisionId: string,
  data: DivisionRequest
): Promise<DivisionResponse> {
  return request
    .patch(`${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/division/${divisionId}`, data)
    .then((res) => res?.data);
}

function deleteDivision(divisionId: string): Promise<DivisionResponse> {
  return request
    .delete(`${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/division/${divisionId}`)
    .then((res) => res?.data);
}

function exportDivisions(companyId: string, type: 'XLSX' | 'CSV') {
  return request
    .get(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/division/${companyId}/export/${type}`,
      {
        responseType: 'blob',
      }
    )
    .then((res) => res?.data);
}

const DivisionService = {
  list,
  getDivisionById,
  create,
  update,
  deleteDivision,
  exportDivisions,
};

export default DivisionService;
