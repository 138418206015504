import { Button } from '@mantine/core';
import { IconMailFast } from '@tabler/icons-react';
import React from 'react';

import { useI18n } from '../contexts/I18nProvider';

export default function ButtonSendInvitation() {
  const { t } = useI18n();

  return (
    <Button type={'submit'} leftSection={<IconMailFast />}>
      {t('w.sendInvitation').split(' ')[0]}
    </Button>
  );
}
