import {
  Tooltip,
  type TooltipProps,
  useMantineColorScheme,
} from '@mantine/core';
import React, { ReactNode } from 'react';

type Props = {
  label: ReactNode;
  children: ReactNode;
  withinPortal?: boolean;
};

export default function CustomTooltip({
  label,
  children,
  withinPortal = true,
  ...restProps
}: Props & TooltipProps) {
  const { colorScheme } = useMantineColorScheme();

  return (
    <Tooltip
      color={colorScheme === 'dark' ? 'hifivework.2' : 'hifivework.3'}
      multiline
      withArrow
      withinPortal={withinPortal}
      label={label}
      {...restProps}
    >
      {children}
    </Tooltip>
  );
}
