import { Group, Space } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import React from 'react';

import { useAuth } from '../contexts/AuthProvider';
import { useI18n } from '../contexts/I18nProvider';
import UserEmergencyContact from '../features/user-profile/components/UserEmergencyContact';
import ProfileService from '../services/ProfileService';
import type {
  UserEmergencyContactType,
  UserResponse,
} from '../types/api/response/user';
import ButtonCancel from './ButtonCancel';
import ButtonSave from './ButtonSave';

type Props = {
  employee: UserResponse | null;
  onClose: () => void;
  refetchUserEmergencyContact?: () => void;
  emergencyContact?: UserEmergencyContactType;
  parentForm: any;
  setUserEmergencyContact: (data: UserEmergencyContactType[]) => void;
};

export default function EditEmergencyContactForm({
  employee,
  onClose,
  refetchUserEmergencyContact,
  emergencyContact,
  parentForm,
  setUserEmergencyContact,
}: Props) {
  const { t } = useI18n();
  const { access_token } = useAuth();

  const form = useForm<UserEmergencyContactType>({
    initialValues: {
      id: emergencyContact
        ? emergencyContact?.id
          ? emergencyContact.id
          : null
        : null,
      userId: emergencyContact
        ? emergencyContact?.userId
          ? emergencyContact.userId
          : employee
          ? employee.id
          : ''
        : employee
        ? employee.id
        : '',
      lastname: emergencyContact
        ? emergencyContact?.lastname
          ? emergencyContact.lastname
          : ''
        : '',
      firstname: emergencyContact
        ? emergencyContact?.firstname
          ? emergencyContact.firstname
          : ''
        : '',
      comment: emergencyContact
        ? emergencyContact?.comment
          ? emergencyContact.comment
          : ''
        : '',
      email: emergencyContact
        ? emergencyContact?.email
          ? emergencyContact.email
          : ''
        : '',
      phone: emergencyContact
        ? emergencyContact?.phone
          ? emergencyContact.phone
          : ''
        : '',
    },
  });

  const { mutate: updateUserEmergencyContact } = useMutation({
    mutationFn: (variables: any) =>
      ProfileService.createOrUpdateUserEmergencyContact(
        employee?.id,
        variables,
        access_token
      ),
    onSuccess: (data) => {
      setUserEmergencyContact(data);
      parentForm.setFieldValue('personsToContact', data);
      if (refetchUserEmergencyContact) {
        refetchUserEmergencyContact();
      }
      onClose();
      showNotification({
        id: 'udpate-added-employee-emergency-contact-success',
        title: t('w.success'),
        message: t('success.emergencyContactUpdated'),
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: () =>
      showNotification({
        id: 'update-employee-added-personal-info-error',
        title: t('w.error'),
        message: t('error.updateUserEmergencyContact', employee?.firstname),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function handleUpdateUserProfileFormSubmit(values: UserEmergencyContactType) {
    if (values.id !== null) {
      updateUserEmergencyContact({
        id: values.id,
        userId: values.userId,
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        phone: values.phone,
        comment: values.comment,
      });
    } else {
      updateUserEmergencyContact({
        userId: values.userId,
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        phone: values.phone,
        comment: values.comment,
      });
    }
  }

  return (
    <form
      onSubmit={form.onSubmit((values) =>
        handleUpdateUserProfileFormSubmit(values)
      )}
    >
      <UserEmergencyContact form={form} />
      <Space h={'md'} />
      <Group justify={'flex-end'} gap="xl">
        <ButtonCancel onClose={onClose} />
        <ButtonSave />
      </Group>
    </form>
  );
}
