import {
  Center,
  Grid,
  Group,
  Image,
  NumberInput,
  Select,
  Stepper,
  Text,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useMediaQuery, useViewportSize } from '@mantine/hooks';
import {
  IconBuildingBank,
  IconCalendarTime,
  IconCheck,
  IconClockHour5,
  IconFile,
  IconUser,
} from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';

import { useAuth } from '../contexts/AuthProvider';
import { useI18n } from '../contexts/I18nProvider';
import s from '../features/company/employees/components/AddEmployeeInformationForm/AddEmployeeInformationForm.module.sass';
import type { AddEmployeeWorkContractFormValues } from '../features/company/employees/components/AddEmployeeWorkContractForm';
import { FranceAreaSelect } from '../features/company/public-holidays-and-leaves/components/FranceAreaSelect';
import CountriesSelect from '../features/sign-up/components/CountriesSelect';
import UserIdentifiantsCompany from '../features/user-profile/components/UserIdentifiantsCompany';
import UserIdentifiantsCompanyButton from '../features/user-profile/components/UserIdentifiantsCompanyButton';
import type { ContractInfo } from '../features/user-profile/settings/UserProfileSettings';
import useUserCompanyModules from '../hooks/useUserCompanyModules';
import ProfileService from '../services/ProfileService';
import type { CompanyGroup } from '../types/api/payload/company';
import {
  CompanyResponse,
  CountryAreaEnum,
  CountryEnum,
} from '../types/api/response/company';
import { type DailyWorkParams, ModuleName } from '../types/api/response/module';
import { BuiltInPermissions, ScopeEnum } from '../types/api/response/role';
import type {
  IdentifiantCompany,
  UserResponse,
} from '../types/api/response/user';
import { hasPermission } from '../utils/authorization';
import { typesContract } from '../utils/contracts';
import { typesRegime } from '../utils/regimes';
import CustomDropzone from './CustomDropzone/CustomDropzone';
import DailyworkTypeMode from './DailyworkTypeMode';
import FileViewer from './FileViewer/FileViewer';
import LabelText from './LabelText/LabelText';

type Props = {
  form: ContractInfo | AddEmployeeWorkContractFormValues;
  userProfile: UserResponse;
  setSelectedCompanyGroup?: (data: string | null) => void;
  companyGroupSelect?: any;
  companyGroupList?: CompanyGroup[];
  company?: CompanyResponse;
};

function isEditMode(
  form: ContractInfo | AddEmployeeWorkContractFormValues
): form is AddEmployeeWorkContractFormValues {
  return Boolean((form as AddEmployeeWorkContractFormValues)?.values);
}

export default function ContractualStepper({
  form,
  userProfile,
  setSelectedCompanyGroup,
  companyGroupSelect,
  companyGroupList,
  company,
}: Props) {
  const { t } = useI18n();
  const checkMediaQuery = useMediaQuery('(min-width: 1025px)');
  const [activeStepper, setActiveStepper] = useState(0);
  const { height } = useViewportSize();
  const { getModule } = useUserCompanyModules(userProfile.id!);
  const { access_token, user } = useAuth();
  const [file, setFile] = useState({
    opened: false,
    path: '',
    type: '',
    loading: false,
  });
  const isModuleDailyWorkActiveForCompany = getModule(
    ModuleName.DailyWork
  )?.active;
  const isModuleDailyWorkActiveForAll = (
    getModule(ModuleName.DailyWork)?.configuration?.params as DailyWorkParams
  )?.activeForAll;
  const isActiveForUser = Boolean(
    userProfile.paidFeatures?.find((item) => item.active)
  );

  useEffect(() => {
    openImage();
  }, [form]);

  function openImage() {
    const userId = isEditMode(form) ? form.values.userId : userProfile.id;
    setFile({ ...file, loading: true });
    let xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';

    xhr.onload = function () {
      if (xhr.status === 200) {
        const newBlob = new Blob([this.response], {
          type: 'application/pdf',
        });
        const filePath = window.URL.createObjectURL(newBlob);

        let link = document.createElement('a');
        link.href = filePath;
        link.download = `contrat`;
        link.target = '_blank';
        setFile({
          path: filePath,
          type: newBlob.type,
          opened: true,
          loading: false,
        });
      }
    };
    xhr.open('GET', ProfileService.getUserContract(userId), true);
    xhr.setRequestHeader('Authorization', 'Bearer ' + access_token);
    xhr.send();
  }

  function renderComponent(_form: ContractInfo) {
    switch (activeStepper) {
      case 1:
        return (
          <Grid gutter="md">
            <Grid.Col span={6}>
              <Group justify={'flex-start'}>
                <Text fz={'sm'} fw={'600'} c={'dimmed'}>
                  {t('w.dateOfAuthorizationToHire')}
                </Text>
                <Text fz={'sm'} fw={'600'}>
                  {_form.employmentAuthorizationDate}
                </Text>
              </Group>
            </Grid.Col>
            <Grid.Col span={6}>
              <Group justify={'flex-start'}>
                <Text fz={'sm'} fw={'600'} c={'dimmed'}>
                  {t('w.dateOfEntry')}
                </Text>

                <Text fz={'sm'} fw={'600'} c={undefined}>
                  {_form.entryDate}
                </Text>
              </Group>
            </Grid.Col>
            <Grid.Col span={6}>
              <Group justify={'flex-start'}>
                <Text fz={'sm'} fw={'600'} c={'dimmed'}>
                  {t('w.dateAuthorizationForDismissal')}
                </Text>

                <Text fz={'sm'} fw={'600'} c={undefined}>
                  {_form.layoffAuthorizationDate}
                </Text>
              </Group>
            </Grid.Col>
            <Grid.Col span={6}>
              <Group justify={'flex-start'}>
                <Text fz={'sm'} fw={'600'} c={'dimmed'}>
                  {t('w.dateOfExit')}
                </Text>
                <Text fz={'sm'} fw={'600'} c={undefined}>
                  {_form.exitDate}
                </Text>
              </Group>
            </Grid.Col>
          </Grid>
        );
      case 2:
        return (
          <Grid gutter={'md'}>
            <Grid.Col span={6}>
              <Group justify={'flex-start'}>
                <Text fz={'sm'} fw={'600'} c={'dimmed'}>
                  {t('w.grossMonthlyRemuneration')}
                </Text>
                <Text fz={'sm'} fw={'600'} c={undefined}>
                  {_form.grossPay}
                </Text>
              </Group>
            </Grid.Col>
          </Grid>
        );
      case 3:
        return (
          <>
            <Grid gutter="md">
              <Grid.Col span={4}>
                <Group justify={'flex-start'}>
                  <Text fz={'sm'} fw={'600'} c={'dimmed'}>
                    {t('w.weeklyHours')}
                  </Text>
                  <Text fz={'sm'} fw={'600'} c={undefined}>
                    {_form.weeklyHours}
                  </Text>
                </Group>
              </Grid.Col>
              <Grid.Col span={4}>
                <Group justify={'flex-start'}>
                  <Text fz={'sm'} fw={'600'} c={'dimmed'}>
                    {t('w.monthlyHours')}
                  </Text>
                  <Text fz={'sm'} fw={'600'} c={undefined}>
                    {_form.monthlyHours}
                  </Text>
                </Group>
              </Grid.Col>
              <Grid.Col span={4}>
                <Group justify={'flex-start'}>
                  <Text fz={'sm'} fw={'600'} c={'dimmed'}>
                    {t('w.yearlyHours')}
                  </Text>
                  <Text fz={'sm'} fw={'600'} c={undefined}>
                    {_form.yearlyHours}
                  </Text>
                </Group>
              </Grid.Col>
            </Grid>
          </>
        );
      case 4:
        return (
          <>
            <Grid gutter="xl">
              <Grid.Col span={{ base: 12, md: 6 }}>
                <Text fz={'sm'} fw={'600'} c={'dimmed'}>
                  {t('w.workContract')}
                </Text>
                {file ? (
                  <Center>
                    {file.type === 'application/pdf' ? (
                      <FileViewer
                        id={`contract`}
                        srcUrl={file.path}
                        title={`contract.pdf`}
                      />
                    ) : (
                      <Center>
                        <Image w={'40%'} h={'40%'} src={file.path} />
                      </Center>
                    )}
                  </Center>
                ) : (
                  <Text fz={'sm'} fw={'600'} c={undefined}>
                    {t('w.noWorkContract')}
                  </Text>
                )}
              </Grid.Col>
            </Grid>
          </>
        );
      case 5:
        return (
          <Grid gutter="xl">
            <Grid.Col span={{ base: 12, md: 6 }}>
              <Group justify={'flex-start'}>
                <Text fz={'sm'} fw={'600'} c={'dimmed'}>
                  {t('w.nameOnPayslip')}
                </Text>
                <Text fz={'sm'} fw={'600'} c={undefined}>
                  {_form.payslipName}
                </Text>
              </Group>
            </Grid.Col>
          </Grid>
        );
      case 6:
        return (
          <Grid gutter="md">
            <Grid.Col span={3}>
              <Text fz={'sm'} fw={'600'} c={'dimmed'}>
                {t('w.timeManagement')}
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <DailyworkTypeMode
                t={t}
                id={userProfile.id}
                companyId={userProfile.companyId!}
                fixedPriceManager={userProfile.fixedPriceManager!}
                paidFeatures={userProfile.paidFeatures!}
              />
            </Grid.Col>
          </Grid>
        );
      default:
        return (
          <>
            <Grid gutter="xl">
              <Grid.Col span={{ base: 12, md: 6 }}>
                <Group justify={'flex-start'}>
                  <Text fz={'sm'} fw={'600'} c={'dimmed'}>
                    {t('w.typeOfContract')}
                  </Text>
                  <Text fz={'sm'} fw={'600'} c={undefined}>
                    {_form.contractType}
                  </Text>
                </Group>
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <Group justify={'flex-start'}>
                  <Text fz={'sm'} fw={'600'} c={'dimmed'}>
                    {t('w.regimeType')}
                  </Text>
                  <Text fz={'sm'} fw={'600'} c={undefined}>
                    {_form.regime}
                  </Text>
                </Group>
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <Group justify={'flex-start'}>
                  <Text fz={'sm'} fw={'600'} c={'dimmed'}>
                    {t('w.regimeLevel')}
                  </Text>
                  <Text fz={'sm'} fw={'600'} c={undefined}>
                    {_form.regimeLevel}
                  </Text>
                </Group>
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <Group justify={'flex-start'}>
                  <Text fz={'sm'} fw={'600'} c={'dimmed'}>
                    {t('w.professionalQualification')}
                  </Text>
                  <Text fz={'sm'} fw={'600'} c={undefined}>
                    {_form.professionalQualifications}
                  </Text>
                </Group>
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <Group justify={'flex-start'}>
                  <Text fz={'sm'} fw={'600'} c={'dimmed'}>
                    {t('w.jobType')}
                  </Text>
                  <Text fz={'sm'} fw={'600'} c={undefined}>
                    {_form.employment}
                  </Text>
                </Group>
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <Group justify={'flex-start'}>
                  <Text fz={'sm'} fw={'600'} c={'dimmed'}>
                    {t('w.comment')}
                  </Text>
                  <Text fz={'sm'} fw={'600'} c={undefined}>
                    {_form.comment}
                  </Text>
                </Group>
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <Group>
                  <CountriesSelect
                    value={_form.countryCode!}
                    label={t('w.countryOfContract')}
                    disabled
                  />
                  {_form.countryCode === CountryEnum.FR && (
                    <FranceAreaSelect value={_form.countryArea} disabled />
                  )}
                </Group>
              </Grid.Col>
            </Grid>
          </>
        );
    }
  }

  function renderComponentRequest(_form: AddEmployeeWorkContractFormValues) {
    switch (activeStepper) {
      case 1:
        return (
          <>
            <Grid gutter="xl">
              <Grid.Col span={{ base: 12, md: 6 }}>
                <DatePickerInput
                  label={<LabelText text={t('w.dateOfAuthorizationToHire')} />}
                  valueFormat={'D MMMM YYYY'}
                  placeholder={t('w.noValue')}
                  clearable
                  {..._form.getInputProps('employmentAuthorizationDate')}
                  disabled={
                    Boolean(userProfile.onBoardingId) &&
                    user.id === userProfile.id
                  }
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <DatePickerInput
                  label={<LabelText text={t('w.dateOfEntry')} />}
                  valueFormat={'D MMMM YYYY'}
                  placeholder={t('w.noValue')}
                  clearable
                  {..._form.getInputProps('entryDate')}
                  disabled={
                    Boolean(userProfile.onBoardingId) &&
                    user.id === userProfile.id
                  }
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <DatePickerInput
                  label={
                    <LabelText text={t('w.dateAuthorizationForDismissal')} />
                  }
                  valueFormat={'D MMMM YYYY'}
                  placeholder={t('w.noValue')}
                  clearable
                  {..._form.getInputProps('layoffAuthorizationDate')}
                  disabled={
                    _form.values.exitDate != null ||
                    (Boolean(userProfile.onBoardingId) &&
                      user.id === userProfile.id)
                  }
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <DatePickerInput
                  label={<LabelText text={t('w.dateOfExit')} />}
                  valueFormat={'D MMMM YYYY'}
                  placeholder={t('w.noValue')}
                  clearable
                  {..._form.getInputProps('exitDate')}
                  disabled={
                    Boolean(userProfile.onBoardingId) &&
                    user.id === userProfile.id
                  }
                />
              </Grid.Col>
            </Grid>
          </>
        );
      case 2:
        return (
          <Grid gutter={'xl'}>
            <Grid.Col span={{ base: 12, md: 6 }}>
              <NumberInput
                min={0}
                label={<LabelText text={t('w.grossMonthlyRemuneration')} />}
                {..._form.getInputProps('grossPay')}
                disabled={
                  Boolean(userProfile.onBoardingId) &&
                  user.id === userProfile.id
                }
              />
            </Grid.Col>
          </Grid>
        );
      case 3:
        return (
          <>
            <Grid grow gutter="xl">
              <Grid.Col span={{ base: 12, md: 4 }}>
                <NumberInput
                  min={0}
                  label={<LabelText text={t('w.weeklyHours')} />}
                  placeholder={
                    !_form.values.weeklyHours
                      ? _form.values.monthlyHours
                        ? ((_form.values.monthlyHours * 12) / 52).toFixed(2)
                        : _form.values.yearlyHours
                        ? (_form.values.yearlyHours / 45.4).toFixed(2)
                        : undefined
                      : undefined
                  }
                  {..._form.getInputProps('weeklyHours')}
                  disabled={
                    !!_form.values.monthlyHours ||
                    !!_form.values.yearlyHours ||
                    (Boolean(userProfile.onBoardingId) &&
                      user.id === userProfile.id)
                  }
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 4 }}>
                <NumberInput
                  min={0}
                  label={<LabelText text={t('w.monthlyHours')} />}
                  {..._form.getInputProps('monthlyHours')}
                  placeholder={
                    !_form.values.monthlyHours
                      ? _form.values.weeklyHours
                        ? ((_form.values.weeklyHours * 52) / 12).toFixed(2)
                        : _form.values.yearlyHours
                        ? (
                            ((_form.values.yearlyHours / 45.4) * 52) /
                            12
                          ).toFixed(2)
                        : undefined
                      : undefined
                  }
                  disabled={
                    !!_form.values.weeklyHours ||
                    !!_form.values.yearlyHours ||
                    (Boolean(userProfile.onBoardingId) &&
                      user.id === userProfile.id)
                  }
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 4 }}>
                <NumberInput
                  min={0}
                  label={<LabelText text={t('w.yearlyHours')} />}
                  {..._form.getInputProps('yearlyHours')}
                  placeholder={
                    !_form.values.yearlyHours
                      ? _form.values.weeklyHours
                        ? (_form.values.weeklyHours * 45.4).toFixed(2)
                        : _form.values.monthlyHours
                        ? (
                            ((_form.values.monthlyHours * 12) / 52) *
                            45.4
                          ).toFixed(2)
                        : undefined
                      : undefined
                  }
                  disabled={
                    !!_form.values.monthlyHours ||
                    !!_form.values.weeklyHours ||
                    (Boolean(userProfile.onBoardingId) &&
                      user.id === userProfile.id)
                  }
                />
              </Grid.Col>
            </Grid>
          </>
        );
      case 4:
        return (
          <Grid gutter="lg">
            <Grid.Col span={{ base: 10, md: 6 }}>
              <Title order={3}>{t('w.workContract')}</Title>
              <CustomDropzone
                onDrop={(files) => _form.setFieldValue('file', files)}
                files={_form.values.file}
                mimeType={'pdf'}
                resetFile={() => _form.setFieldValue('file', [])}
                disabled={
                  Boolean(userProfile.onBoardingId) &&
                  user.id === userProfile.id
                }
              />
            </Grid.Col>
            <Grid.Col span={{ base: 2, md: 6 }}>
              {!!_form.values?.file?.length ? (
                <Center>
                  {_form.values.file[0].type === 'application/pdf' ? (
                    <FileViewer
                      id={`document-${_form.values.id}`}
                      srcUrl={URL.createObjectURL(_form.values.file[0])}
                      title={'contract.pdf'}
                    />
                  ) : (
                    <Center>
                      <Image
                        w={'40%'}
                        h={'40%'}
                        src={URL.createObjectURL(_form.values.file[0])}
                      />
                    </Center>
                  )}
                </Center>
              ) : (
                <>
                  {file ? (
                    <Center>
                      {file.type === 'application/pdf' ? (
                        <FileViewer
                          id={`contract`}
                          srcUrl={file.path}
                          title={`contract.pdf`}
                        />
                      ) : (
                        <Center>
                          <Image w={'40%'} h={'40%'} src={file.path} />
                        </Center>
                      )}
                    </Center>
                  ) : (
                    <Text fz={'sm'} fw={'600'} c={undefined}>
                      {t('w.noWorkContract')}
                    </Text>
                  )}
                </>
              )}
            </Grid.Col>
          </Grid>
        );
      case 5:
        return (
          <Grid grow gutter="xl">
            <Grid.Col span={12}>
              <TextInput
                label={<LabelText text={t('w.nameOnPayslip')} />}
                placeholder={t('w.noValue')}
                {..._form.getInputProps('payslipName')}
                disabled={
                  Boolean(userProfile.onBoardingId) &&
                  user.id === userProfile.id
                }
              />
            </Grid.Col>
            <Grid.Col span={6}>
              {_form?.values?.identifiantsCompany &&
                _form.values.identifiantsCompany.length > 0 &&
                _form.values.identifiantsCompany.map(
                  (item: IdentifiantCompany, index: number) => (
                    <UserIdentifiantsCompany
                      key={index}
                      index={index}
                      form={_form}
                    />
                  )
                )}
            </Grid.Col>
            <Grid.Col>
              <UserIdentifiantsCompanyButton
                onClick={() =>
                  _form.insertListItem('identifiantsCompany', {
                    matricule: '',
                    codeCompany: '',
                  })
                }
              />
            </Grid.Col>
          </Grid>
        );
      case 6:
        return (
          <Grid gutter="md">
            <Grid.Col span={3}>
              <Text>{t('w.timeManagement')}</Text>
            </Grid.Col>
            <Grid.Col span={3}>
              {!Boolean(userProfile.onBoardingId) &&
                userProfile.id !== user.id && (
                  <DailyworkTypeMode
                    t={t}
                    id={userProfile.id}
                    companyId={userProfile.companyId!}
                    fixedPriceManager={userProfile.fixedPriceManager!}
                    paidFeatures={userProfile.paidFeatures!}
                  />
                )}
            </Grid.Col>
          </Grid>
        );
      default:
        return (
          <>
            <Grid gutter="xl">
              <Grid.Col span={12}>
                {companyGroupList &&
                  setSelectedCompanyGroup &&
                  companyGroupList.filter(
                    (item: CompanyGroup) => item.siret !== company?.siret
                  ).length > 0 && (
                    <Select
                      data={companyGroupSelect}
                      label={<LabelText text={t('w.companyGroup')} />}
                      placeholder={t('w.select')}
                      onChange={(_value) => setSelectedCompanyGroup(_value)}
                    />
                  )}
              </Grid.Col>

              <Grid.Col span={{ base: 12, md: 6 }}>
                <Select
                  label={<LabelText text={t('w.typeOfContract')} />}
                  data={typesContract(t)}
                  placeholder={t('w.noValue')}
                  {..._form.getInputProps('contractType')}
                  disabled={
                    Boolean(userProfile.onBoardingId) &&
                    user.id === userProfile.id
                  }
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                {_form.values.contractType === 'OTHER' && (
                  <TextInput
                    label={<LabelText text={t('w.otherContractType')} />}
                    placeholder={t('w.other')}
                    {..._form.getInputProps('otherContractType')}
                    disabled={_form.values.contractType !== 'OTHER'}
                  />
                )}
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <Select
                  label={<LabelText text={t('w.regimeType')} />}
                  data={typesRegime(t)}
                  placeholder={t('w.noValue')}
                  {..._form.getInputProps('regime')}
                  disabled={
                    Boolean(userProfile.onBoardingId) &&
                    user.id === userProfile.id
                  }
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                {_form.values.regime === 'OTHER' && (
                  <TextInput
                    label={<LabelText text={t('w.regimeOther')} />}
                    placeholder={t('w.other')}
                    {..._form.getInputProps('otherRegime')}
                    disabled={_form.values.regime !== 'OTHER'}
                  />
                )}
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <TextInput
                  label={<LabelText text={t('w.regimeLevel')} />}
                  placeholder={t('w.regimeLevel')}
                  {..._form.getInputProps('regimeLevel')}
                  disabled={
                    Boolean(userProfile.onBoardingId) &&
                    user.id === userProfile.id
                  }
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <TextInput
                  label={<LabelText text={t('w.professionalQualification')} />}
                  placeholder={t('w.noValue')}
                  {..._form.getInputProps('professionalQualifications')}
                  disabled={
                    Boolean(userProfile.onBoardingId) &&
                    user.id === userProfile?.id
                  }
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <TextInput
                  label={<LabelText text={t('w.jobType')} />}
                  placeholder={t('w.noValue')}
                  {..._form.getInputProps('employment')}
                  disabled={
                    Boolean(userProfile.onBoardingId) &&
                    user.id === userProfile.id
                  }
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <Group grow>
                  <CountriesSelect
                    value={_form.values.countryCode!}
                    onChange={(value) =>
                      _form.setFieldValue('countryCode', value as CountryEnum)
                    }
                    label={t('w.countryOfContract')}
                  />
                  {_form.values.countryCode === CountryEnum.FR && (
                    <FranceAreaSelect
                      onChange={(value) =>
                        _form.setFieldValue(
                          'countryArea',
                          value as CountryAreaEnum
                        )
                      }
                      value={_form.values.countryArea}
                    />
                  )}
                </Group>
              </Grid.Col>
              <Grid.Col span={12}>
                <Textarea
                  resize={'vertical'}
                  placeholder={t('w.noValue')}
                  label={<LabelText text={t('w.comment')} />}
                  {..._form.getInputProps('comment')}
                  disabled={
                    Boolean(userProfile.onBoardingId) &&
                    user.id === userProfile.id
                  }
                />
              </Grid.Col>
            </Grid>
          </>
        );
    }
  }

  return (
    <Grid
      gutter="lg"
      styles={{
        root: {
          position: 'sticky',
          top: 120,
        },
      }}
    >
      <Grid.Col span={2}>
        <Stepper
          className={checkMediaQuery ? s.stepper : undefined}
          active={activeStepper}
          orientation={'vertical'}
          onStepClick={setActiveStepper}
          miw={'180px'}
        >
          <Stepper.Step
            icon={<IconUser size="1.1rem" />}
            label={checkMediaQuery ? 'Informations' : ''}
            description={checkMediaQuery ? 'Principales' : ''}
            completedIcon={
              isEditMode(form) ? (
                <IconCheck size={'1.1rem'} />
              ) : (
                <IconUser size="1.1rem" />
              )
            }
          />
          <Stepper.Step
            icon={<IconCalendarTime size="1.1rem" />}
            label={checkMediaQuery ? 'Dates' : ''}
            description={checkMediaQuery ? 'Embauche et sortie' : ''}
            completedIcon={
              isEditMode(form) ? (
                <IconCheck size={'1.1rem'} />
              ) : (
                <IconCalendarTime size="1.1rem" />
              )
            }
          />
          <Stepper.Step
            icon={<IconBuildingBank size={'1.1rem'} />}
            label={checkMediaQuery ? t('w.remuneration') : ''}
            description={checkMediaQuery ? 'Salaire' : ''}
            completedIcon={
              isEditMode(form) ? (
                <IconCheck size={'1.1rem'} />
              ) : (
                <IconBuildingBank size="1.1rem" />
              )
            }
          />
          <Stepper.Step
            icon={<IconClockHour5 size={'1.1rem'} />}
            label={checkMediaQuery ? 'Horaires' : ''}
            description={checkMediaQuery ? 'Temps de travail' : ''}
            completedIcon={
              isEditMode(form) ? (
                <IconCheck size={'1.1rem'} />
              ) : (
                <IconClockHour5 size="1.1rem" />
              )
            }
          />
          <Stepper.Step
            icon={<IconFile size="1.1rem" />}
            label={checkMediaQuery ? 'Contrat de travail' : ''}
            description={checkMediaQuery ? 'Pièce jointe' : ''}
            completedIcon={
              isEditMode(form) ? (
                <IconCheck size={'1.1rem'} />
              ) : (
                <IconFile size="1.1rem" />
              )
            }
          />
          <Stepper.Step
            icon={<IconFile size="1.1rem" />}
            label={checkMediaQuery ? 'Informations' : ''}
            description={checkMediaQuery ? 'Paie' : ''}
            completedIcon={
              isEditMode(form) ? (
                <IconCheck size={'1.1rem'} />
              ) : (
                <IconFile size="1.1rem" />
              )
            }
          />
          {isModuleDailyWorkActiveForCompany &&
            hasPermission(
              [
                {
                  permission: BuiltInPermissions.ValidateLeavesAbsences,
                  scope: ScopeEnum.ALL,
                },
                {
                  permission: BuiltInPermissions.AuthorizedUpsertDailyWork,
                  scope: ScopeEnum.ALL,
                },
                {
                  permission: BuiltInPermissions.AuthorizedReadDailyWork,
                  scope: ScopeEnum.ALL,
                },
              ],
              user
            ) &&
            (isModuleDailyWorkActiveForAll || isActiveForUser) && (
              <Stepper.Step
                icon={<IconClockHour5 size={'1.1rem'} />}
                label={checkMediaQuery ? 'Gestion' : ''}
                description={checkMediaQuery ? 'Des temps' : ''}
                completedIcon={
                  isEditMode(form) ? (
                    <IconCheck size={'1.1rem'} />
                  ) : (
                    <IconClockHour5 size="1.1rem" />
                  )
                }
              />
            )}
        </Stepper>
      </Grid.Col>
      <Grid.Col
        styles={{
          col: {
            overflowY: 'scroll',
            height: (height * 3) / 5,
          },
        }}
        span={'auto'}
        pl={'xl'}
        pr={'100px'}
      >
        {isEditMode(form)
          ? renderComponentRequest(form as AddEmployeeWorkContractFormValues)
          : renderComponent(form as ContractInfo)}
      </Grid.Col>
    </Grid>
  );
}
