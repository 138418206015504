import { Button, Flex, NumberInput, Switch } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconDeviceFloppy } from '@tabler/icons-react';
import React from 'react';

import LabelText from '../../../../components/LabelText/LabelText';
import type { ModuleToEditComponentProps } from '../../../../components/ModuleToEditModal';
import { useI18n } from '../../../../contexts/I18nProvider';
import type { TimeRecorderParams as TimeRecorderParamsType } from '../../../../types/api/response/module';

export default function TimeRecorderParams({
  module,
  updateModuleParams,
}: ModuleToEditComponentProps) {
  const { t } = useI18n();
  const moduleParams = module.configuration?.params as TimeRecorderParamsType;

  const form = useForm<TimeRecorderParamsType>({
    initialValues: {
      linkToDailyWork: moduleParams?.linkToDailyWork || false,
      delayPeriod: moduleParams?.delayPeriod || 0,
    },
  });

  function submit(values: TimeRecorderParamsType) {
    updateModuleParams({
      apiUrl: module.description.url,
      payload: {
        active: true,
        ...values,
      },
    });
  }

  return (
    <form onSubmit={form.onSubmit(submit)}>
      <Flex direction={'column'} gap={'xl'}>
        <Flex justify={'flex-end'}>
          <Switch
            labelPosition="left"
            label={t('w.linkToTimeManagementFunctionality')}
            checked={form.values.linkToDailyWork}
            {...form.getInputProps('linkToDailyWork')}
          />
        </Flex>
        <NumberInput
          label={<LabelText text={t('w.tolerancePeriodInMinutes')} />}
          description={t('w.defineTolerancePeriodForTimeClock')}
          {...form.getInputProps('delayPeriod')}
        />
        <Flex justify={'flex-end'} mt={'xl'}>
          <Button type={'submit'} leftSection={<IconDeviceFloppy />}>
            {t('w.saveAndActivate')}
          </Button>
        </Flex>
      </Flex>
    </form>
  );
}
