import { useEffect } from 'react';

import { MS_WHITE_LABEL_ID } from '../variables/GlobalVariables';

export default function AprHubSpotScript() {
  useEffect(() => {
    if (MS_WHITE_LABEL_ID === 'AFRICAPAIERH') {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = 'hs-script-loader';
      script.async = true;
      script.defer = true;
      script.src = '//js-eu1.hs-scripts.com/27175750.js';
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  return null;
}
