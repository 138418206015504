import type { BlockingPeriodRequest } from '../types/api/payload/blockingPeriod';
import type { BlockingPeriodResponse } from '../types/api/response/blockingPeriod';
import { MS_PLANNING_ENDPOINT } from '../variables/GlobalVariables';
import request from './AxiosClient';

function list(companyId: string): Promise<BlockingPeriodResponse[]> {
  return request
    .get(`${MS_PLANNING_ENDPOINT}/v2/blockingPeriod/${companyId}/list`)
    .then((res) => res?.data);
}

function deleteBlockingPeriod(
  blockingPeriodId: string,
  companyId: string
): Promise<BlockingPeriodResponse> {
  return request
    .delete(
      `${MS_PLANNING_ENDPOINT}/v2/blockingPeriod/${companyId}/delete/${blockingPeriodId}`
    )
    .then((res) => res?.data);
}

function createBlockingPeriod(
  companyId: string,
  payload: BlockingPeriodRequest
): Promise<BlockingPeriodResponse> {
  return request
    .post(
      `${MS_PLANNING_ENDPOINT}/v2/blockingPeriod/${companyId}/create`,
      payload
    )
    .then((res) => res?.data);
}

const BlockingPeriodsService = {
  list,
  deleteBlockingPeriod,
  createBlockingPeriod,
};

export default BlockingPeriodsService;
