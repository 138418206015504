import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import CompanyService from '../services/CompanyService';

export default function useCompany(user: any) {
  const { companyId } = useParams();
  const id: string = useMemo(
    () => (companyId ? companyId : user?.company?.id),
    [companyId, user]
  );

  const fiveMinutes = 300000;

  const { data, refetch } = useQuery({
    enabled: !!id,
    queryKey: ['CompanyService.getCompany', id],
    queryFn: () => CompanyService.getCompany(id),
    staleTime: fiveMinutes,
  });

  return { id, company: data, refetch };
}
