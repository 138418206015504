import { Title } from '@mantine/core';
import React from 'react';

import { useI18n } from '../../../contexts/I18nProvider';

export default function ShuttleSheetCommentsDrawerTitle() {
  const { t } = useI18n();
  return (
    <Title size={'h3'} component="p">
      {t('w.comment')}s
    </Title>
  );
}
