import { Stack } from '@mantine/core';

import ConfirmValidateEmployeesList from '../../../../components/ConfirmValidateEmployeesList';
import type { EndOfMonthDataResponseFront } from '../../../../types/front/endMonth';

type Props = {
  selectedEmployees: EndOfMonthDataResponseFront[];
};

export default function EndOfMonthConfirmValidateEmployeesList({
  selectedEmployees,
}: Props) {
  return (
    <Stack>
      {selectedEmployees.map((item) => (
        <ConfirmValidateEmployeesList
          key={item.employee}
          userId={item.employee}
          userFullname={item.employeeObject.fullname!}
        />
      ))}
    </Stack>
  );
}
