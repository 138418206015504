import { Flex, Grid, rem } from '@mantine/core';
import React from 'react';

import CustomUserAvatar from '../../../components/CustomAvatar/CustomUserAvatar';

type Props = {
  userId: string;
};

export default function CustomAvatarContainer({ userId }: Props) {
  return (
    <Grid.Col span={2} style={{ minHeight: rem(80) }}>
      <Flex justify={'center'}>
        <CustomUserAvatar userId={userId} size={120} />
      </Flex>
    </Grid.Col>
  );
}
