import {
  Box,
  Center,
  Group,
  PasswordInput,
  Progress,
  Text,
} from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons-react';
import { ChangeEvent, ReactNode, useMemo } from 'react';

import { useI18n } from '../../../contexts/I18nProvider';

type Props = {
  label: ReactNode;
  placeholder?: string | undefined;
  value: string;
  onChange: (value: ChangeEvent<HTMLInputElement>) => void;
  error?: ReactNode;
};

function PasswordRequirement({
  meets,
  label,
}: {
  meets: boolean;
  label: string;
}) {
  return (
    <Text component="div" c={meets ? 'teal' : 'red'} mt={5} fz="xs">
      <Center inline>
        {meets ? (
          <IconCheck size="0.9rem" stroke={1.5} />
        ) : (
          <IconX size="0.9rem" stroke={1.5} />
        )}
        <Box ml={7}>{label}</Box>
      </Center>
    </Text>
  );
}

export function PasswordStrength({
  label,
  placeholder,
  value,
  onChange,
  error,
}: Props) {
  const { t } = useI18n();

  const requirements = useMemo(
    () => [
      { re: /[0-9]/, label: t('w.containsNumbers') },
      { re: /[a-z]/, label: t('w.containsLowerCaseLetters') },
      { re: /[A-Z]/, label: t('w.containUpperCaseLetters') },
      {
        re: /[$&+,:;=?@#|'<>.^*()%!-]/,
        label: t('w.containsSpecialCharacters'),
      },
    ],
    []
  );

  function getStrength(password: string) {
    let multiplier = password?.length > 5 ? 0 : 1;

    requirements.forEach((requirement) => {
      if (!requirement.re.test(password)) {
        multiplier += 1;
      }
    });

    return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 0);
  }
  const strength = getStrength(value);

  const checks = requirements.map((requirement, index) => (
    <PasswordRequirement
      key={index}
      label={requirement.label}
      meets={requirement.re.test(value)}
    />
  ));

  const bars = Array(4)
    .fill(0)
    .map((_, index) => (
      <Progress
        styles={{ section: { transitionDuration: '0ms' } }}
        value={
          value.length > 0 && index === 0
            ? 100
            : strength >= ((index + 1) / 4) * 100
            ? 100
            : 0
        }
        color={strength > 80 ? 'teal' : strength > 50 ? 'yellow' : 'red'}
        key={index}
        size={4}
      />
    ));

  return (
    <div>
      <PasswordInput
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        label={label}
        required
        error={error}
      />

      <Group gap={5} grow mt="xs" mb="md">
        {bars}
      </Group>

      <PasswordRequirement
        label={t('w.containsAtLeast8Characters')}
        meets={value.length > 7}
      />
      {checks}
    </div>
  );
}
