import { Flex, Text, type TextProps } from '@mantine/core';
import { IconArrowRight } from '@tabler/icons-react';
import React from 'react';

import { useI18n } from '../contexts/I18nProvider';
import {
  isSameDay,
  toDayMonthYearWithoutSession,
  toDayMonthYearWithSession,
} from '../utils/format';

type Props = {
  startHalf: boolean;
  endHalf: boolean;
  startDate: string;
  endDate: string;
};

export default function LeavePeriodDates({
  startHalf,
  endHalf,
  startDate,
  endDate,
  ...restProps
}: Props & TextProps) {
  const { t } = useI18n();

  return (
    <Flex gap={2} align="center" direction="row">
      {!startHalf && !endHalf && isSameDay(startDate, endDate) ? (
        <Text fz={'sm'} fw={'medium'} {...restProps}>
          {toDayMonthYearWithoutSession(startDate)}
        </Text>
      ) : (
        <>
          <Text fz={'sm'} fw={'medium'} {...restProps}>
            {startHalf
              ? toDayMonthYearWithSession(startDate, t)
              : toDayMonthYearWithoutSession(startDate)}
          </Text>
          <IconArrowRight size={12} />
          <Text fz={'sm'} fw={'medium'} {...restProps}>
            {endHalf
              ? toDayMonthYearWithSession(endDate, t)
              : toDayMonthYearWithoutSession(endDate)}
          </Text>
        </>
      )}
    </Flex>
  );
}
