import { Button, Stack, Textarea, TextInput } from '@mantine/core';
import { FileWithPath } from '@mantine/dropzone';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import {
  IconAlertTriangle,
  IconCheck,
  IconEdit,
  IconFilePlus,
  IconFolderPlus,
  IconX,
} from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect } from 'react';

import CustomDropzone from '../../../components/CustomDropzone/CustomDropzone';
import LabelText from '../../../components/LabelText/LabelText';
import { useI18n } from '../../../contexts/I18nProvider';
import DocumentsService, {
  UpdateOrCreateDocumentPayload,
} from '../../../services/DocumentsService';
import {
  DocumentPathType,
  DocumentPropertySpace,
  DocumentView,
} from '../../../types/types';
import handleErrorMessage from '../../../utils/handleErrorMessage';
import { normalizeFile } from '../../../utils/normalizeFile';

type Props = {
  isFolder: boolean;
  space: DocumentPropertySpace;
  userId: string;
  document: DocumentView | null;
  path: string;
  companyId: string;
  handleCloseDrawer: () => void;
  refetch: () => void;
  accountantCompanyId: string | null;
};

type FormValues = {
  files: FileWithPath[];
  title: string;
  comment: string;
};

export default function DocumentsForm({
  isFolder,
  space,
  userId,
  document,
  path,
  companyId,
  handleCloseDrawer,
  refetch,
  accountantCompanyId,
}: Props) {
  const { t } = useI18n();
  const form = useForm<FormValues>({
    initialValues: {
      files: [],
      title: '',
      comment: '',
    },
  });

  const isEditingDocument = Boolean(document);

  useEffect(() => {
    if (isEditingDocument) {
      const documentValues = {
        files: [],
        title: document?.meta.title ? document.meta.title : '',
        comment: document?.meta.comment ? document.meta.comment : '',
      };
      form.setInitialValues(documentValues);
      form.setValues(documentValues);
    }
  }, [isEditingDocument]);

  function resetFile() {
    form.setFieldValue('files', []);
  }

  const { mutate: updateOrCreateDocument, isLoading } = useMutation({
    mutationFn: (variables: UpdateOrCreateDocumentPayload) =>
      DocumentsService.updateOrCreateDocument(
        companyId,
        accountantCompanyId,
        variables
      ),
    onSuccess: (data) => {
      const { id } = data;
      handleCloseDrawer();
      refetch();
      if (!isEditingDocument) {
        if (isFolder) {
          showNotification({
            id: `create-folder-${id}-successful`,
            title: t('w.success'),
            message: `${t('w.folder')} ${t('w.created').toLowerCase()}`,
            color: 'green',
            icon: <IconCheck />,
          });
        } else {
          showNotification({
            id: `create-file-${id}-successful`,
            title: t('w.success'),
            message: `${t('w.file')} ${t('w.created').toLowerCase()}`,
            color: 'green',
            icon: <IconCheck />,
          });
        }
      } else {
        if (isFolder) {
          showNotification({
            id: `update-folder-${id}-successful`,
            title: t('w.success'),
            message: `${t('w.folder')} ${t('w.updated').toLowerCase()}`,
            color: 'green',
            icon: <IconCheck />,
          });
        } else {
          showNotification({
            id: `update-file-${id}-successful`,
            title: t('w.success'),
            message: `${t('w.file')} ${t('w.updated').toLowerCase()}`,
            color: 'green',
            icon: <IconCheck />,
          });
        }
      }
    },
    onError: (error) => {
      if (!isEditingDocument) {
        if (isFolder) {
          showNotification({
            id: `create-folder-error`,
            title: t('w.error'),
            message: handleErrorMessage(error, t),
            color: 'red',
            icon: <IconX />,
          });
        } else {
          showNotification({
            id: `create-file-error`,
            title: t('w.error'),
            message: handleErrorMessage(error, t),
            color: 'red',
            icon: <IconX />,
          });
        }
      } else {
        if (isFolder) {
          showNotification({
            id: `update-folder-error`,
            title: t('w.error'),
            message: handleErrorMessage(error, t),
            color: 'red',
            icon: <IconX />,
          });
        } else {
          showNotification({
            id: `update-file-error`,
            title: t('w.error'),
            message: handleErrorMessage(error, t),
            color: 'red',
            icon: <IconX />,
          });
        }
      }
    },
  });

  function getFileType(
    fileWithPath: FileWithPath
  ): DocumentPathType | undefined {
    if (fileWithPath) {
      const extensionFile = fileWithPath.path?.split('.').pop();
      switch (extensionFile) {
        case 'pdf':
          return DocumentPathType.PDF;
        case 'jpg':
        case 'jpeg':
        case 'png':
          return DocumentPathType.IMAGE;
        case 'xlsx':
        case 'xls':
          return DocumentPathType.XLS;
        case 'docx':
        case 'doc':
          return DocumentPathType.DOC;
        default:
          return DocumentPathType.UNKNOWN;
      }
    }
  }

  function getTitle(values: ReturnType<(values: FormValues) => FormValues>) {
    if (values.title === '') {
      if (form.values.files.length === 1) {
        const file: FileWithPath = form.values.files[0];
        if (file.path) {
          return file.path.split('.')[0];
        }
      }
    }
    return values.title;
  }

  function handleDocumentFormSubmit(
    values: ReturnType<(values: FormValues) => FormValues>
  ): void {
    if (!isEditingDocument) {
      if (isFolder) {
        updateOrCreateDocument({
          title: values.title,
          comment: values.comment,
          path: path,
          documentId: null,
          type: DocumentPathType.FOLDER,
          space: space,
          userId: userId,
          fileName: null,
          file: null,
        });
      } else {
        if (form.values.files.length === 1) {
          updateOrCreateDocument({
            title: getTitle(values),
            comment: values.comment,
            path: path,
            documentId: null,
            type: getFileType(values.files[0])
              ? getFileType(values.files[0])
              : DocumentPathType.FOLDER,
            space: space,
            userId: userId,
            fileName: values.files[0].path ? values.files[0].path : null,
            file: normalizeFile(values.files[0]),
          });
        } else {
          showNotification({
            id: `missing-file`,
            title: t('w.warning'),
            message: `${t('w.file')} ${t('w.required')}`,
            color: 'orange',
            icon: <IconAlertTriangle />,
          });
        }
      }
    } else {
      if (isFolder) {
        updateOrCreateDocument({
          title: values.title,
          comment: values.comment,
          path: document?.path.appPath ? document.path.appPath : '/',
          documentId: document?.id ? document.id : '',
          type: DocumentPathType.FOLDER,
          space: space,
          userId: userId,
          fileName: null,
          file: null,
        });
      } else {
        updateOrCreateDocument({
          title: values.title,
          comment: values.comment,
          path: document?.path.appPath ? document.path.appPath : '/',
          documentId: document?.id ? document.id : '',
          type: document?.path.type,
          space: space,
          userId: userId,
          fileName: null,
          file: null,
        });
      }
    }
  }

  function getPlaceholder() {
    if (form.values.files.length === 1) {
      const file: FileWithPath = form.values.files[0];
      if (file.path) {
        return file.path.split('.')[0];
      }
    } else {
      return t('w.enterText');
    }
  }

  return (
    <form
      onSubmit={form.onSubmit((values) => handleDocumentFormSubmit(values))}
    >
      <Stack gap={'xl'}>
        {!isFolder && !isEditingDocument && (
          <CustomDropzone
            onDrop={(files) => form.setFieldValue('files', files)}
            files={form.values.files}
            mimeType={'several'}
            resetFile={resetFile}
          />
        )}
        <TextInput
          label={
            <LabelText text={isFolder ? 'Nom du dossier' : 'Nom du fichier'} />
          }
          {...form.getInputProps('title')}
          required={isEditingDocument || isFolder}
          placeholder={getPlaceholder()}
          data-autofocus
        />
        <Textarea
          label={<LabelText text={t('w.comment')} />}
          {...form.getInputProps('comment')}
          autosize
          minRows={2}
          maxRows={4}
          placeholder={t('w.enterText')}
        />
        <Button
          type={'submit'}
          leftSection={
            isEditingDocument ? (
              <IconEdit size={16} />
            ) : isFolder ? (
              <IconFolderPlus />
            ) : (
              <IconFilePlus />
            )
          }
          loading={isLoading}
        >
          {isEditingDocument ? t('w.modify') : t('w.add')}
        </Button>
      </Stack>
    </form>
  );
}
