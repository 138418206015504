import {
  Button,
  Center,
  Group,
  LoadingOverlay,
  MultiSelect,
  SimpleGrid,
  Stepper,
  TextInput,
} from '@mantine/core';
import { DateInput, DatePicker } from '@mantine/dates';
import { isNotEmpty, useForm } from '@mantine/form';
import { useMediaQuery } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';

import ButtonSave from '../../../../../components/ButtonSave';
import LabelText from '../../../../../components/LabelText/LabelText';
import { useAuth } from '../../../../../contexts/AuthProvider';
import { useI18n } from '../../../../../contexts/I18nProvider';
import useCompany from '../../../../../hooks/useCompany';
import BlockingPeriodsService from '../../../../../services/BlockingPeriodsService';
import type { BlockingPeriodRequest } from '../../../../../types/api/payload/blockingPeriod';
import type { DivisionResponse } from '../../../../../types/api/response/division';
import handleErrorMessage from '../../../../../utils/handleErrorMessage';
import { NO_WORK_UNIT } from '../../../../../variables/GlobalVariables';

type Props = {
  divisions: DivisionResponse[];
  onClose: () => void;
  refresh: () => void;
};

interface FormValues {
  name: string;
  description: string;
  dateRange: [Date, Date];
  divisionIds: string[];
}

export default function BlockingPeriodsStepper({
  divisions,
  onClose,
  refresh,
}: Props) {
  const { t } = useI18n();
  const { user } = useAuth();
  const { id: companyId, company } = useCompany(user);
  const matches = useMediaQuery('(min-width: 1025px)');
  const [active, setActive] = useState(0);
  const form = useForm<FormValues>({
    initialValues: {
      name: '',
      description: '',
      dateRange: [new Date(), new Date()],
      divisionIds: [],
    },
    validate: {
      name: isNotEmpty(t('w.required')),
    },
  });

  const divisionsSelect = useMemo(() => {
    if (!divisions) {
      return [];
    }
    return divisions.map((division) => {
      return {
        value: division.id,
        label:
          division.name === NO_WORK_UNIT ? t('NO_WORK_UNIT') : division.name,
      };
    });
  }, [divisions]);

  const { mutate: createBlockingPeriod, isLoading } = useMutation({
    mutationFn: (variables: BlockingPeriodRequest) =>
      BlockingPeriodsService.createBlockingPeriod(companyId, variables),
    onSuccess: (data) => {
      const { id, name } = data;
      refresh();
      onClose();
      showNotification({
        id: `create-blocking-period-${name}-id-${id}-successful`,
        title: t('w.success'),
        message: t('success.blockingPeriod.created', name),
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: (error) =>
      showNotification({
        id: 'create-blocking-period-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function handleValidateFirstStepForm(): void {
    if (active === 0) {
      if (form.isValid('name')) {
        form.clearFieldError('name');
        setActive(active + 1);
      } else {
        form.validate();
      }
    }
  }

  function handleValidateSecondStepForm(): void {
    if (active === 1) {
      setActive(active + 1);
    }
  }

  function handleCreateBlockingPeriodSubmit(values: FormValues): void {
    if (form.values.divisionIds.length > 0) {
      createBlockingPeriod({
        companyId: companyId,
        name: values.name,
        description: values.description,
        periodStart: dayjs(values.dateRange[0]).format('DD/MM/YYYY'),
        periodEnd: values.dateRange[1]
          ? dayjs(values.dateRange[1]).format('DD/MM/YYYY')
          : dayjs(values.dateRange[0]).format('DD/MM/YYYY'),
        divisionIds: values.divisionIds,
      });
    } else {
      form.setFieldError('divisionIds', t('w.required'));
    }
  }

  return (
    <form
      onSubmit={form.onSubmit((values) =>
        handleCreateBlockingPeriodSubmit(values)
      )}
    >
      <LoadingOverlay visible={isLoading} />
      <Stepper active={active}>
        <Stepper.Step label={t('w.general')}>
          <SimpleGrid cols={{ base: 1, sm: 2 }}>
            <TextInput
              required
              label={<LabelText text={t('w.name')} />}
              placeholder={t('w.name')}
              {...form.getInputProps('name')}
              data-autofocus
            />
            <TextInput
              label={<LabelText text={'Description'} />}
              placeholder={'Description'}
              {...form.getInputProps('description')}
              data-autofocus
            />
          </SimpleGrid>
        </Stepper.Step>
        <Stepper.Step label="Dates">
          <SimpleGrid cols={{ base: 1, md: 2 }}>
            <Center>
              <DatePicker
                type="range"
                numberOfColumns={matches ? 2 : 1}
                allowSingleDateInRange
                {...form.getInputProps('dateRange')}
              />
            </Center>
            <SimpleGrid>
              <DateInput
                label={<LabelText text={t('w.startDate')} />}
                disabled
                value={form.values.dateRange[0]}
                valueFormat="DD/MM/YYYY"
              />
              <DateInput
                label={<LabelText text={t('w.endDate')} />}
                disabled
                value={
                  form.values.dateRange[1]
                    ? form.values.dateRange[1]
                    : form.values.dateRange[0]
                }
                valueFormat="DD/MM/YYYY"
              />
            </SimpleGrid>
          </SimpleGrid>
        </Stepper.Step>
        <Stepper.Step
          label={company?.divisionLabel ? company.divisionLabel : t('w.unit')}
        >
          <SimpleGrid cols={1}>
            <MultiSelect
              w={'50%'}
              data={divisionsSelect}
              label={
                <LabelText
                  text={
                    company?.divisionLabel ? company.divisionLabel : t('w.unit')
                  }
                />
              }
              placeholder={t('w.select')}
              searchable
              nothingFoundMessage={t('w.noValue')}
              {...form.getInputProps('divisionIds')}
            />
          </SimpleGrid>
        </Stepper.Step>
      </Stepper>
      <Group justify={'flex-end'} mt={'xl'}>
        {active > 0 && (
          <Button onClick={() => setActive(active - 1)}>{t('w.back')}</Button>
        )}
        {active < 2 ? (
          <Button
            onClick={() => {
              handleValidateFirstStepForm();
              handleValidateSecondStepForm();
            }}
          >
            {t('w.next')}
          </Button>
        ) : (
          <ButtonSave />
        )}
      </Group>
    </form>
  );
}
