import { TimeRecorder, TimeRecorderRequest } from '../types/types';
import { MS_PLANNING_ENDPOINT } from '../variables/GlobalVariables';
import request from './AxiosClient';

function list(
  userId: string,
  start: string,
  end: string
): Promise<TimeRecorder[]> {
  return request
    .get(`${MS_PLANNING_ENDPOINT}/v2/time-recorder/${userId}/list`, {
      params: { start: start, end: end },
    })
    .then((res) => res?.data);
}

function update(
  userId: string,
  timeRecorderId: string,
  payload: TimeRecorderRequest
): Promise<TimeRecorder> {
  return request
    .patch(
      `${MS_PLANNING_ENDPOINT}/v2/time-recorder/${userId}/${timeRecorderId}`,
      payload
    )
    .then((res) => res?.data);
}

function deleteOne(userId: string, timeRecorderId: string): Promise<void> {
  return request
    .delete(
      `${MS_PLANNING_ENDPOINT}/v2/time-recorder/${userId}/${timeRecorderId}`
    )
    .then((res) => res?.data);
}

const TimeRecorderService = {
  list,
  update,
  deleteOne,
};

export default TimeRecorderService;
