import { useQuery } from '@tanstack/react-query';

import PaymentAccountService from '../services/PaymentAccountService';

export default function useStripeConfig(paymentAccountId: string) {
  const { data: publishableKey, isLoading: publishableKeyLoading } = useQuery({
    enabled: !!paymentAccountId,
    queryKey: [
      'PaymentAccountService.getStripePublishableKey',
      paymentAccountId,
    ],
    queryFn: () =>
      PaymentAccountService.getStripePublishableKey(paymentAccountId),
    staleTime: Infinity,
  });

  const { data: clientSecretResponse, isLoading: clientSecretLoading } =
    useQuery({
      enabled: !!paymentAccountId,
      queryKey: [
        'PaymentAccountService.getStripeClientSecret',
        paymentAccountId,
      ],
      queryFn: () =>
        PaymentAccountService.getStripeClientSecret(paymentAccountId),
    });

  return {
    publishableKey,
    clientSecret: clientSecretResponse?.client_secret,
    isLoading: publishableKeyLoading || clientSecretLoading,
  };
}
