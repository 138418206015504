import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

import { Space } from '@mantine/core';
import React, { useEffect, useRef, useState } from 'react';
import { Layer, Rect, Stage, Transformer } from 'react-konva';
import { Html } from 'react-konva-utils';
import { Document, Page, pdfjs } from 'react-pdf';

import { PayslipConfigurationFormObject } from '../../../../utils/payslipConfigurations';

const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = url;

type PropsRectangle = {
  shapeProps: PayslipConfigurationFormObject;
  isSelected: boolean;
  onSelect: () => void;
  onChange: (newAttrs: PayslipConfigurationFormObject) => void;
};

const Rectangle = ({
  shapeProps,
  isSelected,
  onSelect,
  onChange,
}: PropsRectangle) => {
  const shapeRef = useRef(null);
  const trRef = useRef(null);

  useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      //@ts-ignore
      trRef?.current?.setNode(shapeRef.current);
      //@ts-ignore
      trRef?.current?.getLayer().batchDraw();
      //@ts-ignore
      trRef?.current?.setRotateEnabled(false);
      //@ts-ignore
      trRef?.current?.setIgnoreStroke(true);
      //@ts-ignore
      trRef?.current?.setKeepRatio(false);
    }
  }, [isSelected]);

  return (
    <>
      <Rect
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        {...shapeProps}
        stroke={shapeProps.color}
        draggable
        strokeScaleEnabled={false}
        onDragEnd={(e) => {
          // @ts-ignore
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransform={() => {
          // do nothing.
        }}
        onTransformEnd={() => {
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef.current;
          // @ts-ignore
          const scaleX = node?.scaleX();
          // @ts-ignore
          const scaleY = node?.scaleY();

          // we will reset it back
          // @ts-ignore
          node?.scaleX(1);
          // @ts-ignore
          node?.scaleY(1);
          // @ts-ignore
          onChange({
            ...shapeProps,
            // @ts-ignore
            x: node?.x(),
            // @ts-ignore
            y: node?.y(),
            // set minimal value
            // @ts-ignore
            width: Math.max(5, node?.width() * scaleX),
            // @ts-ignore
            height: Math.max(node?.height() * scaleY),
          });
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
};

type PayslipConfigShapeProps = {
  pdfUrl: any;
  defaultPayslipConfig: Map<string, PayslipConfigurationFormObject>;
  setDefaultPayslipConfig: (
    newPayslipConfig: Map<string, PayslipConfigurationFormObject>
  ) => void;
};

export default function PayslipConfigShape({
  pdfUrl,
  defaultPayslipConfig,
  setDefaultPayslipConfig,
}: PayslipConfigShapeProps) {
  const [selectedConfigKey, setSelectedConfigKey] = useState<string | null>(
    null
  );
  const [size, setSize] = useState({
    width: 595,
    height: 842,
  });

  const checkDeselect = (e: { target: { getStage: () => any } }) => {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      setSelectedConfigKey(null);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      const pdfElement: any = document.getElementsByClassName(
        'react-pdf__Document'
      );
      setSize((prev) => ({
        ...prev,
        width: pdfElement[0].offsetWidth,
        height: pdfElement[0].offsetHeight,
      }));
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <Stage
      width={size.width}
      height={size.height}
      onMouseDown={checkDeselect}
      onTouchStart={checkDeselect}
    >
      <Layer>
        <Html divProps={{ style: { zIndex: -1 } }}>
          <Space />
          <Document file={pdfUrl} loading={'Chargement...'}>
            <Page pageNumber={1} />
          </Document>
          <Space />
        </Html>
        {Array.from(defaultPayslipConfig.entries())
          .reverse()
          .map(([i, rect]) => {
            return (
              <Rectangle
                key={i}
                shapeProps={rect}
                isSelected={rect.id === selectedConfigKey}
                onSelect={() => {
                  setSelectedConfigKey(rect.id);
                }}
                onChange={(newAttrs) => {
                  let payslipConfig = new Map(defaultPayslipConfig);
                  payslipConfig.set(i, newAttrs);
                  setDefaultPayslipConfig(payslipConfig);
                }}
              />
            );
          })}
      </Layer>
    </Stage>
  );
}
